import useMobile from '../../../hooks/useMobile'

const useShrinkTitle = () => {
  const isMobile = useMobile()

  const truncatePairs = [
    ['New Words'],
    ['Grammar and Usage'],
    ['Grammar & Usage'],
    ['Grammar'],
    ['New Phrases'],
    ['Culture Clip'],
    ['Mini Conversations ミニかいわ Japanese→English', 'Mini Convo J→E', 'Mini Conversations'],
    ['Mini Conversations ミニ会話 J→E', 'Mini Convo J→E', 'Mini Conversations'],
    ['Mini Conversations ミニかいわ English→Japanese', 'Mini Convo E→J', 'Mini Conversations'],
    ['Mini Conversations ミニ会話 E→J', 'Mini Convo E→J', 'Mini Conversations'],
    ['Mini Conversation ミニかいわ Japanese→English', 'Mini Convo J→E', 'Mini Conversation ミニかいわ Japanese→English'],
    ['Mini Conversation ミニ会話 J→E', 'Mini Convo J→E', 'Mini Conversation ミニ会話 J→E'],
    ['Mini Conversation ミニかいわ English→Japanese', 'Mini Convo E→J', 'Mini Conversation ミニかいわ English→Japanese'],
    ['Mini Conversation ミニ会話 E→J', 'Mini Convo E→J', 'Mini Conversation ミニ会話 E→J'],
    ['Mini Conversations', 'Mini Convos'],
    ['English→Japanese', 'Q&A E→J'],
    ['Japanese→English', 'Q&A J→E'],
    ['Q&A しつもんと こたえ Japanese→English', 'Q&A J→E'],
    ['Q&A しつもんと こたえ English→Japanese', 'Q&A E→J'],
    ['Q&A しつもんと こたえ', 'Q&A'],
    ['Q&A 質問と答え', 'Q&A'],
    ['Q&A 質問と答え Japanese→English', 'Q&A J→E'],
    ['Q&A 質問と答え J→E', 'Q&A J→E'],
    ['Q&A 質問と答え English→Japanese', 'Q&A E→J'],
    ['Q&A 質問と答え E→J', 'Q&A E→J'],
    ['Reading Comprehension Explanation'],
    ['Reading Comprehension'],
    ['New Hiragana'],
    ['Various Styles'],
    ['Writing Points'],
    ['Speaking Naturally'],
    ['New Adjectives'],
    ['Verb Usage'],
    ['The goal'],
    ['あいうえお - How this course works', 'How this course works'],
    ['Some History'],
    ['Writing Basics'],
    ['Word Usage'],
    ['Cool Tools'],
    ['Location Words'],
    ['Common Questions'],
    ['New Katakana'],
    ['Conversation'],
    ['New Verbs'],
    ['Explanation'],
    ['Number Activities'],
    ['Various Activities'],
    ['You’re the Interpreter'],
    // ['You’re the Interpreter あなたは通訳です', 'You’re the Interpreter'],
    ['Counters'],
    ['The Counting Units'],
    ['Counting'],
    ['Hiragana - How this course works', 'How this course works'],
    ['Special Usage'],
    // ['Special Usage とくべつな つかいかた', 'Special Usage'],
    ['Kanji Basics'],
    // ['Kanji Basics かんじの きほん', 'Kanji Basics'],
    ['New Kanji'],
    // ['New Kanji あたらしい かんじ', 'New Kanji'],
    // ['New Kanji 新しい漢字', 'New Kanji'],
    ['Time Spans'],
    // ['Time Spans 時間', 'Time Spans'],
    ['Fun Kanji'],
    // ['Fun Kanji たのしい かんじ', 'Fun Kanji'],
    ['New Phrase'],
    // ['New Phrase 新しいフレーズ', 'New Phrase'],
    ['Cool Tool'],
    // ['Cool Tool クール・ツール', 'Cool Tool'],
    ['New Counters'],
    // ['New Counters 新しい助数詞', 'New Counters'],
    // ['New Counters 新しいカウンター', 'New Counters'],
    ['The Interview'],
    // ['The Interview 面接', 'The Interview'],
    ['Kanji Drills'],
    // ['Kanji Drills かんじ ドリル', 'Kanji Drills'],
    ['Kanji Recognition'],
    // ['Kanji Recognition かんじ にんしき', 'Kanji Recognition'],
    // ['Adjectives Usage'],
    ['From me to you'],
    // ['From me to you 私からあなたへ', 'From me to you'],
    ['Japanese Living'],
    // ['Japanese Living 日本の暮らし', 'Japanese Living'],
    ['New Expressions'],
    // ['New Expressions 新しい表現', 'New Expressions'],
    ['Practice and Review'],
    ['Kanji Culture'],
    ['Vocabulary Groups'],
    ['Vocabulary Builder'],
    ['Everyday Katakana'],
    ['Everyday Hiragana']
  ]

  const shrinkFurther = (title) => {
    let newTitle = title

    switch (newTitle) {
      case 'Vocabulary Groups':
        newTitle = 'Vocab Groups'
        break
      case 'Vocabulary Group':
        newTitle = 'Vocab Group'
        break
      case 'Reading Comprehension':
        newTitle = 'Reading Comp.'
        break
      case 'Mini Conversations':
        newTitle = 'Mini Convos'
        break
      case 'Speaking Naturally':
        newTitle = 'Speaking Tips'
        break
      default:
    }
    return newTitle
  }

  // eslint-disable-next-line default-param-last
  const shrinkTitle = (title, long = false, overrideMobile) => {
    let newTitle = title
    
    for (let i = 0; i < truncatePairs.length; i++) {
      const p = truncatePairs[i]

      if (newTitle?.toLowerCase().indexOf(p[0]?.toLowerCase()) !== -1) {
        if (long) {
          newTitle = p[2] || p[1] || p[0]
        } else {
          newTitle = p[1] || p[0]
        }
        break
      }
    }

    if (!overrideMobile && isMobile) return shrinkFurther(newTitle)
    return newTitle
  }

  const shrinkSubTitle = (title, subLessonHeader = false) => {
    let shrunkTitle = shrinkTitle(title, true, false)
    let subTitle = ''
    
    const newTitle = title?.split(shrunkTitle)

    if (!subLessonHeader) return ''

    // if (shrinkTitle(title).indexOf('Q&A') !== -1) setOtherTitle('しつもんと こたえ')
    if (newTitle[0].indexOf('Q&A') !== -1) {
      subTitle = newTitle[0].indexOf('しつもんと こたえ') !== -1 ? 'しつもんと こたえ' : '質問と答え'
      return subTitle
    }

    if (newTitle.length > 1) {
      subTitle = newTitle[1].replace(/:/g, '')
      
      if (shrunkTitle.indexOf('Vocab') !== -1) {
        shrunkTitle = isMobile ? 'Vocab' : shrunkTitle
        subTitle = subTitle.replace('and', '&')
      }
    }

    return subTitle
  }

  return [shrinkTitle, shrinkSubTitle]
}

export default useShrinkTitle
