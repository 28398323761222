import { useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'

type useStudyModeHook = {
  inStudyMode: boolean
  hideEnglish: boolean
  hideOtherLang: boolean
}

const useStudyMode = (): useStudyModeHook => {
  const studySettings = useSelector((state: RootState) => state.auth?.study_mode_settings)
  const { inStudyMode, shownLanguage } = studySettings || { inStudyMode: false, shownLanguage: 'other' }
  
  const hideEnglish = inStudyMode && shownLanguage === 'other'
  const hideOtherLang = inStudyMode && shownLanguage === 'english'

  return { inStudyMode, hideEnglish, hideOtherLang }
}

export default useStudyMode
