import React, { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { ViewSwapStyles, AnimatedHira, AnimatedKata } from '../styles/ViewSwapStyles'

interface ButtonProps {
  beforeSwap: () => void
  handleSwap: () => void
  view: number
  embed?: boolean
  isMobile?: boolean
}

const ViewSwapButton: React.FC<ButtonProps> = ({
  beforeSwap,
  handleSwap,
  view,
  embed,
  isMobile
}) => {
  const [animating, setAnimating] = useState(false)

  useEffect(() => {
    console.log(view, animating)
  }, [view, animating])

  const handleSwapping = () => {
    beforeSwap()
    setAnimating(true)

    setTimeout(() => {
      handleSwap()
      setAnimating(false)
    }, 225)
  }

  return (
    <ViewSwapStyles
      className='view-swap'
      onClick={handleSwapping}
      embed={embed}
      animating={animating}
      isMobile={isMobile}
    >
      <AnimatedHira embed={embed} view={view} animating={animating}>
        ひら
      </AnimatedHira>
      <Icon name='redo' className='swap' />
      <AnimatedKata embed={embed} view={view} animating={animating}>
        カタ
      </AnimatedKata>
    </ViewSwapStyles>
  )
}

export default ViewSwapButton
