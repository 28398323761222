import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

import { Transition } from '@headlessui/react'
import DropdownStyles from '../../styles/DropdownStyles'
import { InboxSolid, UserOutline } from '@graywolfai/react-heroicons'
import { Checkbox } from '@mantine/core'

import ColorizedFuriToggle from './ColorizedFuriToggle'
import FuriOpacitySlider from './FuriOpacitySlider'
import ProgSettingsToggler from './ProgSettingsToggler'
import CourseBackgroundSettings from './CourseBackgroundSettings'
import StudyModeOptions from './StudyModeOptions'
import useMobile from 'src/hooks/useMobile'
import useStaffRoles from 'src/hooks/useStaffRoles'
import useAPI from 'src/hooks/useAPI'
import { JustifyBetweenWithMargin, LowerItems, MenuIcon, MenuItems, UpperItems, UserNotifications } from '../../styles/MainDropdownMenu.styles'
import pluralizeWord from 'src/util/pluralizeWord'
import { RootState } from 'src/configureStore'
import { toggleV8Progressive, changeSettings } from 'src/actions/index'

interface DropdownMenuProps {
  open: boolean
  notificationCount: number
  onSignOut: (pathname?: string) => void
  openAdvancedSettings: () => void
  setSearchText: Dispatch<SetStateAction<string>>
  setSearchResultsVisible: Dispatch<SetStateAction<boolean>>
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({
  open, notificationCount, onSignOut, openAdvancedSettings, setSearchText, setSearchResultsVisible
}) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(false)
  const [willOpen, setWillOpen] = useState(true)
  const isMobile = useMobile()
  const [isStaffRole] = useStaffRoles()
  const [resV8S, loadingV8S, reqV8S] = useAPI('/user/v8_progressive', 'PUT')
  const [resC, loadingC, reqC] = useAPI('/user/calligraphy_mode', 'PUT')
  
  const v8Checked = useSelector((state: RootState) => state.auth?.v8_progressive)
  const calligraphyChecked = useSelector((state: RootState) => state.auth?.calligraphy_mode)
  const dispatch = useDispatch()
  const location = useLocation()

  const handleBeforeEnter = () => setWillOpen(false)
  const handleAfterLeave = () => setWillOpen(true)

  const easeIn = 'tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
  const fadeUpDown = 'tw-translate-y-[-100px] tw-opacity-0'
  const enterLeave = 'tw-translate-y-0 tw-opacity-full'

  useEffect(() => {
    if (!resV8S?.success) return
    dispatch(toggleV8Progressive(resV8S?.v8_progressive))
  }, [resV8S])

  useEffect(() => {
    if (!resC?.success) return
    dispatch(changeSettings({ calligraphy_mode: resC?.calligraphy_mode }))
  }, [resC])

  if (isMobile) return <></>
  return (
    <DropdownStyles willOpen={willOpen}>
      <Transition
        show={open}
        enter={easeIn}
        enterFrom={fadeUpDown}
        enterTo={enterLeave}
        leave={easeIn}
        leaveFrom={enterLeave}
        leaveTo={fadeUpDown}
        beforeEnter={() => handleBeforeEnter()}
        afterEnter={() => setButtonsDisabled(false)}
        beforeLeave={() => setButtonsDisabled(true)}
        afterLeave={() => handleAfterLeave()}
      >
        <MenuItems data-cy='dropdown-menu'>
          <UpperItems>
            {/* {isStaffRole && (
              <SiteSearchBar
                setSearchText={setSearchText}
                setSearchResultsVisible={setSearchResultsVisible}
              />
            )} */}
            {(notificationCount > 0 || true) && (
              <UserNotifications>
                <Link to='/notifications' style={{ textDecoration: 'underline' }}>
                  <MenuIcon>
                    <InboxSolid height='35px' className='tw-inline tw-fill-red' fill='red' />
                  </MenuIcon>
                  You have {pluralizeWord(notificationCount, 'new notification', true)}
                </Link>
              </UserNotifications>
            )}
            <JustifyBetweenWithMargin>
              <div>
                <Link data-cy='account-settings-link' to='/account' style={{ textDecoration: 'underline' }}>
                  <MenuIcon>
                    <UserOutline height='35px' className='tw-inline' />
                  </MenuIcon> Account Settings
                </Link>
              </div>
              <div className='logout'>
                <button data-cy='logout-btn' type='button' onClick={() => onSignOut()}>Sign out</button>
              </div>
            </JustifyBetweenWithMargin>
            {/* <JustifyBetweenWithMargin
              className='tw-cursor-pointer'
              onClick={openAdvancedSettings}
            >
              <div>
                <MenuIcon>
                  <CogSolid height='35px' className='tw-inline tw-fill-blue' fill='blue' />
                </MenuIcon> Advanced Study Settings
              </div>
            </JustifyBetweenWithMargin> */}
          </UpperItems>
          <LowerItems>
            {!location?.pathname?.includes('kanji') && <ProgSettingsToggler />}
            <FuriOpacitySlider />
            <ColorizedFuriToggle />
            <StudyModeOptions />
            <CourseBackgroundSettings />
            {/* admin-only accessible checkbox to toggle v8_progressive via API */}
            {isStaffRole && (
              <div className='tw-flex tw-items-center tw-mb-2'>
                <Checkbox
                  checked={v8Checked}
                  onChange={() => reqV8S({})}
                  label='v8_progressive'
                />
              </div>
            )}
            <div className='tw-flex tw-items-center tw-mb-2'>
              <Checkbox
                checked={calligraphyChecked}
                onChange={(e: React.FormEvent<HTMLInputElement>) => reqC({ calligraphy_mode: e.currentTarget.checked })}
                label='Calligraphy Mode (Beta)'
              />
            </div>
          </LowerItems>
        </MenuItems>
      </Transition>
    </DropdownStyles>
  )
}

export default DropdownMenu
