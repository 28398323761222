/**
 * A component used to render a formatted widget dynamically. Simply a shell around the client
 * view of various widgets that manages the fetching of their cached JSON based upon events that
 * occur outside of them.
 */

import React, { useEffect, useMemo, useState } from 'react'
import { useDebouncedValue } from '@mantine/hooks'

import { Segment } from 'semantic-ui-react'

import useAPI from 'src/hooks/useAPI'

import ConversationWidget from 'src/components/fz_courses/widgets/ConversationWidget'
import ListWidget from 'src/components/fz_courses/widgets/ListWidget'
import QnAWidget from 'src/components/fz_courses/widgets/QnAWidget'
import TableWidget from 'src/components/fz_courses/widgets/TableWidget'
import TextboxWidget from 'src/components/fz_courses/widgets/TextBoxWidget'

const widgetMap = {
  table: TableWidget,
  text: TextboxWidget,
  qna: QnAWidget,
  conversation: ConversationWidget,
  list: ListWidget
}

const FormatPreviewShell = ({ widget, refresher, type, course }) => {
  const [data, setData] = useState<any>()
  const [res, loading, req] = useAPI('/widgets/preview', 'POST')
  const [debounced] = useDebouncedValue(widget, 500)

  useEffect(() => {
    if (debounced) {
      console.log(debounced)
      req({ widget: debounced, course })
    }
  }, [debounced])

  useEffect(() => {
    if (res) {
      console.log(res)
      setData(res?.widget)
    }
  }, [res])

  const renderedWidget = useMemo(() => {
    if (data) {
      return React.createElement(widgetMap[type], { widget: data, refresher, key: `${widget.id}-${refresher}` })
    }
  }, [data])

  return (
    <div>
      <div>
        <Segment vertical loading={loading} key={`${widget.id}-${refresher}`}>
          {renderedWidget}
        </Segment>
      </div>
    </div>
  )
}

export default FormatPreviewShell
