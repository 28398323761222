import styled from '@emotion/styled'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const assetsPath = `${CLOUDFRONT_BASE}/images/banners/winback`
const winback90 = `${assetsPath}/campaign-banner-claim-90-days.webp`
const winback180 = `${assetsPath}/campaign-banner-claim-180-days.webp`

const WinbackBanner = styled.div<{ discount: number }>`
  height: 75px;
  max-width: 500px;
  align-self: center;
  background-image: ${({ discount }) => discount === 30 && `url(${winback90})`};
  background-image: ${({ discount }) => discount === 50 && `url(${winback180})`};
  background-position: center 40%;
  background-size: cover;
  margin: 16px auto;

  @media (max-width: 515px) { height: 50px }
  @media (max-width: 400px) { height: 40px }
`

export default WinbackBanner
