import React, { useMemo } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'

import { NavItem } from './Navigation'
import { Transition } from '@headlessui/react'

import { CogSolid } from '@graywolfai/react-heroicons'
import { createGlobalStyle } from 'styled-components'
import { Header, Icon } from 'semantic-ui-react'

import navigation_ask from 'src/assets/headernav/navigation_ask.svg'
import navigation_books from 'src/assets/headernav/navigation_books.svg'
import navigation_course from 'src/assets/headernav/navigation_course.svg'
import navigation_faq from 'src/assets/headernav/navigation_faq.svg'
import navigation_notebook from 'src/assets/headernav/navigation_notebook.svg'
import navigation_video from 'src/assets/headernav/navigation_video.svg'

import MobileSideDrawerStyles from '../styles/MobileSideDrawerStyles'

import Obscured from '../styles/Obscured'
import UserAvatar from './UserAvatar'
import ProgressiveSettingsToggle from 'src/components/fz_courses/admin/ProgressiveSettingsToggle'
import ErrorReportModal from 'src/components/modals/ErrorReportModal'
import StudyModeOptions from './dropdown_menu/StudyModeOptions'

import useStaffRoles from 'src/hooks/useStaffRoles'

// lock all other scrolling while mobile drawer is open
const DrawerGlobalStyle = createGlobalStyle`
  #content-body {
    overflow: hidden !important;
  }
`

interface MobileDrawerProps {
  closeDrawer: () => void
  onSignOut: (pathname?: string) => void
  openAdvancedSettings: () => void
  open: boolean
}

/* eslint-disable */
const MobileSideDrawer: React.FC<MobileDrawerProps> = ({
  closeDrawer,
  onSignOut,
  open,
  openAdvancedSettings
}) => {
  const username = useSelector((state: RootState) => state.auth.userId)
  const notification_count = useSelector((state: RootState) => state.navigator.notification_count)
  const [isStaffRole] = useStaffRoles()
  const adminOnly = ['FZ Live']

  const primaryNavigation: NavItem[] = useMemo(() => [
    {
      name: 'Homeroom',
      href: '/home',
      icon: <svg
        fill='var(--jfz-blue)'
        stroke='var(--jfz-blue)'
        strokeWidth='0.3px'
        viewBox='0 0 20 20'
        xmlns='http://www.w3.org/2000/svg'
        className='no-select'
      >
        <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
      </svg>,
      current: false,
    },
    {
      name: 'Notifications',
      href: '/notifications',
      icon: <span className="s-ui inbox">
        <Icon name='inbox' />
        <span className="notification-count">{notification_count}</span>
      </span>,
      current: false,
    },
    {
      name: 'Account Settings',
      href: '/account',
      icon: <CogSolid height='25px' width='25px' />,
      current: false,  
    },
    {
      name: 'Courses',
      href: '/courses',
      icon: <img
        src={navigation_course}
        alt='courses'
        className='no-select'
        draggable={false}
      />,
      current: false,
    },
    {
      name: 'Notebook',
      href: '/notebook',
      icon: <img
        src={navigation_notebook}
        alt='courses'
        className='no-select'
        draggable={false}
      />,
      current: false,
    },
    {
      name: 'Books',
      href: '/books',
      icon: <img
      src={navigation_books}
        alt='courses'
        className='no-select'
        draggable={false}
      />,
      current: false,
    },
    {
      name: 'Video Vault',
      href: '/vault',
      icon: <img
        src={navigation_video}
        alt='courses'
        className='no-select'
        draggable={false}
      />,
      current: false,
    },
    {
      name: 'Ask-A-Teacher',
      href: '/ask',
      icon: <img
        src={navigation_ask}
        alt='courses'
        className='no-select'
        draggable={false}
      />,
      current: false,
    },
    {
      name: 'Games',
      href: '/games',
      icon: <span className="s-ui"><Icon name='gamepad' /></span>,
      current: false,
    },
    {
      name: 'FZ Live',
      href: '/fz-live',
      icon: <span className="s-ui"><Icon name='users' /></span>,
      current: false,
    },
    {
      name: 'Search',
      href: '/search',
      icon: <span className="s-ui"><Icon name='search' /></span>,
      current: false,
    },
    {
      name: 'FAQ',
      href: '/faq',
      icon: <img
        src={navigation_faq}
        alt='courses'
        className='no-select'
        draggable={false}
      />,
      current: false,
    },
  ], [notification_count])

  return (
    <MobileSideDrawerStyles className='mobile-drawer'>
      {open && <DrawerGlobalStyle />}
      <Transition
        show={open}
        as='div'
        className='transition-wrapper'
        enter='tw-transform tw-transition tw-ease-in-out tw-duration-500'
        enterFrom='tw-translate-x-[300px]'
        enterTo='tw-translate-x-0'
        leave='tw-transform tw-transition tw-ease-in-out tw-duration-500'
        leaveFrom='tw-translate-x-0'
        leaveTo='tw-translate-x-[300px]'
      > 
        <div className="user-greeting">
          <h3>hi, {username}!</h3>
          <UserAvatar
            notificationCount={0}
            handleBadgeClick={() => null}
            handleAvatarClick={() => null}
          />
        </div>
        <div className='prog-settings'>
          <div style={{ display: 'grid', gridAutoFlow: 'column', marginBottom: '8px' }}>
            <Header as='h4'>Progressive Settings</Header>
            {/* <div style={{ textAlign: 'right' }}>
              <Icon name='cog' onClick={openAdvancedSettings} /> Advanced Settings
            </div> */}
          </div>
          <ProgressiveSettingsToggle variation='nav' />
          <StudyModeOptions />
        </div>
        <div className="links">
          {primaryNavigation.map((item: NavItem) => {
            if (isStaffRole || !adminOnly.includes(item.name)) return (
              <NavLink
                to={item.href}
                className="nav-link"
                key={item.name}
              >
                {item.icon}
                <span className="link-name">{item.name}</span>
              </NavLink>
            )
          })}
          <ErrorReportModal
            trigger={
              <span className='nav-link'>
                <span className="s-ui">
                  <Icon name='bug' />
                </span>
                <span className="link-name">Report a Bug</span>
              </span>
            }
          />
        </div>
        <div className='logout'>
          <button type='button' onClick={() => onSignOut()}>Sign out</button>
        </div>
      </Transition>
      {open && <Obscured onClick={() => closeDrawer()} />}
    </MobileSideDrawerStyles>
  )
}

export default MobileSideDrawer
