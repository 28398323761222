import styled from '@emotion/styled'

type KanaPosterProps = {
  embed?: boolean
  isMobile?: boolean
}

export const KanaPosterDesktop = styled.section<KanaPosterProps>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;

  * {
    box-sizing: border-box;
    font-family: 'Milliard Book';
  }

  * > blockquote {
    margin-top: auto;
    margin-bottom: auto;
  }

  .column .header,
  .row-labels div,
  .cell {
    cursor: pointer;

    :hover {
      color: var(--blue-gray);
      background-color: #c6ddef !important;
    }
  }

  .charts {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    gap: 35px;
  }

  .charts > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 35px;
  }

  p {
    color: var(--blue-gray);
    margin: 0;
    display: block;
    font-size: 1.2vw;
  }

  blockquote {
    margin: 0;
    font-size: 1.4vw;
    font-weight: bold;
    padding-bottom: auto;
  }

  .kana-chart,
  .youon-chart {
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
  }

  .column,
  .kana-cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-width: 4vw;
    width: 4vw;
    max-width: 70px;
    margin: 0 4px;

    .header {
      padding: 0px;
      border-radius: 8px;
      margin-bottom: 12px;
    }
  }

  .kana-cards {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    margin: 4px 0;
    min-height: 4vw;
    max-height: 70px;
  }

  .cell {
    background-color: var(--off-white);
    padding: 12px 0 6px;
    width: 100%;
    height: 5.75vw;
    min-height: 4vw;
    max-height: 70px;
    min-width: 4vw;
    max-width: 70px;
    border-radius: 8px;
    box-shadow: 0 5px 9px 3px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;

    p {
      margin-top: -4px;
    }

    &:hover {
      background-color: #28c6aa;
      blockquote, p {
        color: var(--off-white) !important;
      }
    }
  }

  .selected-cell {
    background-color: ${({ embed }) => embed ? '#52526b' : '#1d78be'} !important;
  }

  .selected-cell, .selected-cell p {
    color: var(--off-white) !important;
  }

  .row .active-cell {
    blockquote, p {
      color: var(--off-white) !important;
    }
  }
  .row .cell:hover {
    blockquote, p {
      color: var(--blue-gray) !important;
    }
  }

  // column and row labels

  .header,
  .row-labels > div {
    text-align: center;
    color: var(--off-white);
    background-color: ${({ embed }) => embed ? '#9296be' : '#28c6aa'};
    border-radius: 8px;
    height: 2vw;
    min-height: 26px;
    width: 2vw;
    min-width: 20px;
    padding-bottom: 6px;
  }
  .header {
    margin-left: 4px;
    width: 100%;
  }

  .empty-column {
    width: auto;
    min-width: 0;

    .header {
      justify-content: flex-start;
      background-color: transparent !important;
      cursor: default;

      :hover {
        background-color: transparent !important;
        color: transparent !important;
      }
    }
  }

  .row-labels {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 32px;

    .row {
      height: 5.75vw;
      max-height: 70px;
    }

    .row div {
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .active-row {
      background-color: ${({ embed }) => embed ? '#52526b' : '#1d78be'} !important;
    }
  }

  h3,
  .row-labels blockquote {
    font-size: 1.25vw;
    padding: 4px;
  }

  .column .header,
  .empty-column .row-labels {
    .row {
      box-shadow: 0 5px 9px 3px rgba(0, 0, 0, 0.1);
    }
    .active-row {
      background-color: ${({ embed }) => embed ? '#52526b' : '#1d78be'} !important;
      :hover { color: var(--off-white); }
    }
  }

  .empty-column .row-labels {
    margin-top: 0px;
  }

  .empty-column .header {
    box-shadow: none;
    margin-bottom: 10px;
  }

  .kana-labels {
    margin-top: 39px;
  }

  .card-enter {
    opacity: 0;
  }
  .card-enter-active {
    opacity: 1;
    transition: opacity 150ms ease-in;
  }
  .card-exit {
    opacity: 1;
  }
  .card-exit-active {
    opacity: 0;
    transition: opacity 150ms ease-in;
  }

  @media (min-width: 2100px) {
    blockquote {
      font-size: 2rem;
    }
    p {
      font-size: 1.5rem;
    }
    .column {
      width: 100px;
    }
    .kana-cards {
      width: 100%;
    }
    .row,
    .empty-column .row {
      height: 100px;
    }
  }

  @media (min-width: 1280px) {
    blockquote {
      font-size: 1.5rem;
    }

    h3,
    .row-labels blockquote {
      font-size: 1.5rem;
      padding: 0px;
    }
  }
`

export const KanaPosterMobile = styled(KanaPosterDesktop)<KanaPosterProps>`
  align-items: center;
  flex-direction: row;

  p {
    font-size: 3vw;
  }

  blockquote {
    font-size: 4vw;
  }

  .kana-chart,
  .youon-chart {
    flex-direction: column;
    margin-right: auto;
  }

  .column,
  .kana-cards {
    flex-direction: row;
    min-width: auto;
    width: auto;
    max-width: 100%;
    margin-top: 2px;
    margin-bottom: 8px;
    height: 13vw;

    .header {
      padding: 0px;
      padding-top: 4vw;
      margin-top: 5px;
      margin-bottom: -3px;
    }
  }

  .kana-cards {
    flex-direction: row;
  }

  .empty-column, .empty-column .kana-cards {
    height: 5vw;
  }

  .row {
    margin-bottom: 0;
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }

  .cell {
    min-height: 13vw;
    max-height: 13vw;
    min-width: 13vw;
    max-width: 13vw;
    margin-right: 10px;
  }

  .header,
  .row-labels > div {
    height: auto;
    width: 13vw;
    margin-right: 10px;
  }

  .row-labels {
    flex-direction: row;
    width: auto;
    margin-bottom: -50px;
    height: 5vw;

    .row {
      height: 5vw;
      padding-bottom: 0px;
    }

    .row div {
      flex-direction: row;
    }
  }

  .header {
    width: 5vw;
  }

  h3,
  .row-labels blockquote {
    font-size: 2.5vw;
  }

  .empty-column .row-labels {
    margin-top: -6px;
  }
`
