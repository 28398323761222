import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'src/configureStore'
import { changeStudyMode } from '../../../../actions/index'

import StudyModeOptionsStyles from '../../styles/StudyModeOptionsStyles'

const StudyModeOptions = () => {
  const studyModeSettings = useSelector((state: RootState) => state.auth?.study_mode_settings)
  const { inStudyMode, shownLanguage } = studyModeSettings || { inStudyMode: false, shownLanguage: 'other' }
  const [checkedState, setCheckedState] = useState(shownLanguage === 'english')
  const dispatch = useDispatch()

  const toggleStudyMode = () => {
    dispatch(changeStudyMode({ ...studyModeSettings, inStudyMode: !inStudyMode }))
  }

  const changeShownLanguage = (e) => {
    const { checked } = e.target
    if (checked) {
      dispatch(changeStudyMode({ ...studyModeSettings, shownLanguage: 'english' }))
    } else {
      dispatch(changeStudyMode({ ...studyModeSettings, shownLanguage: 'other' }))
    }
    setCheckedState(checked)
  }

  useEffect(() => {
    // console.log(studyModeSettings)
  }, [studyModeSettings])

  return (
    <StudyModeOptionsStyles inStudyMode={inStudyMode}>
      <div className='settings'>
        <button onClick={toggleStudyMode}>Study Mode</button>
        <div>
          <aside>Show only</aside>
          <div className='language-selector'>
            <p>J</p>
            <input
              type='checkbox'
              checked={checkedState}
              onChange={(e) => changeShownLanguage(e)}
              disabled={!inStudyMode}>
            </input>
            <p>E</p>
          </div>
        </div>
      </div>
    </StudyModeOptionsStyles>
  )
}

export default StudyModeOptions
