import styled from '@emotion/styled'

export const Header = styled.header<{ isMobile?: boolean }>`
  font-family: 'Jost';
  color: var(--blue-gray);
`

export const CTA = styled.h2<{ isMobile?: boolean }>`
  text-align: center;
  padding-bottom: 24px;
  padding-top: ${({ isMobile }) => isMobile ? '8px' : ''};
  font-family: "Mont Extra Light";
  font-size: 1.5rem;
  font-weight: bold;
  
  @media (max-width: 500px) { font-size: 1.25rem !important; }
`

export const FZPrice = styled.div<{ isMobile: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 16px;

  img {
    padding-right: 36px;
    max-width: 220px;
    border-right: 1px solid var(--blue-gray);
    justify-self: end;

    @media (max-width: 500px) { max-width: 150px; padding-right: 16px; }
  }

  div {
    display: flex;
    flex-direction: column;
    justify-self: start;
    justify-content: center;
    text-align: center;
    padding-left: 36px;

    h2 {
      text-align: center;
      padding: 0;
      margin: 0;
      font-weight: bold;

      span {
        font-size: 2.4rem;
        @media (max-width: 500px) { font-size: 1.5rem; }
      }
    }

    @media (max-width: 500px) { padding-left: 16px; }
  }
`
