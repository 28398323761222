import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

const teacherCore = ({ component: Component, ...rest }) => {
  const teacher = rest.roles?.includes('admin') || rest.roles?.includes('teacher')

  return (
    <Route
      {...rest}
      render={(props) => (
        teacher ? (rest.render?.(props) || <Component {...props} />)
          : <Redirect to='/' />
      )}
    />
  )
}

const mapStateToPropsTeacher = (state) => ({ roles: state.auth.roles })
const TeacherRoute = connect(mapStateToPropsTeacher)(teacherCore)

export default TeacherRoute
