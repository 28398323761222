import React from 'react'
import { ToolSubheaderProps } from '../interfaces/CreationToolkit.interface'
import { ToolSubheader as Subheader } from '../styles'

const ToolSubheader: React.FC<ToolSubheaderProps> = ({
  children
}) => {
  return (
    <Subheader id='tk-tool-subheader'>
      {children}
    </Subheader>
  )
}

export default ToolSubheader
