import React, { createContext, useRef, useState } from 'react'

interface FormattingContextValue {
  markerStackIndex: {[key: number]: string}
  registerIndex: (index: number, marker: string) => void
}

const FormattingContext = createContext<FormattingContextValue>({
  markerStackIndex: {},
  registerIndex: () => false
})

const FormattingProvider: React.FC = ({ children }) => {
  const [markerStackIndex, setMarkerStackIndex] = useState({})

  const registerIndex = (index: number, marker: string) => {
    setMarkerStackIndex({ ...markerStackIndex, [index]: marker })
  }

  return (
    <FormattingContext.Provider value={{ markerStackIndex, registerIndex }}>
      {children}
    </FormattingContext.Provider>
  )
}

export { FormattingContext, FormattingProvider }
