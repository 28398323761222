import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import moment from 'moment'
import axios from 'src/api'

import { ADMIN_ROLE, DEV_ROLE, TEACHER_ROLE } from 'src/util/constants'
import { AdminRolesResponse, UserAvatarResponse } from 'src/types'
import { REDUX_NAV_ACTIONS } from 'src/actions/types'

import { LockClosedSolid, LockOpenSolid } from '@graywolfai/react-heroicons'

import useStaffRoles from 'src/hooks/useStaffRoles'
import useMobile from 'src/hooks/useMobile'
import useAPI from 'src/hooks/useAPI'

import UserBadge from './UserBadge'

import tomoko_default from 'src/assets/chibi/default_avatars/default_standard_1.svg'
import defaultAvatar from 'src/util/defaultAvatars'
import useToolkitStore from 'src/stores/toolkit.store'

const NewNotificationCounter: React.FC<{ notificationCount: number }> = ({ notificationCount }) => {
  if (!notificationCount) return <></>

  return (
    <div className='notification-bubble'>
      <p>{notificationCount > 9 ? '9+' : notificationCount}</p>
    </div>
  )
}

interface UserAvatarProps {
  notificationCount: number
  handleBadgeClick: () => void
  handleAvatarClick: () => void
}

const UserAvatar: React.FC<UserAvatarProps> = ({
  notificationCount, handleAvatarClick, handleBadgeClick
}) => {
  const [bgColor, setBgColor] = useState<string>()
  const [userAvatar, setUserAvatar] = useState<string>()

  /* eslint-disable no-multi-spaces */
  const IAM: string         = useSelector((state: RootState) => state.auth?._id)
  const avatar_url: string  = useSelector((state: RootState) => state.auth?.avatar_url)
  const default_avatar      = useSelector((state: RootState) => state.auth?.default_avatar)
  const admin_view: boolean = useSelector((state: RootState) => state.navigator?.admin_view)
  const user_avatar_cf      = useSelector((state: RootState) => state.navigator?.user_avatar_cf)
  const avatar_expires_at   = useSelector((state: RootState) => state.navigator?.avatar_expires_at)
  const alteredWidgetIds    = useToolkitStore((state) => state.alteredWidgetIds)
  const resetToolkit        = useToolkitStore((state) => state.reset)
  /* eslint-enable */

  const dispatchAdminView = useDispatch()
  const navigatorDispatch = useDispatch()
  const isMobile = useMobile()
  const [isStaffRole] = useStaffRoles()
  const [limitedToRoles] = useStaffRoles(ADMIN_ROLE, DEV_ROLE, TEACHER_ROLE)
  const [userAvatarCfResponse, loadingPost, postRequestUserAvatarCf, postError] = useAPI<UserAvatarResponse>('/user/signed_avatar', 'POST')
  const [adminRoles, loadingRoles, fetchRoles] = useAPI<AdminRolesResponse>('/admin/current', 'LAZYGET')
  
  /**
   *
   * User avatar logic
   *
  */

  const handleAvatarSrc = useCallback(() => {
    let updateAvatar
    let updateBgColor = '#61a1e2'
    
    if (default_avatar) {
      updateAvatar = defaultAvatar(default_avatar.color, default_avatar.version).icon
      updateBgColor = defaultAvatar(default_avatar.color, default_avatar.version).bg_color
    } else {
      updateAvatar = tomoko_default
    }
    
    setUserAvatar(updateAvatar)
    setBgColor(updateBgColor)
  }, [])

  const setReduxAvatar = (url: string, expires_at?: Date) => {
    navigatorDispatch({
      type: REDUX_NAV_ACTIONS.SET_AVATAR_CF,
      payload: {
        user_avatar_cf: url,
        avatar_expires_at: url.length ? expires_at : ''
      }
    })
  }

  const getCfPath = (path) => {
    axios
      .post('/user/signed_avatar', {
        url: path,
      })
      .then((res) => {
        setUserAvatar(res.data.url)
        setReduxAvatar(res.data.url, res.data.expires_at)
      })
      .catch((err) => console.error(err))
  }

  const getAvatarPath = () => {
    axios
      .get('/user/avatar')
      .then((res) => {
        if (res.data.success) getCfPath(res.data.avatar)
      })
      .catch((err) => console.error(err))
  }

  useEffect(() => {
    if (userAvatarCfResponse?.success) {
      // response will be successful if the user has an s3_avatar_url on user doc
      // it returns a cached_at date which expires every 24 hours
      setUserAvatar(userAvatarCfResponse.url)
      setReduxAvatar(userAvatarCfResponse.url, userAvatarCfResponse.expires_at)
    }
    // if there is no s3_avatar_url, the response will be 404 and
    // we can set their avatar src as the default avatar on their user doc
    if (postError?.status > 399) handleAvatarSrc()
  }, [loadingPost, userAvatarCfResponse])

  useEffect(() => {
    // if the user does not have a s3_avatar_url,
    // assign them their default chibi avatar which is added to their user doc on registration
    if (!avatar_url?.trim()?.length) {
      handleAvatarSrc()
      return
    }

    // if there is no redux state for their cf url, request it from the backend
    if (avatar_url.trim().length && !user_avatar_cf) {
      postRequestUserAvatarCf({ url: avatar_url })
    }
  }, [avatar_url])

  const checkCfUrlValidity = () => {
    // checking if their cf url is expired
    const yesterday = moment().subtract(23, 'hours').subtract(59, 'minutes')
    if (avatar_url?.trim()?.length
      && avatar_expires_at
      && moment(avatar_expires_at).isBefore(yesterday)
    ) {
      // if it expired, request a new url from backend
      postRequestUserAvatarCf({ url: avatar_url })
    }
  }

  useEffect(() => {
    // if they have a cf link in redux state, set it as their avatar src
    if (!user_avatar_cf?.length) return
    checkCfUrlValidity()
  }, [user_avatar_cf, isMobile])

  useEffect(() => {
    if (avatar_url) {
      getAvatarPath()
    }
    if (user_avatar_cf?.length) {
      setUserAvatar(user_avatar_cf)
    }
  }, [isMobile])

  useEffect(() => {
    // before page unloads, unset their redux avatar state
    // avatar state is also cleared on logout,
    // but this is supposed to allow them to stay logged in and maintain a valid url...
    // ...when it works
    window.addEventListener('beforeunload', () => setReduxAvatar(''))
    return () => window.removeEventListener('beforeunload', () => setReduxAvatar(''))
  }, [])

  /**
   *
   * Edit button and admin dropdown logic
   *
  */

  const checkUserRoles = (): boolean => (adminRoles?.success
    ? !!adminRoles.admin.find((a) => a._id === IAM)
    : false
  )

  const handleEditMode = () => {
    if (!admin_view) {
      const canViewAdmin = checkUserRoles()
      if (!canViewAdmin) return
    }
    
    if (alteredWidgetIds.length) {
      const confirmMessage = 'You have unsaved changes. Are you sure you want to leave Admin View?'
      if (!window.confirm(confirmMessage)) return
      resetToolkit()
    }

    dispatchAdminView({
      type: REDUX_NAV_ACTIONS.SET_ADMIN_VIEW,
      payload: { admin_view: !admin_view }
    })
  }

  useEffect(() => {
    if (!isStaffRole) return
    fetchRoles()
  }, [location.pathname])

  useEffect(() => {
    checkUserRoles()
  }, [adminRoles, loadingRoles])

  useEffect(() => {
    if (!isStaffRole && admin_view) {
      dispatchAdminView({
        type: REDUX_NAV_ACTIONS.SET_ADMIN_VIEW,
        payload: { admin_view: false }
      })
    }
  }, [isStaffRole])

  const showAdminViewButton = useMemo(() => {
    if (!isStaffRole) return false
    const editable_routes = ['ask', 'courses', 'vault']
    const root = location.pathname.split('/').filter((p) => p)[0]
    if (isStaffRole && editable_routes.includes(root)) return true
  }, [location.pathname, isMobile, isStaffRole])

  return (
    <div className='user-badge-avatar'>
      {(limitedToRoles && showAdminViewButton) && (
        <div className='admin-edit-mode' onClick={handleEditMode}>
          {admin_view ? (
              <>
                <span className="icon">
                  <LockOpenSolid height='25px' className='tw-inline tw-fill-blue' fill='blue' />
                </span>
                <span className="view">View: Admin</span>
              </>
          ) : (
            <>
              <span className="icon">
                <LockClosedSolid height='25px' className='tw-inline tw-fill-blue' fill='blue' />
              </span>
              <span className="view">View: User</span>
            </>
          )}
        </div>
      )}
      <div onClick={handleBadgeClick}>
        <UserBadge />
      </div>
      <div data-cy='user-avatar' className='user-avatar' onClick={handleAvatarClick}>
        <div
          // className={`soft-glow${isStaffRole ? ' radar-blip' : ''}`}
          className={notificationCount > 0 ? 'radar-blip' : ''}
          style={{ backgroundColor: bgColor }}
        >
          <img
            src={userAvatar}
            alt='avatar'
            draggable='false'
            className='no-select'
            style={{ objectFit: 'cover' }}
            onError={() => handleAvatarSrc()}
            onErrorCapture={() => handleAvatarSrc()}
          />
        </div>
        <NewNotificationCounter notificationCount={notificationCount} />
      </div>
    </div>
  )
}

export default UserAvatar
