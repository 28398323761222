import React from 'react'

interface ClickableOverlay {
  open: boolean
  handleClick: () => void
}

const ClickableOverlay: React.FC<ClickableOverlay> = ({
  open,
  handleClick
}) => {
  if (!open) return <></>
  return <div className='clickable-overlay' onClick={handleClick} />
}

export default ClickableOverlay
