import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'

const vaultAdminCore = ({ component: Component, ...rest }) => {
  const vaultAdmin = rest.roles?.includes('vault-admin') || rest.roles?.includes('admin')

  return (
    <Route
      {...rest}
      render={(props) => (
        vaultAdmin ? (rest.render?.(props) || <Component {...props} />)
          : <Redirect to='/' />
      )}
    />
  )
}

const mapStateToPropsVaultAdmin = (state) => ({ roles: state.auth.roles })

const VaultAdminRoute = connect(mapStateToPropsVaultAdmin)(vaultAdminCore)

export default VaultAdminRoute
