import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const jfzLogo = `${CLOUDFRONT_BASE}/images/branding/fromzero_logo_white.png`

interface ColorProps {
  color: string
}

interface BannerProps extends ColorProps {
  preview?: boolean
}

export const Banner = styled.div<BannerProps>`
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 16px;
  padding: 0 32px;
  height: 150px;
  background-color: ${({ color }) => color};
  color: ${({ color }) => color} !important;
  margin-top: 32px;
  opacity: ${({ preview }) => (preview ? 0.75 : 1 )};
`

export const BorderBox = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px;
  padding: 16px;
  border-radius: 10px;
  border: 8px solid ${({ color }) => color};
  color: ${({ color }) => color};
`

export const ChibiContainer = styled.div`
  position: relative;
  top: -30px;
  width: 150px;
`

export const Chibi = styled.img`
  position: absolute;
  clip-path: inset(0 0 79px 0);
`

export const JZFLogo = styled.div`
  background-image: url(${jfzLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 150px;
  height: 150px;
`

export const SlideTitle = styled.h2`
  background-color: white;
  font-size: 3rem !important;
  align-self: center;
  padding: 8px;
  text-align: center;
  font-family: 'Milliard Bold';
`

export const Slides = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 6fr 4fr;
  background-color: white;
`

export const Slide = styled.section`
  display: grid;
  align-self: start;
`

export const LanguageStack = styled.div<ColorProps>`
  display: grid;
  border-bottom: 5px solid ${({ color }) => color};
  align-items: center;
  padding: 32px;
`

export const ArrowLanguageStack = styled.div<{ position: string }>`
  display: grid;
  justify-content: ${({ position }) => position};
  margin-bottom: 32px;
`

export const ArrowLanguageRow = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  gap: 32px;
  align-items: baseline;
`

export const Arrow = styled(motion.div)<ColorProps>`
  width: 75px;
  font-size: 4rem;
  text-align: center;
  transform: scale(1.5, 3);
  color: ${({ color }) => color};
`

export const SlidePlaceholder = styled.div`
  background-color: #f6a9be3b;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 1rem;
  display: grid;
  place-content: center;
  text-align: center;
`

export const SlideSubtitle = styled.h3`
  font-size: 3rem !important;
`

export const ViewSlideshow = styled.button`
  background-color: #28c6aa;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #1e9e7f;
  }
`

export const English = styled(motion.p)<ColorProps>`
  font-size: 2rem;
  color: ${({ color }) => color};
`

export const PresentationTitle = styled.h2`
  font-size: 3rem !important;
  font-family: cursive;
  color: #555;
`

export const PresentationSubtitle = styled.h3`
  font-size: 1.5rem !important;
  font-family: cursive;
  padding-bottom: 16px;
  color: #777;
`
