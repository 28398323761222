import React, { useState } from 'react'
import axios from '../../../../api'

import { Button, Icon } from 'semantic-ui-react'

const RefreshWidgetCacheButton = (props) => {
  const { widget } = props
  const [checkmark, setCheckmark] = useState(false)
  const [error, setError] = useState(false)

  const refreshCache = (polly = false, force = false) => {
    axios.put(`/widgets/${widget}/refresh_cache`, { polly, force })
      .then((res) => {
        if (res.data.success) {
          setCheckmark(true)
          setError(false)
        } else {
          setError(true)
          setCheckmark(false)
        }
      })
  }

  return (
    <>
      <Button color='purple' onClick={() => refreshCache()}>
        Refresh Cache {checkmark && <Icon name='checkmark' />}{error && <Icon name='error' />}
      </Button>
      <Button color='purple' onClick={() => refreshCache(true)}>
        Add Polly {checkmark && <Icon name='checkmark' />}{error && <Icon name='error' />}
      </Button>
      <Button color='purple' onClick={() => refreshCache(true, true)}>
        Add Polly (Force) {checkmark && <Icon name='checkmark' />}{error && <Icon name='error' />}
      </Button>
    </>
  )
}

export default RefreshWidgetCacheButton
