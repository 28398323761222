import { useEffect } from 'react'

const useHandleKeyDown = (key, modifier, callback) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === key && e[`${modifier}Key`]) {
        callback()
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [key, modifier, callback])
}

export default useHandleKeyDown
