import React, { useEffect, useMemo, useState } from 'react'
import axios from 'src/api'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { DOWNLOAD } from '../toolkit.constants'
import { DownloadWidget } from 'src/types'

import { EmbedBlock, EmptyPadding, ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { ToolFooter, ToolHeader, ToolInput, ToolOptions } from '../parts'
import ToolForm from '../parts/ToolForm.part'
import { Dropdown, DropdownItem, DropdownMenu, Embed } from 'semantic-ui-react'
import useWidgetSaved from '../hooks/useWidgetSaved'

const DownloadTool: React.FC<ToolProps<DownloadWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson
}) => {
  const [saved, setSaved] = useState(false)
  const [videoEmbedActive, setVideoEmbedActive] = useState(false)
  const [s3URL, setS3URL] = useState()

  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<DownloadWidget>(id)
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const hookFormMethods = useForm()

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    fetchWidget()
    setSaved(true)
    fetchSubLesson?.()
  }

  const getAWSPath = async (url: string) => {
    const thumbnail = await axios.post('user/signed', { url })
    setS3URL(thumbnail.data.url)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      hookFormMethods.setValue('s3_path', widget.s3_path)
      hookFormMethods.setValue('title', widget.title)
      hookFormMethods.setValue('preview_thumb', widget.preview_thumb)
      setWidgetValues(widget)

      if (widget.preview_thumb) getAWSPath(widget.preview_thumb)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const handleS3Path = (e: React.ChangeEvent<HTMLInputElement>) => {
    hookFormMethods.setValue('s3_path', e.target.value)
  }

  const handlePreviewThumb = (e: React.ChangeEvent<HTMLInputElement>) => {
    hookFormMethods.setValue('preview_thumb', e.target.value)
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={DOWNLOAD}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={DOWNLOAD}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              {/* <ToolInput
                value={'romajiOnly'}
                inputType='checkbox'
                inputLabel='Romaji Mode Only'
                labelColor='var(--blue-gray)'
                labelPosition='after'
              /> */}
            </div>
          </ToolOptions>
          <ToolBody>
            <EmptyPadding />
            {/* <EmbedBlock>
              <Embed
                tabIndex='0'
                active={videoEmbedActive}
                onClick={() => setVideoEmbedActive((prev) => !prev)}
                onKeyUpCapture={(e) => e.key === 'Enter' && setVideoEmbedActive((prev) => !prev)}
                id={id}
                source='youtube'
                placeholder={
                  hookFormMethods.getValues()['yt_id'] === ''
                    ? vaultThumbURL ?? `https://img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`
                    : `https://img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`
                }
                autoplay={true}
                className='no-select'
                iframe={{ allowFullScreen: true }}
              />
            </EmbedBlock> */}
            {s3URL && (
              <div>
                <img src={s3URL} alt='preview' />
              </div>
            )}
            <ToolInput
              value='s3_path'
              placeholder='S3 Path'
              width='70%'
              onChange={handleS3Path}
            />
            <ToolInput
              value='preview_thumb'
              placeholder='Preview Thumbnail'
              width='70%'
              onChange={handlePreviewThumb}
            />
            <EmptyPadding />
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => handleSaveWidget()}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          />
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default DownloadTool
