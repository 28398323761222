import { useState, useEffect } from 'react'
import useAPI from 'src/hooks/useAPI'
import { useParams } from 'react-router-dom'
import { PathParams } from 'src/components/fz_courses/sublessonpage/SublessonPage'
import { Verb } from '../VerbData'
import { conjugate, conjugateAlternates, getGrammarData, Conjugation } from '../ConjugationData'
import { getAvailableVerbs, getAvailableGrammar, getHint } from './util'
import { GrammarResponse, VerbResponse, VerbData, GrammarSelection, VerbSelection, Location, Options, ConjugationData } from './types'
import _ from 'lodash'

const useConjugation = ({ grammar = 'lesson', verbs = 'learned', location, unused }: Options): ConjugationData => {
  const params = useParams<PathParams>()

  //
  // ----- STATE -----
  //

  // stateify passed in options
  const [selectedGrammar, setSelectedGrammar] = useState<GrammarSelection>(grammar)
  const [selectedVerbs, setSelectedVerbs] = useState<VerbSelection>(verbs)
  const [selectedLocation, setSelectedLocation] = useState<Location>(location ?? { course: params.course, lesson: params.lesson })
  // general state
  const [availableGrammar, setAvailableGrammar] = useState<Conjugation[]>([])
  const [availableVerbs, setAvailableVerbs] = useState<Verb[]>(() => getAvailableVerbs(selectedVerbs, selectedLocation))
  const [usedGrammar, setUsedGrammar] = useState<Conjugation[]>([])
  const [usedVerbs, setUsedVerbs] = useState<Verb[]>([])
  const [queuedVerbData, setQueuedVerbData] = useState<VerbData>()
  const [verbData, setVerbData] = useState<VerbData>()
  // grammar/verb requests
  const [grammarResponse] = useAPI<GrammarResponse>('/games/vcg/grammar')
  const [verbResponse, , fetchVerb] = useAPI<VerbResponse>('/games/vcg/next-verb', 'POST')

  //
  // ----- FUNCTIONS -----
  //

  const selectGrammar = () => {
    let reset = false
    let remainingGrammar = availableGrammar.filter((g) => !usedGrammar.includes(g))
    
    if (!remainingGrammar.length) {
      remainingGrammar = availableGrammar
      reset = true
    }

    const nextGrammar = _.sample(remainingGrammar)
    setUsedGrammar((p) => reset ? [nextGrammar] : [...p, nextGrammar])

    return nextGrammar
  }

  const selectVerb = (nextGrammar) => {
    let reset = false
    const { grammar: grammarList } = grammarResponse
    
    let remainingVerbs = availableVerbs.filter((v) => !usedVerbs.includes(v) && grammarList[nextGrammar].includes(v))

    if (!remainingVerbs.length) {
      remainingVerbs = availableVerbs
      reset = true
    }

    const nextVerb = _.sample(remainingVerbs.filter((v) => grammarList[nextGrammar].includes(v)))
    setUsedVerbs((p) => reset ? [nextVerb] : [...p, nextVerb])

    return nextVerb
  }

  const fetchNextVerbData = () => {
    const nextGrammar = selectGrammar()
    const nextVerb = selectVerb(nextGrammar)
    const conjugated = conjugate(nextVerb, nextGrammar)

    fetchVerb({ verb: nextVerb, grammar: nextGrammar, conjugation: conjugated })
  }

  const loadNextVerb = () => {
    setVerbData(queuedVerbData)
    fetchNextVerbData()
  }

  //
  // ----- EFFECTS -----
  //
  
  useEffect(() => {
    setAvailableVerbs(getAvailableVerbs(selectedVerbs, selectedLocation))
  }, [selectedVerbs, selectedLocation])

  useEffect(() => {
    if (!grammarResponse) return

    setAvailableGrammar(getAvailableGrammar(selectedGrammar, selectedLocation, grammarResponse, availableVerbs))
  }, [grammarResponse, selectedGrammar, availableVerbs]) // availableVerbs updates when selectedLocation changes, so no need to watch location

  useEffect(() => {
    if (!availableGrammar.length || unused) return

    const { key: queuedVerb, grammar: queuedGrammar } = queuedVerbData ?? {}

    const verbIsAvailable = availableVerbs.includes(queuedVerb)
    const grammarIsAvailable = availableGrammar.includes(queuedGrammar)
    const shouldFetch = (!queuedVerbData || !verbIsAvailable || !grammarIsAvailable)

    if (shouldFetch) fetchNextVerbData()
  }, [availableGrammar]) // availableGrammar updates when availableVerbs change, so no need to watch verbs

  useEffect(() => {
    if (!verbResponse?.success) return

    const { key, grammar: conjugation } = verbResponse
    const hint = getHint(key, conjugation)
    const correctAnswers = [
      conjugate(key, conjugation, 'kana'),
      conjugate(key, conjugation, 'kanji'),
      ...conjugateAlternates(key, conjugation, 'kana'),
      ...conjugateAlternates(key, conjugation, 'kanji')
    ]
    const conjugationData = getGrammarData(conjugation)

    const nextVerbData = { ...verbResponse, hint, correctAnswers, conjugationData }

    // first time, set verbData and fetch again for queuedVerbData
    if (!verbData) {
      setVerbData(nextVerbData)
      fetchNextVerbData()
    // otherwise, just set queuedVerbData
    } else setQueuedVerbData(nextVerbData)
  }, [verbResponse])

  return {
    verbData,
    loadNextVerb
  }
}

export default useConjugation
