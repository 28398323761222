import styled from 'styled-components'
import tw from 'twin.macro'

const FullWidthDiv = styled.div`
  ${tw`tw-w-full`}
`

const FullWidthFlex = styled(FullWidthDiv)`
  ${tw`tw-flex`}
`

const FullWidthFlexColStart = styled(FullWidthFlex)`
  ${tw`tw-flex-col tw-items-start`}
`

const FullWidthFlexRowStart = styled(FullWidthFlex)`
  ${tw`tw-flex-row tw-items-start`}
`

// eslint-disable-next-line import/prefer-default-export
export const NestedTool = styled(FullWidthDiv)`
  ${tw`tw-border-gray-200 tw-rounded-lg tw-shadow-md`}
`

export const WidgetOptions = styled(FullWidthFlexRowStart)`
  ${tw`tw-flex-wrap tw-justify-center tw-p-4 tw-gap-4`}
`
export const WidgetOption = styled.li`
  ${tw`tw-flex tw-items-center tw-rounded-lg tw-shadow-md tw-bg-white tw-mb-2`}
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
`

export const SampleViewer = styled(FullWidthDiv)`
  
`

export const SampleWidgetTypeText = styled.div`
  ${tw`tw-p-4`}
  >div>h3 {
    color: var(--blue-gray);
    font-family: 'Jost', sans-serif;
  }
`

export const SampleWidgetView = styled(FullWidthDiv)`
  ${tw`tw-h-full`}
`

export const WidgetSelection = styled.div`
  ${tw`tw-w-3/4 tw-mx-auto tw-flex tw-flex-col tw-justify-start tw-items-center`}
`

export const OptionPortal = styled.div<{ shrunk: boolean }>`
  ${tw`tw-border-2 tw-border-dashed tw-border-gray-300 tw-rounded-lg tw-p-3 tw-bg-white tw-mx-auto tw-w-full`}

  >div {
    transform: ${({ shrunk }) => shrunk && 'scale(0.8)'};
  }
`

export const WidgetSelectGrid = styled(FullWidthFlexColStart)`
  justify-content: flex-start;
  align-items: center;

  div:first-child {
    min-width: 25%;
  }
`

export const WidgetSelectButton = styled.button`
  ${tw`tw-border tw-py-2 tw-px-4 tw-mb-4 tw-rounded-md tw-shadow-sm tw-inline-flex tw-justify-center tw-border-transparent tw-rounded-md tw-text-white tw-bg-indigo-500 tw-self-center hover:tw-bg-indigo-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500`}

  font-size: 1.3rem;

  :disabled {
    ${tw`tw-bg-gray-100 tw-border-gray-300 tw-text-gray-400`}
  }
`

export const Modal = styled.div`
  ${tw`tw-p-4 tw-fixed tw-inset-24 tw-bg-white tw-shadow-xl tw-rounded-xl tw-overflow-y-scroll`}
  z-index: 10001;
`

export const ChangeCellWidget = styled.button`
  ${tw`tw-p-4 tw-mt-4 tw-mx-auto tw-border-2 tw-border-dashed tw-rounded-lg tw-shadow-xl tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2 tw-cursor-pointer tw-text-white hover:tw-text-slate-800`}
  background-color: var(--buttons);
  border-color: var(--dark-border);

  font-size: 1.3rem;
  i { color: white; }

  :hover {
    background-color: var(--mid-panel);
    border-color: var(--mid-border);

    i { color: var(--blue-gray); }
  }
`
