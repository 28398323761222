import { useContext } from 'react'
import { SubscriptionContext } from 'src/contexts/SubscriptionContext'
import usePlan from 'src/hooks/subscriptions/usePlan'
import fromzero_premium from 'src/assets/subscription/fromzero_premium.svg'
import { Header, CTA, FZPrice } from './styles/FlowHeader.styles'

const messages = {
  landing: '',
  course: 'Subscribe now to access this lesson!',
  aat: 'Subscribe now to get your questions answered by our Japanese teachers!',
  vault: 'Subscribe now to access the Vault!',
}

const FlowHeader = ({ location }) => {
  const { chosenPlan, affiliate } = useContext(SubscriptionContext)
  const { planId: plan, price } = usePlan(chosenPlan)
  const cta = messages[location] ?? 'Subscribe now to access Premium content!'

  return (
    <Header>
      <CTA>{cta}</CTA>
      {plan && (
        <FZPrice isMobile={false} >
          <img src={fromzero_premium} />
          <div>
            {chosenPlan === 'premium-monthly'
              ? <>
                <h2 style={{ fontFamily: 'Jost' }}><span>{price}</span>/mo.</h2>
                <p>{!affiliate?.internal ? 'Billed Monthly' : 'for your first month'}</p>
              </>
              : <>
                <h2 style={{ fontFamily: 'Jost' }}><span>{price}</span>/yr.</h2>
                <p>Billed Annually</p>
              </>
            }
          </div>
        </FZPrice>
      )}
    </Header>
  )
}

export default FlowHeader
