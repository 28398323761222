import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Transition } from '@headlessui/react'
// import { Obscured } from 'src/assets/styles/DefaultModalStyle'
import SubSuccessStyles from './SubSuccessStyles'
import styled from 'styled-components'

import tomoko_cheer from 'src/assets/chibi/jfz_chibi_tomoko_2.png'
import Confetti from 'react-dom-confetti'
import { Button } from 'semantic-ui-react'
import FZExclamation from 'src/components/FZExclamation'

const ClickHereButton = styled(Link)`
  color: white;
  background-color: var(--jfz-blue);
  border: 1px solid var(--jfz-blue);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  font-weight: 600;
  text-decoration: none !important;
  margin-top: 1rem;

  &:hover {
    background-color: #88b4e0;
    border: #88b4e0;
    color: white !important;
  }
`

interface TomokoProps {
  showing: boolean
  mainText?: string
}

const TomokoSubSuccess: React.FC<TomokoProps> = ({
  showing,
  mainText = 'Your premium subscription starts now!',
}) => {
  const [showTomoko, setShowTomoko] = useState<boolean>(false)
  const [confettiActive, setConfettiActive] = useState(false)

  useEffect(() => {
    if (!showing) return
    setTimeout(() => {
      setShowTomoko(true)
    }, 250)
  }, [showing])

  useEffect(() => {
    if (showTomoko) return
    setTimeout(() => {
      setShowTomoko(true)
    }, 700)
  }, [showTomoko])

  const confetti_config = {
    angle: 90,
    spread: 210,
    startVelocity: 35,
    elementCount: 100,
    dragFriction: 0.15,
    duration: 5000,
    stagger: 10,
    width: '15px',
    height: '8px',
    perspective: '1000px',
    colors: ['#88b4e0', '#ef6960', '#74be72', '#b772b3', '#d76c8d'], // colors of JFZ 1 - 5
  }

  useEffect(() => {
    if (!confettiActive) return
    setTimeout(() => {
      setConfettiActive(false)
    }, 3000)
  }, [confettiActive])

  const confettiPopper = () => {
    if (confettiActive) return
    setConfettiActive(true)
  }

  const resetAnimations = () => {
    setShowTomoko(false)
    setConfettiActive(false)
  }

  if (!showing) return <></>

  return (
    <SubSuccessStyles>
      <div className='tomoko-success'>
        <div className='from-zero-thanks-you'>
          {/* <Button
            className='reset-animations'
            onClick={() => resetAnimations()}
            disabled={confettiActive}
            style={{
              cursor: confettiActive ? 'wait' : 'pointer',
            }}
            icon='refresh'
          /> */}
          {/* <Button
            className='close-popup'
            onClick={() => closePopup()}
            icon='close'
          /> */}
          <Transition
            show={showTomoko}
            appear={true}
            enter='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
            enterFrom='tw-translate-x-[-400px] tw-opacity-0'
            enterTo='tw-translate-x-0 tw-opacity-full'
            leave='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
            leaveFrom='tw-translate-x-0 tw-opacity-full'
            leaveTo='tw-translate-x-[-400px] tw-opacity-0'
          >
            <div>
              <FZExclamation color='blue' title='Thank You' />
              <h3 style={{ textAlign: 'center' }}>{mainText}</h3>
              <p style={{ textAlign: 'center' }}>
                <ClickHereButton
                  to='/home'
                >
                  Click here to get started
                </ClickHereButton>
              </p>
            </div>
          </Transition>
        </div>
        <Transition
          as='div'
          className='tomoko'
          show={showTomoko}
          appear={true}
          enter='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
          enterFrom='tw-translate-y-[400px] tw-translate-x-[200px] tw-opacity-0'
          enterTo='tw-translate-y-0 tw-translate-x-0 tw-opacity-full'
          leave='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
          leaveFrom='tw-translate-y-0 tw-translate-x-0 tw-opacity-full'
          leaveTo='tw-translate-y-[400px] tw-translate-x-[200px] tw-opacity-0'
          afterEnter={() => confettiPopper()}
        >
          <img
            src={tomoko_cheer}
            alt='Cheerleader Tomoko'
            className='no-select'
            draggable={false}
          />
        </Transition>
      </div>

      <div style={{ position: 'absolute', top: '40%', left: '30%' }}>
        <Confetti active={confettiActive} config={confetti_config} />
      </div>
      <div style={{ position: 'absolute', top: '40%', right: '30%' }}>
        <Confetti active={confettiActive} config={confetti_config} />
      </div>
    </SubSuccessStyles>
  )
}

export default TomokoSubSuccess
