import React from 'react'

import { Icon } from 'semantic-ui-react'
import useSublessonStore from '../../../../../stores/sublesson.store'
import { AddWidget } from '../styles'
import { PageType } from 'src/components/pages/blog/BlogEditor'

interface AddWidgetButtonProps {
  index: number
  partId: string
  page?: PageType
  sectionIdx?: number
}

const AddWidgetButton: React.FC<AddWidgetButtonProps> = ({
  index,
  partId,
  page,
  sectionIdx,
}) => {
  const setUpWidgetPalette = () => {
    useSublessonStore.setState({
      newWidgetPosInfo: { insertAt: index, partId, page, sectionIdx },
      widgetPaletteOpen: true,
    })
  }

  return (
    <AddWidget>
      <div className='dash'></div>
      <button
        type='button'
        className='flex-center'
        onClick={() => setUpWidgetPalette()}
      >
        <Icon
          fitted
          name='plus circle'
        />
      </button>
    </AddWidget>
  )
}

export default AddWidgetButton
