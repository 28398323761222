import React from 'react'
import { Icon } from 'semantic-ui-react'
import { ListSectionDivider } from '../styles'
import { AddRowButton, PillText } from '../styles/Tool.styles'

interface InputDividerProps {
  handleClicked: () => void
  borderStyle?: string
  buttonLeftShift?: string
  title?: string
  buttonAsText?: boolean
  buttonTextHShift?: number
  noBgFill?: boolean
  grid?: boolean
}

const ListInputDivider: React.FC<InputDividerProps> = ({
  handleClicked,
  buttonLeftShift,
  title,
  borderStyle = 'solid',
  buttonAsText = false,
  noBgFill = false,
  buttonTextHShift = 0,
  grid = false,
}) => {
  return (
    <ListSectionDivider borderStyle={borderStyle} grid={grid}>
      <AddRowButton
        type='button'
        onClick={() => handleClicked()}
        title={title || 'Add row'}
        style={{ left: buttonLeftShift ?? '' }}
        noBgFill={noBgFill}
      >
        {buttonAsText ? (
          <PillText vShift={-4} hShift={buttonTextHShift}>
            {title}
          </PillText>
        ) : (
          <Icon name='plus circle' size='large' fitted />
        )}
      </AddRowButton>
    </ListSectionDivider>
  )
}

export default ListInputDivider
