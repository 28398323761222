import {
  BUY_COURSE,
  CHANGE_COURSE_BACKGROUND,
  CHANGE_PREFERRED_SPEAKER,
  CHANGE_PROGRESSIVE,
  CHANGE_SETTING,
  CHANGE_SETTINGS,
  CHANGE_STUDY_MODE,
  LOAD_COURSES,
  LOAD_SUBS,
  SIGN_IN,
  SIGN_OUT,
  SET_AVATAR_CF,
  SET_BADGES,
  SET_DEFAULT_AVATAR,
  SET_OWN_AVATAR,
  SET_POPUP,
  SET_ROLES,
  SUBSCRIBE,
  SWITCH_COLORIZED_FURI,
  SWITCH_CUSTOM_PROGRESSIVE,
  TOGGLE_DARK_MODE,
  TOGGLE_V8_PROGRESSIVE,
  UNSET_BADGES,
  UNSET_OWN_AVATAR,
  UPDATE_HISTORY,
  SET_USER_AGENT,
} from './types'

export * from './NavigatorActions'

export const signIn = (userData) => {
  return {
    type: SIGN_IN,
    payload: userData,
  }
}

export const signOut = () => {
  return {
    type: SIGN_OUT,
  }
}

export const loadCourses = (courses) => {
  return {
    type: LOAD_COURSES,
    payload: courses,
  }
}

export const buyCourse = (userData) => {
  return {
    type: BUY_COURSE,
    payload: userData,
  }
}

export const subscribe = () => {
  return {
    type: SUBSCRIBE,
  }
}

export const dispatchSetting = (setting) => {
  return {
    type: CHANGE_SETTING,
    payload: setting,
  }
}

export const dispatchProgressive = (data) => {
  return {
    type: CHANGE_PROGRESSIVE,
    payload: data,
  }
}

export const loadSubs = (subs) => {
  return {
    type: LOAD_SUBS,
    payload: subs,
  }
}

export const setOwnAvatar = (url) => {
  return {
    type: SET_OWN_AVATAR,
    payload: url,
  }
}
export const unsetOwnAvatar = () => {
  return {
    type: UNSET_OWN_AVATAR,
    payload: null,
  }
}

export const setBadges = (badges) => {
  return {
    type: SET_BADGES,
    payload: badges,
  }
}

export const unsetBadges = () => {
  return {
    type: UNSET_BADGES,
    payload: null,
  }
}

export const setDefaultAvatar = ({ color, version }) => ({
  type: SET_DEFAULT_AVATAR,
  payload: { color, version },
})

export const changeCourseBackground = (payload) => {
  return {
    type: CHANGE_COURSE_BACKGROUND,
    payload,
  }
}

export const setRoles = (payload) => {
  return {
    type: SET_ROLES,
    payload,
  }
}

export const setPopup = (popupInfo) => {
  return {
    type: SET_POPUP,
    payload: popupInfo,
  }
}

export const updateHistory = (payload) => {
  return {
    type: UPDATE_HISTORY,
    payload,
  }
}

export const setAvatarCF = (payload) => {
  return {
    type: SET_AVATAR_CF,
    payload,
  }
}

export const changeStudyMode = (payload) => {
  return {
    type: CHANGE_STUDY_MODE,
    payload
  }
}

export const changePreferredSpeaker = (payload) => {
  return {
    type: CHANGE_PREFERRED_SPEAKER,
    payload
  }
}

export const switchColorizedFuri = (payload) => {
  return {
    type: SWITCH_COLORIZED_FURI,
    payload
  }
}

export const switchCustomProgressive = (payload) => {
  return {
    type: SWITCH_CUSTOM_PROGRESSIVE,
    payload
  }
}

export const toggleV8Progressive = (payload) => {
  return {
    type: TOGGLE_V8_PROGRESSIVE,
    payload
  }
}

export const changeSettings = (payload) => {
  return {
    type: CHANGE_SETTINGS,
    payload
  }
}

export const toggleDarkMode = () => ({ type: TOGGLE_DARK_MODE })
export const setUserAgent = (payload) => ({ type: SET_USER_AGENT, payload })
