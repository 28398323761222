import React, { useEffect, useState } from 'react'

import { Header, Table } from 'semantic-ui-react'

const ConversationPreview = props => {
    return (
        <>
            {props.data.map(c =>
                <>
                    <Header as='h4'>{c.title}</Header>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell>Japanese</Table.HeaderCell>
                            <Table.HeaderCell>English</Table.HeaderCell>
                        </Table.Header>
                        <Table.Body>
                            {c.other_lang.map((ol, idx) =>
                                <Table.Row>
                                    <Table.Cell>{ol}</Table.Cell>
                                    <Table.Cell>{c.english[idx]}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </>
            )}
        </>
    )
}

export default ConversationPreview