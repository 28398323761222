import React, { useEffect, useState } from 'react'

import styled, { css } from 'styled-components'
import { Button, Table } from 'semantic-ui-react'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundInjector from '../admin/editor/SoundInjector'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'
import useWidget from '../hooks/useWidget'

import getCourseInfo, { getTitle } from '../../../util/courseInfo'

const UsageWidgetContainer = styled.div`
  position: relative;
  margin-top: ${({ hasTag, isMobile }) => !hasTag && (isMobile ? '8px' : '20px')};
  margin-bottom: ${({ isMobile }) => isMobile ? '8px' : '20px'};
  font-family: 'Milliard Book', 'Yu Gothic UI Regular';
  box-shadow: 0;
  border: 3px solid #51536b;
  background-color: ${({ isMobile }) => isMobile ? '' : 'white'};
`

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 2px 15px;
  font-size: ${({ isMobile }) => isMobile ? '1.1rem' : '1.4rem'};
  font-family: 'Milliard Bold';
  background-color: var(--blue-gray);
  color: var(--off-white);
`

const UsageHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  font-weight: 600;
  border-bottom: 1px solid #51536b;
`

const HeaderText = styled.p`
  padding: ${({ isMobile }) => isMobile ? '1rem' : '1rem 3rem'};
  background-color: ${({ color }) => color};
  color: var(--off-white);
`

const HeaderMeaning = styled.p`
  justify-self: center;
  padding-left: 0.5rem;
`

const HeaderUsageType = styled.p`
  padding: ${({ isMobile }) => isMobile ? '1rem 0.5rem' : '1rem 2rem'};
  border-left: 1px solid #51536b;
`

/* eslint-disable react/prop-types */
const UsageWidget = ({ course, widget, id }) => {
  const [tag, setTag] = useState(widget?.tag)
  const [text, setText] = useState(widget?.text)
  const [meaning, setMeaning] = useState(widget?.meaning)
  const [usageType, setUsageType] = useState(widget?.usageType)
  const [examples, setExamples] = useState(widget?.examples)
  const [playsSound, setPlaysSound] = useState(false)
  const isMobile = useMobile()

  const courseColor = getCourseInfo(getTitle(course)).alt_color_1
  const courseColor2 = getCourseInfo(getTitle(course)).alt_color_2

  useEffect(() => {
    if (!widget) return

    // eslint-disable-next-line
    const { tag, text, meaning, examples, usage_type, plays_sound } = widget
    setTag(tag)
    setText(text)
    setMeaning(meaning)
    setUsageType(usage_type)
    setExamples(examples)
    setPlaysSound(plays_sound)
  }, [widget])

  console.log(widget)

  return (
    <WidgetWrapper widget={{ widgetType: 'UsageWidget', widgetId: id }}>
      {tag && (
        <Tag isMobile={isMobile}>{tag}</Tag>
      )}
      <UsageWidgetContainer isMobile={isMobile} hasTag={tag}>
        <UsageHeader className="fz-colored-font">
          <HeaderText color={courseColor2} isMobile={isMobile}>
            <SoundRenderer text={text} />
          </HeaderText>
          <HeaderMeaning><SoundRenderer text={meaning} /></HeaderMeaning>
          {usageType && <HeaderUsageType isMobile={isMobile}>
            <SoundRenderer text={usageType} />
          </HeaderUsageType>}
        </UsageHeader>
        {examples?.length > 0 && (
          <Table
            className="fz-dark-font"
            style={{ fontSize: '1.25rem', width: '100%', margin: '0' }}
          >
            <tbody>
              {examples.map((e, idx) => (
                <Table.Row key={`usage-${idx}`}>
                  <Table.Cell><SoundRenderer text={e.other_lang} /></Table.Cell>
                  <Table.Cell><SoundRenderer text={e.english} /></Table.Cell>
                </Table.Row>
              ))}
            </tbody>
          </Table>
        )}
      </UsageWidgetContainer>
    </WidgetWrapper>
  )
}

export default UsageWidget
