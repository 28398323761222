import React, { useEffect, useState } from 'react'
import axios from 'src/api'
import { AxiosError, AxiosResponse } from 'axios'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { IMAGE } from '../toolkit.constants'
import { ImageWidget } from 'src/types'

import { ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import ToolImageUploader from '../parts/ToolImageUploader.part'
import { ToolFooter, ToolForm, ToolHeader, ToolOptions } from '../parts'
import useWidgetSaved from '../hooks/useWidgetSaved'

const ImageTool: React.FC<ToolProps<ImageWidget>> = ({
  course,
  lesson,
  sublesson,
  embed,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [uploadingImage, setUploadingImage] = useState(false)
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<ImageWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const handleSaveWidget = (path?: string, idx?: number) => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    if (path) {
      /**
       * why `idx - 1`?
       * index will be one higher than expected because of a janky
       * workaround to maintain image widget backwards compatibility but also
       * allow for paired images to be appended
       */
      if (idx > 0) values.path = values.pairedImages[idx - 1].path
      else values.path = path
    }
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  const updateWidgetImage = async (incomingImage: File, idx: number): Promise<boolean> => {
    console.log(incomingImage)
    if (!hookFormMethods.formState.isValid || uploadingImage) return
    setUploadingImage(() => true)

    const _path = `courses/images/${course}/${lesson}/${sublesson}${embed ? `/${embed}` : ''}`

    const imageName = incomingImage.name.replaceAll(' ', '_')

    const _data = new FormData()
    _data.append('location', _path)
    _data.append('image', incomingImage, `${id}-${imageName}`)
    _data.append('mimetype', incomingImage.type)

    // output all of _data
    _data.forEach((value, key) => {
      console.log(`${key}: ${value}`)
    })

    let _response: AxiosResponse
    try {
      _response = await axios.post(
        '/images',
        _data,
        { headers: { 'Content-Type': 'multipart/form-data;' } }
      )
    } catch (err) {
      const _error = err as AxiosError
      console.error(_error.stack)
      console.warn(_error.message)
      console.warn(_error.name)
    }
    if (_response.data.success) {
      handleSaveWidget(_response.data.path, idx)
    }
    setUploadingImage(() => false)
    return _response.data.success
  }

  const handleFieldError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.error(e)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setWidgetValues(widget)
    }

    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={IMAGE}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      fetchCounter={fetchCounter}
    >
      <div className='image-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={IMAGE}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell} />
          <ToolBody style={{ flexDirection: 'row' }}>
            <ToolImageUploader
              currentImage={{
                path: widget?.path,
                title: widget?.title,
                caption: widget?.caption,
                height: widget?.height,
              }}
              flexDirection='row'
              // flexDirection={widget?.pairedImages?.length ? 'column' : 'row'}
              handleFieldError={handleFieldError}
              index={0}
              updateWidgetImage={updateWidgetImage}
            />
            {/* {widget?.pairedImages?.map((pairedImage, pidx: number) => (
              <ToolImageUploader
                key={pidx}
                currentImage={pairedImage}
                flexDirection='column'
                handleFieldError={handleFieldError}
                index={pidx + 1}
                updateWidgetImage={updateWidgetImage}
              />
            ))} */}
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          />
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default ImageTool
