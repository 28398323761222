import React, { useEffect } from 'react'
import useCF from 'src/hooks/useCF'
import { ImageRendererImage } from './Answers.styles'
import { ImageRendererProps } from '../types'

const ImageRenderer: React.FC<ImageRendererProps> = ({ src, customStyles }) => {
  const [cfPath, getCfPath] = useCF()

  useEffect(() => {
    if (typeof src !== 'string' || !src?.trim().length) return
    getCfPath(src)
  }, [src])

  return <ImageRendererImage src={cfPath} alt='quiz image' customStyles={customStyles} />
}

export default ImageRenderer
