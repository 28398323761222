import { Icon, Ref } from 'semantic-ui-react'

const PlayButton = ({ playAudio, playing, record, huge, iconOnly = false, soundQuestion = false, innerRef = null, isMobile }) => {
  const styles = {
    cursor: 'pointer',
    color: 'var(--off-white)',
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: Object.keys(record.sounds || [])?.length > 0
      ? (playing
        ? '#e83563'
        : '#28c6aa'
      )
      : '#c1d7d3',
    boxShadow: 'none',
    fontSize: huge ? '2rem' : '0.75rem',
    position: 'relative',
    top: '-4px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  } as React.CSSProperties

  if (soundQuestion && styles) {
    styles.borderRadius = '28px'
    styles.padding = '12px 32px'
    styles.fontSize = `${isMobile ? '1.2rem' : '1.5rem'}`
  }

  // centers icon within bounding box
  if (iconOnly && huge) {
    styles.marginLeft = '12px'
    styles.top = 0
  }

  // no longer icon just by itself if question, also has label
  if (soundQuestion) {
    return (
      <Ref innerRef={innerRef}>
        <span
          style={styles}
          className="no-select FZPlayButton"
          onClick={(e) => playAudio(e, false)}
          onKeyUpCapture={(e) => e.key === 'Enter' && playAudio(e, true)}
        >
          <Icon
            style={{ marginTop: '-6px' }}
            // eslint-disable-next-line no-nested-ternary
            name={Object.keys(record.sounds)?.length > 0
              ? (playing
                ? 'stop'
                : 'play'
              )
              : 'warning'}
          />
          Play Sound
        </span>
      </Ref>
    )
  }

  return (
    <Ref innerRef={innerRef}>
      <Icon
        tabIndex="0"
        circular
        onClick={(e) => playAudio(e, false)}
        ref={innerRef}
        onKeyUpCapture={(e) => e.key === 'Enter' && playAudio(e, true)}
        className="no-select FZPlayButton"
        style={styles}
        // eslint-disable-next-line no-nested-ternary
        name={Object.keys(record.sounds || [])?.length > 0
          ? (playing
            ? 'stop'
            : 'play'
          )
          : 'warning'}
      />
    </Ref>
  )
}

export default PlayButton
