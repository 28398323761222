import React from 'react'

import { Divider, Grid } from 'semantic-ui-react'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import SoundRenderer from './SoundRenderer'

const KanjiMemoryWidget = ({ widget }) => {
  // console.log(widget)
  return (
    <WidgetWrapper widget={{ widgetId: widget?._id, widgetType: 'KanjiMemoryWidget' }}>
      <div
        className='fz-dark-font fz-colored-border'
        style={{
          fontFamily: 'Milliard Book, Yu Gothic UI Regular',
          fontSize: '1.25rem',
          backgroundColor: 'white',
          textAlign: 'center',
          justifyContent: 'center',
          alignItems: 'center',
          borderTop: '4px solid',
          borderBottom: '4px solid'
        }}
      >
        {/* kanji and readings, left side */}
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }} className='fz-sub-colored-background'>
          <div style={{ width: '100px', height: '100px', color: 'white' }} className='fz-colored-background'>
            <div style={{ fontSize: '4rem', fontWeight: 'bold', textAlign: 'center' }}>
              {widget?.kanji?.[0]?.text}
            </div>
          </div>
          <div style={{ flex: '1' }}>
            {/* build section (radicals on top, sentence below) */}
            <div>
              <div style={{ position: 'relative', paddingRight: '30px' }}>
                <div style={{ fontSize: '1.5rem' }}>
                  {Array.isArray(widget?.build) && (
                    <SoundRenderer
                      text={widget?.build?.map((b) => b.text).join('')}
                    />
                  )}
                  {typeof widget?.build === 'string' && (
                    <SoundRenderer
                      text={widget?.build}
                    />
                  )}
                </div>
                <div style={{ fontSize: '1.5rem' }}>
                  <SoundRenderer
                    text={widget?.build_sentence}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: '30px' }}>
          <div style={{ position: 'relative' }}>
            <div style={{ fontSize: '1.5rem' }}>
              <SoundRenderer
                text={widget?.read_other}
              />
            </div>
            <div style={{ fontSize: '1.5rem' }}>
              <SoundRenderer
                text={widget?.read_english}
              />
            </div>
          </div>
        </div>
      </div>
    </WidgetWrapper>
  )
}

export default KanjiMemoryWidget
