import { useState, useEffect, useRef, createContext } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { PathParams } from 'src/components/fz_courses/sublessonpage/SublessonPage'
import { generateQuizQuestions, generateShuffledAnswers } from './util'
import { MiniQuizWidget, MiniQuizQuestion, ConjugationData } from 'src/types'
import { Activity } from './types'
import { RootState } from 'src/configureStore'
import useConjugation from 'src/components/pages/games/conjugation_game/useConjugation/useConjugation'
import _ from 'lodash'

export const ActivityContext: React.Context<Activity> = createContext(null)

const useActivity = (widget: MiniQuizWidget) => {
  const { activity_type, subtype, conjugation_data } = widget

  // setting to pass into generateQuizQuestions()
  const isV8 = useSelector((state: RootState) => state.auth?.v8_progressive) ?? true
  const params = useParams<PathParams>()
  const options = { isV8, params }

  const [shuffledQuestions, setShuffledQuestions] = useState<MiniQuizQuestion[]>(() => generateQuizQuestions(widget, options))
  const [shuffledAnswers, setShuffledAnswers] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null)
  const [typedAnswer, setTypedAnswer] = useState('')
  const [answerSubmitted, setAnswerSubmitted] = useState(false)
  const [answeredCorrectly, setAnsweredCorrectly] = useState(false)
  const [commentary, setCommentary] = useState('')
  const [showCommentary, setShowCommentary] = useState(false)
  const [finished, setFinished] = useState(false)
  const [attempts, setAttempts] = useState(0)
  const [transitioning, setTransitioning] = useState(false) // test for animations
  const [inputWasFocused, setInputWasFocused] = useState(false) // only autofocus input types after user interacts to prevent page scroll
  const commentaryTimeoutID = useRef<ReturnType<typeof setTimeout> | null>(null)

  const isInputType = activity_type === 'input'
  const isKanaTyper = subtype === 'kana-typer'
  const isConjugation = subtype === 'conjugation'

  // conjugation data
  const conjugation_defaults: ConjugationData = { grammar_selection: 'lesson', verb_selection: 'lesson-learned', custom_grammar: [], custom_verbs: [] }
  const { grammar_selection, custom_grammar, verb_selection, custom_verbs } = conjugation_data || conjugation_defaults
  const grammarSelection = grammar_selection === 'custom' ? custom_grammar : grammar_selection
  const verbSelection = verb_selection === 'custom' ? custom_verbs : verb_selection
  const { verbData, loadNextVerb } = useConjugation({ grammar: grammarSelection, verbs: verbSelection, unused: !isConjugation })
  const { correctAnswers, hint, conjugationData } = verbData || {}
  const { form } = conjugationData || {}
  const conjugationQuestion = `Type the ${form ?? 'correct conjugation'} for the following verb`
  
  const question = shuffledQuestions[currentQuestion]
  const answer = isInputType ? shuffledAnswers[0] ?? {} : shuffledAnswers[selectedAnswer]

  const questionText = isConjugation ? conjugationQuestion : question?.question

  //
  // ----- DETERMINE IF ANSWER IS CORRECT -----
  //

  const conjugationAnswerIsCorrect = isConjugation && correctAnswers?.includes(typedAnswer) // kana formatted
  const inputAnswerIsCorrect = isInputType && answer?.answer?.includes(typedAnswer)
  const answerIsCorrect = conjugationAnswerIsCorrect || inputAnswerIsCorrect || answer?.isCorrect

  //
  // ----- DETERMINE COMMENTARY -----
  //

  const kanaTyperCommentary = isKanaTyper && (answerIsCorrect ? _.sample(answer?.correctCommentary) : answer?.commentary)
  const conjugationCommentary = isConjugation && (answerIsCorrect ? _.sample(answer?.correctCommentary) : hint ?? '')
  const answerCommentary = kanaTyperCommentary || conjugationCommentary || answer?.commentary

  const checkAnswer = () => {
    clearTimeout(commentaryTimeoutID.current)

    setAnswerSubmitted(true)
    setCommentary(answerCommentary)
    setAttempts((prev) => prev + 1)
    setShowCommentary(true)

    if (answerIsCorrect) {
      setAnsweredCorrectly(true)
    } else {
      setTimeout(() => {
        setSelectedAnswer(null)
        setAnswerSubmitted(false)
      }, 1000)

      commentaryTimeoutID.current = (setTimeout(() => {
        setShowCommentary(false)
      }, 3500))
    }
  }

  const nextQuestion = () => {
    setTransitioning(true)
    setCommentary('')
    setShowCommentary(false)
    setSelectedAnswer(null)
    setTypedAnswer('')
    setAnswerSubmitted(false)
    setAnsweredCorrectly(false)

    if (currentQuestion === shuffledQuestions.length - 1) {
      setCurrentQuestion(0) // set now to avoid 2 answer shuffles on reset (useEffect below)
      setFinished(true)
      return
    }

    // wait for animation to finish before setting next question
    setTimeout(() => {
      setCurrentQuestion((prev) => prev + 1)
      if (isConjugation) loadNextVerb()
    }, 500)
  }

  const checkOrNext = () => {
    if (answeredCorrectly) nextQuestion()
    else checkAnswer()
  }

  const resetActivity = () => {
    // some state is already reset in nextQuestion()
    setTransitioning(true)
    setShuffledQuestions(generateQuizQuestions(widget, options))
    setFinished(false)
    setAttempts(0)
    if (isConjugation) loadNextVerb()
  }

  useEffect(() => {
    if (!shuffledQuestions.length) return
    setShuffledAnswers(generateShuffledAnswers(question))
    setTransitioning(false)
  }, [shuffledQuestions, currentQuestion])
    
  return {
    subtype,
    question: questionText,
    questionType: question?.question_activity_type,
    questionImage: question?.question_image,
    questionSubdata: question?.question_subdata,
    conjugationData: verbData,
    answers: shuffledAnswers,
    currentQuestion,
    numberOfQuestions: shuffledQuestions.length,
    selectedAnswer,
    setSelectedAnswer,
    typedAnswer,
    setTypedAnswer,
    activityType: question?.activity_type,
    answerSubmitted,
    answeredCorrectly,
    commentary,
    showCommentary,
    checkOrNext,
    transitioning,
    finished,
    attempts,
    resetActivity,
    isBeta: isConjugation, // for now only conjugation is beta
    inputWasFocused,
    setInputWasFocused
  }
}

export default useActivity
