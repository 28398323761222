import styled from '@emotion/styled/macro'
import { css } from '@emotion/react/macro'
import { regFontSize, darkFont } from 'src/assets/styles/globalStyles'

interface StackVerticalProps {
  stackVertical: boolean
}

interface IsMobile {
  isMobile: boolean
}

interface QnATitleProps extends IsMobile {
  courseColor: string
}

export const QnATag = styled.div<IsMobile>`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--off-white);
  padding: 10px 53px;
  margin-bottom: 20px;
  margin-left: ${({ isMobile }) => isMobile ? '15px' : '45px'};
  border-radius: 15px;

  & > h2 {
    font-size: ${({ isMobile }) => isMobile ? 'var(--reg-font-size)' : '1.4rem'}
  }
`

export const QnATitle = styled.h2<QnATitleProps>`
  font-family: 'Milliard Book';
  color: ${({ courseColor }) => courseColor};
  font-size: ${({ isMobile }) => isMobile ? '1.3rem' : '1.8rem'} !important;
  padding: ${({ isMobile }) => isMobile ? '0 10px 24px' : '0 45px 24px'};
`

export const StyledBlock = styled.section`
  background-color: white;
`

interface BlockNumberProps {
  courseColor: string
  span2: boolean
}

export const BlockTitle = styled.h3`
  font-family: Poppins;
  font-size: 1.4rem !important;
  font-weight: bold;
  grid-row: 1;
  grid-column: 2 / -1;
  padding: 16px 0;
`

export const BlockNumber = styled.p<BlockNumberProps>`
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 30px;
  height: 30px;
  background-color: ${({ courseColor }) => courseColor};
  color: white;
  border-radius: 50px;
  padding: 0;
  ${({ span2 }) => span2 && 'grid-row: span 2'};
`

interface GridProps {
  isMobile: boolean
  stackVertical: boolean
}

const gridFormat = ({ isMobile, stackVertical }) => css`
  display: grid;
  grid-auto-flow: dense;
  background-color: white;
  align-items: center;
  padding: ${isMobile ? '0 10px' : '0 45px'};
  grid-template-columns: ${stackVertical ? '35px 75px 1fr' : '35px 75px 1fr 1fr'};
  grid-column-gap: 5px;
`

export const Question = styled.div<GridProps>`
  ${gridFormat}
  box-shadow: 0 18px 10px -20px rgba(214, 222, 248, 1),
              0 -18px 10px -20px rgba(214, 222, 248, 1);
  z-index: 2;
  position: relative;

  & > h6 {
    background-color: red;
  }
`

export const Answer = styled.div<GridProps>`
  ${gridFormat}
`

export const Answers = styled.div`
  margin-bottom: 2rem;
  box-shadow: 0 45px 10px -45px rgba(214, 222, 248, 1);
`

export const BlockComment = styled.p`
  ${regFontSize}
  ${darkFont}
  font-weight: bold;
  grid-column: 3 / -1;
  margin: 0 0 .5rem !important;
`

interface ImgProps {
  i: number
  stackVertical: boolean
  hasAside: boolean
}

const questionImgContainer = ({ hasAside, stackVertical }) => css`
  height: 65px;
  width: 65px;
  align-self: end;
  grid-row: ${hasAside && 'span 2'};
  grid-row: ${hasAside && stackVertical && 'span 3'};
`

const answersImgContainer = css`
  height: 50px;
  width: 50px;
  border-radius: 50%;
`

export const ImageContainer = styled.div<ImgProps>`
  ${({ stackVertical }) => stackVertical ? 'grid-row: span 2' : ''};
  justify-self: center;
  grid-column: 2;

  ${({ i }) => i === 0 ? questionImgContainer : answersImgContainer}
`

interface ChibiProps {
  i: number
  icon: string
  stackVertical: boolean
  hasAside: boolean
}

const adjustChibis = ({ i, icon }) => css`
  left: ${(i === 0 && icon === 'tomoko') && '-1px'};
  left: ${(i === 0 && icon === 'mari') && '-4px'};
`

const extendedChibi = css`
  bottom: 25px;
  clip-path: inset(0 0 34px 0);
`

const questionChibi = ({ stackVertical, hasAside }) => css`
  min-width: 72px;
  bottom: 7.5px;
  left: -5px;
  clip-path: inset(0 0 52px 0);

  ${(stackVertical || hasAside) && extendedChibi}
`

const answersChibi = css`
  min-width: 59px;
  left: -7px;
  top: -3px;
`

export const Chibi = styled.img<ChibiProps>`
  position: relative;
  ${({ i }) => i === 0 ? questionChibi : answersChibi}
  ${adjustChibis}
`

export interface EnglishProps {
  i: number
  stackVertical: boolean
}

export const English = styled.p<EnglishProps>`
  font-weight: ${({ i }) => i === 0 ? 900 : ''};
  padding-left: ${({ stackVertical }) => stackVertical && '25px'};
`

export interface OtherLangProps extends StackVerticalProps {
  i: number
}

export const OtherLang = styled.p<OtherLangProps>`
  padding-top: ${({ stackVertical }) => stackVertical && '10px'};
`

export const QnaAside = styled.aside<GridProps>`
  grid-column: 3 / -1;
  font-size: ${({ isMobile }) => isMobile && '.8rem'};
  padding-left: ${({ stackVertical }) => stackVertical && '25px'};
`

export const DashedLine = styled.div`
  grid-column: 3 / -1;
  margin: .5rem 0;
  border: 1px dashed #e2e2e6;
`

interface PlayAllProps {
  displayOrder: boolean
  stackVertical: boolean
}

export const PlayAll = styled.button<PlayAllProps>`
  font-family: Lato, 'Helvetica Neue', Arial,Helvetica, sans-serif;
  font-weight: 700;
  grid-column: 3;
  margin: 1rem 0;
  padding: .5rem;
  border-radius: 4px;
  max-width: 100px;
  grid-column: ${({ displayOrder, stackVertical }) => (!displayOrder || stackVertical) ? '3' : '4'};
  margin-left: ${({ stackVertical }) => stackVertical && '25px'};
`

export const DashAndBubble = styled.div`
  grid-column: 2 / -1;
  margin: 1rem 0 .5rem;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
`

interface DashLineProps {
  color?: string
  thick?: boolean
}

export const DashLine = styled.div<DashLineProps>`
  border-top: ${({ thick }) => thick ? 4 : 2}px dashed #ccc;
  border-color: ${({ color }) => color || '#ccc'};
`

interface DashAndBubbleProps {
  isMobile: boolean
  color?: string
}

export const DashBubble = styled.p<DashAndBubbleProps>`
  font-size: ${({ isMobile }) => isMobile ? '.85rem' : '1rem'};
  color: white;
  background-color: ${({ color }) => color || '#67a6e7'};
  padding: 0.3rem 1.5rem !important;
  border-radius: 20px;
  margin: 0 1rem !important; // no idea why this needs !important flag
`

export const Placeholder = styled.div<StackVerticalProps>`
  ${({ stackVertical }) => stackVertical ? 'grid-row: span 2' : ''};
`
