import React, { useState } from 'react'

import useMobile from 'src/hooks/useMobile'
import useSubscribed from 'src/hooks/useSubscribed'
import useStaffRoles from 'src/hooks/useStaffRoles'

import { chibiBucket } from 'src/util/chibiBucket'
import { CardHeader, QuestionTypeButton, QuestionTypePickers, QuestionTypesBody } from '../../styles/AskForm.styles'
import JapaneseFromZeroSubModal from 'src/components/modals/JapaneseFromZeroSubModal'
import { FaLock } from 'react-icons/fa'

const QuestionTypePicker: React.FC<{
  onSelectedType: React.Dispatch<React.SetStateAction<'ask' | 'bug'>>
}> = ({
  onSelectedType,
}) => {
  const isMobile = useMobile()
  const [isAdmin] = useStaffRoles()
  const { isPremiumSubscribed } = useSubscribed()

  const [showSubModal, setShowSubModal] = useState(false)
  const askChibi = chibiBucket.vocab
  const bugChibi = chibiBucket.quiz
  const allowAsk = isAdmin || isPremiumSubscribed

  return (
    <>
      <JapaneseFromZeroSubModal
        location='aat'
        subscriptionModalOpen={showSubModal}
        setSubscriptionModalOpen={setShowSubModal}
      />
      <QuestionTypesBody>
        <h2 style={{ textAlign: 'center' }}>How can we help you today?</h2>
        <QuestionTypePickers>
          <QuestionTypeButton
            onClick={() => allowAsk ? onSelectedType('ask') : setShowSubModal(true)}
            isFreeAccount={!allowAsk}>
            <CardHeader>
              <h3>Content related question</h3>
            </CardHeader>
            <img
              src={askChibi}
              alt='Ask about course content'
              className='no-select'
              draggable='false'
              style={{ marginBottom: isMobile ? '-25px' : '-35px', width: '225px' }}
            />
            {!allowAsk && <FaLock size={20} style={{ position: 'absolute', bottom: 10, right: 10 }} />}
          </QuestionTypeButton>
          <QuestionTypeButton onClick={() => onSelectedType('bug')}>
            <CardHeader>
              <h3>Report a bug or typo</h3>
            </CardHeader>
            <img
              src={bugChibi}
              alt='Report a bug'
              className='no-select'
              draggable='false'
            />
          </QuestionTypeButton>
        </QuestionTypePickers>
      </QuestionTypesBody>
    </>
  )
}

export default QuestionTypePicker
