import styled from 'styled-components'

const FZInput = styled.div`
    margin: 0 5% !important;
    width: 90% !important;

    .ui.form {
        justify-content: center;
        align-items: center;

        div {
            width: 100%;
        }
    }

    .ui.input input {
        width: 90vw;
        max-width: 90vw;
        border: 1px solid var(--blue-gray) !important;
        border-radius: 10px !important;
        font-family: "Milliard Book", Arial, Helvetica, sans-serif !important;
        background-color: white !important;
    }

    .fz-button {
        &:focus {
            outline: 2px solid #333;
        }
    }

    @media (max-width: 768px) {
        margin: 0;
        width: 100%;
    }
`

export const FromZeroInput = styled.input`
    width: 100%;
    border: 1px solid var(--blue-gray) !important;
    border-radius: 10px !important;
    font-family: "Milliard Book", Arial, Helvetica, sans-serif !important;
    background-color: white !important;
`

export default FZInput
