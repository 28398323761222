import React from 'react'
import { ToolInput } from '../../parts'

interface QnAQuestionProps {
  outerBlockIndex: number
  blockIndex: number
  onEdit?: () => void
}

const QnAQuestion: React.FC<QnAQuestionProps> = ({
  outerBlockIndex,
  blockIndex,
  onEdit = () => null
}) => {
  const questionFields = [
    [`blockTitles.${outerBlockIndex}`, `Question ${outerBlockIndex + 1} Title (optional)`],
    [`blocks.${outerBlockIndex}.${blockIndex}.other_lang.0`, 'Target Language'],
    [`blocks.${outerBlockIndex}.${blockIndex}.english.0`, 'English'],
    [`blocks.${outerBlockIndex}.${blockIndex}.aside`, 'Aside (optional)'],
  ]
  return (
    <div style={{ width: '100%' }}>
      {questionFields.map((field, idx) => (
        <React.Fragment key={idx}>
          <ToolInput value={field[0]} placeholder={field[1]} onEdit={onEdit} />
          {idx < 3 && <br />}
        </React.Fragment>
      ))}
    </div>
  )
}

export default QnAQuestion
