import { MiniQuizWidget, MiniQuizQuestion } from 'src/types'
import { PathParams } from 'src/components/fz_courses/sublessonpage/SublessonPage'
import { DUMMY_HIRA, DUMMY_DOUBLE_HIRA, DUMMY_KATA, DUMMY_DOUBLE_KATA, DUMMY_SPECIAL_KATA } from 'src/components/pages/games/kana_game/KanaGamePage'
import _ from 'lodash'

type Options = {
  isV8: boolean
  params: PathParams
}

const affirmations = ["That's right!", 'Nice job!', 'Great!', 'You got it!', 'Correct!', 'Nailed it!', 'Awesome!', 'Yes!', 'Keep it up!', 'Fantastic!']

//
// ----- SPECIAL ACTIVITY TYPE QUSTION GENERATION (kana-typer, conjugation) -----
//

const generateKanaTyperQuestions = (widget: MiniQuizWidget, options: Options) => {
  const { isV8, params } = options
  const { course, lesson } = params
  const lessonNum = (!isV8 && course === 'japanese1') ? parseInt(lesson, 10) - 5 : parseInt(lesson, 10)
  const questions = []
  const kana = []
  const DUMMY_KANA = course === 'jfz2' ? DUMMY_KATA : DUMMY_HIRA
  const DUMMY_DOUBLE_KANA = course === 'jfz2' ? DUMMY_DOUBLE_KATA : DUMMY_DOUBLE_HIRA
  
  if (lessonNum < 10) {
    const filteredHirakata = DUMMY_KANA.filter((hirakata) => hirakata.lesson === lessonNum)
    filteredHirakata.forEach((hirakata) => kana.push(...hirakata.characters))
    
    if (course === 'japanese1') {
      if (lessonNum === 5) kana.push(['ん', 'n'])
      if (lessonNum === 8) kana.pop() // remove ん
    }
  } else if (lessonNum === 10) {
    DUMMY_DOUBLE_KANA.forEach((hirakata) => kana.push(...hirakata.characters))
  } else if (lessonNum === 11) {
    if (course === 'jfz2') kana.push(...DUMMY_SPECIAL_KATA)
    else {
      DUMMY_KANA.forEach((hira) => kana.push(...hira.characters))
      DUMMY_DOUBLE_KANA.forEach((hira) => kana.push(...hira.characters))
    }
  } else if (course === 'jfz2' && lessonNum === 12) {
    DUMMY_KANA.forEach((kata) => kana.push(...kata.characters))
    DUMMY_DOUBLE_KANA.forEach((kata) => kana.push(...kata.characters))
    kana.push(...DUMMY_SPECIAL_KATA)
  }

  kana.forEach((_kana) => {
    let extraCommentary
    if (_kana[0] === 'づ') extraCommentary = "(typed as 'du' with Japanese IME input)"
    if (_kana[0] === 'ぢ') extraCommentary = "(typed as 'di' with Japanese IME input)"

    questions.push({
      activity_type: 'input',
      question: 'Type the romaji for the following character',
      correct_answer: _kana[1],
      multichoice_correct_answers: _kana.slice(2) ?? [],
      // actually incorrect commentary but it's easier to just use the same field since its not an array
      correct_commentary: `
        The correct answer is '${_kana[1]}'${_kana[2] ? `${_kana.length > 3 ? ', ' : ' or '} '${_kana[2]}'` : ''}${_kana[3] ? `, or '${_kana[3]}'` : ''}
        ${extraCommentary ?? ''}
      `,
      question_image: _kana[0],
      multichoice_correct_commentary: affirmations
    })
  })

  return _.shuffle(questions)
}

// mostly dummy data -> conjugation game requires dynamic data from the server through useConjugation hook
// range determines the number of questions
const generateConjugationQuestions = () => {
  const range = _.range(5)
  const questions = range.map(() => {
    return {
      activity_type: 'input',
      question: 'Type the correct conjugation for the following verb',
      correct_answer: '',
      multichoice_correct_answers: [],
      correct_commentary: '',
      question_image: '',
      multichoice_correct_commentary: affirmations
    }
  })

  return questions
}

//
// ----- ANSWER GENERATION -----
//

const generateTextAnswers = (question: MiniQuizQuestion) => {
  const correctAnswerData = {
    answer: question.correct_answer,
    commentary: question.correct_commentary,
    isCorrect: true
  }
  const incorrectAnswerData = question.other_answers.map((answer, index) => {
    return {
      answer,
      commentary: question.other_commentary[index],
      isCorrect: false
    }
  })

  return _.shuffle([correctAnswerData, ...incorrectAnswerData])
}

const generateImageAnswers = (question: MiniQuizQuestion) => {
  const answerData = question.other_answers.map((answer, index) => {
    return {
      answer,
      commentary: question.other_commentary[index],
      isCorrect: question.image_correct_answers[index]
    }
  })

  return _.shuffle(answerData)
}

const generateInputAnswer = (question: MiniQuizQuestion) => {
  return [{
    answer: [question.correct_answer, ...question.multichoice_correct_answers].map((a) => a.trim().toLowerCase()),
    commentary: question.correct_commentary, // incorrect commentary for kana/conjugation game
    correctCommentary: question.multichoice_correct_commentary, // kana/conjugation game affirmations
    isCorrect: false // handled in useActivity for input type
  }]
}

//
// ----- EXPORTS -----
//

export const generateQuizQuestions = (widget: MiniQuizWidget, options: Options) => {
  if (widget.subtype === 'lesson-quiz' || widget.subtype === 'mini-quiz') return _.shuffle(widget.items)
  if (widget.subtype === 'kana-typer') return generateKanaTyperQuestions(widget, options)
  if (widget.subtype === 'conjugation') return generateConjugationQuestions()
}

export const generateShuffledAnswers = (question: MiniQuizQuestion) => {
  if (question.activity_type === 'text' || question.activity_type === 'sounds') return generateTextAnswers(question)
  if (question.activity_type === 'images') return generateImageAnswers(question)
  if (question.activity_type === 'input') return generateInputAnswer(question)
}
