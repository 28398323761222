import styled, { css, createGlobalStyle } from 'styled-components'
import rural_night from '../login/bkgd_rural_night_blur.png'
import exclamation from '../lesson_overview_page/page_exclamation.svg'

const bgImageStyles = css`
  body {
    background-image: url(${rural_night});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position-x: 17%;
  }
`

export const AuthBackgroundImage = createGlobalStyle`${bgImageStyles}`
export const BackgroundImage = createGlobalStyle`${bgImageStyles}`

export const GlobalStyle = createGlobalStyle`
  #content-body {
    /* height: 100vh; */

    .auth-fields, .fireworks-town {
      height: 100vh;
    }
  }
  .footer {
    display: none;
    visibility: hidden;
  }
`

export const AuthPageStyles = styled.div`
  width: 100vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;

  display: flex;
  flex-direction: column;

  & > div:first-child > div {
    margin-bottom: 30px;
    flex-direction: column;

    h2 {
      font-size: 1.2rem;
      color: var(--off-white);
    }

    /* img {
            width: 60vw;
        } */
  }

  & > div:last-child {
    width: 100%;
    margin: 0 auto;
    overflow-y: auto;
  }

  .container {
    padding: 20px 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
      width: 100%;
      max-width: 700px;
      display: flex;
      flex-direction: column;

      .field {
        width: 100%;
      }

      section.button-group {
        display: flex;
        justify-content: center;
      }

      .warn {
        margin: 0 !important;
        font-size: 1.2rem;
      }
    }
  }

  .actions {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

    a {
      color: var(--off-white);

      &:hover {
        color: var(--jfz-blue);
      }
    }
  }

  .success {
    background-color: rgba(0, 0, 0, 0.8);
    text-align: center;
    margin: 0 auto;
    width: 80%;
    border-radius: 15px;
    padding: 10px;
    align-self: flex-end;

    h2,
    p {
      color: var(--off-white) !important;
    }
  }

  button,
  .fz-button {
    font-size: 1.2rem;
    color: white !important;
    border: 2px solid var(--jfz-blue) !important;
    background-color: var(--jfz-blue) !important;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 auto;
    cursor: pointer;

    &:hover {
      background-color: white !important;
      color: var(--jfz-blue) !important;
    }

    &:disabled {
      background-color: var(--blue-gray) !important;
      border-color: var(--blue-gray) !important;
      color: var(--off-white) !important;
      cursor: default;
    }
  }

  .fz-button.login {
    margin-left: 8px !important;
  }

  @media (max-width: 600px) {
    background-position-x: 180%;
  }
  @media (max-width: 450px) {
    background-position-x: ${(props) => (props.isLogin ? '150%' : '500%')};
  }

  @media (min-width: 768px) {
    flex-direction: row;
    margin: 0 auto;

    > div {
      height: calc(100vh - (66px + 48px));
      min-width: 50vw;
    }

    .auth-fields {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      background: url(${exclamation});
      background-repeat: no-repeat;
      background-size: 42vh;
      background-position-x: right;
      background-position-y: bottom;
      h2 {
        color: var(--jfz-blue);
      }
    }

    .flex-center {
      img {
        max-width: 300px;
      }
    }

    .fireworks-town {
      background-image: url(${rural_night});
      background-repeat: no-repeat;
      background-size: cover;
      background-position-y: center;
      transition: all 0.5s ease-out;
      overflow: hidden;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      z-index: 1;

      div {
        padding: 8% 20% 10% 25%;
        margin-bottom: -170px;

        img {
          min-width: 450px;
        }
      }
    }

    div:first-child > div h2 {
      color: var(--jfz-blue);
    }

    .actions a,
    .success h2,
    .success p {
      color: var(--jfz-blue);
    }
    .actions a:hover {
      color: var(--sub-blue);
    }
  }
`

export const PasswordField = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 100%;

  .field:first-child {
    width: 100%;
  }

  .password-visibility {
    width: auto !important;
    position: absolute;
    top: ${(props) => (props.inModal ? '35.5px' : (props.yj ? '35.5px' : '10px'))};
    right: 45px;
    color: gray;
    cursor: pointer;

    &:hover {
      color: var(--jfz-blue);
    }
  }

  @media (max-width: 768px) {
    .password-visibility {
      right: 20px;
    }
  }
`
