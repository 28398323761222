import { useContext } from 'react'
import { useViewportSize } from '@mantine/hooks'
import * as R from 'ramda'

import { SubscriptionContext } from 'src/contexts/SubscriptionContext'
import { GoldGradientContainer, SaleSubscribeBlackText } from '../promos/styles/2023NewYearsSale.styles'
import WinbackBanner from '../promos/styles/winbackCampaign.styles'
import SummerBreakBanner from '../promos/styles/summerBreak.styles'
import BackToSchoolBanner from '../promos/styles/backToSchool.styles'
import HalloweenBanner from '../promos/styles/halloween.styles'

const AccountPageBanner = () => {
  const { width } = useViewportSize()
  const { isNewYearsSale, promos, campaigns, affiliate } = useContext(SubscriptionContext)

  // 50% if p6, 30% if p90, else 0
  const getValue = R.cond([
    [R.prop('p6'), R.always(50)],
    [R.prop('p90'), R.always(30)],
    [R.T, R.always(0)]
  ])
  const { p6, p90 } = promos || {}
  const discount = getValue({ p6, p90 })
  const campaign = campaigns?.[0]
  const winback = (p6 || (p90 && !campaign && !affiliate))
  const summer = !p6 && campaign?.identifier === 'summer-campaign-2024'
  const school = !p6 && campaign?.identifier === 'back-to-school-2024'
  const halloween = !p6 && campaign?.identifier === 'halloween-2024'
  const split = width < 500

  return (
    <>
      {isNewYearsSale && (
        <GoldGradientContainer>
          <SaleSubscribeBlackText account>
            Start the New Year with{!split ? ' 50% OFF Yearly!' : ''}
          </SaleSubscribeBlackText>
          {split && <SaleSubscribeBlackText account>
            50% OFF Yearly!
          </SaleSubscribeBlackText>}
        </GoldGradientContainer>
      )}
      {winback && <WinbackBanner discount={discount} />}
      {summer && <SummerBreakBanner />}
      {school && <BackToSchoolBanner />}
      {halloween && <HalloweenBanner />}
    </>
  )
}

export default AccountPageBanner
