import styled from 'styled-components'
import tw from 'twin.macro'
import { css } from '@emotion/react'

const defaultDiv = css(tw`tw-w-full tw-px-5`)

const labelStyles = css`
  label {
    ${tw`tw-font-bold`}
    text-transform: capitalize;
    color: 'var(--blue-gray)';
  }
`

const KanjiBox = styled.div`
  ${tw`tw-flex tw-flex-row tw-justify-start tw-items-center tw-ml-[10px]`}
  ${defaultDiv.styles}
  ${labelStyles.styles}

  label {
    ${tw`tw-align-middle tw-font-bold tw-mr-2`}
    color: 'var(--blue-gray)';
  }

  input[type=text] {
    ${tw`tw-w-20`}
    border: 1px solid var(--light-border);
    outline-color: var(--light-border);
  }

  p.error {
    ${tw`tw-font-bold tw-ml-2`}
    color: var(--jfz-blue);
  }
`

const KanjiInfo = styled.div`
  ${defaultDiv.styles}
  ${tw`tw-w-full tw-pt-5`}
  background-color: var(--mid-panel);
`

const KanjiReadings = styled.div`
  ${labelStyles.styles}
  ${tw`tw-grid tw-gap-8`}
  grid-template-columns: repeat(2, 1fr);
`

const ThreeInputRow = styled.div`
  ${defaultDiv.styles}
  ${labelStyles.styles}
  ${tw`tw-grid tw-my-5 tw-gap-8`}
  grid-template-columns: repeat(3, 1fr);

  >div { margin: 0 auto; }

  input {
    ${tw`tw-w-20`}
  }

  >div {
    label {
      ${tw`tw-mr-2`}
    }

    input[type=text] {
      ${tw`tw-w-20`}
      border: 1px solid var(--light-border);
      outline-color: var(--light-border);
    }
  }
`

const TwoInputRow = styled.div`
  ${defaultDiv.styles}
  ${labelStyles.styles}
  ${tw`tw-grid tw-my-5 tw-gap-8`}
  grid-template-columns: repeat(2, 1fr);

  >div { margin: 0 auto; }

  input {
    /* auto fill field width */

  }

  >div {
    label {
      ${tw`tw-mr-2`}
    }

    input[type=text] {
      /* ${tw`tw-w-20`} */
      border: 1px solid var(--light-border);
      outline-color: var(--light-border);
    }
  }
`

const KanjiExampleBox = styled.div`
  ${defaultDiv.styles}
  ${labelStyles.styles}
  >div>div>div {
    ${tw`tw-grid tw-gap-8 tw-my-5 tw-ml-9`}
    grid-template-columns: repeat(2, 1fr);
  }
`

export {
  KanjiBox,
  KanjiInfo,
  KanjiReadings,
  ThreeInputRow,
  TwoInputRow,
  KanjiExampleBox,
}
