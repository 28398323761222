import React, { useEffect, useMemo, useState } from 'react'
import axios from '../../../../api'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setPopup } from '../../../../actions'
import * as wanakana from 'wanakana'
import * as R from 'ramda'
import * as Diff from 'diff'
import PubSub from 'pubsub-js'

import ReactFuri from 'react-furi'

import { Icon, Loader } from 'semantic-ui-react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import TeacherNote from '../TeacherNote'
import useMobile from 'src/hooks/useMobile'
import useBrowser from 'src/hooks/useBrowser'
import useGrammar from 'src/components/fz_courses/hooks/useGrammar'
import { TNumSpan } from 'src/assets/styles/globalStyles'
import useLongPress from 'src/hooks/useLongPress'

const wrapAtIconStyles = css`
  display: inline-grid;
  align-items: baseline;
  grid-template-columns: auto 1fr;
  text-align: left; // added because of structure widget bug
`

const SoundIconStyleWrapper = styled.blockquote`
  cursor: ${({ noPopup }) => (noPopup ? '' : 'pointer')};
  ${({ holdsFuri }) => holdsFuri && 'padding-top: 8px; margin-top: -4px; padding-bottom: 4px;'}
  transition: all 0.1s ease-in;
  margin: 0;
  /* display: inline; */
  // word-break: ${(props) => (props.progSetting !== 'hirakata' ? 'keep-all' : '')};
  /* stops Chinese / Japanese / Korean text from breaking-mid word */


  /*
    targets the triangle icon in SoundIcon
  */
  .FZPlayButton.play:before {
    position: relative !important;
    left: 0.5px !important;
    top: 0.3px !important;
  }
  
  
  &:focus-within .sound {
    background-color: #c1d7d3;
    color: var(--blue-gray);
    border-radius: 3px;
    padding-left: 4px;
    margin-left: -4px;
    padding-right: 2px;
    margin-right: -2px;
    ${({ holdsFuri, wrapAtIcon }) => (holdsFuri && !wrapAtIcon) && 'padding-top: 8px; margin-top: -4px; padding-bottom: 4px;'}
  }
  &:focus-within rt {
    opacity: ${({ noFuri }) => !noFuri && 1};
  }

  .sound {
    border-radius: 3px;
    color: ${({ white, playing }) => white ? '#fcfaff' : (playing && 'var(--blue-gray)')};
    background-color: ${({ playing, white }) => playing && (white ? 'rgba(0,0,0,.35)' : '#f5ecbb')};
    /* ${({ progSetting }) => progSetting === 'hirakata' ? `
        display: inline-flex;
        align-items: baseline;
    ` : ''}; */
    ${({ playing, holdsFuri, wrapAtIcon }) => (playing && holdsFuri && !wrapAtIcon) ? `
      padding-top: 8px;
      margin-top: -4px;
      padding-left: 4px;
      margin-left: -4px;
      padding-right: 2px;
      margin-right: -2px;
    ` : ''}

    ${({ wrapAtIcon }) => wrapAtIcon && wrapAtIconStyles}
  }

  /* .sound-span {
      white-space: break-spaces;
      word-wrap: break-word;

      &:before {
          content: "";
          position: relative;
          height: 1px;
          width: 21px;
          display: inline-block;
          padding-left: 21px !important;
          margin-left: -21px;
      }
  } */

  i {
    min-width: 21px;
    min-height: 21px;
  }

  rt {
    font-family: 'BIZ UDPGothic';
    opacity: ${(props) => props.noFuri ? 0 : props.furiOpacity / 100};
    transition: all 0.1s ease-in;
    transform: ${({ browser, os }) => {
    /* eslint-disable indent */
      if (browser === 'chrome') {
        if (os === 'macos') return 'translateY(-2px);'
      }
      return 'translateY(0px);'
    }};
    // thanks for being you, Firefox
    margin-bottom: ${({ browser, os }) => {
      if (browser === 'firefox') {
        if (os === 'macos') return '1.5px;'
        if (os === 'windows') return '-4px;'
      }
      return '0px;'
    }};
    /* eslint-enable */

    /*
    // prevent furigana from creating space around a kana,
    // squish kana together, 
    // recenter over the kanji
    
    width: 1px;
    white-space: nowrap;
    letter-spacing: -1px;
    transform: translateX(-5px);
    */
  }
  ruby:hover rt, rt:hover { opacity: ${({ noFuri }) => !noFuri && 1}; }
  .furi-on-hover rt { opacity: 0; }
  .furi-on-hover:hover rt { opacity: 1; }
  .furi-onyomi {
    color: ${({ inverted }) => inverted ? '#c3c9f7' : '#0e24eb'};
  }
  .furi-kunyomi {
    color: ${({ inverted }) => inverted ? '#fca9c1' : '#eb0e4c'};
  }
  .furi-exception {
    color: ${({ inverted }) => inverted ? '#bdffd6' : '#1cc75e'};
  }
  /* .visible rt { opacity: 1 !important; } */
`

const progSpecMap = [
  ['onnichi wa', 'onnichiwa'],
  [' pp', 'pp'],
  ['Desu.', 'desu.'],
  ['iniro', 'in iro'],
  ['mennasai', 'men nasai'],
  ['anjou bi', 'anjoubi'],
  ['goーrudenwiーku', 'goーruden wiーku'],
  ['oyasuminasai', 'oyasumi nasai']
  // ['hayougozaimasu', 'hayou gozaimasu']
]

export const createPronunciationMap = (breakdown) => {
  let pronunciationMap = ''
  breakdown?.forEach((bd) => {
    const { pos_detail_1, pos_detail_2, pronunciation = '', reading = '', surface_form = '' } = bd

    if (['接続助詞'].includes(pos_detail_2) || ['係助詞', '格助詞'].includes(pos_detail_1)) {
      pronunciationMap += pronunciation.trim()
    } else if (reading && !/[０-９]+/.test(surface_form)) {
      pronunciationMap += reading.trim()
    } else {
      pronunciationMap += surface_form.trim()
    }
  })
  return pronunciationMap.replace(/[一-龯]/g, '')
}

export const generateSpaces = (breakdown) => {
  const spaces = [], capitals = [], pipes = []
  let currentPos = 0

  breakdown?.forEach((bd, idx) => {
    const { reading = '', basic_form = '', surface_form = '', pos_detail_1 = '', pos_detail_2 = '', pos = '' } = bd
    const readingLength = reading.trim().length

    if (!reading) return

    // certain nouns
    if (pos === '名詞' && pos_detail_1 === '一般') {
      spaces.push(currentPos + readingLength - 1)
    }

    if (pos === '名詞' && basic_form?.length > 1 && basic_form?.includes('月') && pos_detail_1 !== '副詞可能') {
      // months
      spaces.push(currentPos - 1 + readingLength - 2)
    } else if (pos_detail_2 === '助数詞') {
      // counters
      spaces.push(currentPos + readingLength - 1)
    } else if (pos_detail_1 === '副詞可能' && basic_form?.includes('日')) {
      // days
      spaces.push(currentPos + readingLength - 4)
    } else if (pos === '名詞' && ['サ変接続', '副詞可能'].includes(pos_detail_1)) {
      spaces.push(currentPos + readingLength - 1)
    } else if (pos_detail_1 === '代名詞' && basic_form === '何') {
      // nan before stuff
      spaces.push(currentPos + readingLength - 1)
    } else if (basic_form === '月' && idx > 0) {
      spaces.push(currentPos - 1)
    } else if (basic_form === 'する' || basic_form === 'ある') {
      // suru
      spaces.push(currentPos - 1)
      spaces.push(currentPos + readingLength - 1)
    } else if (pos === '助詞' && pos_detail_1 !== '接続助詞') {
      // particles
      spaces.push(currentPos - 1)
      if (surface_form !== 'じゃ') spaces.push(currentPos + readingLength - 1)
    } else if (pos === '感動詞') {
      spaces.push(currentPos - 1)
    } else if (surface_form === 'ない') {
      // janai
      if (idx > 0 && breakdown[idx - 1].surface_form !== 'じゃ' && (breakdown[idx - 1].conjugated_form !== '連用テ接続' && breakdown[idx - 1].conjugated_form !== 'ガル接続')) {
        spaces.push(currentPos - 1)
      }
    } else if (pos === '副詞' || (pos === '形容詞'
      && ((idx + 1) < breakdown.length && breakdown[idx + 1].surface_form !== 'ない')
      && ((idx + 1) < breakdown.length && breakdown[idx + 1].conjugated_type !== '特殊・タ'))) {
      // adverbs/adjectives
      if (currentPos > 0) spaces.push(currentPos - 1)
      spaces.push(currentPos + readingLength - 1)
    } else if (pos === '助動詞' && (surface_form === 'です' || basic_form === 'ござる' || basic_form === 'です')) {
      // auxiliary verbs
      spaces.push(currentPos - 1)
    } else if (pos === '記号' && pos_detail_1 === '読点') {
      // commas
      spaces.push(currentPos)
    } else if (pos_detail_1 === '接尾' && basic_form !== '日' && basic_form !== '語') {
      // san
      spaces.push(currentPos - 1)
      if (basic_form === '年' || basic_form === '中' || basic_form === '円') spaces.push(currentPos + 1)
    } else if (pos_detail_1 === '数' && breakdown[idx].basic_form !== '十' && !(idx > 0 && breakdown[idx - 1].basic_form === '十')) {
      // numbers
      // console.log('numbers')
      spaces.push(currentPos - 1)
      pipes.push(currentPos - 1)
    } else if (pos_detail_1 === '数' && idx < breakdown.length - 1 && breakdown[idx + 1].basic_form === '十') {
      // nijuu
      spaces.push(currentPos - 1)
      pipes.push(currentPos - 1)
    } else if (pos_detail_1 === '非自立' && (idx > 0 && breakdown[idx - 1].basic_form !== 'て')) {
      // no particle
      spaces.push(currentPos - 1)
    }

    if (idx > 0 && pos_detail_2 === '人名' && pos_detail_1 !== '接尾') {
      // capitals mid-sentence
      capitals.push(currentPos)
      spaces.push(currentPos - 1)
    }

    currentPos += readingLength || surface_form.trim().length
  })

  // console.log(spaces, capitals, pipes)
  return [spaces, capitals, pipes]
}

const isKanji = (c) => wanakana.isKanji(c) || c === '々'

export const generateKanji = (text, newFuriMap = {}, prog_data = {}) => {
  let newText = ''

  for (const c of text) {
    if (isKanji(c) && newFuriMap[c] && !prog_data[c]) {
      newText += newFuriMap[c]
    } else {
      newText += c
    }
  }

  return newText
}

export const handleSpecialCases = (str, romaji) => {
  // console.log(str)
  let compiledString = str

  // account for japanese periods following english
  if (compiledString.length > 1 && compiledString[compiledString.length - 1] === '。' && wanakana.isRomaji(compiledString[compiledString.length - 2])) {
    compiledString = compiledString.replace(/。$/, '.')
  }

  if (romaji) compiledString = compiledString.replace(/\s*」\s*/, '" ')
  if (romaji) compiledString = compiledString.replace(/\s*「\s*/, ' "')
  if (romaji) compiledString = compiledString.replace('、', ',')
  if (romaji) compiledString = compiledString.replace(' ,', ',')
  compiledString = compiledString.replace(' .', '.')
  compiledString = compiledString.replace('..', '.')
  compiledString = compiledString.replace('.', '. ')
  compiledString = compiledString.replace('  ', ' ')
  // compiledString = compiledString.replace(' _ ', ' _____ ')

  compiledString = compiledString.replace(' ss', 'ss')

  compiledString = compiledString.replace(/(?:[.?!])\W*[a-z]/g, (i) => i.toUpperCase())
  compiledString = compiledString.replace(/[　-龯0-9０-９？！_] [　-龯0-9０-９？！_]/g, (i) => i.replace(' ', ''))
  compiledString = compiledString.replace(/[　-龯0-9０-９？！_]\.\s*$/g, (i) => i.replace('.', '。'))
  compiledString = compiledString.replace(' 。', '。')
  compiledString = compiledString.replace(/[a-zA-Z0-9]。/g, (i) => i.replace('。', '.'))
  compiledString = compiledString.replace(/[a-zA-Z]\.[a-zA-Z]/g, (i) => i.replace('.', '. '))
  compiledString = compiledString.replace(/Ii/g, 'ii')
  compiledString = compiledString.replace(' ?', '')
  compiledString = compiledString.replace('-', 'ー')

  // compiledString = compiledString.replace(/\s*ー\s*/, (i) => i.trim())

  // replace japanese commas inside english text
  compiledString = compiledString.replace(/[a-zA-Z]、/, (i) => i.replace('、', ','))
  compiledString = compiledString.replace(/[一-龯] [一-龯]/g, (i) => i.replace(' ', ''))
  compiledString = compiledString.replace(/[0-9] [0-9]/g, (i) => i.replace(' ', ''))

  compiledString = compiledString.replace(/「[a-zA-Z0-9]/g, (i) => i.replace('「', '"'))
  compiledString = compiledString.replace(/[a-zA-Z0-9]」/g, (i) => i.replace('」', '"'))

  compiledString = compiledString.replace(/,[　-龯]/g, (i) => i.replace(',', ', '))

  // (name) to moushimasu
  compiledString = compiledString.replace('( ', '(')
  compiledString = compiledString.replace(')', ') ')
  compiledString = compiledString.replace('((', '(')

  compiledString = compiledString.replace('イーメール', 'Eメール')

  compiledString = compiledString.replace(' ？', '？')

  if (compiledString.indexOf('(') !== -1 && compiledString.indexOf(')') === -1) {
    compiledString = compiledString.replace('(', '')
  }

  compiledString = compiledString.replace(/\. [a-z]/, (i) => i.toUpperCase())

  compiledString = compiledString.replace(' 、 ', ', ')
  compiledString = compiledString.replace(' !', '!')

  compiledString = compiledString.replace(/\|/g, ' ')
  compiledString = compiledString.replace(/ 、/g, '、')

  // console.log(compiledString)

  return compiledString
}

// じゃ、四(よ)時(じ)四(よん)十(じゅっ)分(ぷん)に銀(ぎん)行(こう)の前(まえ)に居(い)て下(くだ)さい。
// 英(えい)語(ご)で静(しず)かの意(い)味(み)は、「quiet」です。
export const progressify = (text, romaji, prog_setting, prog_data, record, bonusData, furi) => {
  if (record?.type === 'KoreanRecord') return record?.base_text
  if (record?.type === 'ChineseRecord') {
    if (romaji) return record?.pinyin
    return record?.base_text
  }
  if (bonusData && ['kana', 'kanji'].includes(bonusData.force_display)) {
    return text
  }
  if (['kanji'].includes(prog_setting)) return text

  const { breakdown } = record
  const pronunciationMap = createPronunciationMap(breakdown)
  const newFuriMap = {}

  // console.log(pronunciationMap)

  // create new furi map we'll use to override provided text
  furi && furi.forEach((f) => {
    if (f.length > 1) {
      newFuriMap[f[0]] = f[1]
    }
  })

  // create new text with injected kanji
  if (prog_setting === 'kanji') text = generateKanji(text, newFuriMap, prog_data)

  // console.log(text)

  // fetch spaces and capitals
  let [spaces, capitals, pipes] = generateSpaces(breakdown)
  // console.log(text)
  // console.log(spaces)
  // console.log(pipes)

  let capitalized = breakdown[breakdown.length - 1].pos_detail_1 === '句点' || breakdown[breakdown.length - 1].pos === '記号'
  const period = capitalized

  // names at very beginning of longer sentences
  if (breakdown[0].pos_detail_2 === '人名' && breakdown[0].pos_detail_1 !== '接尾') {
    capitalized = true
  }

  // capitalize names
  if (text.length > 1 && breakdown.length === 1 && ((breakdown[0].pos_detail_2 === '人名' && breakdown[0].pos_detail_1 !== '接尾') || (breakdown[0].pos_detail_2 === '組織'))) {
    capitalized = true
  }

  const splitText = text.split('')
  let compiledString = ''

  const numMap = {
    '０': '0', '１': '1', '２': '2', '３': '3', '４': '4', '５': '5',
    '６': '6', '７': '7', '８': '8', '９': '9'
  }

  // にほんごが はなせるように なりたい。
  // console.log(splitText)
  splitText.forEach((t, idx) => {
    // console.log(t, idx)
    // shrink all spaces if we've reached a > 1-char kanji
    if (newFuriMap[t]?.length > t.length) {
      const diff = newFuriMap[t]?.length - 1
      spaces = spaces.map((s) => s - diff)
    }
    // account for compound kana (しゃ, etc)
    if (['ゃ', 'ょ', 'ゅ', 'ュ', 'ャ', 'ョ', 'ェ', 'っ', 'ッ', 'ォ', 'ァ', 'ィ'].includes(splitText[idx])) {
      // console.log('returning on compound')
      if (spaces.includes(idx)) {
        compiledString += ' '
      }
      return;
    }

    if (idx < splitText.length - 1 && ['ゃ', 'ょ', 'ゅ', 'ュ', 'ャ', 'ョ', 'ェ', 'ォ', 'ァ', 'ィ'].includes(splitText[idx + 1])) {
      // console.log('next is compound')
      const combo = t + splitText[idx + 1]
      if (prog_setting !== 'hirakata' && (!prog_data[combo] || romaji)) {
        let romajiCombo
                
        // uniquely truncate this combo as kata
        if (['ェ', 'ォ', 'ァ', 'ィ'].includes(splitText[idx + 1])) {
          if (combo === 'ウィ') romajiCombo = 'wi'
          else {
            romajiCombo = wanakana.toRomaji(combo[0]).slice(0, -1)
            romajiCombo += wanakana.toRomaji(combo[1])
          }
        } else {
          romajiCombo = wanakana.toRomaji(combo)
        }

        // compound character doubling up small tsu
        if (['っ', 'ッ'].includes(splitText[idx - 1])) {
          romajiCombo = romajiCombo[0] + romajiCombo
        }
        compiledString += romajiCombo
      } else if (prog_data['つ'] && idx > 0 && splitText[idx - 1] === 'っ') {
        compiledString += 'っ' + combo
      } else if (prog_data['ツ'] && idx > 0 && splitText[idx - 1] === 'ッ') {
        compiledString += 'ッ' + combo
      } else if (prog_setting === 'hirakata' && idx > 0 && splitText[idx - 1] === 'っ') {
        compiledString += 'っ' + combo
      } else if (prog_setting === 'hirakata' && idx > 0 && splitText[idx - 1] === 'ッ') {
        compiledString += 'ッ' + combo
      } else compiledString += combo

      if (pipes.includes(idx)) {
        compiledString += '|'
      } else if (spaces.includes(idx)) {
        compiledString += ' '
      }
      return;
    }

    if (/[a-zA-Z]/.test(t)) {
      spaces = spaces.map((s) => s + 1)
      compiledString += t

      // see if the next character is non-alphanumeric and add a space if so
      if (idx < splitText.length - 1 && !/[a-zA-Z]/.test(splitText[idx + 1])) {
        compiledString += ' '
      }
    }

    else if (['、', '。', 'ー', '？', '！', '・', '「', '」', '_', '✖', '×'].includes(t)) compiledString += t
    else if (/[0-9]/.test(t)) compiledString += t
    else if (numMap[t]) {
      // console.log('found in num map')
      compiledString += numMap[t]
    } else if (prog_setting !== 'hirakata' && (romaji || !prog_data[t])) {
      // console.log('romaji or not prog data t')
      let romajiAppend = ''
      const currentRomaji = wanakana.toRomaji(pronunciationMap[idx])
      // console.log('current romaji', t, currentRomaji, idx)

      // double up letters
      if (idx > 0 && (splitText[idx - 1] === 'っ' || splitText[idx - 1] === 'ッ') && currentRomaji) {
        romajiAppend += currentRomaji[0]
      }
      romajiAppend += currentRomaji

      // mid-sentence capitalize
      if (capitals.includes(idx)) {
        romajiAppend = romajiAppend.charAt(0).toUpperCase() + romajiAppend.slice(1)
      }

      if (romajiAppend === 'wo') romajiAppend = 'o'

      // if it's romaji "n", and next character is romaji "i", add an apostrophe
      if (romajiAppend === 'n' && idx < splitText.length - 1 && !prog_data[splitText[idx + 1]] && wanakana.toRomaji(pronunciationMap[idx + 1]) === 'i') {
        romajiAppend += '\''
      }

      compiledString += romajiAppend
      // console.log(compiledString, romajiAppend)
    } else if (prog_setting === 'hirakata') {
      let kanaAdd = t
      if (idx > 0 && splitText[idx - 1] === 'っ') {
        // console.log('small tsu')
        kanaAdd = 'っ' + kanaAdd
      } else if (idx > 0 && splitText[idx - 1] === 'ッ') kanaAdd = 'ッ' + kanaAdd

      compiledString += kanaAdd
    } else {
      // console.log('else not romaji')
      let kanaAdd = t
      if (prog_data['つ'] && idx > 0 && splitText[idx - 1] === 'っ') {
        // console.log('small tsu')
        kanaAdd = 'っ' + kanaAdd
      } else if (prog_data['ツ'] && idx > 0 && splitText[idx - 1] === 'ッ') kanaAdd = 'ッ' + kanaAdd

      // if we know the character but not small tsu...
      if (!prog_data['つ'] && idx > 0 && splitText[idx - 1] === 'っ') {
        // console.log('not small tsu')
        kanaAdd = prog_data[t]
        kanaAdd = kanaAdd[0] + kanaAdd
      }

      compiledString += kanaAdd
      // console.log(kanaAdd)
    }

    // console.log(pipes)
    // console.log(idx, spaces.includes(idx), pipes.includes(idx))
    if (pipes?.includes(idx) && !/[a-zA-Z]/.test(t)) {
      compiledString += '|'
    } else if (pipes?.includes(idx)) {
      compiledString += '|'
    } else if (spaces.includes(idx) && !/[a-zA-Z]/.test(t)) {
      compiledString += ' '
    }
    // console.log(compiledString)
  })

  if (capitalized && !record.bonus?.force_lowercase) {
    compiledString = compiledString.charAt(0).toUpperCase() + compiledString.slice(1)
  }

  compiledString = handleSpecialCases(compiledString, romaji)

  progSpecMap.forEach((ps) => {
    compiledString = compiledString.replace(ps[0], ps[1])
  })

  return compiledString.trim()
}

export const createFuriData = (splitRecord, furis) => {
  const newFuriData = []

  splitRecord && splitRecord.forEach((sr) => {
    // check each character of the non-furi for kanji
    for (let i = 0; i < sr.length; i++) {
      if (wanakana.isKanji(sr[i])) {
        // if we're > 0, we have preceding kana; split
        if (i > 0) {
          const preKana = sr.slice(0, i)
          const postKanji = sr.slice(i)
          newFuriData.push([preKana])
          newFuriData.push([postKanji])
          return
        } else {
          newFuriData.push([sr])
          return
        }
      }

      if (i === sr.length - 1) {
        newFuriData.push([sr])
      }
    }
  })

  // match furi with new non-furi
  if (furis) {
    let currentKanji = 0
    for (let i = 0; i < furis.length; i++) {
      while (currentKanji < newFuriData.length) {
        if (wanakana.isKanji(newFuriData[currentKanji][0][0]) || newFuriData[currentKanji][0][0] === '々' || newFuriData[currentKanji][0][0] === 'ヵ' || newFuriData[currentKanji][0][0] === 'ヶ') {
          newFuriData[currentKanji].push(furis[i])
          currentKanji++
          break
        } else {
          currentKanji++
        }
      }
    }
  }

  return newFuriData
}

export const recordSplit = (record) => {
  const furiRegex = /\([０-９？　-龯_]+\)/g

  const furis = record?.base_text.match(furiRegex)?.map((f) => f.replace(/[()]/g, ''))
  const splitRecord = record?.base_text.split(furiRegex).filter((e) => e !== '')

  return { furis, splitRecord }
}

const isNotKanjiOrKana = (char) => !wanakana.isKanji(char) && !wanakana.isKana(char)

// return the binary value for which all 1's are set mapping to the length of the string
// passed in
// e.g., for the string おはよう, return the binary value for 1111
const highestBinary = (str) => {
  let binary = 0
  for (let i = 0; i < str.length; i++) {
    binary += 2 ** i
  }
  return binary
}

// given a string (e.g., おはよう) and a binary value (0111), add a space for every
// bit in the binary string that equates to 1
// e.g., given おはよう and 0111, return おは よ う (spaces after the second, third, and fourth char)
const spacesPermutation = (str, val) => {
  let spaces = ''
  let idx = str.length - 1
  const strSplit = str.split('')
  while (idx > -1) {
    if (val & 1) {
      // insert a space into the split string array at idx
      strSplit.splice(idx, 0, ' ')
    }
    val >>= 1
    idx--
  }
  return { head: strSplit.join(''), drop: R.filter((c) => c === ' ', strSplit) }
}

// NOTE: needs to account for kanji compounds with one furi as well (asatte)
export const pairFuriToKanjiProg = (progressified, furiData, progData) => {
  // const shouldPrint = ['こre wa dare no desu か', 'しょうたくんは、わるいことを しそうに ない子ですが、じつは大へんな子な んです。', '今日は日 よう日だから、でん車ですわ れそうだ。', 'むすこは きょ年の ふくが まだき られそうです。'].includes(progressified)
  // const shouldPrint = true
  // console.log(progressified)
  // if (shouldPrint) console.group(progressified)
  // if (shouldPrint) console.log(furiData)

  // pointfree func to see if a passed in prop (using flip) is within progData
  const inWL = R.flip(R.prop)(progData)

  // checks to see if all the characters (in case compound) in index 0
  // of the pair are in the progData whitelist
  const wlPairs = R.filter(R.all(R.compose(inWL, R.head)), furiData)
  
  // OLD
  // const wlPairs = R.filter(R.compose(inWL, R.head), furiData)

  // if (shouldPrint) console.log(wlPairs)

  const buildProg = (b, k) => {
    const { build, base } = b
    // if (shouldPrint) console.log(build, base)

    // print all possible permutations of R.head(k) with a space inserted between each
    // possible character (but only one space at a time)
    // e.g., for 'おはよう' produce ['お はよう', 'おは よう', 'おはよ う', 'おはよう']
    let kIndex = R.indexOf(R.head(k), base)
    let len = R.length(R.head(k))
    let counter = 0
    let dropLength = R.head(k).length

    for (let i = 0; i < highestBinary(R.head(k)); i++) {
      const permData = spacesPermutation(R.head(k), i)
      const newHead = permData.head
      dropLength = R.head(k).length + permData.drop.length
      kIndex = R.indexOf(newHead, base)
      if (kIndex !== -1) break
    }

    // if (shouldPrint) console.log(R.head(k), base)
    // if (shouldPrint) console.log(kIndex)

    const new_b = [
      ...build, [R.take(kIndex, base)], k
    ].filter((k) => k.length && k[0] !== '')
    // if (shouldPrint) console.log(new_b)

    const new_k = R.drop(kIndex + dropLength, base)
    // if (shouldPrint) console.log(new_k)

    return { build: new_b, base: new_k }
  }

  const res = R.reduce(buildProg, { build: [], base: progressified }, wlPairs)
  // if (shouldPrint) console.log(res)
  // if (shouldPrint) console.groupEnd()
  return [...res.build, [res.base]]
}

// given a progressified string of the form:
//
// おじいさんは のどが とてもかわいていたので、水 をさ がしました。
//
// as well as a furi map of the form:
// 0: ['お'],
// 1: ['爺', 'じい'],
// 2: ['さんは'],
// 3: ['喉', 'のど'],
// 4: ['がとても'],
// 5: ['乾', 'かわ'],
// 6: ['いていたので、'],
// 7: ['水', 'みず'],
// 8: ['を'],
// 9: ['探', 'さが'],
// 10: ['しました。'],
//
// as well as a progressive settings map containing which characters/kanji we know
// as keys mapping to their furi, return an array of all of the furi pairs (or just kana)
// that can be paired with the kanji in the progressified string
export const pairFuriToKanjiProgNew = (progressified, furiData, progData) => {
  const shouldPrint = progressified === 'あつい夏 の日 でした。とても年 をとったおじいさんが 山を あるい ていました。'
  // console.group(progressified)
  // filter out any of the kanji not in the progressive map
  const inWL = R.all((char) => progData[char] || isNotKanjiOrKana(char))
  const wlPairs = R.filter(R.compose(inWL, R.head), furiData)
  if (shouldPrint) console.log(wlPairs)

  const buildProg = (b, k) => {
    const { build, base } = b
    if (shouldPrint) console.log(build, base)

    const noSpaceBase = base.replace(/\s/g, '')
    const kIndex = R.indexOf(k[0], noSpaceBase)

    // add all of the characters from the normal base except spaces (but count those)
    // so we know how many extra characters we should take and drop from base
    let built = ''
    let spaces = 0
    let counter = 0
    let charCounter = 0
    if (shouldPrint) console.log(k[0])
    while (built !== k[0] && counter < base.length) {
      if (base[counter] === ' ') {
        spaces++
      } else {
        if (base[counter] === k[0][charCounter]) {
          built += base[counter]
          charCounter++
        }
      }
      counter++
    }
    if (shouldPrint) console.log(built, spaces, counter)

    const new_b = [
      ...build, [R.take(counter - 1, base)], k
    ].filter((k) => k.length && k[0] !== '')

    const new_k = R.drop(counter, base)

    return { build: new_b, base: new_k }
  }

  const res = R.reduce(buildProg, { build: [], base: progressified }, wlPairs)

  const result = [...res.build, [res.base]]
  console.groupEnd()
  return result
}

export const patchReading = (progressified, reading) => {
  let newReading = ''
  const diff = Diff.diffChars(progressified, reading)

  // 五(ご)ページに戻(もど)って下(くだ)さい。(base text)
  // ごページにもどってください。           (normal reading)
  // 五ページに もどってください。          (progressive text)
  // ごページに もどってください。          (target reading)

  // 学(がっ)校(こう)まで歩(ある)いて二(に)十(じゅう)五(ご)分(ふん)、かかります。
  diff.forEach((part, idx) => {
    const { added, removed, value } = part

    if (idx > 0 && diff[idx - 1].removed && diff[idx - 1].value.indexOf(' ') === 0) {
      newReading += ' '
    }

    // special case ka/ke like in kagetsu
    if (added && idx > 0 && diff[idx - 1].removed && (diff[idx - 1].value.slice(-1) === 'ヵ' || diff[idx - 1].value.slice(-1) === 'ヶ')) {
      const lastChar = value.slice(-1)
      const prevValue = diff[idx - 1].value
      const prevLastChar = prevValue.slice(-1)

      if (lastChar === 'か' && prevLastChar === 'ヵ') {
        newReading += value.slice(0, -1) + 'ヵ'
      } else if (prevLastChar === 'ヶ') {
        newReading += value.slice(0, -1) + 'ヶ'
      }
    } else if (added || (removed && value === ' ')) {
      newReading += value
    } else if (!added && !removed) {
      newReading += value
    }

    if (idx > 0 && diff[idx - 1].removed && diff[idx - 1].value.indexOf(' ') > 0) {
      newReading += ' '
    }
  })

  return newReading.replace(/ +/g, ' ')
}

// DISABLE TEMPORARILY FOR LIVE
const isOnYomi = (kanji, furi, map) => {
  // kanji compound with one furi block
  if (false && kanji.length > 1) {
    const onComp = R.pipe(
      // [上, 手]
      R.split(''),
      // [on(上), on(手)]
      R.map(R.compose(R.prop('on_yomi'), R.flip(R.prop)(map))),
      // [on(上) * on(手)]
      R.xprod(R.join('')),
      // any([on(上) * on(手)] === furi)
      R.any(R.equals(furi))
    )(kanji)
    // return onComp
  }
  const on_yomi = map?.[kanji]?.on_yomi
  const flat_on = on_yomi?.map(wanakana.toHiragana)
  if (flat_on?.includes(furi)) return true

  // check if prefix furi
  if (furi[furi.length - 1] === '-') {
    const pre = furi.slice(0, -1)
    if (flat_on?.includes(pre)) return true
  }

  if (!flat_on) return false

  // check if this is suffix furi
  for (let i = 0; i < flat_on.length; i++) {
    const on = flat_on[i]

    // check if creating a がっこう type deal with small tsu
    if (furi.indexOf('っ') !== -1) {
      if (furi[0] === on[0]) return true
    }

    if (on[0] === '-') {
      const post = on.substring(1)
      if (furi === post) return true
  
      // check dakuten/handakuten
      const dak = String.fromCharCode(post.charCodeAt(0) + 1) + post.substring(1)
      if (furi === dak) return true
  
      const han = String.fromCharCode(dak.charCodeAt(0) + 1) + post.substring(1)
      if (furi === han) return true
    }
  }

  return false
}

const isKunYomi = (kanji, furi, map) => {
  const flat_kun = map?.[kanji]?.kun_yomi
  if (flat_kun?.includes(furi)) return true

  if (!flat_kun) return false

  // check if this is suffix furi
  for (let i = 0; i < flat_kun.length; i++) {
    const kun = flat_kun[i]

    // check if prefix furi
    if (kun[kun.length - 1] === '-') {
      const pre = kun.slice(0, -1)
      if (pre === furi) return true
    }

    // check if pre-dot
    if (kun.indexOf('.') !== -1) {
      if (furi === kun.split('.')[0]) {
        return true
      }
    }

    let post = kun
    if (kun[0] === '-') {
      post = kun.substring(1)
    }

    if (furi === post) return true

    // check dakuten/handakuten
    const dak = String.fromCharCode(post.charCodeAt(0) + 1) + post.substring(1)
    if (furi === dak) return true

    const han = String.fromCharCode(dak.charCodeAt(0) + 1) + post.substring(1)
    if (furi === han) return true
  }

  return false
}

// const isOnYomi = (kanji, furi, map) => false
// const isKunYomi = (kanji, furi, map) => false

const SoundIcon = (props) => {
  const [record, setRecord] = useState(props.record)
  const [audio, setAudio] = useState(null)
  const [playing, setPlaying] = useState(false)
  const [note, setNote] = useState(props.teacherNote)
  const [furiMap, setFuriMap] = useState({})
  const [progressiveFuri, setProgressiveFuri] = useState({})
  const [bonusData, setBonusData] = useState(null)
  const [holdsFuri, setHoldsFuri] = useState(false)
  const [furiVisible, setFuriVisible] = useState(false)
  const [onTapTimer, setOnTapTimer] = useState(null)
  const location = useLocation()
  const isMobile = useMobile()
  const currentSoundPopup = useSelector((state) => state.soundPopup?.activePopup)
  const progSettings = useSelector((state) => state.auth.progressive_settings)
  const [browser, os] = useBrowser()
  const [noIcon, setNoIcon] = useState(false)
  // const grammar = useGrammar(props.record?.base_text, props.record?.breakdown)

  const { progressive_settings: { main_default, furi_opacity } = {}, progressive_data } = props
  const custom_enabled = progSettings?.custom_progressive?.enabled
  const custom_settings = progSettings?.custom_progressive?.settings
  const {
    inHeader,
    bold,
    yellow,
    path,
    widget,
    onSave,
    noPopup,
    noSound,
    onSound,
    num,
    playingNum,
    ping,
    colorized_furi,
    male,
    female,
    format,
    icon,
    resizeAfter
  } = props

  const iconOnly = bonusData?.sound_icon_display === 'icon only'

  const kanjiData = R.compose(R.indexBy(R.prop('literal')), R.unnest)(props.kanjiData || [])

  useEffect(() => {
    setNoIcon(
      bonusData?.sound_icon_display === 'no icon'
    || (bonusData?.no_sound && bonusData?.sound_icon_display !== 'default' && bonusData?.sound_icon_display !== 'icon only')
    )
  }, [bonusData])

  // useEffect(() => {
  //   console.log('no icon', noIcon)
  // }, [noIcon])

  useEffect(() => {
    let isMounted = true
    if (record || props.record) return;
        
    axios.post('/lang_records/', { type: 'JapaneseRecord', base_text: props.text, widget: props.widget })
      .then((res) => {
        if (isMounted) setRecord(res.data.record)
      })
      .catch((err) => console.error(err))

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    let sub
    if (audio) {
      // props.onSoundFound(audio)
      sub = PubSub.subscribe('AUDIO PLAY', () => {
        console.log('audio play sound record listener')
        audio.pause()
        audio.onended(-1)
        audio.currentTime = 0
      })
    }

    return () => { if (sub) PubSub.unsubscribe(sub) }
  }, [audio])

  useEffect(() => {
    if (!record && ping) {
      console.log('pinging with no record')
      ping()
    } else if (playingNum !== undefined && num !== undefined && playingNum === num) {
      console.log('playing normal')
      if (audio) audio.play()
    }
  }, [playingNum])
    
  useEffect(() => {
    console.log(record)
    if (!record) return;

    setBonusData(record.bonus)

    // create individual Kanji-mapped furi from record
    const furiRegex = /\([０-９？　-龯_]+\)/g

    const furis = record.base_text.match(furiRegex)?.map((f) => f.replace(/[()]/g, ''))
    const splitRecord = record.base_text.split(furiRegex).filter((e) => e !== '')

    if (furis) {
      const newFuriMap = {}

      let kanaOffset = 0
      for (let i = 0; i < splitRecord.length; i++) {
        // ensure this index into the kanji string is kanji
        while (i + kanaOffset < record.kanji.length && (!wanakana.isKanji(record.kanji[i + kanaOffset]) && record.kanji[i + kanaOffset] !== '々')) {
          kanaOffset++
        }
        newFuriMap[i + kanaOffset] = furis[i]
        if (wanakana.isKanji(splitRecord[i]) && splitRecord[i].length > 1) kanaOffset += splitRecord[i].length - 1
      }

      setFuriMap(newFuriMap)
    }

    // don't unnecessarily query icons that are duplicated
    if (record.teacherNote) {
      setNote(record.teacherNote)
    }

    // choose the sound based on the speaker override of the bonus data first
    let a
    console.log(record.sounds)
    if (record.bonus?.force_speaker) {
      switch (record.bonus.force_speaker?.toLowerCase()) {
        case 'kanako':
          a = new Audio(record.sounds['Kanako'])
          break
        case 'hikari':
          a = new Audio(record.sounds['Hikari'])
          break
        case 'kairo':
          a = new Audio(record.sounds['Kairo'])
          break
        default:
          break
      }
    }

    // choose the sound based on the gender override of the bonus data second (if it exists)
    if (!a && record.bonus) {
      if (record.bonus.force_gender) {
        switch (record.bonus.force_gender) {
          case 'm': case 'M': case 'male': case 'Male': case 'man': case 'Man': case 'boy':
            a = new Audio(record.sounds['Takeru'] || record.sounds['Mr. Fukumachi'])
            break
          case 'f': case 'F': case 'female': case 'Female': case 'Woman': case 'W': case 'w': case 'woman': case 'girl':
            a = new Audio(record.sounds['Kanako'] || record.sounds['Hikari'] || record.sounds['Seoyeon'])
            break
          default:
            break
        }
      }
    }

    // console.log(male, female)

    // if male or female are passed as a prop instead (from convos etc)
    if (!a && female) {
      a = new Audio(record.sounds['Kanako'] || record.sounds['Hikari'] || record.sounds['Seoyeon'])
    } else if (!a && male) {
      a = new Audio(record.sounds['Takeru'] || record.sounds['Mr. Fukumachi'])
    }

    if (!a) {
      a = new Audio(record.sounds[props.preferred_speaker]
      || record.sounds['Kanako'] || record.sounds['Mr. Fukumachi']
      || record.sounds['Yukari'] || record.sounds['Hikari']
      || record.sounds['Takeru'] || record.sounds['default']
      || record.sounds['hyunwoo'] || record.sounds['sunhee']
      || record.sounds['Seoyeon'])
    }

    // console.log('no icon in effect', noIcon)
    // console.log(onSound)
    if (onSound) onSound(num, !noIcon)
    a.onplay = () => setPlaying(true)
    a.onended = (n) => {
      console.log('pinging n')
      setPlaying(false)
      if (ping) ping(n)
    }

    setAudio(a)

    // unload audio at end of lifecycle
    return () => { a.src = '' }
  }, [record, props.preferred_speaker, noIcon])

  const playAudio = (e, a11y) => {
    if (!a11y) e.stopPropagation()
    PubSub.publishSync('AUDIO PLAY')

    if (!playing) audio.play()
  }

  const ja_span_styles = {
    fontFamily: (inHeader || bold)
      ? 'Noto Sans JP Bold, Milliard Bold'
      : 'Noto Sans JP, Yu Gothic UI Regular, Milliard Book, Helvetica, sans-serif', // Noto Sans JP, removed for now
    fontWeight: inHeader ? 'unset' : (bold ? 900 : 200),
    color: yellow ? 'yellow' : '',
  }

  if (format) {
    if (format?.bold) {
      ja_span_styles.fontWeight = 900
    }
    if (format?.italic) {
      ja_span_styles.fontStyle = 'italic'
    }
    if (format?.underline) {
      ja_span_styles.textDecoration = 'underline'
    }
    if (format?.color) {
      ja_span_styles.color = format.color
    }
    if (format?.highlight) {
      ja_span_styles.backgroundColor = format.highlight
    }
    if (format?.strikethrough) {
      if (ja_span_styles.textDecoration) {
        ja_span_styles.textDecoration += ' line-through'
      } else {
        ja_span_styles.textDecoration = 'line-through'
      }
    }
  }

  const stringJoinLast = R.compose(R.join(''), R.map(R.last))

  const RomajiTextSpan = () => {
    const { furis, splitRecord } = recordSplit(record)
    // console.log(furis, splitRecord)
    const newFuriData = createFuriData(splitRecord, furis)

    // create reading just from the kana
    // console.log(newFuriData)
    const reading = stringJoinLast(newFuriData)
    // console.log(reading)

    const fullStringLength = newFuriData.reduce((p, c) => p += c[0].length, 0)

    let parentStyle = {}

    if (resizeAfter && fullStringLength >= resizeAfter) {
      const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter) * (isMobile ? 0.04 : 0.03)
      parentStyle.fontSize = `${newSize}rem`
      ja_span_styles.fontSize = `${newSize}rem`
    }

    return (
      <span style={ja_span_styles}>
        {progressify(reading, true, main_default, props.progressive_data, record, bonusData)}
      </span>
    )
  }

  const ProgressiveTextSpan = () => {
    const { furis, splitRecord } = recordSplit(record)
    const newFuriData = createFuriData(splitRecord, furis)
    // console.log(furis, splitRecord, newFuriData)

    // replace kanji with kana if they're not in the progressive map
    const word = newFuriData.reduce((p, c) =>
      p += (R.any((e) => wanakana.isKanji(e) && !progressive_data[e])([...c[0]]) ?
        (c[1] || c[0]) : c[0]), '')

    // create reading just from the kana
    let reading = stringJoinLast(newFuriData)

    const progMap = custom_enabled ? custom_settings : progressive_data

    // generate progressive version of text with spaces
    let progressified = progressify(word, false, 'progressive', progMap, record, bonusData, newFuriData)

    // prevent overlaying reading atop romaji
    if (wanakana.isRomaji(progressified)) {
      reading = ''
    }
        
    // calculate string diff to prepare space injection into reading
    // reading and progressive text need to properly line up, spaces and all
    reading = patchReading(progressified, reading)
    reading = reading.replace('?', '？')
    progressified = progressified.replace('?', '？')

    // check for the presence of any kanji characters in progressified
    const kanji = R.any((e) => wanakana.isKanji(e))(progressified)
    const builtProg = kanji ? pairFuriToKanjiProg(progressified, newFuriData, progMap) : [[progressified]]
    // console.log(builtProg)

    // console.log(progressified)
    // console.log(builtProg)

    const fullStringLength = newFuriData.reduce((p, c) => p += c[0].length, 0)

    let parentStyle = {}

    if (resizeAfter && fullStringLength >= resizeAfter) {
      const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter) * (isMobile ? 0.04 : 0.03)
      parentStyle.fontSize = `${newSize}rem`
      ja_span_styles.fontSize = `${newSize}rem`
    }

    return (
      record && builtProg?.map(([text, furigana], index) => (
        // eslint-disable-next-line no-nested-ternary
        text === ' ' ? text : (
          furigana ? (
            <ruby key={`${index}-${furigana}`} style={{ ...ja_span_styles, display: `${furigana ? '' : 'inline-flex'}` }}>
              {text || ' '}
              {furigana && <rt style={{ textAlign: 'center' }} className={furi_opacity < 10 ? 'furi-on-hover' : ''}>{furigana}</rt>}
            </ruby>
          ) : (
            [...text].map((t, idx) => (
              <ruby key={`${text}-${t}-${idx}-${index}-${furigana}-${widget}-r`} style={{ ...ja_span_styles, display: `${furigana ? '' : 'inline-flex'}` }}>
                <span key={`${text}-${t}-${idx}-${index}-${furigana}-${widget}-text`}>{t === ' ' ? <>&nbsp;</> : t}</span>
                {furigana && <rt style={{ textAlign: 'center' }} className={furi_opacity < 10 ? 'furi-on-hover' : ''}>{furigana}</rt>}
              </ruby>
            ))
          )
        )))
    )
  }
    
  // ニ(に)十(じゅっ)分(ぷん)ぐらいです。
  // まだニ(に)十(じゅっ)分(ぷん)です。
  // ニ(に)十(じゅっ)分(ぷん)頃(ごろ)です。
  const KanjiTextSpan = () => {
    const { furis, splitRecord } = recordSplit(record)

    const newFuriData = createFuriData(splitRecord, furis)

    useEffect(() => {
      if (holdsFuri) return
      for (const i of newFuriData) {
        if (i.length > 1) {
          setHoldsFuri(true)
          break
        }
      }
    }, [newFuriData])

    const fullStringLength = newFuriData.reduce((p, c) => p += c[0].length, 0)

    let parentStyle = {}

    if (resizeAfter && fullStringLength >= resizeAfter) {
      const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter) * (isMobile ? 0.04 : 0.03)
      parentStyle.fontSize = `${newSize}rem`
      ja_span_styles.fontSize = `${newSize}rem`
    }

    return (
      <>
        {newFuriData.map(([word, reading], i) =>
          <ReactFuri
            style={parentStyle}
            word={word}
            key={i}
            reading={reading}
            render={({ pairs }) => (
              pairs.map(([furigana, text], index) => (
                <ruby key={`${index}-${furigana}`} style={{ ...ja_span_styles }}>
                  {progressify(text, false, main_default, progressive_data, record, bonusData, newFuriData)}
                  {furigana && <rt style={{ textAlign: 'center' }} className={`
                    ${furi_opacity < 10 ? 'furi-on-hover' : ''}
                    ${colorized_furi && (isOnYomi(text, furigana, kanjiData) ? 'furi-onyomi' : (isKunYomi(text, furigana, kanjiData) ? 'furi-kunyomi' : 'furi-exception'))}
                  `}>{furigana}</rt>}
                </ruby>
              ))
            )}
        />)}
      </>
    )
  }

  const NonRomajiTextSpan = () => {
    const { furis, splitRecord } = recordSplit(record)
    const newFuriData = createFuriData(splitRecord, furis)
    // replace kanji with kana if they're not in the progressive map
    const word = newFuriData.reduce((p, c) =>
      p += (R.any((e) => wanakana.isKanji(e) && !progressive_data[e])([...c[0]]) ?
        (c[1] || c[0]) : c[0]), '')

    // create reading just from the kana
    const reading = newFuriData.reduce((p, c) => p += (c[1] || c[0]), '')

    const fullStringLength = newFuriData.reduce((p, c) => p += c[0].length, 0)

    let parentStyle = {}

    if (resizeAfter && fullStringLength >= resizeAfter) {
      const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter) * (isMobile ? 0.04 : 0.03)
      parentStyle.fontSize = `${newSize}rem`
      ja_span_styles.fontSize = `${newSize}rem`
    }

    console.log(reading)

    return (
      reading?.split('')?.map((c) =>
        <ruby key={`${reading}-${c}`} style={{ ...ja_span_styles, display: 'inline-flex' }}>
          <span>{c}</span>
        </ruby>
      )
    )
  }

  const PlayButton = () => {
    return (
      <Icon
        tabIndex="0"
        circular
        onClick={(e) => playAudio(e, false)}
        onKeyUpCapture={(e) => e.key === 'Enter' && playAudio(e, true)}
        className="no-select FZPlayButton"
        style={{
          cursor: 'pointer',
          color: 'var(--off-white)',
          // eslint-disable-next-line no-nested-ternary
          backgroundColor: Object.keys(record.sounds)?.length > 0
            ? ((playing || props.playing)
              ? '#e83563'
              : '#28c6aa'
            )
            : '#c1d7d3',
          boxShadow: 'none',
          fontSize: '0.75rem',
          position: 'relative',
          top: '-4px',
          display: 'inline-flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        // eslint-disable-next-line no-nested-ternary
        name={Object.keys(record.sounds)?.length > 0
            ? ((playing || props.playing)
              ? 'stop'
              : 'play'
            )
            : 'warning'}
      />
    )
  }

  useEffect(() => {
    // check that there's actually a sound popup in state so we don't flood redux with empty actions
    if (!currentSoundPopup) return

    // because of the glory of redux, the popup will stay open
    // if we move to another page with a SoundInjector on it
    // i.e., courses -> notebook
    // and also between page refreshes
    // so set the popup id back to null if location changes
    setFuriVisible(false)
    props.setPopup(null)
  }, [location])

  const openPopup = (e) => {
    if (props.noPopup) return

    const rect = isMobile ? { x: 0, y: 0 } : e?.target?.getBoundingClientRect()
    // console.log(rect)
    // console.log(window.scrollY, window)
    // console.log(props.widget)
    // console.log(props.path)
    console.log(props.lessonPath)
    props.setPopup({
      path: props.path || props.widget || props.lessonPath,
      record,
      widget: props.widget,
      note,
      onSave: props.onSave,
      x: rect.x,
      y: rect.bottom + rect.height + window.scrollY + (window.scrollY ? 400 : 0),
    })
    setOnTapTimer(null)
  }

  const handleSoundPopup = (e) => {
    const recordId = record?._id || props?.record?._id || null
    if (!recordId) return

    if (isMobile) {
      if ((['progressive', 'kanji'].includes(main_default))) setFuriVisible(true)
      else setFuriVisible(false)
    }

    openPopup(e)
  }

  // const longPressEvent = useLongPress(
  //   handleSoundPopup,
  //   handleSoundPopup,
  //   { delay: isMobile ? 300 : 0 }
  // )

  const browserShort = useMemo(() => {
    if (!browser) return 'chrome'
    return browser.split(' ')[0].toLowerCase()
  }, [browser])

  const osShort = useMemo(() => {
    if (!os) return 'windows'
    return os.split(' ')[0].toLowerCase()
  }, [os])

  const bonusForceMode = bonusData?.force_display

  const forceMode = {
    kana: 'hirakata',
    kanji: 'kanji'
  }[bonusForceMode]

  // Wrapper as <blockquote> for letting screen readers know the text inside of this is not English
  return (record || props.record) ? (
    <SoundIconStyleWrapper
      noPopup={props?.noPopup}
      playing={(Object.keys(record.sounds)?.length > 0 && (playing || props.playing)) && true}
      holdsFuri={holdsFuri}
      progSetting={main_default}
      furiOpacity={furi_opacity}
      lang="jp"
      inverted={props.inverted}
      white={props.white}
      wrapAtIcon={props.wrapAtIcon}
      browser={browserShort}
      os={osShort}
      noFuri={bonusData?.no_furi}
    >
      {props?.lineNum && <TNumSpan>{props.lineNum}</TNumSpan>}
      <span className='sound'>
        {props?.icon && !props.record?.icon && !noIcon && <PlayButton />}
        <span>
          <span
            tabIndex={0}
            className={isMobile && furiVisible ? ' visible' : ''}
            onKeyUpCapture={(e) => e.key === 'Enter' && handleSoundPopup(e)}
            onClick={noPopup ? ((e) => e.preventDefault()) : ((e) => handleSoundPopup(e))}
            
            // // for mobile, long tap to open popup
            // onTouchStart={(e) => handleOnTap(e)}
            
            // // onTouchX doesn't seem to trigger in emulated mobile device, so here's this
            // onPointerUp={handleTapLift}
            // onPointerOut={handleTapLift}
            // onPointerMove={handleTapLift}
            // onTouchEndCapture={handleTapLift}
            // onTouchEnd={handleTapLift}

            // // still let user select text without opening popup
            // onTouchMoveCapture={handleTapLift}
            // onTouchMove={handleTapLift}
            // onTouchCancel={handleTapLift}
          >
            {
              iconOnly ? <>&nbsp;&nbsp;</> // retains ability to open popup
              : {
                romaji: <RomajiTextSpan />,
                kanji: <KanjiTextSpan />,
                progressive: <ProgressiveTextSpan />,
                hirakata: <NonRomajiTextSpan />
              }[forceMode || main_default || 'progressive']
            }
          </span>
          {note && <TeacherNote white={props.white} text={note?.text} />}
        </span>
      </span>
    </SoundIconStyleWrapper>
  ) : (
    <Loader active inline />
  )
}

const mapStateToProps = (state) => {
  return {
    progressive_settings: state.auth.progressive_settings,
    progressive_data: state.auth.progressive_data,
    preferred_speaker: state.auth.preferred_speaker,
    colorized_furi: state.auth.colorized_furi
  }
}

export default connect(mapStateToProps, { setPopup })(SoundIcon)
