import styled from 'styled-components';

const ConvoBlockBody = styled.div`
  width: 100%;
  margin-top: 12px;
`

const ResponsePairs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  > div {
    width: 100%;
  }
`

const ConvoSpeaker = styled.div`
  width: 100%;
  margin: 10px 0;
`

const SpeakerSelectionGroup = styled.div`
  width: 100%;
  margin-left: 50px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 50px;
`

const SpeakerSelectionGrid = styled.div`
  >div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 25px;
    gap: 10px;
  }
`

interface ClickableBubbleProps {
  ringColor: string
  selected: boolean
}

const ClickableBubble = styled.div<ClickableBubbleProps>`
  width: 100px;
  margin: 0 auto;
  text-align: center;
  text-transform: capitalize;

  img {
    position: relative;
    border: 5px solid transparent;
    border-radius: 50%;
    padding: 10px;
    margin-bottom: 8px;
    transition: all 150ms ease-out;
    cursor: pointer;

    background-color: ${({ ringColor, selected }) => selected && `rgb(${ringColor})`};

    :hover {
      background: radial-gradient(
        transparent,
        rgba(${({ ringColor }) => ringColor}, 0.75),
        rgba(${({ ringColor }) => ringColor}, 1)
      );
    }
  }
`

const CurrentSpeaker = styled.div`
  font-weight: bold;
  text-transform: capitalize;
  color: var(--blue-gray);

  span {
    display: inline-flex;
    flex-direction: row;
    align-items: center;

    background-color: var(--blue-gray);
    color: white;
    margin-left: 8px;
    padding: 8px 12px;
    border-radius: 8px;

    cursor: pointer;

    &:hover {
      background-color: var(--buttons);
    }

    img {
      margin-left: 8px;
      width: 35px;
    }
  }
`

const ConversationLine = styled.div`
  display: flex;
  justify-content: stretch;
  width: 100%;

  >img {
    object-fit: cover;
    width: 45px;
    height: 45px;
    margin: 8px;
    margin-right: 0;
  }
`

const ConversationStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 8px;
  width: 100%;

  >div {
    width: 100%;
    margin: 8px 0;

    input {
      width: 100%;
    }
  }
`

const DeleteConversationLineButton = styled.button`
  border: none;
  border-radius: 8px;
  background-color: transparent;
  padding: 0 8px;

  i { color: var(--red); }

  :hover {
    background-color: var(--red);
    i { color: white; }
  }
`

export {
  ClickableBubble,
  ConversationLine,
  ConversationStack,
  ConvoBlockBody,
  ConvoSpeaker,
  CurrentSpeaker,
  DeleteConversationLineButton,
  ResponsePairs,
  SpeakerSelectionGroup,
  SpeakerSelectionGrid,
}
