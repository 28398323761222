import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { KANJI, KANJI_MEMORY } from '../toolkit.constants'
import { KanjiExamples, KanjiMemoryWidget } from 'src/types'

import CourseCreationToolkit from '../CourseCreationToolkit'
import {
  ListInputDivider,
  ToolFooter,
  ToolHeader,
  ToolInput,
  ToolOptions,
  ToolSubheader,
} from '../parts'

import ToolForm from '../parts/ToolForm.part'

import { KanjiBox, KanjiExampleBox, KanjiInfo, KanjiReadings, TwoInputRow } from '../styles/KanjiTool.styles'
import { EmptyPadding, ListSectionDivider, PairedField, ToolBody } from '../styles'
import { PairedFieldsWithIcon } from '../styles/UsageTool.styles'
import { DeleteConversationLineButton } from '../styles/ConversationTool.styles'
import { Icon } from 'semantic-ui-react'
import useWidgetSaved from '../hooks/useWidgetSaved'

const KanjiMemoryTool: React.FC<ToolProps<KanjiMemoryWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<KanjiMemoryWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  
  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  const commitTemporaryChanges = (examples: KanjiExamples[]) => {
    // hookFormMethods.setValue('examples', examples)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })

      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const kanjiTopFields = {
    onyomi: ['onyomi', 'Japanese origin reading'],
    kunyomi: ['kunyomi', 'Chinese origin reading'],
  }

  const buildFields = {
    build: ['build', 'Kanji build (e.g. 木 + 木 = 林)'],
    build_sentence: ['build_sentence', 'Sentence using the kanji build'],
  }
  
  const readFields = {
    read_other: ['read_other', 'Other lang (e.g., Japanese) reading example'],
    read_english: ['read_english', 'English reading example translation'],
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={KANJI_MEMORY}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={KANJI_MEMORY}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell} />
          <ToolSubheader>
            <KanjiBox>
              <label htmlFor='kanji' title='Kanji character to display'>Kanji</label>
              <input
                type='text'
                maxLength={1}
                title='Kanji character to display'
                {...hookFormMethods.register('kanji')}
              />
            </KanjiBox>
          </ToolSubheader>
          <ToolBody>
            <KanjiInfo>
              <KanjiReadings>
                {Object.entries(kanjiTopFields)
                  .map(([registeredValue, [label, placeholder]], idx) => (
                    <div key={`${registeredValue}-${idx}`}>
                      <ToolInput
                        value={registeredValue}
                        inputLabel={label}
                        placeholder={placeholder}
                        title={placeholder}
                      />
                    </div>
                  ))
                }
              </KanjiReadings>
              <EmptyPadding />
              <KanjiReadings>
                {Object.entries(buildFields)
                  .map(([registeredValue, [label, placeholder]], idx) => (
                    <div key={`${registeredValue}-${idx}`}>
                      <ToolInput
                        removeMargin
                        value={registeredValue}
                        inputLabel={label}
                        title={placeholder}
                      />
                    </div>
                  ))
                }
              </KanjiReadings>
              <EmptyPadding />
              <KanjiReadings>
                {Object.entries(readFields)
                  .map(([registeredValue, [label, placeholder]], idx) => (
                    <div key={`${registeredValue}-${idx}`}>
                      <ToolInput
                        removeMargin
                        value={registeredValue}
                        inputLabel={label}
                        title={placeholder}
                      />
                    </div>
                  ))
                }
              </KanjiReadings>
              {/* <ToolInput
                value='meaning'
                placeholder='English meaning for the kanji'
                title='English meaning for the kanji'
              /> */}
              <EmptyPadding />
            </KanjiInfo>
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          >
            <ToolInput
              value='plays_sound'
              inputType='checkbox'
              placeholder='Play Sound'
              inputLabel='Play Sound'
              labelColor='white'
              labelPosition='after'
            />
          </ToolFooter>
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default KanjiMemoryTool
