import React, { useEffect, useState } from 'react'
import axios from '../../../../../api'

import { Button, Card, Form } from 'semantic-ui-react'

import ImageWidgetEditor from '../ImageWidgetEditor'

import useWidget from '../../../hooks/useWidget'

const ImageQuestionWidgetEditor = ({id, course, lesson, sublesson}) => {
    const [widget, fetchWidget, loading, editWidget] = useWidget(id)
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        setQuestions(widget?.questions)
    }, [widget])

    const addQuestion = () => {
        axios.post(`/widgets/activities/image/${id}/add`)
        .then(res => {
            fetchWidget()
        })
    }

    const setQuestion = (idx, value) => {
        let tempQuestions = [...questions]
        tempQuestions[idx].question = value
        setQuestions(tempQuestions)
    }

    return (
        <div>
            <Card.Group itemsPerRow={2}>
                {questions?.map((q, idx) =>
                    <Card>
                        <Form>
                            <Form.Input
                                inline
                                label='Question'
                                value={questions[idx].question}
                                onChange={(e, {value}) => setQuestion(idx, value)}
                            />
                            <ImageWidgetEditor id={q.image_widget} embed course={course} lesson={lesson} sublesson={sublesson} />
                        </Form>
                    </Card>)}
            </Card.Group>
            
            <Button color='blue' icon='plus' onClick={addQuestion} />
            <Button color='green' icon='save' onClick={() => editWidget({questions})} />
        </div>
    )
}

export default ImageQuestionWidgetEditor