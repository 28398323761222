import { useCallback, useEffect, useRef, useState } from 'react'
import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
// import { setFlashMessage } from '../context/FlashMessageContext'

import axios from 'src/api'

// T = type defined at run time as opposed to compile time
type APIHook<T> = [
  T | undefined,
  boolean,
  // eslint-disable-next-line
  (arg?: any) => Promise<void>,
  AxiosResponse | undefined,
  () => void
];

export type Method = 'GET' | 'LAZYGET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

/**
  * @description A hook setup to use API calls. Automatically makes `GET`
  *   requests and catches potential errors.
  * @template T The type of data expected to be returned in the response.
  * @param {string} url The URL to request the data from.
  * @param {Method} [method='GET'] The REST method to use for the request,
  *   options include `GET`, `POST`, `PUT`, `PATCH`, and `DELETE`
  * @returns {APIHook<T>} The return of this hook has 5 parts in an array:
  * - `response` which is the most recently returned data.
  * - `loading` a boolean which represents if a request is in a pending state.
  * - `request()` a function that makes the request, any arguments passed in
  *     will be passed into the axios call.
  * - `error` which is any errors that occurred with the request, this is reset
  *     if a new request is made without errors.
  * - `reset()` a function that resets all errors and responses
* */

// eslint-disable-next-line
const useAPI = <T = any>(url: string, method: Method = 'GET'): APIHook<T> => {
  const [response, setResponse] = useState<T>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosResponse>()
  const abortController = useRef(new AbortController())

  const request = useCallback(async (...args) => {
    if (loading || error || !url) return
    setLoading(true)
    let res: AxiosResponse<T>

    if (abortController.current) abortController.current.abort()

    abortController.current = new AbortController()

    try {
      const config: AxiosRequestConfig = {
        signal: abortController.current.signal,
        method: method === 'LAZYGET' ? 'GET' : method,
        ...args[0],
        url,
        data: args[0]
      }

      res = await axios(config)

      setResponse(res.data)
    } catch (err) {
      // console.log(url, method, err)
      if (abortController.current.signal.aborted) {
        // console.log('Request aborted')
        return
      }
      const e = err as AxiosError
      setError(e?.response)
    }

    setLoading(false)
  }, [method, url])

  const reset = useCallback(() => {
    setResponse(undefined)
    setError(undefined)
    setLoading(false)
    if (abortController.current) abortController.current.abort()
  }, [])

  useEffect(() => {
    if (method === 'GET') request()
    return () => {
      if (abortController.current) abortController.current.abort()
    }
  }, [method, request])

  return [response, loading, request, error, reset]
}

export default useAPI
