// hook that watches for a change in the window's location and does a refetch of the user's custom
// progressive settings at /progressive_settings and updates redux state with it
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import useAPI from 'src/hooks/useAPI'
import { REDUX_ACTIONS } from 'src/types'

const useCustomProgressive = () => {
  const dispatch = useDispatch()
  const [res, loading, req] = useAPI('/progressive_settings', 'GET')

  useEffect(() => {
    // console.log(res)
    if (res?.success) {
      dispatch({
        type: REDUX_ACTIONS.CHANGE_SETTING,
        payload: res.progressive_settings
      })
    }
  }, [res])

  return {
    loading, refetch: req
  }
}

export default useCustomProgressive
