// import ReactFuri from 'react-furi'
import React from 'react'
import * as R from 'ramda'
import { Record } from 'src/types'
import { useSelector } from 'react-redux'

import { RootState } from 'src/configureStore'
import { recordSplit, createFuriData, generateTokens } from './sound_icon_util'
import { JP_SpanStyles } from './SoundIcon.types'
import { createFormatStyles } from './ProgressiveTextSpan'

// import JapaneseSpan from './JapaneseSpan'

type HirakataTextSpanProps = {
  record: Record
  resizeAfter?: number
  styles?: JP_SpanStyles
  isMobile?: boolean
  formatText?: string
}

const removeAllSpacesButAfterParticles = (tokens) => {
  const newTokens = [...tokens]

  // enumerate over tokens
  return newTokens.map((token, idx) => {
    const newToken = { ...token }
    newToken.pre = false
    if (token.bd.pos !== '助詞') {
      newToken.post = false
    }

    // remove post if the next bd token is punctuation
    if (newToken.post) {
      const nextToken = tokens[newTokens.indexOf(token) + 1]
      if (nextToken?.bd.pos === '記号') {
        newToken.post = false
      }
    }

    // add a space after adjectives
    if (newToken.bd.pos === '形容詞') {
      newToken.post = true
    }

    // remove space in front of くない when used with adjectives
    if (newToken.bd?.basic_form === 'ない' && newTokens[idx - 1]?.bd?.pos === '形容詞') {
      newToken.pre = false
    }

    // do the same for post for the prior token
    if (newToken.bd?.pos === '形容詞' && newTokens[idx + 1]?.bd?.basic_form === 'ない') {
      newToken.post = false
    }

    // remove any space after か if it comes before なあ or なぁ
    if (newToken.bd?.basic_form === 'か' && (newTokens[idx + 1]?.bd?.basic_form === 'なあ' || newTokens[idx + 1]?.bd?.basic_form === 'なぁ')) {
      newToken.post = false
    }

    return newToken
  })
}

const HirakataTextSpan: React.FC<HirakataTextSpanProps> = ({
  styles, record, resizeAfter, isMobile, formatText
}) => {
  const calligraphyMode = useSelector((state: RootState) => state.auth?.calligraphy_mode)
  if (record.type === 'KoreanRecord') {
    return (
      <span className="text-2xl font-bold">
        {record.base_text}
      </span>
    )
  }

  const { breakdown } = record
  const { furis, splitRecord } = recordSplit(record)
  const newFuriData = createFuriData(splitRecord, furis)

  const newStyles = { ...styles }

  let progressiveTokens = []
  try {
    progressiveTokens = generateTokens(breakdown, newFuriData, {}, {}, 'kanji', record, formatText)
    // if (record.base_text === '美味(おい)しい') console.log(progressiveTokens)
  } catch (e) {
    console.log(e)
    return <span>{record.base_text}</span>
  }
  progressiveTokens = removeAllSpacesButAfterParticles(progressiveTokens)

  const fullStringLength = newFuriData.reduce((p, c) => p += c[0].length, 0)

  if (newStyles && !styles?.fontSize && resizeAfter && fullStringLength >= resizeAfter) {
    const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter)
      * (isMobile ? 0.04 : 0.03)
    newStyles.fontSize = `${newSize}rem`
  }

  if (calligraphyMode) {
    newStyles.fontFamily = 'Calligraphy'
  }

  const shouldPrint = record.base_text === 'は' && false
  if (shouldPrint) console.log(record.base_text)
  if (shouldPrint) console.log(progressiveTokens)

  // V2 progressive system render
  return (
    <>
      {progressiveTokens?.map(({ text, formatted, pre, post }, index) => (
        <>
          {pre && index > 0 && <>{' '}</>}
          {(formatted?.length ? formatted : text)?.map((token, idy) => {
            let t, _
            let textToken = text[idy]
            const formatStyles = formatted?.length ? createFormatStyles(token?.format, token?.color, token?.highlightColor) : {}
            if (Array.isArray(textToken)) {
              [_, t] = textToken
            } else {
              t = textToken
            }
            if (shouldPrint) console.log(textToken, t, _)
            if (shouldPrint) console.log('will output', t)
            if (shouldPrint) console.log('key is', `${record.base_text}-${t}-${index}-${idy}-r`)
            return (
              <ruby key={`${record.base_text}-${t}-${index}-${idy}-r`} style={{ ...newStyles, ...formatStyles, display: 'inline-flex' }}>
                <span key={`${record.base_text}-${t}-${index}-${idy}-text`}>{t === ' ' ? <>&nbsp;</> : t}</span>
              </ruby>
            )
          })}
          {post && index !== (progressiveTokens?.length - 1) && <span style={{ width: '30px' }}>&nbsp;&nbsp;</span>}
        </>
      ))}
    </>
  )
}

export default HirakataTextSpan
