import React from "react"
import DefaultTemplate from "../templates/DefaultTemplate"
import ArrowTemplate from "../templates/ArrowTemplate"

const defaultVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 }
}

const TemplateRenderer = ({ slide, slideIdx, currentItem, courseColor, variants = defaultVariants }) => {
  const props = { slide, slideIdx, currentItem, courseColor, variants }
  
  switch (slide.template) {
    case 'arrow': return <ArrowTemplate {...props} />
    default: return <DefaultTemplate {...props} />
  }
}

export default TemplateRenderer