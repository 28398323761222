import React from 'react'
import Main from './Game/Main'

export const DUMMY_HIRA = [
  {
    id: 'hA',
    name: 'A',
    label: 'あいうえお',
    characters: [
      ['あ', 'a'],
      ['い', 'i'],
      ['う', 'u'],
      ['え', 'e'],
      ['お', 'o'],
    ],
    lesson: 1,
  },
  {
    id: 'hKA',
    name: 'KA',
    label: 'かきくけこがぎぐげご',
    characters: [
      ['か', 'ka'],
      ['き', 'ki'],
      ['く', 'ku'],
      ['け', 'ke'],
      ['こ', 'ko'],
      ['が', 'ga'],
      ['ぎ', 'gi'],
      ['ぐ', 'gu'],
      ['げ', 'ge'],
      ['ご', 'go'],
    ],
    lesson: 2,
  },
  {
    id: 'hSA',
    name: 'SA',
    label: 'さしすせそざじずぜぞ',
    characters: [
      ['さ', 'sa'],
      ['し', 'shi'],
      ['す', 'su'],
      ['せ', 'se'],
      ['そ', 'so'],
      ['ざ', 'za'],
      ['じ', 'ji'],
      ['ず', 'zu'],
      ['ぜ', 'ze'],
      ['ぞ', 'zo'],
    ],
    lesson: 3
  },
  {
    id: 'hTA',
    name: 'TA',
    label: 'たちつてとだぢづでど',
    characters: [
      ['た', 'ta'],
      ['ち', 'chi'],
      ['つ', 'tsu'],
      ['て', 'te'],
      ['と', 'to'],
      ['だ', 'da'],
      ['ぢ', 'ji'],
      ['づ', 'dzu', 'zu'],
      ['で', 'de'],
      ['ど', 'do'],
    ],
    lesson: 4,
  },
  {
    id: 'hNA',
    name: 'NA',
    label: 'なにぬねの',
    characters: [
      ['な', 'na'],
      ['に', 'ni'],
      ['ぬ', 'nu'],
      ['ね', 'ne'],
      ['の', 'no'],
    ],
    lesson: 5,
  },
  {
    id: 'hHA',
    name: 'HA',
    label: 'はひふへほばびぶべぼぱぴぷぺぽ',
    characters: [
      ['は', 'ha'],
      ['ひ', 'hi'],
      ['ふ', 'fu', 'hu'],
      ['へ', 'he'],
      ['ほ', 'ho'],
      ['ば', 'ba'],
      ['び', 'bi'],
      ['ぶ', 'bu'],
      ['べ', 'be'],
      ['ぼ', 'bo'],
      ['ぱ', 'pa'],
      ['ぴ', 'pi'],
      ['ぷ', 'pu'],
      ['ぺ', 'pe'],
      ['ぽ', 'po'],
    ],
    lesson: 6,
  },
  {
    id: 'hMA',
    name: 'MA',
    label: 'まみむめも',
    characters: [
      ['ま', 'ma'],
      ['み', 'mi'],
      ['む', 'mu'],
      ['め', 'me'],
      ['も', 'mo'],
    ],
    lesson: 7,
  },
  {
    id: 'hYA',
    name: 'YA',
    label: 'やゆよ',
    characters: [
      ['や', 'ya'],
      ['ゆ', 'yu'],
      ['よ', 'yo'],
    ],
    lesson: 8,
  },
  {
    id: 'hRA',
    name: 'RA',
    label: 'らりるれろ',
    characters: [
      ['ら', 'ra'],
      ['り', 'ri'],
      ['る', 'ru'],
      ['れ', 're'],
      ['ろ', 'ro'],
    ],
    lesson: 9,
  },
  {
    id: 'hWA',
    name: 'WA',
    label: 'わをん',
    characters: [
      ['わ', 'wa'],
      ['を', 'wo', 'o'],
      ['ん', 'n'],
    ],
    lesson: 8
  },
]

export const DUMMY_DOUBLE_HIRA = [
  {
    id: 'hKYA',
    name: 'KYA',
    label: 'きゃきゅきょぎゃぎゅぎょ',
    characters: [
      ['きゃ', 'kya'],
      ['きゅ', 'kyu'],
      ['きょ', 'kyo'],
      ['ぎゃ', 'gya'],
      ['ぎゅ', 'gyu'],
      ['ぎょ', 'gyo'],
    ],
  },
  {
    id: 'hSHA',
    name: 'SHA',
    label: 'しゃしゅしょじゃじゅじょ',
    characters: [
      ['しゃ', 'sha'],
      ['しゅ', 'shu'],
      ['しょ', 'sho'],
      ['じゃ', 'ja'],
      ['じゅ', 'ju'],
      ['じょ', 'jo'],
    ],
  },
  {
    id: 'hCHA',
    name: 'CHA',
    label: 'ちゃちゅちょ',
    characters: [
      ['ちゃ', 'cha'],
      ['ちゅ', 'chu'],
      ['ちょ', 'cho'],
    ],
  },
  {
    id: 'hNYA',
    name: 'NYA',
    label: 'にゃにゅにょ',
    characters: [
      ['にゃ', 'nya'],
      ['にゅ', 'nyu'],
      ['にょ', 'nyo'],
    ]
  },
  {
    id: 'hHYA',
    name: 'HYA',
    label: 'ひゃひゅひょびゃびゅびょぴゃぴゅぴょ',
    characters: [
      ['ひゃ', 'hya'],
      ['ひゅ', 'hyu'],
      ['ひょ', 'hyo'],
      ['びゃ', 'bya'],
      ['びゅ', 'byu'],
      ['びょ', 'byo'],
      ['ぴゃ', 'pya'],
      ['ぴゅ', 'pyu'],
      ['ぴょ', 'pyo'],
    ]
  },
  {
    id: 'hMYA',
    name: 'MYA',
    label: 'みゃみゅみょ',
    characters: [
      ['みゃ', 'mya'],
      ['みゅ', 'myu'],
      ['みょ', 'myo'],
    ]
  },
  {
    id: 'hRYA',
    name: 'RYA',
    label: 'りゃりゅりょ',
    characters: [
      ['りゃ', 'rya'],
      ['りゅ', 'ryu'],
      ['りょ', 'ryo'],
    ]
  },
]

export const DUMMY_KATA = [
  {
    id: 'kA',
    name: 'A',
    label: 'アイウエオ',
    characters: [
      ['ア', 'a'],
      ['イ', 'i'],
      ['ウ', 'u'],
      ['エ', 'e'],
      ['オ', 'o'],
    ],
    lesson: 1,
  },
  {
    id: 'kKA',
    name: 'KA',
    label: 'カキクケコガギグゲゴ',
    characters: [
      ['カ', 'ka'],
      ['キ', 'ki'],
      ['ク', 'ku'],
      ['ケ', 'ke'],
      ['コ', 'ko'],
      ['ガ', 'ga'],
      ['ギ', 'gi'],
      ['グ', 'gu'],
      ['ゲ', 'ge'],
      ['ゴ', 'go'],
    ],
    lesson: 2,
  },
  {
    id: 'kSA',
    name: 'SA',
    label: 'サシスセソザジズゼゾ',
    characters: [
      ['サ', 'sa'],
      ['シ', 'shi'],
      ['ス', 'su'],
      ['セ', 'se'],
      ['ソ', 'so'],
      ['ザ', 'za'],
      ['ジ', 'ji'],
      ['ズ', 'zu'],
      ['ゼ', 'ze'],
      ['ゾ', 'zo'],
    ],
    lesson: 3,
  },
  {
    id: 'kTA',
    name: 'TA',
    label: 'タチツテトダヂヅデド',
    characters: [
      ['タ', 'ta'],
      ['チ', 'chi'],
      ['ツ', 'tsu'],
      ['テ', 'te'],
      ['ト', 'to'],
      ['ダ', 'da'],
      ['ヂ', 'ji'],
      ['ヅ', 'dzu', 'zu'],
      ['デ', 'de'],
      ['ド', 'do'],
    ],
    lesson: 4,
  },
  {
    id: 'kNA',
    name: 'NA',
    label: 'ナニヌネノ',
    characters: [
      ['ナ', 'na'],
      ['ニ', 'ni'],
      ['ヌ', 'nu'],
      ['ネ', 'ne'],
      ['ノ', 'no'],
    ],
    lesson: 5,
  },
  {
    id: 'kHA',
    name: 'HA',
    label: 'ハヒフヘホバビブベボパピプペポ',
    characters: [
      ['ハ', 'ha'],
      ['ヒ', 'hi'],
      ['フ', 'fu', 'hu'],
      ['ヘ', 'he'],
      ['ホ', 'ho'],
      ['バ', 'ba'],
      ['ビ', 'bi'],
      ['ブ', 'bu'],
      ['ベ', 'be'],
      ['ボ', 'bo'],
      ['パ', 'pa'],
      ['ピ', 'pi'],
      ['プ', 'pu'],
      ['ペ', 'pe'],
      ['ポ', 'po'],
    ],
    lesson: 6,
  },
  {
    id: 'kMA',
    name: 'MA',
    label: 'マミムメモ',
    characters: [
      ['マ', 'ma'],
      ['ミ', 'mi'],
      ['ム', 'mu'],
      ['メ', 'me'],
      ['モ', 'mo'],
    ],
    lesson: 7,
  },
  {
    id: 'kYA',
    name: 'YA',
    label: 'ヤユヨ',
    characters: [
      ['ヤ', 'ya'],
      ['ユ', 'yu'],
      ['ヨ', 'yo'],
    ],
    lesson: 8,
  },
  {
    id: 'kRA',
    name: 'RA',
    label: 'ラリルレロ',
    characters: [
      ['ラ', 'ra'],
      ['リ', 'ri'],
      ['ル', 'ru'],
      ['レ', 're'],
      ['ロ', 'ro'],
    ],
    lesson: 9,
  },
  {
    id: 'kWA',
    name: 'WA',
    label: 'ワヲン',
    characters: [
      ['ワ', 'wa'],
      ['ヲ', 'wo', 'o'],
      ['ン', 'n'],
    ],
    lesson: 8,
  },
]

export const DUMMY_DOUBLE_KATA = [
  {
    id: 'kKYA',
    name: 'KYA',
    label: 'キャキュキョギャギュギョ',
    characters: [
      ['キャ', 'kya'],
      ['キュ', 'kyu'],
      ['キョ', 'kyo'],
      ['ギャ', 'gya'],
      ['ギュ', 'gyu'],
      ['ギョ', 'gyo'],
    ],
  },
  {
    id: 'kSHA',
    name: 'SHA',
    label: 'シャシュショジャジュジョ',
    characters: [
      ['シャ', 'sha'],
      ['シュ', 'shu'],
      ['ショ', 'sho'],
      ['ジャ', 'ja'],
      ['ジュ', 'ju'],
      ['ジョ', 'jo'],
    ],
  },
  {
    id: 'kCHA',
    name: 'CHA',
    label: 'チャチュチョ',
    characters: [
      ['チャ', 'cha'],
      ['チュ', 'chu'],
      ['チョ', 'cho'],
    ],
  },
  {
    id: 'kNYA',
    name: 'NYA',
    label: 'ニャニュニョ',
    characters: [
      ['ニャ', 'nya'],
      ['ニュ', 'nyu'],
      ['ニョ', 'nyo'],
    ]
  },
  {
    id: 'kHYA',
    name: 'HYA',
    label: 'ヒャヒュヒョビャビュビョピャピュピョ',
    characters: [
      ['ヒャ', 'hya'],
      ['ヒュ', 'hyu'],
      ['ヒョ', 'hyo'],
      ['ビャ', 'bya'],
      ['ビュ', 'byu'],
      ['ビョ', 'byo'],
      ['ピャ', 'pya'],
      ['ピュ', 'pyu'],
      ['ピョ', 'pyo'],
    ]
  },
  {
    id: 'kMYA',
    name: 'MYA',
    label: 'ミャミュミョ',
    characters: [
      ['ミャ', 'mya'],
      ['ミュ', 'myu'],
      ['ミョ', 'myo'],
    ]
  },
  {
    id: 'kRYA',
    name: 'RYA',
    label: 'リャリュリョ',
    characters: [
      ['リャ', 'rya'],
      ['リュ', 'ryu'],
      ['リョ', 'ryo'],
    ]
  },
]

export const DUMMY_SPECIAL_KATA = [
  ['ティ', 'ti'],
  ['ディ', 'di'],
  ['チェ', 'che'],
  ['トゥ', 'tu'],
  ['ドゥ', 'du'],
  ['シェ', 'she'],
  ['ジェ', 'je'],
  ['ヴァ', 'va'],
  ['ウィ', 'wi'],
  ['ウェ', 'we'],
  ['ウォ', 'wo'],
  ['ファ', 'fa'],
  ['フィ', 'fi'],
  ['フェ', 'fe'],
  ['フォ', 'fo'],
]

const KanaGamePage = () => {
  return (
    <>
      <Main hiraChars={DUMMY_HIRA} kataChars={DUMMY_KATA}/>
    </>
  )
}

export default KanaGamePage
