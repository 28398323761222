import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import * as R from 'ramda'

import axios from 'src/api'

import { RootState } from 'src/configureStore'

const getCampaignsStatus = async () => {
  const res = await axios.get('/subscriptions/campaigns')
  return res.data.campaigns
}

const useCampaigns = () => {
  const { _id } = useSelector((state: RootState) => state.auth)
  const { data: campaigns } = useQuery({ queryKey: ['campaigns_status', _id], queryFn: getCampaignsStatus })

  return { campaigns: R.filter(Boolean, campaigns || []) }
}

export default useCampaigns
