import React, { useMemo, useState } from 'react'
import axios from 'src/api'

import { Divider, Dropdown, Header } from 'semantic-ui-react'
import { Menu, TagDropdown } from '../../styles/TagMenu.styles'

const langCourses = ['jfz1', 'jfz2', 'jfz3', 'jfz4', 'jfz5']

const areaOptions = [
  { key: 'jfz1', title: 'Japanese From Zero! Course 1' },
  { key: 'jfz2', title: 'Japanese From Zero! Course 2' },
  { key: 'jfz3', title: 'Japanese From Zero! Course 3' },
  { key: 'jfz4', title: 'Japanese From Zero! Course 4' },
  { key: 'jfz5', title: 'Japanese From Zero! Course 5' },
  { key: 'kansai', title: 'Kansai Dialect' },
  { key: 'youtube', title: 'YouTube' },
  { key: 'site support', title: 'Site Support' },
  { key: 'book support', title: 'Book Support' },
  { key: 'misc', title: 'Other' },
]

interface TagMenuProps {
  tag: string[]
  setTag: (arg: string[]) => void
  modal?: boolean
}

const TagMenu: React.FC<TagMenuProps> = ({
  tag,
  setTag,
  modal
}) => {
  const [showCourseLessons, setShowCourseLessons] = useState(false)
  const [lessonOptions, setLessonOptions] = useState([])

  const getLessonTitles = (id) => {
    axios.get(`/course/${id}/lessons/titles`)
      .then((res) => setLessonOptions([res.data.prelessons, res.data.lessons]))
      .catch((err) => console.error(err))
  }

  const handleTags = (key, type) => {
    let updatedTag = [...tag]

    if (type === 'main') {
      updatedTag[0] = key

      if (key === 'kansai') {
        updatedTag[1] = 'japanese'
      } else if (langCourses.includes(key)) {
        updatedTag[1] = 'japanese'
        setShowCourseLessons(true)
        getLessonTitles(key)
      } else {
        updatedTag = [key, '', '']
      }
    }

    if (type === 'lesson') {
      updatedTag[2] = `${key}`
    }

    setTag(updatedTag)
  }

  const legibleKeys = useMemo(() => {
    let legibleTitle = ''
    areaOptions.forEach((option) => {
      if (tag[0] === option.key) legibleTitle = option.title
    })
    return legibleTitle
  }, [tag])

  return (
    <Menu style={{ marginTop: '0px', paddingTop: '14px', backgroundColor: modal ? 'white' : 'var(--off-white)' }}>
      <Header style={{ margin: '0 10px 0 0' }}>Question Tag</Header>
      <div>
        <TagDropdown
          text={legibleKeys}
          direction='left'
        >
          <Dropdown.Menu>
            {areaOptions.map((c) => (
              <Dropdown.Item
                key={c.key}
                text={c.title}
                onClick={() => handleTags(c.key, 'main')}
              />
            ))}
          </Dropdown.Menu>
        </TagDropdown>

        {showCourseLessons && langCourses.includes(tag[0]) && (
          <TagDropdown
            disabled={!lessonOptions.length}
            text={tag[1] !== '' ? `Lesson ${tag[2]}` : 'Lesson...'}
          >
            <Dropdown.Menu
              style={{
                maxHeight: '30vh',
                overflowY: 'scroll',
              }}
            >
              {!!lessonOptions.length && !!lessonOptions[0].length && (
                <>
                  {lessonOptions[0].map((l, idx) => (
                    <Dropdown.Item
                      key={l._id}
                      text={`Lesson ${String.fromCharCode(65 + idx)} - ${l.title}`}
                      onClick={() => handleTags(String.fromCharCode(65 + idx), 'lesson')}
                    />
                  ))}
                  <Divider />
                </>
              )}
              {!!lessonOptions.length && !!lessonOptions[1].length && (
                lessonOptions[1].map((l, idx) => (
                  <Dropdown.Item
                    key={l._id}
                    text={`Lesson ${idx + 1} - ${l.title}`}
                    onClick={() => handleTags(idx + 1, 'lesson')}
                  />
                ))
              )}
            </Dropdown.Menu>
          </TagDropdown>
        )}
      </div>
    </Menu>
  )
}

export default TagMenu
