import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Progress } from '@mantine/core'
import { CLOUDFRONT_BASE } from 'src/util/constants'
import getCourseInfo, { getTitle } from 'src/util/courseInfo'
import { IsMobile, ActivityContainerProps, CourseProps } from './types'

const activityBase = `${CLOUDFRONT_BASE}/images/activities`
const bkgdPolyfetti = `${activityBase}/bkgd_polyfetti_c1.svg`

const containerStyles = ({ course, isMobile }) => css`
  border: 2px dashed ${getCourseInfo(getTitle(course)).alt_color_7 ?? '#60a2ef'};
  padding: ${isMobile ? '14px' : '28px'};
  font-size: 1.5rem;
  background-color: white;
  background-image: url(${bkgdPolyfetti});
  background-size: contain;
  min-height: 650px;
`

export const ActivityContainer = styled.div<ActivityContainerProps>`
  ${containerStyles}
`

export const ActivityHeader = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-template-rows: minmax(90px, auto);
  gap: 16px;
`

export const ActivityType = styled.h3<{ course: string, isBeta: boolean }>`
  font-size: 2rem !important;
  font-family: Poppins;
  color: ${({ course }) => getCourseInfo(getTitle(course)).alt_color_7 ?? '#60a2ef'};
  border-bottom: 1px dashed #aaa;
  display: grid;
  align-items: center;
  grid-row: 1;
  grid-column: 1;

  // below is the 'Beta' tag
  grid-template-columns: auto auto;
  justify-content: start;

  &::after {
    content: '${({ isBeta }) => isBeta ? 'Beta' : ''}';
    font-size: 0.5em;
    color: white;
    font-family: 'Poppins';
    background-color: ${({ course }) => getCourseInfo(getTitle(course)).alt_color_7 ?? '#60a2ef'};
    margin-left: 8px;
    padding: 0 8px;
    border-radius: 8px;
  }
`

export const SpeechContainer = styled.div<IsMobile>`
  justify-self: right;
  align-self: end;
  position: relative;
  grid-column: ${({ isMobile }) => isMobile && '1 / 3'};
  grid-row: 1;
`

export const SpeechBubble = styled.svg`
  position: absolute;
  height: 100%;
  width: 100%;

  .cls-1 {
    fill: #ffeca8;
    stroke: #fff;
    stroke-width: 1px;
    fill-rule: evenodd;
  }
`

export const Commentary = styled.p`
  padding: 12px;
  margin: 0 12px 12px !important;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: bold;

`

export const Tomoko = styled.img<IsMobile>`
  grid-row: 1 / 3;
  grid-column: 3;
  height: ${({ isMobile }) => isMobile ? 100 : 150}px;
  align-self: end;
`

export const Question = styled.h4<IsMobile>`
  font-size: ${({ isMobile }) => isMobile ? 1.1 : 1.5}rem !important;
  font-family: Poppins;
  color: #3C4043;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  gap: 8px;
  padding-bottom: 16px;
`

export const QuestionNumber = styled.span<IsMobile>`
  font-size: ${({ isMobile }) => isMobile ? 1 : 1.25}rem;
  width: ${({ isMobile }) => isMobile ? 25 : 30}px;
  height: ${({ isMobile }) => isMobile ? 25 : 30}px;
  border-radius: 50%;
  background-color: #3C4043;
  color: white;
  display: grid;
  place-content: center;
`

export const GradientProgress = styled(Progress)`
  border: 1px solid #eee;
  background-color: white;
  padding: 8px;

  & .mantine-Progress-bar {
    background: linear-gradient(90deg, #B0C6FF 0%, #89E9D9 100%);
  }
`

export const CheckAnswer = styled.button<{ answeredCorrectly?: boolean }>`
  display: block;
  margin: 0 auto;
  background-color: ${({ answeredCorrectly }) => answeredCorrectly ? '#74e3d0' : '#60A2EF'};
  color: white;
  border: none;
  border-radius: 30px;
  padding: 16px 40px;
  font-size: 1rem;
  font-family: Poppins;
  margin-top: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    background-color: #C8D0EA;
    cursor: default;
  }
`

//
// RESULTS STYLES
//

export const ResultsContainer = styled.div<ActivityContainerProps>`
  ${containerStyles}
  display: grid;
  gap: 32px;
  align-content: end;
  justify-content: center;
  justify-items: center;
  padding-bottom: 0;
`

export const ResultsHeader = styled.h2<ActivityContainerProps>`
  position: relative;
  font-size: ${({ isMobile }) => isMobile ? 1.5 : 2}rem !important;
  font-family: 'Poppins Bold';
  color: #3C4043;
  background-color: ${({ course }) => getCourseInfo(getTitle(course)).alt_color_4 ?? '#60a2ef'};
  padding: 8px 16px;
  border-radius: 8px;

  & span {
    color: white;
  }
`

export const Message = styled.p<IsMobile>`
  font-size: ${({ isMobile }) => isMobile ? 1 : 1.5}rem;
  font-family: 'Katahdin Round';
  color: #3C4043;
  border: 2px solid #C8D0EA;
  border-style: solid none;
`

export const TryAgain = styled(CheckAnswer)`
  padding: 8px 40px;
`

export const ResultsTomoko = styled.img<IsMobile>`
  position: absolute;
  height: ${({ isMobile }) => isMobile ? 125 : 250}px;
  align-self: end;
  justify-self: end;
`
