import React, { useMemo } from 'react'
import { toRomaji } from 'wanakana'

const purpleGray = '#52526b'

interface CardProps {
  active: boolean
  selected?: boolean
  kana: string
  animating: boolean
  delay: number
  embed?: boolean
  handleCardClicked: (arg: string) => void
}

const KanaCard: React.FC<CardProps> = ({
  active,
  selected,
  kana,
  animating,
  delay,
  embed,
  handleCardClicked
}) => {
  const bgColor = useMemo(() => {
    let color = ''
    if (active) color = (embed ? purpleGray : '#1d78be')
    if (selected) color = '#569ace'
    return color
  }, [active, selected])

  return (
    <div
      className='row'
      style={{ animationDelay: delay.toString() }}
      onClick={() => handleCardClicked(kana)}
    >
      <div
        className={`cell${active ? ' active-cell' : ''}${selected ? ' selected-cell' : ''}`}
      >
        <blockquote lang='jp'>{kana}</blockquote>
        <p>
          {toRomaji(kana)}
        </p>
      </div>
    </div>
  )
}

export default KanaCard
