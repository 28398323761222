import React, { useCallback, useState, useEffect } from 'react'
import { default as ax } from 'axios'
import axios from 'src/api'

import { Embed } from 'semantic-ui-react'
import ReactPlayer from 'react-player'
import useMobile from '../../../hooks/useMobile'
import useAPI from 'src/hooks/useAPI'
import WidgetWrapper from 'src/components/error_boundary/WidgetWrapper'
import { VideoWidget as VideoWidgetType } from 'src/types'

const VideoWidget: React.FC<{ widget: VideoWidgetType }> = ({ widget }) => {
  const { _id, yt_id, vault_video_s3_path, vault_thumb_s3_path } = widget
  const joined_id = typeof yt_id === 'string' ? yt_id : yt_id?.map((s: any) => s?.text || s).join('')
  const maxResDefault = `https://img.youtube.com/vi/${joined_id}/maxresdefault.jpg`
  // const hqDefault = `https://img.youtube.com/vi/${joined_id}/hqdefault.jpg`
  const [active, setActive] = useState(false)
  const [vaultVideoURL, setVaultVideoURL] = useState()
  const [vaultThumbURL, setVaultThumbURL] = useState()
  const [thumbPath, setThumbPath] = useState(maxResDefault)
  const isMobile = useMobile()
  
  const getAWSPaths = async (video: string, thumb: string) => {
    const _video = await axios.post('user/signed', { url: video })
    const _thumb = await axios.post('user/signed', { url: thumb })
    
    setVaultVideoURL(_video.data.url)
    setVaultThumbURL(_thumb.data.url)
  }

  useEffect(() => {
    // console.log(thumbPath)
  }, [thumbPath])

  useEffect(() => {
    if (vault_video_s3_path) getAWSPaths(vault_video_s3_path, vault_thumb_s3_path)
  }, [])

  // console.log(yt_id)
  // console.log(joined_id)

  const checkImageExists = useCallback((url: string, callback: (exists: boolean) => void) => {
    // console.log('checking if image exists')
    const img = new Image()
    img.onload = () => {
      // do a check for whether we're getting the youtube placeholder image,
      // which is a 120x90 image with the text "YouTube" in the middle
      // we'll just check dimensions
      if (img.width <= 120) {
        callback(false)
      } else callback(true)
    }
    img.onerror = () => callback(false)
    img.src = url
  }, [])

  useEffect(() => {
    if (yt_id) {
      const maxResDefault = `https://img.youtube.com/vi/${joined_id}/maxresdefault.jpg`
      const hqDefault = `https://img.youtube.com/vi/${joined_id}/hqdefault.jpg`
      const sdDefault = `https://img.youtube.com/vi/${joined_id}/sddefault.jpg`
      const mqDefault = `https://img.youtube.com/vi/${joined_id}/mqdefault.jpg`
      const defaultThumb = `https://img.youtube.com/vi/${joined_id}/default.jpg`
      const thumb = maxResDefault

      checkImageExists(thumb, (exists) => {
        // console.log('callback fired as', exists)
        if (exists) {
          setThumbPath(thumb)
        } else {
          setThumbPath(hqDefault)
        }
      })
    }
  }, [yt_id])

  return (
    <WidgetWrapper
      data-t={`widget:video-id:${_id}`}
      widget={{ widgetId: _id, widgetType: 'VideoWidget' }}
    >
      <div style={{ padding: isMobile ? '6px 14px' : '15px 0' }}>
        {yt_id && (
          <Embed
            tabIndex='0'
            active={active}
            onClick={() => setActive((prev) => !prev)}
            onKeyUpCapture={(e) => e.key === 'Enter' && setActive((prev) => !prev)}
            id={joined_id}
            source='youtube'
            placeholder={thumbPath}
            autoplay={true}
            className='no-select'
            style={{ borderRadius: '3px', zIndex: 0 }}
            iframe={{ allowFullScreen: true }}
            onError={() => console.log('error')}
          />
        )}
        {!yt_id && vaultVideoURL && (
          <div style={{ position: 'relative', paddingTop: '56.25%' }}>
            <ReactPlayer
              style={{ position: 'absolute', top: '0', left: '0' }}
              url={vaultVideoURL}
              light={vaultThumbURL}
              controls={true}
              width="100%"
              height="100%"
              volume={0.5}
            />
          </div>
        )}
      </div>
    </WidgetWrapper>
  )
}

export default VideoWidget
