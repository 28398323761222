import { useState, useEffect } from 'react'
import axios from 'src/api'

import useBonusData from 'src/hooks/useBonusData'

const useRecord = (record, base_text, widget) => {
  const [recordState, setRecordState] = useState(record)
  const bonusData = useBonusData(recordState)
  const [teacherNote, setTeacherNote] = useState(null)

  useEffect(() => {
    let isMounted = true
    if (recordState) return;
        
    axios.post('/lang_records/', { type: 'JapaneseRecord', base_text, widget })
      .then((res) => {
        if (isMounted) setRecordState(res.data.record)
      })
      .catch((err) => console.error(err))

    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    // console.log(record)
    if (record.teacherNote) {
      setTeacherNote(record.teacherNote)
    }
  }, [record])

  return { record: recordState, bonusData, teacherNote }
}

export default useRecord
