import styled from 'styled-components'

import SoundRenderer from '../SoundRenderer'
import useMobile from '../../../../hooks/useMobile'

const LineSegmentStyles = styled.div`
  padding-top: ${({ isMobile }) => isMobile ? '10px' : '20px'};
  font-family: "Milliard Book", sans-serif;
  margin-bottom: ${({ final_line }) => final_line ? '10px' : ''};


  .speaker-index {
    font-feature-settings: tnum;
    font-variant-numeric: tabular-nums;
    padding-right: 5px;
  }

  .commentary {
    font-size: 1rem;
  }
`

const LineGrid = styled.div`
  font-size: var(--reg-font-size);
  display: grid;
  align-items: baseline;
  grid-template-columns: auto 1fr;
  grid-row-gap: 2px;
`

const English = styled.div`
  grid-column: 2;
  padding-left: 25px;
`

const OtherLang = styled.div`
  grid-column: 2;
`

const LineSegment = ({
  line_num,
  conversation_length,
  displayOrder,
  shouldOrder,
  displayIn,
  hideEnglish,
  hideOtherLang,
  indexing,
  index,
  current,
  next,
  register,
  english,
  other_lang,
  showEnglish,
  commentary,
  widgetType
}) => {
  const isMobile = useMobile()

  return (
    <LineSegmentStyles
      isMobile={isMobile}
      final_line={line_num === conversation_length - 1}
      displayOrder={displayOrder}
      shouldOrder={shouldOrder}>
          <LineGrid>
            {shouldOrder && <span className="speaker-index">{indexing === 'alpha' ? (index % 2 ? 'B: ' : 'A: ') : `${index + 1}. `}</span>}
            {displayOrder && (
              <OtherLang className={'fz-dark-font conversation-line other'}>
                <SoundRenderer
                  playingNum={current} num={index}
                  onSound={register} ping={next}
                  text={other_lang}
                  placeholder={hideOtherLang}
                  index={index}
                  wrapAtIcon
                />
              </OtherLang>
            )}
            <English>
              <p className="english"><SoundRenderer text={english} placeholder={hideEnglish} index={index} /></p>
              {commentary && commentary?.content && (
                <p className="commentary">
                  <SoundRenderer
                    text={commentary.content}
                    placeholder={hideEnglish}
                    index={index}
                  />
                </p>
              )}
            </English>
            {!displayOrder && (
              <OtherLang className={'fz-dark-font conversation-line other'}>
                <SoundRenderer
                  playingNum={current} num={index}
                  onSound={register} ping={next}
                  text={other_lang}
                  placeholder={hideOtherLang}
                  index={index}
                />
              </OtherLang>
            )}
          </LineGrid>
    </LineSegmentStyles>
  )
}

export default LineSegment
