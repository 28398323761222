import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import axios from 'src/api'

import ProgSettingsTogglerStyles from '../../styles/ProgSettingsTogglerStyles'
import { AxiosError } from 'axios'
import { ProgressiveSettingsResponse, REDUX_ACTIONS } from 'src/types'

const settings = [
  {
    id: 'romaji',
    value: 'R',
  },
  {
    id: 'progressive',
    value: 'P',
  },
  {
    id: 'hirakata',
    value: 'か',
  },
  {
    id: 'kanji',
    value: '字',
  },
]

const ProgSettingsToggler = () => {
  const [active, setActive] = useState('progressive')
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const dispatchProg = useDispatch()
  
  const main_default = progSettings?.main_default
  const furi_opacity = progSettings?.furi_opacity

  useEffect(() => {
    if (main_default) setActive(main_default)
  }, [main_default])

  const handleChangeSetting = async (setting: string) => {
    try {
      // we don't care about the response in order to set redux state
      // it's only important to fire this off to save it their record
      // still gonna set the responose type tho
      await axios.post<ProgressiveSettingsResponse>('/user/progressive_settings', { setting, furi_opacity })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.code)
      console.error(error?.message)
    }

    dispatchProg({
      type: REDUX_ACTIONS.CHANGE_SETTING,
      payload: { ...progSettings, main_default: setting, furi_opacity }
    })
  }

  return (
    <ProgSettingsTogglerStyles className='progressive-settings'>
      <p style={{ paddingBottom: '8px' }}>Progressive Settings</p>
      <div>
        {settings.map((setting) => (
          <button
            key={setting.id}
            className={active === setting.id ? 'active' : ''}
            onClick={() => handleChangeSetting(setting.id)}
          >
            {setting.value}
          </button>
        ))}
      </div>
    </ProgSettingsTogglerStyles>
  )
}

export default ProgSettingsToggler
