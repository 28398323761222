import React, { useEffect, useMemo, useState } from 'react'
import axios from 'src/api'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { VIDEO } from '../toolkit.constants'
import { VideoWidget } from 'src/types'

import { EmbedBlock, EmptyPadding, ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { ToolFooter, ToolHeader, ToolInput, ToolOptions } from '../parts'
import ToolForm from '../parts/ToolForm.part'
import { Dropdown, DropdownItem, DropdownMenu, Embed } from 'semantic-ui-react'
import useWidgetSaved from '../hooks/useWidgetSaved'

interface VaultCategory {
  title: string
  _id: string
}

interface VaultVideo {
  s3_path: string
  s3_thumb_path: string
  title: string
}

const VideoTool: React.FC<ToolProps<VideoWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [videoEmbedActive, setVideoEmbedActive] = useState(false)
  const [vaultCategories, setVaultCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState<VaultCategory>()
  const [categoryVideos, setCategoryVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState<VaultVideo>()
  const [vaultThumbURL, setVaultThumbURL] = useState()

  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<VideoWidget>(id)
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const hookFormMethods = useForm()

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    fetchWidget()
    setSaved(true)
    fetchSubLesson()
  }

  const getVaultCategories = async () => {
    const categories = await axios.get('/vault/categories')
    setVaultCategories(categories.data.categories)
  }

  const getCategoryVideos = async () => {
    const videos = await axios.get(`/vault/category/${selectedCategory._id}/videos`)
    setCategoryVideos(videos.data.videos)
  }

  const getAWSPath = async (url: string) => {
    const thumbnail = await axios.post('user/signed', { url })
    setVaultThumbURL(thumbnail.data.url)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      hookFormMethods.setValue('yt_id', widget.yt_id)
      hookFormMethods.setValue('vault_video_s3_path', widget.vault_video_s3_path)
      hookFormMethods.setValue('vault_thumb_s3_path', widget.vault_thumb_s3_path)
      setWidgetValues(widget)

      if (widget.vault_thumb_s3_path) getAWSPath(widget.vault_thumb_s3_path)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  useEffect(() => {
    getVaultCategories()
  }, [])

  useEffect(() => {
    if (!selectedCategory) return
    getCategoryVideos()
  }, [selectedCategory])

  useEffect(() => {
    if (!selectedVideo) return

    hookFormMethods.setValue('yt_id', '')
    hookFormMethods.setValue('vault_video_s3_path', selectedVideo.s3_path)
    hookFormMethods.setValue('vault_thumb_s3_path', selectedVideo.s3_thumb_path)

    getAWSPath(selectedVideo.s3_thumb_path)
  }, [selectedVideo])

  const youtube_video_id = useMemo(() => {
    // return first video of JFZ playlist if no id
    return hookFormMethods.getValues()['yt_id'] ?? 'RsrakMT1h2g'
  }, [hookFormMethods.formState])

  const handleYtID = (e: React.ChangeEvent<HTMLInputElement>) => {
    const splitValue = e.target.value.split('watch?v=')
    if (splitValue.length > 1) hookFormMethods.setValue('yt_id', splitValue[1])
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={VIDEO}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={VIDEO}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              <ToolInput
                value={'romajiOnly'}
                inputType='checkbox'
                inputLabel='Romaji Mode Only'
                labelColor='var(--blue-gray)'
                labelPosition='after'
              />
            </div>
          </ToolOptions>
          <ToolBody>
            <EmptyPadding />
            <div className='tw-flex tw-gap-5' style={{ marginBottom: '5px' }}>
              <h3>Vault Video: </h3>
              <Dropdown text={selectedCategory?.title ?? 'Category'}>
                <DropdownMenu>
                  {vaultCategories.map((category) => (
                    <DropdownItem
                      key={category.title}
                      text={category.title}
                      onClick={() => setSelectedCategory(category)}
                    />
                  ))}
                </DropdownMenu>
              </Dropdown>
              <Dropdown text={selectedVideo?.title ?? 'Video'}>
                <DropdownMenu>
                  {categoryVideos.map((video, idx) => (
                    <DropdownItem
                      key={`${video.title}-${idx}`}
                      text={video.title}
                      onClick={() => setSelectedVideo(video)}
                    />
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
            <EmbedBlock>
              <Embed
                tabIndex='0'
                active={videoEmbedActive}
                onClick={() => setVideoEmbedActive((prev) => !prev)}
                onKeyUpCapture={(e) => e.key === 'Enter' && setVideoEmbedActive((prev) => !prev)}
                id={id}
                source='youtube'
                placeholder={
                  hookFormMethods.getValues()['yt_id'] === ''
                    ? vaultThumbURL ?? `https://img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`
                    : `https://img.youtube.com/vi/${youtube_video_id}/maxresdefault.jpg`
                }
                autoplay={true}
                className='no-select'
                iframe={{ allowFullScreen: true }}
              />
            </EmbedBlock>
            <ToolInput
              value='yt_id'
              placeholder='Youtube Video ID'
              width='70%'
              onChange={handleYtID}
            />
            <EmptyPadding />
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          />
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default VideoTool
