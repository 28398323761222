import styled from 'styled-components'

const InfoCard = styled.div`
  z-index: 10;
  position: absolute;
  top: 18px;
  right: 100px;
  left: 15px;
  background-color: #fbfaff;
  padding: 1rem;
  border: 2px solid #1d191b;
  border-radius: 0px 25px 25px 25px;
  text-align: left;

  @media (max-width: 740px) {
    left: 0px;
  }
`

export default InfoCard
