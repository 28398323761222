import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { dispatchProgressive, signOut } from 'src/actions'

import { hotjar } from 'react-hotjar'

import axios from 'src/api'
import { AxiosError } from 'axios'

import NavigatorStyles from './styles'
import Navigation from './nav_parts/Navigation'

import useInterval from 'src/hooks/useInterval'
import useMobile from 'src/hooks/useMobile'
import useCustomProgressive from 'src/hooks/useCustomProgressive'
import usePageTracking from 'src/hooks/usePageTracking'

import FZErrorBoundary from '../error_boundary/FZErrorBoundary'
import FooterLinks from '../FooterLinks'
import FlashMessage from './nav_parts/FlashMessage'
import LoginModal from '../modals/LoginModal'
import FZStyleManager from './styles/FZStyleManager'
import ProgressBar from './nav_parts/ProgressBar'

import TailwindResetUnreset from './styles/TailwindResetUnreset'
import useBrowser from 'src/hooks/useBrowser'
import { AudioContext } from 'src/contexts/AudioContext'
import { TabContext } from 'src/contexts/TabContext'
import { SET_USER_AGENT, SET_REFERRER } from 'src/actions/types'
import useEngagement from 'src/hooks/useEngagement'

interface NavigatorProps {
  children: React.ReactNode
  signOut: () => void
  dispatchProgressive: (progressive: any) => void
}

const Navigator: React.FC<NavigatorProps> = (props: NavigatorProps) => {
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)
  const lessonOverview = useRouteMatch('/courses/:id/:lid')
  const sublessonPage = useRouteMatch('/courses/:id/:lid/:sid')
  const affiliate = useRouteMatch('/join/:affiliate')
  const courseInfo = useSelector((state: RootState) => state.navigator.course_info)
  const userId = useSelector((state: RootState) => state.auth?.userId)
  const _id = useSelector((state: RootState) => state.auth?._id)
  const isMobile = useMobile()
  const history = useHistory()
  const location = useLocation()
  const [browser, os] = useBrowser()
  const dispatch = useDispatch()
  const uaBrowser = useSelector((state: RootState) => state.user?.browser)
  const uaOS = useSelector((state: RootState) => state.user?.os)
  const { audio, setAudioSrc } = useContext(AudioContext)
  const { tabId } = useContext(TabContext)
  const { refetch } = useCustomProgressive()
  // const _ = usePageTracking(location.pathname)
  const engagement = useEngagement()

  const redirectBlacklist = ['books', 'login', 'register', 'faq', 'tos', 'privacy']

  useEffect(() => {
    if (userId) {
      hotjar.identify(userId, {
        _id,
      })
    }
    hotjar.stateChange(location.pathname)

    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, 200)

    if (audio) {
      audio.pause()
      audio.currentTime = 0
      setAudioSrc('PAGE_CHANGE')
    }

    // if the string "courses/jfz" is in the pathname, then we're on a course page
    // and should load the progressive map for whatever lesson we're on if the path is of the form
    // /courses/jfz:id/:lid~
    const lessonRegex = /courses\/jfz\d+\/[\d\w]+/g

    if (location.pathname.match(lessonRegex)) {
      const l = location.pathname.split('/').filter((p) => p)[2]
      const course = location.pathname.split('/').filter((p) => p)[1]
      // console.log(l, course)
      axios
        .get('lesson/progressive', {
          params: {
            lesson: l,
            course,
            sublesson: location.pathname.split('/').filter((p) => p)[3]
          }
        })
        .then((res) => {
          console.log(res)
          // setProgressiveData(res.data.progressiveData)
          props.dispatchProgressive(res.data.progMap)
        })
        .catch((err) => console.error(err))
    }

    // custom progressive
    refetch()
  }, [location.pathname])

  useEffect(() => {
    if (!uaBrowser || !uaOS) {
      dispatch({
        type: SET_USER_AGENT,
        payload: { browser, os }
      })
    }
  }, [])

  useEffect(() => {
    // console.log('Tab ID:', tabId)
  }, [tabId])

  // track referrer if it exists and isn't same-origin or localhost
  useEffect(() => {
    if (!document.referrer?.includes('localhost') && !document.referrer?.includes('https://www.fromzero.com/')) {
      dispatch({
        type: SET_REFERRER,
        payload: document.referrer
      })
    }
  }, [location.pathname])

  const handleSignOut = (pathname) => {
    const signOutWhitelist = ['/join', '/home', '/login', '/register', '/faq', '/tos', '/privacy', '/resend', '/books', '/register', '/support', '/store', '/forgot_password', '/claim', '/memberships/premium/subscribe']
    if (pathname && (signOutWhitelist.includes(pathname) || pathname.includes('claim') || pathname.includes('join') || pathname.includes('ask') || pathname.includes('activate') || pathname.includes('/bugs') || pathname.includes('reset') || pathname.includes('change_email'))) {
      return
    }
    axios.post('/user/logout')
      .then((res) => {
        if (res.data.success) {
          props.signOut()
          history.push('/')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const pingAndOpenModal = useCallback(async () => {
    try {
      const status = engagement.idle ? 'idle' : 'active'
      await axios.get('/user/ping', {
        params: {
          path: location.pathname,
          status,
          referrer: document.referrer,
          browser: navigator.userAgent,
          language: navigator.language,
          platform: navigator.platform,
          screen: `${window.screen.width}x${window.screen.height}`,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          tabId
        }
      })
    } catch (err) {
      const error = err as AxiosError

      if (error.response?.status === 403) {
        handleSignOut(location.pathname)
      } else {
        console.error(error?.code)
        console.error(error?.message)
      }
    }
  }, [location, engagement.idle, tabId])

  useEffect(() => {
    pingAndOpenModal()
  }, [location.pathname])

  useInterval(() => {
    if (!window.navigator.onLine) {
      // props.signOut()
      // setSignInModalOpen([true, 'CONNECTION'])
      return
    }
    const path = location.pathname.split('/').filter((p) => p)[0]
    if (!redirectBlacklist.includes(path)) pingAndOpenModal()
  }, 60 * 1000)

  const { pathname } = location
  const hideFooterLinks = pathname === '/' || !!affiliate
  const transparent_nav_bar: boolean = useMemo(() => {
    const transparentRoutes = ['login', 'register']
    const root = pathname.split('/').filter((p) => p)[0]
    if (transparentRoutes.includes(root)) return true
    return false
  }, [pathname])
  
  useEffect(() => {
    document.body.scrollTo({ top: 0, left: 0, behavior: 'auto' })
  }, [location, location.pathname])

  const shouldShowLoginModal: boolean = useMemo(() => {
    if (isSignedIn) return false
    const modalWhitelist = [
      'account', 'admin', 'badges',
      'course', 'courses', 'home', 'korean',
      'memberships', 'notebook', 'player', 'teacher', 'thankyou',
      'tools', 'user', 'vault',
    ]
    const root = pathname.split('/').filter((p) => p)[0]
    if (modalWhitelist.includes(root)) return true
    return false
  }, [isSignedIn, location])

  const hardUAWidth = useMemo(() => {
    const result = !isMobile && (!!lessonOverview || !!sublessonPage)
    return result
  }, [pathname])

  // not sure if this is necessary, gonna try commenting it out for a minute - germ
  // const Children = useMemo(() => {
  //   return props.children
  // }, [location.pathname])

  return (
    <NavigatorStyles
      mobile={isMobile}
      hardUserAvatarWidth={hardUAWidth}
      transparent_nav_bar={transparent_nav_bar}
    >
      <FZStyleManager courseInfo={courseInfo}>
        <TailwindResetUnreset>
          <header className='navbar'>
            <Navigation isMobile={isMobile} onSignOut={handleSignOut} />
            <ProgressBar />
            <FlashMessage />
          </header>
          <FZErrorBoundary>
            <div id='content-body'>
              {shouldShowLoginModal
                && <LoginModal renderOverride={!isSignedIn} />
              }
              {props.children}
            </div>
            {!hideFooterLinks && <FooterLinks />}
          </FZErrorBoundary>
        </TailwindResetUnreset>
      </FZStyleManager>
    </NavigatorStyles>
  )
}

export default connect(null, { signOut, dispatchProgressive })(Navigator)
