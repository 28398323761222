import styled from 'styled-components'

export default styled.div`
  margin: 15px 0;

  >section:first-child {
    text-align: center;
    margin: 0 auto -13px;
  
    h2 {
      display: inline;
      padding: 6px 16px;
      border-radius: 5px;
      color: var(--off-white);
      font-size: 1.4rem;
    }
  }

  >section:last-child {
    border-top: 3px dashed #6c6f94;
    border-bottom: 3px dashed #6c6f94;
    padding: ${({ isMobile }) => isMobile ? ' 45px 0' : '45px'};
  }
`
