import React, { useEffect, useState, useRef } from 'react'
import axios from '../../../api'

import { Image } from 'semantic-ui-react'
import HoverVideoPlayer from 'react-hover-video-player'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'
import useAPI from 'src/hooks/useAPI'

type ImageFigureProps = {
  overlay?: boolean
  cfPath?: string
  setCfPath?: React.Dispatch<React.SetStateAction<string | undefined>>
  height?: number
  showBorder?: boolean
  title?: string
  caption?: string
  id: string
  isGridWidget?: boolean
  isImageGrid?: boolean
}

const ImageFigure: React.FC<ImageFigureProps> = ({ overlay, cfPath, setCfPath, height, showBorder, title, caption, id, isGridWidget, isImageGrid }) => {
  if (!cfPath) return null

  console.log('isGridWidget && isImageGrid', isGridWidget, isImageGrid, isGridWidget && isImageGrid)

  const style = {
    margin: '0 auto',
    borderRadius: '3px',
    height: `${height ?? ''}px`
  }

  // if the cfpath contains .svg, set its width to 75%
  if (cfPath.includes('.svg')) {
    style['width'] = '75%'
  }

  // if is grid widget, set the width to 100%
  if (isGridWidget && isImageGrid) {
    style['max-height'] = '205px'
  }

  return (
    <figure
      style={{
        border: showBorder ? '1.5px solid var(--blue-gray)' : '1.5px solid transparent',
        display: 'grid',
        gridTemplateRows: '1fr auto',
        height: `${title ? 'calc(100% - 51px)' : '100%'}`,
        backgroundColor: overlay ? 'white' : '',
      }}
    >
      {/* cfPath may be a link to an SVG */}
      {cfPath?.includes('.svg') ? (
        <img
          className="no-select"
          style={style}
          src={cfPath}
          draggable={false}
          onError={() => setCfPath(undefined)}
        />
      ) : (
        <Image
          className="no-select"
          style={{
            margin: '0 auto',
            borderRadius: '3px',
            height: `${height ?? ''}px`,
            maxHeight: (isImageGrid && isGridWidget) ? '205px' : '',
          }}
          src={cfPath}
          draggable={false}
          onError={() => setCfPath(undefined)}
        />
      )}
      {caption && (
        <figcaption>
          <div
            className="fz-dark-font"
            style={{
              margin: '10px auto 0',
              padding: '10px',
              textAlign: 'center',
              fontSize: '1.3rem',
            }}
          >
            <SoundRenderer widget={id} text={caption} />
          </div>
        </figcaption>
      )}
    </figure>
  )
}

interface ImageWidgetProps {
  widget: {
    title: string
    caption: string
    showBorder: boolean
    path: string
    video_path: string
    height?: number
  }
  id: string
  grid?: string
  course?: string
  isGridWidget?: boolean
  isImageGrid?: boolean
}

const ImageWidget: React.FC<ImageWidgetProps> = (props) => {
  const [cfPath, setCfPath] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [caption, setCaption] = useState<string>('')
  const [video, setVideo] = useState<string>('')
  const videoRef = useRef<HTMLVideoElement>(null)
  const [showBorder, setShowBorder] = useState<boolean>(true)
  const [widgetResponse, loading, widgetReq] = useAPI(`/widgets/${props.id}`, 'LAZYGET')
  const isMobile = useMobile()

  const imageFigureProps = {
    cfPath,
    setCfPath,
    height: props.widget.height,
    showBorder,
    title,
    caption,
    id: props.id,
    isGridWidget: props.isGridWidget,
    isImageGrid: props.isImageGrid
  }

  const gridID = props.grid

  const onWidgetResponse = (widget) => {
    if (!widget) return

    setShowBorder(widget.showBorder)
    if (widget.caption) setCaption(widget.caption)
    if (widget.title) setTitle(widget.title)
  }

  const getCfPath = async (widget) => {
    if (!widget) return
    try {
      const urlRes = await axios.post('/user/signed', { url: widget?.path, widget: gridID || props.id })

      if (urlRes?.data?.success) {
        setCfPath(urlRes?.data?.url)
      }
    } catch (err) {
      console.error(err)
    }
    if (widget?.video_path) {
      try {
        const fullPath = `courses/writing-guides/${widget?.video_path}.mp4`
        const videoRes = await axios.post('/user/signed', { url: fullPath, widget: gridID || props.id })

        if (videoRes?.data?.success) {
          setVideo(videoRes?.data?.url)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  useEffect(() => {
    if (!props.widget?.path) widgetReq({ params: { cache: true, course: props.course } })
    if (props.widget?.path) {
      onWidgetResponse(props.widget)
      getCfPath(props.widget)
    }
  }, [])

  useEffect(() => {
    if (!widgetResponse) return
    
    onWidgetResponse(JSON.parse(widgetResponse?.widget?.cache))
    getCfPath(JSON.parse(widgetResponse?.widget?.cache))
  }, [widgetResponse])

  if (loading) return <PlaceholderWidget widget_type="ImageWidget" />

  return (
    <>
      {cfPath && (
        <WidgetWrapper widget={{ widgetId: props.id, widgetType: 'ImageWidget' }}>
          <div style={{ position: 'relative', height: '100%' }}>
            {title && (
              <h2 className="fz-colored-background" style={{ color: 'var(--off-white)', textAlign: 'center', padding: '12px 0', }}>
                <SoundRenderer widget={props.id} text={title} />
                {/* <SoundInjector widget={props.id}>{title}</SoundInjector> */}
              </h2>
            )}
            {!video && (
              <ImageFigure
                data-t={`widget:image-id:${props.id}`}
                { ...imageFigureProps }
              />
            )}
            {video && (
              <div style={{ background: 'white', display: 'grid', placeContent: 'center' }}>
                <HoverVideoPlayer
                  videoSrc={video}
                  sizingMode="overlay"
                  videoRef={videoRef}
                  restartOnPaused
                  pausedOverlay={<ImageFigure { ...imageFigureProps } overlay />}
                />
              </div>
            )}
          </div>
        </WidgetWrapper>
      )}
    </>
  )
}

export default ImageWidget
