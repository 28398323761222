import styled from '@emotion/styled'
import { blue } from 'src/components/pages/landing_page/v3/styles'

export const PaymentOptionsWrapper = styled.div<{ activeTab: string }>`
  margin: 0 auto;
  width: 500px;

  .mantine-Tabs-tabsList {
    margin-bottom: 8px;
  }

  .mantine-Tabs-tabLabel {
    font-size: 1.5rem;
    font-family: 'Tilt Warp';
  }

  .mantine-Tabs-tab:first-child {
    border-bottom: ${({ activeTab }) => activeTab === 'stripe' && `2px solid ${blue}`};
  }
  .mantine-Tabs-tab:first-child .mantine-Tabs-tabLabel {
    color: ${({ activeTab }) => activeTab === 'stripe' ? blue : '#B2B8C7'};
  }

  .mantine-Tabs-tab:last-child {
    border-bottom: ${({ activeTab }) => activeTab === 'paypal' && `2px solid ${blue}`};
  }

  .mantine-Tabs-tab:last-child .mantine-Tabs-tabLabel {
    color: ${({ activeTab }) => activeTab === 'paypal' ? blue : '#B2B8C7'};
  }

  .mantine-Tabs-tabControl {
    border-bottom: ${({ activeTab }) => activeTab === 'stripe' && `3px solid ${blue}`};
  }
  
  input, .StripeElement {
    margin-top: 8px;
    border-radius: 25px;
    border: 1px solid ${blue};
    padding: 10px 20px !important;
    font-size: 1.25rem !important;
    font-family: 'Outfit' !important;
    width: 100%;
  }

  .StripeElement {
    background-color: white !important;
    margin-bottom: 16px;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin: 0;
    padding: 0 16px;

    .mantine-Tabs-tabLabel {
      font-size: 1.25rem;
    }
  }
`

export const Disclaimer = styled.p`
  font-size: 1rem;
  font-family: 'Outfit';
  color: #B4B4B4;
  line-height: 1.2;
  margin-bottom: 16px !important;
  text-align: left;
`
