import { useContext } from 'react'
import PaymentPlan from './PaymentPlan'
import usePricing from 'src/hooks/subscriptions/usePricing'
import { SubscriptionContext } from 'src/contexts/SubscriptionContext'
import { PaymentPlans } from './styles/PlanSelection.styles'
import mari from 'src/assets/chibi/mari/chibi_new_phrases.png'
import tomoko from 'src/assets/chibi/tomoko/chibi_culture_clip.png'

const PlanSelection = () => {
  const { affiliate } = useContext(SubscriptionContext)
  const { monthlyPrice, annualPrice, monthlyBase, annualBase } = usePricing()

  return (
    <>
      <PaymentPlans>
        <PaymentPlan
          plan='Monthly'
          chibi={mari}
          paymentPlan='premium-monthly'
          affiliate={affiliate}
          actualPrice={monthlyPrice}
          baseP={monthlyBase}
        />
        <PaymentPlan
          plan='Yearly'
          chibi={tomoko}
          paymentPlan='premium-annual'
          affiliate={affiliate}
          actualPrice={annualPrice}
          baseP={annualBase}
        />
      </PaymentPlans>
    </>
  )
}

export default PlanSelection
