import React from 'react'

import styled from 'styled-components'

import SoundRenderer from '../SoundRenderer'
import useMobile from '../../../../hooks/useMobile'
import useStudyMode from '../../hooks/useStudyMode'

const LineSegmentStyles = styled.div`
  font-family: "Milliard Book", sans-serif;
  border-radius: 10px;
  padding: ${(props) => props.index % 2 === 0 ? '0.5rem 1rem 0.5rem 2rem' : '0.5rem 2rem 0.5rem 1rem'};
  margin: 0.8rem 3rem 0.3rem;
  background-color: ${(props) => props.textColor};
  color: #fcfaff !important;

  .conversation-line {
    font-size: var(--reg-font-size);
    padding: 2px 0;
    color: white !important;
  }

  .conversation-line.other {
    .sound-span {
      text-indent: 0px;
    }
    
    .speaker-index {
      font-feature-settings: tnum;
      font-variant-numeric: tabular-nums;
      position: absolute;
      left: 0;
    }
  }

  .commentary {
    font-size: 1rem;
  }
`

const LineSegmentConvo = (props) => {
  const isMobile = useMobile()
  const { inStudyMode, hideEnglish, hideOtherLang } = useStudyMode()

  return (
    <LineSegmentStyles
      isMobile={isMobile}
      final_line={props.line_num === props.conversation_length - 1}
      displayOrder={props.displayOrder}
      shouldOrder={props.shouldOrder}
      index={props.index}
      textColor={props.textColor}>
        {props.displayOrder && ( // Other first
          <>
            <div className="conversation-line other">
              {props.shouldOrder && <span className="speaker-index">{props.indexing === 'alpha' ? (props.index % 2 ? 'B: ' : 'A: ') : `${props.index + 1}. `}</span>}
              <SoundRenderer
                index={props.index}
                placeholder={hideOtherLang}
                playingNum={props.current}
                num={props.index}
                onSound={props.register}
                ping={props.next}
                text={props.other_lang}
                white
                styles={{ color: '#fcfaff' }}
                male={props.male}
                female={props.female}
                inverted
                translation={props.english}
              />
            </div>
            {(props.showEnglish || inStudyMode) && (
              <div
                className="conversation-line english"
              >
                <p className="english">
                  <SoundRenderer
                    text={props.english}
                    index={props.index}
                    placeholder={hideEnglish}
                    white
                    styles={{ color: '#fcfaff' }}
                    male={props.male}
                    female={props.female}
                    inverted
                    translation={props.other_lang}
                  />
                </p>
                {props.commentary && props.commentary?.content && <p className="commentary">
                  <SoundRenderer text={props.commentary.content} white styles={{ color: '#fcfaff' }} />
                </p>}
              </div>
            )}
          </>
        )}
        {!props.displayOrder && ( // English first
          <>
            <div className="conversation-line english">
              {props.shouldOrder && <span className="speaker-index">{props.indexing === 'alpha' ? (props.index % 2 ? 'B: ' : 'A: ') : `${props.index + 1}. `}</span>}
              <SoundRenderer
                text={props.english}
                index={props.index}
                placeholder={hideEnglish}
                white
                styles={{ color: '#fcfaff' }}
                male={props.male}
                female={props.female}
                inverted
                translation={props.other_lang}
              />
            </div>
            {(props.showEnglish || inStudyMode) && (
              <div className="conversation-line other">
                <SoundRenderer
                  index={props.index}
                  placeholder={hideOtherLang}
                  playingNum={props.current} num={props.index}
                  onSound={props.register} ping={props.next}
                  text={props.other_lang}
                  white
                  styles={{ color: '#fcfaff' }}
                  male={props.male}
                  female={props.female}
                  inverted
                  translation={props.english}
                />
              </div>
            )}
          </>
        )}
    </LineSegmentStyles>
  )
}

export default LineSegmentConvo
