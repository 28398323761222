import React, { useEffect, useRef, useState } from 'react'
import * as wanakana from 'wanakana'

import styled from 'styled-components'

import { Icon } from 'semantic-ui-react'
import { ColorSwatch, Group, Popover } from '@mantine/core'

import useOutsideAlerter from 'src/hooks/useOutsideAlerter'

const RichTextOptions = styled.div`
  position: absolute;
  top: -40px;
  left: 20px;

  > div > button {
    height: 40px;
    width: 40px;

    > span {
      position: relative;
    }

    &:hover {
      background-color: #ccc;
    }
  }
`

interface RichTextButtonsProps {
  textAreaRef: React.RefObject<HTMLTextAreaElement> | React.RefObject<HTMLInputElement>
  selection?: { start: number; end: number }
  textColor: string
  highlightColor: string
  setTextColor: (arg: string) => void
  setHighlightColor: (arg: string) => void
}

const findActionAttribute = (element) => {
  // Search for the data-action attribute in the current element or its parent elements
  let currentElement = element
  while (currentElement) {
    const action = currentElement.getAttribute('data-action')
    if (action) {
      return action
    }
    currentElement = currentElement.parentElement
  }
  return null
}

const jpRegex = /(?!_|」|[\d,]|[⺀-⿕]|^[A-Z0-9]*$|[A-Z0-9]\n)(?:(?:\|:\w(?:<[#\w\d]*>)*|:\|)*(?:[A-Z]*～*[　-龯\d０-９？！_…❶-❾，･]+～*[A-Z]*|\([　-龯\d０-９]+\)|\([\w]+\)[　-龯\d０-９？！_…～❶-❾，･])+(?:[\w ]+」|\|:\w(?:<[#\w\d]*>)*|:\|)*)+/g

const bucketSVG = (
  <svg data-action='c' width="auto" height="auto" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path data-action='c' fillRule="evenodd" clipRule="evenodd" d="M4.5 0C3.11929 0 2 1.11929 2 2.5V6.29284L1.5606 6.73224C0.58429 7.70855 0.584293 9.29146 1.5606 10.2678L4.73217 13.4393C5.70849 14.4157 7.2914 14.4157 8.26771 13.4393L12.4393 9.26777C13.4156 8.29146 13.4156 6.70855 12.4393 5.73224L9.26771 2.56066C8.6326 1.92555 7.74078 1.70359 6.92625 1.89479C6.65566 0.806497 5.67202 0 4.5 0ZM6 3.70705V7H7V2.91383C7.52765 2.72776 8.13858 2.84575 8.5606 3.26777L11.7322 6.43934C12.318 7.02513 12.318 7.97488 11.7322 8.56066L11.2928 9H1.91379C1.7277 8.47234 1.84567 7.86138 2.26771 7.43934L6 3.70705ZM5.991 2.33478C5.90876 1.58409 5.27259 1 4.5 1C3.67157 1 3 1.67157 3 2.5V5.29284L5.73218 2.56066C5.81439 2.47845 5.90091 2.40315 5.991 2.33478Z" fill="#000000"/>
    <path data-action='c' d="M12.6445 9.73698L14.1788 11.2713C15.0933 12.1858 15.0099 13.6921 14 14.5C13.2075 15.134 12.0815 15.134 11.289 14.5C10.2791 13.6921 10.1957 12.1858 11.1102 11.2713L12.6445 9.73698Z" fill="#000000"/>
  </svg>
)

// given text that could have formatting tags in it, return the text such that any formatting tags that either end
// or terminate within a paren'd furi are moved outside of the furi
const cleanText = (text) => {
  let cleanedText = text
  // whether a double space is encountered in the text
  const doubleSpace = cleanedText.indexOf('  ') > -1 ? 1 : 0

  cleanedText = text.replace('  ', ' ')
  const furiganaTagMiddleRegex = /([　-龯])(\([　-龯]+(\|:\w(<[#\w\d]*>)*|:\|)[　-龯]+\))/g
  const furiganaTagEndRegex = /([　-龯])(\([　-龯]+(\|:\w(<[#\w\d]*>)*|:\|)\))/g
  const furiganaTagStartRegex = /([　-龯])(\((\|:\w(<[#\w\d]*>)*|:\|)[　-龯]+\))/g
  const tagRegex = /\|:\w(<[#\w\d]*>)*|:\|/g
  let plus = 0, minus = 0

  console.log('starting text:', cleanedText)

  // if there are any middle matches:
  // - \|:\w(<[#\w\d]*>)* matches (starting format tags) go in front of the [　-龯] character before the first \(
  // - :| matches (ending format tags) go after the \) character captured at the end of the regex
  // Example: 少(し|:uょう) becomes |:u少(しょう)
  // Example: 少(しょ:|う) becomes 少(しょう):|
  while (furiganaTagMiddleRegex.test(cleanedText)) {
    console.log('found middle match')
    cleanedText = cleanedText.replace(furiganaTagMiddleRegex, (match, kanji, furi, tag) => {
      console.log(match, kanji, furi, tag)
      const startTag = match.indexOf('|:') > -1
      const endTag = match.indexOf(':|') > -1
      // location in the match where the start tag is located
      if (match.indexOf('|:') > -1) {
        minus += match.indexOf('|:')
        plus += match.indexOf('|:')
      }
      // location in the match where the end tag is located
      if (match.indexOf(':|') > -1) {
        // plus gets the number of chars left in the match after the closing tag
        plus += match.length - match.indexOf(':|') - 2
      }
      console.log(plus, minus)
      const formatTags = furi.match(tagRegex)
      const newFuri = furi.replace(tagRegex, '')
      console.log(formatTags, newFuri)
      return startTag ? `${formatTags[0]}${kanji}${newFuri}` : `${kanji}${newFuri}${formatTags[0]}`
    })
  }

  console.log('after middle matches:', cleanedText)

  // if there are any end matches, move the format tag to the end of the furi
  // Example: 少(しょう:|) becomes 少(しょう):|
  while (furiganaTagEndRegex.test(cleanedText)) {
    console.log('found end match')
    cleanedText = cleanedText.replace(furiganaTagEndRegex, (match, kanji, furi, tag) => {
      console.log(match, kanji, furi, tag)
      const startTag = match.indexOf('|:') > -1
      const endTag = match.indexOf(':|') > -1

      // location in the match where the start tag is located
      if (match.indexOf('|:') > -1) {
        minus -= match.length - match.indexOf(tag) - tag.length
        plus += match.indexOf('|:')
      }
      // location in the match where the end tag is located
      if (match.indexOf(':|') > -1) {
        // plus gets the number of chars left in the match after the closing tag
        plus += match.length - match.indexOf(':|') - 2
      }
      console.log(plus, minus)
      const formatTags = furi.match(tagRegex)
      const newFuri = furi.replace(tagRegex, '')
      return `${kanji}${newFuri}${formatTags[0]}`
    })
  }

  console.log('after end matches:', cleanedText)

  // if there are any start matches, move the format tag to the start of the furi before the kanji
  // Example: 少(|:uしょう) becomes |:u少(しょう)
  while (furiganaTagStartRegex.test(cleanedText)) {
    console.log('found start match')
    cleanedText = cleanedText.replace(furiganaTagStartRegex, (match, kanji, furi, tag) => {
      console.log(match, kanji, furi, tag)
      const startTag = match.indexOf('|:') > -1
      const endTag = match.indexOf(':|') > -1

      // location in the match where the start tag is located
      if (match.indexOf('|:') > -1) {
        minus += match.indexOf('|:')
        plus += match.indexOf('|:')
      }
      // location in the match where the end tag is located
      if (match.indexOf(':|') > -1) {
        // plus gets the number of chars left in the match after the closing tag
        plus -= match.indexOf('|:')
      }
      const formatTags = furi.match(tagRegex)
      const newFuri = furi.replace(tagRegex, '')
      return `${formatTags[0]}${kanji}${newFuri}`
    })
  }

  console.log('after start matches:', cleanedText)

  return { text: cleanedText.replace('  ', ' ').replace(')undefined', ')'), plus, minus }
}

const highlightColors = [
  '#ffeb8e',
  '#a1cdff',
  '#ff99ff',
  '#98eadc',
  '#ff867d',
  '#ddc1ff',
  '#b3b3b3',
  '#ffcc00',
]

const textColors = [
  '#ff0000',
  '#ff8000',
  '#ffff00',
  '#00ff00',
  '#00ffff',
  '#0000ff',
  '#ff00ff',
  '#000000',
]

const RichTextButtons = React.forwardRef<HTMLDivElement, RichTextButtonsProps>((props, ref) => {
  // const [textColor, setTextColor] = useState('#ff0000')
  // const [highlightColor, setHighlightColor] = useState('#ffff00')
  const [savedSelection, setSavedSelection] = useState({ start: -1, end: -1 })
  const colorButtonRef = useRef(null)
  const highlightButtonRef = useRef(null)
  const textButtonRef = useRef(null)
  const [highlightOpen, setHighlightOpen] = useState(false)
  const [textOpen, setTextOpen] = useState(false)

  useOutsideAlerter(highlightButtonRef, () => setHighlightOpen(false))
  useOutsideAlerter(textButtonRef, () => setTextOpen(false))

  const { textColor, setTextColor, highlightColor, setHighlightColor } = props
  const [language, setLanguage] = useState('es')

  // console.log(props.textAreaRef?.current?.selectionStart, props.textAreaRef?.current?.selectionEnd)

  useEffect(() => {
    console.log('fresh buttons')
  }, [])

  useEffect(() => {
    // console.log(savedSelection)
  }, [savedSelection])

  // update the selection when the text area ref's selection updates
  useEffect(() => {
    if (props.textAreaRef?.current) props.textAreaRef.current.onselect = () => {
      const { selectionStart: start, selectionEnd: end } = props.textAreaRef.current
      setSavedSelection({ start, end })
    }

    return () => {
      if (props.textAreaRef?.current) props.textAreaRef.current.onselect = null
    }
  }, [props.textAreaRef])

  const clearFormatting = (e) => {
    e.preventDefault()
    const textArea = props.textAreaRef.current
    if (!textArea) return

    const { start, end } = savedSelection
    const text = textArea.value
    const before = text.substring(0, start)
    const after = text.substring(end, text.length)
    const selected = text.substring(start, end)
    const newSelection = before + selected.replace(/<[^>]*>/g, '') + after
    textArea.value = newSelection
    textArea.selectionStart = start
    textArea.selectionEnd = end
    setSavedSelection({ start, end })
  }

  // return the text area ref's text with an inserted format tag around its current selection
  // e.g. if the text area's text is 'hello world' and the user has selected 'world', then
  // the returned text will be 'hello |:b world :|'
  const insertFormatTag = (e, data = { color: '' }) => {
    e.preventDefault()

    const button = e.target
    // console.log(button)
    let action = button.getAttribute('data-action')
    if (data.color) action += `<${data.color}>`
    const textArea = props.textAreaRef.current
    // console.log(textArea)
    if (!textArea) return

    const doubleSpace = textArea.value.indexOf('  ')
    const startT = textArea.selectionStart
    const endT = textArea.selectionEnd

    // if there is a double space before selection start, remove it and adjust selection
    if (doubleSpace > -1 && doubleSpace < startT) {
      textArea.value = textArea.value.replace('  ', ' ')
      textArea.setSelectionRange(startT - 1, endT - 1)
    }

    // if the last character in the selection is a space, bring the selection end back 1 char
    if (textArea.value[endT - 1] === ' ') {
      textArea.setSelectionRange(startT, endT - 1)
    }

    const start = textArea.selectionStart
    const end = textArea.selectionEnd
    const text = textArea.value

    // we are going to use this afterwards in order to trigger a document exec so we can preserve
    // ctrl+z functionality
    const previousText = text

    // just return if the selection is empty
    if (start === end) return

    // console.log('selection not empty', start, end, text)

    // we need to first do an initial check if what we are trying to put formatting tags around in the string
    // isn't already formatted with the same action; there are a few possible ways in which this may manifest:
  
    // 1) the highlighted text is an exclusive subsection of a pre-existing highlighted section
    // - in this case, we want to remove the formatting around the subsection. this can theoretically come up
    //   in three ways of its own:
    //   a) the subsection starts at the beginning and terminates before the closing tag
    //     - in this case, we want to shift the pre-existing opening tag to the end of the highlighted section
    //   b) the subsection starts after the opening tag and terminates at the end
    //     - in this case, we want to shift the pre-existing closing tag to the beginning of the highlighted section
    //   c) the subsection starts after the opening tag and terminates before the closing tag
    //     - in this case, we want to add a closing tag at the beginning of the highlighted section and an opening
    //       tag at the end of the highlighted section
  
    // 2) the highlighted text encompasses the entire pre-existing highlighted section
    // - in this case, we want to remove the formatting around the entire section

    // the first thing we have to do is check if the highlighted text is already formatted with the same action;
    // we do this by building up a marker stack of the opening and closing tags from the beginning of the entire
    // text up to the start of the highlighted text for all possible actions and then do a check to see if the
    // specific action exists in the stack once we've reached the start of the highlighted text
    const markerStack = []
    let markerRegex = /\|:\w(?:<[#\w\d]*>)*|:\|/g
    let markerMatch
    while ((markerMatch = markerRegex.exec(text.slice(0, start))) !== null) {
      console.log(markerMatch)
      // if we've encountered a closing tag, then we want to pop the last opening tag off the stack
      if (markerMatch[0].slice(0, 2) === ':|') {
        markerStack.pop()
      } else {
        // otherwise we want to push the opening tag onto the stack
        markerStack.push(markerMatch[0])
      }
    }
    console.log(markerStack)

    const stackContainsAction = markerStack.some((marker) => marker.slice(2) === action)
    // console.log('stack contains action?', stackContainsAction)

    // if the first character following |: that we want to inject into the string is a match with the jpRegex,
    // then we do not want a space; otherwise we do want a space
    const charAfterFirstJp = new RegExp(jpRegex).test(text[start]) || ['|', ')'].includes(text[start])
    // console.log(text[start], 'jp?', charAfterFirstJp)
    const charBeforeLastJp = new RegExp(jpRegex).test(text[end - 1]) || text[end - 1] === '|' || text[end - 1] === '(' || (text[end - 1] === ')' && wanakana.isKana(text[end - 2]))
    // console.log(text[end - 1], 'jp?', charBeforeLastJp)

    // if the marker stack is empty, then the highlighted text is not already formatted with the same action
    if (!stackContainsAction) {
      // UNLESS we are literally selecting the entirety of a selection, in which case we want to remove that
      // formatting if it's the same as what we're trying to inject
      const testRegex = new RegExp(`^\\|:${action}(?:<\\w+>)?\\s*.+:\\|$`, 'g')
      const actionRegex = new RegExp(`^\\|:${action}(?:<\\w+>)?\\s*|:\\|$`, 'g')
      const stackContainsAction = text.slice(start, end).match(testRegex)

      console.log('stack contains action?', stackContainsAction)

      if (stackContainsAction) {
        // if the highlighted text is the entirety of a pre-existing highlighted section, then we want to remove
        // the formatting around the entire section
        console.log('stack contains action insert')
        let newChunk = text.slice(start, end).replace(actionRegex, '')
        const { text: newText, plus, minus } = cleanText(text.slice(0, start) + newChunk + text.slice(end))

        // actual new chunk will be the selection (adjusted) taken out of newText
        newChunk = newText.slice(start - minus, start + newChunk.length + plus)
        
        props.textAreaRef.current.focus()
        props.textAreaRef.current.setSelectionRange(start - minus, end + plus)
        document.execCommand('insertText', false, newChunk)
        return
      }

      // if the highlighted text is not already formatted with the same action, then we want to inject the
      // opening and closing tags around the highlighted text
      console.log('stack does not contain action insert')
      let newChunk = `|:${action}${charAfterFirstJp ? '' : ' '}` + text.slice(start, end) + `${charBeforeLastJp ? '' : ' '}:|`
      const uncleanText = text.slice(0, start) + newChunk + text.slice(end)
      const { text: newText, plus, minus } = cleanText(uncleanText)

      // actual new chunk will be the selection (adjusted) taken out of newText
      newChunk = newText.slice(start - minus, start + newChunk.length + plus)

      props.textAreaRef.current.focus()
      props.textAreaRef.current.setSelectionRange(start - minus, end + plus)
      document.execCommand('insertText', false, newChunk)
      // props.textAreaRef.current.setRangeText(newText, 0, uncleanText.length, 'end')
      // props.textAreaRef.current.setSelectionRange(start, start + newChunk.length)
      return
    }

    // if the highlighted text is already formatted with the same action, then we want to check up until
    // the end of the highlighted text for a closing tag; if we find one, then we want to shift the closing
    // tag to the end of the selection; otherwise we want to remove the formatting around the highlighted text
    // by closing the opening tag at the beginning of the highlighted text and opening a new action tag at the
    // end of the highlighted text
    markerRegex = /\|:\w(?:<[#\w\d]*>)*|:\|/g
    markerMatch = null
    while ((markerMatch = markerRegex.exec(text.slice(0, end))) !== null) {
      // if we've encountered a closing tag, then we want to shift the closing tag to the end of the selection
      if (markerMatch[0].slice(0, 2) === ':|') {
        console.log('closing tag found insert')
        const { text: newText, plus, minus } = cleanText(text.slice(0, start) + text.slice(start, end) + `:|` + text.slice(end))
        props.textAreaRef.current.focus()
        props.textAreaRef.current.setSelectionRange(0, previousText.length)
        document.execCommand('insertText', false, newText)
        return
      }
    }
    // if we haven't encountered a closing tag, then we want to remove the formatting around the highlighted text
    // by closing the opening tag at the beginning of the highlighted text and opening the action tag at the end
    // note: we have to check if the first character before the first jp is actually a ); if it is, it's the end of
    // a furi and we don't want to add a space
    console.log('closing and reopen insert')
    const charBeforeFirstJp = new RegExp(jpRegex).test(text[start - 1]) || (text[start - 1] === ')' && new RegExp(jpRegex).test(text[start - 2]))
    const charAfterLastJp = new RegExp(jpRegex).test(text[end])
    const { text: newText, plus, minus } = cleanText(text.slice(0, start) + `${charBeforeFirstJp ? '' : ' '}:|` + text.slice(start, end) + `|:${action}${charAfterLastJp ? '' : ' '}` + text.slice(end))
    props.textAreaRef.current.focus()
    props.textAreaRef.current.setSelectionRange(0, previousText.length)
    document.execCommand('insertText', false, newText)
  }

  return (
    <RichTextOptions ref={ref}>
      <div style={{ position: 'relative', display: 'flex' }}>
        <button
          data-action='b'
          onClick={insertFormatTag}
        >
          <b data-action='b'>B</b>
        </button>
        <button
          data-action='i'
          onClick={insertFormatTag}
        >
          <em data-action='i' style={{ fontStyle: 'italic' }}>I</em>
        </button>
        <button
          data-action='u'
          onClick={insertFormatTag}
        >
          <span data-action='u' style={{ textDecoration: 'underline' }}>U</span>
        </button>
        {/* button with paint bucket and color swatch underneath, with side arrow to open color picker */}
        <button
          data-action='c'
          onClick={(e) => {
            insertFormatTag(e, { color: textColor })
          }}
        >
          <div data-action='c' style={{ position: 'relative' }}>
            <div data-action='c' style={{ height: '20px', width: '20px', position: 'absolute', left: '12px', top: '-14px' }}>{bucketSVG}</div>
            <div data-action='c' style={{ backgroundColor: textColor, position: 'absolute', top: '10px', height: '5px', width: '26px', left: '8px' }}></div>
          </div>
        </button>
        {/* <button
          data-action='c'
          onClick={(e) => {
            colorButtonRef.current.click()
          }}
          style={{ width: '20px' }}
        >
          <Icon data-action='c' name='caret down' style={{ position: 'absolute', top: '8px' }} />
          <input
            style={{ visibility: 'hidden' }}
            value={textColor}
            type='color'
            data-action='c'
            placeholder='#ff0000'
            onChange={(e) => {
              setTextColor(e.target.value)
              // insertFormatTag(e, { color: e.target.value })
            }}
            ref={colorButtonRef}
          />
        </button> */}
        <Popover opened={textOpen} onChange={setTextOpen} width={200} position="bottom" withArrow shadow="md">
          <Popover.Target>
            <button
              data-action='c'
              onClick={(e) => {
                // highlightButtonRef.current.click()
                setTextOpen(!textOpen)
              }}
              style={{ width: '20px', position: 'relative' }}
            >
              <Icon data-action='c' name='caret down' style={{ position: 'absolute', top: '8px', right: '0px' }} />
              {/* <input
                style={{ visibility: 'hidden' }}
                type='color'
                data-action='h'
                placeholder='#ff0000'
                onChange={(e) => {
                  setHighlightColor(e.target.value)
                  // insertFormatTag(e, { color: e.target.value })
                }}
                ref={highlightButtonRef}
              /> */}
            </button>
          </Popover.Target>
          <Popover.Dropdown>
            <Group ref={textButtonRef}>
              {textColors.map((color, i) => (
                <ColorSwatch data-action='c' key={i} color={color} onClick={(e) => {
                  setTextColor(color)
                }} />
              ))}
            </Group>
          </Popover.Dropdown>
        </Popover>
        <button
          data-action='h'
          onClick={(e) => {
            insertFormatTag(e, { color: highlightColor })
          }}
        >
          <div data-action='h' style={{ position: 'relative' }}>
            <div data-action='h' style={{ backgroundColor: highlightColor, height: '20px', width: '20px', left: '12px', top: '-12px', position: 'absolute' }}>H</div>
          </div>
        </button>
        <Popover opened={highlightOpen} onChange={setHighlightOpen} width={200} position="bottom" withArrow shadow="md">
          <Popover.Target>
            <button
              data-action='h'
              onClick={(e) => {
                // highlightButtonRef.current.click()
                setHighlightOpen(!highlightOpen)
              }}
              style={{ width: '20px', position: 'relative' }}
            >
              <Icon data-action='h' name='caret down' style={{ position: 'absolute', top: '8px', right: '0px' }} />
              {/* <input
                style={{ visibility: 'hidden' }}
                type='color'
                data-action='h'
                placeholder='#ff0000'
                onChange={(e) => {
                  setHighlightColor(e.target.value)
                  // insertFormatTag(e, { color: e.target.value })
                }}
                ref={highlightButtonRef}
              /> */}
            </button>
          </Popover.Target>
          <Popover.Dropdown>
            <Group ref={highlightButtonRef}>
              {highlightColors.map((color, i) => (
                <ColorSwatch data-action='h' key={i} color={color} onClick={(e) => {
                  setHighlightColor(color)
                }} />
              ))}
            </Group>
          </Popover.Dropdown>
        </Popover>
        <button
          data-action='l'
          onClick={(e) => {
            insertFormatTag(e, { color: language })
          }}
        >
          <div data-action='l' style={{ position: 'relative' }}>
            <div data-action='l' style={{ height: '20px', width: '20px', left: '12px', top: '-12px', position: 'absolute' }}>L</div>
          </div>
        </button>
        <button
          data-action='e'
          onClick={clearFormatting}
        >
          <div data-action='e' style={{ position: 'relative' }}>
            <div data-action='e' style={{ height: '20px', width: '20px', left: '12px', top: '-12px', position: 'absolute' }}><Icon name='eraser' /></div>
          </div>
        </button>
      </div>
    </RichTextOptions>
  )
})
RichTextButtons.displayName = 'RichTextButtons'

export default RichTextButtons
