import { VerbSelection, GrammarSelection, Location, GrammarResponse } from './types'
import verbDataList, { getLessonVerbs, getAllVerbs, Verb } from '../VerbData'
import { conjugations, getLessonGrammar, kanaForms, Conjugation } from '../ConjugationData'

// grammar to leave out for now (not taught in lessons)
const notTaughtGrammar = ['nakatta', 'te_iru', 'te_inai', 'te_ita', 'te_inakatta']

export const getAvailableVerbs = (verbs: VerbSelection, location: Location): Verb[] => {
  if (Array.isArray(verbs)) {
    if (!verbs.length) return getAllVerbs() // fallback to all verbs if none are selected
    
    // get text out of auto sound-injected records
    const verbText = verbs.map((v) => {
      if (Array.isArray(v)) return v[0].text
      return v
    })
    
    return verbText
  }

  const { course, lesson } = location
  return getLessonVerbs(course.slice(-1), lesson, verbs)
}

// filter out any grammar that doesn't include at least one of the available verbs
export const filterGrammerByVerbs = (grammarResponse: GrammarResponse, availableVerbs: Verb[]): Conjugation[] => {
  const { grammar } = grammarResponse
  const grammarKeys = Object.keys(grammar) as Conjugation[]

  return grammarKeys.filter((g) => {
    const approvedVerbs = grammar[g]
    return approvedVerbs.some((v) => availableVerbs.includes(v))
  })
}

export const getAvailableGrammar = (grammar: GrammarSelection, location: Location, grammarResponse, availableVerbs: Verb[]): Conjugation[] => {
  const filteredGrammar = filterGrammerByVerbs(grammarResponse, availableVerbs).filter((g) => !notTaughtGrammar.includes(g))

  if (Array.isArray(grammar)) return grammar.length ? filteredGrammar.filter((g) => grammar.includes(g)) : filteredGrammar // fallback to all grammar if none are selected

  const { course, lesson } = location
  return getLessonGrammar(course.slice(-1), lesson, grammar).filter((g) => filteredGrammar.includes(g))
}

const hints = {
  あ: 'あ form',
  い: 'verb stem (い form)',
  う: 'う form',
  え: 'え form',
  お: "informal let's form",
  て: 'て form',
  た: 'た form',
  ちゃ: 'ちゃ form',
  potential: 'え form'
}

const いる_える_hints = {
  あ: 'verb stem (remove る)',
  い: 'verb stem (remove る)',
  お: 'verb stem (remove る) + よう',
  て: 'verb - る + て',
  た: 'verb - る + た',
  ちゃ: 'verb stem (remove る) + ちゃ',
  potential: 'verb stem (remove る) + られ'
}

const する_hints = {
  あ: 'verb stem (し)',
  い: 'verb stem (し)',
  お: 'しよう',
  て: 'して',
  た: 'した',
  potential: '"can do" form - る'
}

const くる_hints = {
  あ: 'こ',
  い: 'verb stem (き)',
  お: 'こよう',
  て: 'きて',
  た: 'きた',
  potential: 'こられ'
}

const いく_hints = {
  て: 'いって',
  た: 'いった',
}

const get_て_た_form_hint = (verb: Verb, ichidan: boolean, form: 'て' | 'た'): string => {
  if (verb === 'する') return `SURU ${form} form (${する_hints[form]})`
  if (verb === '来る') return `KURU ${form} form (${くる_hints[form]})`
  if (verb === '行く') return `IKU ${form} form (${いく_hints[form]})`
  if (ichidan) return `IRU/ERU ${form} form (${いる_える_hints[form]})`

  const start = `${form} form`
  const verbEnding = verb.slice(-1)
  const endingIndex = kanaForms.う.indexOf(verbEnding)

  let end
  if (endingIndex < 3) end = `${kanaForms[form][0]}`
  else if (endingIndex < 6) end = `${kanaForms[form][1]}`
  else end = `${kanaForms[form][endingIndex - 4]}`
  
  return `${start} (${verbEnding} ➡ ${end})`
}

export const getHint = (verb: Verb, grammar: Conjugation): string => {
  const { ichidan } = verbDataList[verb]
  const { stem, ending } = conjugations[grammar]

  const isIchidanHint = ichidan && Object.keys(いる_える_hints).includes(stem)
  const isSuruHint = verb === 'する' && Object.keys(する_hints).includes(stem)
  const isKuruHint = verb === '来る' && Object.keys(くる_hints).includes(stem)
  const isIkuHint = verb === '行く' && Object.keys(いく_hints).includes(stem)

  let hint = hints[stem]
  
  if (stem === 'あ') {
    if (verb === 'ある') return `ARU irregular: ${ending}`
    if (verb.slice(-1) === 'う') hint = 'あ form (う ➡ わ)'
  }
  
  if (isIchidanHint) hint = `IRU/ERU: ${いる_える_hints[stem]}`
  if (isSuruHint) {
    if (grammar === 'eru') hint = 'SURU irregular: "can do" form'
    else hint = `SURU irregular: ${する_hints[stem]}`
  }
  if (isKuruHint) hint = `KURU irregular: ${くる_hints[stem]}`
  if (isIkuHint) hint = `IKU irregular: ${いく_hints[stem]}`
  
  if (stem === 'て' || stem === 'た') hint = get_て_た_form_hint(verb, ichidan, stem)
  
  if (grammar === 'te' || grammar === 'ta') return hint
  return `${hint} + ${ending}`
}
