import React, { useState } from 'react'

import { Icon } from 'semantic-ui-react'
import { FlexCol } from 'src/assets/styles/globalStyles'
import { AddCell, CellManagementBar, ChangeCellContent, GridCell as Cell, RemoveCell } from '../../styles/GridTool.styles'

import NestedWidgetTool from '../NestedWidgetTool'
import EmptyGridCell from './EmptyGridCell'
import DeleteConfirmationModal from '../../../modals/DeleteConfirmation.modal'
import { ToolInput } from '../../parts'

interface GridCellProps {
  fitInView: boolean
  rowLength: number
  rowIndex: number
  colIndex: number
  cell_widget_id: string
  course: string
  lesson: string
  sublesson: string
  assignWidget: (arg: { row: number, col: number, type: string }) => Promise<void>
  handleAddCell: (row_index: number, col_index: number) => void
  handleRemoveCell: (row_index: number, col_index: number) => void
  handleGridWidgetSave?: () => void
  fetchWidget?: (string) => void
  fetchSubLesson?: () => void
}

const GridCell: React.FC<GridCellProps> = ({
  fitInView,
  rowLength,
  rowIndex,
  colIndex,
  cell_widget_id,
  course,
  lesson,
  sublesson,
  assignWidget,
  handleAddCell,
  handleRemoveCell,
  handleGridWidgetSave,
  fetchWidget,
  fetchSubLesson
}) => {
  const [replacingCellWidget, setReplacingCellWidget] = useState<string>()
  const [deletePromptOpen, setDeletePromptOpen] = useState(false)

  const confirmDeleteCell = () => {
    handleRemoveCell(rowIndex, colIndex)
    setDeletePromptOpen(false)
  }

  return (
    <Cell
      fitInView={fitInView}
      rowLength={rowLength}
    >
      <DeleteConfirmationModal
        message='This widget will be'
        emphasized='permanently removed from the grid, database, and cache'
        deletePromptOpen={deletePromptOpen}
        setDeletePromptOpen={setDeletePromptOpen}
        handleDeleteConfirmed={confirmDeleteCell}
      />
      {(!cell_widget_id || replacingCellWidget === `${rowIndex}-${colIndex}`) ? (
        <EmptyGridCell
          assignWidget={assignWidget}
          rowIndex={rowIndex}
          columnIndex={colIndex}
          forceModalOpen={replacingCellWidget === `${rowIndex}-${colIndex}`}
          onModalClose={() => setReplacingCellWidget(undefined)}
        />
      ) : (
        <>
          <FlexCol>
            <CellManagementBar>
              <ChangeCellContent
                onClick={() => setReplacingCellWidget(`${rowIndex}-${colIndex}`)}
                title='Change cell widget'
              >
                <Icon name='repeat' size='large' />
                Change widget
              </ChangeCellContent>
              <div>
                <ToolInput
                  value={`columnWidth.${rowIndex}.${colIndex}`}
                  inputType='number'
                  inputLabel='Cell Width'
                  min={1}
                  max={16}
                  title='16 = 100%, 8 = 50%, 4 = 25%'
                />
              </div>
            </CellManagementBar>
            <NestedWidgetTool
              widget_id={cell_widget_id}
              course={course}
              lesson={lesson}
              sublesson={sublesson}
              onGridWidgetSave={handleGridWidgetSave}
              fetchWidget={fetchWidget}
              fetchSubLesson={fetchSubLesson}
            />
              <CellManagementBar>
                <AddCell title='Add cell left' onClick={() => handleAddCell(rowIndex, colIndex)}>
                  <Icon name='arrow left' />
                  <Icon name='plus' fitted />
                </AddCell>
                <RemoveCell title='Remove cell' onClick={() => setDeletePromptOpen(true)}>
                  <Icon name='trash' fitted />
                </RemoveCell>
                <AddCell title='Add cell right' onClick={() => handleAddCell(rowIndex, colIndex + 1)}>
                  <Icon name='plus' />
                  <Icon name='arrow right' fitted />
                </AddCell>
              </CellManagementBar>
          </FlexCol>
        </>
      )}
    </Cell>
  )
}

export default GridCell
