import styled, { css } from 'styled-components'

const lighterDarkness = css`
  background-color: rgba(0,0,0,0.5);
`
const defaultDarkness = css`
  background-color: rgba(0,0,0,0.75);
`
const darkerDarkness = css`
  background-color: rgba(0,0,0,0.85);
`

export default styled.div<{
  lighter?: boolean
  darker?: boolean
  onClickOnly?: boolean
  noBlur?: boolean
}>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);

  ${defaultDarkness}
  ${({ lighter }) => lighter && lighterDarkness};
  ${({ darker }) => darker && darkerDarkness};
  ${({ onClickOnly }) => onClickOnly && 'background-color: transparent;'}

  ${({ noBlur }) => noBlur && `
    -webkit-backdrop-filter: blur(0);
    backdrop-filter: blur(0);
  `}
`
