import { useState } from 'react'
import axios from '../api'
import { AxiosError, AxiosResponse } from 'axios'
import useStaffRoles from './useStaffRoles'

type useRenderErrorLoggerHook = [
  { path: string, message: string },
  (arg0: {
    path: string,
    message: string,
    stack: any,
    widget: { widgetType: string, widgetId: string }
  }) => Promise<boolean>
]

const useRenderErrorLogger = (): useRenderErrorLoggerHook => {
  const [sentLog, setSentLog] = useState<{ path: string, message: string }>()
  const [isStaffRole] = useStaffRoles()
  
  const logError = async ({ path, message, stack, widget }) => {
    let success = false
    try {
      const res: AxiosResponse = await axios.post('/render-error/logger', { path, message, stack, widget })
      success = res.data.success
      if (res.data.success) setSentLog({ path, message })
    } catch (err) {
      success = false
      const error = err as AxiosError
      if (isStaffRole) console.error(error?.response?.data)
      setSentLog(undefined)
    }
    return success
  }

  return [sentLog, logError]
}

export default useRenderErrorLogger
