import React from 'react'
import { ColumnSwapper as SwapperRow, ToolInput } from '../styles'
import IconButton from './IconButton'

interface ColumnSwapperProps {
  handleColumnsSwapped: () => void
  inputDisabled?: boolean
  columns?: number
  swapped?: boolean
}

const ColumnSwapper: React.FC<ColumnSwapperProps> = ({
  handleColumnsSwapped,
  // columns,
  swapped,
  inputDisabled = false,
}) => {
  const _title = inputDisabled ? 'This widget does not have a column header. This field is for reference only.' : ''

  const col1val = swapped ? 'English' : 'Japanese'
  const col2val = swapped ? 'Japanese' : 'English'

  return (
    <SwapperRow>
      <ToolInput color=''>
        <input
          className='tk-mid-border'
          type='text'
          name={swapped ? 'english' : 'other_lang'}
          placeholder={swapped ? 'English' : 'Japanese'}
          disabled={inputDisabled}
          value={inputDisabled ? col1val : ''}
          title={_title}
        />
      </ToolInput>
      <IconButton
        icon='exchange'
        color='var(--blue-gray)'
        contrastColor='var(--off-white)'
        handleClick={() => handleColumnsSwapped()}
      />
      <ToolInput color=''>
        <input
          className='tk-mid-border'
          type='text'
          name={swapped ? 'other_lang' : 'english'}
          placeholder={swapped ? 'Japanese' : 'English'}
          disabled={inputDisabled}
          value={inputDisabled ? col2val : ''}
          title={_title}
        />
      </ToolInput>
    </SwapperRow>
  )
}

export default ColumnSwapper
