import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { useLocation } from 'react-router-dom'
import useMobile from 'src/hooks/useMobile'
import CenterNavStyles from '../styles/CenterNavStyles'
import CoursePositionBar from './center_nav/CoursePositionBar'

import NonCourseNavOptions from './center_nav/NonCourseNavOptions'

interface CenterNavProps {
  isCourseRoute: boolean
  isCourseOverview: boolean
  isLessonOverview: boolean
  isSublessonPage: boolean
  backgroundColor: string
}

const CenterNav: React.FC<CenterNavProps> = ({
  isCourseRoute,
  isCourseOverview,
  isLessonOverview,
  isSublessonPage,
  backgroundColor,
}) => {
  const isMobile = useMobile()
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)

  const { pathname } = useLocation()
  if (pathname === '/es' || pathname === '/claim') return <></>

  // console.log('rendering center nav')
  // console.log('is course route?', isCourseRoute)

  return (
    <CenterNavStyles className={`nav-items${isMobile && isCourseRoute ? ' apply-min-width' : ''}`}>
      {isSignedIn && <>
        {isCourseRoute ? (
          <CoursePositionBar
            isCourseOverview={isCourseOverview}
            isLessonOverview={isLessonOverview}
            isSublessonPage={isSublessonPage}
          />
        ) : (
          <NonCourseNavOptions
            backgroundColor={backgroundColor}
          />
        )}
      </>}
    </CenterNavStyles>
  )
}

export default CenterNav
