import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { USAGE } from '../toolkit.constants'
import { LanguagePair, UsageWidget } from 'src/types'

import { EmptyPadding, InputRow, PairedField, ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import {
  ListInputDivider,
  ToolFooter,
  ToolHeader,
  ToolInput,
  ToolOptions,
} from '../parts'
import { DeleteConversationLineButton } from '../styles/ConversationTool.styles'
import ToolForm from '../parts/ToolForm.part'
import { Icon } from 'semantic-ui-react'
import { PairedFieldsWithIcon, UsageExamples } from '../styles/UsageTool.styles'
import useWidgetSaved from '../hooks/useWidgetSaved'

const UsageTool: React.FC<ToolProps<UsageWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [storedExamples, setStoredExamples] = useState<LanguagePair[]>()
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<UsageWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  const commitTemporaryChanges = (examples: LanguagePair[]) => {
    hookFormMethods.setValue('examples', examples)
    setStoredExamples(examples)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    /**
     * text: string
     * meaning: string
     * usage_type: string
     * tag: string
     * examples: { other_lang: string, english: string }[] // unused fields
     */
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setStoredExamples(widget?.examples)
      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const addExample = (idx: number) => {
    const _examples = hookFormMethods.getValues()['examples']
    _examples.splice(idx, 0, { other_lang: '', english: '' })
    commitTemporaryChanges(_examples)
  }

  const removeExample = (idx: number) => {
    const _examples = hookFormMethods.getValues()['examples']
    _examples.splice(idx, 1)
    commitTemporaryChanges(_examples)
  }

  const fieldLabels = {
    romajiOnly: 'Romaji Mode Only',
    plays_sound: 'Play Sound',
    usage_type: 'Adjective, Verb, etc.',
    tag: 'Vertical label',
  }
  const pairedInputs = {
    text: 'Text to demonstrate',
    meaning: 'Meaning of text',
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={USAGE}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={USAGE}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              {['romajiOnly', 'plays_sound'].map((inp, idx) => (
                <ToolInput
                  key={idx}
                  value={inp}
                  inputType='checkbox'
                  inputLabel={fieldLabels[inp]}
                  labelColor='var(--blue-gray)'
                  labelPosition='after'
                />
              ))}
            </div>
          </ToolOptions>
          <ToolBody>
            <PairedField>
              {Object.keys(pairedInputs).map((input: string, idx: number) => (
                <div key={idx}>
                  <span>{input}</span>
                  <InputRow>
                    <ToolInput value={input} placeholder={pairedInputs[input]} />
                  </InputRow>
                </div>
              ))}
            </PairedField>
            {Object.keys(fieldLabels).map((input: string, idx: number) => (
              <InputRow key={idx}>
                <ToolInput value={input} placeholder={fieldLabels[input]} />
              </InputRow>
            ))}
            <EmptyPadding />
            <UsageExamples>
              {storedExamples?.map((_, idx) => (
                <PairedFieldsWithIcon key={idx}>
                  <PairedField>
                    <div>
                      <ToolInput
                        value={`examples.${idx}.other_lang`}
                        placeholder='Other language'
                      />
                      <EmptyPadding />
                      <ToolInput value={`examples.${idx}.english`} placeholder='English' />
                      <ListInputDivider handleClicked={() => addExample(idx + 1)} />
                    </div>
                  </PairedField>
                  <DeleteConversationLineButton
                    type='button'
                    title='Delete this conversation segment'
                    onClick={() => removeExample(idx)}
                  >
                    <Icon name='delete' size='large' />
                  </DeleteConversationLineButton>
                </PairedFieldsWithIcon>
              ))}
              {!storedExamples?.length && (
                <ListInputDivider handleClicked={() => addExample(0)} />
              )}
            </UsageExamples>
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          >
            <div>
              <ToolInput
                value={'plays_sound'}
                inputType='checkbox'
                placeholder='Play Sound'
                inputLabel='Play Sound'
                labelColor='white'
                labelPosition='after'
              />
            </div>
          </ToolFooter>
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default UsageTool
