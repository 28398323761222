import React, {
  useEffect,
  useMemo
} from 'react'

import { ToolHeaderProps } from '../interfaces/CreationToolkit.interface'

import { Icon } from 'semantic-ui-react'
import useToolkitStore from '../../../../../stores/toolkit.store'

import {
  DIALOGUE,
  EXAMPLES,
  READINGCOMPREHENSION,
  SENTENCES,
} from '../toolkit.constants'
import { HeaderSubheader, HeaderToolType, ToolHeader as ToolHeaderRow } from '../styles'
import { SubTypeSelect } from '../styles/ListTool.styles'

import useAPI from 'src/hooks/useAPI'

/**
 * ToolHeader
 * Handles List subtype select, log state, floating widget preview, options visibility
 */
const ToolHeader: React.FC<ToolHeaderProps> = ({
  widget_id,
  widget_type,
  widget_subtype,
  onSubtypeChange,
  onNonbreakingChange,
  hookForm,
  isListTool,
  isGridCell = false,
  nonbreaking = false
}) => {
  const setPreviewingWidgetIds = useToolkitStore((state) => state.setPreviewingWidgetIds)
  const setOptionsVisible = useToolkitStore((state) => state.setOptionsVisible)
  const [resShow, loadingShow, reqShow] = useAPI(`/widgets/${widget_id}/no_report_icon`)
  const [resToggle, loadingToggle, reqToggle] = useAPI(`/widgets/${widget_id}/no_report_icon`, 'POST')

  const widgetTypeText = useMemo(() => {
    if (widget_type === 'MiniQuizWidget') return 'Activity'
    return widget_type?.replace('Widget', '')
  }, [widget_type, widget_subtype])

  const handleSubTypeChange = (newSubtype) => {
    if (!onSubtypeChange) return
    onSubtypeChange(newSubtype)
  }

  const handleNonbreakingChange = (newNonbreaking) => {
    onNonbreakingChange(newNonbreaking)
  }

  useEffect(() => {
    // console.log(resToggle)
    if (resToggle) reqShow()
  }, [resToggle])

  const debugState = () => {
    const _state = useToolkitStore.getState()

    /* eslint-disable no-console */
    console.warn('Toolkit state')
    console.log(JSON.stringify(_state, null, 2))
    if (hookForm) {
      console.warn('Tool form state')
      console.log(JSON.stringify(hookForm, null, 2))
    }
    /* eslint-enable */
  }

  const toggleAaTBugReport = () => {
    if (!loadingShow && !loadingToggle) reqToggle()
  }

  return (
    <ToolHeaderRow>
      <HeaderToolType>
        {!isGridCell ? 'Type: ' : 'Cell: '}
        {widgetTypeText?.slice(0, 11)}
        {widgetTypeText?.length > 11 && '...'}
      </HeaderToolType>
      <HeaderSubheader>
        {(isListTool) ? (
          <SubTypeSelect>
            <p className='tw-text-white tw-font-bold'>List type:</p>
            <select
              name='subtype'
              id='subtype'
              value={widget_subtype}
              onChange={(e) => handleSubTypeChange(e.target.value)}
            >
              <option disabled>Choose List Type</option>
              <option value={EXAMPLES}>Examples</option>
              <option value={DIALOGUE}>Dialogue</option>
              <option value={READINGCOMPREHENSION}>Reading Comp.</option>
              <option value={SENTENCES}>Sentences</option>
            </select>
          </SubTypeSelect>
        ) : (<></>)}
        {/* dropdown if MiniQuizWidget to choose between lesson quiz or mini quiz subtype */}
        {(widget_type === 'MiniQuizWidget') ? (
          <SubTypeSelect>
            <p className='tw-text-white tw-font-bold'>Activity type:</p>
            <select
              name='subtype'
              id='subtype'
              value={widget_subtype}
              onChange={(e) => handleSubTypeChange(e.target.value)}
            >
              <option disabled>Choose Quiz Type</option>
              <option value='lesson-quiz'>Lesson Quiz</option>
              <option value='mini-quiz'>Mini Quiz</option>
              {/* <option value='kana-typer'>Kana Typer</option> */}
              <option value='conjugation'>Conjugation</option>
            </select>
          </SubTypeSelect>
        ) : (<div></div>)}
        <div>
          <button
            onClick={toggleAaTBugReport}
            title="Toggle AaT/Bug Report Icon"
          >
            <Icon fitted name='comment' style={{ color: resShow?.no_report_icon ? 'grey' : 'black' }} />
          </button>
          <button
            onClick={debugState}
            title="Log debug info to console"
          >
            <Icon fitted name='bug' />
          </button>
          {!isGridCell && (
            <button
              type='button'
              title='View Render Preview'
              onClick={() => setPreviewingWidgetIds(widget_id)}
            >
              <Icon fitted name='lightning' />
            </button>
          )}
          <button
            type='button'
            title='Open Subheader'
            onClick={() => setOptionsVisible(widget_id)}
          >
            <Icon fitted name='wrench' />
          </button>
        </div>
      </HeaderSubheader>
    </ToolHeaderRow>
  )
}

export default ToolHeader
