import { useState } from 'react'
import axios from '../api'
import { AxiosError, AxiosResponse } from 'axios'

type CFHook = [
  string,
  (arg0: string) => Promise<string>,
  string,
  (arg0: string) => void,
]

/**
  * @description A hook setup to return CF URLS.
  * @returns {CFHook} The return of this hook has 4 parts in an array:
  * - `cfPath` the most recently returned private bucket cfPath.
  * - `getCfPath` fetches a URL for a private bucket cfPath.
  * - `publicCfPath` the most recently returned public bucket cfPath.
  * - `getPublicCfPath` prepends the url for a public bucket resource.
*
*/
const useCF = (): CFHook => {
  const [cfPath, setCfPath] = useState<string>()
  const [publicCfPath, setPublicCfPath] = useState<string>()

  const getCfPath = async (s3Path: string) => {
    if (!s3Path) return

    let path: string
    try {
      const res: AxiosResponse = await axios.post('/user/signed', { url: s3Path })
      if (res.data.success) {
        path = res.data.url
        setCfPath(path)
      }
    } catch (err) {
      const error = err as AxiosError
      console.log(error)
    }

    return path
  }

  const getPublicCfPath = (resource: string) => {
    if (!resource) return
    setPublicCfPath(`https://d32tv5vz8ua1sf.cloudfront.net/${resource}`)
  }

  return [cfPath, getCfPath, publicCfPath, getPublicCfPath]
}

export default useCF
