import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import {
  EARLY_SUPPORTER,
  KANSAI_COURSE,
  SUBSCRIPTION_PLANS,
  VAULT_ACCESS,
} from 'src/util/constants'

interface SubsObject {
  identifier: string
}

type SubscribedHook = {
  isAdmin: boolean,
  isVaultAccess: boolean,
  isKansaiStudent: boolean,
  isEarlySupporter: boolean,
  isPremiumSubscribed: boolean,
  checkAccess: () => void,
}

/* eslint-disable max-len */
/**
  * @description A hook that returns a user's subscription statuses
  * @returns {SubscribedHook} The return of this hook is an object of each level of access a user has:
  * - `isAdmin` {boolean} - whether or not the user has `admin` in their roles array
  * - `isVaultAccess` {boolean}  - whether or not the user has `vault` in their access array
  * - `isKansaiStudent` {boolean}  - whether or not the user has purchased the kansai course
  * - `isEarlySupporter` {boolean}  - whether or not the user has an `early-supporter` subscription
  * - `isPremiumSubscribed` {boolean}  - whether or not the user has an annual or monthly premium subscription
  * - `checkAccess` {function(): void} - a function that reruns checks on the above
  * - NOTE - none of this makes calls to the API; it is all dependent on Redux state
*
*/
/* eslint-enable */
const useSubscribed = (): SubscribedHook => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [isVaultAccess, setIsVaultAccess] = useState<boolean>(false)
  const [isKansaiStudent, setIsKansaiStudent] = useState<boolean>(false)
  const [isEarlySupporter, setIsEarlySupporter] = useState<boolean>(false)
  const [isPremiumSubscribed, setIsPremiumSubscribed] = useState<boolean>(false)

  /* eslint-disable no-multi-spaces */
  const roles: string[]     = useSelector((rootState: RootState) => rootState.auth?.roles)
  const access: string[]    = useSelector((rootState: RootState) => rootState.auth?.access)
  const premium: string[]   = useSelector((rootState: RootState) => rootState.user?.courses)
  const subs: SubsObject[]  = useSelector((rootState: RootState) => rootState.auth?.subs)
  const supporter: string[] = useSelector((rootState: RootState) => rootState.auth?.subscriptions)

  const checkAccess = () => {
    const hasAdmin          = roles?.includes('admin')
    const hasVaultAccess    = access?.includes(VAULT_ACCESS)
    const hasKansai         = premium?.includes(KANSAI_COURSE)
    const earlySupporter    = supporter?.includes(EARLY_SUPPORTER)
    const hasSubscription   = subs?.reduce((previous, current) => {
      return SUBSCRIPTION_PLANS.includes(current?.identifier) ? true : previous
    }, false)

    setIsAdmin(hasAdmin)
    setIsKansaiStudent(hasKansai)
    setIsVaultAccess(hasVaultAccess)
    setIsEarlySupporter(earlySupporter)
    setIsPremiumSubscribed(hasSubscription)

    // console.log(hasAdmin)
    // console.log(hasVaultAccess)
    // console.log(hasKansai)
    // console.log(earlySupporter)
    // console.log(hasSubscription)
  }

  useEffect(() => {
    checkAccess()
  }, [subs, roles, access, premium, supporter])
  /* eslint-enable */

  return ({
    isAdmin,
    isVaultAccess,
    isKansaiStudent,
    isEarlySupporter,
    isPremiumSubscribed,
    checkAccess,
  })
}

export default useSubscribed
