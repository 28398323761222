import { useEffect, useState } from 'react'

import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Loader } from '@mantine/core'

import useSubscribed from 'src/hooks/useSubscribed'
import { loadSubs } from 'src/actions'
import useAPI from 'src/hooks/useAPI'

const valid = [
  'premium-monthly', 'premium-annual', 'premium-monthly-2022', 'premium-annual-2022',
  'internal-aff-monthly', 'internal-aff-monthly', 'email-campaign-2022',
  'email-campaign-2022-annual', 'premium-aff-monthly', 'yj-premium-monthly', 'yj-premium-annual',
  'premium-annual-half', 'gift-plan', 'premium-monthly-50', 'premium-annual-50',
  'premium-aff-monthly-3', 'premium-aff-annual-40'
]

const ultraPremiumCore = ({ component: Component, ...rest }) => {
  const [loading, setLoading] = useState<boolean>()
  const premium = rest.courses?.includes('kansai')
  const admin = rest.roles?.includes('admin')
  const [resActive, loadingSubs, reqActive] = useAPI('/subscriptions/current')
  const alreadySubs = rest.subs

  const { isPremiumSubscribed } = useSubscribed()
  const isSubbed = valid.includes(alreadySubs?.[0]?.identifier)

  useEffect(() => {
    // console.log(resActive)
    if (resActive?.subs.length > 0) {
      rest.loadSubs(resActive?.subs.map((sub) => ({ identifier: sub.plan?.identifier })))
    }
  }, [resActive])

  if (loadingSubs || loading || (!isPremiumSubscribed && loadingSubs)) return <Loader />

  return (
    <Route
      {...rest}
      render={(props) => (
        (premium || admin || isPremiumSubscribed || isSubbed) ? (rest.render?.(props) || <Component {...props} />)
          : <Redirect to='/memberships/premium/subscribe' />
      )}
    />
  )
}

const mapStateToPropsUltraPremium = (state) => ({
  courses: state.user.courses, roles: state.auth.roles, subs: state.auth.subs
})

const UltraPremiumRoute = connect(mapStateToPropsUltraPremium, { loadSubs })(ultraPremiumCore)

export default UltraPremiumRoute
