import React, { useEffect, useState } from 'react'
import { Button, Form, Label } from 'semantic-ui-react'
import axios from '../../../../../api'

import styled from 'styled-components'

const FormStyles = styled.div`
    padding: 15px;
`

/*
MODEL
    title: {
        type: String,
        default: 'Sentence Building',
    },
    target_sentence: String,
    sentence_parts: [String],
*/

const SentenceBuilderWidgetEditor = ({ id }) => {
    const [error, setError] = useState(false)
    const [editSuccess, setEditSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [fields, setFields] = useState({
        title: '',
        target_sentence: '',
        sentence_parts: '',
    })

    useEffect(() => {
        fetchInitialData()
    }, [])

    const fetchInitialData = () => {
        axios.get(`/widgets/${id}`)
        .then((res) => {
            if (res.data.success) {
                const widget = res.data.widget
                setFields({
                    title: widget.title,
                    target_sentence: widget.target_sentence,
                    sentence_parts: widget.sentence_parts.join(', ')
                })
            }
        })
        .catch(err => console.error(err))
    }

    function commitData(e) {
        e.preventDefault()
        const csvWords = fields.sentence_parts.split(',')

        const words = []
        for (let i = 0 ; i < csvWords.length ; i++) {
            if (csvWords[i] == undefined || !csvWords[i].length) continue;
            words.push(csvWords[i].trim())
        }

        const payload = {
            ...fields,
            sentence_parts: words
        }

        if (loading) return;
        setLoading(true)
        axios.post(`/widgets/${id}/edit`, payload)
        .then(res => {
            if (res.data?.success) {
                setLoading(false)
                setEditSuccess(true)
                return setTimeout(() => setEditSuccess(false), 5000)
            }
            setError(true)
            setLoading(false)
        })
        .catch(err => {
            console.error(err)
            setError(true)
            setLoading(false)
        })
    }

    function handleChange(e) {
        setFields({
            ...fields,
            [e.target.name]: e.target.value
        })
    }

    return (
        <FormStyles>
            {error && <h2>Something went wrong; try again</h2>}
            {editSuccess && <h2>Successfully edited!</h2>}
            <Form onSubmit={e => commitData(e)}>
                <Form.Field>
                <Label>Exercise Title</Label>
                <input
                    placeholder='Will be "Sentence Building" if left blank'
                    name="title"
                    value={fields.title}
                    onChange={(e) => handleChange(e)}
                />
                </Form.Field>

                <Form.Field>
                <Label>Target Sentence</Label>
                <input
                    placeholder='What sentence should they write?'
                    name="target_sentence"
                    value={fields.target_sentence}
                    onChange={(e) => handleChange(e)}
                />
                </Form.Field>

                <Form.Field>
                <Label>Word Bank</Label>
                <input
                    placeholder='Enter words separated by comma'
                    name="sentence_parts"
                    value={fields.sentence_parts}
                    onChange={(e) => handleChange(e)}
                />
                </Form.Field>
                <Button color="blue" type='submit'>Submit</Button>
            </Form>
        </FormStyles>
    )
}

export default SentenceBuilderWidgetEditor