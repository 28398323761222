import React, { useEffect, useState } from 'react'
import axios from '../../../../api'

import { Button, Checkbox, Grid, Header, Icon, Message, Segment, Form } from 'semantic-ui-react'

import MicRecorder from 'mic-recorder-to-mp3'

const SoundRecorder = (props) => {
  const [buffer, setBuffer] = useState(null)
  const [blob, setBlob] = useState(null)
  const [file, setFile] = useState(null)
  const [player, setPlayer] = useState(null)
  const [recorder, setRecorder] = useState(new MicRecorder({ bitRate: 128 }))
  const [gender, setGender] = useState('m')
  const [language, setLanguage] = useState('jp')
  const [minimized, setMinimized] = useState(false)
  const [saved, setSaved] = useState(false)
  const [record, setRecord] = useState(props.record)
  const [speaker, setSpeaker] = useState('Kanako')
  const [soundExists, setSoundExists] = useState(false)
  const [error, setError] = useState(false)
  const [isOverride, setIsOverride] = useState(false)

  const buffVolume = () => {
    axios.put('/sounds/volume_bump', { speaker, record })
      .then(console.log)
  }

  const restoreBackup = () => {
    axios.put('/sounds/restore_backup', { speaker, record })
      .then(console.log)
  }

  const speakers = [
    { text: 'Kanako', value: 'Kanako', key: 'Kanako' },
    { text: 'Yukari', value: 'Yukari', key: 'Yukari' },
    { text: 'Mr. Fukumachi', value: 'Mr. Fukumachi', key: 'Mr. Fukumachi' },
  ]

  const languages = [
    { key: 'jp', value: 'jp', text: 'Japanese' },
    { key: 'kr', value: 'kr', text: 'Korean' },
    { key: 'ch', value: 'ch', text: 'Chinese' },
  ]

  const startRecording = () => {
    recorder.start().then(() => {
      // ...
    })
  }

  useEffect(() => {
    if (props.record?.sounds[speaker]) {
      setSoundExists(true)
    } else {
      setSoundExists(false)
    }
  }, [speaker])

  const stopRecording = () => {
    recorder
      .stop()
      .getMp3().then(([buff, blb]) => {
        setBlob(blb)
        setBuffer(buff)

        const f = new File(buff, 'new-sound.mp3', {
          type: blb.type,
          lastModified: Date.now()
        })

        setFile(f)
        setPlayer(new Audio(URL.createObjectURL(f)))
      })
  }

  const playSound = () => {
    player.play()
  }

  const saveSound = () => {
    const data = new FormData()
    data.append('sound', file, file.name)
    // data.append('englishText', englishText)
    // data.append('extraData', extraData)
    data.append('language', language)
    // data.append('languageText', languageText)
    data.append('record', record)
    data.append('speaker', speaker)
    data.append('base_text', record.base_text)
    data.append('romaji', record.romaji)
    data.append('english', record.english)
    data.append('widget', props.widget)
    data.append('override', isOverride)

    console.log('Trying to save sound...')
    console.log('Sound is override:', isOverride)

    axios.post('/sounds/new', data, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then((res) => {
        console.log(res)
        if (res.data.success) {
          setSaved(true)
          if (props.onSave) props.onSave()
        } else {
          setError(true)
        }
      })
  }

  const displayRecorder = () => !minimized && (
    <div>
      <Message warning={soundExists} success={!soundExists}>
        {soundExists
          ? 'Sound already exists for this speaker!'
          : 'No sound yet exists for this speaker!'}
      </Message>
      <Form>
        {saved && <p>Sound saved!</p>}
        {error && <p>There was an error saving this sound!</p>}
        <h4 style={{ color: 'black' }}>Recording Options</h4>
        <Button color='blue' onClick={startRecording} icon='microphone' />
        <Button color='red' onClick={stopRecording} icon='stop' />
        {buffer ? (
          <Button color='purple' onClick={playSound}>
            <Icon name='play' />
          </Button>
        ) : (
          <Button color='purple' disabled icon='play' />
        )}
        <Button floated='right' color='green' disabled={!blob} onClick={saveSound} icon='save' />
        <br />
        <Form>
          <Form.Select
            options={speakers}
            label='Speakers'
            onChange={(e, { value }) => setSpeaker(value)}
            value={speaker}
          />
        </Form>
        <Button color='blue' icon='arrow up' onClick={buffVolume} />
        <Button color='blue' icon='refresh' onClick={restoreBackup} />
        <Checkbox label="Override" onChange={(e, { checked }) => setIsOverride(checked)} />
      </Form>
    </div>
  )

  return (
    <Segment raised>
      <Grid centered style={{ marginBottom: '10px' }}>
        <Grid.Row>
          <Grid.Column width={8}>
            <Header as='h2' textAlign='center'>
              Sound Recorder
            </Header>
            <p>{props.record?.kanji}</p>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button circular onClick={() => setMinimized(!minimized)} icon={minimized ? 'plus' : 'minus'} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {displayRecorder()}
    </Segment>
  )
}

export default SoundRecorder
