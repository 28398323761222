import tomoko_confirmation from 'src/assets/chibi/tomoko/chibi_confirmation.png'
import tomoko_culture_clip from 'src/assets/chibi/tomoko/chibi_culture_clip.png'
import tomoko_pronunciation from 'src/assets/chibi/tomoko/chibi_pronunciation.png'
import tomoko_welcome from 'src/assets/chibi/tomoko/chibi_welcome.png'

import kenji_common_questions from 'src/assets/chibi/kenji/chibi_common_questions.png'
import kenji_writing_hiragana from 'src/assets/chibi/kenji/chibi_writing_hiragana.png'
import kenji_writing_kanji from 'src/assets/chibi/kenji/chibi_writing_kanji.png'
import kenji_writing_katakana from 'src/assets/chibi/kenji/chibi_writing_katakana.png'

import mari_writing_points from 'src/assets/chibi/mari/chibi_writing_points.png'

import ren_grammar from 'src/assets/chibi/ren/chibi_grammar.png'

import rika_comprehension from 'src/assets/chibi/rika/chibi_comprehension.png'
import rika_vocab from 'src/assets/chibi/rika/chibi_vocab.png'

import natsumi_quiz from 'src/assets/chibi/natsumi/chibi_quiz.png'
import natsumi_words from 'src/assets/chibi/natsumi/chibi_words.png'

type chibiMap = {
  [key: string]: string
}

type fullChibiMap = {
  confirmation: string
  culture_clip: string
  pronunciation: string
  welcome: string
  common_questions: string
  writing_hiragana: string
  writing_kanji: string
  writing_katakana: string
  writing_points: string
  grammar: string
  comprehension: string
  vocab: string
  quiz: string
  words: string
}

const tomoko: chibiMap = {
  confirmation: tomoko_confirmation,
  culture_clip: tomoko_culture_clip,
  pronunciation: tomoko_pronunciation,
  welcome: tomoko_welcome,
}

const kenji: chibiMap = {
  common_questions: kenji_common_questions,
  writing_hiragana: kenji_writing_hiragana,
  writing_kanji: kenji_writing_kanji,
  writing_katakana: kenji_writing_katakana,
}

const mari: chibiMap = {
  writing_points: mari_writing_points,
}

const ren: chibiMap = {
  grammar: ren_grammar,
}

const rika: chibiMap = {
  comprehension: rika_comprehension,
  vocab: rika_vocab,
}

const natsumi: chibiMap = {
  quiz: natsumi_quiz,
  words: natsumi_words,
}

const chibiBucket: fullChibiMap = {
  confirmation: tomoko_confirmation,
  culture_clip: tomoko_culture_clip,
  pronunciation: tomoko_pronunciation,
  welcome: tomoko_welcome,
  common_questions: kenji_common_questions,
  writing_hiragana: kenji_writing_hiragana,
  writing_kanji: kenji_writing_kanji,
  writing_katakana: kenji_writing_katakana,
  writing_points: mari_writing_points,
  grammar: ren_grammar,
  comprehension: rika_comprehension,
  vocab: rika_vocab,
  quiz: natsumi_quiz,
  words: natsumi_words,
}

export {
  chibiBucket,
  tomoko,
  kenji,
  mari,
  ren,
  rika,
  natsumi,
}
