import React from 'react'
import { Icon, SemanticICONS } from 'semantic-ui-react'
import { IconButton as Button } from '../styles'

interface IconButtonProps {
  backgroundColor?: string
  color?: string
  contrastColor?: string
  handleClick: () => void
  icon: SemanticICONS
  title?: string
  noHoverEffect?: boolean
  type?: 'button' | 'submit' | 'reset'
  style?: any
  active?: boolean
}

const IconButton: React.FC<IconButtonProps> = ({
  backgroundColor = 'transparent',
  color = 'var(--blue-gray)',
  contrastColor = 'transparent',
  handleClick,
  icon,
  noHoverEffect = false,
  style,
  title,
  type = 'button',
  active = false,
}) => {
  return (
    <Button
      backgroundColor={backgroundColor}
      color={color}
      contrastColor={contrastColor}
      onClick={() => handleClick()}
      noHoverEffect={noHoverEffect}
      style={style}
      type={type}
      title={title}
      active={active}
    >
      <Icon
        name={icon}
        fitted
        size='large'
      />
    </Button>
  )
}

export default IconButton
