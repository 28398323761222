import styled from 'styled-components'

const H1 = styled.div`
  position: relative;
  margin: auto;
  font-size: 30px;
  text-align: center;
  padding-top: 3rem;
  font-family: Jost;
  width: 40%;
  max-width: 30rem;
  
`

export default H1
