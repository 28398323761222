// import words from "../assets/bad_words"

// export default function(string, deepCheck = false) {
//   const strings = string.split(" ")
//   let result = 0
//   strings.forEach(str => exactMatch(str) > 0 ? result++ : null)

//   if (deepCheck) {
//     words.forEach(word => wordInString(string, word) > 0 ? result++ : null)
//   }
//   return result
// }

// function exactMatch(string) {
//   for (const word of words) {
//     if (word === string) return 1
//   }
//   return -1
// } // too big big O = o(n) on 1300+ words in json, for each individual string
//   // eventual goal is to refine into a binary search to find an exact matching word
//     // probably also refine badwords list some are redundant or irrelevant

//   // word in string for short entries, like usernames and category names

// function binarySearch(arr, value) {
//   let start = 0
//   let end = arr.length - 1
//   let middle = Math.floor((start + end) / 2)

//   while (arr[middle] !== value && start <= end) {
//     if (value < arr[middle]) end = middle - 1
//     else start = middle + 1
//     middle = Math.floor((start + end) / 2)
//   }
//   return start > end ? -1 : middle
// }

// function wordInString(long, short) { // long = phrase, short = word to find in phrase
//   let count = 0
//   for (let i = 0 i < long.length i++) {
//     for (let j = 0 j < short.length j++) {
//       if (short[j] !== long[i + j]) break
//       if (j === short.length - 1) count++
//     }
//   }
//   return count
// }

import {
  engBadWords,
  jpnBadWords,
  korBadWords,
  dnoobWords,
} from '../assets/dirtyNaughtyObsceneAndOtherwiseBadWords'
import homoglyphSearch from 'homoglyph-search'

// eslint-disable-next-line
String.prototype.replaceAt = function (index, replacement) {
  return String.substr(0, index) + replacement + String.substr(index + replacement.length)
}

export const containsProfanity = (sentence, language = 'english', useHomoglyphSearch = true) => {
  if (sentence === '' || typeof sentence !== 'string') {
    console.info('containsProfanity(): first argument must be a non-empty string')
    return null
  }
  if (typeof useHomoglyphSearch !== 'boolean') {
    console.info('containsProfanity(): second argument must be a boolean value')
    return null
  }

  let bannedWords
  switch (language) {
    case 'english':
      bannedWords = engBadWords
      break
    case 'japanese':
      bannedWords = jpnBadWords
      break
    case 'korean':
      bannedWords = korBadWords
      break
    case 'all':
      bannedWords = dnoobWords
      break
    default:
  }

  if (useHomoglyphSearch) {
    const homoglyphResult = homoglyphSearch.search(sentence, bannedWords)
    if (homoglyphResult.length > 0) return true
    return false
  }
}

export const maskProfanity = (sentence, mask, language = 'all') => {
  if (typeof sentence !== 'string') return sentence
  let _mask = mask
  let _sentence = sentence
  if (sentence === '' || typeof sentence !== 'string') {
    console.info('maskProfanity(): first argument must be a non-empty string')
    return null
  }
  _mask = typeof mask === 'undefined' ? '*' : mask
  if (_mask.length !== 1 && typeof _mask !== 'undefined') {
    console.info('maskProfanity(): second argument must be a single char. Default - *')
    return null
  }

  let bannedWords
  switch (language) {
    case 'english':
      bannedWords = engBadWords
      break
    case 'japanese':
      bannedWords = jpnBadWords
      break
    case 'korean':
      bannedWords = korBadWords
      break
    default:
      bannedWords = dnoobWords
  }

  const homoglyphResult = homoglyphSearch.search(sentence, bannedWords)

  for (let i = 0; i < homoglyphResult.length; i++) {
    const result = homoglyphResult[i]
    const { match } = result
    let star = ''

    for (let j = 0; j < match.length; j++) {
      star += _mask
    }

    const regex = new RegExp(`\\b${match}\\b`, 'g')
    _sentence = _sentence.replace(regex, star)
  }

  return _sentence
}
