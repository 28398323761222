import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Form, Icon, Grid, Label } from 'semantic-ui-react'

import useWidget from '../../hooks/useWidget'

const DialogueWidgetEditor = props => {
    const [lines, setLines] = useState([])
    const [title, setTitle] = useState('')
    const [subtitle, setSubtitle] = useState('')
    const [bottomTitle, setBottomTitle] = useState('')
    const [disableReveal, setDisableReveal] = useState(false)
    const [playsSound, setPlaysSound] = useState(false)
    const [widget, fetchWidget, loading, editWidget] = useWidget(props.id)

    useEffect(() => {
        setLines(widget?.lines || [])
        setTitle(widget?.title || '')
        setSubtitle(widget?.subtitle || '')
        setBottomTitle(widget?.bottomTitle || '')
        setDisableReveal(widget?.disableReveal)
        setPlaysSound(widget?.plays_sound || false)
    }, [widget])

    const setLine = (idx, line) => {
        let tempLines = [...lines]
        tempLines[idx] = line
        setLines(tempLines)
    }

    const addLine = () => {
        let tempLines = [...lines]
        tempLines.push({speaker: '', text: ''})
        setLines(tempLines)
    }

    const deleteLine = idx => {
        const tempLines = [...lines]
        tempLines.splice(idx, 1)
        setLines(tempLines)
    }

    return (
        <div>
            <Form loading={loading}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={7}>
                            <Form.Input
                                label="Dialogue Title"
                                value={title}
                                onChange={(_, { value }) => setTitle(value)}
                            />
                            <Form.Input
                                label="Dialogue Subtitle"
                                value={subtitle}
                                onChange={(_, { value }) => setSubtitle(value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {lines.map((l, idx) =>
                        <Grid.Row
                            style={{
                                alignItems: "flex-end",
                                padding: "10px"
                            }}
                        >
                            <Grid.Column width={2}>
                                <Form.Input
                                    label={`Speaker ${idx+1}`}
                                    value={l.speaker}
                                    onChange={(_, { value }) => setLine(idx, { speaker: value, text: l.text, other_lang: l.other_lang })}
                                />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Input
                                    label={`Line ${idx+1}`}
                                    value={l.text}
                                    onChange={(_, { value }) => setLine(idx, { speaker: l.speaker, text: value, other_lang: l.other_lang })}
                                />
                            </Grid.Column>
                            <Grid.Column width={6}>
                                <Form.Input
                                    label={`Translation ${idx+1}`}
                                    value={l.other_lang}
                                    onChange={(_, { value }) => setLine(idx, { speaker: l.speaker, text: l.text, other_lang: value })}
                                />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Button color='red' icon='trash' title="Delete line" onClick={() => deleteLine(idx)} />
                            </Grid.Column>
                        </Grid.Row>)}
                        <Form.Input
                            label="Bottom Title"
                            value={bottomTitle}
                            onChange={(_, { value }) => setBottomTitle(value)}
                        />
                </Grid>
            </Form>
            <Grid style={{ backgroundColor: "white", border: "1px solid var(--blue-gray)", borderRadius: "5px", margin: "20px 10px" }}>
                <Grid.Row>
                    <Grid.Column width={2}>
                        <Button
                            icon='plus'
                            color='blue'
                            title="Add line"
                            onClick={addLine}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Button
                            color='blue'
                            title={disableReveal ? "Translation reveal button disabled" : "Disable translation reveal button?"}
                            icon={disableReveal ? 'closed captioning outline' : 'closed captioning'}
                            onClick={() => setDisableReveal(!disableReveal)}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button
                            icon='save'
                            color='green'
                            title='Save changes'
                            onClick={() => editWidget({ lines, title: title.trim(), subtitle: subtitle.trim(), bottomTitle: bottomTitle.trim(), plays_sound: playsSound, disableReveal })}
                        />
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Checkbox
                            checked={playsSound}
                            onChange={(_, { checked }) => setPlaysSound(checked)}
                        />
                        <Label>Play Sound?</Label>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    )
}

export default DialogueWidgetEditor