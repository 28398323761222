import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { CourseInfo } from 'src/types'

import CoursePosBarStyles from '../../styles/CoursePosBarStyles'
import { Button, Header, Icon } from 'semantic-ui-react'
import BreadLeft from '../../styles/BreadLeft'
import BreadRight from '../../styles/BreadRight'

import useMobile from 'src/hooks/useMobile'
import useShrinkTitle from 'src/components/fz_courses/hooks/useShrinkTitle'
import useSubscribed from 'src/hooks/useSubscribed'

import getCourseInfo, { getTitle } from 'src/util/courseInfo'
import { useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'

interface CoursePositionBarProps {
  isCourseOverview: boolean
  isLessonOverview: boolean
  isSublessonPage: boolean
}

const CoursePositionBar: React.FC<CoursePositionBarProps> = ({
  // isCourseOverview,
  // isLessonOverview,
  isSublessonPage,
}) => {
  const [courseTitleLong, setCourseTitleLong] = useState<string>()
  const [courseInfo, setCourseInfo] = useState<CourseInfo>()
  const [lessonNumber, setLessonNumber] = useState<string>()
  const [courseLink, setCourseLink] = useState<string>()
  const [lessonLink, setLessonLink] = useState<string>()
  const [sublessonLink, setSublessonLink] = useState<string>()

  const nSlug = useSelector((state: RootState) => state.navigator.nSlug)
  const nDisabled = useSelector((state: RootState) => state.navigator.nDisabled)
  const pSlug = useSelector((state: RootState) => state.navigator.pSlug)
  const pDisabled = useSelector((state: RootState) => state.navigator.pDisabled)
  const linkToPaywall = useSelector((state: RootState) => state.navigator.linkToPaywall)
  const subLesson = useSelector((state: RootState) => state.navigator.subLesson)
  const numLessons = useSelector((state: RootState) => state.navigator.numLessons)
  const numPrelessons = useSelector((state: RootState) => state.navigator.numPrelessons)

  const [shrinkTitle] = useShrinkTitle()
  const isMobile = useMobile()
  const location = useLocation()
  const history = useHistory()
  const { isPremiumSubscribed, isAdmin } = useSubscribed()

  const courses = [
    'jfz1', 'jfz2', 'jfz3', 'jfz4', 'jfz5',
    'kfz1', 'kfz2', 'kfz3',
    'test', 'jin5', 'hitokoto', 'daily', 'ask', 'jtm',
    'japanese1', 'japanese2', 'japanese3', 'japanese4', 'japanese5',
    'kanji1', 'kanji2', 'kanji3', 'kanji4', 'kanji5',
    'chinese1', 'chinese2', 'chinese3', 'chinese4', 'chinese5',
    'korean1', 'korean2', 'korean3', 'korean4', 'korean5',
    'spanish1', 'spanish2', 'spanish3', 'spanish4', 'spanish5',
    'japones1', 'japones2', 'japones3', 'japones4', 'japones5',
  ]

  const destructurePathname = (path: string) => {
    const split = path.split('/').filter((p) => p.length && p !== 'courses')
    
    let course: string, lesson: string, sublesson: string

    if (courses.includes(split[0])) {
      // eslint-disable-next-line
      course = split[0]
      lesson = split?.[1]
      sublesson = split?.[2]

      const _courseInfo: CourseInfo = getCourseInfo(getTitle(course))
      setCourseInfo(_courseInfo)
      setCourseTitleLong(getTitle(course))
      setLessonNumber(lesson)
      setCourseLink(`/courses/${course}`)
      setLessonLink(`/courses/${course}/${lesson}`)
      setSublessonLink(`${course}/${lesson}/${sublesson}`)
    }
  }

  const handleGoPrevious = useCallback(() => {
    if (pSlug) history.push(`/courses/${pSlug}`)
  }, [pSlug])

  const handleGoNext = () => {
    if (linkToPaywall && !isPremiumSubscribed && !isAdmin) history.push(`/courses/${sublessonLink}/upgrade`)
    else if (nSlug) history.push(`/courses/${nSlug}`)
  }

  useEffect(() => {
    destructurePathname(location.pathname)
  }, [location])

  return (
    <CoursePosBarStyles>
      <div className='tw-w-full tw-box-border tw-flex tw-flex-row tw-justify-around tw-items-center'>
        <Button
          circular inverted
          disabled={pDisabled}
          onClick={() => handleGoPrevious()}
          title={pSlug}
          icon='chevron left'
        />
        {courseTitleLong
          && <div
              className={
                `bread-crumbs tw-w-full tw-flex tw-flex-row tw-justify-center tw-items-center${isMobile ? ' tw-pt-2.5' : ''}`
              }
            >
            <Link to={courseLink}>
              <BreadLeft courseInfo={courseInfo}>
                <Header.Subheader>
                  {courseTitleLong?.replace('From Zero', 'FZ').replace(' (v8)', '').replace('Japanese Topics Mania', 'JTM') || 'From Zero'}
                </Header.Subheader>
              </BreadLeft>
            </Link>
            {lessonNumber
              && <Link to={lessonLink}>
                <BreadRight courseInfo={courseInfo}>
                  <Header.Subheader>L-{lessonNumber}</Header.Subheader>
                </BreadRight>
              </Link>
            }
            {isSublessonPage
              && <>
                {!isMobile && (
                  <div className='chevron tw-inline-block'>
                    <Icon name='chevron right' />
                  </div>
                )}
                {subLesson?.title
                  && <p className='sublesson-title tw-inline-block tw-pt-1'>
                    {shrinkTitle(subLesson.title)}
                  </p>
                }
              </>
            }
          </div>
        }
        <Button
          circular inverted
          disabled={nDisabled}
          onClick={() => handleGoNext()}
          title={(linkToPaywall && !isPremiumSubscribed) ? `${sublessonLink}/upgrade` : nSlug}
          icon='chevron right'
        />
      </div>
    </CoursePosBarStyles>
  )
}

export default CoursePositionBar
