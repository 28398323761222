import { SET_POPUP, SIGN_OUT } from '../actions/types'
import { Record } from '../types'

interface POPUP_STATE {
  note?: {
    text: string,
    widget_id: string,
    rec_id: string,
    date_created: Date | string
  },
  // eslint-disable-next-line
  onSave?: (arg0: any) => Promise<void>,
  path?: string,
  record?: Record
  widget?: string,
  activePopup?: string,
  english?: string,
  widgetType?: string,
  bonusData?: any
}

const INITIAL_STATE: POPUP_STATE = {
  note: undefined,
  onSave: undefined,
  path: undefined,
  record: undefined,
  widget: undefined,
  activePopup: undefined,
  english: undefined,
  widgetType: undefined,
  bonusData: undefined
}

interface Action {
  type: string,
  payload: {
    note?: {
      text: string,
      widget_id: string,
      rec_id: string,
      date_created: Date | string
    },
    onSave?: () => Promise<void>,
    path?: string,
    record?: Record
    widget?: string,
    activePopup?: string,
    x?: number,
    y?: number,
    english?: string,
    widgetType?: string,
    bonusData?: any
  }
}

// eslint-disable-next-line
export default (state: POPUP_STATE = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return { ...INITIAL_STATE }
    }
    case SET_POPUP: {
      const _payload = action.payload

      if (_payload) {
        // if the same record is clicked on, reset state
        if (state.activePopup === _payload.record._id) {
          return {
            activePopup: null,
            note: null,
            onSave: null,
            path: null,
            record: null,
            widget: null,
            widgetType: null,
            bonusData: null,
            x: 0,
            y: 0,
          }
        }
      }

      let yAdjust: number
      let xAdjust: number
            
      if (_payload?.y) {
        // move popup above line clicked on if
        // target is in lower half of screen
        // yAdjust = _payload.y > window.innerHeight / 2 ? _payload.y - 418 : _payload.y - 18
        yAdjust = _payload.y - 418
      }

      if (_payload?.x) {
        // move popup left if it might appear off screen right
        xAdjust = _payload.x + 350 > window.innerWidth * 0.7 ? _payload.x - 175 : _payload.x
      }
      
      return {
        ...state,
        activePopup: _payload?.record._id,
        note: _payload?.note,
        onSave: _payload?.onSave,
        path: _payload?.path,
        record: _payload?.record,
        widget: _payload?.widget,
        x: xAdjust,
        y: yAdjust,
        english: _payload?.english,
        widgetType: _payload?.widgetType,
        bonusData: _payload?.bonusData
      }
    }
    default: {
      return state
    }
  }
}
