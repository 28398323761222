import React from 'react'
import { motion } from 'framer-motion'
import SoundRenderer from '../../SoundRenderer'
import { LanguageStack, English } from '../../styles/PresentationWidget.styles'

const DefaultTemplate = ({ slide, slideIdx, currentItem, courseColor, variants }) => {
  return (
    <>
      {slide.english.map((text, i) => (
        <LanguageStack key={i} color={courseColor}>
          <motion.div
            animate={currentItem > i * 2 ? 'visible' : 'hidden'}
            variants={variants}
            transition={{ duration: currentItem === 0 ? 0 : 0.5 }}
          >
            <SoundRenderer
              key={`slide-${slideIdx}-other_lang-${i}`}
              text={slide.other_lang[i]}
              fontSize='4rem'
              presentation
            />
          </motion.div>
          <English
            color={courseColor}
            animate={currentItem > i * 2 + 1 ? 'visible' : 'hidden'}
            variants={variants}
            transition={{ duration: currentItem === 0 ? 0 : 0.5 }}
          >
            <SoundRenderer
              key={`slide-${slideIdx}-english-${i}`}
              text={text}
              fontSize='4rem'
              presentation
            />
          </English>
        </LanguageStack>
      ))}
    </>
  )
}

export default DefaultTemplate
