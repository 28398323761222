import React, { useEffect, useState } from 'react'
import * as R from 'ramda'

import { Grid, Header, List } from 'semantic-ui-react'

import useMobile from '../../../hooks/useMobile'
import useStudyMode from '../hooks/useStudyMode'

import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import { LanguageRecord } from '../../../types'
import WidgetWrapper from 'src/components/error_boundary/WidgetWrapper'

type Content = {
  english: string
  other_lang: string | LanguageRecord[]
  _id?: string
}

export type Sections = {
  contents: Content[]
  title: string
  _id?: string
}

interface SectionsProps {
  widget_id: string
  sections: Sections[]
  isMobile: boolean
  hideEnglish: boolean
  hideOtherLang: boolean
}

export interface TextListProps {
  id: string
  widget: {
    no_sounds: boolean
    plays_sound: boolean
    sections: Sections[]
    sound_instances: any
    type: string
    _id: string
  }
}

const Sections: React.FC<SectionsProps> = (
  { widget_id, isMobile, sections, hideEnglish, hideOtherLang }
) => {
  return (
    <WidgetWrapper
      data-t={`widget:textlist-id-${widget_id}`}
      widget={{ widgetId: widget_id, widgetType: 'TextListWidget' }}
    >
      {sections.map((section: Sections, index: number) => (
        <React.Fragment key={index}>
          <Header
            inverted
            className="fz-colored-background"
            style={{
              fontFamily: 'Milliard Bold',
              padding: '8px',
              marginBottom: '20px',
              borderRadius: '8px',
            }}
          >
            <SoundRenderer widget={widget_id} text={section.title} />
          </Header>
          <Grid className="fz-dark-font">
            {isMobile
              ? R.splitEvery(1, section.contents).map((contents: Content[], rIndex: number) => (
                  <Grid.Row
                    key={`row-${rIndex}`}
                    style={
                      rIndex !== section.contents.length - 1
                        ? {
                          borderBottom: '1px dashed #ccc',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }
                        : { marginLeft: '10px', marginRight: '10px' }
                    }
                  >
                    {contents.map((content: Content, cIndex: number) => (
                      <React.Fragment key={`colgroup-${cIndex}`}>
                        <Grid.Column width={8} className={hideOtherLang && 'sm-hidden'}>
                          <SoundRenderer widget={widget_id} text={content.other_lang} />
                        </Grid.Column>
                        <Grid.Column width={8} className={hideEnglish && 'sm-hidden'}>
                          <SoundRenderer widget={widget_id} text={content.english} />
                        </Grid.Column>
                      </React.Fragment>
                    ))}
                  </Grid.Row>
              ))
              : R.splitEvery(2, section.contents).map((contents: Content[], rIndex: number) => (
                  <Grid.Row key={`row-${rIndex}`}>
                    {contents.map((content: Content, cIndex: number) => (
                      <React.Fragment key={`colgroup-${cIndex}`}>
                        <Grid.Column width={4} className={hideOtherLang && 'sm-hidden'}>
                          <SoundRenderer widget={widget_id} text={content.other_lang} />
                        </Grid.Column>
                        <Grid.Column width={4} className={hideEnglish && 'sm-hidden'}>
                          <SoundRenderer widget={widget_id} text={content.english} />
                        </Grid.Column>
                      </React.Fragment>
                    ))}
                  </Grid.Row>
              ))
            }
          </Grid>
        </React.Fragment>
      ))}
    </WidgetWrapper>
  )
}

const TextListWidget: React.FC<TextListProps> = ({ id, widget }) => {
  const [sections, setSections] = useState<Sections[]>()
  const isMobile: boolean = useMobile()
  const { hideEnglish, hideOtherLang } = useStudyMode()

  useEffect(() => {
    if (!widget) return
    setSections(widget.sections)
  }, [widget])

  return (
    <div
      className="text-list-widget"
      style={{
        borderRadius: '15px',
        padding: isMobile ? '15px' : '30px',
        fontFamily: 'Milliard Book, Yu Gothic UI Regular',
        backgroundColor: 'white',
      }}
    >
      {!sections ? (
        <PlaceholderWidget widget_type="MixedWidget" />
      ) : (
        <WidgetWrapper widget={{ widgetType: 'TextListWidget', widgetId: id }}>
          <List>
            <Sections
              sections={sections}
              widget_id={id}
              isMobile={isMobile}
              hideEnglish={hideEnglish}
              hideOtherLang={hideOtherLang} />
          </List>
        </WidgetWrapper>
      )}
    </div>
  )
}

export default TextListWidget
