import styled from 'styled-components'

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--jfz-blue);
  background-color: var(--off-white);
  z-index: -1;

  display: flex;
  flex-direction: row;
  align-items: center;

  .kaji {
    position: absolute;
    top: 0;
    left: 8px;
    bottom: 0;
    max-width: 50px;
    object-fit: contain;
    vertical-align: middle;

    img {
      width: 100%;
    }
  }

  .flash-message {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
    p {
      margin: 3px;
      padding: 0;
      text-align: center;
      font-family: 'Milliard Book', sans-serif;
      font-weight: bold;

      &::selection {
        color: var(--blue-gray);
        background-color: var(--yellow);
      }
    }

    i {
      font-size: 1.5rem;
    }
  }
`
