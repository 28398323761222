import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { ReactComponent as MonthlyLabel } from 'src/assets/subscription/label_monthly.svg'
import { ReactComponent as AnnualLabel } from 'src/assets/subscription/label_yearly.svg'

export const planLabelStyles = {
  select: ({ isMobile }) => css`
    margin: ${isMobile ? '.5rem 0' : '1rem auto 0'};
    height: ${isMobile && '15px'};
    justify-self: ${isMobile && 'center'};
  `,
  selected: css`
    margin: 0.5rem 0 1rem;
  `,
  payment: css``
}

interface IsMobile {
  isMobile: boolean
}

interface WithPlanStep extends IsMobile {
  step: 'select' | 'selected' | 'payment'
  selected?: boolean
  reverse?: boolean
}

const speechBubbleDynamic = 'https://d32tv5vz8ua1sf.cloudfront.net/images/register/label-value-speech-bubble.svg'

export const PlanLabelMonthly = styled(MonthlyLabel)<WithPlanStep>`
  height: 35px !important;
  max-width: 150px !important;
  ${({ step }) => planLabelStyles[step]}
  color: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};
  stroke: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};

  @media (max-width: 620px) {
    height: 20px !important;
  }
`

export const PlanLabelAnnual = styled(AnnualLabel)<WithPlanStep>`
  height: 35px !important;
  max-width: 140px !important;
  ${({ step }) => planLabelStyles[step]}
  color: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};
  stroke: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};

  @media (max-width: 620px) {
    height: 20px !important;
  }
`

export const PaymentPlans = styled.div`
  display: flex;
  margin: 48px 0 16px;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  padding: 0 1rem;

  @media (max-width: 450px) {
    gap: 0.5rem;
  }

  @media (max-width: 425px) {
    margin-top: 18px;
    flex-direction: column;
    align-items: center;
    margin-top: 36px;
    text-align: left;
  }
`

export const PaymentPlanContainer = styled.div<{ isSelected: boolean, chosenPlan: string, plan: string }>`
  border: ${({ isSelected }) => isSelected ? 'solid 3px #ffb901' : 'solid 1px #cdcddb'};
  border-radius: 15px;
  padding: 1rem 7rem 1rem 1rem;
  position: relative;
  cursor: pointer;
  max-width: 280px;
  justify-self: center;
  ${({ isSelected }) => isSelected && 'background-color: #2a64bc;'}
  ${({ isSelected }) => isSelected && 'background-color: #2a64bc;'}
  fill: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;

  & > p {
    font-weight: bold;
    color: #6d6c82;
    ${({ isSelected }) => isSelected && 'color: white !important;'}
    ${({ isSelected }) => isSelected && 'color: white !important;'}
  }

  & > svg {
    color: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;
    fill: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;
    stroke: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;
  }

  @media (max-width: 620px) {
    padding: 1rem 5rem 1rem 1rem;
  }

  @media (max-width: 425px) { min-width: 250px; }
`

export const PaymentChibi = styled.img`
  position: absolute;
  width: 100px;
  right: -14.5px;
  bottom: -36px;
  clip-path: inset(0 14px 35px 0 round 13px);
  z-index: 2;

  @media (max-width: 620px) {
    width: 85px;
  }
`

export const SpeechBubble = styled.img`
  position: absolute;
  top: -30px;
  right: 70px;
  width: 125px;

  @media (max-width: 620px) {
    width: 105px;
    right: 55px;
    top: -20px;
  }
`

export const SpeechBubbleDynamic = styled.h4`
  font-family: 'Poppins Bold' !important;
  color: #2a64bc !important;
  position: absolute;
  top: -30px;
  right: 70px;
  background-image: url(${speechBubbleDynamic});
  padding: 8px 24px 20px !important;

  @media (max-width: 620px) {
    padding: 8px 8px 20px !important;
  }

  @media (max-width: 425px) {
    top: -25px;
    right: 65px;
  }
`

export const MonthlySavings = styled.h4`
  font-family: 'Poppins Bold' !important;
  color: #2a64bc !important;
  position: absolute;
  top: -20px;
  right: 40%;

  @media (max-width: 425px) { right: 29%; }
`
