import styled from 'styled-components'
import { Dropdown } from 'semantic-ui-react'

const TagMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 14px 0;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const TagDropdown = styled(Dropdown)`
  margin-right: 20px;
  padding: 4px 12px;
  background-color: white;
  border: 1px solid var(--blue-gray);
  border-radius: 5px;

  div.divider.text { margin-top: 2px; }

  div.divider.text, span.text {
    font-family: 'Milliard Book', sans-serif;
    font-size: 1.1rem;
  }
`

export {
  TagMenu as Menu,
  TagDropdown,
}
