import React, { useEffect, useState } from 'react'

import { Card, Form } from 'semantic-ui-react'

import ImageWidget from '../ImageWidget'

import useWidget from '../../hooks/useWidget'

const ImageQuestionWidget = props => {
    const [widget, fetchWidget, loading, editWidget] = useWidget(props.id)
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        setQuestions(widget?.questions || [])
    }, [widget])
    
    return (
        <div>
            <Card.Group>
                {questions?.map((q, idx) =>
                    <Card>
                        <ImageWidget size='medium' id={q.image_widget} />
                        <Form>
                            <Form.Input
                                inline
                                label='Answer'
                            />
                        </Form>
                    </Card>)}
            </Card.Group>
        </div>
    )
}

export default ImageQuestionWidget