import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 70%;
  margin: 0 auto;
  
  .nav-icon {
    padding: 2px 4px;
    margin: 0 4px;
  }
  
  .nav-icon a {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid transparent;
    padding: 0 2px 4px 0;

    .link-name {
      display: inline-block;
    }

    svg, img {
      width: 25px;
      height: 25px;
      margin-right: 8px;
    }

    :hover {
      border-color: var(--off-white);
    }
  }
  a.active {
    border-color: var(--off-white);
  }

  .nav-icon span {
    color: var(--off-white);
  }

  .s-ui i {
    font-size: 1.8rem;
    margin: 0 10px 10px 0;
  }
  .s-ui.white i { color: var(--off-white); }
  .s-ui.gray i { color: #9f9f9f; }
  .s-ui.blue i { color: var(--jfz-blue); }

  @media (max-width: 1350px) {
    margin: 0 15px;
  }

  @media (max-width: 1200px) {
    gap: 8px;

    .nav-icon {
      a, svg, img {
        margin-right: 0 !important;
      }
    }
    span.link-name {
      display: none !important;
    }
  }

  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
`
