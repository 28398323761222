import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { PRESENTATION } from '../toolkit.constants'
import { PresentationWidget, PresentationWidgetSlide } from 'src/types'

import { ToolFooter, ToolHeader, ToolInput, ToolOptions, ToolForm } from '../parts'
import { BarButton, ToolBody, ToolSubheader } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import useWidgetSaved from '../hooks/useWidgetSaved'
import PresentationSlide from './presentation/PresentationSlide'

const PresentationTool: React.FC<ToolProps<PresentationWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const [widget, fetchWidget, , editWidget] = useWidget<PresentationWidget>(id)
  const [slides, setSlides] = useState<PresentationWidgetSlide[]>([])
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const hookFormMethods = useForm()

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setWidgetValues(widget)
      setSlides(widget.slides)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const handleAddSlide = () => {
    const _slides = hookFormMethods.getValues()['slides']
    _slides.push({ title: '', subtitle: '', template: 'default', english: [''], other_lang: [''] })

    hookFormMethods.setValue('slides', _slides)
    setSlides(_slides)
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={PRESENTATION}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={PRESENTATION}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            {/* removing to keep simple for now to get up and running, and to understand what I'm doing */}
          </ToolOptions>
          <ToolBody>
            <ToolSubheader className='tw-border-b-2 tw-border-gray-300'>
              <ToolInput
                value='title'
                placeholder='Presentation Title'
              />
              <ToolInput
                value='subtitle'
                placeholder='Presentation Subtitle'
              />
            </ToolSubheader>
            {slides.map((slide, idx) => (
              <PresentationSlide key={`slide-${idx}`} slide={slide} idx={idx} formMethods={hookFormMethods} setSlides={setSlides} />
            ))}
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            removeLeftMargin
            widget_id={id}
          >
            <BarButton
              type='button'
              color='white'
              backgroundColor='var(--add)'
              contrastColor='var(--save)'
              borderColor='var(--save)'
              onClick={() => handleAddSlide()}
            >
              Add Slide
            </BarButton>
          </ToolFooter>
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default PresentationTool
