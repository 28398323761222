import React, { useState } from 'react'

import { Modal } from 'semantic-ui-react'
import { Transition } from '@headlessui/react'
import { ClickableBubble, CurrentSpeaker, SpeakerSelectionGrid } from '../../styles/ConversationTool.styles'

import ConversationChatBubble from 'src/components/fz_courses/widgets/ConversationChatBubble'

const chatIcons = {
  tomoko: '49, 100, 152',
  kenji: '177, 85, 85',
  mari: '101, 164, 98',
  ren: '132, 98, 164',
  rika: '152, 49, 90',
  young_girl: '141, 165, 178',
  guy: '207, 135, 71',
  lady: '52, 196, 155',
  lady2: '51, 46, 151',
}

interface SpeakerSelectProps {
  convoNumber: number
  onSelected: (convoNumber: number, chatPosition: number, icon: string) => void
  speakers: string[]
  speakerPosition: 0 | 1
}

const SpeakerSelect: React.FC<SpeakerSelectProps> = ({
  convoNumber,
  onSelected,
  speakers,
  speakerPosition,
}) => {
  const [selectionOpen, setSelectionOpen] = useState(false)

  const handleIconSelected = (icon: string) => {
    onSelected(convoNumber, speakerPosition, icon)
    setSelectionOpen(false)
  }

  const speakerLetter = speakerPosition === 0 ? 'A' : 'B'

  return (
    <div>
      <CurrentSpeaker onClick={() => setSelectionOpen(true)}>
        Speaker {speakerLetter}:
        <span>
          {speakers[speakerPosition]}
          <ConversationChatBubble
            icon={speakers[speakerPosition]}
            inToolView
          />
        </span>
      </CurrentSpeaker>
      <Modal
        open={selectionOpen}
        onClose={() => setSelectionOpen(false)}
      >
        <Modal.Header>Select New Speaker &quot;{speakerLetter}&quot; Icon</Modal.Header>
        <Modal.Description>
          <SpeakerSelectionGrid>
            <Transition show={selectionOpen}>
              {Object.keys(chatIcons).map((iKey, idx) => (
                <Transition.Child
                  key={idx}
                  enter='tw-transition-opacity tw-duration-150'
                  enterFrom='tw-opacity-0'
                  enterTo='tw-opacity-100'
                  leave='tw-transition-opacity tw-duration-75'
                  leaveFrom='tw-opacity-100'
                  leaveTo='tw-opacity-0'
                >
                  <ClickableBubble
                    className='tw-delay-100'
                    onClick={() => handleIconSelected(iKey)}
                    ringColor={chatIcons[iKey]}
                    selected={iKey === speakers[speakerPosition]}
                  >
                    <ConversationChatBubble icon={iKey} inToolView />
                    <p>{iKey.replace('_', ' ')}</p>
                  </ClickableBubble>
                </Transition.Child>
              ))}
            </Transition>
          </SpeakerSelectionGrid>
        </Modal.Description>
      </Modal>
    </div>
  )
}

export default SpeakerSelect
