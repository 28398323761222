import { FlexRow } from 'src/assets/styles/globalStyles'
import styled from 'styled-components'
import tw from 'twin.macro'

export const CellManagementButtonGroup = styled.div`
  ${tw`tw-flex tw-flex-col tw-justify-between tw-items-center tw-mt-2 tw-mb-4`}
`

// eslint-disable-next-line import/prefer-default-export
export const AddCellOrRow = styled.div<{ isAddCell: boolean }>`
  ${tw`tw-rounded-md tw-flex tw-justify-center tw-items-center tw-cursor-pointer`}
  width: ${({ isAddCell }) => (isAddCell ? '40px' : '100%')};
  height: ${({ isAddCell }) => (!isAddCell ? '40px' : '40%')};
  margin-bottom: ${({ isAddCell }) => (!isAddCell ? '12px' : '0')};
  padding-bottom: 4px;

  background-color: var(--add);

  :hover {
    background-color: var(--add-hover);
  }

  i { color: white; }
`

export const RemoveCellOrRow = styled(AddCellOrRow)`
${tw`tw-bg-red-400 hover:tw-bg-red-300`}
`

export const GridRow = styled.div`
  ${tw`tw-w-full tw-flex tw-flex-row tw-justify-start tw-items-start tw-gap-2 tw-px-2`}
  width: 100%;
  overflow: hidden;
  overflow-y: visible;
`

export const GridCol = styled.div`
  ${tw`tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center`}
`

interface RowWidthProps {
  fitInView: boolean
  rowLength: number
}

export const GridCell = styled.div<RowWidthProps>`
  ${tw`tw-w-full tw-h-full tw-mb-4 tw-bg-white tw-relative tw-flex tw-flex-row tw-justify-start tw-items-stretch tw-shadow-sm`}
  min-width: ${({ fitInView, rowLength }) => (fitInView ? `${rowLength / 100}%` : '')};
`

export const ChangeCellContent = styled.button`
  ${tw`tw-rounded-md tw-p-2 tw-flex tw-items-center tw-justify-center tw-cursor-pointer hover:tw-shadow-sm tw-text-white`}

  background-color: var(--buttons);
  opacity: 35%;

  :hover {
    opacity: 100%;

    i {
      transform: rotate(360deg);
    }
  }
  i {
    color: white;
    transition: transform 300ms ease-in-out;
    transform: rotate(0deg);
  }
`

export const CarouselRow = styled.div<RowWidthProps>`
  ${tw`tw-w-full tw-flex tw-flex-row tw-justify-start tw-items-stretch tw-gap-2`}
  width: ${({ fitInView, rowLength }) => (fitInView ? `${100 * rowLength}%` : '100%')};
  align-self: flex-start;
`

export const DividingDiv = styled.div`
  ${tw`tw-w-full tw-my-4 tw-divide-dashed tw-divide-y-2 tw-divide-blue-300`}
  overflow: hidden;
`

export const CellManagementBar = styled(FlexRow)`
  ${tw`tw-w-full tw-justify-between tw-items-center tw-p-2 tw-shadow-sm`}
  background-color: var(--mid-panel);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`

const GridButton = styled.button`
  ${tw`tw-px-2 tw-py-1 tw-rounded-md tw-cursor-pointer hover:tw-shadow-sm`}
  i { color: white; }
  :hover {
    transform: scale(1.05);
  }
`

export const OptionButton = styled(GridButton)`
  background-color: var(--buttons);
  min-width: max-content;

  color: white;
  i {
    font-size: 1.3rem;
  }

  :hover {
    background-color: var(--buttons-hover);
  }
`

export const AddCell = styled(GridButton)`
  background-color: var(--save);
  :hover {
    background-color: var(--save-hover);
  }
`

export const RemoveCell = styled(GridButton)`
  background-color: var(--delete);
  :hover {
    background-color: var(--delete-hover);
  }
`
