import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'

import styled from 'styled-components'
import sleepyTomoko from '../../assets/chibi/sleepy_tomoko.png'

import useRenderErrorLogger from '../../hooks/useRenderErrorLogger'
import useStaffRoles from 'src/hooks/useStaffRoles'
import useLocalStorage from 'src/hooks/useLocalStorage'
import moment from 'moment'

const FallbackWidgetStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 50%;
  margin: 0 auto;
  object-fit: contain;

  img {
    width: 100px;
    margin: 15px 0;
  }

  p {
    font-size: 1rem;
    color: var(--blue-gray);
    font-family: 'Milliard Book', sans-serif;
  }
`

export type Widget = {
  widgetType: string,
  widgetId: string
}

interface CustomFallbackComponentProps {
  error?: any
  resetErrorBoundary?: () => void
  widget?: Widget
  fallbackMessage?: string
}

const FallbackWidget: React.FC<CustomFallbackComponentProps> = (props) => {
  return (
    <FallbackWidgetStyles className='fallback-widget'>
      <img src={sleepyTomoko} className='no-select' draggable={false} alt="widget render error, featuring sleepy Tomoko" />
      <div>
        <p>There was an error rendering {props?.widget?.widgetType || props?.fallbackMessage || 'this widget'}.</p>
        <p>No need to submit a bug report; our developers have been notified!</p>
      </div>
    </FallbackWidgetStyles>
  )
}

const CustomFallbackComponent: React.FC<CustomFallbackComponentProps> = (props) => {
  if (props?.widget) return <FallbackWidget widget={props.widget} />
  return (
    <FallbackWidgetStyles>
      <img src={sleepyTomoko} className='no-select' draggable={false} alt="widget render error, featuring sleepy Tomoko" />
      <div>
        <p>Oops! There was an error rendering {props?.fallbackMessage || 'part of this page'}.</p>
        <p>No need to submit a bug report; our developers have been notified!</p>
      </div>
    </FallbackWidgetStyles>
  )
}

interface FZErrorBoundaryProps {
  children?: React.ReactNode
  widget?: Widget
  fallbackMessage?: string
}

type FZError = {
  stack: string
  message: string
}

const FZErrorBoundary: React.FC<FZErrorBoundaryProps> = (props) => {
  const [error, setError] = useState<FZError>()
  const [loggedError, setLoggedError] = useState<FZError>()
  const [storedValue, setValue] = useLocalStorage('chunks-cached-timestamp', Date.now())
  const location = useLocation()
  const [admin] = useStaffRoles()
  const [sentLog, logError] = useRenderErrorLogger()

  const [chunkError, setChunkError] = useState<boolean>()
  const postForm = useRef(undefined)

  const errorHandler = (err) => {
    if (admin) {
      if (props?.widget) console.error(props.widget)
      // console.error(err?.message)
    }
    setError(err)
  }

  const sendLogError = async () => {
    if (sentLog || process.env.REACT_APP_YJ_DEV === 'true') return
    const result = await logError({
      path: location.pathname,
      message: error?.message,
      stack: error?.stack,
      widget: props?.widget,
    })

    if (result) {
      setLoggedError(error)
      setError(error)
    }
  }

  const refreshPage = () => {
    /**
    * TODO: needs live test
    * cheeses a hard refresh of the page
    * by submitting a form with a GET request of the current page URL
    * solution found here https://stackoverflow.com/a/68151170

    * NOTE: the solution is using Angular and was able to POST a form, but
    * that results in a "cannot post to /" 404 message in my tests before the form can even submit
    */
    if (chunkError && postForm?.current) postForm.current.submit()
    // if (chunkError) window.location.reload()
  }

  useEffect(() => {
    const error_message = error?.message?.toLowerCase()
    if (
      error_message?.includes('loading chunk')
      || error_message?.includes('minified react error')
    ) {
      setChunkError(true)
      const now = moment()
      const cached = moment(storedValue)
      const yesterday = moment().subtract(1, 'days')

      /**
       * set their local storage cached to current time
       * this prevents the refreshPage function from looping indefinitely
       * in case this method of forcing a refresh doesn't work
       */
      if (cached.isBefore(yesterday)) {
        setValue(now)
        refreshPage()
      }
      return
    }
    if (loggedError !== error) sendLogError()
  }, [error])

  return (
    <ErrorBoundary
      // FallbackComponent={props.customFallbackComponent || ErrorFallback}
      onError={errorHandler}
      fallbackRender={() => (
        <CustomFallbackComponent
          error={error}
          widget={props?.widget}
          fallbackMessage={props?.fallbackMessage}
        />
      )}
    >
      {chunkError && (<form method='GET' action={location.pathname} ref={postForm}></form>)}
      {props?.children ? props.children : <></>}
    </ErrorBoundary>
  )
}

export default FZErrorBoundary
