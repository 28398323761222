import React, { useEffect, useState } from 'react'
import * as R from 'ramda'

import { Button, Container, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react'

import ConversationPreview from './grabber/ConversationPreview'
import ExamplesPreview from './grabber/ExamplesPreview'
import QnAPreview from './grabber/QnAPreview'
import SentencesPreview from './grabber/SentencesPreview'
import TablePreview from './grabber/TablePreview'
import TextPreview from './grabber/TextPreview'
import TextListPreview from './grabber/TextListPreview'
import DialoguePreview from './grabber/DialoguePreview'
import KanjiPreview from './grabber/KanjiPreview'

const options = [
  { text: 'Text', key: 'text', value: 'text' },
  { text: 'Sentences', key: 'sentences', value: 'sentences' },
  { text: 'Q & A', key: 'qna', value: 'qna' },
  { text: 'Examples', key: 'examples', value: 'examples' },
  { text: 'Conversation', key: 'conversation', value: 'conversation' },
  { text: 'Table', key: 'table', value: 'table' },
  { text: 'Text List', key: 'text-list', value: 'text-list' },
  { text: 'Dialogue', key: 'dialogue', value: 'dialogue' },
  { text: 'Kanji', key: 'kanji', value: 'kanji' }
]

const widgetToTypeMap = {
  TextboxWidget: 'text',
  SentencesWidget: 'sentences',
  QnAWidget: 'qna',
  ExamplesWidget: 'examples',
  ConversationWidget: 'conversation',
  TableWidget: 'table',
  TextListWidget: 'text-list',
  DialogueWidget: 'dialogue',
  KanjiWidget: 'kanji'
}

const DataGrabber = (props) => {
  const [text, setText] = useState('')
  const [parsedData, setParsedData] = useState([])
  const [selectedWidgetType, setSelectedWidgetType] = useState(widgetToTypeMap[props.selected] || '')
  const [minimized, setMinimized] = useState(false)

  useEffect(() => {
    console.error('empty use effect')
  }, [parsedData])

  const regexes = {
    sentences: /[0-9]\.\s*([０-９？　-龯()_]+)[\W+]\W+(.+)/g,
    sentences_2col: /([0-9]\.\s*([０-９？　-龯_()/]+)\s*)([0-9]\.\s([０-９　-龯_()/]+)\s*)(['’ 0-9a-zA-Z?!_,.()/]+)\s*(['’ 0-9a-zA-Z?!,._()/]+)\s*/g,
    sentences_1colsplit: /[0-9]\.\s+(['’ 0-9a-zA-Z?!/*,._()-]+)\s*([0-9０-９　-龯()_！]+)/g,
    sentences_1coljpfirst: /[0-9]\.\s+([０-９？　-龯()'’ …0-9a-zA-Z?!！“”/*,._-]+)\s*(['’ “”0-9a-zA-Z?!/*,._()\-…:;]+)/g,
    sentences_2coljpfirst: /(?:[0-9]\.\s+([０-９？　-龯()'’ 0-9a-zA-Z?!！“”/*,._-]+)\t*)+\s*(?:(['’ “”0-9a-zA-Z?!/*,._()-]+)\t*)+/g,
    qna: /[0-9]\.\s+([０-９？　-龯()\s]+)\s*\n\t((?:.*\n*){3})/g,
    qna_2col: /[0-9]\.\s+(([０-９？　-龯()]+)\s*(['’ 0-9a-zA-Z?!:(),.]+)\s*)+/g,
    qna_2colrev: /[0-9]\.\s+((['’ 0-9a-zA-Z?!,.]+)\s*([０-９？　-龯()]+)\s*)+/g,
    examples: /([０-９？　-龯()[\]]+\t+[()[a-zA-Z;',/ [\]]+)/g,
    examples_simple: /(.+)\t+(.+)\n*/g,
    conversation: /[0-9]\.\s*((.)+\s((?:A[:：]|B[:：])\s+.*\s*)+)/g,
    text: /.*/g,
    'text-list': /(['’ 0-9a-zA-Z?!;,_/*.()０-９？　-龯～～]+)\s*(([０-９？　-龯_()～～]+)\s+([～～'’ 0-9a-zA-Z?!;,_/*.\-()]+)\s*)+/g,
    dialogue: /([０-９？0-9　-龯()・]+)[:：]*\s+「*([…０-９？0-9　-龯()・～！ ]+)」*\s*/g,
    kanji: /([0-9]+)\t(.+)\t([0-9]+)画\t(.+)\s*くんよみ\t(.+)\s*おんよみ\t(.+)\s*((?:.+ \(.+\) .+[\t\n])+)\s(.)/g
  }

  const removeLeadingNum = R.replace(/^[0-9]\.\s*/g, '')

  const matchToSentences1Col = (matchData) => matchData.map((d) => (
    {
      english: d[2],
      other_lang: d[1]
    }))

  const splitEvenOdd = R.compose(R.values, R.addIndex(R.groupBy)((val, idx) => idx % 2))
  const get2ColSentenceRow = (d) => [
    { english: d[5], other_lang: d[2] }, { english: d[6], other_lang: d[4] }
  ]
  const matchToSentences2Col = (matchData) => {
    return splitEvenOdd(matchData.map(get2ColSentenceRow).flat()).flat()
  }

  const matchToSentences1ColSplit = (matchData) => matchData.map((d) => (
    { english: d[1], other_lang: d[2] }
  ))

  const matchToSentences1ColJpFirst = (matchData) => matchData.map((d) => (
    { english: d[2], other_lang: d[1] }
  ))

  const matchToSentences2ColJpFirst = (matchData) => {
    const returnData = []

    for (let i = 0; i < matchData.length; i++) {
      //
    }

    return returnData
  }

  const extractQnA1Col = R.compose(R.values, R.map(R.filter((n) => n.length > 0)), R.map(R.split('\n\t')), R.map(removeLeadingNum), R.split('\n\n'))
  const cleanQnA1Col = (d) => R.pipe(R.map(R.map(R.compose(R.map(R.trim), R.split('\n')))))(extractQnA1Col(d))
  const matchToQnA1Col = (d) => cleanQnA1Col(d)
    .map((block) => R.zipWith((other, e) => ({
      other_lang: other,
      english: e
    }), block[0], block[1]))
  
  const splitQnA2ColBlocks = R.compose(R.split('\n\n'), R.replace('\n\t\n', '\n\n'), R.replace('\r', ''))
  const deSentenceQnA2Col = R.compose(R.map(R.pipe(removeLeadingNum, R.trim, R.split('\n'))), splitQnA2ColBlocks)
  const matchToQnA2Col = (d) => deSentenceQnA2Col(d)
    .map((block) => block.map((s2) => s2.trim().replace(/\t+/, '\t').split('\t'))
      .map((s) => ({ other_lang: s[0], english: s[1] })))

  const splitQnA2ColBlocksRev = R.compose(R.split('\n\n'), R.replace('\r', ''))
  const deSentenceQnA2ColRev = R.compose(R.map(R.pipe(removeLeadingNum, R.trim, R.split('\n'))), splitQnA2ColBlocksRev)
  const matchToQnA2ColRev = (d) => deSentenceQnA2ColRev(d)
    .map((block) => block.map((s2) => s2.trim().replace(/\t+/, '\t').split('\t'))
      .map((s) => ({ other_lang: s[1], english: s[0] })))

  const matchToExamples = (d) => d.map((e) => e[0])
    .map((e) => R.map(R.trim, R.splitAt(e.indexOf(' ('), e)))
    .map((e) => ({ other_lang: e[0], english: e[1].replace(/[()]/g, '') }))

  const matchToSimpleExamples = (d) => {
    return d.map((e, idx) => ({ other_lang: d[idx][1], english: d[idx][2] }))
  }

  const cleanConversationBlocks = R.map(R.compose(R.map(R.trim), R.split('\n'), R.trim, R.prop(1)))
  const splitOnEmpty = R.splitWhen(R.equals(''))
  const removeEmptyStrings = R.compose(R.map(R.filter((s) => s !== '')), splitOnEmpty)
  const takeHeader = R.compose(R.head, R.head)
  const cleanAB = R.map(R.compose(R.trim, R.replace(/(A[:：]|B[:：])/g, '')))
  const matchToConversation = (d) => R.map(removeEmptyStrings)(cleanConversationBlocks(d))
    .map((data) => ({
      title: data[0][0],
      other_lang: cleanAB(R.splitAt(1, data[0])[1]),
      english: cleanAB(data[1])
    }))

  const breakUpRows = R.split('\n')
  const breakUpTabs = R.split('\t')
  const matchToTable = (d) => {
    const data = R.map(breakUpTabs)(breakUpRows(d))
    return ({
      columns: data[0].length,
      header: data[0],
      rows: data.splice(1, data.length - 1)
    })
  }

  const breakUpTextList = (_text) => {
    const splitText = _text.split('\n')
    splitText.splice(0, 1)
    const words = []
    const wordGroups = splitText.map((st) => st.split('\t'))

    wordGroups.forEach((wg) => {
      if (wg[0] === '' || wg[1] === '') return;
      words.push({ english: wg[1], other_lang: wg[0] })
      if (wg.length === 4) {
        words.push({ english: wg[3], other_lang: wg[2] })
      }
    })

    return words
  }

  const matchToTextList = (d) => {
    return d.map((s) => ({
      title: s[1],
      contents: breakUpTextList(s[0])
    }))
  }

  const matchToDialogue = (matchData) => matchData.map((d) => ({ speaker: d[1], text: d[2] }))

  const splitSecondSpace = (str) => {
    const index = str.indexOf(' ', str.indexOf(' ') + 1)

    const firstChunk = str.substr(0, index)
    const secondChunk = str.substr(index + 1)

    return ({
      japanese: firstChunk,
      english: secondChunk
    })
  }

  const matchToKanji = (matchData) => {
    const exampleStringsBody = matchData[0][7].replace(/[\n\r]/g, '\t').trim()
    const examples = exampleStringsBody.split('\t').map(splitSecondSpace)

    return ({
      number: matchData[0][1],
      radical: matchData[0][2],
      strokes: matchData[0][3],
      meaning: matchData[0][4],
      kunyomi: matchData[0][5],
      onyomi: matchData[0][6],
      kanji: matchData[0][8],
      examples
    })
  }

  const processText = () => {
    // get rid of empty paren groups from Word
    setText(text.replace(/\( \)/g, ''))

    if (!selectedWidgetType) return

    let cleanedSelectedWidgetType = selectedWidgetType
    
    // if (selectedWidgetType === 'sentences' && text.match(regexes['sentences_2coljpfirst']))
    //     cleanedSelectedWidgetType = 'sentences_2coljpfirst'
    
    if (selectedWidgetType === 'sentences' && text.match(regexes['sentences_2col'])) cleanedSelectedWidgetType = 'sentences_2col'
    else if (selectedWidgetType === 'sentences' && text.match(regexes['sentences_1coljpfirst'])) cleanedSelectedWidgetType = 'sentences_1coljpfirst'
    else if (selectedWidgetType === 'sentences' && text.match(regexes['sentences_1colsplit'])) cleanedSelectedWidgetType = 'sentences_1colsplit'

    if (selectedWidgetType === 'examples' && text.match(regexes['examples_simple'])) cleanedSelectedWidgetType = 'examples_simple'

    if (selectedWidgetType === 'qna' && text.match(regexes['qna_2col'])) cleanedSelectedWidgetType = 'qna_2col'

    if (selectedWidgetType === 'qna' && text.match(regexes['qna_2colrev'])) cleanedSelectedWidgetType = 'qna_2colrev'

    const matchData = [...text.matchAll(regexes[cleanedSelectedWidgetType])]

    switch (cleanedSelectedWidgetType) {
      case 'text': return setParsedData(text)
      case 'sentences': return setParsedData(matchToSentences1Col(matchData))
      case 'sentences_2col': return setParsedData(matchToSentences2Col(matchData))
      case 'sentences_1colsplit': return setParsedData(matchToSentences1ColSplit(matchData))
      case 'sentences_1coljpfirst': return setParsedData(matchToSentences1ColJpFirst(matchData))
      case 'sentences_2coljpfirst': return setParsedData(matchToSentences2ColJpFirst(matchData))
      case 'examples': return setParsedData(matchToExamples(matchData))
      case 'examples_simple': return setParsedData(matchToSimpleExamples(matchData))
      case 'qna': return setParsedData(matchToQnA1Col(text))
      case 'qna_2col': return setParsedData(matchToQnA2Col(text))
      case 'qna_2colrev': return setParsedData(matchToQnA2ColRev(text))
      case 'conversation': return setParsedData(matchToConversation(matchData))
      case 'table': return setParsedData(matchToTable(text))
      case 'default': return setParsedData(matchData)
      case 'text-list': return setParsedData(matchToTextList(matchData))
      case 'dialogue': return setParsedData(matchToDialogue(matchData))
      case 'kanji': return setParsedData(matchToKanji(matchData))
      default:
    }
  }

  const createWidget = () => {
    props.onDataProcessed(parsedData)
    // props.onCreateWidget({type: selectedWidgetType, data: parsedData})
  }

  const identifyRegex = () => {
    if (text.match(/[0-9]\.\t+([　-龯()]+)[\W+]\W+[0-9]\.(.+)/)) return 'sentences'
  }

  const renderPreviewData = () => {
    switch (selectedWidgetType) {
      case 'text': return <TextPreview data={parsedData} />
      case 'sentences': return <SentencesPreview data={parsedData} />
      case 'qna': return <QnAPreview data={parsedData} />
      case 'examples': return <ExamplesPreview data={parsedData} />
      case 'conversation': return <ConversationPreview data={parsedData} />
      case 'table': return <TablePreview data={parsedData} />
      case 'text-list': return <TextListPreview data={parsedData} />
      case 'dialogue': return <DialoguePreview data={parsedData} />
      case 'kanji': return <KanjiPreview data={parsedData} />
      default: return null
    }
  }

  return (
    <Container style={{ marginTop: '10px' }}>
      <Segment raised>
        <Grid centered style={{ marginBottom: '10px' }}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header as='h2' textAlign='center'>
                Data Grabber
              </Header>
            </Grid.Column>
            <Grid.Column width={2}>
              <Button circular onClick={() => setMinimized(!minimized)}>
                <Icon name={minimized ? 'plus' : 'minus'} />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {!minimized && (
          <div>
            <Form>
              <Form.TextArea
                placeholder='Enter raw data here...'
                value={text}
                onChange={(e, { value }) => setText(value)}
                rows={10}
              />
              <Form.Select
                required
                placeholder='Select widget type...'
                options={options}
                value={selectedWidgetType}
                onChange={(e, { value }) => setSelectedWidgetType(value)}
              />
              <Button color='green' onClick={processText}>
                Process
              </Button>
            </Form>
            <Header as='h3'>Parsed Data</Header>
            {(parsedData?.length === 0 && <p style={{ color: 'red' }}>Empty!</p>) || (
              <div style={{ color: 'black' }}>
                {renderPreviewData()}
                <Button color='purple' onClick={createWidget}>
                  Create Widget
                </Button>
              </div>
            )}
          </div>
        )}
      </Segment>
    </Container>
  )
}

export default DataGrabber
