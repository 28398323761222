import React from 'react'
import { AddGroupLine } from '../../styles/TextList.styles'

interface AddSectionGroupProps {
  handleClicked: (index: number) => void
  indexInList: number
}

const AddSectionGroup: React.FC<AddSectionGroupProps> = ({
  handleClicked,
  indexInList,
}) => {
  return (
    <AddGroupLine>
      <div>
        <button onClick={() => handleClicked(indexInList)}>
          Add Group
        </button>
      </div>
    </AddGroupLine>
  )
}

export default AddSectionGroup
