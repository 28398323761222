import React, { useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import useAPI from 'src/hooks/useAPI'

import { Widget } from 'src/types'

import RenderedWidget from '../../editor/styles/RenderedWidget'
import { widgetOptions } from '../../../sublessonpart/sublesson_part_imports'
import { SampleViewer, SampleWidgetTypeText, SampleWidgetView } from '../styles/NestedTool.styles'
import usePortal from 'src/hooks/usePortal'

const WidgetPortal = ({ children }) => {
  const portalTarget = usePortal('option-portal')
  return createPortal(children, portalTarget)
}

const WidgetSample: React.FC<{
  sampleWidgetText: string
  widget_type: string
  onSampleClicked: (widget_id: string, widget_type: string) => void
  viewingSample: string
  fetchCounter?: number
}> = ({
  sampleWidgetText,
  widget_type,
  onSampleClicked,
  viewingSample,
}) => {
  const [example, setExample] = useState<Widget>()
  const [sampleWidget] = useAPI(`/widgets/sample/${widget_type}`)

  useEffect(() => {
    if (sampleWidget?.success) {
      setExample(sampleWidget.widget)
    }
  }, [sampleWidget])

  const handleSample = () => {
    onSampleClicked(example._id, example.type)
  }

  const WidgetView = useMemo(() => {
    if (example !== undefined) return widgetOptions[example?.type]?.w
    return null
  }, [example])

  return (
    <SampleViewer>
      <SampleWidgetTypeText onClick={() => handleSample()}>
        <div>
          <h3>{sampleWidgetText}</h3>
        </div>
      </SampleWidgetTypeText>
      {example && viewingSample === example?._id && (
        <WidgetPortal>
          <SampleWidgetView>
            <RenderedWidget
              idx={0}
              hasNoPadding={
                ['ExamplesWidget', 'SentencesWidget', 'QnAWidget', 'TableWidget', 'DialogueWidget'].includes(example.type)
              }
              isFirstIndex={true}
              isLastIndex={true}
              isMobile={false}
              widgetType={example.type}
              fetchCounter={0}
            >
              {(example && WidgetView) && (
                <WidgetView
                  id={example._id}
                  lesson={'1'}
                  course={'1'}
                  adminView={true}
                  widget={example}
                  courseColor={'var(--jfz-blue)'}
                />
              )}
            </RenderedWidget>
          </SampleWidgetView>
        </WidgetPortal>
      )}
    </SampleViewer>
  )
}

export default WidgetSample
