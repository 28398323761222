import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import useInterval from 'src/hooks/useInterval'

import useLoadingProgressBar from 'src/hooks/useLoadingProgressBar'
import mathClamp from 'src/util/mathClamp'

const ProgressBar: React.FC = () => {
  const [progressWidth, setProgressWidth, resetWidth] = useLoadingProgressBar()
  const [loaded, setLoaded] = useState(false)
  const [intervalDelay, setIntervalDelay] = useState<number>()
  const location = useLocation()
  
  const fakeLoader = () => {
    if (loaded) return

    if (progressWidth > 99) {
      setLoaded(true)
      setIntervalDelay(undefined)

      setTimeout(() => {
        resetWidth()
      }, 1500)
      return
    }

    if (progressWidth < 100) {
      setLoaded(false)
      const newValue = Math.round(Math.random() * 25)
      setProgressWidth(progressWidth + mathClamp(newValue, 5, 25))
    }
  }

  useEffect(() => {
    resetWidth()
    if (!location.pathname.includes('/courses/')) return
    setLoaded(false)
    setIntervalDelay(150)
  }, [location.pathname])

  useInterval(fakeLoader, intervalDelay)

  return (
    <div
      className='fz-sub-colored-background'
      style={{
        position: 'fixed',
        top: progressWidth > 1 ? '70px' : '0',
        height: progressWidth > 1 ? '6px' : 0,
        width: progressWidth > 1 ? '100%' : 0,
        zIndex: '2',
        transition: 'all 200ms ease-in'
      }}
    >
      <TransitionGroup>
        <CSSTransition
          timeout={500}
          key={location.pathname}
          classNames='fill'
        >
          <div
            style={{
              position: 'fixed',
              top: progressWidth > 1 ? '70px' : '0',
              backgroundColor: 'var(--yellow)',
              height: '6px',
              width: `${progressWidth}%`,
              zIndex: '2',
            }}
          />
        </CSSTransition>
      </TransitionGroup>
  </div>
  )
}

export default ProgressBar
