import React from 'react'
import { TextListSections } from 'src/types'
import { IconButton, ToolInput } from '../../parts'
import { TextListPair, TLContent, TLSection, VerticalButtonGroup } from '../../styles/TextList.styles'

interface TextListSectionProps {
  handleRemoveContentFromList: (index: number) => void
  removeFromSectionGroups: (index: number) => void
  indexInList: number
  section: TextListSections
}

const TextListSection: React.FC<TextListSectionProps> = ({
  handleRemoveContentFromList,
  removeFromSectionGroups,
  indexInList,
  section
}) => {
  return (
    <TLSection>
      <TLContent>
        <ToolInput
          value={`sections.${indexInList}.title`}
          placeholder="Section Title"
        />
        <div>
          {section.contents.map(({ _id }, cIdx) => (
            <TextListPair key={_id}>
              <ToolInput
                value={`sections.${indexInList}.contents.${cIdx}.other_lang`}
                placeholder="Target Language"
              />
              <ToolInput
                value={`sections.${indexInList}.contents.${cIdx}.english`}
                placeholder="English"
              />
            </TextListPair>
          ))}
        </div>
      </TLContent>
      <VerticalButtonGroup>
        <IconButton
          color='var(--red)'
          contrastColor='var(--off-white)'
          handleClick={() => removeFromSectionGroups(indexInList)}
          icon='trash'
        />
        <IconButton
          color='var(--red)'
          contrastColor='var(--off-white)'
          handleClick={() => handleRemoveContentFromList(indexInList)}
          icon='minus'
        />
      </VerticalButtonGroup>
    </TLSection>
  )
}

export default TextListSection
