import * as R from 'ramda'
import verbData, { Verb } from './VerbData'
import { Selection } from './useConjugation/types'

// REMEMBER: 3 types --> ichidan, godan, irregular

/* ***CONJUGATIONS***
  C1-12: masu form
  C2-: mashou
  C2-09: informal (nai, ta, nakatta, also u --> wa special case)
  C3-03: Te-form (command), nai-de (don't do it): also u --> wa special case
  C3-05: Te-imasu forms (4 forms), te-imasu OR te-masu
  C3-09: tai form (4 forms)
  C3-12: Te-kara (after) ??? --> idk if this is relevant here
  C3-13: Potential form (can, can't, could, *couldn't*?), ichidan --> rareru, rarenai
  C4-04: Have ever/never: ta koto aru/nai
  C4-06: Te-mo ii desu (ka), Te-wa ikemasen || informal (Te mo ii (?), cha/ja dame)
  C4-07: koto ni suru???
  C4-08: ~kata: way to do???
  C4-09: ni iku/kuru: go/come to do something, tari suru (iku/kuru/suru can be conjugated)
  C4-10: u-mae, ta-ato: before/after I do
  C4-11: ta/nai hou ga ii: should/shouldn't, informal-nara: if, tara: if/when
  C4-13: Te-hoshii, nai de-hoshii, te-hoshikunai: want you to..
  C4-14: Te-kureru/ageru/etc... ???
  C5-01: Te-miru: try to (miru conjugated), tari shimasu ka: do you ever?
  C5-02: nagara: while doing
  C5-03: ppanashi, garu ???
  C5-04: you ni naru: become able to
  C5-05: Te-shimau ?? (casual versions maybe)
  C5-: shi-hajimeru, shi-oeru/owaru: to start/end something, rareru: passive form
*/

export type Conjugation = 'masu' | 'masen' | 'mashita' | 'masen_deshita' | 'mashou' | 'nai' | 'ta' | 'nakatta' | 'te' | 'nai_de'
  | 'te_imasu' | 'te_imasen' | 'te_imashita' | 'te_imasen_deshita' | 'te_iru' | 'te_inai' | 'te_ita' | 'te_inakatta'
  | 'tai' | 'takunai' | 'takatta' | 'takunakatta' | 'eru' | 'enai' | 'eta' | 'enakatta' | 'ta_koto_ga_aru' | 'ta_koto_ga_nai'
  | 'temo_ii' | 'tewa_ikemasen' | 'cha_dame' | 'ni_iku' | 'ni_kuru' | 'tari_shimasu' | 'u_mae' | 'ta_ato' | 'ta_hou_ga_ii'
  | 'nai_hou_ga_ii' | 'nara' | 'tara' | 'te_hoshii' | 'nai_de_hoshii' | 'te_hoshikunai' | 'te_miru' | 'nagara' | 'tagaru'
  | 'te_shimau' | 'chau' | 'nakereba_narimasen' | 'nai_to_ikemasen' | 'ou_to_suru' | 'temo'

export type ConjugationData = {
  grammar: string
  stem: 'あ' | 'い'| 'う' | 'え' | 'お'| 'て' | 'た' | 'ちゃ' | 'potential'
  ending: string
  alternate_endings?: string[]
  englishForm: 'VBG' | 'VBD' | 'VBN' | 'VBP' | 'VBZ' | '-to' | 'infinitive'
  englishStem: string
  lesson: string
  form?: string
}

type ConjugationList = Required<{ [key in Conjugation]: ConjugationData }>

const polite = 'POLITE conjugation'
const informal = 'INFORMAL conjugation'
const command = 'COMMAND (て) form'

export const conjugations: ConjugationList = {
  masu: {
    grammar: 'Will do (polite)',
    stem: 'い',
    ending: 'ます',
    englishForm: '-to',
    englishStem: 'I will ',
    lesson: '1/12/3/3',
    form: polite
  },
  masen: {
    grammar: "Won't do (polite)",
    stem: 'い',
    ending: 'ません',
    englishForm: '-to',
    englishStem: "I won't ",
    lesson: '1/12/3/3',
    form: polite
  },
  mashita: {
    grammar: 'Did (polite)',
    stem: 'い',
    ending: 'ました',
    englishForm: 'VBD',
    englishStem: 'I ',
    lesson: '1/12/3/3',
    form: polite
  },
  masen_deshita: {
    grammar: "Didn't (polite)",
    stem: 'い',
    ending: 'ませんでした',
    englishForm: '-to',
    englishStem: "I didn't ",
    lesson: '1/12/3/3',
    form: polite
  },
  mashou: {
    grammar: "Let's (polite)",
    stem: 'い',
    ending: 'ましょう',
    englishForm: '-to',
    englishStem: "Let's ",
    lesson: '2/6/5/4',
    form: polite
  },
  nai: {
    grammar: "Won't do (casual)",
    stem: 'あ',
    ending: 'ない',
    englishForm: '-to',
    englishStem: "I won't ",
    lesson: '2/9/4/3',
    form: informal
  },
  ta: {
    grammar: 'Did (casual)',
    stem: 'た',
    ending: '',
    englishForm: 'VBD',
    englishStem: 'I ',
    lesson: '2/9/4/6',
    form: informal
  },
  nakatta: {
    grammar: "Didn't do (casual)",
    stem: 'あ',
    ending: 'なかった',
    englishForm: '-to',
    englishStem: "I didn't ",
    lesson: '2/9/4/3',
    form: informal
  },
  te: {
    grammar: 'Do (command)',
    stem: 'て',
    ending: '',
    englishForm: '-to',
    englishStem: '',
    lesson: '3/3/4/1',
    form: command
  },
  nai_de: {
    grammar: "Don't (command)",
    stem: 'あ',
    ending: 'ないで',
    englishForm: '-to',
    englishStem: "Don't ",
    lesson: '3/3/4/2',
    form: command
  },
  te_imasu: {
    grammar: 'Is doing (polite)',
    stem: 'て',
    ending: 'います',
    alternate_endings: ['ます'],
    englishForm: 'VBG',
    englishStem: "I'm ",
    lesson: '3/5/5/1',
    form: polite
  },
  te_imasen: {
    grammar: "Isn't doing (polite)",
    stem: 'て',
    ending: 'いません',
    alternate_endings: ['ません'],
    englishForm: 'VBG',
    englishStem: "I'm not ",
    lesson: '3/5/5/1',
    form: polite
  },
  te_imashita: {
    grammar: 'Was doing (polite)',
    stem: 'て',
    ending: 'いました',
    alternate_endings: ['ました'],
    englishForm: 'VBG',
    englishStem: 'I was ',
    lesson: '3/5/5/1',
    form: polite
  },
  te_imasen_deshita: {
    grammar: "Wasn't doing (polite)",
    stem: 'て',
    ending: 'いませんでした',
    alternate_endings: ['ませんでした'],
    englishForm: 'VBG',
    englishStem: "I wasn't ",
    lesson: '3/5/5/1',
    form: polite
  },
  te_iru: {
    grammar: 'Is doing (casual)',
    stem: 'て',
    ending: 'いる',
    alternate_endings: ['る'],
    englishForm: 'VBG',
    englishStem: "I'm ",
    lesson: '3/5/5/1',
    form: informal
  },
  te_inai: {
    grammar: "Isn't doing (casual)",
    stem: 'て',
    ending: 'いない',
    alternate_endings: ['ない'],
    englishForm: 'VBG',
    englishStem: "I'm not ",
    lesson: '3/5/5/1',
    form: informal
  },
  te_ita: {
    grammar: 'Was doing (casual)',
    stem: 'て',
    ending: 'いた',
    alternate_endings: ['た'],
    englishForm: 'VBG',
    englishStem: 'I was ',
    lesson: '3/5/5/1',
    form: informal
  },
  te_inakatta: {
    grammar: "Wasn't doing (casual)",
    stem: 'て',
    ending: 'いなかった',
    alternate_endings: ['なかった'],
    englishForm: 'VBG',
    englishStem: "I wasn't ",
    lesson: '3/5/5/1',
    form: informal
  },
  tai: {
    grammar: 'Want to',
    stem: 'い',
    ending: 'たい',
    englishForm: 'infinitive',
    englishStem: 'I want ',
    lesson: '3/9/5/3'
  },
  takunai: {
    grammar: "Don't want to",
    stem: 'い',
    ending: 'たくない',
    englishForm: 'infinitive',
    englishStem: "I don't want ",
    lesson: '3/9/5/3'
  },
  takatta: {
    grammar: 'Wanted to',
    stem: 'い',
    ending: 'たかった',
    englishForm: 'infinitive',
    englishStem: 'I wanted ',
    lesson: '3/9/5/3'
  },
  takunakatta: {
    grammar: "Didn't want to",
    stem: 'い',
    ending: 'たくなかった',
    englishForm: 'infinitive',
    englishStem: "I didn't want ",
    lesson: '3/9/5/3'
  },
  eru: {
    grammar: 'Can do',
    stem: 'potential',
    ending: 'る',
    alternate_endings: ['ます'],
    englishForm: 'infinitive',
    englishStem: "I'm able ",
    lesson: '3/13/5/1'
  },
  enai: {
    grammar: "Can't do",
    stem: 'potential',
    ending: 'ない',
    alternate_endings: ['ません'],
    englishForm: 'infinitive',
    englishStem: "I'm not able ",
    lesson: '3/13/5/1'
  },
  eta: {
    grammar: 'Was able to do',
    stem: 'potential',
    ending: 'た',
    alternate_endings: ['ました'],
    englishForm: 'infinitive',
    englishStem: 'I was able ',
    lesson: '3/13/5/1'
  },
  enakatta: {
    grammar: "Wasn't able to do",
    stem: 'potential',
    ending: 'なかった',
    alternate_endings: ['ませんでした'],
    englishForm: 'infinitive',
    englishStem: "I wasn't able ",
    lesson: '3/13/5/1'
  },
  ta_koto_ga_aru: {
    grammar: 'Have done before',
    stem: 'た',
    ending: 'ことがある',
    alternate_endings: ['ことがあります', 'ことある', 'ことあります'],
    englishForm: 'VBN',
    englishStem: "I've ",
    lesson: '4/4/6/5'
  },
  ta_koto_ga_nai: {
    grammar: 'Have never',
    stem: 'た',
    ending: 'ことがない',
    alternate_endings: ['ことがありません', 'ことない', 'ことありません'],
    englishForm: 'VBN',
    englishStem: "I've never ",
    lesson: '4/4/6/5'
  },
  temo_ii: {
    grammar: "It's okay to",
    stem: 'て',
    ending: 'もいい',
    alternate_endings: ['いい'],
    englishForm: 'infinitive',
    englishStem: "It's okay ",
    lesson: '4/6/4/2'
  },
  tewa_ikemasen: {
    grammar: "It's not okay to (polite)",
    stem: 'て',
    ending: 'はいけません',
    englishForm: 'infinitive',
    englishStem: "It's not okay ",
    lesson: '4/6/4/3',
    form: polite
  },
  cha_dame: {
    grammar: "It's not okay to (casual)",
    stem: 'ちゃ',
    ending: 'だめ',
    englishForm: 'infinitive',
    englishStem: "It's not okay ",
    lesson: '4/6/4/4',
    form: informal
  },
  ni_iku: {
    grammar: 'Go to do',
    stem: 'い',
    ending: 'にいく',
    alternate_endings: ['にいきます'],
    englishForm: '-to',
    englishStem: "I'm gonna go ",
    lesson: '4/9/5/1'
  },
  ni_kuru: {
    grammar: 'Come to do',
    stem: 'い',
    ending: 'にくる',
    alternate_endings: ['にきます'],
    englishForm: '-to',
    englishStem: "I'm gonna come ",
    lesson: '4/9/5/1'
  },
  tari_shimasu: {
    grammar: 'Do things like...',
    stem: 'た',
    ending: 'りします',
    alternate_endings: ['りする'],
    englishForm: 'VBG',
    englishStem: 'I do things like ',
    lesson: '4/9/5/3'
  },
  u_mae: {
    grammar: 'Before doing',
    stem: 'う',
    ending: 'まえ',
    englishForm: 'VBG',
    englishStem: 'Before ',
    lesson: '4/10/6/4'
  },
  ta_ato: {
    grammar: 'After doing',
    stem: 'た',
    ending: 'あと',
    englishForm: 'VBG',
    englishStem: 'After ',
    lesson: '4/10/6/4'
  },
  ta_hou_ga_ii: {
    grammar: 'Should do',
    stem: 'た',
    ending: 'ほうがいい',
    englishForm: '-to',
    englishStem: 'You should ',
    lesson: '4/11/7/1'
  },
  nai_hou_ga_ii: {
    grammar: "Shouldn't do",
    stem: 'あ',
    ending: 'ないほうがいい',
    englishForm: '-to',
    englishStem: "You shouldn't ",
    lesson: '4/11/7/2'
  },
  nara: {
    grammar: 'If (なら)',
    stem: 'う',
    ending: 'なら',
    englishForm: '-to',
    englishStem: 'If I ',
    lesson: '4/11/7/4',
    form: 'なら conjugation'
  },
  tara: {
    grammar: 'If/when (たら)',
    stem: 'た',
    ending: 'ら',
    englishForm: '-to',
    englishStem: 'If I ',
    lesson: '4/11/7/5',
    form: 'たら conjugation'
  },
  te_hoshii: {
    grammar: 'I want you to do',
    stem: 'て',
    ending: 'ほしい',
    englishForm: 'infinitive',
    englishStem: 'I want you ',
    lesson: '4/13/5/1'
  },
  nai_de_hoshii: {
    grammar: "I don't want you to do (ないで～)",
    stem: 'あ',
    ending: 'ないでほしい',
    englishForm: 'infinitive',
    englishStem: "I don't want you ",
    lesson: '4/13/5/2',
    form: 'ないで～ conjugation'
  },
  te_hoshikunai: {
    grammar: "I don't want you to do (て～)",
    stem: 'て',
    ending: 'ほしくない',
    englishForm: 'infinitive',
    englishStem: "I don't want you ",
    lesson: '4/13/5/2',
    form: 'て～ conjugation'
  },
  te_miru: {
    grammar: 'Try to do (～みる)',
    stem: 'て',
    ending: 'みる',
    alternate_endings: ['みます'],
    englishForm: 'infinitive',
    englishStem: "I'll try ",
    lesson: '5/1/6/4',
    form: '～みる conjugation'
  },
  nagara: {
    grammar: 'While doing',
    stem: 'い',
    ending: 'ながら',
    englishForm: 'VBG',
    englishStem: 'While ',
    lesson: '5/2/5/4'
  },
  tagaru: {
    grammar: 'Someone else wants to do',
    stem: 'い',
    ending: 'たがる',
    alternate_endings: ['たがります'],
    englishForm: 'infinitive',
    englishStem: 'They want ',
    lesson: '5/3/6/5'
  },
  te_shimau: {
    grammar: 'End up doing (polite)',
    stem: 'て',
    ending: 'しまう',
    alternate_endings: ['しまいます'],
    englishForm: 'VBG',
    englishStem: "I'll end up ",
    lesson: '5/5/4/1',
    form: polite
  },
  chau: {
    grammar: 'End up doing (casual)',
    stem: 'ちゃ',
    ending: 'う',
    alternate_endings: ['います'],
    englishForm: 'VBG',
    englishStem: "I'll end up ",
    lesson: '5/5/4/6',
    form: informal
  },
  nakereba_narimasen: {
    grammar: 'Have to do (なければ～)',
    stem: 'あ',
    ending: 'なければなりません',
    alternate_endings: ['なければならない'],
    englishForm: 'infinitive',
    englishStem: 'I have ',
    lesson: '5/7/5/1',
    form: 'なければ～ conjugation'
  },
  nai_to_ikemasen: {
    grammar: 'Have to do (ないと～)',
    stem: 'あ',
    ending: 'ないといけません',
    alternate_endings: ['ないといけない'],
    englishForm: 'infinitive',
    englishStem: 'I have ',
    lesson: '5/7/5/1',
    form: 'ないと～ conjugation'
  },
  ou_to_suru: {
    grammar: 'Try to do (～する)',
    stem: 'お',
    ending: 'とする',
    alternate_endings: ['とします'],
    englishForm: 'infinitive',
    englishStem: "I'll try ",
    lesson: '5/8/4/2',
    form: '～する conjugation'
  },
  temo: {
    grammar: 'Even if',
    stem: 'て',
    ending: 'も',
    englishForm: '-to',
    englishStem: 'Even if I ',
    lesson: '5/13/6/6'
  },
}

export const kanaForms = {
  あ: Array.from('わたらばまなかがさ'),
  い: Array.from('いちりびみにきぎし'),
  う: Array.from('うつるぶむぬくぐす'),
  え: Array.from('えてれべめねけげせ'),
  お: Array.from('おとろぼものこごそ'),
  て: ['って', 'んで', 'いて', 'いで', 'して'],
  た: ['った', 'んだ', 'いた', 'いだ', 'した']
}

const create_あ_い_form = (verb: string, form: 'あ' | 'い', ichidan: boolean): string => {
  const stem = R.init(verb)
  if (verb === 'する') return 'し'
  if (verb === '来る') return '来'
  if (verb === 'くる') return form === 'あ' ? 'こ' : 'き'
  if (verb === '来(く)る') return form === 'あ' ? '来(こ)' : '来(き)'
  if (ichidan) return stem

  const ending = kanaForms[form][kanaForms.う.indexOf(R.last(verb))]
  return (verb === 'ある' && form === 'あ') ? R.tail(ending) : stem + ending
}

const create_え_form = (verb: string, form: 'え' | 'potential', ichidan: boolean) => {
  const stem = R.init(verb)
  if (verb === 'する' && form === 'potential') return 'でき'
  if (verb === '来る' && form === 'potential') return '来られ'
  if (verb === 'くる' && form === 'potential') return 'こられ'
  if (verb === '来(く)る' && form === 'potential') return '来(こ)られ'
  if (ichidan && form === 'potential') return `${stem}られ`

  const ending = kanaForms.え[kanaForms.う.indexOf(R.last(verb))]
  return stem + ending
}

const create_お_form = (verb: string, ichidan: boolean): string => {
  const stem = R.init(verb)
  if (verb === 'する') return 'しよう'
  if (verb === '来る') return '来よう'
  if (verb === 'くる') return 'こよう'
  if (verb === '来(く)る') return '来(こ)よう'
  if (ichidan) return `${stem}よう`

  const ending = kanaForms.お[kanaForms.う.indexOf(R.last(verb))]
  return `${stem + ending}う`
}

const create_て_and_た_form = (verb: string, form: 'て' | 'た', ichidan: boolean): string => {
  const stem = R.init(verb)
  if (verb === '行く') return `行っ${form}`
  if (verb === 'いく') return `いっ${form}`
  if (verb === '行(い)く') return `行(い)っ${form}`
  if (verb === '来る') return `来${form}`
  if (verb === 'くる') return `き${form}`
  if (verb === '来(く)る') return `来(き)${form}`
  if (verb === 'する') return `し${form}`
  
  if (ichidan) return `${stem}${form}`

  const endingIndex = kanaForms.う.indexOf(R.last(verb))
  
  if (endingIndex < 3) return `${stem}${kanaForms[form][0]}`
  if (endingIndex < 6) return `${stem}${kanaForms[form][1]}`

  return stem + kanaForms[form][endingIndex - 4]
}

const create_ちゃ_and_じゃ_form = (verb: string, ichidan: boolean): string => {
  const teForm = create_て_and_た_form(verb, 'て', ichidan)
  const ending = R.last(teForm)

  return R.init(teForm) + (ending === 'て' ? 'ちゃ' : 'じゃ')
}

export const conjugate = (verb: Verb, conjugation: Conjugation, output?: 'base_text' | 'kanji' | 'kana', alternateEnding?: string): string => {
  const { base_text, kana, ichidan } = verbData[verb]
  const { stem, ending } = conjugations[conjugation]

  let _verb: string = base_text
  if (output === 'kanji') _verb = verb
  if (output === 'kana') _verb = kana

  const _ending = alternateEnding || ending

  switch (stem) {
    case 'い':
    case 'あ':
      return create_あ_い_form(_verb, stem, ichidan) + _ending
    case 'て':
    case 'た':
      return create_て_and_た_form(_verb, stem, ichidan) + _ending
    case 'え':
      return create_え_form(_verb, 'え', ichidan) + _ending
    case 'potential':
      return create_え_form(_verb, 'potential', ichidan) + _ending
    case 'ちゃ':
      return create_ちゃ_and_じゃ_form(_verb, ichidan) + _ending
    case 'お':
      return create_お_form(_verb, ichidan) + _ending
    default:
      return _verb + _ending
  }
}

export const conjugateAlternates = (verb: Verb, conjugation: Conjugation, output?: 'base_text' | 'kanji' | 'kana'): string[] => {
  const { alternate_endings } = conjugations[conjugation]
  if (!alternate_endings) return []

  return alternate_endings.map((ending) => conjugate(verb, conjugation, output, ending))
}

export const getLessonGrammar = (course: string, lesson: string, type: Selection): Conjugation[] => {
  const grammar: Conjugation[] = []
  const grammarKeys = Object.keys(conjugations) as Conjugation[]
  const courseNum = parseInt(course, 10)
  const lessonNum = parseInt(lesson, 10)

  grammarKeys.forEach((key) => {
    const [grammarCourseNum, grammarLessonNum] = conjugations[key].lesson.split('/').map((num) => parseInt(num, 10))

    let shouldAdd = false
    if (type === 'all') shouldAdd = true
    if (type === 'lesson') shouldAdd = courseNum === grammarCourseNum && lessonNum === grammarLessonNum
    if (type === 'learned') shouldAdd = courseNum > grammarCourseNum || (courseNum === grammarCourseNum && lessonNum > grammarLessonNum)
    if (type === 'lesson-learned') shouldAdd = courseNum > grammarCourseNum || (courseNum === grammarCourseNum && lessonNum >= grammarLessonNum)

    if (shouldAdd) grammar.push(key)
  })
  
  return grammar
}

export const getAllGrammar = (): Conjugation[] => Object.keys(conjugations) as Conjugation[]

export const getGrammarData = (conjugation: Conjugation): ConjugationData => conjugations[conjugation]
