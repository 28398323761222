import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as R from 'ramda'

import PubSub from 'pubsub-js'

import { Icon } from 'semantic-ui-react'

import {
  TableWidgetContainer,
  TableSubtitle,
  TitleHeader,
  TableGrid,
  TableCell,
  CellContent,
  RightDashedLine,
  TopDashedLine,
  BottomDashedLine,
  TablePlayAll,
  PlayAllDiv,
  OverflowFade,
  RowSeparator
} from './styles/TableWidgetStyles'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import SoundRenderer from './SoundRenderer'
import PlaceholderWidget from './PlaceholderWidget'

import useMobile from '../../../hooks/useMobile'
import useTablePlayAll from '../hooks/useTablePlayAll'
import useStudyMode from '../hooks/useStudyMode'

import getCourseInfo, { getTitle } from '../../../util/courseInfo'

const available_languages = [
  'Japanese',
  'Korean',
  'Chinese',
  'English',
  'German',
  'Spanish',
  '英語(えいご)',
  '日本語(にほんご)',
  '漢字(かんじ)',
] // TODO: from API?

const nihongo = '日(に)本(ほん)語(ご)'
const kanji = '漢(かん)字(じ)'
const eigo = '英(えい)語(ご)'
const unlessNil = R.unless(R.isNil)
const hasNihongo = unlessNil(R.includes(nihongo))
const hasKanji = unlessNil(R.includes(kanji))
const hasEigo = unlessNil(R.includes(eigo))
const hasNihongoOrKanji = R.either(hasNihongo, hasKanji)
const anyJp = R.compose(hasNihongoOrKanji, R.prop('text'), R.head)
const anyEn = R.compose(hasEigo, R.prop('text'), R.head)

const TableWidget = (props) => {
  const [widget, setWidget] = useState(props.widget)
  const [table, setTable] = useState(props.widget.table)
  const [tableCellCount, setTableCellCount] = useState(0)
  const [playsSound, setPlaysSound] = useState(false)
  const [coloredHeader, setColoredHeader] = useState(false)
  const [forceIndent, setForceIndent] = useState(false)
  const [forceBoldHeader, setForceBoldHeader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [headerIsEmpty, setHeaderIsEmpty] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [columnFormat, setColumnFormat] = useState(null)
  const [emptyRows, setEmptyRows] = useState([])
  const isMobile = useMobile()

  const [pageChangeTriggered, setPageChangeTriggered] = useState(false)
  const pcRef = useRef(pageChangeTriggered)
  const { playAll, play, playAllList, register, next, debugMessageQueue } = useTablePlayAll()

  const { hideEnglish, hideOtherLang } = useStudyMode()
  let enIdx = table?.header.indexOf('English')
  let jpIdx = table?.header.indexOf('Japanese')
  if (jpIdx === -1) jpIdx = R.findIndex(anyJp, table?.header)
  if (enIdx === -1) enIdx = R.findIndex(anyEn, table?.header)

  const horizontalPlayAll = table?.horizontalPlayAll || false

  // if none of that worked, we can try to find a column containing a sound renderer object
  // instead of text, and the other column we can default to english
  if (jpIdx === -1) {
    try {
      jpIdx = R.findIndex(R.o(R.equals('Array'), R.type), table?.header)
    } catch (e) {
      // console.log(e)
    }

    // try the first row if still not working
    try {
      if (jpIdx === -1) {
        jpIdx = R.findIndex(R.o(R.equals('Array'), R.type), table?.rows?.[0])
      }
    } catch (e) {
      // console.log(e)
    }

    if (jpIdx > -1) {
      if (jpIdx === 0) enIdx = 1
      else enIdx = 0
    }
  }

  // useEffect(() => {
  //   console.log(playAll)
  //   console.log(playAllList)
  // }, [playAll, playAllList])

  // TODO: if table is more than three columns, multiple may need to be marked for study mode

  // showHeader = HeaderAsRow
  const isHeaderRow = !table.showHeader && !table.title
  const isSubheaderRow = !table.showHeader && table.title

  const columnStyles = table.columnStyles ?? false
  const courseColor = getCourseInfo(getTitle(props.course)).alt_color_1
  const courseColor4 = getCourseInfo(getTitle(props.course)).alt_color_4

  const gridTemplateColumns = columnFormat?.toString().replace(/,/g, ' ')

  // stuff for horizontal scrolling indicator
  const scrollRef = useRef()
  const hasHorizontalScroll = scrollRef?.current?.scrollWidth > scrollRef?.current?.clientWidth
  const scrollSpace = !scrollRef?.current
    ? undefined
    : scrollRef.current.scrollWidth - scrollRef.current.clientWidth
  const fadeOpacity = scrollSpace
    ? (scrollSpace - scrollPosition) / scrollSpace
    : 1

  // adds '' to empty cells to maintain proper table structure
  // SIDE EFFECT: checks for / sets empty rows
  const fixTable = () => {
    if (!widget) return
    const _emptyRows = []
    
    widget.table.rows.forEach((row, idx) => {
      while (row.length < widget.table.columns) {
        row.push('')
      }
      
      const rowIsEmpty = !row.filter((cell) => cell).length
      if (rowIsEmpty) _emptyRows.push(idx)
    })
    
    setEmptyRows(_emptyRows)
  }

  const checkHeader = () => {
    if (!widget) return

    const result = R.none(Boolean, widget.table.header)
    setHeaderIsEmpty(result)
  }
  
  // if there's a RowSeparator, we should adjust to white on the next background
  // I think EvenRow is a misnomer btw, it should be OddRow
  const calculateEvenRow = (idxRow) => {
    if (!emptyRows.length) return idxRow % 2

    let mostRecentEmptyRow = -1
    emptyRows.forEach((emptyRow) => {
      if (emptyRow < idxRow) mostRecentEmptyRow = emptyRow
    })
    
    return (idxRow - mostRecentEmptyRow + 1) % 2
  }

  useEffect(() => {
    setTable(widget?.table)
    setPlaysSound(widget?.plays_sound)
    setForceIndent(widget?.table.forceIndent)
    setForceBoldHeader(widget?.table.forceBoldHeader)
    if (widget?.table) {
      const isLanguageHeader = widget.table?.header
        .filter((content) => available_languages?.includes(content))
      
      setColoredHeader(!!isLanguageHeader?.length)
    }
    setTableCellCount(widget.table.rows?.[0]?.length + (horizontalPlayAll ? 1 : 0))
    fixTable()
    checkHeader()
    setColumnFormat(() => {
      const gridColumnsArray = Array.apply(null, Array(widget.table.columns + (horizontalPlayAll ? 1 : 0)))
      const format = gridColumnsArray.map((_, idx) => columnStyles ? columnStyles[idx]?.columnSize ?? '1fr' : '1fr')

      if (horizontalPlayAll) {
        format[format.length - 1] = 'auto'
      }

      return format
    })
  }, [widget])

  if (loading) return <PlaceholderWidget widget_type="TableWidget" />
  return (
    <WidgetWrapper
      data-t={`widget:table-id:${widget._id}`}
      widget={{ widgetType: 'TableWidget', widgetId: widget._id }}
    >
      {table ? (
        <TableWidgetContainer isMobile={isMobile} forceIndent={forceIndent}>
          {table.title && (
            <TitleHeader courseColor={courseColor}>
              <h2>
                <SoundRenderer text={table.title} />
              </h2>
              {table.subtitle && (
                <TableSubtitle isHeader>
                  <SoundRenderer text={table.subtitle} />
                </TableSubtitle>
              )}
            </TitleHeader>
          )}
          <OverflowFade show={hasHorizontalScroll} opacity={fadeOpacity} />
          <TableGrid
            columns={table.columns + (horizontalPlayAll ? 1 : 0)}
            gridTemplateColumns={gridTemplateColumns}
            isMobile={isMobile}
            ref={scrollRef}
            onScroll={() => setScrollPosition(scrollRef?.current?.scrollLeft)}
          >
            {!headerIsEmpty && table.header?.map((content, idx) => (
              // Table header
              <TableCell
                key={`tableh-${idx}`}
                courseColor={courseColor}
                courseColor4={courseColor4}
                isHeaderRow={isHeaderRow}
                isSubheaderRow={isSubheaderRow}
                isLastColumn={!horizontalPlayAll && idx + 1 === table.columns}
                isEvenRow
                columnIdx={idx}
                columns={table.columns}
                isMobile={isMobile}
                columnStyles={columnStyles ? columnStyles[idx] : false}
                boldHeader={forceBoldHeader}
              >
                {table.title && (columnStyles
                  && columnStyles[idx]?.backgroundColor) && <TopDashedLine />
                }
                {(table.showHeader || (columnStyles && columnStyles[idx]?.backgroundColor))
                  && <BottomDashedLine white={columnStyles && columnStyles[idx]?.backgroundColor} />
                }
                <CellContent>
                  <SoundRenderer
                    text={content}
                    playingNum={playAllList[idx]?.current}
                    num={0}
                    onSound={(row, noIcon, src) => register(idx, row, src)}
                    ping={() => next(idx)}
                    placeholder={
                      !isHeaderRow && !isSubheaderRow && !columnStyles[idx]?.backgroundColor
                      && ((hideEnglish && content && typeof content === 'string') || (hideOtherLang && typeof content !== 'string'))
                    }
                    bold={isHeaderRow}
                  />
                </CellContent>
                {(idx !== (table.columns - 1) && isHeaderRow)
                  && <RightDashedLine />
                }
              </TableCell>
            ))}
            {/* Extra cell if horizontal play all in header */}
            {!headerIsEmpty && horizontalPlayAll && (
              <TableCell
                key={`tableh-${table.columns}`}
                courseColor={courseColor}
                courseColor4={courseColor4}
                isHeaderRow={isHeaderRow}
                isSubheaderRow={isSubheaderRow}
                isLastColumn
                isEvenRow
                columnIdx={table.columns}
                columns={table.columns}
                isMobile={isMobile}
                columnStyles={columnStyles ? columnStyles[table.columns] : false}
              >
                {table.title && (columnStyles
                  && columnStyles[table.columns]?.backgroundColor) && <TopDashedLine />
                }
                {(table.showHeader
                  || (columnStyles && columnStyles[table.columns]?.backgroundColor))
                  && (
                    <BottomDashedLine
                      white={columnStyles && columnStyles[table.columns]?.backgroundColor}
                    />
                  )
                }
                <CellContent>
                  <SoundRenderer
                    text={playAll ? 'Play All' : 'Play'}
                    playingNum={playAllList[table.columns]?.current}
                    num={0}
                    onSound={(row, noIcon, src) => register(table.columns, row, src)}
                    ping={() => next(table.columns)}
                  />
                </CellContent>
                <RightDashedLine />
              </TableCell>
            )}
            {/* Table rows */}
            {table.rows?.map((row, idxRow) => {
              if (emptyRows.includes(idxRow)) return <RowSeparator courseColor={courseColor} />
              
              return (
                <React.Fragment
                  key={`${widget._id}-tabler-${idxRow}`}
                >
                  {row?.map((content, idxCell) => (
                    <TableCell
                      key={`${widget._id}-tablec-${idxRow}-${idxCell}`}
                      courseColor={courseColor}
                      courseColor4={courseColor4}
                      isLastColumn={!horizontalPlayAll && idxCell + 1 === table.columns}
                      isEvenRow={calculateEvenRow(idxRow)}
                      columnIdx={idxCell}
                      columns={table.columns + (horizontalPlayAll ? 1 : 0)}
                      isMobile={isMobile}
                      columnStyles={columnStyles ? columnStyles[idxCell] : false}
                      rowStyles={table.rowStyles ? table.rowStyles[idxRow] : false}
                    >
                      {table.title && headerIsEmpty && idxRow === 0
                        && (columnStyles && columnStyles[idxCell]) && <TopDashedLine />
                      }
                      <CellContent>
                        <SoundRenderer
                          playingNum={playAllList[horizontalPlayAll ? idxRow : idxCell]?.current}
                          num={(horizontalPlayAll ? idxCell : idxRow) + 1}
                          onSound={(soundRow, noIcon, src) => register(horizontalPlayAll ? idxRow : idxCell, soundRow, src)}
                          ping={() => next(horizontalPlayAll ? idxRow : idxCell)}
                          text={content}
                          placeholder={!columnStyles[idxCell]?.backgroundColor
                            && ((hideEnglish && content && typeof content === 'string')
                            || (hideOtherLang && typeof content !== 'string'))}
                          index={horizontalPlayAll ? idxCell : idxRow}
                          translation={row.length > 1 && (idxCell === 0 ? row[1] : row[0])}
                          widgetType='TableWidget'
                        />
                      </CellContent>
                      {idxRow !== table.rows.length - 1
                      && !table.linkedRows?.includes(idxRow)
                      && !emptyRows.includes(idxRow + 1)
                      && (
                        <BottomDashedLine
                          white={columnStyles && columnStyles[idxCell]?.backgroundColor} />
                      )}
                    </TableCell>
                  ))}
                  {/* Extra cell in row if horizontal play all for play all button */}
                  {horizontalPlayAll && (
                    <TableCell
                      key={`${widget._id}-tablec-${idxRow}-${table.columns}`}
                      courseColor={courseColor}
                      courseColor4={courseColor4}
                      isLastColumn
                      isEvenRow={calculateEvenRow(idxRow)}
                      columnIdx={table.columns}
                      columns={table.columns}
                      isMobile={isMobile}
                      columnStyles={columnStyles ? columnStyles[table.columns] : false}
                    >
                      {table.title && headerIsEmpty && idxRow === 0
                        && (columnStyles && columnStyles[table.columns]) && <TopDashedLine />
                      }
                      <CellContent>
                        {playAllList[idxRow]?.current !== undefined && (
                          <TablePlayAll
                            className={playAllList[idxRow]?.current === -1 ? 'fz-play-all' : 'fz-stop-all'}
                            onClick={() => play(widget._id, idxRow)}
                          >
                            <Icon
                              style={{ marginTop: '-6px' }}
                              name={playAllList[idxRow]?.current === -1 ? 'play' : 'stop'}
                            /> All
                          </TablePlayAll>
                        )}
                      </CellContent>
                      {idxRow !== table.rows.length - 1
                      && !table.linkedRows?.includes(idxRow)
                      && (
                        <BottomDashedLine
                          white={columnStyles && columnStyles[table.columns]?.backgroundColor} />
                      )}
                    </TableCell>
                  )}
                </React.Fragment>
              )
            })}
            {/* Extra row at bottom of table for play all button(s) */}
            {!horizontalPlayAll && playAllList.reduce((p, c) => (c?.pa ? true : p), false) && (
              <>
                {playAllList?.map((pa, idx) => (
                  <PlayAllDiv
                    key={`${widget._id}-pad-${idx}`}
                    columns={table.colums}
                    columnIdx={idx}
                    isMobile={isMobile}
                    isHeaderColumn={
                      (columnStyles && columnStyles[idx]?.backgroundColor)
                    }
                    courseColor={courseColor}
                  >
                    {pa?.pa && Object.keys(pa.entries)?.length > 1 && (
                      <TablePlayAll
                        className={pa.current === -1 ? 'fz-play-all' : 'fz-stop-all'}
                        onClick={() => play(widget._id, idx)}
                      >
                        <Icon
                          style={{ marginTop: '-6px' }}
                          name={playAllList[idx]?.current === -1 ? 'play' : 'stop'}
                        /> All
                      </TablePlayAll>
                    )}
                  </PlayAllDiv>
                ))}
              </>
            )}
          </TableGrid>
        </TableWidgetContainer>
      ) : (
        <PlaceholderWidget widget_type="TableWidget" />
      )}
    </WidgetWrapper>
  )
}

export default TableWidget
