import { Icon, Popup } from 'semantic-ui-react'

import SoundRenderer from '../widgets/SoundRenderer'
import useMobile from 'src/hooks/useMobile'

const TeacherNote = ({ text, white }) => {
  const isMobile = useMobile()
  const fontSize = isMobile ? '1rem' : '1.5rem'
  
  return (
    <>
      {text && (
        <Popup
          wide='very'
          style={{ fontFamily: 'Milliard Book', fontSize, borderRadius: '10px', whiteSpace: 'pre-line' }}
          content={<SoundRenderer text={text} />}
          position='right center'
          on='click'
          trigger={
            <Icon color={white ? 'white' : 'blue'}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              name='graduation cap'
            />
          }
        />
      )}
    </>
  )
}

export default TeacherNote
