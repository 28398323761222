import { useCallback, useContext, useEffect, useRef, useState } from 'react'

import { AudioContext } from 'src/contexts/AudioContext'

const useTablePlayAll = () => {
  const [playAll, setPlayAll] = useState([])
  const [playAllWidget, setPlayAllWidget] = useState('')

  const lastColPlayed = useRef(-1)
  const lastRowPlayed = useRef(-1)

  // context containing a global audo object and a setter for its src
  const { audio, setAudioSrc, clickTriggered, setClickTriggered, paWidget, setPAWidget, changeAudioSrc, playAudio, pauseAudio, audioRef } = useContext(AudioContext)

  // columns
  const [playAllList, setPlayAllList] = useState([])
  const playAllListRef = useRef(playAllList)

  const [debugMessageQueue, setDebugMessageQueue] = useState([])
  const [debugMessage, setDebugMessage] = useState('')
  const [thisWidgetClicked, setThisWidgetClicked] = useState(false)

  const nextKey = (current, entries) => {
    // console.log('current', current)
    // console.log('entries', entries)
    const keys = Object.keys(entries).map((k) => parseInt(k, 10)).filter((k) => entries[k])
    const _nextKey = keys.reduce((p, c) => (current < c && p === -1 ? c : p), -1)

    // console.log('next key:', _nextKey)
    return _nextKey
  }

  // when debug message changes, add it to the queue
  useEffect(() => {
    if (!debugMessage) return
    // console.log(debugMessage)
    setDebugMessageQueue((prev) => {
      const newQueue = [...prev]
      newQueue.push(debugMessage)
      return newQueue
    })
  }, [debugMessage])

  // debug message queue should roll over with a max length of 10 messages
  useEffect(() => {
    if (debugMessageQueue.length > 10) {
      setDebugMessageQueue((prev) => {
        const newQueue = [...prev]
        newQueue.shift()
        return newQueue
      })
    }
  }, [debugMessageQueue])

  useEffect(() => {
    playAllListRef.current = playAllList
  }, [playAllList])

  useEffect(() => {
    // console.log(playAll)
  }, [playAll])

  const resetPlayAll = () => {
    setPlayAllList((prev) => {
      const newPA = [...prev]
      newPA.forEach((pa, idx) => {
        if (pa?.current >= 0) newPA[idx].current = -1
      })
      return newPA
    })

    // reset last row and col played
    lastColPlayed.current = -1
    lastRowPlayed.current = -1
  }

  useEffect(() => {
    // if we were the ones to click, ignore it but reset this widget clicked
    setClickTriggered(false)

    if (clickTriggered && thisWidgetClicked) {
      setDebugMessage('click triggered inside table play all and this widget clicked')
      setThisWidgetClicked(false)
      return
    }
    
    if (clickTriggered) {
      // console.log('click triggered inside table play all')
      setDebugMessage('click triggered inside table play all')
      resetPlayAll()
    }
  }, [clickTriggered])

  // watch for playAllList to change and set the onended callback on the audio to play the next sound if it exists
  useEffect(() => {
    setDebugMessage('play all table list changed')

    // find which play all list is playing
    // console.log(playAllListRef.current)
    const currentPlayAll = playAllListRef.current.find((pa) => pa?.current >= 0)
    // console.log('currentPlayAll', currentPlayAll)

    // if there is a current play all, stop the sound
    if (currentPlayAll) {
      try {
        // console.log('play all table list changed mid play-all')
        pauseAudio()
      } catch (e) {
        console.log(e)
      }
    }

    if (!currentPlayAll) {
      // console.log('no current play all; stopping playAllList useEffect')
      setDebugMessage('no current play all; stopping playAllList useEffect')
      return
    }
    const { current, entries } = currentPlayAll

    // whether the last row played was -1
    const lastRowWasMinusOne = lastRowPlayed.current === -1

    // if current is -1, it was terminated, so stop the sound
    // if the current sound is not the same as the one in the audio context, stop the sound
    if (current === -1 || (audio.src !== entries[current] && !lastRowWasMinusOne)) {
      setDebugMessage('current is -1 or audio src is not the same as the current sound')
      // console.log('current is -1 or audio src is not the same as the current sound, invalid')
      changeAudioSrc('INVALID')
      lastRowPlayed.current = -1
      return
    }

    // if the current sound is the same as the one in the audio context, play it
    setDebugMessage('setting audio source to' + entries[current] + 'and playing it')
    // const nextCurrent = nextKey(current, entries)
    // console.log('setting audio src in useeffect')
    changeAudioSrc(entries[current])

    // set onended of the audio context to play the next sound in the list if it exists
    audio.onended = () => {
      const nc = nextKey(current, entries)
      if (entries[nc]) {
        // console.log('setting audio src in onended', entries[nc])
        // console.log('audio src is the same', audio.src === entries[nc])

        // try triggering refresh of sound
        if (audio.src === entries[nc]) changeAudioSrc('INVALID')
        changeAudioSrc(entries[nc])

        // update the play all list to reflect the new current sound
        setPlayAllList((prev) => {
          const newPA = [...prev]
          newPA.forEach((pa, idx) => {
            if (pa?.current >= 0) newPA[idx].current = nc
          })
          return newPA
        })

        if (audio.readyState >= 4) {
          try {
            playAudio()
          } catch (e) {
            console.log(e)
          }
        } else {
          audio.onloadeddata = () => setTimeout(() => {
            try {
              playAudio()
            } catch (e) {
              console.log(e)
            }
          }, 300)
        }
      } else {
        // console.log('no next sound; setting audio src to INVALID')
        changeAudioSrc('INVALID')
        resetPlayAll()
        lastRowPlayed.current = -1
      }
    }

    // console.log(audio)
    // in case we start the same sound over again, a load isn't necessary to play it
    if (audioRef.current.readyState >= 4) {
      setDebugMessage('already loaded the sound; just replaying it again')
      try {
        playAudio()
      } catch (e) {
        console.log(e)
      }
    } else {
      setDebugMessage('setting on loaded data, audio:', audioRef.current)
      audioRef.current.onloadeddata = () => {
        try {
          playAudio()
        } catch (e) {
          console.log(e)
        }
      }
    }
  }, [playAllList])

  useEffect(() => {
    // grab the source of whatever is currently playing in table play all, if any
    const currentPlayAll = playAllListRef.current.find((pa) => pa?.current >= 0)

    if (currentPlayAll === undefined) return

    // console.log(currentPlayAll)

    const { current, entries } = currentPlayAll

    // console.log('paWidget === playAllWidget', paWidget === playAllWidget)
    // console.log('entries[current] === audio.src', entries[current] === audio.src)
    // console.log('entries', entries)
    // console.log('current', current)
    // console.log('entries[current]', entries[current])
    // console.log('audio.src', audio.src)

    // whether the string "INVALID" is in audio.src
    const invalidInSrc = audio.src.includes('INVALID')

    // ignore if there is no audio source or it's the same widget as this one already playing
    if (!audio.src || (paWidget === playAllWidget && !clickTriggered) || (entries[current] === audio.src || invalidInSrc)) {
      setDebugMessage('no audio source or same widget as this one already playing; skipping reset')
      return
    }

    setDebugMessage('audio source changed; resetting play all')

    resetPlayAll()
  }, [audio.src])

  // watch for paWidget to change; if it does and it's not the same widget as this play all hook is watching,
  // end this hook's playing by setting current to -1
  useEffect(() => {
    if (paWidget && paWidget !== playAllWidget) {
      resetPlayAll()
    }
  }, [paWidget])

  const register = (col, num, src) => {
    if (src && playAllListRef.current[col]?.entries[num]) {
      return
    }

    setPlayAll((pa) => Object.values({ ...pa, [col]: !!src }))
    setPlayAllList((pal) => {
      const paObj = {
        ...pal,
        [col]: { pa: true, current: -1, entries: { ...pal[col]?.entries, [num]: pal[col]?.entries?.[num] || src } },
      }
      // console.log(paObj)
      const paKeys = Object.keys(paObj)
      const newPA = []
      paKeys.forEach((k) => { newPA[k] = paObj[k] })

      /* eslint-disable  no-restricted-syntax */
      for (const k of Array(parseInt(col, 10)).keys()) {
        // console.log('key of newPA', newPA[k], k)
        if (!newPA[k]) newPA[k] = undefined
      }
      /* eslint-enable */

      // console.log('returning PA', newPA)
      return newPA
    })
  }

  const play = useCallback((widget, col) => {
    // console.log('triggering table play all')
    setThisWidgetClicked(true)

    // if already playing (current in any of the lists is >= 0), stop playing
    if (playAllListRef.current.some((pa) => pa?.current >= 0)) {
      pauseAudio()
      // console.log('setting audio src to INVALID some >= 0')
      changeAudioSrc('ALREADY_PLAYING')
      resetPlayAll()
      return
    }

    setClickTriggered(true)
    setPAWidget(widget)
    setPlayAllWidget(widget)
    // console.log('setting audio src to INVALID after if')
    changeAudioSrc('TABLE_PLAY_ALL')

    const newPAList = [...playAllListRef.current]
    if (newPAList[col].current >= 0) {
      newPAList[col].current = -1
    } else {
      let next = 0
      let biggestIndex = Object.keys(newPAList[col].entries).reduce((p, c) => (p > c ? p : c), 0)
      while (next < biggestIndex && !newPAList[col].entries[next]) {
        next = next + 1
      }
      newPAList[col].current = next
    }
    // console.log('setting play all inside of play')
    setPlayAllList(newPAList)

    // set autoplay on audio to true if we're on iOS
    if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
      audio.autoplay = true
    }
  }, [playAllList])

  const stop = (col) => {
    // console.log('triggering table play all stop')
    pauseAudio()
    // console.log('setting audio src to INVALID from stop')
    changeAudioSrc('TABLE_PLAY_ALL_STOP')
    const newPAList = [...playAllListRef.current]
    newPAList[col].current = -1
    // console.log('setting play all inside of stop')
    setPlayAllList(newPAList)
    resetPlayAll()
  }

  const next = (col) => {
    // console.log('triggering table play all next')
    const newPAList = [...playAllListRef.current]

    // end of play all
    if (newPAList[col].current === -1) return

    // console.log('setting play all inside of next')
    setPlayAllList((pal) => {
      const nextCurrent = nextKey(pal[col].current, pal[col].entries)
      // console.log('next current', nextCurrent)
      // console.log('pal[col].entries', pal[col].entries)
      return Object.values({
        ...pal,
        [col]: { ...pal[col], current: pal[col].entries[nextCurrent] ? nextCurrent : -1 },
      })
    })
  }

  return { playAll, play, stop, playAllList, register, next, debugMessageQueue }
}

export default useTablePlayAll
