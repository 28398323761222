import React, { useEffect, useState } from 'react'
import axios from 'src/api'
import { useHistory, withRouter, RouteComponentProps } from 'react-router-dom'

import useMobile from 'src/hooks/useMobile'

import { AskAQuestionBody, ViewTypesButton } from '../../styles/AskForm.styles'
import { Button, Divider, Form, Header, Icon, Label, TextArea } from 'semantic-ui-react'
import TagMenu from './TagMenu'
import ErrorReportModal from 'src/components/modals/ErrorReportModal'
import QuestionTypePicker from './QuestionTypePicker'

type PathParamsType = {
  id: string
}

type AaTFormProps = RouteComponentProps<PathParamsType> & {
  type?: 'ask' | 'bug'
  widgetId?: string
  path?: string
  onSubmitted?: () => void
  onFailed?: () => void
  modal?: boolean
}

const AskATeacherQuestionForm: React.FC<AaTFormProps> = (props) => {
  const [questionType, setQuestionType] = useState<'ask' | 'bug'>(props.type)
  const [questionTitle, setQuestionTitle] = useState('')
  const [questionBody, setQuestionBody] = useState('')
  const [tag, setTag] = useState(['misc', '', ''])

  const [titleError, setTitleError] = useState(false)
  const [bodyError, setBodyError] = useState(false)

  const history = useHistory()
  const isMobile = useMobile()

  const handleTitleInput = (value) => {
    if (value.length > 180) {
      setQuestionTitle(value.slice(0, 180))
      setTitleError(true)
      return
    }
    setQuestionTitle(value)
    setTitleError(false)
  }

  const submitQuestion = (title, body) => {
    setTitleError(questionTitle.trim() === '')
    setBodyError(questionBody.trim() === '')

    if (titleError || bodyError) return

    const _tag = tag.filter((t) => t !== '')

    axios.post('/questions', { title, body, tag: _tag, widgetId: props.widgetId, path: props.path })
      .then((res) => {
        if (res.data.success) {
          if (props.onSubmitted) props.onSubmitted()
          else history.push(`/ask/${res.data.question._id}`)
        } else if (props.onFailed) props.onFailed()
      })
      .catch((err) => console.error(err))
  }

  return (
    <>
      {!questionType && (<QuestionTypePicker onSelectedType={setQuestionType} />)}
      <ErrorReportModal
        forceOpen={questionType === 'bug'}
        setClosed={() => setQuestionType(undefined)}
      />
      {(questionType === 'ask') && !props.type && (
        <ViewTypesButton
          className='flex-center'
          onClick={() => setQuestionType(undefined)}
        >
          <Icon name='arrow circle left' fitted /><span>Change submission type</span>
        </ViewTypesButton>
      )}
      {questionType === 'ask' && (
        <AskAQuestionBody type={props.type} isMobile={isMobile}>
          <Form onSubmit={() => submitQuestion(questionTitle, questionBody)}>
            {props.type && <Divider style={{ marginTop: '30px', marginBottom: '-1px', paddingTop: '10px' }} />}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: '10px'
              }}
            >
              <Header>Question Title</Header>
              {!isMobile && <TagMenu tag={tag} setTag={setTag} modal={props.modal} />}
            </div>
            {titleError ? (
              <Label basic color='red' pointing='below'>
                Please enter a value less than 180 characters
              </Label>
            ) : (
              <></>
            )}
            <TextArea
              placeholder='Briefly summarize your question'
              onChange={(_, { value }) => handleTitleInput(value)}
              value={questionTitle}
            />
            <div style={{ marginBottom: '20px' }}></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                marginBottom: '10px'
              }}
            >
              <Header>Question Body</Header>
            </div>
            {bodyError ? (
              <Label basic color='red' pointing='below'>
                Please enter a value
              </Label>
            ) : (
              <></>
            )}
            <TextArea
              placeholder="What's your question?"
              onChange={(e) => setQuestionBody(e.target.value)}
              value={questionBody}
            />
            {isMobile && <TagMenu tag={tag} setTag={setTag} modal={props.modal} />}
            <div className='tw-py-4 tw-flex tw-flex-col tw-justify-center' style={{ paddingBottom: '0px' }}>
              <div className='tw-self-center'>
                <p>
                  Questions are hidden from the View Questions page until answered by a teacher.
                </p>
                <p>
                  It may take several days before a teacher responds.
                </p>
              </div>
              <div className='tw-self-center'>
                <Button>Submit</Button>
              </div>
            </div>
          </Form>
        </AskAQuestionBody>
      )}
    </>
  )
}

export default withRouter(AskATeacherQuestionForm)
