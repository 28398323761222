import React, { useCallback, useMemo, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Icon } from 'semantic-ui-react'

import { IconButton, ListInputDivider, ToolInput } from '../../parts'
import { InputRow, StylesPopup } from '../../styles'
import { AddRowButton, TableDeleteColumnTab, TableFinalRowDivider, TableToolRows } from '../../styles/Tool.styles'
import { FZTable } from 'src/types'

interface FinalRowDividerProps {
  addRow: () => void
  cellCount: number
  handleRemoveColumn: (arg0: number) => void
  shiftDeleteTab: boolean
}

const FinalRowDivider: React.FC<FinalRowDividerProps> = ({
  addRow,
  cellCount,
  handleRemoveColumn,
  shiftDeleteTab,
}) => {
  const cells = useMemo(() => {
    const value = []
    for (let i = 0; i < cellCount; i++) {
      value.push(i)
    }
    return value
  }, [cellCount])

  return (
    <TableFinalRowDivider>
      {cells.map((cell: number) => (
        <TableDeleteColumnTab
          key={cell}
          type='button'
          onClick={() => handleRemoveColumn(cell)}
          title={`Delete column ${cell + 1}`}
        >
          <Icon fitted name='close' />
        </TableDeleteColumnTab>
      ))}
      <AddRowButton
        type='button'
        onClick={() => addRow()}
        title='Add row'
      >
        <Icon name='plus circle' size='large' fitted />
      </AddRowButton>
    </TableFinalRowDivider>
  )
}

interface TableRowsProps {
  addRow: (arg0: number) => void
  handleChainRow: (arg0: number) => void
  handleDeleteColumn: (arg0: number) => void
  handleRemoveRow: (arg0: number) => void
  linkedRows: number[]
  rows: string[][]
  table_id: string
  onEdit?: () => void
  showStyleButtons?: boolean
  handleRowStyles: (e: any, arg: number) => void
  storedTable: FZTable
}

const TableRows: React.FC<TableRowsProps> = ({
  addRow,
  handleChainRow,
  handleDeleteColumn,
  handleRemoveRow,
  linkedRows,
  rows,
  table_id,
  onEdit = () => null,
  showStyleButtons,
  handleRowStyles,
  storedTable
}) => {
  const showColumnDeleteBar = useCallback((rIdx: number): boolean => {
    if (rows.length < 2) return false
    if (rIdx === rows.length - 1) return true
    return false
  }, [rows])

  const [showStyleOptions, setShowStyleOptions] = useState(null)

  return (
    <TableToolRows>
      <Droppable droppableId={`drop-table-${table_id}`} type='TABLE_ROW'>
        {(provided) => (
          <div
            style={{ width: '100%' }}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {rows?.map((row, rIdx: number) => (
              <Draggable
                draggableId={`table-row-drag-${rIdx}-${table_id}`}
                index={rIdx}
                key={`table-row-${table_id}-${rIdx}`}
              >
                {(innerProvided) => (
                  <div
                    key={`${table_id}-${rIdx}`}
                    ref={innerProvided.innerRef}
                    {...innerProvided.draggableProps}
                    {...innerProvided.dragHandleProps}
                  >
                    <InputRow key={`col-${rIdx}`} style={{ position: 'relative' }}>
                      <div>
                        {showStyleButtons && (
                          <IconButton
                            icon='css3'
                            handleClick={() => setShowStyleOptions((p) => p === rIdx ? null : rIdx)}
                            color='var(--blue-gray)'
                            contrastColor='var(--off-white)'
                            title={`Row ${rIdx + 1} Styles`}
                            active={showStyleOptions === rIdx}
                          />
                        )}
                        {showStyleButtons && showStyleOptions === rIdx && (
                          <StylesPopup table row>
                            <label htmlFor='font-size-select'><strong>Font Size: </strong></label>
                            <select name='fontSize' id='row-font-size-select' value={storedTable.rowStyles[rIdx]?.fontSize ?? null} onChange={(e) => handleRowStyles(e, rIdx)}>
                              <option value={null}>Default</option>
                              <option value='0.75rem'>xs</option>
                              <option value='0.875rem'>sm</option>
                              <option value='1rem'>md</option>
                              <option value='1.125rem'>lg</option>
                              <option value='1.25rem'>xl</option>
                              <option value='var(--reg-font-size)'>2xl (reg font size)</option>
                              <option value='1.875rem'>3xl</option>
                              <option value='2.25rem'>4xl</option>
                              <option value='3rem'>5xl</option>
                              <option value='3.75rem'>6xl</option>
                              <option value='4.5rem'>7xl</option>
                              <option value='6rem'>8xl</option>
                              <option value='8rem'>9xl</option>
                            </select>
                          </StylesPopup>
                        )}
                        <IconButton
                          color={linkedRows.includes(rIdx) ? 'var(--mid-border)' : 'var(--mid-panel)'}
                          contrastColor='var(--off-white)'
                          handleClick={() => handleChainRow(rIdx)}
                          icon={linkedRows.includes(rIdx) ? 'chain' : 'broken chain'}
                          title='Link with next row'
                        />
                        {row?.map((_, cellIdx: number) => (
                          <ToolInput
                            key={`${rIdx}-${cellIdx}`}
                            value={`table.rows.${rIdx}.${cellIdx}`}
                            placeholder={`Row ${rIdx + 1} Cell ${cellIdx + 1}`}
                            onEdit={onEdit}
                          />
                        ))}
                        <IconButton
                          color='var(--red)'
                          contrastColor='var(--off-white)'
                          handleClick={() => handleRemoveRow(rIdx)}
                          icon='delete'
                          title='Delete Row'
                        />
                      </div>
                      {showColumnDeleteBar(rIdx) ? (
                        <FinalRowDivider
                          addRow={() => addRow(rIdx)}
                          cellCount={rows[0].length}
                          handleRemoveColumn={handleDeleteColumn}
                          shiftDeleteTab={!((rows[rIdx].length / 2) % 2)}
                        />
                      ) : (
                        <ListInputDivider
                          handleClicked={() => addRow(rIdx)}
                        />
                      )}
                    </InputRow>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {!rows.length && (
        <FinalRowDivider
          addRow={() => addRow(0)}
          cellCount={0}
          handleRemoveColumn={handleDeleteColumn}
          shiftDeleteTab={false}
        />
      )}
    </TableToolRows>
  )
}

export default TableRows
