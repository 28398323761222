import styled from 'styled-components'

const GameButtons = styled.div`
  margin: auto;
  justify-content: center;
  display: flex;
  align-items: center;
  width: 30vw;
  max-width: 25rem;

  button {
    white-space: nowrap;

    @media (max-width: 740px) {
      margin: 1rem;
    }
  }

  .yellow {
    background-color: #f7a248;
  }

  @media (max-width: 740px) {
    width: 70%;
  }
`

export default GameButtons
