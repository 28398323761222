import React, { useState } from 'react'
import Button from '../UI/Button'
import Card from '../UI/Card'
import GameButtons from '../UI/GameButtons'
import StudyButton from '../UI/StudyButton'

/* eslint-disable react/prop-types */
const CharSelect = (props) => {
  const { hiraChars, kataChars, showPage, onCreateArray } = props

  const [gameData, setGameData] = useState([])
  const [hiraButtonToggle, setHiraButtonToggle] = useState(true)
  const [kataButtonToggle, setKataButtonToggle] = useState(true)

  const handleChecked = (checked, setId, data) => {
    const existingSet = gameData.find((set) => set.id === setId)
    setGameData((prevGameData) => {
      if (checked && !existingSet) {
        const newGameData = [...prevGameData]
        const setToAdd = data.find((set) => set.id === setId)
        newGameData.push(setToAdd)
        return newGameData
      }
      if ((checked && existingSet) || (!checked && !existingSet)) {
        const newGameData = [...prevGameData]
        return newGameData
      }
      if (!checked && existingSet) {
        const newGameData = [...prevGameData].filter((set) => set.id !== setId)
        return newGameData
      }
    })
  }

  const checkAllHira = (data) => {
    if (hiraButtonToggle) {
      // eslint-disable-next-line
      for (let i in data) {
        handleChecked(true, data[i].id, data)
      } setHiraButtonToggle(false)
    } else {
      // eslint-disable-next-line
      for (let i in data) {
        handleChecked(false, data[i].id, data)
      } setHiraButtonToggle(true)
    }
  }

  const checkAllKata = (data) => {
    if (kataButtonToggle) {
    // eslint-disable-next-line
      for (let i in data) {
        handleChecked(true, data[i].id, data)
      } setKataButtonToggle(false)
    } else {
    // eslint-disable-next-line
    for (let i in data) {
        handleChecked(false, data[i].id, data)
      } setKataButtonToggle(true)
    }
  }

  const createArray = () => {
    onCreateArray(gameData)
    setGameData([])
  }

  return (
    <>
      <Card style={{ display: showPage === 0 ? 'flex' : 'none' }}>
        {hiraChars.map((set) => (
          <div key={set.id} className='checkbox'>
            <label htmlFor={set.id} style={{ backgroundColor: gameData.includes(set) ? '#f7a248' : '' }}>
              <input
                type='checkbox'
                name={set.id}
                id={set.id}
                checked={gameData.includes(set)}
                onChange={(event) => handleChecked(event.target.checked, set.id, hiraChars)}
              />
              {set.name}
            </label>
            <h3 className='chars'>{set.label}</h3>
          </div>
        ))}
      </Card>
      <Card style={{ display: showPage === 1 ? 'flex' : 'none' }}>
        {kataChars.map((set) => (
          <div key={set.id} className='checkbox'>
            <label htmlFor={set.id} style={{ backgroundColor: gameData.includes(set) ? '#f7a248' : '' }}>
              <input
                type='checkbox'
                name={set.id}
                id={set.id}
                checked={gameData.includes(set)}
                onChange={(event) => handleChecked(event.target.checked, set.id, kataChars)}
              />
              {set.name}
            </label>
            <h3 className='chars'>{set.label}</h3>
          </div>
        ))}
      </Card>
      <GameButtons>
        {showPage === 0 && (
          <Button onClick={() => checkAllHira(hiraChars)}>
              {hiraButtonToggle ? 'CHECK ALL' : 'UNCHECK ALL'}
          </Button>
        )}
        {showPage === 1 && (
          <Button onClick={() => checkAllKata(kataChars)}>
            {kataButtonToggle ? 'CHECK ALL' : 'UNCHECK ALL'}
          </Button>
        )}
        <StudyButton disabled={!gameData.length > 0} onClick={createArray}>STUDY</StudyButton>
      </GameButtons>
    </>
  )
}

export default CharSelect
