import { useContext } from 'react'
import { CardElement } from '@stripe/react-stripe-js'
import { Message } from 'semantic-ui-react'

import useStripeFZ from 'src/hooks/subscriptions/useStripeFZ'
import usePlan from 'src/hooks/subscriptions/usePlan'
import { SubscriptionContext } from 'src/contexts/SubscriptionContext'
import { Disclaimer } from './styles/PaymentOptions.styles'
import RedButton from '../../landing_page/v3/components/RedButton'

const options = {
  style: {
    base: {
      fontSize: '18px',
      '::placeholder': {
        color: '#a6a9b1'
      }
    }
  }
}

const StripeCheckoutForm = ({ onSuccess }) => {
  const { processCard, stripe, processing, processed, cardError, billingDetails, setBillingDetails } = useStripeFZ({
    onSuccess
  })
  const { chosenPlan, isNewYearsSale, affiliate, basePrice, price } = useContext(SubscriptionContext)
  const { interval } = usePlan(chosenPlan)

  const baseText = `${basePrice}/${interval}`
  const affiliateText = `discounted to ${price} for the first ${interval === 'mo' ? '3 months' : 'year'} and ${baseText} thereafter`

  const priceText = `${price}/${interval}`
  let subscriptionValue = (affiliate?.identifier && !affiliate?.internal) ? affiliateText : priceText
  
  // TODO *COLTON* - check if this is the right change, was premium-annual-half which isn't a PLAN_CHOICE -Mike
  if (isNewYearsSale && chosenPlan === 'premium-annual') {
    subscriptionValue = '$77.00/year'
  }

  return (
    <form onSubmit={processCard}>
      {cardError && <Message error style={{ borderRadius: '24px', marginBottom: '0px', fontFamily: 'Outfit' }}>{cardError}</Message>}
      <input
        required
        aria-required
        type='text'
        id='fullname'
        placeholder='Cardholder&apos;s Name'
        onChange={(e) => setBillingDetails({ ...billingDetails, name: e.target.value })}
      />
      <CardElement options={options} />
      <Disclaimer>
        By clicking &quot;Agree &amp; Subscribe&quot; you are enrolling in automatic payments
        of the subscription fee ({subscriptionValue}) that
        will continue until you cancel. You can cancel at any time, through your account
        settings.
      </Disclaimer>
      {affiliate?.identifier && !affiliate?.internal && chosenPlan === 'premium-monthly' && (
        <p style={{ backgroundColor: 'gray', color: 'white', padding: '10px', textAlign: 'center', marginBottom: '28px', marginLeft: '-90px', marginRight: '-90px', marginTop: '20px' }}>
          * After the first 3 months, you will be charged the normal rate of {basePrice}/{interval}.
        </p>
      )}
      {affiliate?.identifier && !affiliate?.internal && chosenPlan === 'premium-annual' && (
        <p style={{ backgroundColor: 'gray', color: 'white', padding: '10px', textAlign: 'center', marginBottom: '28px', marginLeft: '-90px', marginRight: '-90px', marginTop: '20px' }}>
          * After the first year, you will be charged the normal rate of {basePrice}/{interval}.
        </p>
      )}
      <RedButton
        type='submit'
        loading={processing}
        disabled={!billingDetails.name || !stripe || processing || processed}
        style={{ display: 'block', margin: '32px auto' }}
      >
        Agree &amp; Subscribe
      </RedButton>
    </form>
  )
}

export default StripeCheckoutForm
