import styled, { css, keyframes } from 'styled-components'

interface StyleProps {
  mobile: boolean
  backgroundColor: string
}

const BlueBGButtonTheme = css`
  :hover {
    border-color: white !important;
  }
  :focus {
    outline-color: white;
  }

  .nav-icon a span {
    color: white !important;
  }

  .nav-icon a:hover,
  .nav-icon a.active:hover {
    border-color: white !important;
  }

  .avatar .hamburger svg { fill: var(--off-white); }
`
const WhiteBGButtonTheme = css`
  :hover {
    border-color: var(--jfz-blue) !important;
  }
  :focus {
    outline-color: var(--jfz-blue);
  }

  a.ui.button.fz-button {
    :hover {
      border-color: var(--jfz-blue) !important;
    }
    :focus {
      outline-color: var(--jfz-blue);
    }
  }

  .nav-icon a span {
    color: var(--blue-gray) !important;
  }
  .nav-icon a.active {
    border-color: var(--jfz-blue);

    span {
      color: var(--jfz-blue) !important;
      font-weight: bold;
    }
  }
  .nav-icon a:hover,
  .nav-icon a.active:hover {
    border-color: var(--jfz-blue) !important;
  }

  .avatar .hamburger svg {  fill: var(--off-white); }
`

const TransparentBGButtonTheme = css`
  :hover {
    border-color: var(--blue-gray) !important;
  }
  :focus {
    outline-color: var(--blue-gray);
  }
  .nav-icon a span {
    color: var(--blue-gray) !important;
  }
  .nav-icon a:hover,
  .nav-icon a.active:hover {
    border-color: var(--blue-gray) !important;
  }
  .avatar .hamburger svg { fill: var(--off-white); }
`

const blurFrames = keyframes`
  from {
    backdrop-filter: blur(0px);
  }

  to {
    backdrop-filter: blur(0px);
  }
`

export default styled.nav<StyleProps>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 70px;
  max-height: 70px;
  background-color: ${(props) => props.backgroundColor};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-basis: fit-content;
  padding: 8px 15px;
  z-index: 30;

  color: var(--blue-gray);
  font-family: 'Milliard Book', sans-serif;
  vertical-align: middle;

  .avatar {
    margin-left: auto;
  }

  .clickable-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(55, 101, 176, 0.15);
    /* backdrop-filter: blur(2px);
    animation: ${blurFrames} 0.5s; */
  }

  ${BlueBGButtonTheme}
  ${(props) => props.backgroundColor === 'var(--off-white)' && WhiteBGButtonTheme}
  ${(props) => props.backgroundColor === 'transparent' && TransparentBGButtonTheme}

  @media (max-width: 768px) {
    padding: 8px 0;
    background-color: ${(props) => props.backgroundColor};

    .apply-min-width {
      margin-left: 0.625rem;
      margin-right: 0.625rem;
      min-width: calc(100vw - 60px);
    }

    .avatar {
      margin-left: 0;
      margin-right: 8px;
    }
  }
`
