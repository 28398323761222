import { useDispatch, useSelector } from 'react-redux'
import styled from '@emotion/styled'

import { RootState } from 'src/configureStore'
import { REDUX_ACTIONS } from 'src/types'

type GrowingProps = {
  vis: boolean
  maxHeight: number // approx height for animation, should be big enough to ensure no overflow
}

// animation with max-height vs height allows for dynamic content to fit properly based on size
// a little finicky though since the specified height value determines animation look/feel
// smaller = better, but needs to be big enough to contain content
const GrowingDiv = styled.div<GrowingProps>`
  max-height: ${({ vis, maxHeight }) => vis ? maxHeight : 0}px;
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;

  label { margin: 2px 6px 0 0; }
  i { margin-bottom: 8px; }
`

const ColorKey = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: auto auto 1fr;
  background-color: var(--off-white);
  padding: 8px;
  border-radius: 20px;
  gap: 5px;

  p {
    color: #2e2e2e;
    justify-self: left;
  }

  button {
    grid-row: 1 / span 2;
    grid-column: 3;
    background-color: #28c6aa;
    padding: 4px 16px;
    border-radius: 20px;
    align-self: center;
    margin-left: 8px;

    &:hover {
      background-color: #24b198;
    }
  }
`

const ColorizedFuriToggle = () => {
  const isColorized = useSelector((state: RootState) => state.auth.colorized_furi)
  const isSwapped = useSelector((state: RootState) => state.auth.swap_furi_colors)
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const dispatchColorized = useDispatch()

  const handleChangeSettings = (setting: boolean) => {
    dispatchColorized({
      type: REDUX_ACTIONS.SWITCH_COLORIZED_FURI,
      payload: setting
    })
  }
  const swapFuriColors = () => {
    dispatchColorized({
      type: REDUX_ACTIONS.SWAP_FURI_COLORS
    })
  }

  const progOrKanji = ['progressive', 'kanji'].includes(progSettings?.main_default)

  return (
    <GrowingDiv vis={progOrKanji} maxHeight={110 - (isColorized ? 0 : 80)}>
      <div>
        <label htmlFor='colorized_furi'>Colorized Furi</label>
        <input
          id='colorized_furi'
          type='checkbox'
          checked={isColorized}
          onChange={({ target: { checked } }) => handleChangeSettings(checked)}
        />
      </div>
      <GrowingDiv vis={isColorized} maxHeight={80}>
        <ColorKey>
          <p>{isSwapped ? '🔵' : '🔴'}</p>
          <p>kun-yomi</p>
          <button onClick={swapFuriColors}>swap colors</button>
          <p>{isSwapped ? '🔴' : '🔵'}</p>
          <p>on-yomi</p>
        </ColorKey>
      </GrowingDiv>
    </GrowingDiv>
  )
}

export default ColorizedFuriToggle
