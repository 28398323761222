import React, { useEffect, useState } from 'react'
import axios from '../../../../api'

import { Divider, Grid, Header, Segment, Placeholder } from 'semantic-ui-react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import styled from 'styled-components'

import SoundInjector from '../../admin/editor/SoundInjector'
// import useMobile from '../../../../hooks/useMobile'

const OuterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Container = styled.div`
    margin: 8px;
    box-sizing: border-box;
    border: 1px solid lightgrey;
    
    border-radius: 2px;
    min-width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    text-align: center;

    h2 {
        padding: 5px;
    }
`

const BuilderZone = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    gap: 10px;
    margin: 20px;
    padding: 8px;
    border-radius: 5px;
    transition: background-color 0.2s ease;
    background-color: ${props =>
    props.isDraggingOver ? 'skyblue' : 'var(--jfz-blue)'};
    min-height: 125px;
    width: 95%;
`
const SentencePartStyle = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    border: 1px solid lightgrey;
    border-radius: 2px;
    padding: 12px;
    margin-bottom: 8px;
    border-radius: 5px;
    text-align: center;
    min-height: 90px;
    max-height: 90px;
    transition: background-color 0.2s ease;
    background-color: ${props =>
        props.isDragDisabled
        ? 'lightgrey'
        : props.isDragging
            ? 'lightgreen'
            : 'white'
        };
`

const AnswerButton = styled.button`
    padding: 8px 12px;
    border: 2px solid white;
    border-radius: 5px;
    font-size: var(--reg-font-size);
    width: 100%;
    color: white;
    background-color: var(--jfz-blue);
    cursor: pointer;

    &:hover {
        color: var(--jfz-blue);
        border-color: var(--jfz-blue);
        background-color: white;
    }

    &:disabled,
    &:disabled:hover {
        cursor: default;
        color: white;
        border-color: green;
        background-color: green;
    }
`

const SentenceBuilderWidget = ({id}) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [data, setData] = useState({
        title: '',
        target_sentence: '',
    })
    const [columns, setColumns] = useState(
        {
            'column-1': {
                id: 'column-1',
                title: 'Answer',
                parts: [],
            },
            'column-2': {
                id: 'column-2',
                title: 'Word Bank',
                parts: [],
            }
        }
    )
    const [columnOrder] = useState(['column-1', 'column-2'])
    const [correct, setCorrect] = useState(false)
    // const isMobile = useMobile()

    function fetchBuilderData() {
        if (loading) return;
        setLoading(true)
        axios.get(`/widgets/${id}`)
        .then(res => {
            if (res.data?.success) {
                setData(res.data.widget)

                const parts = res.data.widget.sentence_parts
                const partsArr = []
                for (let i = 0; i < parts.length ; i++) {
                    if (parts[i] != undefined) {
                        const part = {
                            id: `part-${i}`,
                            content: parts[i]
                        }
                        partsArr.push(part)
                    }
                }
                setColumns({
                    ...columns,
                    'column-2': {
                        id: 'column-2',
                        title: 'Word Bank',
                        parts: partsArr
                    }
                })
            }

            else setError(true)
            setLoading(false)
        })
        .catch(err => {
            console.error(err)
            setError(true)
            setLoading(false)
        })
    }

    function onDragEnd(result) {
        const { source, destination } = result; // draggableId = item being dragged
        if (!destination) return // no movement or outside of droppable
        if (destination.droppableId === source.droppableId && destination.index === source.index) return // same index same box
        
        const startColumn = columns[source.droppableId] // column grabbed from
        const finishColumn = columns[destination.droppableId] // column dropped on

        // movement within a column
        if (startColumn === finishColumn) {
            // 1. make the array from the starting column nice to read
            const partsArr = startColumn.parts
            // 2. get the word that the user drag/dropped
            const userSelectedPart = partsArr.splice(source.index, 1)
            // 3. splice and spread that word into the destination index
            partsArr.splice(destination.index, 0, ...userSelectedPart)
      
            // 4. spread the parts into the starting column
            const newColumn = {
              ...startColumn,
              parts: partsArr
            }
      
            // 5. commit the updated column to state and exit
            return setColumns({
                ...columns,
                [newColumn.id]: newColumn
            })
        }

        // movement between columns
        if (startColumn !== finishColumn) {
            // 1. arrays
            const startingArr = startColumn.parts
            const finishArr = finishColumn.parts

            // 2. get the word the user drag/dropped
            const userSelectedPart = startingArr.splice(source.index, 1)
            // 3. and put it into the ending array
            finishArr.splice(destination.index, 0, ...userSelectedPart)

            // 4. update columns
            const newStartColumn = {
                ...startColumn,
                parts: startingArr
            }
            const newFinishColumn = {
                ...finishColumn,
                parts: finishArr
            }

            // 5. commit to state
            return setColumns({
                ...columns,
                [newStartColumn.id]: newStartColumn,
                [newFinishColumn.id]: newFinishColumn
            })
        }
    }

    function checkAnswer() {
        const answersColumn = columns['column-1'].parts
        const sentence = []
        for (let i = 0 ; i < answersColumn.length ; i++ ) {
            sentence.push(answersColumn[i].content)
        }
        sentence.join(' ') === data.target_sentence ? setCorrect(true) : setCorrect(false)
    }

    useEffect(() => {
        fetchBuilderData()
    }, [columnOrder])

    const DragDropCtx = () => (
        <DragDropContext onDragEnd={onDragEnd}>
            <OuterContainer>
                {columnOrder.map((colId, index) => {
                    const column = columns[colId]
                    const title = column.title
                    const parts = column.parts
                    return (
                        <Column
                            key={index}
                            id={column.id}
                            title={title}
                            parts={parts}
                        />
                    )}
                )}
            </OuterContainer>            
        </DragDropContext>
    )

    // String, String, <Array <Obj < content, id >>>
    const Column = ({ id, title, parts }) => {
        return (
        <Container>
            <Header as='h2'  className="fz-dark-font" style={{ fontFamily: 'Milliard Bold', marginBottom: '5px' }}>{title}</Header>
            <Droppable droppableId={id} type="WORD">
                {(provided, snapshot) => (
                    <BuilderZone
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        isDraggingOver={snapshot.isDraggingOver}
                    >
                        {parts.map((part, partIdx) =>   // part: content, id
                            <SentencePart key={part.id} part={part} index={partIdx} />                        
                        )}
                        {provided.placeholder}
                    </BuilderZone>
                )}
            </Droppable>
        </Container>
    )}
    
    // String, <Obj < content, id >>
    const SentencePart = ({ index, part }) => {
        const { content, id } = part
        return (
            <Draggable
                draggableId={id}
                index={index}
            >
                {(provided, snapshot) => (
                    <SentencePartStyle
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        isDragging={snapshot.isDragging}
                    >
                        <SoundInjector>
                        <div>
                            {content}
                        </div>
                        </SoundInjector>
                    </SentencePartStyle>
                )}
            </Draggable>
        )
    }

    return (
        <>
        {loading ?
            <Segment>
                <Placeholder fluid>
                    <Placeholder.Header>
                        <Placeholder.Line length="full" />
                        <Placeholder.Line length="very long" />
                        <Placeholder.Line length="full" />
                    </Placeholder.Header>
                </Placeholder>
            </Segment>
            :
            (error ?
                <Segment>
                    <Header as='h2' style={{ fontFamily: 'Milliard Bold', marginBottom: '30px' }}>There was a problem fetching the activity</Header>
                </Segment>
            :   
            
                <Segment className="fz-dark-font">
                    {data.title.length && <Header as='h2' style={{ fontFamily: 'Milliard Bold', marginBottom: '30px' }}>{data.title}</Header>}
                    <Grid centered>
                    <Grid.Row>
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={12}>
                            <SoundInjector>
                                <Grid.Row>
                                    Drag and drop the parts to build this sentence:<br />
                                    <Header as='h2'  className="fz-dark-font" style={{ fontFamily: 'Milliard Bold', marginBottom: '30px' }}>
                                    {data.target_sentence}
                                    </Header>
                                </Grid.Row>
                            </SoundInjector>
                            <Divider />
                            <DragDropCtx />
                        </Grid.Column>
                        <Grid.Column width={2}></Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={12} />
                        <Grid.Column width={4}>
                            <AnswerButton
                                type="button"
                                onClick={checkAnswer}
                                disabled={correct}
                            >
                                {correct ? 'Great Job!' : 'Check Answer'}
                            </AnswerButton>
                        </Grid.Column>
                    </Grid.Row>
                    </Grid>
                </Segment>
            
            )
        }
        </>
    )
}

export default SentenceBuilderWidget
