import React from 'react'
// import {render} from 'react-dom'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { hotjar } from 'react-hotjar'

import App from './components/App'

import configureStore from './configureStore'
import { unregister } from './registerServiceWorker'

const { store, persistor } = configureStore()

const hjid = 2796533
const hjsv = 6
hotjar.initialize(hjid, hjsv)

const container = document.getElementById('root')

// REACT 17 WAY
// render(
//   <Provider store={store}>
//     <PersistGate loading={null} persistor={persistor}>
//       <App />
//     </PersistGate>
//   </Provider>, container
// )

// ***react 18 version*** <-- broke some stuff

const root = createRoot(container!)
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)

/**
 * Remove 'un' from import and this call when service workers are more set up.
 * "Use the built-in support for Workbox's InjectManifest plugin,
 * which will compile your service worker and inject into it a list of URLs to precache."
 */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers:
// https://create-react-app.dev/docs/making-a-progressive-web-app/
unregister()
