import React, { createContext, useEffect, useRef, useState } from 'react'

const TabContext = createContext({
  tabId: '',
  active: false,
  init: () => {}
})

const TabProvider = ({ children }) => {
  const [tabId, setTabId] = useState(sessionStorage.getItem('tabId') || '')
  const [active, setActive] = useState(false)
  const tabIdRef = useRef(tabId)

  useEffect(() => {
    tabIdRef.current = tabId; // Update ref when tabId changes
  }, [tabId]);

  const init = () => {
    if (!tabId) {
      const newTabId = Math.random().toString(36).substring(2, 15)
      sessionStorage.setItem('tabId', newTabId)
      setTabId(newTabId)
    } else {
      setTabId(sessionStorage.getItem('tabId'))
    }
    setActive(true)

    const handleFocus = () => setActive(true);
    const handleBlur = () => setActive(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    }
  }

  useEffect(() => {
    init()
  }, [])

  useEffect(() => {
    sessionStorage.setItem('active', active.toString())
  }, [active])

  return (
    <TabContext.Provider value={{ tabId, active, init }}>
      {children}
    </TabContext.Provider>
  )
}

export { TabContext, TabProvider }
