import { useMemo, useState } from 'react'
import mathClamp from 'src/util/mathClamp'

type UseLoadingHook = [
  number,
  (arg0: number) => void,
  () => void
]

const useLoadingProgressBar = (): UseLoadingHook => {
  const [progressWidth, setProgressWidth] = useState(0)

  const handleProgressValue = (value: number) => {
    const newValue = mathClamp(value, 0, 100)
    if (progressWidth > value) return
    setProgressWidth(() => newValue)
  }

  const reset = () => {
    setProgressWidth(0)
  }

  const progress = useMemo(() => {
    return progressWidth
  }, [progressWidth])

  return [progress, handleProgressValue, reset]
}

export default useLoadingProgressBar
