import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { regFontSize, PlayAll, blurCSS } from 'src/assets/styles/globalStyles'

type TableWidgetContainerProps = {
  isMobile: boolean
  forceIndent: boolean
}

const indentStyles = ({ isMobile }) => css`
  margin: ${isMobile ? '15px 14px' : '15px 45px'};
`

export const TableWidgetContainer = styled.div<TableWidgetContainerProps>`
  margin: 15px 0;
  position: relative;
  ${({ forceIndent }) => forceIndent && indentStyles};
`

type TableSubtitleProps = {
  isHeader: boolean
  isMobile?: boolean
}

// lots of !importants required for SoundRendered stuff?
const subtitleHeaderStyles = css`
  margin-top: 0.25rem !important;
  font-weight: normal !important;
`

const subtitleNonHeaderStyles = css`
  color: var(--blue-gray);
  margin: 15px !important;
  padding: 0;
  font-size: 1.3rem !important;
  margin-left: 20px !important;
`

const mobileSubtitleNonHeaderStyles = css`
  font-size: 1.1rem !important;
  margin-left: 15px !important;
`

export const TableSubtitle = styled.h4<TableSubtitleProps>`
  position: relative;
  z-index: 15;
  ${({ isHeader }) => isHeader ? subtitleHeaderStyles : subtitleNonHeaderStyles}
  ${({ isHeader, isMobile }) => (!isHeader && isMobile) && mobileSubtitleNonHeaderStyles}
`

const mobileFormat = css`
  overflow-x: auto;
`

const tableFormat = ({ columns, gridTemplateColumns }) => css`
  display: grid;
  grid-template-columns: ${gridTemplateColumns ?? `repeat(${columns}, 1fr}`};
  ${regFontSize}
`

type TableGridProps = {
  columns: number
  isMobile: boolean
  gridTemplateColumns: string
}

export const TableGrid = styled.section<TableGridProps>`
  ${tableFormat}
  ${({ isMobile }) => isMobile && mobileFormat}
  
  box-shadow: 0 45px 10px -45px rgba(214, 222, 248, 1);
`

type TitleHeaderProps = {
  courseColor: string
}

export const TitleHeader = styled.header<TitleHeaderProps>`
  background-color: ${({ courseColor }) => courseColor};
  border-radius: 7px 7px 0 0;
  text-align: center;
  padding: 1rem;
  color: white;
  position: relative;
  z-index: 15;

  & > h2 {
    ${regFontSize}
  }
`

type ColumnStyles = {
  fontWeight: string
  backgroundColor: string
  columnSize: string
}

type RowStyles = {
  fontSize: string
  textAlign: string
}

type TableCellProps = {
  courseColor: string
  courseColor4: string
  isHeaderRow: boolean
  isSubheaderRow: boolean
  isLastColumn: boolean
  isEvenRow: boolean
  columnIdx: number
  columns: number
  isMobile: boolean
  isHeaderColumn: boolean
  columnStyles: ColumnStyles | false
  rowStyles: RowStyles | false
  boldHeader: boolean
}

const headerCellStyles = ({ courseColor }) => css`
  font-family: 'Milliard Bold';
  background-color: ${courseColor};
  color: white;
  border-radius: 7px 7px 0 0;
`

const subheaderCellStyles = ({ courseColor4, isLastColumn, boldHeader }) => css`
  background-color: ${courseColor4}80; // 80% opacity
  box-shadow: ${isLastColumn ? '' : '6px 0 6px -5px hsl(226deg 71% 85%)'};
  ${boldHeader && css`font-family: 'Milliard Bold';`}
`

const bodyCellStyles = ({ isLastColumn, isEvenRow, columnStyles, rowStyles, isMobile }) => css`
  font-family: 'Milliard Book', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif, 'YesJapan Gothic';
  font-size: ${rowStyles?.fontSize ?? ''};
  font-size: ${isMobile && rowStyles?.fontSize !== 'var(--reg-font-size)' && `${parseInt(rowStyles?.fontSize.slice(-4, -1), 10) / 2}rem`};

  // prioritize column styles over row styles
  font-size: ${columnStyles?.fontSize ?? ''};
  font-size: ${isMobile && columnStyles?.fontSize !== 'var(--reg-font-size)' && `${parseInt(columnStyles?.fontSize.slice(-4, -1), 10) / 2}rem`};
  font-weight: ${columnStyles && columnStyles.fontWeight};
  text-align: ${columnStyles && columnStyles.textAlign};
  box-shadow: ${isLastColumn ? '' : '6px 0 6px -5px rgba(214, 222, 248, 1)'};
  background-color: ${isEvenRow ? '#f2f3fd' : ''};

  &:hover {
    background-color: ${!columnStyles?.backgroundColor && '#bac2e9'};
  }
`

const backgroundColorStyles = ({ courseColor, columns, columnIdx, isMobile }) => css`
  background-color: ${courseColor};
  color: white;
  z-index: ${columns + columnIdx};
  position: ${isMobile && 'sticky'};
`

export const TableCell = styled.div<TableCellProps>`
  position: relative;
  left: 0;
  z-index: ${({ columns, columnIdx }) => columns - columnIdx};
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  ${({ isHeaderRow }) => isHeaderRow ? headerCellStyles : bodyCellStyles}
  ${({ isSubheaderRow }) => isSubheaderRow && subheaderCellStyles}
  ${({ columnStyles }) => columnStyles ? columnStyles.backgroundColor && backgroundColorStyles : ''};
`

export const RowSeparator = styled.div<{ courseColor: string}>`
  background-color: ${({ courseColor }) => courseColor};
  grid-column: 1 / -1;
  height: 24px;
  border-radius: 4px;
`

type CellContentProps = {
  blur: boolean
}

export const CellContent = styled.span<CellContentProps>`
  margin: 1rem;
  // align-self: start; // optional cell style?
  ${({ blur }) => blur && blurCSS}
`

export const RightDashedLine = styled.div`
  height: 65%;
  border-right: dashed 1px white;
  justify-self: end;
`

type BottomDashedLineProps = {
  white: boolean
}

export const BottomDashedLine = styled.div<BottomDashedLineProps>`
  position: absolute;
  width: 95%;
  border-bottom: ${({ white }) => white ? 'dashed 1px white' : 'dashed 1px #9d9fb3'};
  justify-self: center;
  align-self: end;
`

export const TopDashedLine = styled.div`
  position: absolute;
  width: 95%;
  border-top: dashed 1px white;
  justify-self: center;
  align-self: start;
`

export const TablePlayAll = styled(PlayAll)`
  margin: 1rem 0 1rem 1rem;
  padding: 0.5rem;
  width: 70px;
`

type PlayAllDivProps = {
  isHeaderColumn: boolean
  columns: number
  columnIdx: number
  isMobile: boolean
  courseColor: string
}

const playAllDivMobileStyles = ({ columns, columnIdx }) => css`
  z-index: ${columns + columnIdx};
  position: sticky;
  left: 0;
`

export const PlayAllDiv = styled.div<PlayAllDivProps>`
  ${({ isMobile, isHeaderColumn }) => (isMobile && isHeaderColumn) && playAllDivMobileStyles}
  background-color: ${({ isHeaderColumn, courseColor }) => isHeaderColumn && courseColor};
`

type OverflowFadeProps = {
  show: boolean
  opacity: number
}

export const OverflowFade = styled.div<OverflowFadeProps>`
  background-image: linear-gradient(to right, rgba(255,255,255,0), white 100%);
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 35px;
  z-index: 14;
  // display to none when opacity === 0 to ensure that this div doesn't block funtionality when fully scrolled
  display: ${({ show, opacity }) => (!show || opacity === 0) && 'none'};
  opacity: ${({ opacity }) => opacity};
`
