import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Grid, Header, Icon, Input, Label, Segment, Table } from 'semantic-ui-react'

import useWidget from '../../hooks/useWidget'

const ExamplesWidgetEditor = (props) => {
  const [examples, setExamples] = useState([])
  const [title, setTitle] = useState()
  const [vertical, setVertical] = useState(false)
  const [playsSound, setPlaysSound] = useState(false)
  const [ordered, setOrdered] = useState(false)
  const [swapColumnDisplay, setSwapColumnDisplay] = useState(false) // false = other first
  const [startNum, setStartNum] = useState(1)
  const [spaceEvery, setSpaceEvery] = useState(0)
  const [widget, _fetchWidget, loading, editWidget] = useWidget(props.id)

  useEffect(() => {
    setExamples(widget?.examples || [])
    setVertical(widget?.vertical || false)
    setOrdered(widget?.ordered || false)
    setStartNum(widget?.startNum)
    setPlaysSound(widget?.plays_sound || false)
    setSwapColumnDisplay(widget?.swapColumnDisplay)
    setSpaceEvery(widget?.spaceEvery)
    if (widget?.title) setTitle(widget.title)
  }, [widget])

  const setExample = (idx, val) => {
    const updatedExamples = [...examples]
    updatedExamples[idx] = val
    setExamples(updatedExamples)
  }

  const addExample = () => {
    const updatedExamples = [...examples]
    updatedExamples.push({ other_lang: '', english: '' })
    setExamples(updatedExamples)
  }

  const deleteExample = (idx) => {
    const updatedExamples = [...examples]
    updatedExamples.splice(idx, 1)
    setExamples(updatedExamples)
  }

  const addPair = (idx) => {
    const updatedExamples = [...examples]
    if (!updatedExamples[idx].pairs) updatedExamples[idx].pairs = []
    updatedExamples[idx].pairs.push({
      english: '',
      other_lang: '',
    })
    setExamples(updatedExamples)
  }

  const removePair = (idx, pidx) => {
    const updatedExamples = [...examples]
    updatedExamples[idx].pairs.splice(pidx, 1)
    setExamples(updatedExamples)
  }

  const handlePairsExampleChange = (e, idx, pidx, value) => {
    const updatedExamples = [...examples]
    updatedExamples[idx].pairs[pidx][e.target.name] = value
    setExamples(updatedExamples)
  }

  const swapColumns = () => {
    const updatedExamples = []

    examples.forEach((_, idx) => {
      updatedExamples.push([])

      updatedExamples[idx] = {
        _id: examples[idx]._id,
        english: examples[idx]?.other_lang,
        other_lang: examples[idx]?.english,
        pairs: [...examples[idx]?.pairs ?? []],
      }

      // FIXME: example pairs swap
      // if (!!updatedExamples[idx].pairs.length) {
      //   examples.pairs.forEach((pair, pidx) => {
      //     pair.english = examples[idx].pairs[pidx].other_lang
      //     pair.other_lang = examples[idx].pairs[pidx].english
      //   })
      // }
    })

    setExamples(updatedExamples)
  }

  const handleColumnOrder = () => {
    setSwapColumnDisplay((prev) => !prev)
  }

  const handleSpaceEvery = (num) => {
    setSpaceEvery(num)
  }

  return (
    <Segment loading={loading} raised inverted>
      <Header inverted style={{ backgroundColor: '#144491' }}>
        Examples
      </Header>
      <Input
        fluid
        value={title}
        onChange={(_, { value }) => {
          setTitle(value)
        }}
      />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{swapColumnDisplay ? 'English' : 'Japanese'}</Table.HeaderCell>
            <Table.HeaderCell>{swapColumnDisplay ? 'Japanese' : 'English'}</Table.HeaderCell>
            <Table.HeaderCell>Delete</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {examples.map((b, idx) => (
            <>
              <Table.Row>
                {swapColumnDisplay ? ( // Other → English
                  <>
                    <Table.Cell>
                      <Input
                        fluid
                        value={examples[idx].english}
                        onChange={(_, { value }) => setExample(idx, {
                          english: value,
                          other_lang: b.other_lang,
                        })}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        value={examples[idx].other_lang}
                        onChange={(_, { value }) => (
                          setExample(idx, { english: b.english, other_lang: value })
                        )}
                      />
                    </Table.Cell>
                  </>
                ) : (
                  // English → Other
                  <>
                    <Table.Cell>
                      <Input
                        fluid
                        value={examples[idx].other_lang}
                        onChange={(_, { value }) => (
                          setExample(idx, { english: b.english, other_lang: value })
                        )}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        value={examples[idx].english}
                        onChange={(_, { value }) => (
                          setExample(idx, {
                            english: value,
                            other_lang: b.other_lang,
                          })
                        )}
                      />
                    </Table.Cell>
                  </>
                )}
                <Table.Cell collapsing>
                  <Button color='red' onClick={() => deleteExample(idx)}>
                    <Icon name='trash' />
                  </Button>
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button color='green' title='Add Pair' onClick={() => addPair(idx)}>
                    <Icon name='add circle' />
                  </Button>
                </Table.Cell>
              </Table.Row>
              {!!examples[idx].pairs?.length && (
                <>
                  {examples[idx].pairs?.map((_, pidx) => (
                    <Table.Row key={`pairs-${idx}-${pidx}`}>
                      {swapColumnDisplay ? ( // Other → English
                        <>
                          <Table.Cell>
                            <Label style={{ width: '100%', margin: '0 auto 10px' }}>
                              {`Pair ${idx + 1}-${pidx + 1} Target language `}
                              <Input
                                placeholder={`Pair ${idx + 1}-${pidx + 1} Target language`}
                                value={examples[idx].pairs[pidx].other_lang}
                                name='other_lang'
                                onChange={(e, { value }) => (
                                  handlePairsExampleChange(e, idx, pidx, value)
                                )}
                              />
                            </Label>
                          </Table.Cell>
                          <Table.Cell>
                            <Label style={{ width: '100%', margin: '0 auto 10px' }}>
                              {`Pair ${idx + 1}-${pidx + 1} English `}
                              <Input
                                placeholder={`Pair ${idx + 1}-${pidx + 1} English`}
                                value={examples[idx].pairs[pidx].english}
                                name='english'
                                onChange={(e, { value }) => (
                                  handlePairsExampleChange(e, idx, pidx, value)
                                )}
                              />
                            </Label>
                          </Table.Cell>
                        </>
                      ) : (
                        // English → Other
                        <>
                          <Table.Cell>
                            <Label style={{ width: '100%', margin: '0 auto 10px' }}>
                              {`Pair ${idx + 1}-${pidx + 1} English `}
                              <Input
                                placeholder={`Pair ${idx + 1}-${pidx + 1} English`}
                                value={examples[idx].pairs[pidx].english}
                                name='english'
                                onChange={(e, { value }) => (
                                  handlePairsExampleChange(e, idx, pidx, value)
                                )}
                              />
                            </Label>
                          </Table.Cell>
                          <Table.Cell>
                            <Label style={{ width: '100%', margin: '0 auto 10px' }}>
                              {`Pair ${idx + 1}-${pidx + 1} Target language `}
                              <Input
                                placeholder={`Pair ${idx + 1}-${pidx + 1} Target language`}
                                value={examples[idx].pairs[pidx].other_lang}
                                name='other_lang'
                                onChange={(e, { value }) => (
                                  handlePairsExampleChange(e, idx, pidx, value)
                                )}
                              />
                            </Label>
                          </Table.Cell>
                        </>
                      )}
                      <Table.Cell>
                        <Button
                          color='red'
                          title='Remove Pair'
                          style={{
                            alignSelf: 'flex-end',
                            marginBottom: '10px',
                          }}
                          onClick={() => removePair(idx, pidx)}
                        >
                          <Icon name='trash' />
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </>
              )}
            </>
          ))}
        </Table.Body>
      </Table>
      <Grid centered verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column width={2}>
            <Button
              color='green'
              onClick={() => editWidget({
                examples,
                vertical,
                ordered,
                title,
                startNum,
                plays_sound: playsSound,
                swapColumnDisplay,
                spaceEvery,
              })}
            >
              <Icon name='save' />
            </Button>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button color='blue' onClick={addExample}>
              <Icon name='plus' />
            </Button>
          </Grid.Column>
          <Grid.Column width={2}>
            <Button
              title='Flip content fields'
              compact
              color='orange'
              onClick={swapColumns}
              icon='exchange'
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <Button
              title='Flip content fields on render'
              compact
              color='purple'
              onClick={handleColumnOrder}
              icon='columns'
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Label>
                <Checkbox checked={vertical} onChange={(_, { checked }) => setVertical(checked)} />
                &nbsp;Display Vertical
              </Label>

              <Label>
                <Checkbox
                  checked={playsSound}
                  onChange={(_, { checked }) => setPlaysSound(checked)}
                />
                &nbsp;Play Sound?
              </Label>

              <Label>
                <Checkbox checked={ordered} onChange={(_, { checked }) => setOrdered(checked)} />
                &nbsp;Numbered List
              </Label>

              <Label>
                Start from:&nbsp;
                <Input
                  type='number'
                  min='1'
                  value={startNum}
                  disabled={!ordered}
                  onChange={(_, { value }) => setStartNum(value)}
                  style={{
                    width: '75px',
                  }}
                />
              </Label>
              <Label>
                Space every:&nbsp;
                <Input
                  type='number'
                  min='0'
                  value={spaceEvery}
                  onChange={(_, { value }) => handleSpaceEvery(value)}
                  style={{
                    width: '75px',
                  }}
                />
              </Label>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default ExamplesWidgetEditor
