import React, { useState, useEffect } from "react"

const useFullscreen = (ref) => {
  const [isFullscreen, setIsFullscreen] = useState(false)

  const goFullscreen = () => {
    if (ref.current) ref.current.requestFullscreen()
  }  
  
  useEffect(() => {
    const onFullscreenChange = () => setIsFullscreen(Boolean(document.fullscreenElement))
    document.addEventListener('fullscreenchange', onFullscreenChange)
    
    return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
  }, [])

  return { isFullscreen, goFullscreen }
}

export default useFullscreen