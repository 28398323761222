import React, { useEffect, useState } from 'react'

import { Grid } from 'semantic-ui-react'
import ImageGridRow from './styles/ImageGridWidgetStyles'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'

import TextBoxWidget from './TextBoxWidget'
import ConversationWidget from './ConversationWidget'
import TextListWidget from './TextListWidget'
import TableWidget from './TableWidget'
import ImageWidget from './ImageWidget'
import SentencesWidget from './SentencesWidget'
import QnAWidget from './QnAWidget'
import ExamplesWidget from './ExamplesWidget'
import StructureWidget from './StructureWidget'
import VideoWidget from './VideoWidget'
import MiniQuizWidget from './MiniQuizWidget'
import QnAStructureWidget from './QnAStructureWidget'
import UsageWidget from './UsageWidget'
import DialogueWidget from './DialogueWidget'
import KanjiWidget from './KanjiWidget'
import ListWidget from './ListWidget'

import useMobile from '../../../hooks/useMobile'
import useStaffRoles from 'src/hooks/useStaffRoles'

const WidgetRenderView = (props) => {
  const { widget, course, grid, isImageGrid, isGridWidget } = props
  const widgetProps = { id: widget?._id, widget, course, path: widget?._id, grid, isGridWidget, isImageGrid }

  return (
    <WidgetWrapper widget={{ widgetId: widget._id, widgetType: 'GridWidget' }}>
    <div
      data-t={`widget:grid-id:${widget._id}`}
    >
      {widget ? (
        {
          TextboxWidget: <TextBoxWidget { ...widgetProps } />,
          ConversationWidget: <ConversationWidget { ...widgetProps }/>,
          TextListWidget: <TextListWidget { ...widgetProps } />,
          TableWidget: <TableWidget { ...widgetProps } />,
          ImageWidget: (
            <ImageWidget { ...widgetProps } fitted colWidth={props.width} height="auto" width="auto" grid={grid} />
          ),
          SentencesWidget: <SentencesWidget { ...widgetProps } isGridWidget />,
          QnAWidget: <QnAWidget { ...widgetProps } />,
          ExamplesWidget: <ExamplesWidget { ...widgetProps } />,
          StructureWidget: <StructureWidget { ...widgetProps } />,
          VideoWidget: <VideoWidget { ...widgetProps } />,
          MiniQuizWidget: <MiniQuizWidget { ...widgetProps } />,
          QnAStructureWidget: <QnAStructureWidget { ...widgetProps }/>,
          UsageWidget: <UsageWidget { ...widgetProps } />,
          DialogueWidget: <DialogueWidget { ...widgetProps } />,
          KanjiWidget: <KanjiWidget { ...widgetProps } />,
          // eslint-disable-next-line no-use-before-define
          GridWidget: <GridWidget { ...widgetProps } />,
          ListWidget: <ListWidget { ...widgetProps } />,
        }[widget.type]
      ) : (
        <PlaceholderWidget widget_type="MixedWidget" />
      )}
    </div>
    </WidgetWrapper>
  )
}

const GridWidget = (props) => {
  const [rows, setRows] = useState([])
  const [columnWidth, setColumnWidth] = useState([])
  const [widget] = useState(props.widget)
  const [isImageGrid, setIsImageGrid] = useState(false)
  const isMobile = useMobile(1000)
  // const [loading, setLoading] = useState(false)
  const [isStaffRole] = useStaffRoles()

  useEffect(() => {
    if (!widget) return

    const _rows = widget?.rows || []
    setRows(_rows)

    if (!_rows || columnWidth.length) return

    const colWidth = widget?.columnWidth
    if (colWidth == null || colWidth === undefined || !colWidth.length) {
      // for grid widgets without widths defined, set default widths
      const columnWidths = []
      _rows.forEach((r, ir) => {
        if (columnWidths[ir] === undefined) columnWidths[ir] = []
        r.forEach((_, ic) => {
          columnWidths[ir][ic] = Math.round(16 / r.length)
        })
      })
      setColumnWidth([...columnWidths])
    } else {
      setColumnWidth([...colWidth])
    }

    setIsImageGrid(widget.isImageGrid)
  }, [widget])

  if (isImageGrid) {
    return (
      <WidgetWrapper widget={{ widgetType: 'GridWidget', widgetId: widget._id }}>
        {rows?.map((r, idr) => (
          <ImageGridRow key={`row-${idr}`} roundCorners={idr === 0}>
            {r?.map((c, idc) => (
              <WidgetRenderView isGridWidget={true} isImageGrid={true} course={props.course} widget={c} key={`column-${idc}`} grid={widget._id} />
            ))}
          </ImageGridRow>
        ))}
      </WidgetWrapper>
    )
  }

  return (
    <Grid columns="equal" centered={isMobile}>
      <WidgetWrapper widget={{ widgetType: 'GridWidget', widgetId: widget._id }}>
      {/* little bit of space padding in case the grid is empty */}
      {isStaffRole && !rows?.[0] && <div style={{ padding: '50px' }}>GRID EMPTY</div>}
      {rows?.map((r, idr) => (
        <Grid.Row
          style={{
            padding: isMobile ? '10px 0' : '',
            textAlign: 'left',
            justifyContent: isMobile ? '' : 'space-between',
            margin: isMobile ? '' : '0 15px',
          }}
          key={`row-${idr}`}
        >
          {r?.map((c, idc) => isMobile ? (
              <div
                key={`column-${idc}`}
                style={{ padding: 0, margin: '0 auto 15px', boxSizing: 'border-box', width: '95%' }}
              >
                <WidgetRenderView course={props.course} widget={c} grid={widget._id} />
              </div>
          ) : (
            <Grid.Column
              key={`column-${idc}`}
              width={columnWidth?.[idr]?.[idc] ?? 16 - 1}
              style={{
                padding: 0,
                margin: props.isMoreDetailsWidget ? '0 auto auto' : '',
                boxSizing: 'border-box',
              }}
              verticalAlign="middle"
            >
              <WidgetRenderView
                widget={c}
                width={columnWidth?.[idr]?.[idc] ?? 16 / r.length}
                course={props.course}
                grid={widget._id}
                isGridWidget={true}
              />
            </Grid.Column>
          ))}
        </Grid.Row>
      ))}
      </WidgetWrapper>
    </Grid>
  )
}

export default GridWidget
