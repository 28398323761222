import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactFuri from 'react-furi'
import * as R from 'ramda'
import { Tooltip } from '@mantine/core'
import styled from 'styled-components'

import { RootState } from 'src/configureStore'

import { createFuriData, recordSplit, generateTokens, isOnYomi, isKunYomi } from './sound_icon_util'

import { createFormatStyles } from './ProgressiveTextSpan'

const GrammarTooltip = styled(Tooltip)`
  /* on hover, light animated white semi-transparent background color */
  &:hover {
    background-color: rgba(200, 240, 240, 1) !important;
  }
`

const KanjiTextSpan = ({
  kanjiData,
  record,
  holdsFuri,
  resizeAfter,
  isMobile,
  furi_opacity,
  colorized_furi,
  progressive_data,
  bonusData,
  styles,
  main_default,
  setHoldsFuri,
  formatText
}) => {
  if (record.type === 'KoreanRecord') {
    return (
      <span className="text-2xl font-bold">
        {record.base_text}
      </span>
    )
  }
  const { furis, splitRecord } = recordSplit(record)
  const calligraphyMode = useSelector((state: RootState) => state.auth?.calligraphy_mode)
  const [verticalMode, setVerticalMode] = useState(false)

  const newFuriData = createFuriData(splitRecord, furis)

  useEffect(() => {
    // console.log('calligraphyMode', calligraphyMode)
  }, [calligraphyMode])

  // didn't used to be necessary to progressify kanji, but now is after introducing formatting
  const { breakdown } = record
  let progressiveTokens = []
  try {
    progressiveTokens = generateTokens(breakdown, newFuriData, {}, bonusData, 'kanji', record, formatText)
    if (record.base_text === '引(ひ)っ張(ぱ)る') console.log(progressiveTokens)
  } catch (e) {
    console.log(e)
    return <span>{record.base_text}</span>
  }
  // console.log(record.base_text)
  // console.log(progressiveTokens)

  const newStyles = { ...styles }

  useEffect(() => {
    if (holdsFuri) return
    for (const i of newFuriData) {
      if (i.length > 1) {
        setHoldsFuri(true)
        break
      }
    }
  }, [newFuriData])

  const fullStringLength = newFuriData.reduce((p, c) => p += c[0].length, 0)

  const parentStyle: {
    fontSize?: string
  } = {}

  if (parentStyle && newStyles && !styles?.fontSize && resizeAfter && fullStringLength >= resizeAfter) {
    const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter)
    * (isMobile ? 0.04 : 0.03)
    parentStyle.fontSize = `${newSize}rem`
    newStyles.fontSize = `${newSize}rem`
  }

  const verticalTextStyle = {
    display: 'inline-block',
  }

  if (calligraphyMode) {
    newStyles.fontFamily = 'Calligraphy'
  }

  const verticalContainerStyle = {
    writingMode: 'vertical-rl' as const,
    textOrientation: 'mixed' as const,
    textAlign: 'right' as const,
    maxHeight: '600px'
  }

  // V2 progressive system render
  return (
    // <div style={verticalMode ? verticalContainerStyle : {}}>
    <>
      {progressiveTokens?.map(({ text, formatted, bd }, index) => (
        // eslint-disable-next-line react/jsx-key
        // <GrammarTooltip label={bd?.english}>
          <span>
          {(formatted?.length ? formatted : text)?.map((token, idy) => {
            // console.log('token', token, 'at', index, 'at', idy)
            let t, furigana
            let textToken = text[idy]
            // console.log('text token', textToken, 'at', text, 'at', idy)
            const formatStyles = formatted?.length ? createFormatStyles(token?.format, token?.color, token?.highlightColor) : {}
            if (Array.isArray(textToken)) {
              [t, furigana] = textToken
              // console.log('t and furigana', t, furigana)
            } else {
              t = textToken
              // console.log(t)
            }
            const isOY = furigana ? isOnYomi(t, furigana, kanjiData) : false
            const isKY = furigana ? isKunYomi(t, furigana, kanjiData) : false
            let furiBase = furi_opacity < 10 ? 'furi-on-hover ' : ''
            let furiClass = colorized_furi ? 'furi-exception' : ''
            if (colorized_furi && furigana && isOY) furiClass = 'furi-onyomi'
            if (colorized_furi && furigana && isKY) furiClass = 'furi-kunyomi'
            const finalFuriClass = furiBase + furiClass
            return furigana ? (
              <ruby
                key={`${index}-${furigana}`}
                style={{
                  ...newStyles,
                  ...formatStyles,
                  display: 'ruby',
                  ...(verticalMode ? verticalTextStyle : {})
                  // fontFamily: 'Calligraphy'
                }}
              >
                {t || ' '}
                {furigana ? (
                  <rt
                    style={{
                      textAlign: 'center',
                      ...R.pick(['backgroundColor', 'fontStyle'], formatStyles),
                      // rubyPosition: 'under'
                    }}
                    className={finalFuriClass}
                  >
                    {furigana}
                  </rt>
                ) : (
                  <rt
                    style={{
                      textAlign: 'center',
                      // fontFamily: 'Calligraphy',
                      ...R.pick(['backgroundColor'], formatStyles)
                    }}
                  >
                    {'　'}
                  </rt>
                )}
              </ruby>
            ) : (
              <ruby
                key={`${t}-${index}${idy}-r`}
                style={{
                  ...newStyles,
                  ...formatStyles,
                  display: 'ruby',
                  // fontFamily: 'Calligraphy'
                }}
              >
                {t === ' ' ? <>&nbsp;</> : t}
                <rt style={{ textAlign: 'center', ...R.pick(['backgroundColor'], formatStyles) }}>{'　'}</rt>
              </ruby>
            )
          })}
          </span>
        // </GrammarTooltip>
      ))}
    {/* </div> */}
    </>
  )
}

export default KanjiTextSpan
