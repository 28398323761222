import React, { useCallback, useEffect, useState } from 'react'
import axios from '../../../../api'

import { Header, Segment, Placeholder } from 'semantic-ui-react'
import styled from 'styled-components'

import SoundInjector from '../../admin/editor/SoundInjector'
// import useMobile from '../../../../hooks/useMobile'

const Container = styled.div`
  margin: 30px 0;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const AnswerButton = styled.button`
  padding: 8px 12px;
  border: 2px solid white;
  border-radius: 5px;
  font-size: var(--reg-font-size);
  width: 200px;
  color: white;
  background-color: var(--jfz-blue);
  cursor: pointer;

  &:hover {
    color: var(--jfz-blue);
    border-color: var(--jfz-blue);
    background-color: white;
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    color: white;
    border-color: green;
    background-color: green;
  }
`;

const CustomInput = styled.input`
  box-shadow: none;
  border: 0;
  border-radius: 0;
  border-bottom: ${(props) =>
    props.isCorrect == null
      ? "2px solid var(--jfz-blue)"
      : props.isCorrect
      ? "2px solid green"
      : "2px solid red"};
  margin: 10px 0;
  font-size: var(--reg-font-size);
  font-family: "Roboto", sans-serif;
  min-width: 95%;
  transition: border 0.25s ease-in, border-radius 0.25s ease-in;

  &:focus {
    box-shadow: none;
    border: 0;
    border-bottom: ${(props) =>
      props.isCorrect == null
        ? "2px solid var(--jfz-blue)"
        : props.isCorrect
        ? "2px solid green"
        : "2px solid red"};
  }
`;

const TranslationFields = ({ values, setValues, sentences }) => {

  function handleChange(e) {
    e.preventDefault()
    setValues({
      ...values,
      [e.target.name]: e.target.value
    })
  }

  return (
    <Container>
      <ol style={{ width: '100%' }}>
        {sentences.map((sentence, idx) => (
          <li style={{ fontFamily: 'Milliard Bold', marginBottom: '25px', }}>
            <SoundInjector>
              <Header as='h3' style={{ padding: 0, margin: 0, }}>
                {sentence.target_sentence}
              </Header>
            </SoundInjector>
            <CustomInput
              isCorrect={sentence.isCorrect}
              type="text"
              name={`translation-${idx}`}
              value={values[`translation-${idx}`]}
              onChange={(e) => handleChange(e)}
            />
          </li>
        ))}
      </ol>
    </Container>
  )
}

const SentenceTranslationWidget = ({ id }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [correct, setCorrect] = useState(false)
  const [data, setData] = useState({
    _id: '',
    title: '',
    sentences: [
      // {
        // possible_answers: [],
        // target_sentence: "",
      // },
    ],
  })
  const [values, setValues] = useState({})
  
  // const isMobile = useMobile()

  useEffect(() => {
    if (data._id !== '') return
    fetchBuilderData()
  }, [])

  function fetchBuilderData() {
    if (loading) return;
    setLoading(true)
    axios.get(`/widgets/${id}`)
    .then(res => {
      if (res.data?.success) {
        setData(res.data.widget)
      }
      else setError(true)
      setLoading(false)
    })
    .catch(err => {
      console.error(err)
      setError(true)
      setLoading(false)
    })
  }

  function checkAnswers() {
    // 1. loop over each sentences object
    const updatedCorrectValues = data.sentences.map((sentence, idx) => {
      let hasCorrect = false;

      let possible_answers = sentence.possible_answers.split("\n")

      // 2. for each possible answer, compare it to the student's answer
      for (let i = 0 ; i < possible_answers.length ; i++ ) {
        // intermediary value error
        if (idx == undefined || values[`translation-${idx}`] == undefined) continue
        // 3. if it's correct, break out
        if (possible_answers[i] === values[`translation-${idx}`].trim()) {
          hasCorrect = true
          break
        }
      }

      // 4. append either true or false to the sentence object
      return {
        ...sentence,
        isCorrect: hasCorrect
      }
    })

    // check if there's any false values anywhere
    const hasFalse = updatedCorrectValues.filter(sentence => sentence.isCorrect === false || sentence.isCorrect == null || sentence.isCorrect == undefined)

    setCorrect(hasFalse.length ? false : true)

    setData({
      ...data,
      sentences: updatedCorrectValues
    })
  }

  return (
    <>
    {loading ?
      <Segment>
        <Placeholder fluid>
          <Placeholder.Header>
            <Placeholder.Line length="full" />
            <Placeholder.Line length="very long" />
            <Placeholder.Line length="full" />
          </Placeholder.Header>
        </Placeholder>
      </Segment>
      :
      (error ?
        <Segment>
          <Header as='h2' style={{ fontFamily: 'Milliard Bold', marginBottom: '30px' }}>
            There was a problem fetching the activity
          </Header>
        </Segment>
      :
      <Segment>
        {data.title.length &&
          <Header as='h2' style={{ fontFamily: 'Milliard Bold', marginBottom: '30px' }}>{data.title}</Header>
        }
        <p>Translate the following sentences into Japanese:</p>
        <TranslationFields
          values={values}
          setValues={setValues}
          sentences={data.sentences}
        />
        <AnswerButton
          type="button"
          onClick={checkAnswers}
          disabled={correct}
        >
          {correct ? 'Great Job!' : 'Check Answers'}
        </AnswerButton>
      </Segment>
    )}
    </>
  )
}

export default SentenceTranslationWidget