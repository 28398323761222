import React, { useMemo } from 'react'

import { NavLink } from 'react-router-dom'
import { NavItem } from '../Navigation'

import navigation_ask from 'src/assets/headernav/white_navigation_ask.svg'
import navigation_books from 'src/assets/headernav/white_navigation_books.svg'
import navigation_course from 'src/assets/headernav/white_navigation_course.svg'
// import navigation_games from 'src/assets/headernav/white_navigation_games.svg'
import navigation_notebook from 'src/assets/headernav/white_navigation_notebook.svg'
import navigation_video from 'src/assets/headernav/white_navigation_video.svg'

import gray_navigation_ask from 'src/assets/headernav/gray_navigation_ask.svg'
import gray_navigation_books from 'src/assets/headernav/gray_navigation_books.svg'
import gray_navigation_course from 'src/assets/headernav/gray_navigation_course.svg'
// import gray_navigation_games from 'src/assets/headernav/gray_navigation_games.svg'
import gray_navigation_notebook from 'src/assets/headernav/gray_navigation_notebook.svg'
import gray_navigation_video from 'src/assets/headernav/gray_navigation_video.svg'
import useStaffRoles from 'src/hooks/useStaffRoles'
import useMobile from 'src/hooks/useMobile'
import { Icon } from 'semantic-ui-react'

interface NonCourseProps {
  backgroundColor: string
}

const NonCourseNavOptions: React.FC<NonCourseProps> = ({
  backgroundColor,
}) => {
  const isMobile = useMobile()
  const [isStaffRole] = useStaffRoles()
  const adminOnly = ['FZ Live']

  const navigationItems: NavItem[] = useMemo(() => {
    return [
      {
        name: 'Homeroom',
        href: '/home',
        icon: <svg
            fill={backgroundColor?.indexOf('white') !== -1 ? '#9f9f9f' : 'var(--off-white)'}
            stroke={backgroundColor?.indexOf('white') !== -1 ? '#020202' : ''}
            strokeWidth={backgroundColor?.indexOf('white') !== -1 ? '0.3px' : ''}
            viewBox='0 0 20 20'
            xmlns='http://www.w3.org/2000/svg'
            className='no-select'
          >
          <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
        </svg>,
        current: false,
      },
      {
        name: 'Courses',
        href: '/courses',
        icon: backgroundColor?.indexOf('white') !== -1
          ? <img
              src={gray_navigation_course}
              alt='Courses'
              draggable='false'
              className='no-select'
            />
          : <img
              src={navigation_course}
              alt='Courses'
              draggable='false'
              className='no-select'
            />,
        current: false,
      },
      {
        name: 'Notebook',
        href: '/notebook',
        icon: backgroundColor?.indexOf('white') !== -1
          ? <img
            src={gray_navigation_notebook}
            alt='Notebook'
            draggable='false'
            className='no-select'
          />
          : <img
            src={navigation_notebook}
            alt='Notebook'
            draggable='false'
            className='no-select'
          />,
        current: false,
      },
      {
        name: 'Books',
        href: '/books',
        icon: backgroundColor?.indexOf('white') !== -1
          ? <img
            src={gray_navigation_books}
            alt='Books'
            draggable='false'
            className='no-select'
          />
          : <img
            src={navigation_books}
            alt='Books'
            draggable='false'
            className='no-select'
          />,
        current: false,
      },
      {
        name: 'Video Vault',
        href: '/vault',
        icon: backgroundColor?.indexOf('white') !== -1
          ? <img
            src={gray_navigation_video}
            alt='Video Vault'
            draggable='false'
            className='no-select'
          />
          : <img
            src={navigation_video}
            alt='Video Vault'
            draggable='false'
            className='no-select'
          />,
        current: false,
      },
      {
        name: 'Ask-a-Teacher',
        href: '/ask',
        icon: backgroundColor?.indexOf('white') !== -1
          ? <img
            src={gray_navigation_ask}
            alt='Ask-A-Teacher'
            draggable='false'
            className='no-select'
          />
          : <img
            src={navigation_ask}
            alt='Ask-A-Teacher'
            draggable='false'
            className='no-select'
          />,
        current: false,
      },
      {
        name: 'Games',
        href: '/games',
        icon: <span
                className={`s-ui ${backgroundColor?.indexOf('white') !== -1 ? 'gray' : 'white'}`}
              >
                <Icon name='gamepad' />
              </span>,
        current: false,
      },
      {
        name: 'FZ Live',
        href: '/fz-live',
        icon: <span
                className={`s-ui ${backgroundColor?.indexOf('white') !== -1 ? 'gray' : 'white'}`}
              >
                <Icon name='users' />
              </span>,
        current: false,
      },
      {
        name: 'Search',
        href: '/search',
        icon: <span
                className={`s-ui ${backgroundColor?.indexOf('white') !== -1 ? 'gray' : 'white'}`}
              >
                <Icon name='search' />
                {/* tiny upper right "beta" text */}
              </span>,
        current: false,
        beta: true
      },
    ]
  }, [backgroundColor])

  if (isMobile) return <></>
  return (
    <>
      {navigationItems.map((item: NavItem) => {
        if (isStaffRole || !adminOnly.includes(item.name)) {
          return (
            <div key={item.name} className='nav-icon'>
              <NavLink to={item.href} style={{ textDecoration: 'none', position: 'relative' }}>
                {item.icon}
                <span className='link-name'>{item.name}</span>
                {/* tiny top-right beta text if beta field */}
                {item.beta && (
                  <span style={{ position: 'absolute', top: '0', right: '0', fontSize: '0.5rem' }}>
                    beta
                  </span>
                )}
              </NavLink>
            </div>
          )
        }
        return null
      })}
    </>
  )
}

export default NonCourseNavOptions
