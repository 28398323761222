import styled from 'styled-components'

export default styled.div`
  .transition-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    position: fixed;
    top: 5%;
    bottom: 5%;
    right: 0;
    width: 80vw;

    padding: 20px;
    background-color: var(--off-white);
    border-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    overflow-y: scroll;
    overflow-x: hidden;
    z-index: 4;
  
    .logout {
      margin-top: 20px;
      align-self: center;
    }
  }

  .advanced-settings {
    text-align: center;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .nav-link {
      display: flex;
      margin: 4px auto 4px 15px;

      span.s-ui i {
        font-size: 1.3rem;
        color: var(--jfz-blue);
      }
      svg, img {
        width: 25px;
        height: 25px;
      }
      svg {
        fill: var(--jfz-blue);
      }

      span.link-name {
        display: inline-block;
        margin-top: 4px;
        margin-left: 8px;
        color: var(--jfz-blue) !important;
      }
    }
  }

  span.s-ui.inbox {
    display: inline-block;
    position: relative;
    
    .notification-count {
      display: inline-block;
      position: absolute;
      bottom: 10px;
      right: 15px;
      font-family: 'Jost', sans-serif;
      font-feature-settings: "tnum";
      font-variant-numeric: tabular-nums;
      height: 20px;
      min-width: 20px;
      text-align: center;
      padding: 2.5px 1.5px 2.5px 2.5px;
      font-size: 0.9rem;
      border-radius: 10px;
      color: var(--off-white);
      background-color: var(--red);
    }
  }

  .user-greeting {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 24px;

    h3 {
      font-weight: bold;
      color: var(--jfz-blue);
    }
    
    .user-badge-avatar {
      margin-left: 16px;
      width: auto;
      display: flex;
      align-items: center;

      .user-badge {
        width: 30px;
        height: 30px;
        margin-right: 20px;
      }
    }
  }

  .prog-settings {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    h4 {
      text-align: left;
      color: var(--jfz-blue);
    }
    >div>div>div {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;

    }
  }
`
