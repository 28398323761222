import styled from 'styled-components'

export default styled.div`
  width: 100%;

  a, i { color: var(--off-white); }
  a { margin: 0; }
  
  button {
    width: 36px;
    max-width: 36px;
    height: 36px;
    max-height: 36px;
    cursor: pointer;
    border-radius: 18px !important;
  }
  button:first-child { margin-right: 50px; }
  button:last-child { margin-left: 50px; }

  // default accessibility styles for focus while removing "stuck" button look
  button:focus { background-color: transparent !important; }
  button:focus-visible { outline: 5px auto -webkit-focus-ring-color; }
  button:hover { background-color: white !important; }

  button { flex: 1 1 36px; }

  .bread-crumbs {
    align-items: center;
    color: var(--off-white);
    font-size: 1rem;
    text-transform: uppercase;
    text-align: center;
    /* flex: 0 1 300px; */

    .center-chevron {
      margin-bottom: 8px;
    }
  }

  .sublesson-title {
    color: yellow;
    white-space: ellipsis;
    cursor: default;
    align-self: start; // for some reason "start" centers things here
  }

  .cpb-back-link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    margin-right: 20px;
    
    a, i, h3 {
      margin: 0 !important;
      padding: 0 !important;
    }
    i { font-size: 1.5rem !important; }
    a, a.ui.header {
      margin: 0;
      font-weight: 600;
      color: var(--off-white);
      font-size: var(--small-font-size) !important;
    }
  }

  button.ui.circular.icon.inverted.button i { color: white !important; }
  button.ui.circular.icon.inverted.button:hover i { color: var(--blue-gray) !important; }
  //button.ui.circular.icon.inverted.button:focus-within i { color: var(--blue-gray) !important; }

@media (max-width: 1200px) {  
    button:first-child { margin-right: 12px; }
    button:last-child { margin-left: 12px; }
    .bread-crumbs {
      flex-wrap: wrap;
    }
    .cpb-back-link {
      white-space: normal;
    }
  }
`
