import React from "react"
import { motion } from "framer-motion"
import _ from "lodash"
import SoundRenderer from "../../SoundRenderer"
import {
  ArrowLanguageRow,
  ArrowLanguageStack,
  Arrow,
  English,
  BorderBox,
  SlideSubtitle
} from "../../styles/PresentationWidget.styles"

const colors = [
  '#eb4b4b',
  '#63e763',
  '#6060df',
  '#FFA500',
  '#c049c0',
  '#fbabb8',
]

const shuffledColors = _.shuffle(colors)

const ArrowTemplate = ({ slide, slideIdx, currentItem, variants }) => {
  return (
    <>
      <BorderBox color={shuffledColors[0]}>
        <SlideSubtitle>{slide.subtitle}</SlideSubtitle>
      </BorderBox>
      {slide.english.map((text, i) => {
        if (i % 2) return <></>

        return (
        <ArrowLanguageRow key={`arrow-lang-row-${i}`}>
          <ArrowLanguageStack position='end'>
            <motion.div
              animate={currentItem > i * 2 ? 'visible' : 'hidden'}
              variants={variants}
              transition={{ duration: currentItem === 0 ? 0 : 0.5 }}
            >
              <SoundRenderer
                key={`slide-${slideIdx}-other_lang-${i}`}
                text={slide.other_lang[i]}
                fontSize='4rem'
                color={colors[i]}
                presentation
                bold
              />
            </motion.div>
            <English
              color={colors[i]}
              animate={currentItem > i * 2 + 1 ? 'visible' : 'hidden'}
              variants={variants}
              transition={{ duration: currentItem === 0 ? 0 : 0.5 }}
              style={{ textAlign: 'right' }}
            >
              {text}
            </English>
          </ArrowLanguageStack>
          <Arrow
            color={colors[i]}
            animate={currentItem > i * 2 + 2 ? 'visible' : 'hidden'}
            variants={variants}
            transition={{ duration: currentItem === 0 ? 0 : 0.5 }}
          >
            ➡
          </Arrow>
          <ArrowLanguageStack position='start'>
            <motion.div
              animate={currentItem > i * 2 + 2 ? 'visible' : 'hidden'}
              variants={variants}
              transition={{ duration: currentItem === 0 ? 0 : 0.5 }}
            >
              <SoundRenderer
                key={`slide-${slideIdx}-other_lang-${i + 1}`}
                text={slide.other_lang[i + 1]}
                fontSize='4rem'
                color={colors[i]}
                presentation
                bold
              />
            </motion.div>
            <English
              color={colors[i]}
              animate={currentItem > i * 2 + 3 ? 'visible' : 'hidden'}
              variants={variants}
              transition={{ duration: currentItem === 0 ? 0 : 0.5 }}
            >
              {slide.english[i + 1]}
            </English>
          </ArrowLanguageStack>
        </ArrowLanguageRow>
      )})}
    </>
  )
}

export default ArrowTemplate