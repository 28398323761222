import axios from 'src/api'

import { Button } from 'semantic-ui-react'

const DeleteWidgetButton = (props) => {
  const { handleWidgetDeleted } = props
 
  const deleteWidget = () => {
    axios.delete(`/widgets/${props.id}`)
      .then(() => handleWidgetDeleted())
      .catch((err) => console.error(err))
  }

  return (
    <Button color='red' onClick={() => deleteWidget()}>
      Delete Widget
    </Button>
  )
}

export default DeleteWidgetButton
