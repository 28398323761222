// hook that ties into react-idle-timer to track user engagement

import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'

import useAPI from './useAPI'

// needs a hook to fire when the user goes idle, as detected
// needs a hook to fire when the user comes back from idle
const useEngagement = () => {
  const [engagement, setEngagement] = useState({
    idle: false,
    idleTime: 0,
    activeTime: 0,
  })
  const [res, loading, req] = useAPI('/analytics/engagement', 'POST')

  const handleOnIdle = (event) => {
    setEngagement((e) => ({ ...e, idle: true, idleTime: event }))
  }

  const handleOnActive = (event) => {
    setEngagement((e) => ({ ...e, idle: false, activeTime: event }))
  }

  const { getIdleTime, getActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 5, // 5 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  })

  useEffect(() => {
    if (engagement.idle) {
      req({ status: 'idle', idle_time: engagement.idleTime })
    } else {
      req({ status: 'active', active_time: engagement.activeTime })
    }
  }, [engagement.idle])

  return engagement
}

export default useEngagement
