import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface AudioContextValue {
  audio: HTMLAudioElement
  audioRef: React.MutableRefObject<HTMLAudioElement>
  setAudioSrc: (src: string) => void
  clickTriggered: boolean
  setClickTriggered: (triggered: boolean) => void
  paWidget?: string
  setPAWidget?: (widget: string) => void
  playAudio: () => void
  audioPlaying: boolean
  pauseAudio: () => void
  changeAudioSrc: (src: string) => void
  clickedElement: HTMLElement | null
  setClickedElement: (element: HTMLElement | null) => void
}

const AudioContext = createContext<AudioContextValue>({
  audio: new Audio(),
  audioRef: null,
  setAudioSrc: () => false,
  clickTriggered: false,
  setClickTriggered: () => false,
  playAudio: () => false,
  audioPlaying: false,
  pauseAudio: () => false,
  changeAudioSrc: () => false,
  clickedElement: null,
  setClickedElement: () => false
})

const AudioProvider: React.FC = ({ children }) => {
  const [audioSrc, setAudioSrc] = useState('INIT')
  const [prevAudioSrc, setPrevAudioSrc] = useState('INIT')
  const audioRef = useRef(new Audio())
  const [clickTriggered, setClickTriggered] = useState(false)
  const [paWidget, setPAWidget] = useState('')
  const [audioPlaying, setAudioPlaying] = useState(false)
  const [clickedElement, setClickedElement] = useState<HTMLElement | null>(null)

  const playAudio = () => {
    // console.log('play audio called from context')
    const audio = audioRef.current
    audio.currentTime = 0
    // console.log('audio src:', audio.src, 'audio paused:', audio.paused, 'current time:', audio.currentTime)
    if (audio.src && audio.paused) {
      // console.log('playing audio')
      audio.play().then(() => {
        setAudioPlaying(true)
      }).catch((e) => {
        console.error('Error playing audio:', e)
      })
    }
  }

  const pauseAudio = () => {
    // console.log('pause audio called from context')
    const audio = audioRef.current
    if (audio.src && !audio.paused) {
      audio.pause()
      audio.currentTime = 0
      setAudioPlaying(false)
    }
  }

  const changeAudioSrc = useCallback((src: string) => {
    // console.log('change audio src called from context', src)
    audioRef.current.currentTime = 0
    if (audioPlaying) {
      pauseAudio()
    }
    
    // force replay audio if the source is the same
    if (audioSrc === src) {
      // console.log('source is the same:', src, audioSrc)
      setPrevAudioSrc(src)
      setAudioSrc('')
      return
    }
    setPrevAudioSrc(audioSrc)
    setAudioSrc(src)
  }, [audioPlaying, audioSrc])

  useEffect(() => {
    // if (audioSrc) console.log('audio src changed:', audioSrc.split('mp3')?.[0]?.split('.net')?.[1])
    if (!audioSrc && prevAudioSrc) {
      setAudioSrc(prevAudioSrc)
      return
    }

    try {
      // stop the audio if it's already playing
      const audio = audioRef.current
      audio.src = audioSrc
      audio.currentTime = 0
    } catch (e) {
      console.error('Error setting audio src:', e)
    }
  }, [audioSrc, prevAudioSrc])

  useEffect(() => {
    return () => {
      audioRef.current.pause()
    }
  }, [])

  const providerValue = useMemo(() => ({
    audio: audioRef.current,
    audioRef,
    setAudioSrc,
    clickTriggered,
    setClickTriggered,
    paWidget,
    setPAWidget,
    playAudio,
    audioPlaying,
    pauseAudio,
    changeAudioSrc,
    clickedElement,
    setClickedElement
  }), [audioSrc, clickTriggered, paWidget, audioPlaying, clickedElement])

  return (
    <AudioContext.Provider value={providerValue}>
      {children}
    </AudioContext.Provider>
  )
}

export { AudioContext, AudioProvider }
