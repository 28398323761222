import { useEffect, useState } from 'react'
import axios from 'src/api'
import { useLocation } from 'react-router-dom'

// hook to check whether the user is logged in or not
const useIsLoggedIn = () => {
  const [isLoggedInState, setIsLoggedInState] = useState(false)
  const [loading, setLoading] = useState(true)
  const location = useLocation()

  // query API to check whether the user is logged in or not
  useEffect(() => {
    const checkIsLoggedIn = async () => {
      try {
        const isLoggedIn = await axios.get('/user/ping', { params: { path: location.pathname } })
        setIsLoggedInState(isLoggedIn.data.is_logged_in)
        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    checkIsLoggedIn()
  }, [location.pathname])

  return [isLoggedInState, loading]
}

export default useIsLoggedIn
