export const CONVERSATION = 'ConversationWidget'
export const DIALOGUE = 'DialogueWidget'
export const DOWNLOAD = 'DownloadWidget'
export const EXAMPLES = 'ExamplesWidget'
export const FILLINTHEBLANKS = 'FillInTheBlanksWidget'
export const GRID = 'GridWidget'
export const IMAGE = 'ImageWidget'
export const IMAGEQUESTION = 'ImageQuestionWidget'
export const KANJI = 'KanjiWidget'
export const KANJI_MEMORY = 'KanjiMemoryWidget'
export const INVALIDWIDGET = 'InvalidWidget'
export const LINECONNECT = 'LineConnectWidget'
export const LIST = 'ListWidget'
export const MINIQUIZ = 'MiniQuizWidget'
export const MOREDETAILS = 'MoreDetailsWidget'
export const PHRASE = 'PhraseWidget'
export const PRESENTATION = 'PresentationWidget'
export const QNA = 'QnAWidget'
export const QNASTRUCTURE = 'QnAStructureWidget'
export const QUESTIONANDANSWER = 'QuestionAndAnswerWidget'
export const READINGCOMPREHENSION = 'ReadingComprehensionWidget'
export const SENTENCES = 'SentencesWidget'
export const SENTENCEBUILDER = 'SentenceBuilderWidget'
export const SENTENCETRANSLATION = 'SentenceTranslationWidget'
export const STRUCTURE = 'StructureWidget'
export const TABLE = 'TableWidget'
export const TEXTBOX = 'TextboxWidget'
export const TEXTLIST = 'TextListWidget'
export const USAGE = 'UsageWidget'
export const VIDEO = 'VideoWidget'

const WIDGET_TYPES = [
  CONVERSATION,
  DIALOGUE,
  DOWNLOAD,
  EXAMPLES,
  FILLINTHEBLANKS,
  GRID,
  IMAGE,
  IMAGEQUESTION,
  INVALIDWIDGET,
  KANJI,
  LINECONNECT,
  LIST,
  MINIQUIZ,
  MOREDETAILS,
  PHRASE,
  PRESENTATION,
  QNA,
  QNASTRUCTURE,
  QUESTIONANDANSWER,
  READINGCOMPREHENSION,
  SENTENCES,
  SENTENCEBUILDER,
  SENTENCETRANSLATION,
  STRUCTURE,
  TABLE,
  TEXTBOX,
  TEXTLIST,
  USAGE,
  VIDEO,
]

export const LIST_TYPES = [
  // CONVERSATION,
  DIALOGUE,
  EXAMPLES,
  READINGCOMPREHENSION,
  SENTENCES,
]

export default WIDGET_TYPES
