export const engBadWords = [
  "2g1c",
  "2 girls 1 cup",
  "acrotomophilia",
  "alabama hot pocket",
  "anal",
  "anilingus",
  "anus",
  "arsehole",
  "ass",
  "asshole",
  "assmunch",
  "auto erotic",
  "autoerotic",
  "babeland",
  "baby batter",
  "baby juice",
  "ball gag",
  "ball gravy",
  "ball kicking",
  "ball licking",
  "ball sack",
  "ball sucking",
  "bangbros",
  "bangbus",
  "bareback",
  "barely legal",
  "barenaked",
  "bastard",
  "bastardo",
  "bastinado",
  "bbw",
  "bdsm",
  "beaner",
  "beaners",
  "beaver cleaver",
  "beaver lips",
  "beastiality",
  "bestiality",
  "big black",
  "big breasts",
  "big knockers",
  "big tits",
  "bimbos",
  "birdlock",
  "bitch",
  "bitches",
  "blonde action",
  "blonde on blonde action",
  "blowjob",
  "blow job",
  "blow your load",
  "blue waffle",
  "blumpkin",
  "bollocks",
  "bondage",
  "boner",
  "boob",
  "boobs",
  "booty call",
  "brown showers",
  "brunette action",
  "bukkake",
  "bulldyke",
  "bullet vibe",
  "bullshit",
  "bung hole",
  "bunghole",
  "busty",
  "butthole",
  "camel toe",
  "camgirl",
  "camslut",
  "camwhore",
  "carpet muncher",
  "carpetmuncher",
  "chocolate rosebuds",
  "cialis",
  "circlejerk",
  "cleveland steamer",
  "clit",
  "clitoris",
  "clover clamps",
  "clusterfuck",
  "cock",
  "cocks",
  "coprolagnia",
  "coprophilia",
  "cornhole",
  "coon",
  "coons",
  "creampie",
  "cum",
  "cumming",
  "cumshot",
  "cumshots",
  "cunnilingus",
  "cunt",
  "darkie",
  "date rape",
  "daterape",
  "deep throat",
  "deepthroat",
  "dendrophilia",
  "dick",
  "dildo",
  "dingleberry",
  "dingleberries",
  "dirty pillows",
  "dirty sanchez",
  "doggie style",
  "doggiestyle",
  "doggy style",
  "doggystyle",
  "dog style",
  "dolcett",
  "domination",
  "dominatrix",
  "dommes",
  "donkey punch",
  "double dong",
  "double penetration",
  "dp action",
  "dry hump",
  "dvda",
  "eat my ass",
  "ecchi",
  "ejaculation",
  "erotic",
  "erotism",
  "escort",
  "eunuch",
  "fag",
  "faggot",
  "fecal",
  "felch",
  "fellatio",
  "feltch",
  "female squirting",
  "femdom",
  "figging",
  "fingerbang",
  "fingering",
  "fisting",
  "foot fetish",
  "footjob",
  "frotting",
  "fuck",
  "fuck buttons",
  "fuckin",
  "fucking",
  "fucktards",
  "fudge packer",
  "fudgepacker",
  "futanari",
  "gangbang",
  "gang bang",
  "gay sex",
  "genitals",
  "giant cock",
  "girl on",
  "girl on top",
  "girls gone wild",
  "goatcx",
  "goatse",
  "god damn",
  "gokkun",
  "golden shower",
  "goodpoop",
  "goo girl",
  "goregasm",
  "grope",
  "group sex",
  "g-spot",
  "guro",
  "hand job",
  "handjob",
  "hard core",
  "hardcore",
  "hentai",
  "homoerotic",
  "honkey",
  "hooker",
  "horny",
  "hot carl",
  "hot chick",
  "how to kill",
  "how to murder",
  "huge fat",
  "humping",
  "incest",
  "intercourse",
  "jack off",
  "jail bait",
  "jailbait",
  "jelly donut",
  "jerk off",
  "jigaboo",
  "jiggaboo",
  "jiggerboo",
  "jizz",
  "juggs",
  "kike",
  "kinbaku",
  "kinkster",
  "kinky",
  "knobbing",
  "leather restraint",
  "leather straight jacket",
  "lemon party",
  "livesex",
  "lolita",
  "lovemaking",
  "make me come",
  "male squirting",
  "masturbate",
  "masturbating",
  "masturbation",
  "menage a trois",
  "milf",
  "missionary position",
  "mong",
  "motherfucker",
  "mound of venus",
  "mr hands",
  "muff diver",
  "muffdiving",
  "nambla",
  "nawashi",
  "negro",
  "neonazi",
  "nigga",
  "nigger",
  "nig nog",
  "nimphomania",
  "nipple",
  "nipples",
  "nsfw",
  "nsfw images",
  "nude",
  "nudity",
  "nutten",
  "nympho",
  "nymphomania",
  "octopussy",
  "omorashi",
  "one cup two girls",
  "one guy one jar",
  "orgasm",
  "orgy",
  "paedophile",
  "paki",
  "panties",
  "panty",
  "pedobear",
  "pedophile",
  "pegging",
  "penis",
  "phone sex",
  "piece of shit",
  "pikey",
  "piss",
  "pissing",
  "pissflaps",
  "piss pig",
  "pisspig",
  "playboy",
  "pleasure chest",
  "pole smoker",
  "ponyplay",
  "poof",
  "poon",
  "poontang",
  "punany",
  "poop chute",
  "poopchute",
  "porn",
  "porno",
  "pornography",
  "prince albert piercing",
  "pthc",
  "pubes",
  "pussy",
  "queaf",
  "queef",
  "quim",
  "raghead",
  "raging boner",
  "rape",
  "raping",
  "rapist",
  "rectum",
  "reverse cowgirl",
  "rimjob",
  "rimming",
  "rosy palm",
  "rosy palm and her 5 sisters",
  "rusty trombone",
  "sadism",
  "santorum",
  "scat",
  "schlong",
  "scissoring",
  "semen",
  "sex",
  "sexcam",
  "sexo",
  "sexy",
  "sexual",
  "sexually",
  "sexuality",
  "shaved beaver",
  "shaved pussy",
  "shemale",
  "shibari",
  "shit",
  "shitblimp",
  "shitty",
  "shota",
  "shrimping",
  "skeet",
  "slanteye",
  "slut",
  "s&m",
  "smut",
  "snatch",
  "snowballing",
  "sodomize",
  "sodomy",
  "spastic",
  "spic",
  "splooge",
  "splooge moose",
  "spooge",
  "spread legs",
  "spunk",
  "strap on",
  "strapon",
  "strappado",
  "strip club",
  "style doggy",
  "suicide girls",
  "sultry women",
  "swastika",
  "swinger",
  "tea bagging",
  "threesome",
  "throating",
  "thumbzilla",
  "tight white",
  "tit",
  "tits",
  "titties",
  "titty",
  "tongue in a",
  "topless",
  "tosser",
  "towelhead",
  "tranny",
  "tribadism",
  "tub girl",
  "tubgirl",
  "twat",
  "twink",
  "twinkie",
  "two girls one cup",
  "upskirt",
  "urethra play",
  "urophilia",
  "vagina",
  "venus mound",
  "viagra",
  "vibrator",
  "violet wand",
  "vorarephilia",
  "voyeurweb",
  "vulva",
  "wank",
  "wetback",
  "wet dream",
  "white power",
  "whore",
  "worldsex",
  "wrapping men",
  "wrinkled starfish",
  "xxx",
  "yaoi",
  "yellow showers",
  "yiffy",
  "zoophilia",
]

export const jpnBadWords = [
  "3p",
  "g スポット",
  "s ＆ m",
  "sm",
  "sm女王",
  "xx",
  "アジアのかわいい女の子",
  "アスホール",
  "アナリングス",
  "アナル",
  "イラマチオ",
  "エクスタシー",
  "エスコート",
  "エッチ",
  "エロティズム",
  "エロティック",
  "オーガズム",
  "オカマ",
  "おしりのあな",
  "おっぱい",
  "おなに",
  "お何",
  "オッパイ",
  "オナニー",
  "オマンコ",
  "おもらし",
  "カーマスートラ",
  "カント",
  "クリトリス",
  "グループ・セックス",
  "グロ",
  "クンニリングス",
  "ゲイ・セックス",
  "ゲイボーイ",
  "ゴールデンシャワー",
  "コカイン",
  "ゴックン",
  "サディズム",
  "しばり",
  "スウィンガー",
  "スカートの中",
  "スカトロ",
  "ストラップオン",
  "ストリップ劇場",
  "スラット",
  "スリット",
  "セクシーな 10 代",
  "セックス",
  "ソドミー",
  "ちんこ",
  "ディープ・スロート",
  "ディック",
  "ディルド",
  "デートレイプ",
  "テレフォンセックス",
  "ドッグスタイル",
  "トップレス",
  "なめ",
  "ニガー",
  "ヌード",
  "ネオ・ナチ",
  "ハードコア",
  "パイパン",
  "バイブレーター",
  "バック・スタイル",
  "パンティー",
  "ビッチ",
  "ファック",
  "フィスト",
  "フェティッシュ",
  "フェラチオ",
  "ふたなり",
  "ぶっかけ",
  "フック",
  "プリンス アルバート ピアス",
  "プレイボーイ",
  "ベアバック",
  "ペニス",
  "ペニスバンド",
  "ボーイズラブ",
  "ボールギャグ",
  "ホモ",
  "ポルノ",
  "ポルノグラフィー",
  "ボンテージ",
  "マザー・ファッカー",
  "マスターベーション",
  "まんこ",
  "やおい",
  "やりまん",
  "ラティーナ",
  "レイプ",
  "レズビアン",
  "ロリータ",
  "淫乱",
  "陰毛",
  "革抑制",
  "騎上位",
  "巨根",
  "巨乳",
  "強姦犯",
  "玉なめ",
  "玉舐め",
  "緊縛",
  "近親相姦",
  "後背位",
  "合意の性交",
  "拷問",
  "殺し方",
  "殺人事件",
  "殺人方法",
  "支配",
  "児童性虐待",
  "自己愛性",
  "射精",
  "手コキ",
  "獣姦",
  "女の子",
  "女王様",
  "女子高生",
  "女装",
  "新しいポルノ",
  "人妻",
  "人種",
  "性交",
  "正常位",
  "生殖器",
  "精液",
  "足フェチ",
  "足を広げる",
  "大陰唇",
  "脱衣",
  "茶色のシャワー",
  "中出し",
  "潮吹き女",
  "潮吹き男性",
  "直腸",
  "剃毛",
  "貞操帯",
  "奴隷",
  "二穴",
  "乳首",
  "尿道プレイ",
  "覗き",
  "売春婦",
  "縛り",
  "噴出",
  "糞",
  "糞尿愛好症",
  "糞便",
  "平手打ち",
  "変態",
  "勃起する",
  "夢精",
  "毛深い",
  "誘惑",
  "幼児性愛者",
  "裸",
  "裸の女性",
  "乱交",
  "両性",
  "両性具有",
  "両刀",
  "輪姦",
  "卍",
  "宦官",
  "肛門",
  "膣"
]

export const korBadWords = [
  "강간",
  "개새끼",
  "개자식",
  "개좆",
  "개차반",
  "거유",
  "계집년",
  "고자",
  "근친",
  "노모",
  "니기미",
  "뒤질래",
  "딸딸이",
  "때씹",
  "또라이",
  "뙤놈",
  "로리타",
  "망가",
  "몰카",
  "미친",
  "미친새끼",
  "바바리맨",
  "변태",
  "병신",
  "보지",
  "불알",
  "빠구리",
  "사까시",
  "섹스",
  "스와핑",
  "쌍놈",
  "씨발",
  "씨발놈",
  "씨팔",
  "씹",
  "씹물",
  "씹빨",
  "씹새끼",
  "씹알",
  "씹창",
  "씹팔",
  "암캐",
  "애자",
  "야동",
  "야사",
  "야애니",
  "엄창",
  "에로",
  "염병",
  "옘병",
  "유모",
  "육갑",
  "은꼴",
  "자위",
  "자지",
  "잡년",
  "종간나",
  "좆",
  "좆만",
  "죽일년",
  "쥐좆",
  "직촬",
  "짱깨",
  "쪽바리",
  "창녀",
  "포르노",
  "하드코어",
  "호로",
  "화냥년",
  "후레아들",
  "후장",
  "희쭈그리"
]

export const dnoobWords = [...engBadWords, ...jpnBadWords, ...korBadWords]