import React, { useEffect, useState } from 'react'

const QnAStructureWidget = props => {
    return (
        <div>
            <p>Q&A Structure Widget</p>
        </div>
    )
}

export default QnAStructureWidget