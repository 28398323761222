import React from 'react'

import { Icon } from 'semantic-ui-react'
import { AddCellOrRow } from '../../styles/GridTool.styles'

interface AddCellOrGridRowProps {
  handleClicked: () => void
  addsCell?: boolean
}

const AddCellOrGridRow: React.FC<AddCellOrGridRowProps> = ({
  handleClicked,
  addsCell = false, // dictates whether or not the button should be
  // horizontal (adds row) or vertical (adds cell)
}) => {
  return (
    <AddCellOrRow
      isAddCell={addsCell}
      onClick={() => handleClicked()}
    >
      <Icon name='plus' fitted />
    </AddCellOrRow>
  )
}

export default AddCellOrGridRow
