import React, { useCallback, useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { AxiosError } from 'axios'

import axios from 'src/api'

const adminCore = ({ component: Component, ...rest }) => {
  const admin = rest.roles.includes('admin')
  const [pingFailed, setPingFailed] = useState(false)

  // const ping = useCallback(async () => {
  //   try {
  //     await axios.get('/user/ping', { params: { path: location.pathname } })
  //   } catch (err) {
  //     const error = err as AxiosError
  //     if (error.response?.status === 403) {
  //       setPingFailed(true)
  //     }
  //   }
  // }, [location.pathname])

  // useEffect(() => {
  //   ping()
  // }, [location.pathname])

  return (
    <Route
      {...rest}
      render={(props) => (
        admin ? (rest.render?.(props) || <Component {...props} />) : <Redirect to='/' />
      )}
    />
  )
}

const mapStateToProps = (state) => ({ roles: state.auth.roles })

const AdminRoute = connect(mapStateToProps)(adminCore)

export default AdminRoute
