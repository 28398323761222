import styled from 'styled-components'
import tw from 'twin.macro'
import triangles from '../../../assets/sound_popup/pattern_white.svg'

export const AlignedRight = styled.div`
  ${tw`tw-ml-auto tw-mr-0`}
`

export const AdminFields = styled.section`
  ${tw`tw-my-2 tw-p-4 tw-bg-slate-700 tw-rounded-lg`}
`

export const AdminFieldView = styled.div`
  ${({ active }) => active && tw`tw-border-l tw-border-white tw-ml-4`}

  >div {
    ${tw`tw-bg-slate-700 tw-p-4 tw-rounded-r-lg tw-h-full tw-divide-y tw-divide-white tw-overflow-y-scroll tw-flex tw-flex-col tw-gap-2`}
    min-height: 100%;
    max-height: 600px;
    min-width: 350px;
  }
`

export const AdminView = styled.div`
  ${tw`tw-pb-4 tw-px-2`}

  h2, h3, h4 {
    ${tw`tw-text-white tw-font-bold tw-py-2 tw-my-2`}
  }
  h3 {
    font-size: 1.3rem;
  }
`

export const AdminButtonGroup = styled.section`
  ${tw`tw-grid tw-grid-cols-3 tw-gap-2`}
  
  button { 
    min-width: max-content;
    ${tw`tw-flex tw-items-stretch tw-gap-2`}
  }
`

export const KeyRing = styled.ul`
  max-width: 300px;
  ${tw`tw-flex tw-flex-row tw-flex-wrap tw-gap-2`}
  li { list-style: none; }
`

export default styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.85);
  background-image: url(${triangles});
  background-blend-mode: screen;
  background-size: 65%;
  border-radius: 15px;
  opacity: 0.99;
  min-width: 330px;
  padding: 20px;
  width: ${({ isMobile }) => isMobile ? '330px' : ''};
  max-width: ${({ isMobile }) => isMobile ? '330px' : ''};
  ${tw`tw-shadow-xl tw-flex`}

  // headernav is 801, mobile nav menu is 998 & 999, semantic load spinner is 1000
  z-index: 997;

  span, ruby {
    font-family: 'Milliard Book', sans-serif;
  }

  ruby rt {
    font-size: 0.7em;
  }

  h4.ui.header, .sub.header, .field label {
    color: white !important;
  }

  .record-sounds>div:focus {
    outline: 2px solid yellow;
  }

  .exit {
    position: absolute;
    background-color: #f57504;
    top: -10px;
    right: -10px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    border: 4px solid var(--off-white);
    z-index: 2;
    cursor: pointer;

    &:focus {
      outline: 2px solid #333;
    }

    &>div {
      position: relative;
      border: 2px solid black;
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    i {
      position: relative;
      color: var(--off-white);
      top: 4px;
      left: 5px;
    }
    
  }

  .decoration {
    position: absolute;
  }
  .decoration.top {
    top: 0;
    right: 0;
  }
  .decoration.bottom {
    transform: rotate(180deg);
    left: 0;
    bottom: 0;
  }

  h2 {
    text-align: center;
    font-family: "Milliard Book";
  }

  .no-display {
    display: none;
    visibility: hidden;
  }
  .add-notebook {
    color: white;
    
    h2 {
      font-weight: bold;
      font-size: 1.3rem;
      padding-bottom: 8px;
    }

    aside {
      font-family: 'Jost';
      margin-bottom: 5px;
    }
  }
  .selinput {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    >div {
      display: flex;
      position: relative;
      width: 100%;
    }

    input {
      margin: 0;
      width: 90%;
      border: 1px solid black;
      border-radius: 0;
      border-left: none;
      background-color: white;
      font-family: "Milliard Book";
      padding: 5px 8px;
      color: var(--blue-gray);
    }
    button {
      font-weight: bold;
      padding: 5px 10px;
      border: 1px solid white;
      color: white;
      background-color: transparent;
      cursor: pointer;
      font-family: "Milliard Book";
    }
    button:hover {
      background-color: white;
      color: var(--blue-gray);
    }
  }
  .pseudo-select {
    width: 32px;
    border: 1px solid black;
    border-right: none;
    background-color: white;
    cursor: pointer;

    p {
      color: var(--blue-gray);
      margin: 0;
      padding: 0;
      font-size: 1rem;
    }

    &:hover {
      background-color: #ddd;
    }
  }
  .focused {
    background-color: #ddd;
  }
  .pseudo-options {
    position: absolute;
    top: 40px;
    left: 0;
    right: 9px;
    background-color: white;
    color: var(--blue-gray);
    border: 1px solid black;
    border-top: none;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 2;

    >div {
      padding: 5px 10px;
    }
    >div:hover {
      background-color: #ddd;
    }
  }
  .record-text {
    background-color: white;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: bold;
    padding: 10px;
    align-items: self-end;
  }
  .record-sounds {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    margin: 1rem 0 !important;
  }
  .record-sounds > div {
    flex-direction: column;
    margin: 0 8px;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    cursor: pointer;
    font-family: "Milliard Book";
    flex-basis: 85px;
  }

  @media (max-width: 768px) {
    form.ui.form {
      width: 50%;
      margin: 0 auto;
    }

    .record-sounds {
      width: 100%;
      margin: 0 auto;
    }
  }
`

export const Speaker = styled.div`
  position: relative;
  padding-top: 2rem;
  display: grid;
  width: ${({ isMobile }) => isMobile && '70px'};

  :before {
    content: '${({ isDefault }) => isDefault && 'Default'}';
    justify-self: center;
    position: absolute;
    top: 0;
  }
`
