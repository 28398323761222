import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { BaseToolProps } from './interfaces/Tools.interface'
import { REDUX_NAV_ACTIONS } from 'src/actions/types'

import useStaffRoles from 'src/hooks/useStaffRoles'

import BaseTool from './styles/BaseTool.styles'
import FZErrorBoundary from 'src/components/error_boundary/FZErrorBoundary'
import { ClientView, EnterEditModeButton, ToolLabel } from './parts'
import useToolkitStore from 'src/stores/toolkit.store'
import DraggablePreview from './parts/DraggablePreview'
import useWidget from '../../hooks/useWidget'

const OUTLINE_COLORS = ['#91cdfb', '#ff9c99', '#92d180', '#daa9d9', '#d07fa1']

const CourseCreationToolkit: React.FC<BaseToolProps> = ({
  children,
  widget_id,
  widget_type,
  course,
  index,
  widgetsLength,
  draggable_props,
  defaultOpenToolkit,
  fetchCounter
}) => {
  const history = useHistory()
  const dispatchAdminView = useDispatch()
  const editingWidgetIds = useToolkitStore((state) => state.editingWidgetIds)
  const previewingWidgetIds = useToolkitStore((state) => state.previewingWidgetIds)
  const removeWidgetValues = useToolkitStore((state) => state.removeWidgetValues)
  const setAlteredId = useToolkitStore((state) => state.setAlteredId)
  const [isStaffRole] = useStaffRoles('admin', 'dev', 'teacher', 'design')
  const tool_ref = useRef(null)

  // note to self:
  // `fetchWidget` resolves in `widget` with uninjected text
  const [widget, fetchWidget] = useWidget(widget_id)
  const [injectedWidget, fetchInjected] = useWidget(widget_id, true)

  // useEffect(() => {
  //   console.log(draggable_props)
  // }, [draggable_props])

  useEffect(() => {
    fetchInjected()
  }, [fetchCounter])

  useEffect(() => {
    if (!isStaffRole) {
      dispatchAdminView({
        type: REDUX_NAV_ACTIONS.SET_ADMIN_VIEW,
        payload: { admin_view: false }
      })
      history.replace('/home')
    }
  }, [isStaffRole])

  useEffect(() => {
    fetchInjected()
    if (!editingWidgetIds?.includes(widget_id)) {
      fetchWidget()
      removeWidgetValues(widget_id)
      setAlteredId(widget_id, false)
    }
  }, [editingWidgetIds])

  const widgetName = widget_type === 'MiniQuizWidget' ? 'LessonQuizWidget' : widget_type

  return (
    <BaseTool
      ref={tool_ref}
      outlineColor={OUTLINE_COLORS[index % (OUTLINE_COLORS.length - 1)]}
    >
    <FZErrorBoundary>
      {(previewingWidgetIds?.includes(widget_id) && widget) && (
        <DraggablePreview
          course={course}
          id={widget_id}
          index={index}
          tool_ref={tool_ref}
          widgetType={widget_type}
          widgetsLength={widgetsLength}
          widget={injectedWidget}
        />
      )}
      {!defaultOpenToolkit && (
        <>
          <ToolLabel
            tool_open={editingWidgetIds.includes(widget_id)}
            text={widgetName}
            draggable_props={draggable_props}
            is_draft={widget?.is_draft}
          />
          <EnterEditModeButton
            widget_id={widget_id}
            title={`${editingWidgetIds.includes(widget_id) ? 'Close' : 'Open'} ${widgetName} Tool`}
          />
        </>
      )}
      {defaultOpenToolkit || editingWidgetIds.includes(widget_id) ? (
        children
      ) : (
        <ClientView
          course={course}
          id={widget_id}
          index={index}
          widgetType={widget_type}
          widgetsLength={widgetsLength}
          widget={injectedWidget}
          fetchCounter={fetchCounter}
        />
      )}
    </FZErrorBoundary>
    </BaseTool>
  )
}

export default CourseCreationToolkit
