import { CourseInfo } from 'src/types'
import styled from 'styled-components'

interface StyleManagerProps {
  courseInfo?: CourseInfo
}

export default styled.div.attrs((props: StyleManagerProps) => ({
  color: props.courseInfo?.color || '',
  alt_color_2: props.courseInfo?.alt_color_2 || '',
  alt_color_3: props.courseInfo?.alt_color_3 || '',
  alt_color_4: props.courseInfo?.alt_color_4 || '',
  alt_color_5: props.courseInfo?.alt_color_5 || '',
  alt_color_6: props.courseInfo?.alt_color_6 || '',
  brushstrokeBackground: props.courseInfo?.alt_color_3 || '',
}))<StyleManagerProps>`
  .fz-placeholder-gradient {
    .placeholder {
      background: ${(props) => `linear-gradient(90deg, ${props.color}, ${props.alt_color_2}, ${props.color})`};
      background-size: 200% 200%;

      -webkit-animation: placeholder_shimmer 5s ease infinite;
      -moz-animation: placeholder_shimmer 5s ease infinite;
      animation: placeholder_shimmer 5s ease infinite;
    }
  }
  .fz-dark-font {
    color: var(--blue-gray) !important;
    font-size: var(--reg-font-size) !important;
  }
  .fz-colored-header {
    color: ${(props) => props.color} !important;
  }
  .fz-colored-font {
    color: ${(props) => props.color} !important;
    font-size: var(--reg-font-size);
  }
  .fz-colored-title {
    color: ${(props) => props.color} !important;
  }
  .fz-colored-font-disabled {
    color: #ccd !important;
    font-size: var(--reg-font-size) !important;
  }
  .fz-colored-font-2 {
    color: ${(props) => props.alt_color_3} !important;
    font-size: var(--reg-font-size) !important;
  }
  .fz-colored-background {
    background-color: ${(props) => props.color} !important;
  }
  .fz-colored-background-light {
    background-color: ${(props) => props.color}25 !important;
    color: var(--blue-grey) !important;
  }
  .fz-hovered-background {
    &:hover {
      background-color: ${(props) => props.alt_color_2} !important;
    }
  }
  .fz-colored-background-disabled {
    background-color: #ccd !important;
  }
  .fz-sub-colored-background {
    background-color: ${(props) => props.alt_color_3} !important;
  }
  .fz-brushstroke-colored-font {
    color: ${(props) => props.brushstrokeBackground} !important;
  }
  .fz-colored-border {
    border-color: ${(props) => props.color} !important;
  }
  .fz-sub-colored-border {
    border-color: ${(props) => props.alt_color_3} !important;
  }
  .fz-play-all {
    color: white !important;
    background-color: #28c6aa !important;

    &:focus-visible {
      outline: 2px solid #333;
    }
  }
  .fz-stop-all {
    color: white !important;
    background-color: #e83563 !important;

    &:focus-visible {
      outline: 2px solid #333;
    }
  }
  .fz-colored-button {
    color: white !important;
    background-color: ${(props) => props.color} !important;
    border: 2px solid ${(props) => props.color} !important;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 auto;
    cursor: pointer;
    font-size: 1.2rem;

    &:hover {
      background-color: ${(props) => props.alt_color_2} !important;
      border-color: ${(props) => props.alt_color_2} !important;
      color: var(--off-white) !important;
    }
    &:disabled {
      background-color: var(--blue-gray) !important;
      border-color: var(--blue-gray) !important;
      color: var(--off-white) !important;
      cursor: default;
    }
  }
  .fz-button-inverted {
    color: ${(props) => props.color} !important;
    background-color: white !important;
    border: 2px solid transparent;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 0 auto;
    cursor: pointer;
    font-size: 1.2rem;

    &:hover {
      color: white !important;
      background-color: ${(props) => props.alt_color_2} !important;
      border-color: ${(props) => props.alt_color_2} !important;
    }
    &:disabled {
      background-color: var(--off-white) !important;
      border-color: var(--blue-gray) !important;
      color: var(--blue-gray) !important;
      cursor: default;
    }
  }
  
  // study mode styles
  .sm-hidden {
    opacity: 0;

    :hover {
    opacity: 100%;
  }
`
