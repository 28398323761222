import React, { useEffect, useState } from 'react'

const PhraseWidgetEditor = props => {
    return (
        <div>

        </div>
    )
}

export default PhraseWidgetEditor