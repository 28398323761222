import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'

const DialoguePreview = props => {
    return <Table>
        <Table.Header>
            <Table.HeaderCell>Speaker</Table.HeaderCell>
            <Table.HeaderCell>Text</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
            {props.data.map(e => <Table.Row><Table.Cell>{e.speaker}</Table.Cell><Table.Cell>{e.text}</Table.Cell></Table.Row>)}
        </Table.Body>
    </Table>
}

export default DialoguePreview