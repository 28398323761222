import React from 'react'
import { Conversation } from 'src/types'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import ConversationChatBubble from 'src/components/fz_courses/widgets/ConversationChatBubble'
import {
  ConversationLine,
  ConversationStack,
  ConvoBlockBody,
  ConvoSpeaker,
  DeleteConversationLineButton,
  ResponsePairs,
} from '../../styles/ConversationTool.styles'
import { ListInputDivider, ToolInput } from '../../parts'
import { Icon } from 'semantic-ui-react'

interface ConversationBlockProps {
  convoId: string
  convoNumber: number
  conversation: Conversation
  addLineToConversation: (convoNum: number) => void
  removeLineFromConversation: (convoNum: number, rowIdx: number) => void
  onEdit?: () => void
}

const ConversationBlock: React.FC<ConversationBlockProps> = ({
  convoId,
  convoNumber,
  conversation,
  addLineToConversation,
  removeLineFromConversation,
  onEdit = () => {},
}) => {
  const { _id } = conversation
  return (
    <ConvoBlockBody>
      <ResponsePairs>
        <Droppable droppableId={`drop-convo_lines-${convoNumber}-${convoId}`} type='CONVO_LINE'>
          {(provided, snapshot) => (
            <div
              style={{ width: '100%' }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
            {conversation?.other_lang?.map((c_ol, i) => (
              <Draggable
                draggableId={`convo-line-drag-${convoNumber}-${convoId}-${i}`}
                index={i}
                key={`${convoId}-${convoNumber}-convo-line-${i}`}
              >
                {(innerProvided, innerSnapshot) => (
                  <div
                    key={`${convoId}-${i}`}
                    ref={innerProvided.innerRef}
                    {...innerProvided.draggableProps}
                    {...innerProvided.dragHandleProps}
                  >
                    <ConvoSpeaker key={i}>
                      <ConversationLine>
                        <ConversationChatBubble icon={conversation?.icons?.[i % 2]} inToolView />
                        <ConversationStack>
                          <ToolInput
                            value={`conversations.${convoNumber}.other_lang.${i}`}
                            placeholder='Target Language'
                            onEdit={onEdit}
                          />
                          <ToolInput
                            value={`conversations.${convoNumber}.english.${i}`}
                            placeholder='English'
                            onEdit={onEdit}
                          />
                        </ConversationStack>
                        <DeleteConversationLineButton
                          type='button'
                          title='Delete this conversation segment'
                          onClick={() => removeLineFromConversation(convoNumber, i)}
                        >
                          <Icon name='delete' size='large' fitted />
                        </DeleteConversationLineButton>
                      </ConversationLine>
                    </ConvoSpeaker>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
          )}
        </Droppable>
      </ResponsePairs>
      <ListInputDivider
        borderStyle='dashed'
        handleClicked={() => addLineToConversation(convoNumber)}
      />
    </ConvoBlockBody>
  )
}

export default ConversationBlock
