import React, { useState } from 'react'
import axios from '../../../../api'

import { Button, Form } from 'semantic-ui-react'

const SoundUploader = () => {
  const [uploadFile, setUploadFile] = useState(null)
  const [uploading, setUploading] = useState(false)

  const uploadSound = () => {
    setUploading(true)

    const data = new FormData()
    data.append('sound', uploadFile, uploadFile.name)

    axios
      .post('/sounds/upload', data, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then(() => {
        // if (res.data.success) {
        //   console.log(res.data.body.rows)
        // }
        setUploading(false)
      })
  }

  const fileChange = (event) => {
    setUploadFile(event.target.files[0])
  }

  return (
    <div>
      <Form onSubmit={uploadSound}>
        <Form.Field>
          <Button as='label' htmlFor='file' type='button'>
            Choose file...
          </Button>
          <input type='file' id='file' hidden onChange={fileChange} />
        </Form.Field>
        <Button loading={uploading} disabled={uploading || !uploadFile} type='submit'>
          Upload
        </Button>
      </Form>
    </div>
  )
}

export default SoundUploader
