import React, { useEffect, useRef, useState } from 'react'

import { Button, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react'

import useMobile from '../../../hooks/useMobile'
import useStudyMode from '../hooks/useStudyMode'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import usePlayAll from '../hooks/usePlayAll'
import LineSegmentConvo from './globalWidgetComponents/LineSegmentConvo'
import getCourseInfo, { getTitle } from '../../../util/courseInfo'

import tomoko from '../../../assets/conversation_widget/conversation_profile_1.png'
import kenji from '../../../assets/conversation_widget/conversation_profile_2.png'
import mari from '../../../assets/conversation_widget/conversation_profile_3.png'
import ren from '../../../assets/conversation_widget/conversation_profile_4.png'
import rika from '../../../assets/conversation_widget/conversation_profile_5.png'
// eslint-disable-next-line
import loli from '../../../assets/conversation_widget/conversation_profile_6.png'
// eslint-disable-next-line
import young_girl from '../../../assets/conversation_widget/conversation_profile_6.png'
import guy from '../../../assets/conversation_widget/conversation_profile_7.png'
import lady from '../../../assets/conversation_widget/conversation_profile_8.png'
import lady2 from '../../../assets/conversation_widget/conversation_profile_9.png'

const chatIcons = {
  tomoko: [tomoko, '#316498'],
  kenji: [kenji, '#b15555'],
  mari: [mari, '#65a462'],
  ren: [ren, '#8462a4'],
  rika: [rika, '#98315a'],
  loli: [loli, '#8da5b2'],
  young_girl: [young_girl, '#8da5b2'],
  guy: [guy, '#cf8747'],
  lady: [lady, '#34c49b'],
  lady2: [lady2, '#332e97'],
}

const genders = {
  tomoko: 'female',
  kenji: 'male',
  mari: 'female',
  ren: 'male',
  rika: 'female',
  loli: 'female',
  young_girl: 'female',
  guy: 'male',
  lady: 'female',
  lady2: 'female'
}

/* eslint-disable react/prop-types */
const ConversationBlock = (props) => {
  const [showEnglish, setShowEnglish] = useState(false)
  const [click, setClick] = useState(0)
  const { title, other_lang, english, commentary } = props.convo
  const [sounds, setSounds] = useState({})
  const isMobile = useMobile()
  const soundsRef = useRef(sounds)
  const { playAll, play, stop, current, register, next } = usePlayAll()
  const icons = props.convo.icons?.length > 1 ? props.convo.icons : ['tomoko', 'ren']
  const { inStudyMode, hideEnglish, hideOtherLang } = useStudyMode()

  useEffect(() => {
    setClick(click + 1)
  }, [showEnglish])

  useEffect(() => {
    // console.log(sounds)
    soundsRef.current = sounds
  }, [sounds])

  return (
    <WidgetWrapper
      widget={{ widgetType: 'ConversationWidget', widgetId: props.widget }}
    >
      <Segment
        style={{
          border: '0px solid transparent',
          boxShadow: !isMobile ? `
            5px 5px 0px 3px ${getCourseInfo(getTitle(props.course)).alt_color_3},
            -2px -2px 5px -3px rgb(0.4, 0.4, 0.4, 0.2),
            6px 6px 5px -3px rgb(0.7, 0.7, 0.7, 0.3)` : '',
          borderRadius: '15px',
          padding: isMobile ? '10px 5px' : '15px',
          marginBottom: props.final_block ? '10px' : '20px',
          marginTop: '0',
        }}
      >
        <Grid verticalAlign="middle">
          <Grid.Row
            style={{
              padding: isMobile ? '10px 0' : '10px',
              margin: isMobile ? '0 10px' : '',
              borderBottom: isMobile ? '1px dashed #ddd' : '',
            }}
          >
            <Grid.Column
              width={13}
              style={{
                paddingLeft: isMobile ? '14px' : '10px',
                paddingRight: isMobile ? '14px' : '10px',
              }}
            >
              <Header
                className="fz-colored-title"
                style={{
                  fontFamily: 'Milliard Bold',
                  fontSize: isMobile ? 'var(--large-font-size)' : 'var(--reg-font-size)',
                  fontFeatureSettings: 'tnum',
                  fontVariantNumeric: 'tabular-nums',
                }}
              >
                {props.numericalOrdering && `${(props.idx + 1).toString()}. `}
                <SoundRenderer text={title} />
              </Header>
            </Grid.Column>
            <Grid.Column width={3} textAlign="right">
              <Button
                onClick={() => setShowEnglish(!showEnglish)}
                icon={showEnglish ? 'eye' : 'eye slash'}
                circular
              />
            </Grid.Column>
          </Grid.Row>
          {!isMobile && <Divider style={{ margin: '0 20px' }} />}
        </Grid>
        <Grid style={{ padding: '0' }}>
          <Grid.Row style={{ paddingTop: '0px' }}>
            <Grid.Column width={16} style={{ padding: '20px' }}>
              {other_lang.map((o, idy) => (
                <div
                  key={idy}
                  style={{
                    display: 'flex',
                    flexDirection: idy % 2 === 0 ? 'row' : 'row-reverse',
                  }}
                >
                  <img
                    src={idy % 2 === 0 ? chatIcons[icons[0]][0] : chatIcons[icons[1]][0]}
                    style={{
                      position: 'absolute',
                      marginTop: '3px',
                      right: idy % 2 !== 0 ? '20px' : '',
                      backgroundColor: 'white',
                      clipPath: 'circle(31.5px)'
                    }}
                  />
                  <LineSegmentConvo
                    widgetType="conversation"
                    line_num={idy}
                    index={idy}
                    indexing={'alpha'}
                    showEnglish={showEnglish}
                    other_lang={o}
                    english={english[idy]}
                    displayOrder={props.displayOrder}
                    shouldOrder={false}
                    displayIn="group"
                    widget={props.id}
                    plays_sound={props.plays_sound}
                    conversation_length={other_lang.length}
                    click={click}
                    current={current}
                    next={next}
                    register={register}
                    textColor={idy % 2 === 0 ? chatIcons[icons[0]][1] : chatIcons[icons[1]][1]}
                    male={genders[icons[idy % 2 === 0 ? 0 : 1]] === 'male'}
                    female={genders[icons[idy % 2 === 0 ? 0 : 1]] === 'female'}
                  />
                </div>
              ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {commentary && (
          <Grid style={{ padding: '0' }}>
            <Grid.Row style={{ paddingTop: '0px' }}>
              <Grid.Column width={16} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <div style={{ fontSize: '1.2rem', marginLeft: '10px', color: 'var(--blue-gray)' }}>
                  <SoundRenderer text={commentary} />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {(props.displayOrder || showEnglish || inStudyMode) && (
          <Button
            style={{ marginTop: '10px', marginLeft: '50px' }}
            className={current === -1 ? 'fz-play-all' : 'fz-stop-all'}
            onClick={() => play(`${props.convo}-${props.idx}`)}
          >
            <Icon name="play" />
            {current === -1 ? 'Play All' : 'Stop All'}
          </Button>
        )}
      </Segment>
    </WidgetWrapper>
  )
}

const ConversationWidget = (props) => {
  const [widget, setWidget] = useState(props.widget)
  const [loading, setLoading] = useState(!props.widget)
  const [conversations, setConversations] = useState(props.widget.conversations)
  
  // true = Tar / Eng, false = Eng / Tar
  const [displayOrder, setDisplayOrder] = useState(props.widget.displayOrder)
  const [numericalOrdering, setNumericalOrdering] = useState(props.widget.numericalOrdering)

  useEffect(() => {
    if (props.widget) setLoading(false)
  }, [props.widget])

  if (loading) return <PlaceholderWidget widget_type="ConversationWidget" />
  return (
    <>
      {conversations?.map((d, i) => (
        <ConversationBlock
          data-t={`widget:conversation-${widget._id}-block:${i}`}
          convo={d}
          key={i}
          idx={i}
          // changed this at one point hope it's not broke
          plays_sound={widget.plays_sound}
          displayOrder={displayOrder}
          numericalOrdering={numericalOrdering}
          widget={props.id}
          final_block={i === conversations.length - 1}
          course={props.course}
        />
      ))}
    </>
  )
}

export default ConversationWidget
