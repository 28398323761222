import styled from 'styled-components'

export default styled.div`
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  display: flex;
  justify-content: center;
  align-items: center;

  .tomoko-success {
    position: relative;
    width: 60%;
    height: 50%;
    border-radius: 15px;
    padding: 15px;
    background-color: var(--off-white);
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.15);
    
    display: flex;
    justify-content: space-between;
    align-items: center;

    overflow: hidden;

    .reset-animations, .close-popup {
      position: absolute;
      border-radius: 0px;
      z-index: 1;
    }

    .reset-animations {
      top: -2px;
      left: -2px;
      border-bottom-right-radius: 15px;
    }
    .close-popup {
      top: -2px;
      right: -5px;
      border-bottom-left-radius: 15px;
      color: var(--red) !important;
      cursor: pointer;

      :hover {
        color: var(--off-white) !important;
        background-color: var(--red) !important;
      }
    }
    
    h2, h3, p {
      color: var(--blue-gray);
      font-family: 'Jost', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }

    h2 { margin: 15px 0; }
    h3 {
      font-size: 1.8rem;
      margin: 10px 0;
    }
    p {
      font-size: 1.3rem;
      margin: 40px 0;
    }

    .fz-exclamation {
      justify-content: center;

      .fz-title {
        font-size: 2.4rem;
        span {
          font-size: 3rem;
        }
      }
    }

    .from-zero-thanks-you {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 3;

      width: 55%;
      height: 100%;
      padding: 20px;
    }

    .tomoko {
      width: 45%;
      height: 100%;
      /* background-color: tomato; */

      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 50px;
      
      img {
        object-fit: contain;
        height: 100%;
      }
    }
  } // end .tomoko-success

  @media (max-width: 768px) {
    h3, p {
      text-align: center !important;
    }

    .tomoko-success {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .from-zero-thanks-you {
        height: 60%;
        width: 100%;
        margin-bottom: auto;
        padding: 0;
      }

      .tomoko {
        position: absolute;  
        top: 150px;
        width: 250px;
        margin: auto;
      }
    }
  }

  @media (max-width: 420px) {
    .tomoko-success .tomoko {
      bottom: -150px;
    }
  }
`
