import React, { useRef } from 'react'
import { PresentationWidget as PresentationProps } from 'src/types'
import usePresentation from './presentationWidgetUtil/usePresentation'
import SlideBanner from './presentationWidgetUtil/components/SlideBanner'
import TemplateRenderer from './presentationWidgetUtil/components/TemplateRenderer'
import getCourseInfo, { getTitle } from 'src/util/courseInfo'
import {
  Slides,
  Slide,
  SlidePlaceholder,
  PresentationTitle,
  PresentationSubtitle,
  ViewSlideshow,
} from './styles/PresentationWidget.styles'

const PresentationWidget: React.FC<{ widget: PresentationProps, course: string }> = ({ widget, course }) => {
  const courseColor = getCourseInfo(getTitle(course)).alt_color_1
  const ref = useRef<HTMLDivElement>(null)
  const {
    title,
    subtitle,
    slides,
    currentSlide,
    currentItem,
    isFullscreen,
    goFullscreen,
    handleKeyPress,
  } = usePresentation(widget, ref)

  return (
    <div ref={ref} onKeyDown={handleKeyPress}>
      {isFullscreen ? (
        <Slides tabIndex={1}>
          <Slide>
            <SlideBanner title={slides[currentSlide].title} courseColor={courseColor} />
            <TemplateRenderer
              slide={slides[currentSlide]}
              slideIdx={currentSlide}
              currentItem={currentItem}
              courseColor={courseColor}
            />
          </Slide>
          {currentSlide < slides.length - 1 && (
            <Slide>
              <SlideBanner title={slides[currentSlide + 1].title} courseColor={courseColor} preview />
              <TemplateRenderer
                slide={slides[currentSlide + 1]}
                slideIdx={currentSlide}
                currentItem={currentItem}
                courseColor={courseColor}
                variants={{ hidden: { opacity: 1 }, visible: { opacity: 1 } }}
              />
            </Slide>
          )}
        </Slides>
        ) : ( 
        <SlidePlaceholder>
          <PresentationTitle>{title}</PresentationTitle>
          <PresentationSubtitle>{subtitle}</PresentationSubtitle>
          <ViewSlideshow onClick={goFullscreen}>View Slideshow</ViewSlideshow>
        </SlidePlaceholder>
      )}
    </div>
  )
}

export default PresentationWidget
