import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Transition } from '@headlessui/react'

import SideDrawerStyles from '../styles/SideDrawerStyles'
import { NavItem } from './Navigation'
import ErrorReportModal from 'src/components/modals/ErrorReportModal'
import useMobile from 'src/hooks/useMobile'
import useStaffRoles from 'src/hooks/useStaffRoles'

interface SideDrawerProps {
  open: boolean
  primaryNavigation: NavItem[]
}

const adminOnly = ['FZ Live']

const ModalTrigger = (
  <div className='drawer-button'>
    <button type='button'>Support</button>
  </div>
)

const SideDrawer: React.FC<SideDrawerProps> = ({
  open,
  primaryNavigation,
}) => {
  const isMobile = useMobile()
  const [isStaffRole] = useStaffRoles('admin', 'dev', 'teacher')
  const [willOpen, setWillOpen] = useState(true)

  const handleBeforeEnter = () => setWillOpen(false)
  const handleAfterLeave = () => setWillOpen(true)

  if (isMobile) return <></>
  return (
    <SideDrawerStyles key='side-drawer-styles' willOpen={willOpen}>
      <Transition
        show={open}
        enter='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
        enterFrom='tw-translate-x-[-300px]'
        enterTo='tw-translate-x-0'
        leave='tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
        leaveFrom='tw-translate-x-0'
        leaveTo='tw-translate-x-[-300px]'
        beforeEnter={() => handleBeforeEnter()}
        afterLeave={() => handleAfterLeave()}
        key='side-drawer-transition'
      >
        <div id='left-side-drawer' className='nav-items' key='left-side-drawer'>
          {primaryNavigation.map((item: NavItem, idx: number) => {
            if (isStaffRole || !adminOnly.includes(item.name)) {
              return (
                <div key={`${item.name}-${idx}`} className='drawer-icon'>
                  <NavLink to={item.href} style={{ textDecoration: 'none', position: 'relative' }}>
                    {item.icon}
                    <span>{item.name}</span>
                  </NavLink>
                </div>
              )
            }
            return null
          })}
          <ErrorReportModal trigger={ModalTrigger} />
        </div>
      </Transition>
    </SideDrawerStyles>
  )
}

export default SideDrawer
