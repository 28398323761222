import styled from 'styled-components'
import tw from 'twin.macro'

export const MenuItems = styled.div`
  ${tw`tw-flex tw-flex-col tw-h-full tw-overflow-y-scroll`}
  max-height: 85vh;
`

export const MenuIcon = styled.span`
  ${tw`tw-mr-1.5 tw-text-transparent`}
`

export const UpperItems = styled.div`
  ${tw`tw-pt-2 tw-px-2`}
  background-color: var(--off-white);

  * { color: var(--blue-gray); }
`

export const UserNotifications = styled.div`
  ${tw`tw-py-3 tw-border-b tw-border-gray-300`}
`

export const LowerItems = styled.div`
  ${tw`tw-py-2 tw-px-4`}
  background-color: var(--jfz-blue);

  * {
    color: var(--off-white);
  }

  p {
    ${tw`tw-pt-1 tw-pb-2`}
  }

  >div:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const JustifyBetweenWithMargin = styled.div`
  ${tw`tw-flex tw-items-center tw-justify-between tw-my-3`}
  flex: 1 0 auto;
`
