import {
  recordSplit,
  generateTokens,
  createFuriData,
  cleanProgressiveTokens,
  progressivePostProcess,
} from './sound_icon_util'

import {
  createFormatStyles,
} from './ProgressiveTextSpan'

// apaーto
// apa－to
// apa–to
const romajiSpecialCaseReplace = (str) => {
  // can be either a string or a format object based on prior usage
  if (typeof str === 'string') {
    return str.replace('、', ', ').replace('ー', '–')
  }

  return str.map((s) => s.replace('、', ', ').replace('ー', '–'))
}

const capitalize = (str) => {
  // if the text starts with "ii", don't capitalize it
  if (str.trim().indexOf('ii') === 0) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const RomajiTextSpan = ({
  record,
  isMobile,
  styles,
  resizeAfter,
  bonusData,
  formatText
}) => {
  if (record.type === 'KoreanRecord') {
    return (
      <span className="text-2xl font-bold">
        {record.base_text}
      </span>
    )
  }

  const { breakdown } = record
  const { furis, splitRecord } = recordSplit(record)
  const newFuriData = createFuriData(splitRecord, furis)
  let progressiveTokens

  try {
    progressiveTokens = generateTokens(breakdown, newFuriData, {}, bonusData, 'romaji', null, formatText)
  } catch (e) {
    console.log(e)
    return <span>{record.base_text}</span>
  }

  progressiveTokens = cleanProgressiveTokens(progressiveTokens)

  // set the first progressive token to be capitalized if the last one is a period
  if (progressiveTokens.length > 1 && ['.', '。', '…'].includes(progressiveTokens[progressiveTokens.length - 1].text[0])) {
    if (progressiveTokens[0].text.join('').indexOf('ii') !== 0) progressiveTokens[0].capitalized = true
  }

  progressiveTokens = progressivePostProcess(progressiveTokens, bonusData)

  // TODO after it's working: special case, post process
  const romajiContent = progressiveTokens.map((token, idx) => {
    const preToken = progressiveTokens[idx - 1]
    const preTokenEmpty = !preToken?.text?.length && !preToken?.formatted?.length
    const secondTokenPreTokenEmpty = idx === 1 && preTokenEmpty
    let rs = []
    if (token.pre && !secondTokenPreTokenEmpty) rs.push(<span>{' '}</span>)
    if (!token.formatted?.length && !token.text?.length) return null

    const tokenText = (token.formatted?.length ? token.formatted : token.text)?.map((tk, idx) => {
      const formatStyles = typeof tk === 'object' ? createFormatStyles(tk?.format, tk?.color, tk?.highlightColor) : {}
      const maybeCapitalized = token.capitalized && idx === 0 ? capitalize(tk.text || tk) : tk.text || tk
      if (typeof maybeCapitalized === 'object' && !Array.isArray(maybeCapitalized) && !maybeCapitalized?.text) return null
      return <span style={formatStyles}>{romajiSpecialCaseReplace(maybeCapitalized)}</span>
    })
    rs = rs.concat(tokenText)
    if (token.post) rs.push(<span>{' '}</span>)
    return rs
  })

  const newStyles = { ...styles }

  const fullStringLength = romajiContent.length
  if (newStyles && !styles?.fontSize && resizeAfter && fullStringLength >= resizeAfter) {
    const newSize = (isMobile ? 0.82 : 1.1) - (fullStringLength - resizeAfter)
    * (isMobile ? 0.04 : 0.03)

    newStyles.fontSize = `${newSize}rem`
  }

  return (
    <span style={newStyles}>
      {romajiContent}
    </span>
  )
}

export default RomajiTextSpan
