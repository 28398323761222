import React, { useEffect, useState } from 'react'
import { Button, Form, Segment } from 'semantic-ui-react'

import useWidget from '../../../hooks/useWidget'

const ReadingComprehensionWidgetEditor = props => {
    const [lines, setLines] = useState([])
    const [widget, fetchWidget, loading, editWidget] = useWidget(props.id)

    useEffect(() => {
        setLines(widget?.lines || [])
    }, [widget])

    const addLine = () => {
        let tempLines = [...lines]
        tempLines.push({ other_lang: '', english: '' })
        setLines(tempLines)
    }

    const setLine = (idx, val) => {
        let tempLines = [...lines]
        tempLines[idx] = val
        setLines(tempLines)
    }

    return (
        <Segment loading={loading}>
            <Form>
                {lines?.map((l, idx) =>
                    <Form.Group widths='equal'>
                        <Form.Input
                            value={lines[idx].other_lang}
                            label={`Line ${idx + 1} Japanese`}
                            onChange={(_, { value }) => setLine(idx, { other_lang: value, english: l.english })}
                        />
                        <Form.Input
                            value={lines[idx].english}
                            label={`Line ${idx + 1} English`}
                            onChange={(_, { value }) => setLine(idx, { other_lang: l.other_lang, english: value })}
                        />
                    </Form.Group>)}
            </Form>
            <Button color='blue' icon='plus' onClick={addLine} />
            <Button color='green' icon='save' onClick={() => editWidget({ lines })} />
        </Segment>
    )
}

export default ReadingComprehensionWidgetEditor