import React from 'react'
import useMobile from 'src/hooks/useMobile'
import useActivity, { ActivityContext } from './useActivity'
import QuestionSection from './QuestionSection'
import Answers from './Answers/Answers'
import Results from './Results'
import { Space } from '@mantine/core'
import { ActivityContainer, CheckAnswer } from './Activity.styles'
import { ActivityProps } from './types'

const Activity: React.FC<ActivityProps> = ({ widget, course }) => {
  const isMobile = useMobile()
  const context = useActivity(widget)
  const { finished, checkOrNext, answeredCorrectly, selectedAnswer, typedAnswer } = context

  const Quiz = (
    <ActivityContainer data-t={`widget:activity-id:${widget?._id}`} isMobile={isMobile} course={course}>
      <QuestionSection course={course} />
      <Space h='xl' />
      <Answers />
      <CheckAnswer
        onClick={checkOrNext}
        disabled={!answeredCorrectly && selectedAnswer === null && typedAnswer === ''}
        answeredCorrectly={answeredCorrectly}
      >
        {answeredCorrectly ? 'Next' : 'Check'}
      </CheckAnswer>
    </ActivityContainer>
  )

  return (
    <ActivityContext.Provider value={context}>
      {finished
        ? <Results course={course} />
        : Quiz
      }
    </ActivityContext.Provider>
  )
}

export default Activity
