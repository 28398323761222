import axios from 'src/api'
import { AxiosError, AxiosResponse } from 'axios'
import create from 'zustand'
import { devtools } from 'zustand/middleware'

interface ToolkitStore {
  alteredWidgetIds: string[]
  editingWidgetIds: string[]
  optionsVisible: string[]
  previewingWidgetIds: string[]
  savedIds: string[]
  widgetValues: any
  removeWidgetValues: (arg: string) => void
  setAlteredId: (arg: string, changed: boolean) => void
  setEditingId: (arg: string) => void
  setOptionsVisible: (arg: string) => void
  setPreviewingWidgetIds: (id: string) => void
  setSavedIds: (id: string) => void
  setWidgetValues: (widget: any) => void
  deleteWidget: (id: string) => Promise<void>
  reset: () => void
}

/* eslint-disable max-len */
/**
 * useToolkitStore - ephemeral state management.
 * Handles toolkit state while editing.
 * When edit mode is exited the state should be reset.
 * @var {Boolean} optionsVisible show/hide the options bar for tools
 * @var {String} editingWidgetIds the ids of the current open tools
 * @var {Boolean} deleteWidgetError it's that
 * @method {Function} setOptionsVisible toggles the options bar for the currently editing widget
 * @method {Function} setEditingIds toggles the widget's render and edit view
 * @method {async Function} deleteWidget takes the id of the current widget (just in case), and deletes the widget
 * @method {Function} reset resets the above state for the next widget to be edited
 */
/* eslint-enable */
const useToolkitStore = create<ToolkitStore>((set) => ({
  alteredWidgetIds: [],
  editingWidgetIds: [],
  optionsVisible: [],
  previewingWidgetIds: [],
  savedIds: [],
  widgetValues: {},
  setAlteredId: (incoming_id: string, changed: boolean) => set((state) => {
    let alteredWidgetIds = [...state.alteredWidgetIds]

    if (alteredWidgetIds.includes(incoming_id) && !changed) {
      alteredWidgetIds = alteredWidgetIds.filter((id) => id !== incoming_id)
    } else if (changed) {
      // for some reason this one likes to triple up on ids
      alteredWidgetIds = alteredWidgetIds.filter((id) => id !== incoming_id)
      alteredWidgetIds = [...alteredWidgetIds, incoming_id]
    }

    return { ...state, alteredWidgetIds }
  }),
  setEditingId: (incoming_id: string) => set((state) => {
    const newEditingIds = [...state.editingWidgetIds]
    if (newEditingIds.includes(incoming_id)) {
      newEditingIds.splice(newEditingIds.indexOf(incoming_id), 1)
    } else {
      newEditingIds.push(incoming_id)
    }
    return ({ editingWidgetIds: newEditingIds })
  }),
  setOptionsVisible: (arg: string) => set((state) => {
    const newOptsVisibles = [...state.optionsVisible]
    if (newOptsVisibles.includes(arg)) {
      newOptsVisibles.splice(newOptsVisibles.indexOf(arg), 1)
    } else {
      newOptsVisibles.push(arg)
    }
    return ({ optionsVisible: newOptsVisibles })
  }),
  setPreviewingWidgetIds: (id: string) => set((state) => {
    const updatedPreviews = [...state.previewingWidgetIds]
    if (updatedPreviews.includes(id)) {
      updatedPreviews.splice(updatedPreviews.indexOf(id), 1)
    } else {
      updatedPreviews.push(id)
    }
    return ({ previewingWidgetIds: updatedPreviews })
  }),
  setSavedIds: (id: string) => set((state) => {
    const newSavedIds = [...state.savedIds]
    if (newSavedIds.includes(id)) {
      newSavedIds.splice(newSavedIds.indexOf(id), 1)
    } else {
      newSavedIds.push(id)
    }
    return ({ savedIds: newSavedIds })
  }),
  setWidgetValues: (widget: any) => set((state) => {
    if (!widget || typeof widget === 'string') return

    const newWidgetValues = { ...state.widgetValues }
    newWidgetValues[widget._id] = widget

    return ({ widgetValues: newWidgetValues })
  }),
  removeWidgetValues: (widget_id: string) => set((state) => {
    const newWidgetValues = { ...state.widgetValues }
    delete newWidgetValues[widget_id]
    return ({ widgetValues: newWidgetValues })
  }),
  deleteWidget: async (id) => {
    let response: AxiosResponse
    try {
      response = await axios.delete(`/widgets/${id}`)
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.message)
      console.error(error?.name)
    } finally {
      if (response?.data?.success) {
        // refresh the state
        set((state) => {
          const newWidgetValues = { ...state.widgetValues }
          delete newWidgetValues[id]
          return ({
            widgetValues: newWidgetValues,
            alteredWidgetIds: state.alteredWidgetIds.filter((wid) => wid !== id),
            editingWidgetIds: state.editingWidgetIds.filter((wid) => wid !== id),
            optionsVisible: state.optionsVisible.filter((wid) => wid !== id),
            previewingWidgetIds: state.previewingWidgetIds.filter((wid) => wid !== id),
            savedIds: state.savedIds.filter((wid) => wid !== id),
          })
        })
      }
    }
  },
  deleteWidgetError: undefined,
  reset: () => set({
    alteredWidgetIds: [],
    editingWidgetIds: [],
    optionsVisible: [],
    previewingWidgetIds: [],
    savedIds: [],
    widgetValues: {},
  }),
}))

export default useToolkitStore
