import React from 'react'

import { Icon } from 'semantic-ui-react'
import { EditPencil } from '../styles'

import useToolkitStore from '../../../../../stores/toolkit.store'

const EnterEditModeButton: React.FC<{
  title?: string
  widget_id: string
}> = ({
  title = 'Open Tool',
  widget_id,
}) => {
  const setEditing = useToolkitStore((state) => state.setEditingId)
  const openTools = useToolkitStore((state) => state.editingWidgetIds)
  const alteredWidgetIds = useToolkitStore((state) => state.alteredWidgetIds)
  const setAlteredWidgetIds = useToolkitStore((state) => state.setAlteredId)

  const promptBeforeAction = () => {
    const confirmMessage = 'You have unsaved changes. Are you sure you want to continue?'
    if (window.confirm(confirmMessage)) {
      setEditing(widget_id)
      setAlteredWidgetIds(widget_id, false)
    }
  }

  const handleOpenEditor = () => {
    if (openTools.includes(widget_id)
      && alteredWidgetIds.includes(widget_id)
    ) {
      return promptBeforeAction()
    }
    setEditing(widget_id)
  }

  // this isn't working in a consistent way, curse the internet
  // useEffect(() => {
  //   if (openTools.includes(widget_id) && alteredWidgetIds.includes(widget_id)) {
  //     window.onbeforeunload = (event) => {
  //       const e = event || window.event
  //       e.preventDefault()
  //       promptBeforeAction()
  //       return ''
  //     }
  //   } else {
  //     window.onbeforeunload = null
  //   }

  //   return () => window.onbeforeunload = null
  // }, [openTools, alteredWidgetIds])

  return (
    <EditPencil title={title}>
      <button
        type='button'
        onClick={() => handleOpenEditor()}
        disabled={!widget_id}
      >
        <Icon name='pencil alternate' />
      </button>
    </EditPencil>
  )
}

export default EnterEditModeButton
