import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { QnAWidgetBlocks } from 'src/types'
import { ListInputDivider, ToolSubheader, ToolInput } from '../../parts'
import QnAQuestion from './QnAQuestion'
import QnAResponses from './QnAResponses'
import { QnAComment } from '../../styles'

interface QnABlocksProps {
  qnaBlocks: QnAWidgetBlocks[][]
  addResponse: (blockIdx: number, qIdx: number, remove: boolean) => void
  removeResponse: (blockIdx: number, qIdx: number, remove: boolean) => void
  qna_id: string
  onEdit?: () => void
}

const QnABlocks: React.FC<QnABlocksProps> = ({
  qnaBlocks,
  addResponse,
  removeResponse,
  qna_id,
  onEdit = () => null
}) => {
  return (
    <Droppable droppableId={`drop-qna-${qna_id}`} type='QNA_BLOCK'>
      {(provided) => (
        <div
          style={{ width: '100%' }}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {qnaBlocks?.map((block, idx: number) => (
            <Draggable
              draggableId={`qna-block-drag-${idx}-${qna_id}`}
              index={idx}
              key={`${qna_id}-qna-block-${idx}`}
            >
              {(innerProvided) => (
                <div
                  key={`${qna_id}-${idx}`}
                  ref={innerProvided.innerRef}
                  {...innerProvided.draggableProps}
                >
                  <Droppable droppableId={`drop-qna_lines-${idx}-${qna_id}`} type='QNA_LINE'>
                    {(provided2) => (
                      <div
                        style={{ width: '100%' }}
                        ref={provided2.innerRef}
                        {...provided2.droppableProps}
                      >
                        {block.map((_, bIdx: number) => (
                          <Draggable
                            draggableId={`qna-line-drag-${idx}-${qna_id}-${bIdx}`}
                            index={bIdx}
                            key={`${qna_id}-${idx}-qna-line-${bIdx}`}
                          >
                            {(innerProvided2) => (
                              <div
                                key={`${qna_id}-${idx}-${bIdx}`}
                                ref={innerProvided2.innerRef}
                                {...innerProvided2.draggableProps}
                                {...innerProvided2.dragHandleProps}
                              >
                                {
                                  bIdx > 0 ? (
                                    // insert draggable here for shifting responses
                                    <QnAResponses
                                      outerBlockIndex={idx}
                                      blockIndex={bIdx}
                                      removeResponse={removeResponse}
                                      onEdit={onEdit}
                                    />
                                  ) : (
                                    <div {...innerProvided.dragHandleProps}>
                                      <ToolSubheader>
                                        {/* insert draggable here for shifting questions */}
                                        <QnAQuestion
                                          outerBlockIndex={idx}
                                          blockIndex={bIdx}
                                          onEdit={onEdit}
                                        />
                                      </ToolSubheader>
                                    </div>
                                  )
                                }
                                <ListInputDivider
                                  handleClicked={() => addResponse(idx, bIdx + 1, false)}
                                  borderStyle='dashed'
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        <QnAComment>
                          <ToolInput value={`blockComments.${idx}`} placeholder='Comment' />
                        </QnAComment>
                        {provided2.placeholder}
                      </div>
                    )}
                  </Droppable>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default QnABlocks
