import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import _ from 'lodash'

import useAPI from '../hooks/useAPI'

interface EventData {
  element?: string
  pageX?: number
  pageY?: number
  scrollX?: number
  scrollY?: number
  pageRoute: string
  date_created: number
  event: string
}

interface UserEventContextType {
  events: EventData[]
}

const UserEventContext = createContext<UserEventContextType | undefined>(undefined)

export const UserEventProvider: React.FC = ({ children }) => {
  const [events, setEvents] = useState<EventData[]>([])
  const eventBuffer = useRef<EventData[]>([])
  const [res, loading, req] = useAPI('/analytics/events', 'POST')

  useEffect(() => {
    const handleEvent = (event: MouseEvent | Event) => {
      const pageRoute = window.location.pathname
      const date_created = Date.now()
      let eventData: EventData

      if (event instanceof MouseEvent) {
        let element = (event.target as HTMLElement).getAttribute('data-t') || (event.target as HTMLElement).tagName
        const { pageX, pageY } = event

        // traverse up the DOM tree to check for data-t
        let currentElement = event.target as HTMLElement
        while (currentElement) {
          if (currentElement.getAttribute('data-t')) {
            element = `${currentElement.getAttribute('data-t')}/${element}`
          }
          currentElement = currentElement.parentElement
        }

        eventData = { event: 'click', element, pageX, pageY, pageRoute, date_created }
      } else {
        const { scrollX, scrollY } = window
        eventData = { event: 'scroll', scrollX, scrollY, pageRoute, date_created }
      }

      eventBuffer.current.push(eventData)
      setEvents([...eventBuffer.current])
    }

    const handleScroll = _.debounce(() => handleEvent(new Event('scroll')), 100)

    document.addEventListener('click', handleEvent, true)
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('click', handleEvent)
      document.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const sendEvents = async () => {
      if (eventBuffer.current.length > 0) {
        try {
          await req({ events: eventBuffer.current })
          eventBuffer.current = []
          setEvents([])
        } catch (e) {
          console.error('Error sending clicks:', e)
        }
      }
    }

    const debouncedSendEvents = _.debounce(sendEvents, 2000)
    debouncedSendEvents()

    return () => {
      debouncedSendEvents.cancel()
    }
  }, [events])

  return <UserEventContext.Provider value={{ events }}>{children}</UserEventContext.Provider>
}

export const useClickContext = () : UserEventContextType => {
  const context = useContext(UserEventContext)
  if (context === undefined) {
    throw new Error('useClickContext must be used within a ClickProvider')
  }
  return context
}
