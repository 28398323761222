import { DefaultRootState } from 'react-redux'
import { Badge, ProgressiveData, ProgressiveSettings, Subscription } from '.'

export type AUTH_STATE = {
  isSignedIn: boolean
  userId: string
  userEmail: string
  badges: Badge[]
  roles?: string[]
  access?: string[]
  subscriptions?: string[]
  progressive_data?: ProgressiveData
  progressive_settings?: ProgressiveSettings
  subs?: Subscription[]
  avatar_url?: string
  cf_avatar_url?: string
  default_avatar: {
    color:
      | 'black'
      | 'blue'
      | 'brown'
      | 'green'
      | 'orange'
      | 'pink'
      | 'purple'
      | 'red'
      | 'teal'
      | 'white'
      | 'yellow'
    version: 1 | 2 | 3
  }
  course_background:
    | 'kana'
    | 'scenery'
    | 'scenery_plus'
    | 'japan_day'
    | 'japan_night'
    | 'japan_dawn'
    | 'stationary'
    | 'study_mode'
    | 'none'
  study_mode_settings: {
    inStudyMode: boolean
    shownLanguage: 'other' | 'english'
  }
  preferred_speaker: string
  colorized_furi: boolean
  swap_furi_colors: boolean
  _id: string
  initial_login: boolean
  planSelectionAtSignup: 'free' | 'premium-monthly' | 'premium-annual'
  affiliateId?: string
  username_changed?: boolean
  homeroom_notifications?: boolean
  referral_origin?: string
  v8_progressive: boolean
  calligraphy_mode: boolean
  needs_v8_disclaimer?: boolean
  v7_last_quiz?: string
}

export interface AuthState extends DefaultRootState {
  auth: AUTH_STATE
}
// eslint-disable-next-line no-shadow
export enum REDUX_ACTIONS {
  BUY_COURSE = 'BUY_COURSE',
  LOAD_COURSES = 'LOAD_COURSES',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  SUBSCRIBE = 'SUBSCRIBE',
  CHANGE_SETTING = 'CHANGE_SETTING',
  CHANGE_SETTINGS = 'CHANGE_SETTINGS',
  CHANGE_PROGRESSIVE = 'CHANGE_PROGRESSIVE',
  LOAD_SUBS = 'LOAD_SUBS',
  SET_BADGES = 'SET_BADGES',
  UNSET_BADGES = 'UNSET_BADGES',
  SET_OWN_AVATAR = 'SET_OWN_AVATAR',
  UNSET_OWN_AVATAR = 'UNSET_OWN_AVATAR',
  SET_DEFAULT_AVATAR = 'SET_DEFAULT_AVATAR',
  CHANGE_COURSE_BACKGROUND = 'CHANGE_COURSE_BACKGROUND',
  UPDATE_HISTORY = 'UPDATE_HISTORY',
  SET_ROLES = 'SET_ROLES',
  SET_POPUP = 'SET_POPUP',
  SET_AVATAR_CF = 'SET_AVATAR_CF',
  SWITCH_COLORIZED_FURI = 'SWITCH_COLORIZED_FURI',
  SWAP_FURI_COLORS = 'SWAP_FURI_COLORS',
  SWITCH_CUSTOM_PROGRESSIVE = 'SWITCH_CUSTOM_PROGRESSIVE'
}
