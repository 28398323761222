import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'
import getCourseInfo, { getTitle } from '../../../util/courseInfo'

const StructureGrid = styled.section`
  display: grid;
  grid-template-columns: ${({ width, isMobile }) => isMobile ? '1fr' : width}; 
  margin-bottom: 10px;
  border-radius: 15px;
  color: white;
  overflow: hidden;
  justify-content: center;
  box-shadow: ${({ width, boxShadow, isMobile }) => width === '1fr' && !isMobile && boxShadow};
`

const StructureWidget = (props) => {
  const [parts, setParts] = useState({})
  const [widget, setWidget] = useState(props.widget)
  const [playsSound, setPlaysSound] = useState(false)
  const [noSound, setNoSound] = useState(false)
  const isMobile = useMobile()
  const [loading, setLoading] = useState(false)

  const boxShadow = `
    3px 3px 0px 2px ${getCourseInfo(getTitle(props.course)).alt_color_3},
    -2px -2px 5px -3px rgb(0.4, 0.4, 0.4, 0.2),
    6px 6px 5px -3px rgb(0.7, 0.7, 0.7, 0.3)
  `

  useEffect(() => {
    if (!widget) return

    setParts({
      ...widget.parts,
      title: widget.parts.title,
    })
    setPlaysSound(widget.plays_sound)
    setNoSound(widget.no_sound)
    // console.log(widget)
  }, [widget])

  useEffect(() => {
    // console.log(parts)
  }, [parts])

  const section_styles = {
    fontFamily: 'Milliard Book',
    borderTop: '2px solid white',
    padding: '0',
    margin: '0',
    fontSize: 'var(--reg-font-size)',
    color: 'white',
    width: 'auto',
    lineHeight: '1.8',
  }

  if (loading) return <PlaceholderWidget widget_type='StructureWidget' />

  return (
    <WidgetWrapper
      data-t={`widget:structure-id:${widget._id}`}
      widget={{ widgetId: widget._id, widgetType: 'StructureWidget' }}
    >
      <StructureGrid
        width={widget.styles?.width ?? 'auto'}
        isMobile={isMobile}
        boxShadow={boxShadow}
      >
        <div
          className='fz-colored-font fz-colored-border fz-colored-background'
          style={{
            boxShadow: !isMobile ? boxShadow : '',
            display: 'table',
            overflow: 'hidden',
            border: '2px solid transparent',
            borderRadius: '15px',
            color: 'white',
            textAlign: 'center',
            marginBottom: widget.styles?.width === '1fr' ? '' : '5px', // keeps boxShadow visible
            position: 'relative'
          }}
        >
          {parts?.title && <div
            className='fz-colored-font'
            style={{
              backgroundColor: 'var(--off-white)',
              margin: '0 auto',
              padding: '12px 0',
              boxSizing: 'border-box',
              textAlign: 'center',
              fontFamily: 'Milliard Bold',
              fontSize: '1.4rem',
            }}
          >
            <SoundRenderer inverted key={parts?.title} text={parts?.title} />
          </div>}
          <div
            style={{
              margin: '0 30px',
            }}
          >
            <div
              style={{
                ...section_styles,
                padding: '20px 0',
                border: 'none',
              }}
            >
              <SoundRenderer inverted key={parts?.top} noSound={noSound} text={parts?.top} />
            </div>
            {parts?.middle && (
              <>
                <div style={{ ...section_styles, padding: '20px 0' }}>
                  <SoundRenderer
                    inverted
                    key={parts?.middle}
                    noSound={noSound}
                    text={parts?.middle}
                  />
                </div>
              </>
            )}
            {parts?.bottom && (
              <>
                <div style={{ ...section_styles, padding: '20px 0' }}>
                  <SoundRenderer
                    inverted
                    key={parts?.bottom}
                    noSound={noSound}
                    text={parts?.bottom}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </StructureGrid>
    </WidgetWrapper>
  )
}

export default StructureWidget
