import { useEffect, useState } from 'react'

import { Button, Form, Grid, Loader } from 'semantic-ui-react'

import useWidget from '../../hooks/useWidget'

const QuestionAndAnswerWidget = (props) => {
  const [questions, setQuestions] = useState([])
  const [translations, setTranslations] = useState([])
  const [answers, setAnswers] = useState([])
  const [widget, , loading] = useWidget(props.id)

  useEffect(() => {
    setQuestions(widget?.questions)
  }, [widget])

  const setTranslation = (idx, val) => {
    const tempTranslations = [...translations]
    tempTranslations[idx] = val
    setTranslations(tempTranslations)
  }

  const setAnswer = (idx, val) => {
    const tempAnswers = [...answers]
    tempAnswers[idx] = val
    setAnswers(tempAnswers)
  }

  return (
    <div>
      {loading && (
        <Grid centered>
          <Loader active inline />
        </Grid>
      )}
      {questions?.map((q, idx) => (
        <div
          key={idx}
          style={{ fontFamily: 'Yu Gothic UI Regular, Milliard Bold', fontSize: '2rem' }}
        >
          <Grid verticalAlign='middle'>
            <Grid.Row>
              <Grid.Column width={16} style={{ fontFamily: 'Milliard Bold', fontSize: '2rem' }}>
                {q.other_lang}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>Translation</Grid.Column>
              <Grid.Column width={12}>
                <Form.Input
                  inline
                  onChange={(e, { value }) => setTranslation(idx, value)}
                  value={translations[idx]}
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={4}>Answer</Grid.Column>
              <Grid.Column width={12}>
                <Form.Input
                  inline
                  onChange={(e, { value }) => setAnswer(idx, value)}
                  value={answers[idx]}
                  fluid
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row textAlign='right'>
              <Grid.Column width={16}>
                <Button color='green'>Show Solution</Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      ))}
    </div>
  )
}

export default QuestionAndAnswerWidget
