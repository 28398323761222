import React, { useEffect, useState, useRef } from 'react'
import axios from '../../../api'

import { Button, Image } from 'semantic-ui-react'
import HoverVideoPlayer from 'react-hover-video-player'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundInjector from '../admin/editor/SoundInjector'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'
import useAPI from 'src/hooks/useAPI'
import getCourseInfo, { getTitle } from '../../../util/courseInfo'

interface DownloadWidgetProps {
  widget: {
    title: string
    caption: string
    showBorder: boolean
    path: string
    video_path: string
  }
  id: string
  grid?: string
  course?: string
}

const DownloadWidget: React.FC<DownloadWidgetProps> = (props) => {
  const [cfPath, setCfPath] = useState<string>('')
  const [title, setTitle] = useState<string>('')
  const [caption, setCaption] = useState<string>('')
  const [previewThumb, setPreviewThumb] = useState<string>('')
  const videoRef = useRef<HTMLVideoElement>(null)
  const [showBorder, setShowBorder] = useState<boolean>(true)
  const [widgetResponse, loading, widgetReq] = useAPI(`/widgets/${props.id}`, 'LAZYGET')
  const courseColor = getCourseInfo(getTitle(props.course as string) as string).alt_color_1
  const isMobile = useMobile()

  useEffect(() => {
    widgetReq({ params: { cache: true, course: props.course } })
  }, [])

  const gridID = props.grid

  const onWidgetResponse = (widget) => {
    if (!widget) return

    setShowBorder(widget.showBorder)
    if (widget.caption) setCaption(widget.caption)
    if (widget.title) setTitle(widget.title)
  }

  const getCfPath = async (widget) => {
    if (!widget) return
    try {
      const urlRes = await axios.post('/user/signed', { url: widget?.s3_path, widget: gridID || props.id })

      if (urlRes?.data?.success) {
        setCfPath(urlRes?.data?.url)
      }
    } catch (err) {
      console.error(err)
    }
    if (widget?.preview_thumb) {
      try {
        const thumbRes = await axios.post('/user/signed', { url: widget?.preview_thumb, widget: gridID || props.id })

        if (thumbRes?.data?.success) {
          setPreviewThumb(thumbRes?.data?.url)
        }
      } catch (err) {
        console.error(err)
      }
    }
  }

  useEffect(() => {
    if (!widgetResponse) return
    
    onWidgetResponse(JSON.parse(widgetResponse?.widget?.cache))
    getCfPath(JSON.parse(widgetResponse?.widget?.cache))
  }, [widgetResponse])

  const ImageFigure = () => {
    return (
    <figure
      style={{
        border: showBorder ? '1.5px solid var(--blue-gray)' : '1.5px solid transparent',
        margin: isMobile ? '0' : '',
        objectFit: 'contain',
        overflow: 'hidden',
      }}
    >
      <Image
        className="no-select"
        style={{ margin: '0 auto', borderRadius: '3px', maxHeight: '500px' }}
        src={previewThumb}
        draggable={false}
        onError={() => setPreviewThumb(undefined)}
      />
      {caption && (
        <figcaption>
          <div
            className="fz-dark-font"
            style={{
              width: '50%',
              margin: '10px auto 0',
              padding: '10px',
              textAlign: 'center',
              fontSize: '1.3rem',
              lineHeight: 1.1,
            }}
          >
            <SoundRenderer widget={props.id} text={caption} />
          </div>
        </figcaption>
      )}
    </figure>
    )
  }

  if (loading) return <PlaceholderWidget widget_type="DownloadWidget" />

  return (
    <>
      {cfPath && (
        <div style={{ position: 'relative' }}>
          <WidgetWrapper widget={{ widgetId: props.id, widgetType: 'DownloadWidget' }}>
            {title && (
              <div
                className="image-title fz-colored-background"
                style={{
                  textAlign: 'center',
                  padding: '4px 0',
                }}
              >
                <h2 style={{ color: 'var(--off-white)' }}>
                  <SoundRenderer widget={props.id} text={title} />
                  {/* <SoundInjector widget={props.id}>{title}</SoundInjector> */}
                </h2>
              </div>
            )}
            <ImageFigure />
            {/* centered course-colored download button */}
            <div
              data-t={`widget:download-id:${props.id}-button`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '10px 0',
              }}
            >
              <Button
                as="a"
                href={cfPath}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  backgroundColor: courseColor,
                  color: 'var(--off-white)',
                  padding: '10px 20px',
                  borderRadius: '5px',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
                }}
              >
                Download
              </Button>
            </div>
          </WidgetWrapper>
        </div>
      )}
    </>
  )
}

export default DownloadWidget
