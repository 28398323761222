import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { REDUX_NAV_ACTIONS } from 'src/actions/types'
import { Icon } from 'semantic-ui-react'

import NavigationStyles from '../styles/NavigationStyles'

import { MenuSolid } from '@graywolfai/react-heroicons'
import navigation_ask from 'src/assets/headernav/navigation_ask.svg'
import navigation_books from 'src/assets/headernav/navigation_books.svg'
import navigation_course from 'src/assets/headernav/navigation_course.svg'
import navigation_faq from 'src/assets/headernav/navigation_faq.svg'
// import navigation_games from 'src/assets/headernav/navigation_games.svg'
// import navigation_home from 'src/assets/headernav/navigation_home.svg'
import navigation_notebook from 'src/assets/headernav/navigation_notebook.svg'
import navigation_video from 'src/assets/headernav/navigation_video.svg'

import AdminDropdownMenu from './dropdown_menu/AdminDropdownMenu'
import CenterNav from './CenterNav'
import ClickableOverlay from './ClickableOverlay'
import DropdownMenu from './dropdown_menu/DropdownMenu'
import FZLogo from './FZLogo'
import LoggedOutNav from './LoggedOutNav'
import MobileSideDrawer from './MobileSideDrawer'
import SideDrawer from './SideDrawer'
import UserAvatar from './UserAvatar'
import AdvancedProgSettings from './dropdown_menu/AdvancedProgSettings'
import SiteSearchResults from './dropdown_menu/SiteSearchResults'

import useAPI from 'src/hooks/useAPI'
import useSearch from 'src/hooks/useSearch'
import useInterval from 'src/hooks/useInterval'
import useStaffRoles from 'src/hooks/useStaffRoles'
import getCourseInfo, { getTitle } from 'src/util/courseInfo'
import { CourseInfo } from 'src/types'
import WidgetPalette from 'src/components/fz_courses/admin/creation_toolkit/parts/WidgetPalette'

import 'src/assets/main.css'

const darkNavRoutes = [
  '/home', '/memberships/premium/subscribe', '/courses',
  '/notifications', '/account', '/notebook', '/books', '/vault',
  '/ask', '/games', '/faq', '/search'
]

// check if any of the haystack items match the needle as a regex
const mapMatch = (needle, haystack) => {
  return haystack.find((item) => {
    return new RegExp(item).test(needle)
  })
}

export interface NavItem {
  name: string
  href: string
  icon: React.ReactNode
  current: boolean
  beta?: boolean
}

interface NavigationProps {
  isMobile: boolean
  onSignOut: (pathname: string) => void
}

interface NotificationResponse {
  success: boolean
  notifications: number
  stickyCount?: number
}

const Navigation: React.FC<NavigationProps> = ({ isMobile, onSignOut }) => {
  // drawer management
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false)
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false)
  const [clickOverlayVisible, setClickOverlayVisible] = useState(false)
  const [advancedSettingsVisible, setAdvancedSettingsVisible] = useState(false)
  const [searchResultsVisible, setSearchResultsVisible] = useState(false)

  const [notificationCount, setNotificationCount] = useState(0)
  const [isCourseRoute, setIsCourseRoute] = useState(false)
  
  const navigatorDispatch = useDispatch()
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)
  const notification_count = useSelector((state: RootState) => state.navigator?.notification_count)

  const [pNotifications, pLoading, pRequest] = useAPI<NotificationResponse>('/notifications/new', 'LAZYGET')
  const [gNotifications, gLoading, gRequest] = useAPI<NotificationResponse>('/notifications/global/new', 'LAZYGET')

  const courseOverview = useRouteMatch('/courses/:id')
  const lessonOverview = useRouteMatch('/courses/:id/:lid')
  const sublessonPage = useRouteMatch('/courses/:id/:lid/:sid')

  const history = useHistory()
  const location = useLocation()
  const [isStaffRole] = useStaffRoles()
  const [searchResults, searchLoading, setSearchText, counts, countsLoading, setCategory, setPage] = useSearch()

  const closeDrawers = () => {
    setDrawerOpen(false)
    setDropdownOpen(false)
    setAdminDropdownOpen(false)
    setRightDrawerOpen(false)
    setAdvancedSettingsVisible(false)
    setSearchResultsVisible(false)
  }

  const openAdvancedSettings = () => {
    setDropdownOpen(false)
    setRightDrawerOpen(false)
    setAdvancedSettingsVisible(!advancedSettingsVisible)
  }

  const openSearchResults = () => {
    setSearchResultsVisible(!searchResultsVisible)
  }

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === 'Escape') closeDrawers()
  }

  useEffect(() => {
    if (
      adminDropdownOpen
      || drawerOpen
      || dropdownOpen
      || rightDrawerOpen
      || advancedSettingsVisible
      || searchResultsVisible
    ) setClickOverlayVisible(true)
    else setClickOverlayVisible(false)
  }, [
    adminDropdownOpen,
    drawerOpen,
    dropdownOpen,
    rightDrawerOpen,
    advancedSettingsVisible,
    searchResultsVisible
  ])

  useEffect(() => {
    window.addEventListener('keyup', (e) => handleKeyUp(e))
    return () => {
      window.addEventListener('keyup', (e) => handleKeyUp(e))
    }
  }, [])

  useEffect(() => {
    if (lessonOverview) {
      setIsCourseRoute(true)
      return
    }
    setIsCourseRoute(false)
  }, [lessonOverview])

  useEffect(() => {
    if (!advancedSettingsVisible) closeDrawers()
  }, [location, isMobile])

  useEffect(() => {
    if (pNotifications?.success && gNotifications?.success) {
      let nCount = 0
      nCount += pNotifications.notifications || 0

      if (gNotifications?.notifications) {
        if (gNotifications?.stickyCount) {
          nCount += gNotifications.notifications - gNotifications.stickyCount
        } else nCount += gNotifications.notifications
      }

      if (notification_count !== nCount) {
        navigatorDispatch({
          type: REDUX_NAV_ACTIONS.SET_NOTIFICATION_COUNT,
          payload: { notification_count: nCount }
        })
      }
    }
  }, [pNotifications, gNotifications, pLoading, gLoading])

  useEffect(() => {
    setNotificationCount(notification_count ?? 0)
  }, [notification_count])

  const refetchNotifications = () => {
    if (!isSignedIn) return
    pRequest()
    gRequest()
  }

  useInterval(refetchNotifications, 1000 * 60)

  useEffect(() => {
    refetchNotifications()
  }, [location])

  const primaryNavigation: NavItem[] = [
    {
      name: 'Homeroom',
      href: '/home',
      icon: <svg
              fill='var(--jfz-blue)'
              stroke='var(--jfz-blue)'
              strokeWidth='0.3px'
              viewBox='0 0 20 20'
              xmlns='http://www.w3.org/2000/svg'
              className='no-select'
            >
            <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z' />
            </svg>,
      current: false,
    },
    {
      name: 'Courses',
      href: '/courses',
      icon: <img
              src={navigation_course}
              alt='Courses'
              draggable='false'
              className='no-select'
            />,
      current: false,
    },
    {
      name: 'Notebook',
      href: '/notebook',
      icon: <img
              src={navigation_notebook}
              alt='Courses'
              draggable='false'
              className='no-select'
            />,
      current: false,
    },
    {
      name: 'Books',
      href: '/books',
      icon: <img
              src={navigation_books}
              alt='Courses'
              draggable='false'
              className='no-select'
            />,
      current: false,
    },
    {
      name: 'Video Vault',
      href: '/vault',
      icon: <img
              src={navigation_video}
              alt='Courses'
              draggable='false'
              className='no-select'
            />,
      current: false,
    },
    {
      name: 'Ask-A-Teacher',
      href: '/ask',
      icon: <img
              src={navigation_ask}
              alt='Courses'
              draggable='false'
              className='no-select'
            />,
      current: false,
    },
    {
      name: 'Games',
      href: '/games',
      icon: <span className='s-ui blue'>
              <Icon name='gamepad' />
            </span>,
      current: false,
    },
    {
      name: 'FZ Live',
      href: '/fz-live',
      icon: <span className='s-ui blue'>
              <Icon name='users' />
            </span>,
      current: false,
    },
    {
      name: 'Search',
      href: '/search',
      icon: <span className='s-ui blue'>
              <Icon name='search' />
              {/* tiny upper right "beta" text */}
              <span style={{ fontSize: '0.5rem', position: 'absolute', right: '50px' }}>beta</span>
            </span>,
      current: false,
    },
    {
      name: 'FAQ',
      href: '/faq',
      icon: <img
              src={navigation_faq}
              alt='Courses'
              draggable='false'
              className='no-select'
            />,
      current: false,
    },
  ]

  const handleDropdownVisibility = () => {
    if (!isMobile) setDropdownOpen((prev) => !prev)
    if (isMobile) setRightDrawerOpen((prev) => !prev)
    if (isStaffRole) setAdminDropdownOpen(false)
  }
  
  const handleAdminRouteVisibility = () => {
    if (isStaffRole) {
      setAdminDropdownOpen((prev) => !prev)
      setDropdownOpen(false)
    }
  }

  const { pathname } = location
  const backgroundColor: string = useMemo(() => {
    const transparentRoutes = ['login', 'register', 'admin']
    if (isMobile) {
      transparentRoutes.push('courses')
    }

    const splitPath = pathname.split('/').filter((p) => p)
    const root = splitPath[0]
    
    let course_info: CourseInfo
    let bgColor = 'var(--off-white)'

    if (root === 'courses') {
      if (isMobile && splitPath.length === 2) {
        bgColor = 'transparent'
      } else {
        // eslint-disable-next-line no-lonely-if
        if (splitPath.length > 1) {
          course_info = getCourseInfo(getTitle(splitPath?.[1]))
          bgColor = 'var(--jfz-blue)'
        } else {
          bgColor = 'var(--off-white)'
        }
      }
    }
    
    navigatorDispatch({
      type: REDUX_NAV_ACTIONS.SET_COURSE_INFO,
      payload: { course_info }
    })

    if (transparentRoutes.includes(root)) bgColor = 'transparent'
    return bgColor
  }, [pathname, isMobile])

  useEffect(() => {
    if (isSignedIn && pathname === '/') history.replace('/home')
  }, [pathname])

  const isAdminPanel = useMemo(() => {
    if (!pathname.includes('/admin/bug/')
      && pathname.includes('/admin')
      && !pathname.includes('vault')
    ) return true
    return false
  }, [pathname])

  const showAvatar = pathname !== '/es' && pathname !== '/claim'

  return (
    <NavigationStyles
      mobile={isMobile}
      backgroundColor={backgroundColor}
      className='fz-colored-background'
    >
      <ClickableOverlay
        open={clickOverlayVisible}
        handleClick={closeDrawers}
      />
      {!isAdminPanel && (
        <FZLogo
          backgroundColor={backgroundColor}
          isCourseRoute={isCourseRoute}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      )}
      {isSignedIn ? (
        <>
          <SideDrawer open={drawerOpen} primaryNavigation={primaryNavigation} />
          {!isAdminPanel && (
            <CenterNav
              isCourseRoute={isCourseRoute}
              isCourseOverview={!!courseOverview}
              isLessonOverview={!!lessonOverview}
              isSublessonPage={!!sublessonPage}
              backgroundColor={backgroundColor}
            />
          )}
          <AdvancedProgSettings
            open={advancedSettingsVisible}
            onClose={closeDrawers}
          />
          <SiteSearchResults
            open={searchResultsVisible}
            onClose={closeDrawers}
            results={searchResults}
            setCategory={setCategory}
            setPage={setPage}
            counts={counts}
            loading={searchLoading || countsLoading}
          />
          {showAvatar && (
            <div className='avatar'>
              {isMobile ? (
                <div className='hamburger' onClick={() => setRightDrawerOpen(true)}>
                  <Icon
                    name='bars'
                    size='big'
                    style={{ color: darkNavRoutes.includes(pathname) ? '#222' : '#fcfaff' }}
                  />
                </div>
              ) : (
                <UserAvatar
                  data-cy='user-avatar-nav'
                  notificationCount={notificationCount}
                  handleBadgeClick={handleAdminRouteVisibility}
                  handleAvatarClick={handleDropdownVisibility}
                />
              )}
            </div>
          )}
          <AdminDropdownMenu isOpen={adminDropdownOpen} />
          {isStaffRole && <WidgetPalette />}
          <DropdownMenu
            open={dropdownOpen}
            notificationCount={notificationCount}
            onSignOut={onSignOut}
            openAdvancedSettings={openAdvancedSettings}
            setSearchText={setSearchText}
            setSearchResultsVisible={setSearchResultsVisible}
          />
          {isMobile && (
            <MobileSideDrawer
              closeDrawer={() => setRightDrawerOpen(false)}
              onSignOut={onSignOut}
              open={rightDrawerOpen}
              openAdvancedSettings={openAdvancedSettings}
            />
          )}
        </>
      ) : (
        <LoggedOutNav />
      )}
    </NavigationStyles>
  )
}

export default Navigation
