import styled from 'styled-components'

const Button = styled.button`
  cursor: pointer;
  font: inherit;
  font-weight: 600;
  border-radius: 13px;
  border: 2px solid white;
  color: white;
  padding: 0.25rem 1rem;
  display: flex;
  align-self: center;
  margin: 2rem auto;
  background-color: #1d191b;
  box-shadow: 0 0 0 2pt #1d191b;

  &:disabled {
    cursor: default;
    background-color: #f7a248;
  }
`

export default Button
