import React, { useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { TABLE } from '../toolkit.constants'
import { FZTable, TableWidget } from 'src/types'

import { ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { IconButton, ToolFooter, ToolHeader, ToolInput, ToolOptions, ToolSubheader } from '../parts'
import ToolForm from '../parts/ToolForm.part'
import TableRows from './table/TableRows'
import TableHeader from './table/TableHeader'
import useWidgetSaved from '../hooks/useWidgetSaved'
import FormatPreviewShell from 'src/components/fz_courses/sublessonpart/FormatPreviewShell'

const TableTool: React.FC<ToolProps<TableWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<TableWidget>(id)
  const [storedTable, setStoredTable] = useState<FZTable>()
  const [colRowStyleButtons, setColRowStyleButtons] = useState(false)
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const hookFormMethods = useForm()
  const [previewOpen, setPreviewOpen] = useState(false)
  const tableRef = useRef<HTMLDivElement>(null)

  // console.log(hookFormMethods.getValues()['table'])

  const [refresher, setRefresher] = useState(0)

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })

      setStoredTable(widget.table)
      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const commitChanges = (_table) => {
    hookFormMethods.setValue('table', _table)
    setStoredTable(_table)
  }

  const checkLinkedRows = (table, idx) => {
    let lr: number[] = table.linkedRows
    if (lr.includes(idx)) {
      lr = lr.filter((link) => (link !== idx || link === idx + 1 || link === idx - 1))
    }
    // TODO: add or subtract 1 based on position of row inserted
    // instead of breaking links

    // eslint-disable-next-line no-param-reassign
    table.linkedRows = lr
    return table
  }

  const addRow = (idx: number) => {
    let _table = hookFormMethods.getValues()['table']
    const _newRow = new Array(_table.columns).fill('')
    _table.rows.splice(idx + 1, 0, _newRow)
    _table.rowStyles.push(null)

    _table = checkLinkedRows(_table, idx)
    commitChanges(_table)
  }

  const chainRow = (idx) => {
    const _table = hookFormMethods.getValues()['table']
    let _linkedRows = _table?.linkedRows ?? []

    if (_linkedRows.includes(idx)) _linkedRows = _linkedRows.filter((links) => links !== idx)
    else _linkedRows.push(idx)

    _table.linkedRows = _linkedRows
    commitChanges(_table)
  }

  const deleteRow = (idx) => {
    const _table = hookFormMethods.getValues()['table']
    _table.rows.splice(idx, 1)
    _table.rowStyles.splice(idx, 1)
    commitChanges(_table)
  }

  const onRowStylesChanged = (e, row_idx: number) => {
    const _table = hookFormMethods.getValues()['table']
    const { name, value } = e.target

    if (!_table.rowStyles[row_idx]) _table.rowStyles[row_idx] = { [name]: value }
    else _table.rowStyles[row_idx][name] = value

    commitChanges(_table)
  }

  const handleShiftColumns = () => {
    const _table = hookFormMethods.getValues()['table']

    const firstHeaderEl = _table.header.shift()
    _table.header.push(firstHeaderEl)

    const shiftedRows = _table.rows.map((row) => {
      const firstRowEl = row.shift()
      row.push(firstRowEl)
      return row
    })

    _table.rows = shiftedRows
    commitChanges(_table)
  }

  const addColumn = () => {
    const _table = hookFormMethods.getValues()['table']
    _table.columns += 1
    _table.header.push('')
    _table.rows.forEach((row) => { row.push('') })
    _table.columnStyles.push(null)

    commitChanges(_table)
  }

  const deleteColumn = (idx) => {
    const _table = hookFormMethods.getValues()['table']
    _table.columns -= 1
    _table.header.splice(idx, 1)
    _table.rows.forEach((r) => { r.splice(idx, 1) })
    _table.columnStyles.splice(idx, 1)

    commitChanges(_table)
  }

  const onColumnStylesChanged = (e, col_idx: number) => {
    const _table = hookFormMethods.getValues()['table']
    const { name, value } = e.target

    if (!_table.columnStyles[col_idx]) _table.columnStyles[col_idx] = { [name]: value }
    else _table.columnStyles[col_idx][name] = value

    commitChanges(_table)
  }

  // given a string representing \n-separated rows with \t-separated columns,
  // return a 2D array of rows and columns with the appropriate values
  const pasteTable = (table: string) => {
    const _table = hookFormMethods.getValues()['table']
    const rows = table.split('\n')
    const newRows = rows.map((row) => row.split('\t'))
    console.log(rows, newRows)
    _table.rows = newRows

    // ensure the table has as many header columns as the first row
    if (_table.header.length < newRows[0].length) {
      const diff = newRows[0].length - _table.header.length
      for (let i = 0; i < diff; i += 1) {
        _table.header.push('')
      }
      _table.columns = newRows[0].length
    }
    commitChanges(_table)
  }

  // handle paste from clipboard with ctrl-enter, only if the table is empty
  const handlePaste = (e) => {
    // return if we're not hovered within the table ref
    if (!tableRef.current?.contains(e.target)) return
    if (e.ctrlKey && e.key === 'Enter' && !hookFormMethods.getValues()['table']?.rows?.length) {
      e.preventDefault()
      navigator.clipboard.readText().then((text) => {
        pasteTable(text)
      })
    }
  }

  // handle paste from clipboard with ctrl-enter, only if the table is empty
  useEffect(() => {
    document.addEventListener('keydown', handlePaste)
    return () => {
      document.removeEventListener('keydown', handlePaste)
    }
  }, [hookFormMethods.getValues()['table']])

  // const onRowStyleButtonClicked = (row_idx: number) => {
  //   const _table = hookFormMethods.getValues()['table']

  //   let rowStyles = []
  //   if (!_table?.rowStyles?.length) {
  //     rowStyles = Array(_table.rows.length)
  //   } else {
  //     rowStyles = _table.rowStyles
  //   }

  //   if (rowStyles[row_idx]) {
  //     rowStyles.splice(row_idx, 1, null)
  //   } else {
  //     rowStyles.splice(row_idx, 1, { fontWeight: 'normal' })
  //   }

  //   console.log(rowStyles)
  //   _table.rowStyles = rowStyles
  //   commitChanges(_table)
  // }

  const toolFooterOptionsLabels = {
    'table.forceBoldHeader': ['Bold Header', 'Header row will be bold'],
    'table.verticalBorders': ['Vertical Borders', 'Show vertical borders on cells'],
    'table.showHeader': ['Header as Row', 'Alters header row\'s appearance'],
    'table.horizontalPlayAll': ['Horizontal Play All', 'Whether "Play All" should work horizontally instead of vertically']
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={TABLE}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool' ref={tableRef}>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={TABLE}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              <ToolInput
                value={'plays_sound'}
                inputLabel='Play Sound'
                title='Should this widget play VA sounds?'
                inputType='checkbox'
                labelPosition='after'
              />
            </div>
            <div>
              <ToolInput
                value={'table.forceIndent'}
                inputLabel='Force Indent'
                title='Indent all cells'
                inputType='checkbox'
                labelPosition='after'
              />
            </div>
            <div>
              <IconButton
                icon='css3'
                backgroundColor='transparent'
                contrastColor='white'
                title='Custom Styles'
                active={colRowStyleButtons}
                handleClick={() => setColRowStyleButtons((p) => !p)}
              />
              <input
                id='col_row_style_button_vis'
                name='col_row_style_button_vis'
                type='checkbox'
                checked={colRowStyleButtons}
                onChange={() => setColRowStyleButtons((p) => !p)}
                aria-hidden='true'
                style={{ display: 'none' }}
              />
              <label htmlFor='col_row_style_button_vis' className='tw-font-bold tw-cursor-pointer'>
                Row/Column Styles
              </label>
            </div>
          </ToolOptions>
          <ToolSubheader>
            <ToolInput
              value={'table.title'}
              placeholder='Title'
            />
            <ToolInput
              value={'table.subtitle'}
              placeholder='Subtitle'
            />
          </ToolSubheader>
          <ToolBody>
            <TableHeader
              showStyleButtons={colRowStyleButtons}
              columnStyles={storedTable?.columnStyles}
              headerRow={storedTable?.header ?? []}
              handleShiftColumns={handleShiftColumns}
              handleAddColumn={addColumn}
              handleColumnStyles={onColumnStylesChanged}
              storedTable={storedTable}
            />
            <TableRows
              addRow={addRow}
              handleRemoveRow={deleteRow}
              handleChainRow={chainRow}
              handleDeleteColumn={deleteColumn}
              rows={storedTable?.rows ?? [['']]}
              linkedRows={storedTable?.linkedRows ?? []}
              table_id={id}
              onEdit={() => setRefresher((p) => p + 1)}
              showStyleButtons={colRowStyleButtons}
              handleRowStyles={onRowStylesChanged}
              storedTable={storedTable}
            />
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          >
            {Object.keys(toolFooterOptionsLabels).map((opt) => (
              <div key={opt}>
                <ToolInput
                  value={opt}
                  inputType='checkbox'
                  inputLabel={toolFooterOptionsLabels[opt][0]}
                  title={toolFooterOptionsLabels[opt][1]}
                  labelPosition='after'
                  labelColor='white'
                />
              </div>
            ))}
            {/* eyeball icon button that will open the widget render preview */}
            <div>
              <IconButton
                icon='eye'
                backgroundColor='transparent'
                contrastColor='white'
                title='Preview'
                handleClick={() => setPreviewOpen((p) => !p)}
              />
            </div>
          </ToolFooter>
          {previewOpen && (
            <FormatPreviewShell
              widget={{ ...hookFormMethods.getValues(), id }}
              type='table'
              refresher={refresher}
              course={course}
            />
          )}
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default TableTool
