import React, { useEffect, useState } from 'react'

import { Button, Form, Segment } from 'semantic-ui-react'

import useWidget from '../../../hooks/useWidget'

const QuestionAndAnswerWidgetEditor = props => {
    const [questions, setQuestions] = useState([])
    const [widget, fetchWidget, loading, editWidget] = useWidget(props.id)

    useEffect(() => {
        setQuestions(widget?.questions)
    }, [widget])

    const addQuestion = () => {
        let tempQuestions = [...questions]
        tempQuestions.push({other_lang: '', english: '', answer: ''})
        setQuestions(tempQuestions)
    }

    const setQuestion = (idx, val) => {
        let tempQuestions = [...questions]
        tempQuestions[idx] = {...tempQuestions[idx], ...val}
        setQuestions(tempQuestions)
    }

    return (
        <div>
            {questions?.map((q, idx) =>
                <div>
                    <Form.Input
                        inline
                        onChange={(e, {value}) => setQuestion(idx, {other_lang: value})}
                        value={q.other_lang}
                        label='Question Text'
                    />
                    <Form.Input
                        inline
                        onChange={(e, {value}) => setQuestion(idx, {english: value})}
                        value={q.english}
                        label='Translation Text'
                    />
                    <Form.Input
                        inline
                        onChange={(e, {value}) => setQuestion(idx, {answer: value})}
                        value={q.answer}
                        label='Answer Text'
                    />
                </div>)}
            <Button color='green' icon='save' onClick={() => editWidget({questions})} />
            <Button color='blue' icon='plus' onClick={addQuestion} />
        </div>
    )
}

export default QuestionAndAnswerWidgetEditor