import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Header } from 'semantic-ui-react'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
// import LineBreaker from '../util/LineBreaker'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'
import getCourseInfo, { getTitle } from '../../../util/courseInfo'
import ReactLinkify from 'react-linkify'

/* eslint-disable react/prop-types */
const TextBoxWidget = (props) => {
  const [widget] = useState(props.widget)
  const [loading, setLoading] = useState(!props?.widget)
  const progSettings = useSelector((state) => state.auth.progressive_settings)
  const isMobile = useMobile()

  useEffect(() => {
    if (props.widget) setLoading(false)
  }, [props.widget])

  if (loading) return <PlaceholderWidget widget_type="TextboxWidget" />
  if (widget?.romajiOnly && progSettings !== 'romaji') return <></>

  const howMuchMarginLeft = useCallback((title) => {
    const beginsWithNumber = /^[0-9]./i
    const jpStartRegex = /^\s*[\s-龯]/
    let marginLeft = '0px'

    if (beginsWithNumber.test(title)) {
      marginLeft = '23px'
      if (jpStartRegex.test(widget.title.slice(2))) {
        // title leads with number and has following Japanese
        marginLeft = '48px'
      }
    }

    if (!beginsWithNumber.test(title) && jpStartRegex.test(widget.title)) {
      // title only has leading Japanese
      marginLeft = '26px'
    }

    return marginLeft
  }, [])

  const courseColor = getCourseInfo(getTitle(props.course)).alt_color_1
  const courseColor2 = getCourseInfo(getTitle(props.course)).alt_color_2

  if (widget?.title && !widget?.subtitle && !widget?.body) {
    return (
      <div style={{ position: 'relative' }}>
        <WidgetWrapper
          widget={{ widgetId: widget._id, widgetType: 'TextBoxWidget' }}
          style={{ position: 'relative' }}
        >
          <div
            style={{
              marginTop: '15px',
              paddingLeft: isMobile ? '16px' : '',
              paddingRight: isMobile ? '16px' : '',
            }}
          >
            {widget?.title && (
              <>
                <div
                  style={{
                    padding: '10px',
                    paddingBottom: '0px'
                  }}
                >
                  <h2
                    data-t={`widget:textbox-title-${widget._id}`}
                    style={{
                      fontFamily: 'Milliard Book',
                      fontSize: isMobile ? '1.3rem' : '1.8rem',
                      color: courseColor,
                      marginBottom: '0px',
                      paddingBottom: '0px',
                      fontFeatureSettings: 'tnum',
                      fontVariantNumeric: 'tabular-nums',
                    }}
                  >
                    <SoundRenderer
                      contextAudio
                      text={widget.title}
                      widgetType='TextBoxWidget'
                    />
                  </h2>
                </div>
              </>
            )}
          </div>
        </WidgetWrapper>
      </div>
    )
  }

  return (
    <div style={{ position: 'relative' }}>
      <WidgetWrapper
        widget={{ widgetId: widget._id, widgetType: 'TextBoxWidget' }}
      >
        <div
          style={{
            marginTop: isMobile ? '' : '10px',
            marginBottom: '10px',
            paddingLeft: isMobile ? '16px' : '',
            paddingRight: isMobile ? '16px' : '',
          }}
        >
          {(widget?.title || widget?.subtitle) && (
            <div
              style={{
                padding: '10px',
                paddingBottom: '0px'
              }}
            >
              {widget?.title && (
              <h2
                data-t={`widget:textbox-title-${widget._id}`}
                style={{
                  fontFamily: 'Milliard Book',
                  fontSize: isMobile ? '1.3rem' : '1.8rem',
                  color: courseColor,
                  borderBottom: '2px dashed #ccc',
                  marginBottom: '0px',
                  paddingBottom: '10px',
                  fontFeatureSettings: 'tnum',
                  fontVariantNumeric: 'tabular-nums',
                }}
              >
                <SoundRenderer
                  text={widget.title}
                  widgetType='TextBoxWidget'
                />
              </h2>
              )}

              {widget.subtitle && (
                <h4
                  data-t={`widget:textbox-subtitle-${widget._id}`}
                  style={{
                    fontFamily: 'Milliard Book',
                    margin: 0,
                    marginLeft: howMuchMarginLeft(widget.title),
                    color: courseColor2,
                    fontSize: isMobile ? '1rem' : '1.5rem',
                    paddingTop: '7px'
                  }}
                >
                  <SoundRenderer
                    contextAudio
                    text={widget.subtitle}
                    widgetType='TextBoxWidget'
                  />
                </h4>
              )}
            </div>
          )}
          
          <div
            className='fz-dark-font'
            data-t={`widget:textbox-body-${widget._id}`}
            style={{
              whiteSpace: 'pre-line',
              fontFamily: 'Milliard Book',
              paddingLeft: widget?.title || widget?.indent ? '20px' : '0px',
              paddingRight: widget?.title ? '20px' : '0px',
              paddingTop: widget?.title ? '15px' : '0px',
              fontSize: widget?.title ? '1rem' : '1.5rem',
              lineHeight: '1.8',
            }}
          >
            <ReactLinkify>
              <SoundRenderer contextAudio urlify text={widget?.body} bold widgetType='TextBoxWidget' />
            </ReactLinkify>
          </div>
        </div>
      </WidgetWrapper>
    </div>
  )
}
/* eslint-enable */

export default TextBoxWidget
