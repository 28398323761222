import usePlan from './usePlan'

export type PLAN_CHOICE = 'premium-monthly' | 'premium-annual' | ''

/**
 * Given a monthly or annual plan choice, return the pricing information for that
 * plan via finding out things like whether the user is grandfathered, whether there
 * is a global campaign, whether they have personalized marketing discounts, etc.
 */
const usePricing = () => {
  const { planId: monthlyPlan, price: monthlyPrice, base: monthlyBase } = usePlan('premium-monthly')
  const { planId: annualPlan, price: annualPrice, base: annualBase } = usePlan('premium-annual')

  // calculate the price and plan based on the user's status etc in hooks

  return { monthlyPrice, annualPrice, monthlyBase, annualBase, monthlyPlan, annualPlan }
}

export default usePricing
