import { REDUX_NAV_ACTIONS, SET_SUBLESSON, SIGN_OUT } from 'src/actions/types'
import { CourseInfo, SubLesson } from 'src/types'

interface NAV_STATE {
  admin_view: boolean
  course?: string
  lesson?: string
  nav_bar_color?: string
  nDisabled?: boolean
  notification_count?: number
  nSlug?: string
  part?: string
  pDisabled?: boolean
  pSlug?: string
  linkToPaywall: boolean
  subLesson?: SubLesson,
  numLessons?: number,
  numPrelessons?: number,
  sub?: string
  user_avatar_cf?: string
  avatar_expires_at?: Date | string
  course_info: CourseInfo
}

interface Action {
  type: REDUX_NAV_ACTIONS | string
  payload: NAV_STATE
}

export interface NavCTX {
  navCtxState: NAV_STATE
  navCtxDispatch: (payload: NAV_STATE) => void
}

const INITIAL_STATE: NAV_STATE = {
  admin_view: false,
  course: undefined,
  lesson: undefined,
  nav_bar_color: undefined,
  nDisabled: undefined,
  notification_count: undefined,
  nSlug: undefined,
  part: undefined,
  pDisabled: undefined,
  pSlug: undefined,
  sub: undefined,
  user_avatar_cf: undefined,
  avatar_expires_at: undefined,
  course_info: undefined,
  numLessons: undefined,
  numPrelessons: undefined,
  linkToPaywall: undefined
}

// eslint-disable-next-line
export default (state: NAV_STATE = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case SIGN_OUT: {
      return { ...INITIAL_STATE }
    }
    case REDUX_NAV_ACTIONS.SET_SLUG: {
      return {
        ...state,
        course: action.payload.course,
        lesson: action.payload.lesson,
        nDisabled: action.payload.nDisabled,
        nSlug: action.payload.nSlug,
        part: action.payload?.part || undefined,
        pDisabled: action.payload.pDisabled,
        pSlug: action.payload.pSlug,
        sub: action.payload.sub,
        linkToPaywall: action.payload.linkToPaywall,
      }
    }
    case SET_SUBLESSON:
    case REDUX_NAV_ACTIONS.SET_SUBLESSON: {
      return {
        ...state,
        subLesson: action.payload.subLesson,
      }
    }
    case REDUX_NAV_ACTIONS.SET_NUM_LESSONS: {
      return {
        ...state,
        numLessons: action.payload.numLessons
      }
    }
    case REDUX_NAV_ACTIONS.SET_NUM_PRELESSONS: {
      return {
        ...state,
        numPrelessons: action.payload.numPrelessons
      }
    }
    case REDUX_NAV_ACTIONS.SET_AVATAR_CF: {
      return {
        ...state,
        user_avatar_cf: action.payload.user_avatar_cf,
        avatar_expires_at: action.payload.avatar_expires_at,
      }
    }
    case REDUX_NAV_ACTIONS.SET_BAR_COLOR: {
      return {
        ...state,
        nav_bar_color: action.payload.nav_bar_color,
      }
    }
    case REDUX_NAV_ACTIONS.SET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notification_count: action.payload.notification_count,
      }
    }
    case REDUX_NAV_ACTIONS.SET_ADMIN_VIEW: {
      return {
        ...state,
        admin_view: action.payload.admin_view,
      }
    }
    case REDUX_NAV_ACTIONS.SET_COURSE_INFO: {
      return {
        ...state,
        course_info: action.payload.course_info,
      }
    }
    default: {
      return state
    }
  }
}
