import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'

import axios from 'src/api'
import { RootState } from 'src/configureStore'

const getGrandfatherStatus = async () => {
  try {
    const res = await axios.get('/subscriptions/grandfather')
    return res?.data?.grandfather_plan || null
  } catch (e) {
    return null
  }
}

const useGrandfather = () => {
  const { _id } = useSelector((state: RootState) => state.auth)
  const { data: grandfather_plan } = useQuery({ queryKey: ['grandfather_status', _id], queryFn: getGrandfatherStatus })

  return { grandfather: grandfather_plan }
}

export default useGrandfather
