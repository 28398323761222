import styled, { css, createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

const CreationToolKitGlobalStyles = createGlobalStyle`
  body {
    --main: #495576;
    --buttons: #6b7cb4;
    --buttons-hover: #5568a8;

    /* panel segments: */
    --dark-panel: #b7c3eb;
    --mid-panel: #d6ddf4;
    --light-panel: #f5f7ff;

    /* borders: */
    --dark-border: #6b7cb4;
    --mid-border: #97a6d6;
    --light-border: #b7c3eb;

    /* button colors: */
    --save: #83e6a6;
    --delete: #d34a71;
    --add: #72b1ff;
    --duplicate: #72b1ff;

    --save-hover: #3cd873;
    --delete-hover: #dd7391;
    --add-hover: #509eff;

    --edit-red: var(--delete);
    --edit-green: var(--save);
    --edit-blue: var(--add);

    * {
      font-family: 'Milliard Book', 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif, 'YesJapan Gothic';
    }
  }
`

const defaultToolSectionRules = css`
  background-color: var(--main);
  width: 100%;
  min-height: 45px;

  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
`

export default styled.section<{ outlineColor: string }>`
  position: relative;
  outline: 2px solid ${({ outlineColor }) => outlineColor};
  border-radius: 2px;
  :focus-within {
    outline-color: var(--yellow);
    outline-width: 4px;
    z-index: 1;
    box-shadow: 0 0 10px 5px rgba(255,255,0,0.25);
  }
  /**
    individual common parts
  */

  button {
    font-weight: bold;
  }

  input {
    padding: 4px 10px;
    font-size: 1.1rem;
    border-radius: 8px;

    :focus {
      outline: 1px solid var(--jfz-blue);
    }
  }

  /* @media (prefers-color-scheme: dark) {
    input, textarea, select {
      background-color: #333 !important;
      color: var(--off-white) !important;
    }
    background-color: #2f3640;
  } */
`

const EditableWidget = styled.div<{ floating: boolean }>`
  border: ${({ floating }) => floating ? 'none' : '2px dashed var(--main)'};
  width: 100%;
  .ImageWidget {
    img {
      max-height: ${({ floating }) => floating ? '300px' : '100%'};
    }
  }
`

const ToolBody = styled.div`
  ${tw`tw-flex tw-flex-col tw-justify-center tw-items-center`}
  background-color: var(--light-panel);
`

const ToolHeader = styled.div`
  ${defaultToolSectionRules}
  ${tw`tw-shadow-lg tw-justify-between`}
`

const HeaderToolType = styled.div`
  ${tw`tw-flex tw-justify-center tw-items-center tw-font-bold tw-py-2 tw-px-6`}
  min-width: max-content;
  background-color: var(--orange);
  color: var(--blue-gray);
`

const HeaderSubheader = styled.div`
  ${tw`tw-flex tw-flex-row tw-items-center tw-justify-between tw-w-full`}

  label {
    ${tw`tw-font-bold tw-p-2 tw-text-white`}
  }

  input {
    ${tw`tw-rounded-md tw-p-2 tw-w-1/2`}
  }

  div {
    ${tw`tw-flex tw-items-center tw-h-full`}

    :first-child {
      ${tw`tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-2 tw-w-full`}
    }
    :last-child {
      ${tw`tw-flex tw-flex-row tw-items-center tw-gap-2 tw-px-2 tw-ml-auto`}
      width: min-content;
    }
  }

  .title-input:focus-within,
  .title-input:not(.title-input:focus-within):hover {
    width: 100%;

    input { width: 80% }
  }
  
  .title-input:not(.title-input:focus-within) {
    width: 40%;
    input { width: 50% }
  }

  button {
    ${tw`tw-p-2 tw-rounded-full tw-bg-white`}
    height: 35px;
    width: 35px;

    i {
      position: relative;
      bottom: 2px;
      font-size: 1.2rem;
      color: #333;
    }

    :hover {
      background-color: #ddd;
    }
  }
`

const ToolOptions = styled.div`
  ${defaultToolSectionRules}
  ${tw`tw-justify-between tw-items-center`}

  color: var(--blue-gray);
  background-color: var(--light-panel);

  .extra-options,
  .default-options {
    ${tw`tw-flex tw-items-center`}
  }

  .extra-options {
    ${tw`tw-items-stretch tw-gap-4 tw-w-3/5 tw-ml-3`}

    >div {
      ${tw`tw-flex tw-items-center tw-gap-4`}
    }
  }

  .default-options {
    ${tw`tw-self-stretch tw-justify-end tw-w-2/5 tw-m-0 tw-ml-auto`}

    button {
      ${tw`tw-h-full tw-py-2 tw-px-4 tw-text-white`}
    }

    .copy-id {
      color: var(--blue-gray);
      background-color: var(--mid-panel);
    }
    .cache { background-color: var(--dark-panel); }
    .danger-delete {
      ${tw`tw-p-2 tw-w-24`}
      background-color: var(--delete);

      :hover {
        background-color: var(--delete-hover);
      }
    }

    .polly { background-color: plum; }
    .polly.force { background-color: rebeccapurple; }
  }
`

const ToolSubheader = styled.div`
  ${defaultToolSectionRules}
  ${tw`tw-p-4 tw-flex-row tw-gap-2 tw-ml-auto tw-mr-0`}

  background-color: var(--dark-panel);
  box-shadow:
    0 10px 5px -10px rgba(214, 222, 248, 1),
    0 5px 10px -10px rgba(214, 222, 248, 1),
    0 -10px 5px -10px rgba(214, 222, 248, .4);

  input {
    ${tw`tw-w-full tw-border-2 tw-rounded-lg tw-shadow-sm`}
    border-color: var(--dark-border);
  }
`

const ToolFooter = styled.div`
  ${defaultToolSectionRules}
  background-color: var(--main);
`

const Timestamp = styled.p`
  ${tw`tw-bg-offwhite tw-text-gray-600 tw-font-bold tw-px-1.5 tw-whitespace-nowrap tw-rounded-md`}
`

const ToolFooterLeft = styled.div<{ removeLeftMargin: boolean }>`
  ${defaultToolSectionRules}
  ${tw`tw-items-center tw-gap-2 tw-mr-auto tw-ml-2`}
  margin-left: ${({ removeLeftMargin }) => removeLeftMargin ? '0' : ''};
`

const ToolFooterRight = styled.div`
  ${defaultToolSectionRules}
  ${tw`tw-justify-center tw-items-center`}
  width: fit-content;
`

const RenderPreview = styled.div`
  ${tw`tw-absolute tw-top-0 tw-right-0 tw-z-20 tw-bg-white tw-rounded-xl tw-shadow-md`}
`

const WidgetContent = styled.div`
  ${tw`tw-flex tw-justify-center tw-items-center tw-rounded-xl tw-p-8`}
  background-color: var(--light-panel);
`

const ClosePreviewButton = styled.div`
  ${tw`tw-absolute tw-top--3 tw-right--3 tw-z-10 tw-rounded tw-shadow-md tw-rounded-full tw-w-11 tw-h-11 tw-border-4 tw-border-white tw-cursor-pointer`}

  background-color: #f57504;

  &:focus { outline: 2px solid #333; }

  &>div {
    ${tw`tw-relative tw-w-full tw-h-full tw-rounded-full tw-border-2 tw-border-solid tw-border-black`}
  }

  i {
    position: relative;
    color: var(--off-white);
    top: 3px;
    left: 4.5px;
  }
`

const JumpBackToTool = styled(ClosePreviewButton)`
  ${tw`tw-left--3 tw-bg-teal-500`}

  i {
    top: 3px;
    left: 4px;
  }
`

export {
  ClosePreviewButton,
  EditableWidget,
  CreationToolKitGlobalStyles,
  JumpBackToTool,
  RenderPreview,
  Timestamp,
  ToolBody,
  ToolFooter,
  ToolFooterLeft,
  ToolFooterRight,
  ToolHeader,
  HeaderToolType,
  HeaderSubheader,
  ToolOptions,
  ToolSubheader,
  WidgetContent,
}
