import React, { useEffect, useState } from 'react'
import axios from '../../../../../api'

import { Button, Form, Segment } from 'semantic-ui-react'

import useWidget from '../../../hooks/useWidget'

const LineConnectWidgetEditor = props => {
    const [pairs, setPairs] = useState([])
    const [widget, loading, fetchWidget, editWidget] = useWidget(props.id)

    useEffect(() => {
        setPairs(widget?.pairs)
    }, [widget])

    const addPair = () => {
        let tempPairs = [...pairs]
        tempPairs.push({left: '', right: ''})
        setPairs(tempPairs)
    }

    const setPair = (idx, left, right) => {
        let tempPairs = [...pairs]
        tempPairs[idx] = {left, right}
        setPairs(tempPairs)
    }

    return (
        <div>
            <Form loading={loading}>
                {pairs?.map((p, idx) => 
                    <Form.Group>
                        <Form.Input
                            value={pairs[idx].left}
                            onChange={(e, {value}) => setPair(idx, value, p.right)}
                            label='Left Item'
                        />
                        <Form.Input
                            value={pairs[idx].right}
                            onChange={(e, {value}) => setPair(idx, p.left, value)}
                            label='Right Item'
                        />
                    </Form.Group>)}
            </Form>
            <Button color='blue' icon='plus' onClick={addPair} />
            <Button color='green' icon='save' onClick={() => editWidget({pairs})} />
        </div>
    )
}

export default LineConnectWidgetEditor