// hook for grabbing whether the user is paying and their yj group status from the API
import { useEffect, useState } from 'react'
import useAPI from 'src/hooks/useAPI'

const useIsPaying = () => {
  const [res, loading] = useAPI('/user/is_paying', 'GET')
  const [yj, setYj] = useState(false)
  const [personalExpiry, setPersonalExpiry] = useState(null)
  const [group, setGroup] = useState(null)
  const [paying, setPaying] = useState(undefined)

  useEffect(() => {
    if (res?.success) {
      setYj(res.yj)
      setPersonalExpiry(res.personal_expiry)
      setGroup(res.group)
      setPaying(res.paying)
    }
  }, [res])

  return {
    yj, personalExpiry, group, loading, paying
  }
}

export default useIsPaying
