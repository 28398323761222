import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { TEXTLIST } from '../toolkit.constants'
import { TextListSections, TextListWidget } from 'src/types'
import { ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { ListInputDivider, ToolFooter, ToolForm, ToolHeader, ToolInput, ToolOptions } from '../parts'
import useWidgetSaved from '../hooks/useWidgetSaved'
import TextListSection from './textlist/TextListSection'
import AddSectionGroup from './textlist/AddSectionGroup'
import { InsetDiv } from '../styles/TextList.styles'

const TextListTool: React.FC<ToolProps<TextListWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [storedSections, setStoredSections] = useState<TextListSections[]>()
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<TextListWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const commitTemporaryChanges = (sections: TextListSections[]) => {
    hookFormMethods.setValue('sections', sections)
    setStoredSections(sections)
  }

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setStoredSections(widget.sections)
      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const addToSectionGroups = (sIndex: number) => {
    const sections = [...storedSections]
    const newSection: TextListSections = {
      title: '',
      contents: [{
        other_lang: '',
        english: ''
      }]
    }
    sections.splice(sIndex + 1, 0, newSection)
    commitTemporaryChanges(sections)
  }

  const removeFromSectionGroups = (sIndex: number) => {
    const sections = [...storedSections]
    sections.splice(sIndex, 1)
    commitTemporaryChanges(sections)
  }

  const handleRemoveContentFromList = (sIndex: number) => {
    const sections = [...storedSections]
    sections[sIndex].contents.pop()
    commitTemporaryChanges(sections)
  }

  const handleAddToContentList = (sIndex: number) => {
    const sections = [...storedSections]
    sections[sIndex].contents.push({
      other_lang: '',
      english: ''
    })

    commitTemporaryChanges(sections)
  }

  const fieldLabels = {
    romajiOnly: 'Romaji Only',
    plays_sound: 'Play Sound',
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={TEXTLIST}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='textbox-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={TEXTLIST}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              {['romajiOnly', 'plays_sound'].map((inp, idx) => (
                <ToolInput
                  key={idx}
                  value={inp}
                  inputType='checkbox'
                  inputLabel={fieldLabels[inp]}
                  labelColor='var(--blue-gray)'
                  labelPosition='after'
                />
              ))}
            </div>
          </ToolOptions>
          <ToolBody>
            {storedSections?.length ? storedSections.map((section, sIdx) => (
              <React.Fragment key={section._id}>
                <TextListSection
                  removeFromSectionGroups={removeFromSectionGroups}
                  handleRemoveContentFromList={handleRemoveContentFromList}
                  indexInList={sIdx}
                  section={section}
                />
                <InsetDiv>
                  <ListInputDivider
                    borderStyle='dashed'
                    handleClicked={() => handleAddToContentList(sIdx)}
                  />
                </InsetDiv>
                <AddSectionGroup
                  handleClicked={addToSectionGroups}
                  indexInList={sIdx}
                />
              </React.Fragment>
            )) : (
              <AddSectionGroup
                handleClicked={addToSectionGroups}
                indexInList={0}
              />
            )}
          </ToolBody>

          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          />
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default TextListTool
