import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import useToolkitStore from 'src/stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'
import useWidgetSaved from '../hooks/useWidgetSaved'

import { Conversation, ConversationWidget } from 'src/types'

import { CONVERSATION } from '../toolkit.constants'
import { ToolProps } from '../interfaces/Tools.interface'

import { BarButton, ToolBody } from '../styles'
import { SpeakerSelectionGroup } from '../styles/ConversationTool.styles'
import { FlexCol, FlexRow } from 'src/assets/styles/globalStyles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { IconButton, ToolFooter, ToolHeader, ToolInput, ToolOptions, ToolSubheader } from '../parts'
import FormatPreviewShell from 'src/components/fz_courses/sublessonpart/FormatPreviewShell'
import ToolForm from '../parts/ToolForm.part'
import ConversationBlock from './conversation/ConversationBlock'
import SpeakerSelect from './conversation/SpeakerSelect'

const emptyConversation = {
  _id: undefined,
  title: '',
  commentary: '',
  other_lang: ['', ''],
  english: ['', ''],
  icons: ['tomoko', 'kenji'],
}

const ConversationTool: React.FC<ToolProps<ConversationWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [widget, fetchWidget, , editWidget] = useWidget<ConversationWidget>(id)
  const [storedConvos, setStoredConvos] = useState<Conversation[]>()
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [refresher, setRefresher] = useState(0)

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setWidgetValues(widget)
      setStoredConvos(widget.conversations)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const commitTemporaryChanges = (conversations) => {
    hookFormMethods.setValue('conversations', conversations)
    setStoredConvos(conversations)
  }

  const handleSpeakerIcon = (
    convoNumber: number,
    chatPosition: number,
    icon: string
  ) => {
    const _conversations = hookFormMethods.getValues()['conversations']
    _conversations[convoNumber].icons[chatPosition] = icon
    commitTemporaryChanges(_conversations)
  }

  const addLineToConversation = (convoIdx: number) => {
    const _conversations = hookFormMethods.getValues()['conversations']
    _conversations[convoIdx].other_lang.push('')
    _conversations[convoIdx].english.push('')
    commitTemporaryChanges(_conversations)
  }

  const removeLineFromConversation = (convoIdx: number, rowIdx: number) => {
    const _conversations = hookFormMethods.getValues()['conversations']
    _conversations[convoIdx].other_lang.splice(rowIdx, 1)
    _conversations[convoIdx].english.splice(rowIdx, 1)
    commitTemporaryChanges(_conversations)

    // TODO: when no lines, remove section?
  }

  const addNewConversationSection = () => {
    const _conversations = hookFormMethods.getValues()['conversations']
    _conversations.push(emptyConversation)
    commitTemporaryChanges(_conversations)
  }

  const removeConversationSection = (convoNum: number) => {
    const _conversations = hookFormMethods.getValues()['conversations']
    _conversations.splice(convoNum, 1)
    commitTemporaryChanges(_conversations)
  }

  const toolOptionsLabels = {
    displayOrder: ['Display Order', 'Display English first or second'],
    numericalOrdering: ['Ordered', 'Toggle conversation numbers'],
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={CONVERSATION}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={CONVERSATION}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              {Object.keys(toolOptionsLabels).map((opt) => (
                <ToolInput
                  key={opt}
                  value={opt}
                  inputType='checkbox'
                  inputLabel={toolOptionsLabels[opt][0]}
                  title={toolOptionsLabels[opt][1]}
                  labelPosition='after'
                />
              ))}
            </div>
          </ToolOptions>
          <Droppable droppableId={`drop-convo-${id}`} type='CONVO_BLOCK'>
            {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
            {(provided, snapshot) => (
              <div
                style={{ width: '100%' }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {storedConvos?.map((convo, idx) => (
                  <Draggable
                    draggableId={`convo-block-drag-${idx}-${id}`}
                    index={idx}
                    key={`convo-block-${idx}`}
                  >
                    {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                    {(innerProvided, innerSnapshot) => (
                      <div
                        key={convo._id}
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        <ToolSubheader>
                          <FlexRow center gap={2}>
                            <FlexCol gap={1}>
                              <ToolInput
                                value={`conversations.${idx}.title`}
                                placeholder='Title'
                              />
                              <ToolInput
                                value={`conversations.${idx}.commentary`}
                                placeholder='Commentary'
                              />
                            </FlexCol>
                            {storedConvos?.length > 1 && (
                              <IconButton
                                icon='x'
                                color='red'
                                contrastColor='white'
                                title='Delete this conversation block'
                                handleClick={() => removeConversationSection(idx)}
                              />
                            )}
                          </FlexRow>
                        </ToolSubheader>
                        <ToolBody>
                          <ConversationBlock
                            convoNumber={idx}
                            conversation={convo}
                            addLineToConversation={addLineToConversation}
                            removeLineFromConversation={removeLineFromConversation}
                            convoId={id}
                            onEdit={() => setRefresher((p) => p + 1)}
                          />
                          <SpeakerSelectionGroup>
                            <SpeakerSelect
                              speakers={storedConvos[idx].icons}
                              convoNumber={idx}
                              onSelected={handleSpeakerIcon}
                              speakerPosition={0}
                            />
                            <SpeakerSelect
                              speakers={storedConvos[idx].icons}
                              convoNumber={idx}
                              onSelected={handleSpeakerIcon}
                              speakerPosition={1}
                            />
                          </SpeakerSelectionGroup>
                        </ToolBody>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            removeLeftMargin
            widget_id={id}
          >
            <BarButton
              type='button'
              onClick={() => addNewConversationSection()}
              color='white'
              backgroundColor='var(--add)'
              borderColor='var(--save)'
              contrastColor='var(--save)'
            >
              Add Conversation
            </BarButton>
            <div>
              <ToolInput
                value={'plays_sound'}
                title='Should this widget play VA sounds?'
                inputType='checkbox'
                inputLabel='Play Sound'
                labelPosition='after'
                labelColor='white'
              />
            </div>
            {/* eyeball icon button that will open the widget render preview */}
            <div>
              <IconButton
                icon='eye'
                backgroundColor='transparent'
                contrastColor='white'
                title='Preview'
                handleClick={() => setPreviewOpen((p) => !p)}
              />
            </div>
          </ToolFooter>
          {previewOpen && (
            <FormatPreviewShell
              widget={{ ...hookFormMethods.getValues(), id }}
              type='conversation'
              refresher={refresher}
              course={course}
            />
          )}
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default ConversationTool
