import React from 'react'
import { Banner, ChibiContainer, Chibi, SlideTitle, JZFLogo } from '../../styles/PresentationWidget.styles'

import tomoko from 'src/assets/chibi/tomoko/chibi_culture_clip_right.png'
import kenji from 'src/assets/chibi/kenji/chibi_new_kana.png'
import mari from 'src/assets/chibi/mari/chibi_new_phrases_right.png'
import ren from 'src/assets/chibi/ren/chibi_grammar.png'
import rika from 'src/assets/chibi/rika/chibi_comprehension.png'

const SlideBanner = ({ title, courseColor, preview = false }) => {
  return (
    <Banner color={courseColor} preview={preview}>
      <ChibiContainer>
        <Chibi src={mari} />
      </ChibiContainer>
      <JZFLogo />
      <SlideTitle>{title}</SlideTitle>
    </Banner>
  )
}

export default SlideBanner