import { Widget } from '.'
import { CustomProgressive } from '..'
import { UserAgent } from '../common'

/* eslint-disable no-use-before-define */
// types and interfaces are allowed to be defined in any order in typescript land
export interface User {
  _id: string
  username: string
  email: string
  alternate_contact: string
  active?: boolean
  courses?: string[]
  subscriptions?: string[]
  admin?: boolean
  badges?: Badge[]
  account_created: Date
  address?: {
    name?: string
    street?: string
    city?: string
    state?: string
    zip?: string
  }
  roles?: string[]
  claimed: boolean
  notes: string[]
  access: string[]
  s3_avatar_path?: string
  default_avatar: {
    color: string
    version: number
  }
  course_background: string
  history?: { pathname?: string }[]
  received_free_course: boolean
  shared_emails: string[]
  benefacted: boolean
  claimed_gift: boolean
  benefactees: User[]
  benefactor: User

  stripe_cus_id?: string
  stripe_sub_id?: string
  stripe_price_id?: string
  current_period_end?: Date
  card_brand?: string
  card_last_4?: string
  canceled?: boolean

  subs?: Subscription[]

  progressive_settings?: ProgressiveSettings
  notebook?: NotebookCategory[]
  last_course?: {
    id?: string
    lesson?: string
    sublesson?: string
    part?: string
    path?: string
  }
  liked_questions?: AskATeacherQuestion[]
  liked_answers?: AskATeacherAnswer[]
  social?: {
    muted: boolean
    reason?: string
    muted_until?: Date
  }
  last_seen: Date
  grandfather_plan: Plan
}

export interface FZAffiliate {
  identifier: string
  person_name: string
  user: User
  internal: boolean
  active: boolean
  createdAt: Date
  updatedAt: Date
}

export interface Geolocation {
  location: {
    coordinates: number[], // [longitude, latitude] IN THAT SPECIFIC ORDER
  },
  country_code: string,
  country_name: string,
  city: string,
  state: string,
  IPv4: string,
}

export interface UserGeolocation extends Geolocation {
  _id: string
  user: User,
  created_at: Date,
  updated_at: Date,
  user_agent: UserAgent,
}

// eslint-disable-next-line
export enum BADGE_IDENTIFIER {
  PLACEHOLDER = 'abc',
  LEGACY = 'legacy',
  FOUNDER = 'founder',
  PIONEER = 'pioneer',
  WISHTAG = 'wishtag',
  MENTOR = 'mentor',
  MEETUP = 'meetup',
  DEV = 'dev',
  ARTIST = 'artist',
  CEO = 'ceo',
  TEACHER = 'teacher',
  AUTHOR = 'author',
  MOD = 'mod',
  JFZ_BOOK1_BRONZE = 'jfz-book1-bronze',
  JFZ_BOOK1_SILVER = 'jfz-book1-silver',
  JFZ_BOOK1_GOLD = 'jfz-book1-gold',
  JFZ_BOOK2_BRONZE = 'jfz-book2-bronze',
  JFZ_BOOK2_SILVER = 'jfz-book2-silver',
  JFZ_BOOK2_GOLD = 'jfz-book2-gold',
  JFZ_BOOK3_BRONZE = 'jfz-book3-bronze',
  JFZ_BOOK3_SILVER = 'jfz-book3-silver',
  JFZ_BOOK3_GOLD = 'jfz-book3-gold',
  JFZ_BOOK4_BRONZE = 'jfz-book4-bronze',
  JFZ_BOOK4_SILVER = 'jfz-book4-silver',
  JFZ_BOOK4_GOLD = 'jfz-book4-gold',
  JFZ_BOOK5_BRONZE = 'jfz-book5-bronze',
  JFZ_BOOK5_SILVER = 'jfz-book5-silver',
  JFZ_BOOK5_GOLD = 'jfz-book5-gold'
}

export interface Badge {
  award?: string
  badge_earned?: Date
  display?: boolean
  icon?: string
  identifier?: string
  name?: string
  nameShort?: string
  obtained?: boolean
  prerequisite?: string
  selected?: boolean
}

export interface Subscription {
  _id?: string
  user?: User
  plan?: Plan

  stripe_cus_id?: string
  stripe_sub_id?: string

  paypal_sub_id?: string
  paypal_cus_id?: string
  
  current_period_end?: Date
  card_brand?: string
  card_last_4?: string
  active?: boolean
  last_paid?: Date
  canceled?: boolean
  payment_failed?: boolean
  date_created?: Date
  last_modified?: Date
  gifted_duration?: number
}

export interface Transaction {
  _id: string
  user: User
  description: string
  amount: number
  date_created: Date
  plan: Plan
  refunded: boolean
  refunded_date: Date
}

export interface Plan {
  _id: string
  identifier: string
  name: string
  stripe_price_id: string
  paypal_price_id: string
  subscribed: number // number of users on this plan
  date_created: Date
  amount: number
}

export interface MasterRecord {
  _id: string

  children: string[]
  text: string
  extraData?: string
  flatText?: string
  flatData?: string
  date_created: Date
}

type Speaker = {
  [key: string]: string
}

export type Record = {
  _id: string
  bonus?: any
  tags: string[]
  refs: string[]
  type: string
  breakdown: {
    _id: string
    word_id: number
    word_type: string,
    word_position: number,
    surface_form: string
    pos: string
    pos_detail_1: string
    pos_detail_2: string
    pos_detail_3: string
    conjugated_type: string
    conjugated_form: string
    basic_form: string
    reading: string
    pronunciation: string
  }[]
  date_created: Date
  sounds: [{
    speaker: string
    path: string,
    date_created: Date
  }]
  base_text: string
  kanji: string
  progressive: string
  hirakata: string
  romaji: string
  master: MasterRecord | string
  __v: number
}

export interface LanguageRecord {
  _id: string
  text?: string
  widget?: string
  record: Record
}

export interface NotebookCategory {
  _id: string
  title: string
  user: User
  entries: NotebookEntry[]
  date_created: Date
}

export interface NotebookEntry {
  _id: string
  user: User
  record: LanguageRecord
  category: NotebookCategory
  date_created: Date
}

export interface AskATeacherQuestion {
  _id: string
  answers?: AskATeacherAnswer[]
  body?: string
  date_created?: Date
  teacher_answered_date?: Date
  flagged: boolean
  likes?: User[]
  likeCount?: number
  user?: User
  reports?: {
    reported_by?: User
    reason?: string
    reported_on?: string
  }[]
  status: string
  tag: string[]
  title?: string
  injected_text: string
  url?: string
  metadata?: {
    ip?: string
    userAgent?: UserAgent
    location?: Geolocation
    name?: string
    email?: string
  }
  images?: [{ path: string, date_created?: Date }]
  yj_imported?: boolean
}

export interface AskATeacherAnswer {
  _id: string
  body: string
  date_created: Date
  flagged: boolean
  isVerifiedAnswer: boolean
  question: AskATeacherQuestion
  likes: User[]
  likeCount: number
  reports?: {
    reported_by: User
    reason: string
    reported_on: string
  }[]
  user: User
  injected_text: string
}

export interface Bulletin {
  author_role: string
  title?: string
  content: string
  pub_date: string
  author: User
  visible: boolean
}

export interface Course {
  _id: string
  identifier: string
  title?: string
  instructor?: string
  description?: string
  lessons?: Lesson[]
  prelessons?: Lesson[]
}

export interface Lesson {
  title?: string
  subtitle?: string
  description?: string
  
  before?: string
  before_cache?: string
  before_needs_cache?: boolean

  goals?: string
  goals_cache?: string
  goals_needs_cache?: boolean
  
  from_teacher?: string
  from_teacher_cache?: string
  from_teacher_needs_cache?: boolean

  courseName?: string
  sublessons?: SubLesson[]
  path?: string
  comments?: LessonComment[]
  widgets?: Widget[]
  progressive_data?: ProgressiveData
}

export interface WidgetCache {
  cache?: string
  widget?: Widget
  pure_widget?: string
  needs_recache?: boolean
  date_cached: Date
}

export interface SoundInstance {
  widget?: Widget
  lang_record?: LanguageRecord
  text_display?: string
  description?: string
  display_type?: string
}

export interface ProgressiveSettings {
  _id?: string
  user?: User
  main_default: 'romaji' | 'hirakata' | 'progressive' | 'kanji'
  furi_opacity: number
  custom_progressive?: {
    enabled: boolean
    settings: CustomProgressive
  }
}

export interface ProgressiveData {
  keyword?: string
  link?: ProgressiveData
  keys?: string[]
  vals?: string[]
}

export interface SubLessonPart {
  _id: string
  title: string
  widgets?: Widget[]
  stale_part?: boolean // used in Creation ToolKit Tools
}

export interface SubLesson {
  _id: string
  description?: string
  is_draft: boolean
  parent_lesson: string
  parts: SubLessonPart[]
  progressive_only: boolean
  sublesson_mascot?: string
  sublesson_type?: string
  title: string
  subtitle?: string
  widgets: Widget[]
}

export interface LessonComment {
  lesson_ref?: Lesson
}

export interface Webhook {
  _id: string,
  body: string,
  type: string,
  user?: User,
  date_created: Date,
  archived?: boolean,
  sub_id?: string,
  key?: string
}

export interface BugReport {
  _id: string
  title?: string
  bug_type?: string
  body?: string
  submitter?: User
  metadata?: {
    browser?: {
      name?: string
      version?: string
    }
    engine?: {
      name?: string
      version?: string
    }
    os?: {
      name?: string
      version?: string
      versionName?: string
    }
    platform?: string
    connection?: string
    cookiesEnabled?: boolean
    installedLanguages?: string[]
    activeLanguage?: string
    geoloc?: {
      IPv4: string
      city: string
      country_code: string
      country_name: string
      latitude: number,
      longitude: number,
      postal_code: string
      state: string
    }
    name?: string
    email?: string
  }
  history?: string[]
  date_created: Date
  location?: string
  follow_up?: boolean
  fixed: boolean
  assigned_to?: {
    name: string
    assigned_on: Date
    admin: User
  },
  working_on?: {
    name: string
    since: Date
    admin: User
  },
  dev_note?: {
    author: User
    body: string
    date_created: Date
  }[]
  follow_ups?: {
    _id: string
    user: User
    type: string
    body: string
    date_created: Date
  }[]
  watchers?: User[],
  visibility?: 'pending' | 'public' | 'private' | 'hidden'
  tags?: {
    name: string
    color: string
  }[]
  closed_by?: User
  closed_at?: Date
  devComment?: string
  active: boolean
  reopened_count?: number
}

export interface Tag {
  _id: string
  key: string
  text: string
  color?: string
}

export interface FollowUp {
  _id: string
  user: User
  type: string
  body: string
  date_created: Date
  images?: [{ path: string, date_created?: Date }]
}

export interface PublicBugReport {
  _id: string
  bug_type?: string
  title?: string
  body?: string
  submitter?: User
  metadata?: {
    email?: string
    name?: string
    browser?: {
      name?: string
      version?: string
    }
    engine?: {
      name?: string
      version?: string
    }
    os?: {
      name?: string
      version?: string
      versionName?: string
    }
    platform?: string
    connection?: string
    cookiesEnabled?: boolean
    installedLanguages?: string[]
    activeLanguage?: string
    geoloc?: {
      IPv4: string
      city: string
      country_code: string
      country_name: string
      latitude: number,
      longitude: number,
      postal_code: string
      state: string
    }
  }
  date_created: Date
  location?: string
  fixed: boolean
  assigned_to?: {
    name: string
    assigned_on: Date
    admin: User
  },
  working_on?: {
    name: string
    since: Date
    admin: User
  },
  follow_ups?: FollowUp[]
  watchers?: User[],
  visibility?: 'pending' | 'public' | 'private' | 'hidden'
  tags?: [Tag],
  closed_by?: User
  closed_at?: Date
  active: boolean
  devComment?: string
  reopened_count?: number
  follow_up?: boolean
  dev_note?: [{
    author: User
    body: string
    date_created: Date
  }]
  widget: string
  images?: [{ path: string, date_created?: Date }]
}

export interface PrivateNotification {
  _id: string
  for: User
  content: string
  contentType?: string
  createdAt: Date
  noReply?: boolean
  read: boolean
  safeHTML: boolean
  shouldClean: boolean
  title: string
  type?: string // added in Notifications page programmatically
}

export interface GlobalNotification {
  _id: string
  content: string
  cleanAt: string
  contentType?: string
  createdBy: string
  createdAt: string
  noReply?: boolean
  readBy: [string]
  read: boolean
  safeHTML: boolean
  sticky: boolean
  title: string
  type?: string // added in Notifications page programmatically
}
/* eslint-enable */
