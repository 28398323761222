import React from 'react'
import { useHistory } from 'react-router-dom'
import HelmetMetaData from 'src/components/HelmetMetaData'

import useMobile from '../../hooks/useMobile'

import styled, { createGlobalStyle } from 'styled-components'

import natsumi_panic from '../../assets/chibi/jfz_chibi_natsumi_2.png'

const PageNotFoundStyled = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  #natsumi {
    height: ${props => props.isMobile ? '200px' : '600px'};
    margin-right: ${props => props.isMobile ? '10px' : '100px'};
  }
  #natsumi img {
    height: 100%;
    
  }
  #shadow {
    width: ${props => props.isMobile ? '30px' : '125px'};
    box-shadow: ${props => props.isMobile ? '30px -10px 20px 10px rgba(0,0,0,0.3);' : '90px -10px 50px 30px rgba(0,0,0,0.3);'};
  }
  #oopsy {
    text-align: center;
    width: ${props => props.isMobile ? '50%' : '33%'};
  }
  h1 {
    font-size: ${props => props.isMobile ? '2rem' : '5rem'};
    color: #2E64BC;
  }
  p {
    font-size: ${props => props.isMobile ? '1.2rem' : '1.8rem'};
  }
`

const Button = styled.button`
  border: #2E64BC 1px solid;
  padding: 8px 15px;
  cursor: pointer;
  color: #2E64BC;
  font-weight: 600;
  transition: background-color 0.2s ease-out;
  outline: none;

  &:hover {
    color: white;
    background-color: #2E64BC;
  }
`

const GlobalGreyBackground = createGlobalStyle`
  body {
    background-color: #fbfaff;

    .no-select {
      -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
         -khtml-user-select: none; /* Konqueror HTML */
           -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                      supported by Chrome and Opera */
    }

    #natsumi { animation: natsumi 0.6s ease-in; }
    #oopsy { animation: oops 0.6s ease-in; }

    @keyframes natsumi {
      from {
        opacity: 0;
        transform: translateX(-150px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
    @keyframes oops {
      from {
        opacity: 0;
        transform: translate(150px, -150px) rotate(45deg);
      }
      to {
        opacity: 1;
        transform: translate(0, 0) rotate(0deg); 
      }
    }
  }
`

const NotFound = (props) => {
  const history = useHistory()
  const isMobile = useMobile()

  const handleClick = () => {
    let link = "/"
    if (props.embedded) link = props.embedded
    history.push(link)
  }

  return (
    <>
      {!props.embedded && <HelmetMetaData title='404 | From Zero!' />}
      {!props.embedded && <GlobalGreyBackground />}
      <PageNotFoundStyled isMobile={isMobile}>
        <div id="natsumi" className="no-select">
          <img src={natsumi_panic} alt="Chibi Natsumi" className="no-select" draggable="false" />
          <div id="shadow" className="no-select"></div>
        </div>
        <div id="oopsy" className="no-select">
          <h1 className="no-select">おっと！</h1>
          <p className="no-select">{props.caughtError ? "Something went wrong!" : "The link or page you're looking for doesn't exist"}</p>
          <Button type="button" className="no-select" onClick={handleClick}>{props.embedded ? "Return to lesson overview?" : "Take me home!"}</Button>
        </div>
      </PageNotFoundStyled>
    </>
  )
}

export default NotFound