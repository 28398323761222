import { Route } from 'react-router-dom'
import { connect } from 'react-redux'

import JapaneseFromZeroSubModal from '../modals/JapaneseFromZeroSubModal'
import LoginModal from '../modals/LoginModal'
import { SUBSCRIPTION_PLANS } from 'src/util/constants'

const premiumCore = ({ component: Component, ...rest }) => {
  const premium = rest.courses?.includes('kansai')
  const vaultAccess = rest.access?.includes('vault')
  const admin = rest.roles?.includes('admin')
  const supporter = rest.subscriptions?.includes('early-supporter')
  const subscribed = rest.subs?.reduce((p, c) => {
    return SUBSCRIPTION_PLANS.includes(c.identifier) ? true : p
  }, false)
  const affiliate = rest.roles?.includes('affiliate')

  if (!rest.isSignedIn) return <LoginModal />

  const canPass = premium || admin || vaultAccess || supporter || subscribed || affiliate

  return (
    <Route
      {...rest}
      render={(props) => (
        canPass
          ? (rest.render?.(props) || <Component {...props} />)
          : <JapaneseFromZeroSubModal subscriptionModalOpen />
      )}
    />
  )
}

const mapStateToPropsPremium = (state) => ({
  isSignedIn: state.auth.isSignedIn,
  courses: state.user.courses,
  roles: state.auth.roles,
  access: state.auth.access,
  subscriptions: state.auth.subscriptions,
  subs: state.auth.subs
})

const PremiumRoute = connect(mapStateToPropsPremium)(premiumCore)

export default PremiumRoute
