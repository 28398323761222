import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import axios from 'src/api'
import { useCookies } from 'react-cookie'
import { LoadingOverlay } from '@mantine/core'
import styled from '@emotion/styled'
import { useScrollIntoView } from '@mantine/hooks'

// import LoginModal from '../modals/LoginModal'
import HelmetMetaData from 'src/components/HelmetMetaData'
import FZExclamation from '../FZExclamation'
import JapaneseFromZeroSubModal from '../modals/JapaneseFromZeroSubModal'
import TomokoSubSuccess from './account/subscriptions/TomokoSubSuccess'

import { DashAndBubble } from '../fz_courses/widgets/styles/QnAStyles'
import { Icon } from 'semantic-ui-react'
import {
  CheckEmail,
  Checkmark,
  ConfirmAccount,
  CreateAccountContainer,
  Exclamation,
  Hannah,
  HannahContainer,
  HannahEmailDashLine,
  InputContainer,
  Invalid,
  MobileCheckmark,
  MobileDashLine,
  MobilePlanButton,
  MobilePlans,
  MobileYearlyBanner,
  ModalContainer,
  MonthlyPrice,
  MonthlySavings,
  MostPopular,
  PaymentChibi,
  PaymentPlanContainer,
  Perk,
  Perks,
  PerkText,
  Plan,
  PlanButton,
  PlanDashLine,
  PlanHeader,
  PlanLabel,
  PlanLabelAnnual,
  PlanLabelFree,
  PlanLabelMonthly,
  Plans,
  PriceInfo,
  RedX,
  RegisterForm,
  RegisterInput,
  RegisterPageContainer,
  SelectedPlan,
  SpeechBubble,
  SpeechBubbleDynamic,
  Star,
  Step,
  StepDashLine,
  StepSubheader,
  YearlyDiscount,
  YJClaimModalContainer,
  TopText,
  BottomText } from './styled/registerPage.styles'

import hannah from '../../assets/chibi/hannah_deliver_color.png'
import exclamation_gray from 'src/assets/full_exclamation_gray.svg'

import useMobile from 'src/hooks/useMobile'
import useAPI from 'src/hooks/useAPI'
import { signIn } from 'src/actions'
import { SubscriptionContext, SubscriptionProvider } from 'src/contexts/SubscriptionContext'
import { useEmailTaken, useValidEmail, useValidPassword, useYJAvailable } from 'src/util/register'

import monthlyLabel from 'src/assets/subscription/label_monthly.svg'
import yearlyLabel from 'src/assets/subscription/label_yearly.svg'

const createLabel = 'https://d32tv5vz8ua1sf.cloudfront.net/images/register/label_create_account.svg'
const selectLabel = 'https://d32tv5vz8ua1sf.cloudfront.net/images/register/label_select_plan.svg'
const freeLabel = 'https://d32tv5vz8ua1sf.cloudfront.net/images/register/label_free.svg'
const premiumLabel = 'https://d32tv5vz8ua1sf.cloudfront.net/images/register/label_premium.svg'
const speechBubble = 'https://d32tv5vz8ua1sf.cloudfront.net/images/register/label_best_value.svg'

const freePerks = [
  'First 3 Lessons of Each Primary Course',
  'View Questions Asked by Other Students',
]

const monthlyPerks = [
  '5 Courses with Over 60 Lessons',
  'Customizable Notebook',
  'Access to Exclusive Beta Courses',
  'Get Your Questions Answered by Our Japanese Teachers',
  // 'Access to Over 250 Learning Videos',
  // 'Lock in Your Subscription Price'
]

const planData = {
  Free: {
    perks: [
      'First 3 Lessons of Each Primary Course',
      'Customizable Notebook',
      'View Questions Asked by Other Students',
    ],
    price: 'Free',
    aff_price: 'Free',
    aff_internal_price: 'Free',
    campaign_price: 'Free',
    plan: 'free',
    url: freeLabel
  },
  Monthly: {
    perks: [
      ...freePerks,
      ...monthlyPerks,
    ],
    price: '$12.97/mo',
    aff_price: '$6.49/mo',
    aff_price_text: 'Get your first month half off!',
    aff_price_text_short: '50% Off First Month',
    aff_internal_price: '$8.43/mo',
    campaign_price: '$8.97/mo',
    plan: 'premium-monthly',
    url: monthlyLabel,
    disclaimer: '$12.97 each month ',
    disclaimer_aff: '$8.43 each month ',
    disclaimer_campaign: '$8.97 each month ',
    disclaimer_affiliate: '$6.49 for the first month, then $12.97 each month ',
  },
  Yearly: {
    perks: [
      ...freePerks,
      ...monthlyPerks,
      'Two Months Free!',
    ],
    aff_base_price: '$155.64/yr',
    price: '$129.70/yr',
    aff_price: '$129.70/yr',
    aff_internal_price: '$93.38/yr',
    campaign_price: '$89.70/yr',
    plan: 'premium-annual',
    url: yearlyLabel,
    disclaimer: '$129.70 each year ',
    disclaimer_aff: '$93.38 each year ',
    disclaimer_campaign: '$89.70 each year ',
    disclaimer_affiliate: '$129.70 each year ',
  }
}

const iconFix = { margin: '0 0.5rem 5px 1rem' }

export const StepHeader = ({ url, isMobile }) => {
  return (
    <DashAndBubble>
      <StepDashLine isMobile={isMobile} />
        <Step src={url} isMobile={isMobile} />
      <StepDashLine isMobile={isMobile} />
    </DashAndBubble>
  )
}

// topText must be short (1 line) atm to avoid breaking Hannah
export const HannahEmailModal = ({ topText, bottomText, modalClose }) => {
  return (
    <ModalContainer>
      <CheckEmail>
        {modalClose && <RedX onClick={modalClose}>✕</RedX>}
        <HannahContainer>
          <Hannah src={hannah} alt='Hannah holding an envelope' />
        </HannahContainer>
        <p>{topText}</p>
        <HannahEmailDashLine />
        <h6>{bottomText}</h6>
      </CheckEmail>
    </ModalContainer>
  )
}

// This is basically a better version of HannahEmailModal imo, idk who made the first one it's trash
// Static fer now tho so I'm not gonna change it
// copilot's writing comments again lol, I'm not gonna change it, it's fine
export const YJClaimModal = () => {
  const isMobile = useMobile()

  return (
    <ModalContainer>
      <YJClaimModalContainer>
        <HannahContainer>
          <Hannah src={hannah} alt='Hannah holding an envelope' isMobile={isMobile} yjClaim />
        </HannahContainer>
        <TopText isMobile={isMobile}>
          <span style={{ display: 'block', textAlign: 'center', color: '#4d79ff', margin: '-8px 0' }}>Important Info</span>
          <br />
          As your email is linked to your YesJapan account, we have transferred your settings and progress to From Zero!
        </TopText>
        <HannahEmailDashLine />
        <BottomText>
          To continue your studies, please check your email and click "claim account".
          <span style={{ display: 'block', margin: '12px 0' }}></span>
          Once you've claimed your account, you can update your username and reset your course and quiz progress via your account settings.
        </BottomText>
      </YJClaimModalContainer>
    </ModalContainer>
  )
}

export const PaymentPlan = ({
  plan, chibi, paymentPlan, actualPrice: actualP, baseP: basePrice, isAatPage = null, resAff = null, grandfather = null
}) => {
  const { chosenPlan, onPlanChosen: setChosenPlan, affiliate, campaigns, promos } = useContext(SubscriptionContext)
  const ActualLabel = plan === 'Monthly' ? PlanLabelMonthly : PlanLabelAnnual
  let promoValueMonthly, promoValueAnnual
  if (promos?.p6) {
    promoValueMonthly = 50
    promoValueAnnual = 60
  } else if ((affiliate?.internal) || promos?.p90 || campaigns?.[0] || grandfather) {
    promoValueMonthly = 30
    promoValueAnnual = 40
  } else if (affiliate) {
    promoValueMonthly = 50
  }

  return (
    <PaymentPlanContainer
      plan={plan}
      isSelected={paymentPlan === chosenPlan}
      onClick={() => {
        setChosenPlan(paymentPlan)
      }}
      chosenPlan={chosenPlan}
      isAatPage={isAatPage}
    >
      <ActualLabel selected={paymentPlan === chosenPlan} step='payment' />
      <p style={{ textDecoration: actualP !== basePrice ? 'line-through' : 'none' }}>
        {basePrice}/{plan === 'Monthly' ? 'mo' : 'yr'}
      </p>
      {actualP !== basePrice && <p>{actualP}/{plan === 'Monthly' ? 'mo' : 'yr'}{plan === 'Monthly' && affiliate ? '*' : ''}</p>}
      <PaymentChibi src={chibi} />
      {/* plan === 'Yearly' && <SpeechBubble src={speechBubble} /> */}

      {/* this is for you sir colton ↓↓↓ */}
      {/* need to add promo conditions below with proper format */}
      {/* rounded down to closest multiple of 5 - ex: '60% Off!' */}

      {/* replace 'true' with promo check */}
      {plan === 'Monthly' && promoValueMonthly && <MonthlySavings isAatPage={isAatPage}>{promoValueMonthly}% Off!</MonthlySavings>}
      {/* replace 'false' with savings if promo; 'Best Value!' if normal annual price */}
      {plan === 'Yearly' && <SpeechBubbleDynamic>{promoValueAnnual ? `${promoValueAnnual}% Off!` : 'Best Value!'}</SpeechBubbleDynamic>}
    </PaymentPlanContainer>
  )
}

const EmbedLine = styled.p`
  font-size: ${({ isMobile }) => isMobile ? '1.75rem' : '2.5rem'} !important;
  font-weight: bold;
  text-align: center;

  > span {
    font-size: ${({ isMobile }) => isMobile ? '1.8rem' : '2.6rem'} !important;
    color: #5c7cbe;
  }
`

const WarningIcon = () => (
  <Icon size="tiny" className="warn" name="warning circle" style={{ marginTop: '5px' }} />
)

const PasswordError = () => (
  <Invalid data-cy='password-error'>
    <WarningIcon />{' '}
    Your password must be at least 8 characters long and contain 1
    number, 1 special character, and 1 uppercase character!
  </Invalid>
)

const EmailNotValidatedError = () => (
  <Invalid data-cy='email-not-validated-error'>
    <p>
      <WarningIcon />{' '}
      Email address could not be validated. If you believe this
      was typed correctly,{' '}
      <Link to="/login" style={{ color: 'orange' }}>
        try logging in
      </Link>{' '}
      or resetting your password.
    </p>
  </Invalid>
)

const TOSPrivacyPolicyText = () => (
  <p>
    * By creating an account, you agree to our <Link to='/tos' target='_blank'>Terms of Use</Link> and{' '}
    <Link to='/privacy' target='_blank'>Privacy Policy</Link>.
  </p>
)

const EmailNotValid = () => (
  <Invalid data-cy='email-not-valid-error'>
    <WarningIcon />{' '}
    You must enter a valid email address to continue!
  </Invalid>
)

const EmailUnavailable = () => (
  <Invalid data-cy='username-email-unavailable-error'>
    <WarningIcon />{' '}
    Sorry, that email is unavailable!
  </Invalid>
)

const FieldsBlank = () => (
  <Invalid data-cy='fields-no-blank-error'>
    <WarningIcon />{' '}
    Fields can&apos;t be blank!
  </Invalid>
)

export const RegisterFormNew = ({
  isMobile, selectedPlan, setRegistrationSuccess, affiliate,
  campaign, signIn, history, setYjTransfer, setShowClaimModal
}) => {
  const [
    email, setEmail, isValidEmail, emailErrors, checkEmailValid
  ] = useValidEmail()
  const [
    password, setPassword, isValidPassword, passwordErrors, checkPasswordValid
  ] = useValidPassword()
  const [
    isAvailable, setIsAvailable, checkAvailable
  ] = useYJAvailable(email)

  const [emailTaken, setEmailTaken, checkEmailTaken] = useEmailTaken(email)
  const [registrationError, setRegistrationError] = useState(false)
  const [yjt, setYjt] = useState(false)

  const [passwordVisible, setPasswordVisible] = useState(false)
  const [shake, setShake] = useState(false)
  const [loading, setLoading] = useState(false)

  const onAuthFailure = () => {
    setShake(true)

    setTimeout(() => {
      setShake(false)
    }, 200)
  }

  const submitFormRegistration = useCallback(async () => {
    if (loading) return

    setLoading(true)

    if (!checkEmailValid() || !checkPasswordValid() || await checkEmailTaken()) {
      setLoading(false)
      return onAuthFailure()
    }

    const isYJClaimable = await checkAvailable()
    if (isYJClaimable) {
      setLoading(false)
      setShowClaimModal(true)
      return
    }

    axios
      .post('/user/register', {
        password: password.trim(),
        email: email.toLowerCase().trim(),
        planSelectionAtSignup: planData[selectedPlan].plan,
        affiliate,
        campaign
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false)
          setRegistrationError(false)

          // account registered successfully, do next stuff
          setRegistrationSuccess(true)

          setYjTransfer(res.data.yj_transfer)
          setYjt(res.data.yj_transfer)

          signIn(res.data)

          // if they registered with the free option, redirect to /home
          if (selectedPlan === 'Free' && !res.data.yj_transfer) {
            // console.log('free plan selected; pushing home')
            history.push('/home')
          }
        } else {
          setRegistrationError(true)
          onAuthFailure()
        }
      })
      .catch(() => {
        setRegistrationError(true)
        onAuthFailure()
        setLoading(false)
      })
  }, [loading, password, email])

  const submitForm = (e) => {
    e.preventDefault()
    submitFormRegistration()
  }

  const handleCloseTransfer = () => {
    history.push('/home')
  }

  return (
    <RegisterForm
      onSubmit={(e) => submitForm(e)}
      className={shake ? ' horizontalShake shakeIt' : '' }
      isMobile={isMobile}
      id="register-search-form"
      data-cy='register-form'
    >
      <TomokoSubSuccess
        closePopup={handleCloseTransfer}
        showing={yjt}
        mainText="You've successfully claimed your YesJapan account!"
      />
      {(emailErrors?.length || passwordErrors?.length) ? <FieldsBlank data-cy='registration-fields-blank' /> : ''}
      {emailTaken && <EmailUnavailable data-cy='email-taken' />}
      <InputContainer>
        <Icon name='envelope outline' color='grey' style={iconFix} />
        <RegisterInput
          type='email'
          placeholder='email'
          data-cy='input-email'
          name='username'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!isValidEmail}
          required
          aria-required='true'
          onFocus={() => setEmailTaken(false)}
        />
      </InputContainer>
      {!isValidEmail && <EmailNotValid data-cy='email-error' />}
      {registrationError && <EmailNotValidatedError />}
      <InputContainer password>
        <Icon name='lock' color='grey' style={iconFix} />
        <RegisterInput
          placeholder='password'
          data-cy='input-password'
          type={passwordVisible ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!isValidPassword}
          required
          aria-required='true'
          autocomplete="off"
        />
        <span
          className="password-visibility"
          onClick={() => setPasswordVisible(!passwordVisible)}
        >
          <Icon name={passwordVisible ? 'eye' : 'eye slash'} />
        </span>
      </InputContainer>
      {!isValidPassword && <PasswordError data-cy='password-error' />}
      <ConfirmAccount type='submit' data-cy='create-account-btn'>Create Account*</ConfirmAccount>
      <TOSPrivacyPolicyText />
    </RegisterForm>
  )
}

const RegisterPage = (props) => {
  const [selectedPlan, setSelectedPlan] = useState('Monthly')
  const [registrationSuccess, setRegistrationSuccess] = useState(false)
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false)
  const step2 = useRef()
  const [resAff, loading, reqAff] = useAPI('/affiliate/cookie', 'LAZYGET')
  const { scrollIntoView, targetRef } = useScrollIntoView({ offset: 60 })
  const [yjTransfer, setYjTransfer] = useState(false)
  const [showClaimModal, setShowClaimModal] = useState(false)

  // check cookie for affiliate code
  const [cookies, setCookie] = props.useCookies ? useCookies(['affiliate', 'campaign']) : [{}, () => {}]

  if (props.isSignedIn && !props.delayRedirect) props.history.push('/home')
  const isMobile = useMobile()
  const { embedded } = props

  // send a request to get the affiliate linked to the code stored as a cookie
  useEffect(() => {
    if (cookies.affiliate || cookies.campaign) {
      // console.log(cookies)
      reqAff({ params: { affiliate: cookies.affiliate, campaign: cookies.campaign } })
    }
  }, [cookies.affiliate, cookies.campaign])

  useEffect(() => {
    // console.log(resAff)
  }, [resAff])

  useEffect(() => {
    if (registrationSuccess) {
      setSubscriptionModalOpen(true)
    }
  }, [registrationSuccess])

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan)
    scrollIntoView()
  }

  const resAffInt = resAff?.affiliate?.internal || resAff?.campaign?.active
  const resAffName = resAff?.affiliate?.person_name || resAff?.campaign?.identifier
  const priceType = (resAffInt ? 'aff_internal_price' : 'price')
  let priceKey = resAff?.campaign ? 'campaign_price' : priceType
  const priceText = resAff?.affiliate ? 'aff_price_text' : ''
  const shortPriceText = resAff?.affiliate ? 'aff_price_text_short' : ''
  if (props.affiliate && !resAff?.campaign) priceKey = 'aff_price'
  // console.log(resAffInt, resAffName)

  const selectedData = planData[selectedPlan]
  const planPrice = (props.affiliate ? selectedData?.price : selectedData[priceKey])

  const ActualLabel = {
    Monthly: PlanLabelMonthly,
    Yearly: PlanLabelAnnual,
    Free: PlanLabelFree
  }[selectedPlan]

  return (
    <>
      <HelmetMetaData title="Begin your language journey | From Zero!" />
      {/* <LoginModal flat asLogin={false} /> */}
      <LoadingOverlay visible={loading} />
      <RegisterPageContainer isMobile={isMobile}>
        {!embedded && <FZExclamation
          title='Register'
          color="blue"
          centered
          style={{
            margin: '1rem 0 0'
          }}
        />}
        {!embedded && <p>Subscribe to begin your learning experience!</p>}
        {embedded && <EmbedLine isMobile={isMobile}>
          A new learning experience is waiting <span>FOR YOU!</span>
        </EmbedLine>}
        <StepHeader url={selectLabel} isMobile={isMobile} />
        {isMobile
          ? (
            <>
              <MobilePlans>
                <div></div> {/* temp placeholder */}
                <PlanLabel src={planData.Free.url} step='select' isMobile />
                <PlanLabel src={premiumLabel} step='select' isMobile />
                <MobileDashLine />
                {planData.Monthly.perks.map((perk, idx) => (
                  <React.Fragment key={`mobile-${perk}`}>
                    <PerkText isMobile>{perk}</PerkText>
                    <MobileCheckmark>{idx < 2 && '✓'}</MobileCheckmark>
                    <MobileCheckmark premium>✓</MobileCheckmark>
                  </React.Fragment>
                ))}
                <div></div> {/* temp placeholder */}
                <MobilePlanButton
                  onClick={() => handlePlanClick('Free')}
                >
                  Start Free
                </MobilePlanButton>
                <MobilePlanButton
                  premium
                  onClick={() => handlePlanClick('Monthly')}
                >
                  {planData.Monthly[shortPriceText] || planData.Monthly[priceKey]}
                </MobilePlanButton>
              </MobilePlans>
              <MobileYearlyBanner>
                <p>Or get <span>2 MONTHS FREE</span> when paying Annually</p>
                <MobilePlanButton
                  yearly
                  onClick={() => handlePlanClick('Yearly')}
                >
                  {planData.Yearly[priceKey]}
                </MobilePlanButton>
              </MobileYearlyBanner>
            </>
          )
          : (
            <Plans>
              {Object.keys(planData).map((plan) => (
                <Plan
                  selected={selectedPlan === plan}
                  key={plan} plan={plan} onClick={() => handlePlanClick(plan)}
                >
                  <PlanHeader plan={plan} selected={selectedPlan === plan}>
                    {plan === 'Free' && <PlanLabelFree reverse selected={selectedPlan === plan} step='select' />}
                    {plan === 'Monthly' && <PlanLabelMonthly reverse selected={selectedPlan === plan} step='select' />}
                    {plan === 'Yearly' && <PlanLabelAnnual reverse selected={selectedPlan === plan} step='select' />}
                    {plan === 'Yearly' && <YearlyDiscount selected={selectedPlan === plan} step='select'>2 Months Free</YearlyDiscount>}
                    {plan === 'Monthly' && <YearlyDiscount selected={selectedPlan === plan} step='select'>✨Most Popular✨</YearlyDiscount>}
                  </PlanHeader>
                  <PlanDashLine plan={plan} />
                  <Perks selected={selectedPlan === plan}>
                    {plan === selectedPlan && <Exclamation src={exclamation_gray} alt='!' draggable={false} />}
                    {planData[plan].perks.filter((perk) => !(plan !== 'Free' && freePerks.includes(perk))).map((perk) => (
                      <Perk key={plan + perk}>
                        <Checkmark plan={plan} selected={selectedPlan === plan}>✓</Checkmark>
                        <PerkText plan={plan} selected={selectedPlan === plan}>{perk}</PerkText>
                      </Perk>
                    ))}
                  </Perks>
                  {plan === 'Yearly' && (
                    <MonthlyPrice selected={selectedPlan === plan} style={{ marginTop: 'auto' }}>
                      {resAffInt ? '$129.70' : '$155.64'}
                    </MonthlyPrice>
                  )}
                  {plan === 'Monthly' && resAffInt && (
                    <MonthlyPrice selected={selectedPlan === plan} style={{ color: '#333', marginTop: 'auto' }}>$12.97</MonthlyPrice>
                  )}
                  {plan === 'Free' && (
                    <p style={{
                      fontWeight: 'bold',
                      textAlign: 'center',
                      marginTop: 'auto',
                      marginBottom: '-1rem',
                      color: selectedPlan === 'Free' ? 'white' : 'inherit'
                    }}>
                      No credit card required.
                    </p>
                  )}
                  <PlanButton
                    selected={selectedPlan === plan}
                    plan={plan}
                    onClick={() => handlePlanClick(plan)}
                    style={{ marginTop: ['Monthly', 'Free'].includes(plan) ? 'auto' : 'inherit' }}
                  >
                    {planData[plan][priceText] || planData[plan][priceKey]}
                  </PlanButton>
                </Plan>
              ))}
            </Plans>
          )
        }
        <div ref={targetRef}></div>
        <StepHeader url={createLabel} isMobile={isMobile} />
        {selectedPlan !== 'Free' && (
          <StepSubheader create>
            After creating an account, you will be taken to the payment screen.
          </StepSubheader>
        )}
        <CreateAccountContainer isMobile={isMobile}>
          <SelectedPlan isMobile={isMobile}>
            <p>Selected Plan</p>
            <ActualLabel style={{ margin: '4px auto 10px' }} />
            <PlanDashLine selected />
            <PriceInfo>
              <p>Plan Price:</p>
              <p>{selectedPlan === 'Free' ? 'Free' : planPrice}</p>
            </PriceInfo>
            <PriceInfo>
              <p>Today&apos;s Total</p>
              <p>{selectedPlan === 'Free'
                ? '$0.00'
                : `${planData[selectedPlan][priceKey].slice(0, -3)}${props.affiliate && selectedPlan === 'Monthly' ? ' (50% off first month)' : ''}`}
              </p>
            </PriceInfo>
            {selectedPlan !== 'Free' && resAffInt && (
              <p style={{ color: '#12ce12' }}><br/>Your 30% discount has been applied!</p>
            )}
            {selectedPlan === 'Monthly' && resAff?.affiliate && (
              <p style={{ color: '#12ce12' }}><br/>Your first month discount of 50% has been applied!</p>
            )}
            {selectedPlan === 'Free' && (
              <p>
                <br/>No credit card will be required to register, and you will not
                &nbsp;be auto-enrolled in premium at any time.
              </p>
            )}
          </SelectedPlan>
          <RegisterFormNew
            isMobile={isMobile}
            setRegistrationSuccess={setRegistrationSuccess}
            selectedPlan={selectedPlan}
            affiliate={resAff?.affiliate}
            campaign={resAff?.campaign}
            signIn={props.signIn}
            history={props.history}
            setYjTransfer={setYjTransfer}
            setShowClaimModal={setShowClaimModal}
          />
        </CreateAccountContainer>
      </RegisterPageContainer>
      {/* REMOVE THIS MODAL AFTER DEBUGGING OR COMMENT OUT: */}
      {/* <JapaneseFromZeroSubModal
        title="Subscribe now and complete your registration!"
        subscriptionModalOpen={subscriptionModalOpen}
        setSubscriptionModalOpen={setSubscriptionModalOpen}
        landing
        truncated
        affiliate={props.affiliate}
      /> */}
      {registrationSuccess && !yjTransfer && selectedPlan !== 'Free' && (
        <JapaneseFromZeroSubModal
          title="Subscribe now and complete your registration!"
          subscriptionModalOpen={subscriptionModalOpen}
          setSubscriptionModalOpen={setSubscriptionModalOpen}
          landing
          truncated
          affiliate={props.affiliate}
        />
      )}
      {showClaimModal && <YJClaimModal />}
    </>
  )
}

const routerRegisterPage = withRouter(RegisterPage)

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
  }
}

export default connect(mapStateToProps, { signIn })(routerRegisterPage)
