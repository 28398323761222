import { useEffect, useState } from 'react'

const useBonusData = (record) => {
  const [bonusData, setBonusData] = useState(record.bonus)
  const [noIcon, setNoIcon] = useState(false)

  useEffect(() => {
    setBonusData(record.bonus)
  }, [record.bonus?.toString()])

  useEffect(() => {
    const { sound_icon_display: display, no_sound } = bonusData || {};
    setNoIcon(display === 'no icon' || (no_sound && !['default', 'icon only'].includes(display)));
  }, [bonusData])

  const iconOnly = bonusData?.sound_icon_display === 'icon only'

  return { bonusData, noIcon, iconOnly }
}

export default useBonusData
