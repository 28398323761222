import { useState } from 'react'
// import axios from 'src/api'

import { Button, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react'
import { ExtendedWidget } from 'src/types'

import DataGrabber from './DataGrabber'

interface DataGrabberButton {
  compact: boolean
  size: string
  type: string
  selected?: string
  onDataProcessed: (arg: ExtendedWidget[]) => void
  /**
   * ExtendedWidget[] is probably good enough
   * until someone is crazy enough to
   * give type defs to DataGrabber
   */
}

const DataGrabberButton: React.FC<DataGrabberButton> = ({
  type,
  selected,
  onDataProcessed,
}) => {
  const [open, setOpen] = useState<boolean>()
  const [loading] = useState<boolean>()

  const populateWidgetData = () => null

  const processData = (data) => {
    setOpen(false)
    onDataProcessed(data)
  }

  return (
    <Modal
      basic
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size='small'
      trigger={
        <Button compact size='small' color='yellow'>
          Grabber
        </Button>
      }
    >
      <Segment>
        <Header textAlign='center'>
          Data Grabber
          <Header.Subheader>{type}</Header.Subheader>
        </Header>
        <Modal.Content>
          <DataGrabber
            selected={selected}
            onDataProcessed={processData}
          />
        </Modal.Content>
        <Modal.Actions>
          <Grid centered style={{ marginTop: '10px', marginBottom: '10px' }}>
            <Button color='red' inverted onClick={() => setOpen(false)}>
              <Icon name='remove' />
            </Button>
            <Button color='green' inverted onClick={() => populateWidgetData()} loading={loading}>
              <Icon name='checkmark' />
            </Button>
          </Grid>
        </Modal.Actions>
      </Segment>
    </Modal>
  )
}

export default DataGrabberButton
