import styled, { css } from 'styled-components'
import tw from 'twin.macro'

const zeroAutoMargin = css`
  margin: 0 auto;
`

interface BarButtonProps {
  color: string
  backgroundColor: string
  borderColor?: string
  contrastColor?: string
  spaceLeft?: boolean
}

const BarButton = styled.button<BarButtonProps>`
  margin-left: ${({ spaceLeft }) => spaceLeft ? '8px' : '0'};
  height: 100%;
  width: max-content;
  padding: 0 15px;
  color: #fff;
  border: 2px solid transparent;
  background-color: ${({ backgroundColor }) => backgroundColor};

  :hover {
    color: ${({ color }) => color};
    border-color: ${({ backgroundColor, borderColor }) => borderColor ?? backgroundColor};
    background-color: ${({ contrastColor }) => contrastColor ?? 'var(--off-white)'};
  }

  :disabled {
    filter: brightness(0.85);
    cursor: not-allowed;

    :hover {
      color: #fff;
      border-color: ${({ backgroundColor, borderColor }) => borderColor ?? backgroundColor};
      background-color: ${({ backgroundColor }) => backgroundColor};
    }
  }
`

interface InputProps {
  color?: string
  width?: string
  removeMargin?: boolean
  bgColor?: string
}

const ToolInput = styled.div<InputProps>`
  position: relative;
  display: flex;
  align-items: center;

  width: ${({ width }) => width};
  ${({ removeMargin }) => !removeMargin && zeroAutoMargin}
  input {
    width: ${({ width }) => width};
    ${({ removeMargin }) => !removeMargin && zeroAutoMargin}
  }

  label {
    margin: 0 8px;
    font-weight: bold;
    color: ${(props) => props.color ?? 'var(--blue-gray)'};
    width: max-content;
  }

  input[type=text] {
    border: 1px solid var(--light-border);
    outline-color: var(--light-border);
  }
  input[type=text].tk-dark-border { border-color: var(--dark-border); }
  input[type=text].tk-mid-border { border-color: var(--mid-border); }
  input[type=text].tk-light-border { border-color: var(--light-border); }
  input[type=number] { width: 60px; }
  input[type=checkbox] {
    width: 20px;
    height: 20px;
  }
  input, textarea {
    background-color: ${({ bgColor }) => bgColor ?? ''};
  }
`

const InputRow = styled.div`
  ${tw`tw-w-full tw-px-2 tw-mt-4`}

  >div {
    ${tw`tw-w-full tw-flex tw-items-stretch tw-justify-center`}

    >div {
      width: 100%;
      ${zeroAutoMargin}
    }
  }
  input {
    ${tw`tw-bg-white tw-border`}
    border-color: var(--light-border);
    width: 95%;
    ${zeroAutoMargin}
    box-shadow: 0 0 12px 4px rgba(214, 222, 248, 0.75);
  }
  >button:first-child {
    margin: 0 0 0 12px;
  }
  >button:last-child {
    margin: 0 12px 0 0;
  }
`

const PairedField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 9px;

  >div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    span {
      margin: 10px 14px -10px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }
`

const EmptyPadding = styled.div`
  padding: 10px 0;
`

const BaseDivider = css`
  position: relative;
  margin: 14px 0 12px;
  padding: 1px;
  border-top: 1px solid var(--blue-gray);
  width: 100%;
`

interface DividerProps {
  borderStyle?: string
  grid?: boolean
}

const ListSectionDivider = styled.div<DividerProps>`
  ${BaseDivider}
  border-style: ${({ borderStyle }) => borderStyle ?? 'solid'};
  ${({ grid }) => grid && 'grid-column: 1 / -1;'}
`

const EmbedBlock = styled.div`
  width: 50%;
  margin: 0 auto 20px;

  >div {
    border-radius: 3px;
    z-index: 0;
  }
`

const QnAResponse = styled.div`
  width: 100%;
  padding: 16px;

  display: flex;
  align-items: center;

  >div {
    width: 100%;
    margin-right: 12px;
  }
`

const QnAComment = styled.div`
  background-color: #ebceb7;
  padding: 1rem;
`

const AddRowButton = styled.button<{ noBgFill?: boolean }>`
  position: absolute;
  top: -0.85em;
  left: calc(50% - 0.6em);
  background-color: ${({ noBgFill }) => noBgFill ? 'transparent' : 'var(--off-white)'};
  border-radius: 1.5em;
  z-index: 5;

  i {
    color: var(--blue-gray);
  }
`

interface PillTextPosProps {
  vShift: number;
  hShift: number
}

export const PillText = styled.div<PillTextPosProps>`
  ${tw`tw-relative tw-py-1 tw-px-2 tw-border tw-border-solid tw-rounded-full`}

  border-color: var(--blue-gray);
  top: ${({ vShift }) => vShift ?? '0'}px;
  left: ${({ hShift }) => hShift ?? '0'}px;
  background-color: var(--off-white);

  :hover {
    background-color: var(--light-border);
    color: var(--off-white);
  }
`

const TableFinalRowDivider = styled.div`
  ${BaseDivider}
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 18px 0 0 3.5px;
`

const TableDeleteColumnTab = styled.button`
  width: 30px;
  height: 30px;
  margin: -2.5px auto 0;

  background-color: var(--red);
  border: 3px solid var(--red);
  border-radius: 8px;

  i { color: white; }
  &:hover {
    background-color: var(--white);
    i { color: var(--red); }
  }
`

const TableToolRows = styled.div`
  width: 100%;
  padding: 0 14px;
`

const ToolTextarea = styled.div`
  position: relative;
  width: 100%;
  min-height: 60px;
  padding: 16px;

  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--mid-border);
    border-radius: 8px;
    color: var(--blue-gray);
    font-size: 1.1rem;
    resize: vertical;

    :active, :focus {
      border-color: var(--dark-border);
      outline: 1px solid var(--dark-border);
    }
  }
`

interface ImageToolUploaderProps {
  flexDirection: 'row' | 'column'
  embedded?: boolean
}

const ImageUploaderTool = styled.div<ImageToolUploaderProps>`
  flex-direction: ${(props) => props.flexDirection};
  width: 100%;
  height: 100%;

  span.red { color: var(--red); }

  >div {
    flex-direction: column;
    height: 100%;
    width: 50%;
    padding: 8px;

    div {
      width: 100%;
    }
  }

  >div:first-child {
    background-color: var(--mid-panel);
    padding-bottom: ${({ embedded }) => embedded ? '8px' : '32px'};
    border-radius: 10px;
    height: ${({ embedded }) => embedded ? '200px' : 'auto'};
    width: ${({ embedded }) => embedded ? '200px' : 'auto'};
    
    img {
      object-fit: contain;
      border-radius: 3px;
      min-height: 50%;
      min-width: 50%;
      max-height: 100%;
      max-width: 100%;
    }
  }

  >div:last-child {
    justify-content: stretch;

    input {
      width: 90%;
      margin: 8px auto;
    }
  }

  .tk-image-picker {
    border: 1px solid var(--mid-border);
    border-radius: 5px;
    padding: 4px 12px;
    margin-top: 24px;
    font-weight: normal;

    &:hover {
      background-color: var(--mid-border);
      color: white;
    }
  }
`

interface IconButtonProps {
  color?: string
  contrastColor?: string
  backgroundColor?: string
  noHoverEffect?: boolean
  toggle?: boolean
  active?: boolean
}

/* eslint-disable indent */
const IconButton = styled.button<IconButtonProps>`
  width: 30px;
  height: 30px;
  z-index: 1;

  padding: 5px;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 8px;

  i {
    position: relative;
    bottom: 2px;
    font-size: 1.2rem;
    color: ${(props) => props.color};
    color: ${(props) => props.active ? 'dodgerblue' : ''};
  }

  ${(props) => !props.noHoverEffect && `:hover {
    background-color: ${props.color};
    i { color: ${props.contrastColor}; }
  }`
  }
`
/* eslint-enable */

const ToolLabel = styled.div<{ open: boolean; is_draft: boolean }>`
  ${tw`tw-absolute tw-font-bold`}
  top: 25%;
  left: ${({ open }) => open ? '-45px' : '-43px'};
  background-color: ${({ is_draft }) => is_draft ? 'var(--yellow)' : 'var(--main)'};
  color: ${({ is_draft }) => is_draft ? 'var(--blue-gray)' : 'white'};
  font-size: 1.3rem;
  padding: 32px 5px;
  position: absolute;
  top: 0;

  writing-mode: vertical-lr;
  text-orientation: sideways-right;
  -moz-transform: scale(-1);
  -webkit-transform: scale(-1);
  -o-transform: scale(-1);
  transform: scale(-1);

  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
`

const AddWidget = styled.div`
  --orange: #ffae20;

  width: 100%;
  position: relative;

  .dash, button {
    position: absolute;
  }

  .dash {
    left: 0;
    right: 0;
    border-top: 2px dashed var(--orange);
    z-index: 1;
  }
  button {
    height: 2rem;
    width: 2rem;
    left: 48%;
    top: -0.95rem;
    background-color: var(--off-white);
    border-radius: 50%;
    z-index: 10;

    i {
      position: relative;
      bottom: 6px;
      font-size: 1.8rem;
      color: var(--blue-gray);
    }
  }
`

const absPosition = '1.3rem'
const AbsTop = css`top: -${absPosition};`
const AbsYMid = css`top: calc(50% - ${absPosition});`
const AbsBottom = css`bottom: -${absPosition};`
const yAxisPos = { top: AbsTop, middle: AbsYMid, bottom: AbsBottom }

const AbsLeft = css`left: -${absPosition};`
const AbsXMid = css`left: calc(50% - ${absPosition});`
const AbsRight = css`right: -${absPosition};`
const xAxisPos = { left: AbsLeft, middle: AbsXMid, right: AbsRight }
interface PencilProps {
  verticalPosition?: 'top' | 'middle' | 'bottom'
  horizontalPosition?: 'left' | 'middle' | 'right'
}

const EditPencil = styled.div<PencilProps>`
  position: absolute;
  ${({ verticalPosition = 'top' }) => yAxisPos[verticalPosition]}
  ${({ horizontalPosition = 'left' }) => xAxisPos[horizontalPosition]}

  border: 2px solid var(--off-white);
  padding: 3px;

  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  z-index: 5;
  
  button {
    padding: 3px;
    border-radius: 50%;
    background-color: var(--orange);

    i {
      position: relative;
      left: 1.5px;
      color: #fff;
      font-size: 1.3rem;

      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
      -ms-filter: fliph; /*IE*/
      filter: fliph; /*IE*/
    }

    :disabled {
      filter: brightness(0.8);
    }
  }

  :hover {
    border-color: #fff;
    background-color: var(--orange);
    button { background-color: #fff; }
    i { color: var(--orange); }
  }

  @media (prefers-color-scheme: dark) {
    background-color: rgba(44, 62, 80, 0.7);
    button i {
      color: var(--blue-gray);
    }
  }
`

const ColumnSwapper = styled.div`
  ${tw`tw-flex tw-items-stretch tw-justify-center tw-w-full`}
  display: flex;

  padding: 14px 8px;
  background-color: var(--mid-panel);

  >div {
    width: 100%;
    margin: 0 5px;
  }

  input {
    background-color: white;
    border: 1px solid var(--mid-border);
    width: 95%;
    ${zeroAutoMargin}
  }
`

interface StylesPopupProps {
  table?: boolean
  structure?: boolean
  row?: boolean
}

const structureStylesPopup = css`
  min-width: 200px;
  left: 40px;
`

const tableStylesPopup = css`
  min-width: 285px;
  left: 50%;
  margin-left: 15px;
`

const tableRowStylesPopup = css`
  max-width: 500px;
  left: 45px;
  top: -10px;
`

const StylesPopup = styled.div<StylesPopupProps>`
  ${tw`tw-absolute tw-bg-white tw-p-4 tw-border tw-border-fzblue tw-rounded-3xl`}
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  z-index: 15;

  ${({ structure }) => structure && structureStylesPopup}
  ${({ table }) => table && tableStylesPopup}
  ${({ row }) => row && tableRowStylesPopup}
`

export {
  AddRowButton,
  AddWidget,
  BarButton,
  ColumnSwapper,
  EditPencil,
  EmbedBlock,
  EmptyPadding,
  IconButton,
  InputRow,
  ImageUploaderTool,
  ListSectionDivider,
  PairedField,
  QnAResponse,
  QnAComment,
  TableDeleteColumnTab,
  TableFinalRowDivider,
  TableToolRows,
  ToolInput,
  ToolLabel,
  ToolTextarea,
  StylesPopup
}
