import React from 'react'
import Draggable from 'react-draggable'

import { Icon } from 'semantic-ui-react'
import { ClosePreviewButton, JumpBackToTool, RenderPreview, WidgetContent } from '../styles/BaseTool.styles'

import useToolkitStore from 'src/stores/toolkit.store'

import ClientView, { ClientViewProps } from './ClientView.part'

interface DraggableProps extends ClientViewProps {
  tool_ref: React.RefObject<HTMLDivElement>
}

const DraggablePreview: React.FC<DraggableProps> = ({
  course,
  id,
  index,
  tool_ref,
  widgetsLength,
  widgetType,
  widget,
}) => {
  const setPreviewingWidgetIds = useToolkitStore((state) => state.setPreviewingWidgetIds)

  const closePreview = () => setPreviewingWidgetIds(id)

  const jumpToPreview = () => {
    if (tool_ref.current) {
      tool_ref.current.scrollIntoView({ behavior: 'smooth' })
      closePreview()
    }
  }

  return (
    <Draggable
      disabled={false}
      enableUserSelectHack={false}
    >
      <RenderPreview>
        <JumpBackToTool
          tabIndex={-1}
          onClick={jumpToPreview}
          title='Close and scroll back to tool'
        >
          <div><Icon name='reply' /></div>
        </JumpBackToTool>
        <ClosePreviewButton
          tabIndex={-1}
          onClick={closePreview}
          title='Close preview'
        >
          <div><Icon name='close' /></div>
        </ClosePreviewButton>
        <WidgetContent className='no-select'>
          <ClientView
            floating
            course={course}
            id={id}
            index={index}
            widget={widget}
            widgetType={widgetType}
            widgetsLength={widgetsLength}
          />
        </WidgetContent>
      </RenderPreview>
    </Draggable>
  )
}

export default DraggablePreview
