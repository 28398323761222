import React from 'react'

import tomoko from 'src/assets/conversation_widget/conversation_profile_1.png'
import kenji from 'src/assets/conversation_widget/conversation_profile_2.png'
import mari from 'src/assets/conversation_widget/conversation_profile_3.png'
import ren from 'src/assets/conversation_widget/conversation_profile_4.png'
import rika from 'src/assets/conversation_widget/conversation_profile_5.png'
import young_girl from 'src/assets/conversation_widget/conversation_profile_6.png'
import guy from 'src/assets/conversation_widget/conversation_profile_7.png'
import lady from 'src/assets/conversation_widget/conversation_profile_8.png'
import lady2 from 'src/assets/conversation_widget/conversation_profile_9.png'

import styled, { css } from 'styled-components'

interface ChatBubbleImg {
  right: string
  studentTeacherView: boolean
}

const StudentViewPositioning = css`
  position: absolute;
  margin-top: 3px;
`

const ChatBubble = styled.img<ChatBubbleImg>`
  ${({ studentTeacherView }) => studentTeacherView ? '' : StudentViewPositioning};
  right: ${({ right }) => right && `${right}px`};
`

// not sure if the colors will be relevant for this widget later,
// so keeping the old object here for now
// const chatIcons = {
//   tomoko: [tomoko, '#316498'],
//   kenji: [kenji, '#b15555'],
//   mari: [mari, '#65a462'],
//   ren: [ren, '#8462a4'],
//   rika: [rika, '#98315a'],
//   young_girl: [young_girl, '#8da5b2'],
//   guy: [guy, '#cf8747'],
//   lady: [lady, '#34c49b'],
//   lady2: [lady2, '#332e97'],
// }

const chatIcons = {
  tomoko,
  kenji,
  mari,
  ren,
  rika,
  young_girl,
  guy,
  lady,
  lady2,
}

interface ChatBubbleProps {
  icon?: string
  idy?: number
  inToolView?: boolean
}

const ConversationChatBubble: React.FC<ChatBubbleProps> = ({
  icon = tomoko,
  idy = 0,
  inToolView = false,
}) => {
  return (
    <ChatBubble
      src={chatIcons[icon]}
      draggable='false'
      className='no-select'
      right={idy % 2 !== 0 ? '20' : ''}
      studentTeacherView={inToolView}
    />
  )
}

export default ConversationChatBubble
