import { useContext } from 'react'
import { ActivityContext } from './useActivity'
import { ResultsContainer, ResultsHeader, Message, TryAgain, ResultsTomoko } from './Activity.styles'
import useMobile from 'src/hooks/useMobile'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const tomoko = `${CLOUDFRONT_BASE}/images/activities/act_tmk_complete.webp`

const Results = ({ course }) => {
  const { numberOfQuestions, attempts, resetActivity } = useContext(ActivityContext)
  const isMobile = useMobile()
  const score = Math.round((numberOfQuestions / attempts) * 100)
  let message = ''

  if (score === 100) message = 'Wow - Perfect score!!!'
  else if (score > 69) message = 'Amazing - Your skills are unmatched!!'
  else if (score > 33) message = 'Good job - Time to take it to the next level!'
  else if (score > 9) message = "You've got this - I'm rooting for you!"
  else message = "Don't worry - Your hard work will pay off!"

  return (
    <ResultsContainer isMobile={isMobile} course={course}>
      <ResultsHeader course={course} isMobile={isMobile}>
        YOU GOT THROUGH <span>{numberOfQuestions}</span> QUESTIONS IN <span>{attempts}</span> ATTEMPTS!
      </ResultsHeader>
      <Message isMobile={isMobile}>{message}</Message>
      {/* divs used as spacers, since they create more rows which in turn renders more of grids "gap" */}
      <div />
      <TryAgain onClick={resetActivity}>Try Again</TryAgain>
      <div />
      <div />
      <div />
      <div />
      <ResultsTomoko src={tomoko} alt='Tomoko encouraging you!' isMobile={isMobile} />
    </ResultsContainer>
  )
}

export default Results
