import React, { useEffect, useState } from 'react'

import { Divider, Header } from 'semantic-ui-react'

const SentencesPreview = props => {
    return (
        <div>
            {props.data.map((s, idx) =>
                <div>
                    <Header as='h4'>{idx+1}.</Header>
                    <b>English</b><br/>
                    <div>{s.english}</div>
                    <b>Japanese</b><br/>
                    <div>{s.other_lang}</div>
                    <Divider />
                </div>)}
        </div>
    )
}

export default SentencesPreview