import React, { useEffect, useState } from 'react'
import axios from '../../../../api'

import { Header, Segment, Placeholder } from 'semantic-ui-react'
import styled from 'styled-components'

import SoundInjector from '../../admin/editor/SoundInjector'
import useMobile from '../../../../hooks/useMobile'

const FillInTheBlanksWidget = ({ id }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [data, setData] = useState({
        _id: '',
        title: '',
        blanks: [
            {
                prompt: '',
                target_sentence: '',
                other_lang_translation: '',
            }
        ]
    })
    const isMobile = useMobile()

    useEffect(() => {
        if (data._id !== '') return
        fetchBuilderData()
    }, [])

    function fetchBuilderData() {
        if (loading) return;
        setLoading(true)
        axios.get(`/widgets/${id}`)
        .then(res => {
            if (res.data?.success) {
                setData(res.data.widget)
            }
            else setError(true)
            setLoading(false)
        })
        .catch(err => {
            console.error(err)
            setError(true)
            setLoading(false)
        })
    }

    const FillBlanks = styled.div`
        box-sizing: border-box;
        padding: 15px;
        font-size: var(--reg-font-size);
    `
    const InlineInput = styled.input`
        display: ${isMobile ? 'block' : 'inline-block'};
        margin: 0 10px;
        box-shadow: none;
        border: none;
        border-radius: 0;
        border-bottom: 2px solid var(--jfz-blue);
        
        &:focus {
            box-shadow: none;
        }
    `

    const InputFields = ({ sentence }) => {
        let part = []
        const returnLine = []

        if (sentence == undefined) return <h3>Missing sentence data.</h3>

        for (let i = 0 ; i <= sentence.length ; i++) {
            // exit early if the rest of the sentence doesn't have any more underscores
            if ( !sentence.slice(i, sentence.length).includes("_") ) {
                returnLine.push(sentence.slice(i, sentence.length))
                break
            }
            // add individual pieces of the sentence until we hit an underscore
            if (sentence[i] !== "_") part.push(sentence[i])

            // commit the individual pieces to our returnLine when we hit an underscore and add an input field
            if (sentence[i] === "_") {
                returnLine.push(<span className="sentence-fragment">{part.join("")}</span>)
                returnLine.push(<InlineInput type="text" name={`input-${i}`} />)
                part = []
            }
        }

        return (
            <SoundInjector>
                <FillBlanks>
                    {returnLine.map(line => line)}
                </FillBlanks>
            </SoundInjector>
        )
    }

    return (
        <>
        {loading ?
            <Segment>
                <Placeholder fluid>
                    <Placeholder.Header>
                        <Placeholder.Line length="full" />
                        <Placeholder.Line length="very long" />
                        <Placeholder.Line length="full" />
                    </Placeholder.Header>
                </Placeholder>
            </Segment>
            :
            (error ?
                <Segment>
                    <Header as='h2' style={{ fontFamily: 'Milliard Bold', marginBottom: '30px' }}>There was a problem fetching the activity</Header>
                </Segment>
            :
            <Segment>
                {data.title.length &&
                    <Header as='h2' style={{ fontFamily: 'Milliard Bold', marginBottom: '30px' }}>{data.title}</Header>
                }
                <p>Fill in the blanks to complete this translation:</p>

                {data.blanks?.map((blank, idx) => (
                    <div key={idx}>
                        {blank.prompt && <h2>{blank.prompt}</h2>}
                        <h3>{blank.other_lang_translation}</h3>
                        <InputFields sentence={blank.target_sentence} />
                    </div>
                ))}

            </Segment>
        )}
        </>
    )
}

export default FillInTheBlanksWidget