import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { STRUCTURE } from '../toolkit.constants'
import { StructureWidget } from 'src/types'

import { InputRow, ToolBody, StylesPopup } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { ToolFooter, ToolHeader, ToolInput, ToolOptions, ToolSubheader, IconButton } from '../parts'
import ToolForm from '../parts/ToolForm.part'
import useWidgetSaved from '../hooks/useWidgetSaved'

const StructureTool: React.FC<ToolProps<StructureWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [showStyleOptions, setShowStyleOptions] = useState(false)
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<StructureWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  const selectedWidth = hookFormMethods.getValues('styles.width')

  const handleWidthChange = (e) => {
    hookFormMethods.setValue('styles.width', e.target.value)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const fieldLabels = {
    'parts.top': 'Top Text',
    'parts.middle': 'Middle Text',
    'parts.bottom': 'Bottom Text',
  }

  const widthOptions = [
    { value: 'auto', text: 'Minimum', },
    { value: '0.5fr', text: 'Small', },
    { value: '0.75fr', text: 'Medium', },
    { value: '0.9fr', text: 'Large', },
    { value: '1fr', text: 'Maximum', },
  ]

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={STRUCTURE}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={STRUCTURE}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div style={{ position: 'relative' }}>
              <IconButton
                icon='css3'
                backgroundColor='transparent'
                contrastColor='white'
                title='Widget Styles'
                handleClick={() => setShowStyleOptions((p) => !p)}
              />
              {showStyleOptions && (
                <StylesPopup>
                  <label htmlFor='width-select'><strong>Width:{' '}</strong></label>
                  <select
                    name='width'
                    id='width-select'
                    value={selectedWidth}
                    onChange={handleWidthChange}
                  >
                    {widthOptions.map(({ value, text }) => (
                      <option key={value} value={value}>{text}</option>
                    ))}
                  </select>
                </StylesPopup>
              )}
            </div>
          </ToolOptions>
          <ToolSubheader>
            <ToolInput
              value={'parts.title'}
              placeholder='Title'
            />
          </ToolSubheader>
          <ToolBody>
            {Object.keys(fieldLabels).map((input: string, idx: number) => (
              <InputRow key={idx} style={{
                marginTop: idx > 0 ? '8px' : '24px',
                marginBottom: idx > (Object.keys(fieldLabels).length - 2) ? '24px' : '8px'
              }}>
                <ToolInput
                  value={input}
                  placeholder={fieldLabels[input]}
                />
              </InputRow>
            ))}
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          >
            <div>
              <ToolInput
                value={'plays_sound'}
                inputType='checkbox'
                placeholder='Play Sound'
                inputLabel='Play Sound'
                labelColor='white'
                labelPosition='after'
              />
            </div>
          </ToolFooter>
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default StructureTool
