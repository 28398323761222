import styled from 'styled-components'

const UsageExamples = styled.div`
  width: 100%;
  padding: 0 8px 0 20px;
`

const PairedFieldsWithIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export {
  UsageExamples,
  PairedFieldsWithIcon,
}
