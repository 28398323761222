import React, { useContext } from 'react'
import { toHiragana } from 'wanakana'
import useMobile from 'src/hooks/useMobile'
import { ActivityContext } from '../useActivity'
import SoundRenderer from '../../../SoundRenderer'
import ImageRenderer from './ImageRenderer'
import {
  AnswersWrapper,
  Kana,
  ConjugationVerbSection,
  Conjugated,
  OptionsContainer,
  AnswerOption,
  AnswerInput,
  ConjugationEnglish,
  questionImageStyles,
  imgStyles
} from './Answers.styles'

const Answers = () => {
  const {
    subtype,
    answers,
    questionType,
    questionImage,
    questionSubdata,
    conjugationData,
    activityType,
    currentQuestion,
    transitioning,
    answerSubmitted,
    answeredCorrectly,
    selectedAnswer,
    setSelectedAnswer,
    typedAnswer,
    setTypedAnswer,
    checkOrNext,
    inputWasFocused,
    setInputWasFocused
  } = useContext(ActivityContext)
  const isKanaTyper = subtype === 'kana-typer'
  const isConjugation = subtype === 'conjugation'
  const isText = activityType === 'text'
  const isImage = activityType === 'images'
  const isSound = activityType === 'sounds'
  const isInput = activityType === 'input'

  const clickType = ['text', 'images', 'sounds'].includes(activityType)

  const { verb, japanese, english } = conjugationData ?? {}
  const hideInput = isConjugation && answeredCorrectly

  const isMobile = useMobile()

  return (
    <AnswersWrapper transitioning={transitioning}>
      {questionImage && isKanaTyper && <Kana>{questionImage}</Kana>}
      {questionImage && !isKanaTyper && <ImageRenderer src={questionImage} key={questionImage} customStyles={questionImageStyles} />}
      {questionType === 'sound' && <SoundRenderer text={questionSubdata} key={`${currentQuestion}-${questionSubdata}-sound`} soundQuestion />}
      {isConjugation && (
        <ConjugationVerbSection>
          {conjugationData ? (
            <>
              <SoundRenderer key={verb?._id} text={[{ record: verb }]} fontSize={isMobile ? '2.5rem' : '3.5rem'} huge={!isMobile} bold />
              <ConjugationEnglish isMobile={isMobile}>{english}</ConjugationEnglish>
            </>
          ) : (
            <p>Loading verbs...</p>
          )}
        </ConjugationVerbSection>
      )}
      <OptionsContainer activityType={activityType}>
        {/* click type */}
        {clickType && answers.map((option, idx) => {
          const answerText = Array.isArray(option.answer) ? option.answer.map((q) => q.text).join('') : option.answer

          return (
            <AnswerOption
              key={`${currentQuestion}-${answerText}-${idx}-option`}
              tabIndex={0}
              activityType={activityType}
              onClick={() => { if (!answerSubmitted) setSelectedAnswer(idx) }}
              onKeyUpCapture={(e) => e.key === 'Enter' && setSelectedAnswer(idx)}
              selected={selectedAnswer === idx}
              submitted={answerSubmitted}
              correct={option.isCorrect}
              answeredCorrectly={answeredCorrectly}
            >
              {isText && <SoundRenderer key={`${currentQuestion}-${answerText}-${idx}-text`} text={option.answer} noPopup />}
              {isImage && <ImageRenderer key={`${currentQuestion}-${answerText}-${idx}-image`} src={option.answer} customStyles={imgStyles} />}
              {isSound && <SoundRenderer key={`${currentQuestion}-${answerText}-${idx}-sound`} text={option.answer} iconOnly huge noPopup />}
            </AnswerOption>
          )
        })}
        {/* input type */}
        {isInput && (
          hideInput
            ? (
              <Conjugated>
                <SoundRenderer key={japanese?._id} text={[{ record: japanese }]} style={{ margin: '0 auto' }} />
              </Conjugated>
            ) : (
              <AnswerInput
                type='text'
                autoFocus={inputWasFocused}
                value={typedAnswer}
                onChange={(e) => {
                  const answer = isConjugation ? toHiragana(e.target.value, { IMEMode: true }) : e.target.value.toLowerCase()
                  setTypedAnswer(answer.trim())
                }}
                onKeyUpCapture={(e) => e.key === 'Enter' && (typedAnswer || answeredCorrectly) && checkOrNext()}
                onFocus={() => setInputWasFocused(true)}
              />
            )
        )}
      </OptionsContainer>
    </AnswersWrapper>
  )
}

export default Answers
