import create from 'zustand'
import { devtools } from 'zustand/middleware'
import { SubLessonPart } from 'src/types'
import { PageType } from 'src/components/pages/blog/BlogEditor'

export type SublessonMeta = {
  course: string
  lesson: string
  sub: string
  part: string

  type: string
  mascot: string
  progressive_only: boolean

  sublesson_id: string
  lessonID: string

  title: string
  subtitle?: string
  lessonTitle: string
  prevSublessonTitle: string
  nextSublessonTitle: string

  numPrelessons: number
  numSublessons: number
  numLessons: number
  partNum: number
}

export type Slug = {
  nDisabled: boolean
  pDisabled: boolean
  nSlug: string
  pSlug: string
  linkToPaywall: boolean
}

interface SubLessonStore {
  parts: SubLessonPart[]
  newWidgetPosInfo: { insertAt: number, partId: string, page?: PageType, sectionIdx?: number }
  sublessonMeta: SublessonMeta
  slug: Slug
  quizCompleted: boolean
  setSlug: (slug: Slug) => void
  addPart: (indexInParts?: number) => Promise<void>
  addWidget: (
    // can be a part ID OR a page ID now
    lessonPartId: string,
    selectedWidget: string,
    indexInPart?: number,
    subtype?: string,
    sectionIdx?: number
  ) => Promise<void>
  fetchSublesson: () => Promise<void>
  fetchPart: (partID: string) => Promise<void>
  fetchWidget: (widgetID: string) => Promise<void>
  setParts: (parts: SubLessonPart[]) => void
  setSublessonMeta: (sublessonMeta: SublessonMeta) => void
  widgetPaletteOpen: boolean
  setWidgetPaletteOpen: (arg: boolean) => void
  setQuizCompleted: (arg: boolean) => void
  reset: () => void
}

/**
 * useSublessonStore.
 * Handles ephemeral state for a sublesson.
 * @func  addWidget
 * @param {String} lessonPartId part to add the widget to
 * @param {String} selectedWidget type of widget to add
 * @param {Number} indexInPart optional index to insert the widget to
 * @func  setParts
 * @param {SubLessonPart[]} parts the sublesson parts to set to state
 */
const useSublessonStore = create<SubLessonStore>((set) => ({
  parts: undefined,
  newWidgetPosInfo: undefined,
  sublessonMeta: undefined,
  slug: undefined,
  quizCompleted: true,
  addWidget: undefined,
  addPart: undefined,
  fetchSublesson: undefined,
  fetchPart: undefined,
  fetchWidget: undefined,
  widgetPaletteOpen: false,
  setSublessonMeta: (sublessonMeta: SublessonMeta) => {
    // console.log('setting sublessonMeta', sublessonMeta)
    set({ sublessonMeta })
  },
  setSlug: (slug: Slug) => set({ slug }),
  setParts: (parts: SubLessonPart[]) => set({ parts }),
  setWidgetPaletteOpen: (value: boolean) => set({ widgetPaletteOpen: value }),
  setQuizCompleted: (status: boolean) => set({ quizCompleted: status }),
  reset: () => set({
    parts: undefined,
    newWidgetPosInfo: undefined,
    sublessonMeta: undefined,
    slug: undefined,
    addWidget: undefined,
    addPart: undefined,
    fetchSublesson: undefined,
    fetchPart: undefined,
    fetchWidget: undefined,
    widgetPaletteOpen: false,
  }),
}))

export default useSublessonStore
