import { useContext } from 'react'
import { SubscriptionContext } from 'src/contexts/SubscriptionContext'
import { PlanLabelMonthly, PlanLabelAnnual, PaymentPlanContainer, PaymentChibi, MonthlySavings, SpeechBubbleDynamic } from './styles/PlanSelection.styles'
import useMobile from 'src/hooks/useMobile'

const PaymentPlan = ({ plan, chibi, paymentPlan, affiliate, actualPrice: actualP, baseP: basePrice }) => {
  const { chosenPlan, onPlanChosen: setChosenPlan, promos } = useContext(SubscriptionContext)
  const isMobile = useMobile()
  const ActualLabel = plan === 'Monthly' ? PlanLabelMonthly : PlanLabelAnnual
  let promoValueMonthly, promoValueAnnual
  if (promos?.p6) {
    promoValueMonthly = 50
    promoValueAnnual = 60
  } else if (promos?.affiliate || promos?.p90 || promos?.campaigns?.[0] || promos?.grandfather) {
    promoValueMonthly = 30
    promoValueAnnual = 40
  }

  return (
    <PaymentPlanContainer
      plan={plan}
      chosenPlan={chosenPlan}
      isSelected={paymentPlan === chosenPlan}
      onClick={() => {
        console.log('payment plan click', paymentPlan)
        setChosenPlan(paymentPlan)
      }}
    >
      <ActualLabel selected={paymentPlan === chosenPlan} step='payment' isMobile={isMobile} />
      <p style={{ textDecoration: actualP !== basePrice ? 'line-through' : 'none' }}>
        {basePrice}/{plan === 'Monthly' ? 'mo' : 'yr'}
      </p>
      {actualP !== basePrice && <p>{actualP}/{plan === 'Monthly' ? 'mo' : 'yr'}{plan === 'Monthly' && affiliate ? '*' : ''}</p>}
      <PaymentChibi src={chibi} />
      {plan === 'Monthly' && promoValueMonthly && <MonthlySavings>{promoValueMonthly}% Off!</MonthlySavings>}
      {plan === 'Yearly' && <SpeechBubbleDynamic>{promoValueAnnual ? `${promoValueAnnual}% Off!` : 'Best Value!'}</SpeechBubbleDynamic>}
    </PaymentPlanContainer>
  )
}

export default PaymentPlan
