import axios from 'axios'

export default axios.create({
  // baseURL: process.env.REACT_APP_ENDPOINT,

  // we can do something like this to check for cypress and use a different endpoint
  baseURL: window.Cypress ? 'http://localhost:4001' : process.env.REACT_APP_ENDPOINT,

  // this was used with browserstack and ngrok to test iOS
  // ALSO MAKE SURE "PREVENT CROSS-SITE TRACKING" IS SET ON BROWSERSTACK SAFARI SETTINGS UGH
  // baseURL: 'http://api-fromzero.ngrok.io',

  withCredentials: true, // for whatever reason the mobile view needs this to work successfully
})
