import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../configureStore'

import { AxiosError, AxiosResponse } from 'axios'
import axios from '../../../api'

type LastCourse = {
  id?: string,
  lesson?: string,
  sublesson?: string,
  part?: string
}

type BookmarkResponse = {
  success: boolean
  last_course: LastCourse
  needs_v8_disclaimer?: boolean
  v7_last_quiz?: string
}

type BookmarkHook = [
  LastCourse,
  () => Promise<LastCourse>,
  (args: any) => Promise<void>
]

const useCourseBookmark = (): BookmarkHook => {
  const [courseBookmark, setCourseBookmark] = useState<LastCourse>()
  const isSignedIn: boolean = useSelector((state: RootState) => state.auth?.isSignedIn)
  const [loading, setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
    
  const fetchLastCourse = async () => {
    if (!isSignedIn || loading) return
        
    setLoading(true)
    let course
    let lesson
    let sublesson
    let part

    try {
      const res: AxiosResponse<BookmarkResponse> = await axios.get('/user/bookmark')
      if (res.data.success) {
        setCourseBookmark(res.data.last_course)
                
        course = res.data.last_course.id
        lesson = res.data.last_course.lesson
        sublesson = res.data.last_course.sublesson
        part = res.data.last_course?.part

        dispatch({
          type: 'SET_V8_DISCLAIMER',
          payload: res.data.needs_v8_disclaimer
        })
      }
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.response?.data)
    }

    setLoading(false)

    return { course, lesson, sublesson, part }
  }

  useEffect(() => {
    fetchLastCourse()
  }, [])

  const saveLastCourse = async ({ course_id, lesson, sublesson, part = '' }) => {
    if (!isSignedIn || loading || (!course_id && !lesson.length)) return
    if (sublesson === 'null') return // idk how this is happening but sometimes it saves null as a string
        
    setLoading(true)
    const payload = { course_id, lesson, sublesson, part }

    let res: AxiosResponse<BookmarkResponse>

    try {
      res = await axios.post('/user/bookmark', payload)

      // used to trigger a redux-based modal on nav to v8 for the first time
      if (res.data.v7_last_quiz) {
        dispatch({
          type: 'SET_V7_LAST_QUIZ',
          payload: res.data.v7_last_quiz
        })
      }
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.response?.data)
    }

    setLoading(false)
  }

  return [courseBookmark, fetchLastCourse, saveLastCourse]
}

export default useCourseBookmark
