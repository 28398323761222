import React, { createElement, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'src/api'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Button, Form, Header, Icon, Message } from 'semantic-ui-react'

import {
  PasswordField,
} from 'src/assets/styles/authPageStyles'

import FromZeroInput from 'src/assets/styles/FromZeroInput'
import useMobile from 'src/hooks/useMobile'
import ErrorReportModal from 'src/components/modals/ErrorReportModal'

import { containsProfanity } from 'src/util/naughtyWordFinder'
import { emailPattern, passwordSpecialCharPattern, usernamePattern } from 'src/util/commonRegexPatterns'
import useLocalStorage from 'src/hooks/useLocalStorage'
import useBrowser from 'src/hooks/useBrowser'

const YJLabelInput = styled(Form.Input)`
  /* make form label not bold */
  label {
    font-weight: normal !important;
    font-family: Milliard Book !important;
    color: var(--jfz-blue) !important;
  }
`

export const WarningHeader = styled(Header)`
  display: inline-flex;
  text-align: left;
  margin-top: 0;
  color: var(--jfz-blue);
  ${({ isMobile }) => isMobile && 'background-color: rgba(0,0,0,0.7);'}
  border-radius: 5px;
  ${({ isMobile }) => isMobile && 'padding: 8px;'}
`

// takes an element and a condition, and returns the children wrapped in that element if true,
// otherwise returning the children in a fragment
const Wrap = ({ as, cond, children: c }) => cond ? createElement(as, {}, c) : <>{c}</>

const WarningMessage = ({ isMobile, children, wrapP }) => (
  <WarningHeader isMobile={isMobile} as="h4">
    <Wrap as='p' cond={wrapP}>
      <Icon
        size="tiny"
        className="warn"
        name="warning circle"
      />{' '}
      {children}
    </Wrap>
  </WarningHeader>
)

const AuthInputFields = (props) => {
  const {
    asLogin,
    isLogin,
    location,
    noLabels,
    queryValues,
    registrationSuccess,
    setRegistrationSuccess,
    flat,
    setInputFocused,
    yj = false,
    onYjRedeem = () => null,
    setShowClaimModal
  } = props
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  // errors
  const [emailError, setEmailError] = useState(false)
  const [emailTaken, setEmailTaken] = useState(false)
  const [emailBlank, setEmailBlank] = useState(false)
  const [naughtyWord, setNaughtyWord] = useState(false)
  const [loginFailure, setLoginFailure] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [usernameTaken, setUsernameTaken] = useState(false)
  const [passwordBlank, setPasswordBlank] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [usernameError, setUsernameError] = useState(false)
  const [usernameTooShort, setUsernameTooShort] = useState(false)
  const [registrationError, setRegistrationError] = useState(false)
  const [passwordConfirmNoMatch, setPasswordConfirmNoMatch] = useState(false)
  const [yjNotExist, setYjNotExist] = useState(false)
  const [yjRedeem, setYjRedeem] = useState(false)
  const [yjEmailSent, setYjEmailSent] = useState(false)

  const [passwordVisible, setPasswordVisible] = useState(false)

  const history = useHistory()
  const isMobile = useMobile()
  const [browser, os, platform] = useBrowser()
  const [noAccessTracking] = useLocalStorage('disallowFromZeroDeviceLoginAccessTracking', false)

  const isSignedIn = useSelector((state) => state.auth.isSignedIn)
  const referrer = useSelector((state) => state.auth.referral_origin)

  useEffect(() => {
    if (yj || yjRedeem) return
    if (!isSignedIn) return
    if (location.pathname === '/login') {
      history.push('/home')
    } else {
      window.location.reload(true)
    }
  }, [isSignedIn])

  const checkTaken = async (emailOrUsername, type) => {
    const payload = { [type]: emailOrUsername }
    const response = await axios.post('/user/check-availability', payload)

    let uNameResult = false
    let emailResult = false

    if (!response.data.success) {
      if (response.data.message === 'USERNAME_EXISTS') uNameResult = false
      if (response.data.message === 'EMAIL_EXISTS') emailResult = false
    }

    if (type === 'username') {
      setUsernameTaken(uNameResult)
    }
    if (type === 'email') {
      setEmailTaken(emailResult)
    }

    return uNameResult || emailResult
  }

  const validateEmail = () => {
    let result = false
    let takenResult = false
    if (!emailPattern.test(email)) {
      result = true
    }
    if (!asLogin) takenResult = checkTaken(email, 'email')
    setEmailError(result)
    if (props.inModal) setInputFocused(false) // to re-show LoginModal banner on mobile
    return result || takenResult
  }

  const validateUsername = () => {
    let _tooShort = false
    let _error = false
    let _naughtyWord = false
    let _wasTaken = false

    setUsername(username.trim())

    if (!username.trim() || username.trim().length < 3) {
      _tooShort = true
      setUsernameTooShort(true)
    } else if (!usernamePattern.test(username.trim())) {
      _error = true
      setUsernameError(true)
    } else if (containsProfanity(username)) {
      _naughtyWord = true
      setNaughtyWord(true)
    } else {
      setUsernameTooShort(false)
      setUsernameError(false)
      setNaughtyWord(false)
      if (!asLogin) _wasTaken = checkTaken(username, 'username')
    }

    return _tooShort || _wasTaken || _error || _naughtyWord
  }

  const validatePassword = () => {
    let result = false
    if (
      password.length < 8
      || !/[A-Z]+/g.test(password)
      || !/[0-9]+/g.test(password)
      || !passwordSpecialCharPattern.test(password)
    ) {
      result = true
    }

    setPasswordBlank(false)
    setPasswordError(result)
    if (props.inModal) setInputFocused(false) // to re-show LoginModal banner on mobile

    if (!password) setPasswordBlank(result)
    return result
  }

  const submitFormRegistration = () => {
    if (
      emailError
      || emailBlank
      || naughtyWord
      || passwordBlank
      || passwordError
      || usernameError
    ) return

    axios
      .post('/user/register', {
        username: username.trim(),
        password: password.trim(),
        email: email.toLowerCase().trim(),
        referral_origin: referrer,
      })
      .then((res) => {
        if (res.data.success) {
          setRegistrationSuccess(true)
        } else {
          setRegistrationError(true)
          props.onAuthFailure()
        }
      })
      .catch(() => {
        setRegistrationError(true)
        props.onAuthFailure()
      })
  }

  // don't think this will be called anymore
  const submitFormRegistrationYj = async () => {
    if (
      emailError
      || emailBlank
      || naughtyWord
      || passwordBlank
      || passwordError
      || usernameError
    ) return

    const payload = {
      password: password.trim(),
      email: email.toLowerCase().trim(),
      referral_origin: referrer,
      browser,
      os,
      platform,
      noAccessTracking
    }

    setYjNotExist(false)
    const resCheck = await axios.post('/user/check-availability-yj', payload)
    if (!resCheck.data.success) {
      setYjNotExist(true)
      return
    }
    // if (resCheck.data.message === 'EMAIL_SENT') {
    //   setYjEmailSent(true)
    //   return
    // }
    const response = await axios.post('/user/register', payload)

    if (response.data.success) {
      setRegistrationSuccess(true)
      setYjRedeem(true)
      onYjRedeem()
      props.signIn({
        ...response.data,
        avatar_url: response.data.s3_avatar_path,
      })
    } else {
      setRegistrationError(true)
      props.onAuthFailure()
    }
  }

  const submitFormLogin = async () => {
    setEmailBlank(false)
    setPasswordBlank(false)

    const payload = {
      password: password.trim(),
      email: email.toLowerCase().trim(),
      browser,
      os,
      platform,
      noAccessTracking
    }
    // if the user has a YJ sub transfer pending, send email and direct to /claim;
    // else, attempt login
    let resCheck
    try {
      resCheck = await axios.post('/user/check-availability-yj', payload)
    } catch (err) {
      console.error(err)
    }
    if (resCheck?.data?.success) return setShowClaimModal(true)

    try {
      axios
        .post('/user/login', {
          email: email.trim().toLowerCase(),
          password: password.trim(),
          noAccessTracking,
          browser,
          os,
          platform,
        })
        .then((res) => {
          if (res.data.success) {
            setLoginFailure(false)

            props.signIn({
              ...res.data,
              avatar_url: res.data.s3_avatar_path,
            })

            if (!flat) {
              window.location.reload(true)
            } else {
              setTimeout(() => {
                history.push('/home')
              }, 1000)
            }
          } else {
            setLoginFailure(true)
            props.onAuthFailure()
          }
        })
        .catch((err) => {
          setLoginFailure(true)
          if (err.message.indexOf('429') > -1) {
            setLoginError('Too many login attempts; please try again later')
          }
          props.onAuthFailure()
        })
    } catch (err) {
      setLoginFailure(true)
      props.onAuthFailure()
    }

    setTimeout(() => {
      setLoginFailure(false)
    }, 15000)
  }

  const validatePasswordConfirm = () => {
    let result = false
    if (!passwordConfirm) {
      result = true
    } else if (password !== passwordConfirm) {
      result = true
    }
    setPasswordConfirmNoMatch(result)
    return result
  }

  const submitForm = (e) => {
    e.preventDefault()
    if (!email.trim()) return setEmailBlank(true)
    if (!password) return setPasswordBlank(true)

    if (yj) {
      submitFormRegistrationYj()
    } else if (isLogin) {
      submitFormLogin()
    } else {
      validateEmail()
      validateUsername()
      const _naughtyWord = containsProfanity(username)
      const _validPassword = validatePassword()
      const _validPasswordConfirm = validatePasswordConfirm()

      setNaughtyWord(_naughtyWord)

      if (
        !(
          emailError
          || emailBlank
          || _naughtyWord
          || passwordBlank
          || passwordError
          || usernameError
          || _validPassword
          || _validPasswordConfirm
        )
      ) submitFormRegistration()
    }
  }

  const RenderErrors = () => {
    return (
      <>
        {registrationError && (
          <Header as="h4" style={{ textAlign: 'center' }}>
            Sorry, that username or email is unavailable!
          </Header>
        )}
      </>
    )
  }

  return (
    <>
      {queryValues.timeout && (
        <Message
          style={{
            marginTop: '20px',
            marginBottom: '0px',
          }}
          negative
        >
          <Message.Header>Session Expired</Message.Header>
          <p>Please sign in again to continue learning! :)</p>
        </Message>
      )}
      {loginFailure && (
        <Header textAlign="center" as="h4" color="red">
          {loginError || 'Invalid login!'}
        </Header>
      )}
      {yjNotExist && (
        <div style={{ fontFamily: 'Milliard Book' }}>
          <Header textAlign="center" as="h4" color="red">
            The email you entered does not appear to be linked to a valid YesJapan account.
          </Header>
          <p style={{ margin: '10px 0', fontWeight: 'bold' }}>Please check:</p>
          <ol>
            <li>If there is a typo in the email</li>
            <li>That the email you entered is the one you used for your YesJapan account (did you use an older email address?)</li>
            <li>That your YesJapan account is on an active paying subscription</li>
          </ol>
          <p style={{ margin: '10px 0' }}>If you still need help, please contact us by clicking&nbsp;
            <ErrorReportModal
              trigger={<a>here.</a>}
            />
          </p>
        </div>
      )}
      {yjEmailSent && (
        <div style={{ fontFamily: 'Milliard Book' }}>
          <Header textAlign="center" as="h4" color="red">
            Please check the email you provided for further instructions!
          </Header>
          <p style={{ margin: '10px 0' }}>If you still need help, please contact us by clicking&nbsp;
            <ErrorReportModal
              trigger={<a>here.</a>}
            />
          </p>
        </div>
      )}
      {(emailBlank || passwordBlank) && (
        <Header textAlign="center" as="h4" color="red">
          Email/password can&apos;t be blank!
        </Header>
      )}
      <RenderErrors />
      {!registrationSuccess && (
        <>
          <FromZeroInput className={'container'}>
            <Form data-cy='login-form' onSubmit={submitForm} size="large" key="large">
              <YJLabelInput
                data-cy='input-email'
                label={!noLabels ? 'Email' : (yj && '1. Enter your email address linked to your YesJapan.com account')}
                placeholder="Email"
                name="username"
                type="email"
                value={email}
                onChange={(_, { value }) => setEmail(value)}
                onFocus={() => { if (props.inModal) setInputFocused(true) }}
                onBlur={validateEmail}
                error={emailBlank}
                required={!yj}
                aria-required='true'
              />
              {!isLogin && emailError && (
                <WarningMessage isMobile={isMobile}>
                  You must enter a valid email address to continue!
                </WarningMessage>
              )}
              {!isLogin && emailTaken && (
                <WarningMessage isMobile={isMobile} wrapP>
                  Email address could not be validated. If you believe this
                  was typed correctly,{' '}
                  <Link to="/login" style={{ color: 'orange' }}>
                    try logging in
                  </Link>{' '}
                  or resetting your password.
                </WarningMessage>
              )}
              {!isLogin && (
                <>
                  <Form.Input
                    data-cy='input-username'
                    label={!noLabels && 'Username'}
                    placeholder="Username"
                    name="name"
                    type="text"
                    value={username}
                    onChange={(_, { value }) => {
                      // filterBadWords()
                      setUsername(value)
                    }}
                    onBlur={() => validateUsername()}
                    error={usernameError || usernameTooShort}
                  />
                  {usernameError && (
                    <WarningMessage isMobile={isMobile}>
                      Your username must contain only numbers, letters,
                      underscores, or dashes!
                    </WarningMessage>
                  )}
                  {usernameTooShort && (
                    <WarningMessage isMobile={isMobile}>
                      Your username must be at least 3 characters!
                    </WarningMessage>
                  )}
                  {naughtyWord && (
                    <WarningMessage isMobile={isMobile}>
                      Please enter a family-friendly name!
                    </WarningMessage>
                  )}
                  {usernameTaken && (
                    <WarningMessage isMobile={isMobile}>
                      Sorry, that username is already in use!
                    </WarningMessage>
                  )}
                </>
              )}
              <PasswordField inModal={props.inModal} yj={yj}>
                <YJLabelInput
                  data-cy='input-password'
                  label={!noLabels ? 'Password' : (yj && '2. Create a new password')}
                  name="password"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Password"
                  value={password}
                  onChange={(_, { value }) => setPassword(value)}
                  onFocus={() => { if (props.inModal) setInputFocused(true) }}
                  onBlur={() => validatePassword()}
                  error={passwordBlank}
                  required={!yj}
                  aria-required='true'
                />
                <div
                  className="password-visibility"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  <Icon name={passwordVisible ? 'eye' : 'eye slash'} />
                </div>
              </PasswordField>
              {!isLogin && passwordError && (
                <WarningMessage isMobile={isMobile} data-cy='password-error'>
                  Your password must be at least 8 characters long and contain 1
                  number, 1 special character, and 1 uppercase character!
                </WarningMessage>
              )}
              {!isLogin && (
                <>
                  <Form.Input
                    data-cy='input-password-confirm'
                    label={!noLabels && 'Confirm Password'}
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Confirm your password"
                    value={passwordConfirm}
                    onChange={(_, { value }) => setPasswordConfirm(value)}
                    onBlur={() => validatePasswordConfirm()}
                    error={passwordConfirmNoMatch}
                  />
                  {passwordConfirmNoMatch && (
                    <WarningMessage isMobile={isMobile}>
                      Your password and password confirmation must match!
                    </WarningMessage>
                  )}
                </>
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Button
                  type="submit"
                  className="fz-button"
                  onClick={submitForm}
                  data-cy='login-submit-btn'
                >
                  {isLogin && yj && 'Claim'}
                  {isLogin && !yj && 'Sign In'}
                  {!isLogin && 'Register'}
                </Button>
                {isMobile && !isLogin && (
                  <Button
                    className="fz-button login"
                    onClick={() => history.push('/login')}
                  >
                    Sign in here
                  </Button>
                )}
              </div>
            </Form>
          </FromZeroInput>

          {!yj && (
            <div className="actions">
              {isLogin ? (
                <Link to="/register">
                  Don&apos;t have an account yet? Register here!
                </Link>
              ) : (
                <>
                  {!isMobile && (
                    <Link to="/login">Already have an account? Login here!</Link>
                  )}
                </>
              )}
              <Link to="/resend">My activation link didn&apos;t arrive!</Link>
              <Link to="/forgot_password">I forgot my password!</Link>
            </div>
          )}
        </>
      )}

      {!isLogin && registrationSuccess && (
        <div className="success">
          <Header as="h2" icon textAlign="center">
            Registration Successful!
          </Header>
          <p>Check your inbox for your confirmation link!</p>
          <p>
            If you don&apos;t see an email from us, you can{' '}
            <Link to="/resend">request another confirmation link here</Link>
          </p>
        </div>
      )}
    </>
  )
}

export default AuthInputFields
