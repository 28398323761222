import React from 'react'
import { ToolLabel as Label } from '../styles'

/**
 * ToolLabel
 * Displays what a widget's type is outside of edit view.
 */
const ToolLabel: React.FC<{
  text: string,
  draggable_props: any,
  tool_open: boolean,
  is_draft: boolean
}> = ({
  text = '',
  draggable_props,
  tool_open,
  is_draft = false,
}) => {
  if (!draggable_props) return (<></>)

  const labelTitle = is_draft ? `\nThis ${text} is marked as drafting.` : false
  return (
    <Label
      open={tool_open}
      is_draft={is_draft} {...draggable_props}
      title={`Click and drag me to move the widget.${labelTitle || ''}`}
    >
      <p>{text?.replace('Widget', '')}</p>
    </Label>
  )
}

export default ToolLabel
