import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const assetsPath = `${CLOUDFRONT_BASE}/images/promos/2023_new_year_sale`
const confetti = `${assetsPath}/effect-new-years-confetti-site-background.png`
const fireworks = `${assetsPath}/effect-new-years-fireworks-site-background.png`
const polygons = `${assetsPath}/effect-new-years-ploygons-site-background.png`
const sparkles = `${assetsPath}/effect-new-years-sparkles-site-background.png`

//
// --- PremiumMembershipSubscribePage ---
//

export const SaleSubscribePage = styled.div`
  position: relative;
  padding: 60px 16px;
  background: 
    url(${fireworks}),
    url(${polygons}),
    url(${confetti}),
    url(${sparkles}),
    radial-gradient(ellipse at center, #ffffff 0%, #e8edff 50%, #d9e3f5 100%);
  background-size: cover, cover, cover, contain;
  background-blend-mode: screen, normal, normal, normal, normal;
  background-position: 0% 0%, 0% 0%, -10% 0%, 0% 0%;
  min-height: calc(100vh - 120px); // idk why this is necessary, 100% doesn't work
`

export const SaleSubscribeHeader = styled.h3`
  font-family: 'Racing Sans One', sans-serif;
  font-size: 2rem !important;
  text-align: center;
  // background gradient DD974F > FFC368 > DD974F
  background: linear-gradient(90deg, #c78746, 35%, #FFC368, 65%, #c78746);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 768px) { font-size: 1.75rem !important; }
`

export const SaleSubscribeBlackText = styled.h2<{ banner?: boolean, account?: boolean, small?: boolean, glow?: boolean }>`
  font-family: 'Racing Sans One', sans-serif !important;
  font-size: ${({ small }) => small ? '1.5rem' : '3rem'} !important;
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  text-align: center;

  // background gradient dark off-black > lighter black > dark off-black
  // only want if there's no glow since the transparency doesn't work with text-shadow
  ${({ glow }) => glow
    ? css`text-shadow: 0 0 5px white, 0 0 10px white, 0 0 20px white;`
    : css`
      background: linear-gradient(90deg, #1a1a1a, 20%, #555, 80%, #1a1a1a);
      background-clip: text;
      -webkit-text-fill-color: transparent;
  `}

  

  @media (max-width: 768px) { font-size: ${({ small }) => small ? '1.25rem' : '2.5rem'} !important; }

  ${({ banner, glow }) => banner && css`
    background-image: ${!glow ? 'linear-gradient(90deg, #1a1a1a, 20%, #333, 80%, #1a1a1a)' : ''};

    @media (max-width: 1100px) { font-size: 2.25rem !important; }
    @media (max-width: 850px) { font-size: 1.75rem !important; }
    @media (max-width: 768px) { margin: 0 !important; }
    @media (max-width: 450px) { font-size: 1.5rem !important; }
    @media (max-width: 380px) { font-size: 1.1rem !important; }
  `}

  ${({ account }) => account && css`
    margin: 0 !important;
    font-size: 1.5rem !important; 
    
    @media (max-width: 768px) { font-size: 1.5rem !important; } // need this to override thing above it's a mess
  `}

  ${({ small }) => small && css`
    font-size: 1.5rem !important;
    @media (max-width: 1268px) { font-size: 1.5rem !important; }
    @media (max-width: 768px) { font-size: 1.25rem !important; }
    @media (max-width: 450px) { font-size: 1rem !important; }
    font-family: 'Mont Heavy' !important;
    margin-top: -10px !important;
  `}
`

export const SaleSubscribeLightCopy = styled.p`
  font-size: 1.2rem;
  text-align: center;
  color: #888;
  margin-top: 8px !important;

  @media (max-width: 767px) { font-size: 1rem; }
`

//
// --- Account Page ---
//

export const GoldGradientContainer = styled.div`
  background: #f5d59a;
  align-self: stretch;
  margin: 16px auto;
  padding: 24px 0;
  max-width: 500px;

  @media (max-width: 499px) { padding: 12px 0; }
`
