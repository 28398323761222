import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { AxiosError } from 'axios'

import axios from 'src/api'

import { Menu, Transition } from '@headlessui/react'
import BackgroundSettingsStyles from '../../styles/BackgroundSettingsStyles'

import { REDUX_ACTIONS } from 'src/types'
import { ChevronDownSolid, ChevronUpSolid } from '@graywolfai/react-heroicons'

const CourseBackgroundSettings = () => {
  const [setting, setSetting] = useState<string>()
  const course_background = useSelector((state: RootState) => state.auth?.course_background)
  const dispatchBGSetting = useDispatch()

  useEffect(() => {
    if (course_background) {
      setSetting(course_background)
      return
    }
    setSetting('Scenery')
  }, [course_background])

  const settingNames = {
    scenery: 'Scenery',
    kana: 'Kana',
    scenery_plus: 'Scenery+',
    stationery: 'Stationery',
    japan_dynamic: 'Japan Dynamic (Beta)',
    japan_day: 'Japan Day',
    japan_night: 'Japan Night',
    japan_dawn: 'Japan Dawn',
    study_mode: 'Study Mode',
    none: 'None',
  }
  const settingsList = Object.keys(settingNames)

  const changeSetting = async (course_bg_setting) => {
    setSetting(course_bg_setting)
    dispatchBGSetting({ type: REDUX_ACTIONS.CHANGE_COURSE_BACKGROUND, payload: course_bg_setting })
    try {
      await axios.post('/user/change_course_background', { course_background: course_bg_setting })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.code)
      console.error(error?.message)
    }
  }

  return (
    <BackgroundSettingsStyles>
      <Menu as='div'>
        {({ open }) => (
          <>
            <Menu.Button style={{ backgroundColor: 'inherit', background: 'inherit' }}>
              <span className='tw-text-offwhite'>Background:{' '}</span>
              <span className='tw-font-bold'>{settingNames[setting]}</span>
              {open
                ? <ChevronUpSolid height='20px' className='tw-inline tw-fill-white' fill='white' />
                : <ChevronDownSolid height='20px' className='tw-inline tw-fill-white' fill='white' />
              }
            </Menu.Button>
            <Transition
              show={open}
              enter='tw-transition tw-duration-100 tw-ease-out'
              enterFrom='tw-transform tw-scale-90 tw-opacity-0'
              enterTo='tw-transform tw-scale-100 tw-opacity-100'
              leave='tw-transition tw-duration-75 tw-ease-out'
              leaveFrom='tw-transform tw-scale-100 tw-opacity-100'
              leaveTo='tw-transform tw-scale-90 tw-opacity-0'
            >
              <Menu.Items className='menu-items'>
                {settingsList.map((_setting: string) => (
                  <Menu.Item
                    key={_setting}
                    disabled={setting === _setting}
                  >
                    <p
                      className={`menu-item tw-p-0 ${setting === _setting ? 'active' : ''}`}
                      onClick={() => changeSetting(_setting)}
                    >
                      {settingNames[_setting]}
                    </p>
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </BackgroundSettingsStyles>
  )
}

export default CourseBackgroundSettings
