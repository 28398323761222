import styled, { css } from 'styled-components';

interface StudyModeProps {
  inStudyMode: boolean
}

const disabledStyles = css`
  input {
    background-color: #E8E8E8;
    cursor: default;
  }
  input::before {
    background-color: #BEBEBE;
  }
  p {
    color: #BEBEBE !important;
  }
`

export default styled.div<StudyModeProps>`
  
  button {
    background-color: #28c6aa;
    padding: 0.3rem 2rem;
    border-radius: 20px;
    box-shadow: ${({ inStudyMode }) => inStudyMode ? 'inset 2px 2px 2px 2px #1f9c86' : ''}
  }
  button:hover {
    background-color: #24b198;
  }

  aside {
    font-size: 0.9rem;
    text-align: center;
  }

  input {
    position: relative;
    appearance: none;
    display: flex;
    align-items: center;
    margin: 2.5px 0.5rem 0;
    height: 1.5rem;
    width: 3rem;
    background-color: white;
    border-radius: 20px;
    cursor: pointer;
  }
  input::before {
    content: '';
    display: inline-block;
    height: 1rem;
    width: 1rem;
    background-color: #28c6aa;
    border-radius: 50%;
    position: absolute;
    left: 10%;
    transition: .3s;
  }
  input:checked::before {
    left: 60%;
  }
  
  .settings {
    display: flex;
    justify-content: space-between;
  }

  .language-selector {
    display: flex;
    align-items: center;
  }
  .language-selector > p {
    padding: 0 !important;
    font-weight: bold;
    font-size: 1.5rem;
  }

  ${({ inStudyMode }) => !inStudyMode && disabledStyles}
`
