import { CourseInfo } from 'src/types'
import styled from 'styled-components'

interface CrumbProps {
  courseInfo: CourseInfo
}

export default styled.div.attrs((props: CrumbProps) => ({
  color: props.courseInfo.color,
  alt_color_4: props.courseInfo.alt_color_4,
}))<CrumbProps>`
  display: inline-block;
  position: relative;
  height: 26px;
  margin: 0 5px 0 2px;
  width: 55px;
  background-color: ${(props) => props.alt_color_4};
  z-index: 1;
  padding-left: 1px;

  :after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 26px;
    border-left: 13px solid ${(props) => props.color};
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
  }

  div {
    color: ${(props) => props.color};
    display: inline-block;
    height: 14px;
    padding-left: 7px;
    margin: 4px 0 8px 2px;
  }

  @media (max-width: 377px) {
    width: 45px;
    font-size: 0.9rem;
  }

  @media (max-width: 343px) {
    width: 40px;
    font-size: 0.75rem;
  }
`
