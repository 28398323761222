import { useState } from 'react'
import { Tabs } from '@mantine/core'
import StripeCheckoutForm from 'src/components/pages/account/subscriptions/StripeCheckoutForm'
import usePayPal from 'src/hooks/subscriptions/usePayPal'
import { PaymentOptionsWrapper } from './styles/PaymentOptions.styles'

const PaymentOptions = ({ onSuccess, subscriptionSuccess }) => {
  const [activeTab, setActiveTab] = useState<string>('stripe')
  const { paypalButton, payPalError } = usePayPal({ onSuccess })

  return (
    <PaymentOptionsWrapper activeTab={activeTab}>
      <Tabs value={activeTab} onTabChange={setActiveTab}>
        <Tabs.List grow>
          <Tabs.Tab value='stripe'>Credit Card</Tabs.Tab>
          <Tabs.Tab value='paypal'>Paypal</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='stripe'>
          <StripeCheckoutForm onSuccess={onSuccess} />
        </Tabs.Panel>
        <Tabs.Panel value='paypal'>
          {!subscriptionSuccess && (
            <div style={{ width: '70%', margin: '16px auto' }}>
              {payPalError && <p style={{ fontFamily: 'Outfit', fontSize: '1.2rem', color: 'red', padding: '4px' }}>{payPalError}</p>}
              {paypalButton}
            </div>)}
        </Tabs.Panel>
      </Tabs>
    </PaymentOptionsWrapper>
  )
}

export default PaymentOptions
