import { useState, useEffect } from 'react'
import { Select, MultiSelect } from '@mantine/core'
import { getAllGrammar, getGrammarData } from 'src/components/pages/games/conjugation_game/ConjugationData'
import { getAllVerbs, getVerbData } from 'src/components/pages/games/conjugation_game/VerbData'

const grammar = getAllGrammar().map((g) => {
  const { grammar: def, lesson } = getGrammarData(g)
  return { value: g, label: `${g} - ${def}`, group: `Course ${lesson.slice(0, 1)}` }
})

const verbs = getAllVerbs().map((verb) => {
  const { kana, location } = getVerbData(verb)
  return { value: verb, label: `${verb} (${kana})`, group: `Course ${location.slice(0, 1)}` }
})

const ConjugationSelection = ({ widget, formMethods }) => {
  const [selectedGrammar, setSelectedGrammar] = useState('lesson')
  const [selectedVerbs, setSelectedVerbs] = useState('lesson-learned')
  const [customGrammar, setCustomGrammar] = useState([])
  const [customVerbs, setCustomVerbs] = useState([])

  useEffect(() => {
    if (!widget) return

    const defaults = { grammar_selection: 'lesson', verb_selection: 'lesson-learned', custom_grammar: [], custom_verbs: [] }
    const { grammar_selection, verb_selection, custom_grammar, custom_verbs } = widget?.conjugation_data || defaults

    setSelectedGrammar(grammar_selection)
    setSelectedVerbs(verb_selection)
    setCustomGrammar(custom_grammar)
    setCustomVerbs(custom_verbs)

    formMethods.setValue('conjugation_data', {
      grammar_selection,
      verb_selection,
      custom_grammar,
      custom_verbs
    })
  }, [widget])

  const handleGrammarChange = (value) => {
    if (typeof value === 'string') {
      setSelectedGrammar(value)
      formMethods.setValue('conjugation_data.grammar_selection', value)
    } else {
      setCustomGrammar(value)
      formMethods.setValue('conjugation_data.custom_grammar', value)
    }
  }
  
  const handleVerbChange = (value) => {
    if (typeof value === 'string') {
      setSelectedVerbs(value)
      formMethods.setValue('conjugation_data.verb_selection', value)
    } else {
      setCustomVerbs(value)
      formMethods.setValue('conjugation_data.custom_verbs', value)
    }
  }

  return (
    <div style={{ padding: '16px 32px', display: 'grid', gap: '8px' }}>
      <Select
        label='Select grammar'
        value={selectedGrammar}
        data={[
          { value: 'lesson', label: 'Current lesson only' },
          { value: 'learned', label: 'Previously learned (doesn\'t include current lesson)' },
          { value: 'lesson-learned', label: 'Learned (includes current lesson)' },
          { value: 'all', label: 'All' },
          { value: 'custom', label: 'Custom' }
        ]}
        onChange={(value) => handleGrammarChange(value)}
      />
      <MultiSelect
        label='Custom grammar selection'
        value={customGrammar}
        data={grammar}
        disabled={selectedGrammar !== 'custom' }
        searchable
        onChange={(value) => handleGrammarChange(value)}
      />
      <Select
        label='Select verbs'
        value={selectedVerbs}
        data={[
          { value: 'lesson', label: 'Current lesson only' },
          { value: 'learned', label: 'Previously learned (doesn\'t include current lesson)' },
          { value: 'lesson-learned', label: 'Learned (includes current lesson)' },
          { value: 'all', label: 'All' },
          { value: 'custom', label: 'Custom' }
        ]}
        onChange={(value) => handleVerbChange(value)}
      />
      <MultiSelect
        label='Custom verb selection (some verbs may not be available yet)'
        value={customVerbs}
        data={verbs}
        disabled={selectedVerbs !== 'custom' }
        searchable
        onChange={(value) => handleVerbChange(value)}
      />
    </div>
  )
}

export default ConjugationSelection
