import React, { useMemo } from 'react'
import Timer from 'src/components/Timer'
import useAPI from 'src/hooks/useAPI'
import { number } from 'yup'
import ReadingComprehensionWidgetEditor from '../../editor/activities/ReadingComprehensionWidgetEditor'
import DialogueWidgetEditor from '../../editor/DialogueWidgetEditor'
import ExamplesWidgetEditor from '../../editor/ExamplesWidgetEditor'
import SentencesWidgetEditor from '../../editor/SentencesWidgetEditor'
import { NestedTool } from '../styles'

import ConversationTool from './ConversationTool'
import GridTool from './GridTool'
import ImageTool from './ImageTool'
import KanjiTool from './KanjiTool'
import ListTool from './ListTool'
// import MiniQuizTool from './MiniQuizTool'
import QnATool from './QnATool'
import StructureTool from './StructureTool'
import TableTool from './TableTool'
import TextboxTool from './TextboxTool'
import TextListTool from './TextListTool'
import UsageTool from './UsageTool'
import VideoTool from './VideoTool'

const openToolbox = (widget, metadata, fetchCounter, fetchSubLesson, onGridWidgetSave, fetchWidget) => {
  const { course, lesson, sublesson } = metadata

  const options = {
    id: widget._id,
    incomingWidget: widget,
    widgetsLength: 1,
    index: 0,
    course,
    lesson,
    sublesson,
    outsideLoading: false,
    justChanged: 'false',
    draggable_props: undefined,
    isGridCell: true,
    fetchCounter,
    fetchSubLesson,
    onGridWidgetSave,
    fetchWidget
  }

  const widgets = {
    ConversationWidget: <ConversationTool {...options} />,
    GridWidget: <GridTool {...options} />,
    ImageWidget: <ImageTool {...options} />,
    // MiniQuizWidget: <MiniQuizTool />,
    KanjiWidget: <KanjiTool {...options} />,
    ListWidget: <ListTool {...options} />,
    QnAWidget: <QnATool {...options} />,
    StructureWidget: <StructureTool {...options} />,
    TableWidget: <TableTool {...options} />,
    TextboxWidget: <TextboxTool {...options} />,
    TextListWidget: <TextListTool {...options} />,
    UsageWidget: <UsageTool {...options} />,
    VideoWidget: <VideoTool {...options} />,
    ExamplesWidget: <ExamplesWidgetEditor {...options} />,
    SentencesWidget: <SentencesWidgetEditor {...options} />,
    DialogueWidget: <DialogueWidgetEditor {...options} />,
    ReadingComprehensionWidget: <ReadingComprehensionWidgetEditor {...options} />,
  }

  return widgets[widget.type]
}

interface NestedWidgetProps {
  widget_id: string
  course: string
  lesson: string
  sublesson: string
  fetchCounter?: number,
  fetchSubLesson?: () => void,
  onGridWidgetSave?: () => void,
  fetchWidget?: (id: string) => void
}

const NestedWidgetTool: React.FC<NestedWidgetProps> = ({
  widget_id,
  course,
  lesson,
  sublesson,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave,
  fetchWidget
}) => {
  const [nestedWidget] = useAPI(`/widgets/${widget_id}`)

  const nested = useMemo(() => {
    if (nestedWidget?.success && nestedWidget?.widget) {
      return openToolbox(
        nestedWidget.widget,
        { course, lesson, sublesson },
        fetchCounter,
        fetchSubLesson,
        onGridWidgetSave,
        fetchWidget
      )
    }
    return (
      <Timer spinnerWithMessage={'Loading nested widget...'} />
    )
  }, [nestedWidget])

  return (
    <NestedTool>
      {nested}
    </NestedTool>
  )
}

export default NestedWidgetTool
