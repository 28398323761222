import styled from 'styled-components'

export default styled.div`
  * {
    color: var(--off-white);
  }

  .menu-item {
    border-radius: 8px;
    padding: 6px 0;
    margin: 3px 0;
    cursor: pointer;
    
    p { padding: 0 !important; }

    :hover {
      background-color: var(--sub-blue);
    }
  }

  .active {
    color: #9f9f9f !important;
    
    :hover {
      background-color: transparent;
    }
  }
`
