import { BADGE_IDENTIFIER } from 'src/types'

/* eslint-disable */
const cloudfront_base = 'https://d32tv5vz8ua1sf.cloudfront.net/images/badges',
      ceo       = cloudfront_base + '/badge_ceo.png',
      dev       = cloudfront_base + '/badge_dev.png',
      mod       = cloudfront_base + '/badge_mod.png',
      artist    = cloudfront_base + '/badge_artist.png',
      author    = cloudfront_base + '/badge_author.png',
      legacy    = cloudfront_base + '/badge_legacy.png',
      mentor    = cloudfront_base + '/badge_mentor.png',
      meetup    = cloudfront_base + '/badge_meetup.png',
      teacher   = cloudfront_base + '/badge_teacher.png',
      founder   = cloudfront_base + '/badge_founder.png',
      pioneer   = cloudfront_base + '/badge_pioneer.png',
      wishtag   = cloudfront_base + '/badge_wishtag.png',
      new_user  = cloudfront_base + '/badge_new_user.png',
      jfzbook1a = cloudfront_base + '/badge_book_1A.png',
      jfzbook1b = cloudfront_base + '/badge_book_1B.png',
      jfzbook1c = cloudfront_base + '/badge_book_1C.png',
      jfzbook2a = cloudfront_base + '/badge_book_2A.png',
      jfzbook2b = cloudfront_base + '/badge_book_2B.png',
      jfzbook2c = cloudfront_base + '/badge_book_2C.png',
      jfzbook3a = cloudfront_base + '/badge_book_3A.png',
      jfzbook3b = cloudfront_base + '/badge_book_3B.png',
      jfzbook3c = cloudfront_base + '/badge_book_3C.png',
      jfzbook4a = cloudfront_base + '/badge_book_4A.png',
      jfzbook4b = cloudfront_base + '/badge_book_4B.png',
      jfzbook4c = cloudfront_base + '/badge_book_4C.png',
      jfzbook5a = cloudfront_base + '/badge_book_5A.png',
      jfzbook5b = cloudfront_base + '/badge_book_5B.png',
      jfzbook5c = cloudfront_base + '/badge_book_5C.png'
/* eslint-enable */
export interface Badges {
  _id?: string
  identifier: string
  icon: string
  name: string
  nameShort?: string
  award: string
  prerequisite: string
  display: boolean
  obtained: boolean
}

const staff_badges: Badges[] = [
  {
    identifier: 'mod',
    icon: mod,
    name: 'Moderator',
    award: 'Moderator for the From Zero! website',
    prerequisite: 'Moderate for the From Zero! website',
    display: true,
    obtained: false,
  },
  {
    identifier: 'dev',
    icon: dev,
    name: 'Developer',
    award: 'Developer for the From Zero! website',
    prerequisite: 'Contribute to the development of the From Zero! website',
    display: true,
    obtained: false,
  },
  {
    identifier: 'author',
    icon: author,
    name: 'Author',
    award: 'Author for the From Zero! website',
    prerequisite: 'Create a course on the From Zero! website',
    display: true,
    obtained: false,
  },
  {
    identifier: 'teacher',
    icon: teacher,
    name: 'Teacher',
    award: 'Teacher for the From Zero! website',
    prerequisite: 'Create a course on the From Zero! website',
    display: true,
    obtained: false,
  },
  {
    identifier: 'artist',
    icon: artist,
    name: 'Artist',
    award: 'Creates art for the From Zero! website',
    prerequisite: 'Create art assets for the From Zero! website',
    display: true,
    obtained: false,
  },
  {
    identifier: 'ceo',
    icon: ceo,
    name: 'CEO',
    award: 'CEO for From Zero!',
    prerequisite: 'CEO of From Zero!',
    display: true,
    obtained: false,
  },
]

const vanity_badges: Badges[] = [
  {
    identifier: 'founder',
    icon: founder,
    name: 'Founder',
    award:
      'Obtained the Kansai dialect course during the limited time promotion',
    prerequisite: 'Obtain Kansai dialect during a limited time promotion',
    display: false,
    obtained: false,
  },
  {
    identifier: 'pioneer',
    icon: pioneer,
    name: 'Pioneer',
    award: 'Participated in the Japanese From Zero! closed beta',
    prerequisite: 'Participate in the Japanese From Zero! closed beta',
    display: true,
    obtained: false,
  },
  {
    identifier: 'legacy',
    icon: legacy,
    name: 'Legacy',
    award:
      'Created a YesJapan account before the full Japanese From Zero! launch',
    prerequisite:
      'Create a YesJapan account before the full Japanese From Zero! launch',
    display: false,
    obtained: false,
  },
  {
    identifier: 'mentor',
    icon: mentor,
    name: 'Mentor',
    award: 'Mentor for the From Zero! website',
    prerequisite: "Help guide others' understanding of course content",
    display: true,
    obtained: false,
  },
  {
    identifier: 'meetup',
    icon: meetup,
    name: 'Meetup',
    award: 'Attended a FromZero! meetup',
    prerequisite: 'Attend a FromZero! meetup',
    display: false,
    obtained: false,
  },
  {
    identifier: 'wishtag',
    icon: wishtag,
    name: 'Wishtag',
    award: 'Broke a studying streak and came back for more',
    prerequisite: 'Show perseverance',
    display: false,
    obtained: false,
  },
  {
    identifier: 'new_user',
    icon: new_user,
    name: 'New User',
    award: 'Created an account on From Zero!',
    prerequisite: 'Create an account on From Zero!',
    display: true,
    obtained: false,
  }
]

const jfz_books: string[][] = [
  ['1', 'Bronze', jfzbook1a],
  ['1', 'Silver', jfzbook1b],
  ['1', 'Gold', jfzbook1c],
  ['2', 'Bronze', jfzbook2a],
  ['2', 'Silver', jfzbook2b],
  ['2', 'Gold', jfzbook2c],
  ['3', 'Bronze', jfzbook3a],
  ['3', 'Silver', jfzbook3b],
  ['3', 'Gold', jfzbook3c],
  ['4', 'Bronze', jfzbook4a],
  ['4', 'Silver', jfzbook4b],
  ['4', 'Gold', jfzbook4c],
  ['5', 'Bronze', jfzbook5a],
  ['5', 'Silver', jfzbook5b],
  ['5', 'Gold', jfzbook5c],
]

const book_award_text = (
  book: string,
  level: string,
  award = false
): string => {
  const prefix = `Complete${award ? 'd' : ''} JFZ! Course`
  switch (level) {
    case 'bronze':
      return `${prefix} ${book} with an average score of 70%`
    case 'silver':
      return `${prefix} ${book} with an average score of 85%`
    default:
      return `${prefix} ${book} with a perfect score`
  }
}

export const jfz_book_badges: Badges[] = jfz_books.map((book) => ({
  identifier: `jfz-book${book[0]}-${book[1].toLowerCase()}`,
  icon: book[2],
  name: `Japanese From Zero! ${book[0]} ${book[1]}`,
  nameShort: `JFZ! ${book[0]} ${book[1]}`,
  award: book_award_text(book[0], book[1].toLowerCase(), true),
  prerequisite: book_award_text(book[0], book[1].toLowerCase()),
  display: true,
  obtained: false,
}))

const available_badges: Badges[] = [
  ...staff_badges,
  ...vanity_badges,
  ...jfz_book_badges,
]

export default available_badges
