import { Selection } from './useConjugation/types'

export type Verb = '買う' | '売る' | '食べる' | '帰る' | '来る' | '行く' | '飲む' | '書く' | '読む' | '洗う'
          | '拾う' | '見る' | '聞く' | 'する' | '寝る' | '起きる' | '戻る' | '泊まる' | '働く' | '話す'
          | '払う' | '待つ' | '答える' | '会う' | '遊ぶ' | '住む' | '飼う' | '見せる' | 'なる' | '始める'
          | '探す' | '泳ぐ' | '無くす' | '見つける' | '分かる' | 'いる' | 'ある' | '遅れる' | 'かかる' | '思う'
          | '言う' | '変わる' | 'やる' | '吸う' | '変わる' | '始まる' | '終わる' | 'いる' | 'ある' | '見つかる'
          | '始まる' | '終わる' | '頑張る' | '違う' | '死ぬ' | '運ぶ' | '嗅ぐ' | '打つ' | '殺す' | '消す'

type VerbData = Partial<{ [key in Verb]: {
  def: string
  ichidan: boolean
  kana: string
  base_text: string
  location: string // 'course/lesson'
} }>

const verbData: VerbData = {
  帰る: {
    def: 'to return',
    ichidan: false,
    kana: 'かえる',
    base_text: '帰(かえ)る',
    location: '1/12'
  },
  来る: {
    def: 'to come',
    ichidan: false,
    kana: 'くる',
    base_text: '来(く)る',
    location: '1/12'
  },
  行く: {
    def: 'to go',
    ichidan: false,
    kana: 'いく',
    base_text: '行(い)く',
    location: '1/12'
  },
  分かる: {
    def: 'to understand',
    ichidan: false,
    kana: 'わかる',
    base_text: '分(わ)かる',
    location: '1/12'
  },
  いる: {
    def: 'to be; to have',
    ichidan: true,
    kana: 'いる',
    base_text: 'いる',
    location: '2/1'
  },
  ある: {
    def: 'to be; to have',
    ichidan: false,
    kana: 'ある',
    base_text: 'ある',
    location: '2/1'
  },
  買う: {
    def: 'to buy',
    ichidan: false,
    kana: 'かう',
    base_text: '買(か)う',
    location: '2/5'
  },
  売る: {
    def: 'to sell',
    ichidan: false,
    kana: 'うる',
    base_text: '売(う)る',
    location: '2/5'
  },
  食べる: {
    def: 'to eat',
    ichidan: true,
    kana: 'たべる',
    base_text: '食(た)べる',
    location: '2/5'
  },
  飲む: {
    def: 'to drink',
    ichidan: false,
    kana: 'のむ',
    base_text: '飲(の)む',
    location: '2/5'
  },
  書く: {
    def: 'to write',
    ichidan: false,
    kana: 'かく',
    base_text: '書(か)く',
    location: '2/6'
  },
  読む: {
    def: 'to read',
    ichidan: false,
    kana: 'よむ',
    base_text: '読(よ)む',
    location: '2/6'
  },
  洗う: {
    def: 'to wash',
    ichidan: false,
    kana: 'あらう',
    base_text: '洗(あら)う',
    location: '2/9'
  },
  拾う: {
    def: 'to pick up',
    ichidan: false,
    kana: 'ひろう',
    base_text: '拾(ひろ)う',
    location: '2/9'
  },
  死ぬ: {
    def: 'to die',
    ichidan: false,
    kana: 'しぬ',
    base_text: '死(し)ぬ',
    location: '2/9'
  },
  運ぶ: {
    def: 'to move / carry (something)',
    ichidan: false,
    kana: 'はこぶ',
    base_text: '運(はこ)ぶ',
    location: '2/9'
  },
  泳ぐ: {
    def: 'to swim',
    ichidan: false,
    kana: 'およぐ',
    base_text: '泳(およ)ぐ',
    location: '2/9' // also 3/13
  },
  嗅ぐ: {
    def: 'to smell; sniff',
    ichidan: false,
    kana: 'かぐ',
    base_text: '嗅(か)ぐ',
    location: '2/9'
  },
  打つ: {
    def: 'to shoot (a gun, etc.)',
    ichidan: false,
    kana: 'うつ',
    base_text: '打(う)つ',
    location: '2/9'
  },
  殺す: {
    def: 'to kill',
    ichidan: false,
    kana: 'ころす',
    base_text: '殺(ころ)す',
    location: '2/9'
  },
  消す: {
    def: 'to erase',
    ichidan: false,
    kana: 'けす',
    base_text: '消(け)す',
    location: '2/9'
  },
  見る: {
    def: 'to watch / see',
    ichidan: true,
    kana: 'みる',
    base_text: '見(み)る',
    location: '2/10'
  },
  聞く: {
    def: 'to hear; listen; ask',
    ichidan: false,
    kana: 'きく',
    base_text: '聞(き)く',
    location: '2/10'
  },
  する: {
    def: 'to do',
    ichidan: false,
    kana: 'する',
    base_text: 'する',
    location: '2/11'
  },
  寝る: {
    def: 'to sleep',
    ichidan: true,
    kana: 'ねる',
    base_text: '寝(ね)る',
    location: '2/12'
  },
  起きる: {
    def: 'to wake up',
    ichidan: true,
    kana: 'おきる',
    base_text: '起(お)きる',
    location: '2/12'
  },
  遅れる: {
    def: 'to be late',
    ichidan: true,
    kana: 'おくれる',
    base_text: '遅(おく)れる',
    location: '2/12'
  },
  戻る: {
    def: 'to return',
    ichidan: false,
    kana: 'もどる',
    base_text: '戻(もど)る',
    location: '3/1'
  },
  頑張る: {
    def: "to do one's best",
    ichidan: false,
    kana: 'がんばる',
    base_text: '頑(がん)張(ば)る',
    location: '3/1'
  },
  泊まる: {
    def: 'to stay',
    ichidan: false,
    kana: 'とまる',
    base_text: '泊(と)まる',
    location: '3/1'
  },
  働く: {
    def: 'to work',
    ichidan: false,
    kana: 'はたらく',
    base_text: '働(はたら)く',
    location: '3/1'
  },
  話す: {
    def: 'to talk',
    ichidan: false,
    kana: 'はなす',
    base_text: '話(はな)す',
    location: '3/1'
  },
  かかる: {
    def: 'to cost money; take time',
    ichidan: false,
    kana: 'かかる',
    base_text: 'かかる',
    location: '3/2'
  },
  払う: {
    def: 'to pay',
    ichidan: false,
    kana: 'はらう',
    base_text: '払(はら)う',
    location: '3/2'
  },
  思う: {
    def: 'to think',
    ichidan: false,
    kana: 'おもう',
    base_text: '思(おも)う',
    location: '3/4'
  },
  言う: {
    def: 'to say',
    ichidan: false,
    kana: 'いう',
    base_text: '言(い)う',
    location: '3/4'
  },
  待つ: {
    def: 'to wait',
    ichidan: false,
    kana: 'まつ',
    base_text: '待(ま)つ',
    location: '3/4'
  },
  答える: {
    def: 'to answer',
    ichidan: true,
    kana: 'こたえる',
    base_text: '答(こた)える',
    location: '3/5'
  },
  会う: {
    def: 'to meet',
    ichidan: false,
    kana: 'あう',
    base_text: '会(あ)う',
    location: '3/6'
  },
  遊ぶ: {
    def: 'to play',
    ichidan: false,
    kana: 'あそぶ',
    base_text: '遊(あそ)ぶ',
    location: '3/6'
  },
  住む: {
    def: 'to live',
    ichidan: false,
    kana: 'すむ',
    base_text: '住(す)む',
    location: '3/6'
  },
  違う: {
    def: 'to be wrong / different',
    ichidan: false,
    kana: 'ちがう',
    base_text: '違(ちが)う',
    location: '3/6'
  },
  飼う: {
    def: 'to have / raise a pet',
    ichidan: false,
    kana: 'かう',
    base_text: '飼(か)う',
    location: '3/7'
  },
  見せる: {
    def: 'to show',
    ichidan: true,
    kana: 'みせる',
    base_text: '見(み)せる',
    location: '3/7'
  },
  なる: {
    def: 'to become',
    ichidan: false,
    kana: 'なる',
    base_text: 'なる',
    location: '3/8'
  },
  始める: {
    def: 'to start',
    ichidan: true,
    kana: 'はじめる',
    base_text: '始(はじ)める',
    location: '3/8'
  },
  始まる: {
    def: 'to start',
    ichidan: false,
    kana: 'はじまる',
    base_text: '始(はじ)まる',
    location: '3/8'
  },
  終わる: {
    def: 'to finish',
    ichidan: false,
    kana: 'おわる',
    base_text: '終(お)わる',
    location: '3/8'
  },
  吸う: {
    def: 'to smoke',
    ichidan: false,
    kana: 'すう',
    base_text: '吸(す)う',
    location: '3/9'
  },
  探す: {
    def: 'to search',
    ichidan: false,
    kana: 'さがす',
    base_text: '探(さが)す',
    location: '3/11'
  },
  やる: {
    def: 'to do',
    ichidan: false,
    kana: 'やる',
    base_text: 'やる',
    location: '3/11'
  },
  変わる: {
    def: 'to change',
    ichidan: false,
    kana: 'かわる',
    base_text: '変(か)わる',
    location: '3/12'
  },
  無くす: {
    def: 'to lose',
    ichidan: false,
    kana: 'なくす',
    base_text: '無(な)くす',
    location: '3/13'
  },
  見つける: {
    def: 'to find',
    ichidan: true,
    kana: 'みつける',
    base_text: '見(み)つける',
    location: '3/13'
  },
  見つかる: {
    def: 'to be found',
    ichidan: false,
    kana: 'みつかる',
    base_text: '見(み)つかる',
    location: '3/13'
  },
}

export const getLessonVerbs = (course: string, lesson: string, type: Selection): Verb[] => {
  const verbs: Verb[] = []
  const verbKeys = Object.keys(verbData) as Verb[]
  const courseNum = parseInt(course, 10)
  const lessonNum = parseInt(lesson, 10)

  verbKeys.forEach((key) => {
    const [verbCourseNum, verbLessonNum] = verbData[key].location.split('/').map((num) => parseInt(num, 10))

    let shouldAdd = false
    if (type === 'all') shouldAdd = true
    if (type === 'lesson') shouldAdd = courseNum === verbCourseNum && lessonNum === verbLessonNum
    if (type === 'learned') shouldAdd = courseNum > verbCourseNum || (courseNum === verbCourseNum && lessonNum > verbLessonNum)
    if (type === 'lesson-learned') shouldAdd = courseNum > verbCourseNum || (courseNum === verbCourseNum && lessonNum >= verbLessonNum)

    if (shouldAdd) verbs.push(key)
  })

  return verbs
}

export const getAllVerbs = (): Verb[] => Object.keys(verbData) as Verb[]

export const getVerbData = (verb: Verb) => verbData[verb]

export default verbData
