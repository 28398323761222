import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ToolInput as Input } from '../styles'
import ConnectForm from '../tools/ConnectForm'
import styled from 'styled-components'

import useAPI from 'src/hooks/useAPI'

import RichTextButtons from './RichTextButtons'
import useOutsideAlerter from 'src/hooks/useOutsideAlerter'

interface ToolInputProps {
  value: string
  validationCb?: (arg: string) => boolean | string
  classNames?: string[]
  inputType?: React.HTMLInputTypeAttribute
  inputLabel?: string
  labelColor?: string
  labelPosition?: 'before' | 'after'
  placeholder?: string
  min?: number
  max?: number
  minLength?: number
  maxLength?: number
  title?: string
  width?: string
  removeMargin?: boolean
  required?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  bgColor?: string
  style?: React.CSSProperties
  onClickOut?: () => void
  centerPlaceholder?: boolean
  onEdit?: () => void
}

const ToolInput: React.FC<ToolInputProps> = ({
  value,
  validationCb,
  inputLabel,
  labelColor,
  min,
  max,
  minLength,
  maxLength,
  title,
  labelPosition = 'before',
  placeholder = null,
  classNames = [''],
  inputType = 'text',
  width = '100%',
  removeMargin = false,
  required = false,
  onChange,
  bgColor,
  style,
  onClickOut = () => undefined,
  centerPlaceholder = false,
  onEdit = () => undefined
}) => {
  const customOnChangeRegister = onChange ? { onChange } : {}
  const inputRef = useRef<HTMLInputElement>(null)
  const buttonsRef = useRef<HTMLDivElement>(null)
  const [richTextOptionsVisible, setRichTextOptionsVisible] = useState(false)
  const [textColor, setTextColor] = useState('#ff0000')
  const [highlightColor, setHighlightColor] = useState('#ffff00')
  const [loadingFuriInject, setLoadingFuriInject] = useState(false)
  const [canTryFuriAgain, setCanTryFuriAgain] = useState(true)
  const [resFuri, loading, reqFuri] = useAPI('/widgets/furi/inject', 'LAZYGET')

  const fetchFuriInject = useCallback(() => {
    if (loading || loadingFuriInject) return

    setLoadingFuriInject(true)
    reqFuri({
      params: {
        text: inputRef.current.value,
      }
    })
  }, [loading, loadingFuriInject])

  const addFuriListener = useCallback((e: KeyboardEvent) => {
    // detect ctrl+space key combo to inject furi
    if (!canTryFuriAgain) return
    if (document.activeElement === inputRef.current && e.ctrlKey && e.key === ' ') {
      fetchFuriInject()
    }
  }, [canTryFuriAgain, inputRef])

  const setFuriTryAgain = (e: KeyboardEvent) => {
    if (e.key === ' ') {
      setCanTryFuriAgain(true)
    }
  }

  // initialize keyboard shortcut for furi injection
  useEffect(() => {
    document.addEventListener('keydown', addFuriListener)

    // add listener to set furi able to try again when they release space and control
    document.addEventListener('keyup', setFuriTryAgain)

    // cleanup in case memory leak
    return () => {
      document.removeEventListener('keydown', addFuriListener)
      document.removeEventListener('keyup', setFuriTryAgain)
    }
  }, [])

  useEffect(() => {
    if (!inputRef.current) return
    onEdit()
  }, [inputRef.current?.value])

  useEffect(() => {
    setLoadingFuriInject(false)

    // select entirety of text that in field and replace with resFuri.text, using
    // the API to allow ctrl+Z to work
    if (resFuri?.text) {
      inputRef.current.setSelectionRange(0, inputRef.current.value.length)
      document.execCommand('insertText', false, resFuri.text?.replace('&lt;', '<'))
    }
  }, [resFuri])

  // console.log(onClickOut)

  // useEffect(() => {
  //   console.log(inputRef)
  // }, [inputRef])

  useOutsideAlerter(inputRef, onClickOut)

  const styles = { margin: (inputType !== 'checkbox') ? '0 auto' : '0 0 0 10px', ...style }
  if (centerPlaceholder) styles.textAlign = 'center'

  // console.log(buttonsRef.current)

  return (
    <Input
      color={labelColor}
      width={width}
      removeMargin={removeMargin}
      bgColor={bgColor}
      // on blur, check if we clicked on the rich text buttons; if not, hide them
      onBlur={(e) => {
        // console.log('blur')
        // console.log(buttonsRef.current)
        // console.log(document.activeElement)
        // console.log('ref contains active:', buttonsRef.current?.contains(document.activeElement))
        if (inputType === 'text') {
          // console.log(e)
          if (buttonsRef.current && buttonsRef.current.contains(document.activeElement)) return
          if (e.relatedTarget === buttonsRef.current || e.relatedTarget?.getAttribute('data-action')) return
          setRichTextOptionsVisible(false)
        }
      }}
    >
      {(inputLabel && labelPosition === 'before') && <label htmlFor={value} title={title ?? ''}>{inputLabel}</label>}
      {richTextOptionsVisible && (
        <RichTextButtons
          ref={buttonsRef}
          textAreaRef={inputRef}
          selection={{ start: inputRef.current?.selectionStart, end: inputRef.current?.selectionEnd }}
          textColor={textColor}
          highlightColor={highlightColor}
          setTextColor={setTextColor}
          setHighlightColor={setHighlightColor}
        />
      )}
      <ConnectForm>
        {({ register, unregister }) => {
          const { ref, ...rest } = register(value, {
            validate: validationCb,
            ...customOnChangeRegister
          })

          // nothing like a sick little use effect nested in the middle of a component return yay context
          // needed for validation to be removed when component unmounts
          useEffect(() => {
            return () => {
              if (validationCb) unregister(value)
            }
          }, [])

          return (
            <input
              id={value}
              className={classNames.join(' ')}
              name={value}
              placeholder={placeholder}
              type={inputType}
              required={required}
              spellCheck
              autoCorrect='false'
              autoComplete='false'
              title={title ?? ''}
              min={min}
              max={max}
              minLength={minLength}
              maxLength={maxLength}
              {...rest}
              ref={(e) => {
                ref(e)
                inputRef.current = e
              }}
              style={styles}
              onFocus={() => {
                rest.onFocus?.()
                if (inputType === 'text') setRichTextOptionsVisible(true)
              }}
            />
          )
        }}
      </ConnectForm>
      {(inputLabel && labelPosition === 'after') && <label htmlFor={value} title={title ?? ''}>{inputLabel}</label>}
    </Input>
  )
}

export default ToolInput
