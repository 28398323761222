import { useCallback, useEffect, useReducer, useState } from 'react'
import axios from 'src/api'

export const useValidEmail = () => {
  const [email, setEmail] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  const [errors, setErrors] = useState<string[]>([])

  const checkValid = useCallback(() => {
    if (email.length > 0) {
      const matchesRegex = !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      const doublePeriod = !!email.match(/\.\./)
      setIsValid(matchesRegex && !doublePeriod)
      setErrors([])
      return true
    }

    setIsValid(false)

    // is blank error
    setErrors(['Please enter an email address'])
    return true
  }, [email])

  return [email, setEmail, isValid, errors, checkValid]
}

export const isValidPassword = (password: string) => {
  return !!password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@#$%^&(){}[\]:;<>,.?/~_+\-=|]).{8,32}$/)
}

export const useValidPassword = () => {
  const [password, setPassword] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(true)
  const [errors, setErrors] = useState<string[]>([])

  const checkValid = useCallback(() => {
    if (password.length > 0) {
      const _isValid = isValidPassword(password)
      
      setIsValid(_isValid)
      if (_isValid) setErrors([])

      return _isValid
    }

    setIsValid(false)

    // is blank error
    setErrors(['Please enter a password'])
    return false
  }, [password])

  return [password, setPassword, isValid, errors, checkValid]
}

export const useRegister = () => {
  const [email, setEmail, isEmailValid] = useValidEmail()
  const [password, setPassword, isPasswordValid] = useValidPassword()
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  useEffect(() => {
    setIsFormValid((isEmailValid && isPasswordValid) as boolean)
  }, [isEmailValid, isPasswordValid])

  return [email, setEmail, password, setPassword, isFormValid]
}

export const useEmailTaken = (email: string) => {
  const [isTaken, setIsTaken] = useState<boolean>(false)

  const checkTaken = useCallback(async () => {
    if (email.length > 0) {
      const res = await axios.post('/user/check-availability', { email })
      setIsTaken(!res.data.success)
      return !res.data.success
    }

    setIsTaken(false)
    return false
  }, [email])

  return [isTaken, setIsTaken, checkTaken]
}

export const useYJAvailable = (email: string) => {
  const [isAvailable, setIsAvailable] = useState<boolean>(false)

  const checkAvailable = useCallback(async () => {
    if (email.length > 0) {
      const res = await axios.post('/user/check-availability-yj', { email })
      setIsAvailable(res.data.success)
      return res.data.success
    }

    setIsAvailable(false)
    return false
  }, [email])

  return [isAvailable, setIsAvailable, checkAvailable]
}
