import React, { useEffect, useState } from 'react'

import { Table } from 'semantic-ui-react'

const TablePreview = props => {
    return (
        <Table celled style={{fontFamily: 'Jost', borderRadius: '15px'}}>
            <Table.Header>
                <Table.Row>
                    {props.data.header.map((c, idx) =>
                        <Table.Cell>
                            {c}
                        </Table.Cell>
                    )}
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {props.data.rows.map((r, idxR) =>
                    <Table.Row>
                        {r.map((c, idxC) =>
                            <Table.Cell>
                                {c}
                            </Table.Cell>
                        )}
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    )
}

export default TablePreview