import styled from 'styled-components'
import tw from 'twin.macro'

export const SubTypeSelect = styled.div`
  ${tw`tw-my-2 tw-ml-1`}
  min-width: max-content;

  select {
    ${tw`tw-p-2 tw-rounded-md`}
  }
`

export const ChainLink = styled.span`
  ${tw`tw-absolute`}

  z-index: 0;
  top: 25px;
  left: 15px;
  bottom: -33px;
  min-width: 4px;

  border-right: 4px dashed var(--mid-border);
`

export const LinkItemsButton = styled.button<{ active: boolean, linked: boolean }>`
  ${tw`tw-rounded-lg tw-bg-transparent`}
  z-index: 1;
  min-width: 35px;
  min-height: 35px;

  color: ${({ active }) => active ? 'var(--mid-border)' : 'var(--mid-panel)'};

  :hover {
    color: ${({ active }) => active ? 'var(--mid-border)' : 'var(--mid-panel)'};
    background-color: ${({ active }) => active ? 'var(--mid-panel)' : 'var(--mid-border)'};
  }

  i {
    position: relative;
    bottom: 2px;
  }

  ${({ linked }) => linked && `
    border: 2px dashed var(--mid-border);
  `}
`

export const LineOptions = styled.div`
  ${tw`tw-flex tw-justify-start tw-items-center tw-gap-4`}
`

export const DialogueSpeakerSelector = styled.div`
  ${tw`tw-border tw-shadow-sm tw-rounded-md tw-px-2 tw-bg-slate-200 tw-flex tw-justify-start tw-items-center`}

  label {
    ${tw`tw-mr-1`}
  }
  input {
    ${tw`tw-rounded-md tw-border-l tw-ml-1`}
  }
`
