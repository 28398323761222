import { useEffect, useMemo, useState } from 'react'
import { Widget } from 'src/types'

type UseWidgetHook = [
  string,
  Date,
  (w: Widget) => void,
]

/**
 * useWidgetSaved
 * @returns {UseWidgetHook} The return of this hook has 2 parts in an array:
 * - `updatedAt` the time this widget was updated at, will clear after 5 seconds.
 * - `setUpdatedWidget()` sets the updated widget to pull timestamp from.
 */
const useWidgetSaved = (): UseWidgetHook => {
  const [rawTimestamp, setRawTimestamp] = useState<Date>()
  const [updatedWidget, setUpdatedWidget] = useState<Widget>()

  const updatedAt: string = useMemo(() => {
    if (!updatedWidget) return ''

    const timestamp = updatedWidget?.updatedAt
    setRawTimestamp(updatedWidget?.updatedAt)
    return new Date(timestamp)
      .toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        hourCycle: 'h24',
        minute: '2-digit',
        second: '2-digit',
      })
  }, [updatedWidget])

  useEffect(() => {
    if (!updatedAt) return
    setTimeout(() => {
      setUpdatedWidget(undefined)
      setRawTimestamp(undefined)
    }, 5000)
  }, [updatedAt])

  return [updatedAt, rawTimestamp, setUpdatedWidget]
}

export default useWidgetSaved
