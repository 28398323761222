import React, { useEffect, useState } from 'react'
import { Button, Form, Label } from 'semantic-ui-react'
import axios from '../../../../../api'

import styled from 'styled-components'

const FormStyles = styled.div`
    padding: 15px;
`

const SentenceTranslationWidgetEditor = ({ id }) => {
    const [error, setError] = useState(false)
    const [editSuccess, setEditSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [fields, setFields] = useState({
    //     title: '',
    //     sentences: [
    //     // {
    //         // possible_answers: [],
    //         // target_sentence: "",
    //     // },
    //     ],
    // })
    const [title, setTitle] = useState("")
    const [sentences, setSentences] = useState([])

    useEffect(() => {
        fetchInitialData()
    }, [])

    const fetchInitialData = () => {
        axios.get(`/widgets/${id}`)
        .then((res) => {
            if (res.data.success) {
                const widget = res.data.widget
                setTitle(widget.title)
                setSentences(widget.sentences)
            }
        })
        .catch(err => console.error(err))
    }

    function commitData(e) {
        e.preventDefault()
        const payload = {
            title,
            sentences,
        }

        console.log(payload)

        if (loading) return;
        setLoading(true)
        axios.post(`/widgets/${id}/edit`, payload)
        .then(res => {
            if (res.data?.success) {
                setLoading(false)
                setEditSuccess(true)
                return setTimeout(() => setEditSuccess(false), 5000)
            }
            setError(true)
            setLoading(false)
        })
        .catch(err => {
            console.error(err)
            setError(true)
            setLoading(false)
        })
        setLoading(false)
    }

    function handleChange(e, index) {
        sentences[index] = {
            ...sentences[index],
            [e.target.name]: e.target.value
        }
        
        setSentences([ ...sentences ])
    }

    function addLine() {
        sentences.push({
            possible_answers: "",
            target_sentence: "",
        })

        setSentences([ ...sentences ])
    }

    function removeLine() {
        sentences.pop()
        setSentences([ ...sentences ])
    }

    return (
        <FormStyles>
            {error && <h2>Something went wrong; try again</h2>}
            {editSuccess && <h2>Successfully edited!</h2>}
            <Form onSubmit={e => commitData(e)}>
                <Form.Field>
                    <Label>Exercise Title</Label>
                    <input
                        placeholder='Will be "Translation" if left blank'
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </Form.Field>
                {sentences.map((_, index) => (
                    <>
                    <Form.Field>
                        <Label>Sentence to Translate {index + 1}</Label>
                        <input
                            placeholder='What sentence should they translate?'
                            name="target_sentence"
                            value={sentences[index].target_sentence}
                            onChange={(e) => handleChange(e, index)}
                        />
                    </Form.Field>

                    <Form.Field>
                        <Label>Possible Answers {index + 1}</Label>
                        <textarea
                            placeholder='Enter possible answers, separated by line break'
                            name="possible_answers"
                            rows="3"
                            cols="30"
                            value={sentences[index].possible_answers}
                            onChange={(e) => handleChange(e, index)}
                        />
                    </Form.Field>
                    </>
                ))}

                <Button
                    type='submit'
                    color="blue"
                >
                    Submit
                </Button>
                <Button
                    type='button'
                    color="green"
                    onClick={addLine}
                >
                    Add Line
                </Button>
                <Button
                    type='button'
                    color="red"
                    onClick={removeLine}
                >
                    Remove Line
                </Button>
            </Form>
        </FormStyles>
    )
}

export default SentenceTranslationWidgetEditor