import React, { useEffect, useState } from 'react'
import * as R from 'ramda'

const TextPreview = props => {
    console.log(props.data)
    return (
        <div>
            {props.data.split('\n').map(t => <div>{t}<br/></div>)}
        </div>
    )
}

export default TextPreview