import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Skeleton } from '@mantine/core'

import useStudyMode from 'src/components/fz_courses/hooks/useStudyMode'

type VisibleSpanProps = {
  visible: boolean
}

const VisibleSpan = styled.span<VisibleSpanProps>`
  opacity: ${({ visible }) => visible ? 1 : 0};
  transition: opacity 0.1s linear;
`

const FadeSkeleton = styled(Skeleton)`
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
`

const StudyModePlaceholder = ({ children, placeholder, text, index }) => {
  const [visible, setVisible] = useState(!placeholder)
  const { inStudyMode, hideEnglish, hideOtherLang } = useStudyMode()

  let textKey
  if (!text || typeof text === 'string') textKey = text
  else textKey = text.map((t) => t?.record?._id || t).join('')
  const key = `${hideEnglish}-${textKey}-${hideOtherLang}`

  const mouseLeave = () => {
    setVisible(!placeholder)
  }

  const mouseEnter = () => {
    setVisible(placeholder || true)
  }

  useEffect(() => {
    setVisible(!placeholder)
  }, [inStudyMode, hideEnglish, hideOtherLang])

  return (
    <div
      key={key}
      style={{
        width: placeholder ? '100%' : 'auto',
        height: placeholder ? '100%' : 'auto',
        display: placeholder ? 'inline-block' : 'inline',

        // for debugging
        // backgroundColor: 'red',
        position: 'relative'
      }}
      onMouseOver={mouseEnter}
      onMouseOut={mouseLeave}
    >
      {!visible && (
        <FadeSkeleton
          width={index % 2 === 0 ? '80%' : '60%'}
          height={24}
          radius='xl'
          animate={false}
        />
      )}
      <VisibleSpan
        visible={visible}
        onMouseOut={mouseLeave}
      >
        {children}
      </VisibleSpan>
    </div>
  )
}

export default StudyModePlaceholder
