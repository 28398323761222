import React, { useMemo } from 'react'

import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Button, Header, Image } from 'semantic-ui-react'

import exclamation from '../assets/exclamation.svg'
import exclamation_blue from '../assets/exclamation_blue.svg'
import useMobile from '../hooks/useMobile'

import ask_a_question from '../assets/page_titles/header_ask_a_question.svg'
import ask_a_teacher from '../assets/page_titles/header_ask_a_teacher.svg'
import badges from '../assets/page_titles/header_badges.svg'
import books from '../assets/page_titles/header_books.svg'
import bug_report from '../assets/page_titles/header_bug_report.svg'
import courses from '../assets/page_titles/header_courses.svg'
import faq from '../assets/page_titles/header_faq.svg'
import fromzero from '../assets/page_titles/header_fromzero.svg'
import games from '../assets/page_titles/header_games.svg'
import homeroom from '../assets/page_titles/header_homeroom.svg'
import login from '../assets/page_titles/header_login.svg'
import login_white from '../assets/page_titles/header_login_white.svg'
import my_notebook from '../assets/page_titles/header_my_notebook.svg'
import register from '../assets/page_titles/header_register.svg'
import register_white from '../assets/page_titles/header_register_white.svg'
import report_a_problem from '../assets/page_titles/header_report_a_problem.svg'
import store from '../assets/page_titles/header_store.svg'
import support from '../assets/page_titles/header_support.svg'
import video_vault from '../assets/page_titles/header_video_vault.svg'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: ${(props) => props.centered ? 'center' : 'flex-start'};
  padding: 15px;

  img.fz-title {
    height: 3rem;
  }

  div>img {
    padding: 0;
    min-width: 35px;
    min-height: 35px;
    width: 35px;
    height: 35px;
    display: inline-block;
    margin-left: -3px;
  }

  p {
    padding: 0;
    margin: 0 0 3px;
  }

  .exclamation-subheader {
    margin: 0 0 -4px;
  }

  .fz-title {
    font-size: ${(props) => props.isMobile ? '1.8rem' : '2.4rem'};

    span {
      font-size: ${(props) => props.isMobile ? '2.2rem' : '3rem'};
      vertical-align: baseline;
    }
  }

  .colorized {
    color: ${({ color }) => color === 'blue' ? 'var(--jfz-blue)' : color};
  }

  .ui.header {
    flex-direction: row;
    align-items: baseline;
  }
`

/**
 * @description The reusable From Zero! flavor exclamation mark we've all been waiting for.
 * @param {centered} boolean Whether or not to center the widget.
 * @param {color} String What color the title should be.
 * @param {subcolor} String What color the subtitle should be.
 * @param {title} String Main title for the widget.
 * @param {subheader} String Not bold subheader.
 * @param {subtitle} String Bold subtitle, reduces exclamation size when it's used without a title.
 * @param {style} Object Add styles to the widget wrapper, for positioning and simple modifications.
 * @param {editable} boolean Whether or not to render an edit button that's clickable.
 * @param {onEditClick} function Callback for when the edit button is clicked.
 * @param {noExclam} boolean Whether or not to render the exclamation mark.
 */
const FZExclamation = (props) => {
  const {
    centered,
    color,
    style = {},
    subcolor,
    subheader,
    subtitle,
    title,
    size = 'normal',
    editable,
    onEditClick,
    noExclam,
    lowercase
  } = props
  const isMobile = useMobile()

  const prerenderedTitles = {
    'Ask-A-Question': ask_a_question,
    'Ask-A-Teacher': ask_a_teacher,
    Badges: badges,
    Books: books,
    'Bug Report': bug_report,
    Courses: courses,
    FAQ: faq,
    FromZero: fromzero,
    Games: games,
    Homeroom: homeroom,
    Login: login,
    Login_mobile: login_white,
    'My Notebook': my_notebook,
    Register: register,
    Register_mobile: register_white,
    'Report A Problem': report_a_problem,
    Store: store,
    Support: support,
    'Video Vault': video_vault
  }

  const imageStyles = useMemo(() => {
    let _styles = {}
    if (size === 'big') {
      _styles = {
        width: '50px',
        height: '50px',
        minWidth: '50px',
        minHeight: '50px',
      }
      return _styles
    }

    if (subtitle) {
      _styles = {
        width: '18px',
        height: '18px',
        minWidth: '18px',
        minHeight: '18px',
        paddingBottom: '5px',
        marginRight: '-2px'
      }
    }

    return _styles
  }, [])

  if (title === 'no title') {
    return (
      <img
        style={{
          ...style,
        }}
        draggable={false}
        className="no-select exclamation"
        alt="From Zero! exclamation point"
        src={color === 'blue' ? exclamation_blue : exclamation}
      />
    )
  }
    
  if (Object.prototype.hasOwnProperty.call(prerenderedTitles, title)) {
    return (
      <Wrapper centered={centered} style={{ ...style }} isMobile={isMobile}>
        <img
          className="fz-title no-select"
          alt={prerenderedTitles[title]}
          src={prerenderedTitles[title]}
          draggable={false}
          style={props.mobileImageStyle ? { ...props.mobileImageStyle, width: '100%' } : {}}
        />
      </Wrapper>
    )
  }

  return (
    <Wrapper
      centered={centered}
      className='fz-exclamation'
      isMobile={isMobile}
      style={{ ...style }}
      color={color}
    >
    {title && (
      <Header
        as="h2"
        className="flex-center colorized"
        style={{
          fontFamily: 'Jost Bold',
          textTransform: `${lowercase ? '' : 'uppercase'}`
        }}
      >
        {title.split(' ').map((word, i) => {
          const alpha = /[a-z]|[　-龯0-9０-９？！_…]/i

          return (
            <div key={`${i}-${word}`} className="fz-title no-select">
              {word[0].match(alpha)
                ? <>
                  <span>{word[0]}</span>
                  {word.length > 1 && word.slice(1, word.length)}
                  {i !== title.split(' ').length - 1 && (<>&nbsp;</>)}
                </>
                : <>
                  <span>{word}</span>
                </>
              }
            </div>
          )
        })}
        {!noExclam && (
          <div
            style={subtitle && !title ? {
              width: '22px',
              height: '22px',
            } : { position: 'relative' }}
          >
            <Image
              draggable={false}
              className="no-select"
              alt="From Zero! exclamation point"
              src={color === 'blue' ? exclamation_blue : exclamation}
            />
            {editable && (
              <Button
                icon='edit'
                onClick={onEditClick}
                style={{
                  position: 'absolute',
                  top: '-12px',
                  right: '-36px',
                  zIndex: '1'
                }}
              />
            )}
          </div>
        )}
      </Header>
    )}

    {subheader && (
      <p
        className="exclamation-subheader"
        style={{
          color: subcolor || 'var(--blue-gray)',
          fontWeight: 200,
          fontFamily: 'Jost Thin'
        }}
      >
        {subheader}
      </p>
    )}
    {subtitle
      && <>
        <Image
          draggable={false}
          className="no-select"
          alt="From Zero! exclamation point"
          src={color === 'blue' ? exclamation_blue : exclamation}
          style={imageStyles}
        />
        <p
          style={{
            color: subcolor || 'var(--jfz-blue)',
            fontWeight: 600,
            fontFamily: 'Jost Bold',
            paddingBottom: '5px'
          }}
        >
          {subtitle}
        </p>
      </>
    }
    </Wrapper>
  )
}

export default FZExclamation
