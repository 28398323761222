import React, { useContext, useState, useEffect } from 'react'
import { Icon, Button } from 'semantic-ui-react'

import SoundRenderer from './SoundRenderer'
import useStudyMode from '../hooks/useStudyMode'
import usePlayAll from '../hooks/usePlayAll'
import useMobile from '../../../hooks/useMobile'
import {
  Commentary,
  Example,
  ExamplesGrid,
  ExamplesHeader,
  ExamplesPlayAll,
  DialogueFlex,
  Divider,
  ListSegment,
  PairLine,
  ReadingLabel,
  ReadingRow,
  ReadingWrapper,
  RenderedSound,
  Segment,
  Spacer,
  Speaker,
  Subtitle,
  Title,
  TheRevealer,
  ReadingReveal,
} from './styles/ListWidget.styles'
import WidgetWrapper from 'src/components/error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'

import { AudioContext } from 'src/contexts/AudioContext'

interface ListWidgetProps {
  widget: {
    title: string
    subtype: string
    list: {
      commentary: {
        align: boolean
        content: string
      }
      chained: boolean
      english: string
      other_lang: {
        text: string
      }
      speaker: string
    }[]
    ordered: boolean
    _id: string
    startNum: number
    swapColumnDisplay: boolean
    vertical: boolean
    spaceEvery: number
  }
  fetchCounter?: number
  part?: string
}

// English-Japanese order should be determined in DOM for accessibility
const ExamplesEnglish = ({
  ex, i, current, register, next, autoVertical, swapColumnDisplay, ordered, hideEnglish
}) => {
  return (
    <RenderedSound
      isVerticalGrid={autoVertical}
      swapColumnDisplay={swapColumnDisplay}
      isOrdered={ordered}
      english
    >
      <SoundRenderer
        num={i}
        playingNum={current}
        onSound={register}
        text={ex.english}
        ping={next}
        placeholder={hideEnglish}
        index={i}
        translation={ex.other_lang}
      />
    </RenderedSound>
  )
}

const ListWidget: React.FC<ListWidgetProps> = ({ widget, part }) => {
  const { subtype, list, ordered, _id, swapColumnDisplay, spaceEvery, startNum = 1, vertical } = widget
  const names = ['Tomoko', 'Kenji', 'Natsumi', 'Rika', 'Ren', 'Tanaka', 'Smith', 'Mari']
  const [subtitle, setSubtitle] = useState<string>('')
  const [title, setTitle] = useState<string>(widget.title)
  const [loading, setLoading] = useState<boolean>(!widget)
  const [autoVertical, setAutoVertical] = useState<boolean>(vertical)
  const [isExamples, setIsExamples] = useState<boolean>(false)
  const [isDialogue, setIsDialogue] = useState<boolean>(false)
  const [isReadingComp, setIsReadingComp] = useState<boolean>(false)
  const [showOtherLang, setShowOtherLang] = useState<boolean>(false)
  const [showEnglish, setShowEnglish] = useState<boolean>(false)
  const [numbering, setNumbering] = useState<number[]>([])
  const [hasChaining, setHasChaining] = useState(false)

  const { hideEnglish, hideOtherLang } = useStudyMode()
  const { playAll, play, stop, current, register, next, entries } = usePlayAll()

  const isMobile = useMobile()

  const widgetTitles = {
    ExamplesWidget: 'Examples',
    SentencesWidget: 'Example Sentences',
    DialogueWidget: 'Short Dialogue',
  }

  useEffect(() => {
    if (!widget) return
    let idx = startNum
    let chained = false
    let hasChain = false

    const numbers = widget.list.map((item) => {
      const number = chained ? null : idx

      chained = item.chained
      idx = chained ? idx : idx + 1

      if (item.chained) hasChain = true
      return number
    })
    setHasChaining(hasChain)
    setNumbering(numbers)
  }, [widget])

  useEffect(() => {
    if (subtype === 'ExamplesWidget' || subtype === 'SentencesWidget') {
      setIsExamples(true)
    }
    if (subtype === 'DialogueWidget') {
      setIsDialogue(true)
    }
    if (subtype === 'ReadingComprehensionWidget') {
      setIsReadingComp(true)
    }
    if (title === 'List') setTitle(widgetTitles[subtype])
  }, [subtype])

  useEffect(() => {
    setLoading(!widget)
    if (isMobile) setAutoVertical(true)
    let setAV = false
    list.forEach((s) => {
      if (s.other_lang?.[0]?.text?.length >= 25) setAV = true
    })
    setAutoVertical(setAV)
  }, [])

  if (loading) return <PlaceholderWidget widget_type="ListWidget" />

  const DialoguePlayAll = () => (
    <Button
      style={{ flex: 'none', margin: `1rem 0 ${swapColumnDisplay || !showOtherLang ? '1rem' : 0} 25%` }}
      size={isMobile ? 'tiny' : 'medium'}
      className={current === -1 ? 'fz-play-all' : 'fz-stop-all'}
      onClick={() => play(_id)}
    >
      <Icon name="play" />
      {current === -1 ? 'Play All' : 'Stop All'}
    </Button>
  )

  return (
    <WidgetWrapper widget={{ widgetType: 'ListWidget', widgetId: _id }}>
      {isExamples && (
        <ListSegment isMobile={isMobile} className="fz-dark-font">
          <ExamplesGrid
            isVerticalGrid={autoVertical || vertical}
            isOrdered={ordered}
            className="fz-dark-font"
            isMobile={isMobile}
          >
            {title && (
              <>
                <ExamplesHeader
                  className={isMobile ? 'fz-colored-title' : 'fz-colored-font'}
                  isMobile={isMobile}
                >
                  <SoundRenderer text={title} bold />
                </ExamplesHeader>
                <Spacer isMobile={isMobile} />
              </>
            )}
            {list.map((ex, i, li) => (
              <React.Fragment key={`examples-${i}`}>
                {!spaceEvery && hasChaining && numbering[i] && i !== 0 && (
                  <PairLine key={`spacer-${i}`} />
                )}
                {ordered && <span>{numbering[i] ? `${numbering[i]}.` : ''}</span>}
                {swapColumnDisplay && !autoVertical && (
                  <ExamplesEnglish
                    autoVertical={autoVertical || vertical}
                    swapColumnDisplay={swapColumnDisplay}
                    ordered={ordered}
                    hideEnglish={hideEnglish && (ex.english && ex.other_lang)}
                    current={current}
                    register={register}
                    next={next}
                    ex={ex}
                    i={i}
                  />
                )}
                <RenderedSound isVerticalGrid={autoVertical || vertical} isOrdered={ordered}>
                  <SoundRenderer
                    num={i}
                    playingNum={current}
                    onSound={register}
                    text={ex.other_lang}
                    ping={next}
                    placeholder={hideOtherLang && (ex.english && ex.other_lang)}
                    index={i}
                    wrapAtIcon
                    translation={ex.english}
                  />
                </RenderedSound>
                {(!swapColumnDisplay || autoVertical || vertical) && (
                  <ExamplesEnglish
                    autoVertical={autoVertical || vertical}
                    swapColumnDisplay={swapColumnDisplay}
                    ordered={ordered}
                    hideEnglish={hideEnglish && (ex.english && ex.other_lang)}
                    current={current}
                    register={register}
                    next={next}
                    ex={ex}
                    i={i}
                  />
                )}
                {ex.commentary?.content && (
                  <Commentary
                    isVerticalGrid={autoVertical || vertical}
                    swapColumnDisplay={swapColumnDisplay}
                    isMobile={isMobile}
                    isOrdered={ordered}
                  >
                    <SoundRenderer
                      playingNum={current}
                      num={i}
                      // onSound={register}
                      text={ex.commentary.content}
                      // ping={next}
                    />
                  </Commentary>
                )}
                {spaceEvery > 0 && (i + 1) % spaceEvery === 0 && i !== list.length - 1 && (
                  <Spacer key={`spacer-${i}`} />
                )}
              </React.Fragment>
            ))}
            {playAll && list.length > 1 && (
              <ExamplesPlayAll
                className={current === -1 ? 'fz-play-all' : 'fz-stop-all'}
                onClick={() => play(_id)}
                isVerticalGrid={autoVertical || vertical}
                isOrdered={ordered}
                swapColumnDisplay={swapColumnDisplay}
              >
                <Icon name="play" />
                {current === -1 ? 'Play All' : 'Stop All'}
              </ExamplesPlayAll>)}
          </ExamplesGrid>
        </ListSegment>
      )}
      {isDialogue && (
        <ListSegment isMobile={isMobile}>
          <DialogueFlex isMobile={isMobile}>
            <Title className={isMobile ? 'fz-colored-title' : 'fz-colored-font'}>
              <SoundRenderer text={title} />
            </Title>
            <TheRevealer>
              <Button
                onClick={() => setShowOtherLang(!showOtherLang)}
                className="fz-button"
                icon={showOtherLang ? 'eye slash' : 'eye'}
              />
            </TheRevealer>
          </DialogueFlex>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
          <div>
            <div className="fz-dark-font">
              {list.map((l, idx) => (
                <div style={{ display: 'flex', padding: '14px 0' }} key={idx}>
                  <Speaker>
                    <SoundRenderer text={l.speaker || names[idx]} wrapAtIcon />
                  </Speaker>
                  <Example key={idx} style={{ flexBasis: '75%' }} className="fz-dark-font">
                    <SoundRenderer
                      playingNum={current}
                      onSound={register}
                      ping={next}
                      num={idx}
                      text={swapColumnDisplay ? l.english || l.english[0] : l.other_lang}
                      translation={swapColumnDisplay ? l.other_lang : l.english || l.english[0]}
                    />
                  </Example>
                </div>
              ))}
            </div>
            {playAll && !swapColumnDisplay && list.length > 1 && <DialoguePlayAll />}
            {showOtherLang && (
              <>
                <Divider style={{ margin: '30px auto' }} />
                <div className="fz-dark-font">
                  {list.map((l, idx) => (
                    <div style={{ display: 'flex', padding: '14px 0' }} key={idx}>
                      <Speaker>
                        <SoundRenderer text={l.speaker || names[idx]} wrapAtIcon />
                      </Speaker>
                      <div key={idx} style={{ flexBasis: '75%' }}>
                        <SoundRenderer
                          playingNum={current}
                          onSound={register}
                          ping={next}
                          num={idx}
                          text={swapColumnDisplay ? l.other_lang : l.english || l.english[0]}
                          translation={swapColumnDisplay ? l.english || l.english[0] : l.other_lang}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
            {playAll && swapColumnDisplay && showOtherLang && list.length > 1
              && <DialoguePlayAll />
            }
          </div>
        </ListSegment>
      )}
      {isReadingComp && (
        <Segment isMobile={isMobile} reading={true}>
          {list.map((p, idx) => (
            <ReadingWrapper key={idx}>
              <ReadingRow>
                <ReadingLabel>
                  <p>{(idx + 1).toString().padStart(2, '0')}</p>
                </ReadingLabel>
                <span className="fz-dark-font">
                  {!showEnglish ? (
                    <SoundRenderer
                      playingNum={current}
                      num={idx}
                      onSound={register}
                      ping={next}
                      text={p.other_lang}
                      wrapAtIcon
                      translation={p.english}
                    />
                  ) : (
                    <Example>
                      <SoundRenderer text={p.english} translation={p.other_lang} />
                    </Example>
                  )}
                </span>
              </ReadingRow>
            </ReadingWrapper>
          ))}
          {playAll && list.length > 1 && (
            <ReadingRow>
              {/* placeholder div */}
              <div style={{ height: '24px', width: '24px' }}></div>
              <Button
                style={{ margin: '1rem auto 0 0' }}
                className={current === -1 ? 'fz-play-all' : 'fz-stop-all'}
                onClick={() => play(_id)}
                data-t={`widget:list-subtype:${subtype}-id:${_id}-element:play-all`}
              >
                <Icon name="play" />
                {current === -1 ? 'Play All' : 'Stop All'}
              </Button>
              <ReadingReveal>
                <Button
                  onClick={() => setShowEnglish(!showEnglish)}
                  className="fz-button"
                  icon={showEnglish ? 'eye slash' : 'eye'}
                />
              </ReadingReveal>
            </ReadingRow>
          )}
        </Segment>
      )}
    </WidgetWrapper>
  )
}

export default ListWidget
