import styled from 'styled-components'
import tw from 'twin.macro'

export const MiniQuizItemAnswers = styled.div`
  ${tw`tw-w-full tw-p-4`}

  :last-child {
    ${tw`tw-pb-0`}
  }
`

export const MQItemAnswerRow = styled.div`
  ${tw`tw-w-full tw-flex tw-flex-row tw-items-center tw-justify-center tw-mb-4 tw-mr-4 tw-gap-4`}
`

export const DeleteListItemButton = styled.button<{ leftPad?: boolean }>`
  ${tw`tw-bg-white tw-rounded-full tw-ml--1 tw-border tw-border-slate-300`}
  height: 30px;
  min-width: 30px;

  ${({ leftPad }) => leftPad && tw`tw-ml-1`}

  i {
    color: var(--red);
  }

  :hover {
    ${tw`tw-shadow-md`}
    transform: scale(1.1);
    background-color: var(--red);
    i { color: white; }
  }
`

export const AddCorrectAnswer = styled.button`
  ${tw`tw-bg-white tw-rounded-full tw-ml--1 tw-border tw-border-slate-300`}
  height: 30px;
  min-width: 30px;

  i {
    color: var(--save);
  }

  :hover {
    ${tw`tw-shadow-md`}
    transform: scale(1.1);
    background-color: var(--save);
    i { color: white; }
  }
`

export const HorizontalSpacer = styled.div`
  ${tw`tw-ml--1`}
  min-width: 30px;
`
