import React, { useEffect, useState } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'

import SoundIcon from './SoundIcon'
import { Remark } from 'react-remark'

const DeferredPromise = (props) => {
  const [resolve, setResolve] = useState('')

  useEffect(() => {
    let isMounted = true
    Promise.resolve(props.promise).then((res) => {
      if (isMounted) setResolve(res)
    })

    return () => {
      isMounted = false
    }
  }, [])

  return <>{resolve}</>
}

const recursiveMap = (children, fn, parent, soundKey, md) => {
  if (typeof children === 'string') {
    return <DeferredPromise promise={fn(children, soundKey, md)} />
  }
  if (React.isValidElement(children)) {
    return React.cloneElement(children, {
      children: recursiveMap(
        children.props.children,
        fn,
        children,
        soundKey || children.props.soundKey
      ),
    })
  }
  if (Array.isArray(children)) {
    return children.map((child) => recursiveMap(child, fn, children, soundKey))
  }
}

const mapIndexed = R.addIndex(R.map)

const invalidJapanese = (text) => {
  const englishOnlyRegex = /^\([a-zA-Z0-9_]+\)$/g
  const parenedRegex = /^\(.*\)$/g
  const numbersOnlyRegex = /^[0-9０-９]+$/g
  const periodOnlyRegex = /^[。.]$/g
  const underscoreOnlyRegex = /^_+$/g
  const tripleDotOnlyRegex = /^…$/g

  return (
    numbersOnlyRegex.test(text)
    || tripleDotOnlyRegex.test(text)
    || periodOnlyRegex.test(text)
    || underscoreOnlyRegex.test(text)
    || englishOnlyRegex.test(text)
    || (text.length === 1 && (text.indexOf('「') !== -1 || text.indexOf('」') !== -1))
    || parenedRegex.test(text)
  )
}

// const resultsToMappedData = async (matches, path, widget) => {
//   const mappedData = {}

//   if (matches) {
//     matches.forEach((m) => {
//       const valid = invalidJapanese(m)
//       if (!valid) mappedData[m] = { type: 'JapaneseRecord' }
//     })
//   }

//   const mapRes = await axios.post('/lang_records/inject', {
//     id: '', map: mappedData, path, widget
//   })

//   return mapRes
// }

const SoundInjector = ({ children, ...p }) => {
  // const [recordMap, setRecordMap] = useState({})
  // const recordRef = useRef(recordMap)
  // const [results, setResults] = useState([])

  // useEffect(() => {
  //     setResults(recursiveMap(children, injectSounds(p)))
  // }, [p.refresh])

  // useEffect(() => {
  //     let newRecordMap = {}
  //     Object.keys(recordRef.current).forEach(k => {
  //         recordRef.current[k].filter(r => typeof r === 'object')
  //           .map(r => newRecordMap[r.key] = true)
  //     })
  //     console.log(newRecordMap)
  // }, [results])

  // useEffect(() => {
  //   recordRef.current = recordMap
  //   console.log(recordRef.current)
  // }, [recordMap])

  const soundFound = (soundKey, s) => {
    if (p.onSoundFound) p.onSoundFound(soundKey, s)
  }

  const injectSounds = (props) => async (el, soundKey) => {
    const jpRegex = /(?!_|」|[0-9,])(?:[　-龯0-9０-９？！_…]+|\([　-龯0-9０-９]+\)|\([a-zA-Z0-9]+\)[　-龯0-9０-９？！_…]+|[a-zA-Z0-9 ]+」)+/g
    const jpStartRegex = /^(?![0-9]*\.)(?:[　-龯0-9０-９？！_…]+|\([　-龯0-9０-９]+\))+/

    let matches = el.match(jpRegex)
    let normalText = el.split(jpRegex)

    if (matches) matches = matches.filter((m) => !invalidJapanese(m))

    // prevents more than one of the same sound from having an icon
    const alreadyIconed = {}
    // const foundJ = []

    // console.log(matches)

    if (!matches || matches.length === 0) return el
    
    const jpFirst = jpStartRegex.test(el)

    if (jpFirst) normalText.shift()

    if (props.markdown) normalText = normalText.map((t, i) => <Remark key={i}>{`${t}&nbsp;`}</Remark>)

    // let recordMap

    // create a map of base text => record data
    // if (p.server) {
    //     let res = await resultsToMappedData(matches, p.path, p.widget)
    //     if (res) recordMap = res.data?.injectMap
    // }

    const iconMapped = mapIndexed((e, idx) => {
      const parsedText = e
      if (invalidJapanese(parsedText)) return parsedText
      alreadyIconed[parsedText] = alreadyIconed[parsedText] ? alreadyIconed[parsedText] + 1 : 1

      return (
        <SoundIcon
          key={parsedText}
          bold={p.bold}
          underlineColor={p.underlineColor}
          widget={p.widget}
          onSave={props.onSave}
          icon={alreadyIconed[parsedText] === 1 && !p.noIcon}
          playing={idx === p.playing}
          roles={props.roles}
          text={parsedText}
          onSoundFound={(s) => soundFound(soundKey, s)}
          path={p.path}
          yellow={p.yellow}
          markdown={props.markdown}
          inverted={props.inverted}
          // record={recordRef.current[parsedText]}
        />
      )
    })(matches)

    if (iconMapped.length < normalText.length) iconMapped.push('')

    const r = R.flatten(R.zip(jpFirst ? iconMapped : normalText, jpFirst ? normalText : iconMapped))
    // console.log(r)

    // if (el[0] === '(') {
    //     r.unshift('(')
    // }

    // setRecordMap({...recordRef.current, [el]: r})

    return r
  }

  if (p.playsSound === false || p.plays_sound === false) return <>{children}</>

  return <>{recursiveMap(children, injectSounds(p))}</>
}

const mapStateToProps = (state) => {
  return { roles: state.auth.roles }
}

export default connect(mapStateToProps)(SoundInjector)
