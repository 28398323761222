import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'

import { absoluteCenter } from 'src/assets/styles/globalStyles'

const lightGreen = '#28c6aa'
const lightPurple = '#9296be'

const animate_icon = keyframes`
  0% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.8);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`
const iconAnimation = css`animation: ${animate_icon} 200ms ease-in 0ms 1 normal;`

const hira_active_inactive = ({ embed }) => keyframes`
  from {
    transform: translateX(24px) translateY(-3px);
    box-shadow: -7px 0 13px -4px ${embed ? lightPurple : lightGreen};
    opacity: 1;
  }
  50% {
    transform: translateX(-14px) translateY(-31px);
    box-shadow: 0px 0 4px -4px ${embed ? lightPurple : lightGreen};
  }
  to {
    transform: translateX(-50px) translateY(-12px);
    box-shadow: 7px 0 13px -4px ${embed ? lightPurple : lightGreen};
    opacity: 0.65;
  }
`
const hiraAnimation = ({ view, embed }) => css`
  animation: ${hira_active_inactive(embed)} 200ms ease-in 0ms 1 ${view ? 'normal' : 'reverse'};
`

const kata_active_inactive = ({ embed }) => keyframes`
  from {
    transform: translateX(4px) translateY(-3px);
    box-shadow: 7px 0 13px -4px ${embed ? lightPurple : lightGreen};
    opacity: 1;
  }
  50% {
    transform: translateX(-33px) translateY(18px);
    box-shadow: 0px 0 4px -4px ${embed ? lightPurple : lightGreen};
  }
  to {
    transform: translateX(-71px) translateY(-12px);
    box-shadow: -7px 0 13px -4px ${embed ? lightPurple : lightGreen};
    opacity: 0.65;
  }
`
const kataAnimation = ({ view, embed }) => css`
  animation: ${kata_active_inactive(embed)} 200ms ease-in 0ms 1 ${view ? 'reverse' : 'normal'};
`

const frontView = css`
  font-weight: bold;
  box-shadow: 0 0 8px -2px rgba(255, 255, 255, 1);
`

type AnimatedProps = {
  animating: boolean
  view: number
  embed: boolean
}

const hiraKataCSS = ({ animating, embed }) => css`
  position: absolute;
  width: 32px;
  background-color: ${embed ? lightPurple : lightGreen};
  padding: 8px;
  border-radius: 12px;
  ${animating && css`filter: blur(2px);`}
`

export const AnimatedHira = styled.span<AnimatedProps>`
  ${hiraKataCSS}
  ${({ view }) => !view
    ? css`transform: translateX(24px) translateY(-3px);`
    : css`
      transform: translateX(-50px) translateY(-12px);
      opacity: 0.65;
      z-index: -1;
    `}
  ${({ animating }) => animating && hiraAnimation}
  ${({ view }) => !view && frontView}
`

export const AnimatedKata = styled.span<AnimatedProps>`
  ${hiraKataCSS}
  ${({ view }) => view
    ? css`transform: translateX(4px) translateY(-3px);`
    : css`
      transform: translateX(-71px) translateY(-12px);
      opacity: 0.65;
      z-index: -1;
    `}
  ${({ animating }) => animating && kataAnimation}
  ${({ view }) => view && frontView}
`

interface SwapProps {
  view?: number
  embed?: boolean
  animating?: boolean
  isMobile?: boolean
}

export const ViewSwapStyles = styled.button<SwapProps>`
  top: ${({ embed }) => embed ? -70 : -35}px;
  right: ${({ embed }) => embed ? 50 : 30}px;
  ${({ isMobile }) => isMobile && css`right: 0px;`}
  position: absolute;
  background-color: ${({ embed }) => embed ? lightPurple : lightGreen};
  color: var(--off-white);
  padding: 12px;
  border-radius: 12px;
  width: 50px;

  :hover {
    background-color: ${({ embed }) => embed ? '#abafd9' : '#52d1bb'};
    span {
      background-color: ${({ embed }) => embed ? '#abafd9' : '#52d1bb'};
    }
  }

  .swap {
    ${({ animating }) => animating && iconAnimation}
  }
`
