import CharSelect from './CharSelect'
import React, { useState } from 'react'
import Game from './Game'
import _ from 'lodash'
import GameButtons from '../UI/GameButtons'
import H1 from '../UI/H1'
import Button from '../UI/Button'
import img from '../UI/kana_bkgd.png'
import { createGlobalStyle } from 'styled-components'

const KanaGameBackground = createGlobalStyle`
    body {
      background-image: url(${img});
      background-repeat: repeat;
      background-size: cover;
      background-size: 300px;
      background-position-y: 65px;
      overflow-x: hidden;
    }
`
/* eslint-disable react/prop-types */
const Main = (props) => {
  const [gameArray, setGameArray] = useState([])
  const [isGameRunning, setIsGameRunning] = useState(false)
  const [showPage, setShowPage] = useState(0)

  const gameStarter = (gameData) => {
    const newGameArray = []
    // eslint-disable-next-line
    for (let set in gameData) {
      if (gameData[set].characters) {
        newGameArray.push(...gameData[set].characters)
        setGameArray(_.shuffle(newGameArray))
        setIsGameRunning(!isGameRunning)
      } else {
        setIsGameRunning(!isGameRunning)
        return
      }
    }
  }

  return (
    <>
      <KanaGameBackground />
      {isGameRunning ? (
        <Game gameStarter={gameStarter} gameArray={gameArray} setGameArray={setGameArray} />
      ) : (
        <>
          <H1>Choose the KANA to study!</H1>
          <GameButtons>
            <Button disabled={showPage === 0} onClick={() => setShowPage(0)}>
              Hiragana
            </Button>
            <Button disabled={showPage === 1} onClick={() => setShowPage(1)}>
              Katakana
            </Button>
          </GameButtons>
          <CharSelect
            showPage={showPage}
            hiraChars={props.hiraChars}
            kataChars={props.kataChars}
            // doubleHira={props.doubleHira}
            onCreateArray={gameStarter}
          />
        </>
      )}
    </>
  )
}

export default Main
