import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'

import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { TEXTBOX } from '../toolkit.constants'
import { TextboxWidget } from 'src/types'
import { ToolBody, ToolTextarea } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { IconButton, RichTextButtons, ToolFooter, ToolForm, ToolHeader, ToolInput, ToolOptions, ToolSubheader } from '../parts'
import FormatPreviewShell from 'src/components/fz_courses/sublessonpart/FormatPreviewShell'
import useWidgetSaved from '../hooks/useWidgetSaved'
import useAPI from 'src/hooks/useAPI'

const TextboxTool: React.FC<ToolProps<TextboxWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  // fetchSubLesson,
  fetchWidget: fetchW,
  onGridWidgetSave = () => null,
}) => {
  // console.log(fetchW)
  const [saved, setSaved] = useState(false)
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<TextboxWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const textAreaRef = useRef<HTMLTextAreaElement>(null)
  const [loadingFuriInject, setLoadingFuriInject] = useState(false)
  const [canTryFuriAgain, setCanTryFuriAgain] = useState(true)
  const [resFuri, loading, reqFuri] = useAPI('/widgets/furi/inject', 'LAZYGET')
  const { ref, ...rest } = hookFormMethods.register('body')

  // for rich text buttons
  const [textColor, setTextColor] = useState('#ff0000')
  const [highlightColor, setHighlightColor] = useState('#ffff00')

  // increment this on every update to refresh the preview widget
  const [refresher, setRefresher] = useState(0)
  const [previewOpen, setPreviewOpen] = useState(false)

  useEffect(() => {
    if (!textAreaRef.current) return
    setRefresher((prev) => prev + 1)
  }, [textAreaRef.current?.value])

  useEffect(() => {
    setLoadingFuriInject(false)
    // update react hook form body with resFuri's response
    if (resFuri?.text) {
      hookFormMethods.setValue('body', resFuri.text?.replace(/&lt;/g, '<'))
    }
  }, [resFuri])

  const fetchFuriInject = useCallback(() => {
    if (loading || loadingFuriInject) return

    setLoadingFuriInject(true)
    reqFuri({
      params: {
        // get text from 'body' field of react-hook-form
        text: hookFormMethods.getValues().body
      }
    })
  }, [loading, loadingFuriInject])

  const addFuriListener = useCallback((e: KeyboardEvent) => {
    // detect ctrl+space key combo to inject furi
    if (!canTryFuriAgain) return
    if (document.activeElement === textAreaRef.current && e.ctrlKey && e.key === ' ') {
      fetchFuriInject()
    }
  }, [canTryFuriAgain, textAreaRef])

  const setFuriTryAgain = (e: KeyboardEvent) => {
    if (e.key === ' ') {
      setCanTryFuriAgain(true)
    }
  }

  // initialize keyboard shortcut for furi injection
  useEffect(() => {
    document.addEventListener('keydown', addFuriListener)

    // add listener to set furi able to try again when they release space and control
    document.addEventListener('keyup', setFuriTryAgain)

    // cleanup in case memory leak
    return () => {
      document.removeEventListener('keydown', addFuriListener)
      document.removeEventListener('keyup', setFuriTryAgain)
    }
  }, [])

  const handleSaveWidget = async () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    await editWidget({ ...values, course }).then(() => fetchWidget())
    setSaved(true)
    // fetchWidget()
    fetchW(id)
    // fetchSubLesson()
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (isOpen && widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setWidgetValues(widget)
    }
  }, [widget])

  useEffect(() => {
    if (widget && saved) {
      if (isOpen) {
        setUpdatedWidget(widget)
      }

      setTimeout(() => {
        setSaved(false)
      }, 1000)
    }
  }, [saved])

  const fieldLabels = {
    romajiOnly: 'Romaji Only',
    plays_sound: 'Play Sound',
    indent: 'Indent',
    title: 'Title',
    subtitle: 'Subtitle',
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={TEXTBOX}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='textbox-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={TEXTBOX}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              {['romajiOnly', 'plays_sound', 'indent'].map((inp, idx) => (
                <ToolInput
                  key={idx}
                  value={inp}
                  inputType='checkbox'
                  inputLabel={fieldLabels[inp]}
                  labelColor='var(--blue-gray)'
                  labelPosition='after'
                />
              ))}
            </div>
          </ToolOptions>
          <ToolSubheader>
            {['title', 'subtitle'].map((inp, idx) => (
              <ToolInput
                key={idx}
                value={inp}
                placeholder={fieldLabels[inp]}
                labelColor='var(--off-white)'
                labelPosition='before'
                inputLabel={fieldLabels[inp]}
              />
            ))}
          </ToolSubheader>
          <RichTextButtons
            textAreaRef={textAreaRef}
            textColor={textColor}
            setTextColor={setTextColor}
            highlightColor={highlightColor}
            setHighlightColor={setHighlightColor}
          />
          <ToolBody>
            <ToolTextarea>
              <textarea
                id='body'
                name='body'
                placeholder='Main content'
                spellCheck
                autoComplete='false'
                rows={10}
                {...hookFormMethods.register('body')}
                ref={(e) => {
                  ref(e)
                  textAreaRef.current = e
                }}
              />
            </ToolTextarea>
          </ToolBody>

          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          >
            {/* eyeball icon button that will open the widget render preview */}
            <div>
              <IconButton
                icon='eye'
                backgroundColor='transparent'
                contrastColor='white'
                title='Preview'
                handleClick={() => setPreviewOpen((p) => !p)}
              />
            </div>
          </ToolFooter>
          {previewOpen && (
            <FormatPreviewShell
              widget={{ ...hookFormMethods.getValues(), id }}
              type='text'
              refresher={refresher}
              course={course}
            />
          )}
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default TextboxTool
