import styled from 'styled-components'

interface PaletteStyleProps {
  willOpen: boolean
}

const PaletteBody = styled.div<PaletteStyleProps>`
  position: absolute;
  z-index: 4;
  top: 0;
  bottom: 0;
  right: 0;
  width: 400px;
  height: 100vh;
  ${(props) => props.willOpen
    ? 'display: none; visibility: hidden; height: 0;'
    : 'display: block; visibility: visible; height: 100vh;'
    // eslint-disable-next-line
  }

  >div {
    width: 100%;
    height: 100%;
    border-left: 1px solid rgba(55, 101, 176, 0.5);
    box-shadow: 0 0 12px 4px rgba(55, 101, 176, 0.1);
    background-color: white;
  }
`

const Palette = styled.div`
  height: 100vh;
  padding: 20px;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    text-align: center;
  }
`

const AddWidgetButton = styled.button`
  text-transform: capitalize;
  border: 2px solid var(--add);
  background-color: white;
  color: var(--blue-gray);
  padding: 4px 8px;
  border-radius: 8px;
  margin: 2rem auto;

  :hover {
    border-color: var(--save);
    background-color: var(--save);
    color: white;
  }
`

const WidgetGroup = styled.div`
  margin-bottom: 20px;

  h3 {
    text-align: center;
    padding-bottom: 8px;
    border-bottom: 1px solid transparent;
    cursor: pointer;

    :hover {
      border-bottom: 1px solid var(--blue-gray);
    }
  }
`

const WidgetCardDeck = styled.div`
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: minmax(100px, 100px);
  gap: 10px;
  width: 100%;
`

interface WidgetCard {
  selected: boolean
  visible: boolean
}

const WidgetCard = styled.div<WidgetCard>`
  width: 100px;
  height: 100px;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  border: 1px solid var(--blue-gray);
  border-radius: 8px;
  text-transform: capitalize;

  cursor: pointer;

  transition: all 0.15s ease-out;

  font-weight: ${(props) => props.selected && 'bold'};
  display: ${(props) => props.visible ? 'flex' : 'none'};
  visibility: ${(props) => props.visible ? 'visible' : 'hidden'};

  p {
    padding: 8px;
    text-align: center;
    font-size: 0.8rem;
  }

  :hover {
    background-color: #dde;
    font-weight: bold;
    color: var(--blue-gray);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
  }

  background-color: ${(props) => props.selected && 'var(--jfz-blue)'} !important;
  color: ${(props) => props.selected && 'var(--off-white)'} !important;
`

export {
  AddWidgetButton,
  Palette,
  PaletteBody,
  WidgetCard,
  WidgetCardDeck,
  WidgetGroup,
}
