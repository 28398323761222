import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation, useRouteMatch } from 'react-router-dom'
import { RootState } from 'src/configureStore'

import useMobile from 'src/hooks/useMobile'

import { ChevronDownSolid, ChevronUpSolid } from '@graywolfai/react-heroicons'

interface FZLogoProps {
  backgroundColor: string
  isCourseRoute: boolean
  drawerOpen: boolean
  setDrawerOpen: (arg) => void
}

const FZLogo: React.FC<FZLogoProps> = ({
  backgroundColor,
  isCourseRoute,
  drawerOpen,
  setDrawerOpen
}) => {
  const isSignedIn = useSelector((state: RootState) => state.auth?.isSignedIn)
  const location = useLocation()
  const isMobile = useMobile()
  
  const courseOverview = useRouteMatch('/courses/:id')
  const lessonOverview = useRouteMatch('/courses/:id/:lid')
  const sublessonPage = useRouteMatch('/courses/:id/:lid/:sid')

  // whether or not to show the logo in mobile
  const shouldDisplay: boolean = useMemo(() => {
    if (!isMobile) return true
    if (lessonOverview || sublessonPage) return false
    const root = location.pathname.split('/').filter((p) => p)[0]
    if (isMobile && ['register'].includes(root)) return false
    return true
  }, [location.pathname, isMobile])

  const linksTo = useMemo(() => {
    const route = isSignedIn ? '/home' : '/'
    return route === location.pathname ? '#' : route
  }, [location.pathname])

  const handleCourseClick = () => {
    if (isCourseRoute) setDrawerOpen((prev) => !prev)
  }

  return (
    <>
      {shouldDisplay && (
        <div
          className='fz-logo'
          title={isCourseRoute ? 'Open navigation drawer' : ''}
          onClick={() => handleCourseClick()}
        >
          {isCourseRoute ? (
            <span>
              <img
                src={backgroundColor?.indexOf('blue') !== -1
                  ? 'https://d32tv5vz8ua1sf.cloudfront.net/images/branding/fromzero_logo_white.png'
                  : 'https://d32tv5vz8ua1sf.cloudfront.net/images/branding/fromzero_logo.png'
                }
                alt='From Zero'
                draggable='false'
                className='no-select'
              />
              <span className="chevron">
              {
                drawerOpen
                  ? <ChevronUpSolid height='20px' className={`tw-inline${backgroundColor?.indexOf('blue') !== -1 ? ' white' : ' blue'}`} />
                  : <ChevronDownSolid height='20px' className={`tw-inline${backgroundColor?.indexOf('blue') !== -1 ? ' white' : ' blue'}`} />
              }
              </span>
            </span>
          ) : (
            <Link to={linksTo}>
              <img
                src={backgroundColor?.indexOf('blue') !== -1
                  ? 'https://d32tv5vz8ua1sf.cloudfront.net/images/branding/fromzero_logo_white.png'
                  : 'https://d32tv5vz8ua1sf.cloudfront.net/images/branding/fromzero_logo.png'
                }
                alt='From Zero'
                draggable='false'
                className='no-select'
              />
            </Link>
          )}
        </div>
      )}
    </>
  )
}

export default FZLogo
