import React, { useEffect, useState } from 'react'
import { Button, Form, Label } from 'semantic-ui-react'
import axios from '../../../../../api'

import styled from 'styled-components'

const FormStyles = styled.div`
    padding: 15px;
`

const FillInTheBlanksWidgetEditor = ({ id }) => {
    const [error, setError] = useState(false)
    const [editSuccess, setEditSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        title: '',
        blanks: [
            {
                prompt: '',
                target_sentence: '',
                other_lang_translation: '',
            },
        ]
    })

    useEffect(() => {
        if (data.title) return
        fetchInitialData()
    }, [])

    const fetchInitialData = () => {
        axios.get(`/widgets/${id}`)
        .then((res) => {
            if (res.data.success) {
                const widget = res.data.widget
                console.log(widget)
                setData({
                    title: widget.title,
                    blanks: widget.blanks,
                })
            }
        })
        .catch(err => console.error(err))
    }

    function commitData(e) {
        e.preventDefault()
        const payload = { ...data }

        console.log(payload)

        if (loading) return;
        setLoading(true)
        axios.post(`/widgets/${id}/edit`, payload)
        .then(res => {
            if (res.data?.success) {
                setLoading(false)
                setEditSuccess(true)
                return setTimeout(() => setEditSuccess(false), 5000)
            }
            setError(true)
            setLoading(false)
        })
        .catch(err => {
            console.error(err)
            setError(true)
            setLoading(false)
        })
        setLoading(false)
    }
    // 彼女は_し、_し、親切です。
    // She is smart, pretty, and kind.

    function handleChange(e, idx) {
        const field = e.target.name
        const val = e.target.value

        const updatedBlanks = [...data.blanks]
        updatedBlanks[idx] = { ...updatedBlanks[idx], [field]: val }

        setData({
            ...data,
            blanks: updatedBlanks,
        })
        
        console.log(data)
    }

    function handleAddLine() {
        setData({
            ...data,
            blanks: [
                ...data.blanks,
                {
                    prompt: '',
                    target_sentence: '',
                    other_lang_translation: '',
                }
            ]
        })
    }

    function removeLine() {
        data.blanks.pop()
        setData({ ...data })
    }

    return (
        <FormStyles>
            {error && <h2>Something went wrong; try again</h2>}
            {editSuccess && <h2>Successfully edited!</h2>}
            <Form onSubmit={e => commitData(e)}>
                <Form.Field>
                <Label>Exercise Title</Label>
                <input
                    placeholder='Will be "Fill in the Blanks" if left blank'
                    name="title"
                    value={data.title}
                    onChange={e => setData({ ...data, title: e.target.value })}
                />
                </Form.Field>
                
                {data.blanks && data.blanks.map((_, idx) => (
                    <div style={{
                        border: "2px solid var(--jfz-blue)",
                        borderRadius: "5px",
                        padding: "10px",
                        margin: "10px 0",
                    }}>
                        <h3>Group {idx + 1}</h3>
                        <Form.Field>
                            <Label>Prompt {idx + 1}</Label>
                            <input
                                placeholder="Optional prompt"
                                name="prompt"
                                value={data.blanks[idx].prompt}
                                onChange={e => handleChange(e, idx)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Label>Target Sentence {idx + 1}</Label>
                            <input
                                placeholder="Use underscores to signify user input fields"
                                name="target_sentence"
                                value={data.blanks[idx].target_sentence}
                                onChange={e => handleChange(e, idx)}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Label>Other Language Sentence {idx + 1}</Label>
                            <input
                                placeholder="Enter the translation"
                                name="other_lang_translation"
                                value={data.blanks[idx].other_lang_translation}
                                onChange={e => handleChange(e, idx)}
                            />
                        </Form.Field>
                    </div>
                ))}
                <Button color="green" type="submit">Submit</Button>
                <Button
                    color="blue"
                    type="button"
                    onClick={handleAddLine}
                >
                    Add Line
                </Button>
                <Button
                    type="button"
                    color="red"
                    onClick={removeLine}
                >
                    Remove Line
                </Button>
            </Form>
        </FormStyles>
    )
}

export default FillInTheBlanksWidgetEditor