import React, { useEffect, useState } from 'react'
import * as R from 'ramda'

import {Header} from 'semantic-ui-react'

const TextListPreview = props => {
    return (
        <div>
            {props.data.map(wg => 
                <div>
                    <Header style={{color: 'white', backgroundColor: 'blue'}}>{wg.title}</Header>
                    {wg.contents.map(c =>
                        <div style={{padding: '10px'}}>
                            {c.other_lang}: {c.english}
                        </div>)}
                </div>)}
        </div>
    )
}

export default TextListPreview