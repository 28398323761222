import React from 'react'

import FZErrorBoundary, { Widget } from './FZErrorBoundary'

interface WidgetWrapperProps {
  widget?: Widget
}

const WidgetWrapper: React.FC<WidgetWrapperProps> = (props) => {
  return (
    <FZErrorBoundary widget={props.widget}>
      {props.children}
    </FZErrorBoundary>
  )
}

export default WidgetWrapper
