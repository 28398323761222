import React, { useEffect, useMemo, useState } from 'react'
import axios from 'src/api'

import { Button, Image, Form, Checkbox, Label } from 'semantic-ui-react'

const ImageWidgetEditor = (props) => {
  const { id, course, lesson, sublesson, embed } = props
  const [uploadFile, setUploadFile] = useState([])
  const [path, setPath] = useState('')
  const [title, setTitle] = useState('')
  const [caption, setCaption] = useState('')
  const [oversized, setOversized] = useState()
  const [showBorder, setShowBorder] = useState()
  const [cfPath, setCfPath] = useState('')
  const [titleDidChange, setTitleDidChange] = useState(false)
  const [captionDidChange, setCaptionDidChange] = useState(false)
  const [noUpload, setNoUpload] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [settingVideoPath, setSettingVideoPath] = useState(false)
  const [kana, setKana] = useState('')
  // const [randomID, setRandomID] = useState(Math.random().toString(36).substring(7))

  const randomID = useMemo(() => Math.random().toString(36).substring(7), [])
  const randomID2 = useMemo(() => Math.random().toString(36).substring(7), [])
  const randomID3 = useMemo(() => Math.random().toString(36).substring(7), [])
  const randomID4 = useMemo(() => Math.random().toString(36).substring(7), [])

  useEffect(() => {
    axios.get(`/widgets/${id}`).then(({ data: { widget } }) => {
      setPath(widget.path)
      setCaption(widget.caption)
      setOversized(widget.isOversized)
      setShowBorder(widget.showBorder)
      setTitle(widget.title)
      setKana(widget.video_path)
    })
  }, [id])

  const getCfPath = async () => {
    await axios
      .post('/user/signed', { url: path })
      .then((res) => setCfPath(res.data.url))
      .catch((err) => console.error(err))
  }

  const saveImage = async () => {
    const video_path = `courses/writing-guides/${kana}.mov`
    const payload = { path, caption, title, video_path }
    if (oversized) payload.isOversized = oversized
    if (showBorder) payload.showBorder = showBorder
    if (kana) payload.video_path = video_path
    if (captionDidChange && caption) payload.caption = caption.trim()
    if (titleDidChange && title) payload.title = title.trim()

    await axios
      .post(`/widgets/${id}/edit`, payload)
      .then(() => getCfPath())
      .catch((err) => console.error(err))
  }

  // useEffect(() => {
  //   if (path) saveImage()
  // }, [path])

  const fileChange = (event) => {
    setUploadFile(event.target.files[0])
  }

  const handlePath = (event) => {
    setPath(event.target.value)
  }

  const handleVideoPath = (event) => {
    setKana(event.target.value)
  }

  const handleTitleChange = (e) => {
    setTitleDidChange(true)
    setTitle(e.target.value)
  }
  const handleCaptionChange = (e) => {
    setCaptionDidChange(true)
    setCaption(e.target.value)
  }

  const onFormSubmit = () => {
    setUploading(true)

    if (!course || !lesson || !sublesson) {
      // console.log(`ERROR: ${course} ${lesson} ${sublesson}`)
      return false
    }

    const data = new FormData()

    const _path = `${course}/${lesson}/${sublesson}${embed ? `/${embed}` : ''}`
    data.append('location', `courses/images/${_path}`)
    data.append('image', uploadFile, uploadFile.name)
    if (captionDidChange && caption?.length) data.append('caption', caption.trim())
    data.append('isOversized', oversized)
    data.append('showBorder', showBorder)
    // data.append('video_path', video_path)

    axios
      .post('/images', data, { headers: { 'Content-Type': 'multipart/form-data;' } })
      .then((res) => {
        if (res.data.success) {
          setPath(res.data.path)
          setCaption(res.data.caption)
          setOversized(res.data.isOversized)
          setShowBorder(res.data.showBorder)
          setKana(res.data.video_path)
        }
        setUploading(false)
      })
  }

  return (
    <div>
      {cfPath && <Image centered src={cfPath} />}
      {path}
      <Form onSubmit={onFormSubmit}>
        <fieldset disabled={uploading} aria-busy={uploading} style={{ border: 'none' }}>
          <Form.Field>
            <Button as="label" htmlFor={randomID} type="button">
              Choose file...
            </Button>
            <input type="file" id={randomID} hidden onChange={fileChange} />
            {!noUpload && <Button as="label" htmlFor={randomID2} type="button" onClick={() => setNoUpload(true)}>
              Use S3 path...
            </Button>}
            {noUpload && <input type="text" value={path} id={randomID2} onChange={handlePath} />}
            {!settingVideoPath && <Button as="label" htmlFor={randomID2} type="button" onClick={() => setSettingVideoPath(true)}>
              Writing Guide?
            </Button>}
            {settingVideoPath && <input type="text" placeholder="hiargana/A" value={kana} id={randomID2} onChange={handleVideoPath} />}
          </Form.Field>
          <Form.Field>
            <Label htmlFor={randomID3}>任意 Title</Label>
            <input
              type="text"
              name="title"
              id={randomID3}
              placeholder="Enter a title for this image... (Optional)"
              value={title}
              autoComplete="off"
              onChange={(e) => handleTitleChange(e)}
            />
          </Form.Field>
          <Form.Field>
            <Label htmlFor={randomID4}>任意 Caption</Label>
            <input
              type="text"
              name="caption"
              id={randomID4}
              placeholder="Enter a caption for this image... (Optional)"
              value={caption}
              autoComplete="off"
              onChange={(e) => handleCaptionChange(e)}
            />
          </Form.Field>
          <Form.Field
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Label htmlFor="oversize">Oversized</Label>
            <Checkbox
              name="oversize"
              checked={oversized}
              onChange={() => setOversized(!oversized)}
            />
          </Form.Field>
          <Form.Field
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Label htmlFor="showBorder">Show Border?</Label>
            <Checkbox
              name="showBorder"
              checked={showBorder}
              onChange={() => setShowBorder(!showBorder)}
            />
          </Form.Field>
          {uploadFile && <p>File selected: {uploadFile.name}</p>}
          <Button loading={uploading} aria-busy={uploading} disabled={uploading} type="submit">
            Upload
          </Button>
          <Button type="button" onClick={saveImage}>
            Update
          </Button>
        </fieldset>
      </Form>
    </div>
  )
}

export default ImageWidgetEditor
