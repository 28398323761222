import { useEffect, useState } from 'react'

const useMobile = (width = 768): boolean => {
  const [mobile, setMobile] = useState(window.innerWidth < width)

  // const lockOrientation = () => {
  //     const de = document.documentElement

  //     if (de.requestFullscreen) { de.requestFullscreen() }
  //     else if (de.mozRequestFullscreen) { de.mozRequestFullscreen() }
  //     else if (de.webkitRequestFullscreen) { de.webkitRequestFullscreen() }
  //     else if (de.msRequestFullscreen) { de.msRequestFullscreen() }

  //     window.screen.orientation.lock('portrait')
  // }

  const handleResize = (): void => {
    // iPad has changed its userAgent to "Macintosh" from iOS 13 (same as MacOS),
    // so to check if user is on an iPad we now need to do this
    const iPad = navigator.userAgent.toLowerCase().indexOf('macintosh') > -1
      && navigator.maxTouchPoints
      && navigator.maxTouchPoints > 2

    if (iPad
      || navigator.userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile/i)
    ) {
      // lockOrientation()
      setMobile(true)
      return
    }

    const _orn: string = window.screen['msOrientation']
      || (window.screen.orientation
      || window.screen['mozOrientation']).type
      || null

    // gives us the true dimensions of the screen, e.g.,
    // 1.25 (my pc's default zoom level)
    // * 3072 (computed window width after pc zoom) = 3840 (my actual monitor resolution)
    // const actualDisplayWidth = window.devicePixelRatio * window.innerWidth
    // const actualDisplayHeight = window.devicePixelRatio * window.innerHeight

    if (_orn === 'portrait-primary' || _orn === 'portrait-secondary') {
      setMobile(true)
      return
    }

    setMobile(window.innerWidth < width)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return mobile
}

export default useMobile
