import black1 from '../assets/chibi/default_avatars/default_black_1.svg'
import black2 from '../assets/chibi/default_avatars/default_black_2.svg'
import black3 from '../assets/chibi/default_avatars/default_black_3.svg'
import blue1 from '../assets/chibi/default_avatars/default_blue_1.svg'
import blue2 from '../assets/chibi/default_avatars/default_blue_2.svg'
import blue3 from '../assets/chibi/default_avatars/default_blue_3.svg'
import brown1 from '../assets/chibi/default_avatars/default_brown_1.svg'
import brown2 from '../assets/chibi/default_avatars/default_brown_2.svg'
import brown3 from '../assets/chibi/default_avatars/default_brown_3.svg'
import green1 from '../assets/chibi/default_avatars/default_green_1.svg'
import green2 from '../assets/chibi/default_avatars/default_green_2.svg'
import green3 from '../assets/chibi/default_avatars/default_green_3.svg'
import orange1 from '../assets/chibi/default_avatars/default_orange_1.svg'
import orange2 from '../assets/chibi/default_avatars/default_orange_2.svg'
import orange3 from '../assets/chibi/default_avatars/default_orange_3.svg'
import pink1 from '../assets/chibi/default_avatars/default_pink_1.svg'
import pink2 from '../assets/chibi/default_avatars/default_pink_2.svg'
import pink3 from '../assets/chibi/default_avatars/default_pink_3.svg'
import purple1 from '../assets/chibi/default_avatars/default_purple_1.svg'
import purple2 from '../assets/chibi/default_avatars/default_purple_2.svg'
import purple3 from '../assets/chibi/default_avatars/default_purple_3.svg'
import red1 from '../assets/chibi/default_avatars/default_red_1.svg'
import red2 from '../assets/chibi/default_avatars/default_red_2.svg'
import red3 from '../assets/chibi/default_avatars/default_red_3.svg'
import teal1 from '../assets/chibi/default_avatars/default_teal_1.svg'
import teal2 from '../assets/chibi/default_avatars/default_teal_2.svg'
import teal3 from '../assets/chibi/default_avatars/default_teal_3.svg'
import white1 from '../assets/chibi/default_avatars/default_white_1.svg'
import white2 from '../assets/chibi/default_avatars/default_white_2.svg'
import white3 from '../assets/chibi/default_avatars/default_white_3.svg'
import yellow1 from '../assets/chibi/default_avatars/default_yellow_1.svg'
import yellow2 from '../assets/chibi/default_avatars/default_yellow_2.svg'
import yellow3 from '../assets/chibi/default_avatars/default_yellow_3.svg'

const black = '#434343'
const blue = '#61a1e2'
const brown = '#cf977e'
const green = '#8ccc87'
const orange = '#ec9c75'
const pink = '#d4839e'
const purple = '#ae99ea'
const red = '#d36b6b'
const teal = '#58bfaf'
const white = '#fbfbfe'
const yellow = '#ffe56a'

interface AvatarColor {
  icon: string
  bg_color: string
}

interface AvatarColors {
  [key: string]: AvatarColor
}

export const avatar_colors: AvatarColors = {
  black1: { icon: black1, bg_color: black },
  black2: { icon: black2, bg_color: black },
  black3: { icon: black3, bg_color: black },
  blue1: { icon: blue1, bg_color: blue },
  blue2: { icon: blue2, bg_color: blue },
  blue3: { icon: blue3, bg_color: blue },
  brown1: { icon: brown1, bg_color: brown },
  brown2: { icon: brown2, bg_color: brown },
  brown3: { icon: brown3, bg_color: brown },
  green1: { icon: green1, bg_color: green },
  green2: { icon: green2, bg_color: green },
  green3: { icon: green3, bg_color: green },
  orange1: { icon: orange1, bg_color: orange },
  orange2: { icon: orange2, bg_color: orange },
  orange3: { icon: orange3, bg_color: orange },
  pink1: { icon: pink1, bg_color: pink },
  pink2: { icon: pink2, bg_color: pink },
  pink3: { icon: pink3, bg_color: pink },
  purple1: { icon: purple1, bg_color: purple },
  purple2: { icon: purple2, bg_color: purple },
  purple3: { icon: purple3, bg_color: purple },
  red1: { icon: red1, bg_color: red },
  red2: { icon: red2, bg_color: red },
  red3: { icon: red3, bg_color: red },
  teal1: { icon: teal1, bg_color: teal },
  teal2: { icon: teal2, bg_color: teal },
  teal3: { icon: teal3, bg_color: teal },
  white1: { icon: white1, bg_color: white },
  white2: { icon: white2, bg_color: white },
  white3: { icon: white3, bg_color: white },
  yellow1: { icon: yellow1, bg_color: yellow },
  yellow2: { icon: yellow2, bg_color: yellow },
  yellow3: { icon: yellow3, bg_color: yellow },
}

const randomColor = (): string => {
  const possible_colors = [
    'black',
    'blue',
    'brown',
    'green',
    'orange',
    'pink',
    'purple',
    'red',
    'teal',
    'white',
    'yellow',
  ]
  const random_number = Math.round(Math.random() * 10)
  return possible_colors[random_number]
}

const defaultAvatar = (
  color: string = randomColor(),
  number = 1,
): AvatarColor => avatar_colors[`${color}${number}`]

export default defaultAvatar
