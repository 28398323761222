import { useEffect, useState } from 'react'

const possibleIcons = ['💕', '💖', '💯', '🎵', '✔️', '✨', '⭐', '🌟', '💫', '🔥', '😊', '🤩', '😍', '🥳', '😻', '🙌', '💪']

const AffiliateThankYou = ({ affiliate }) => {
  const [chosenIcon, setChosenIcon] = useState('')

  useEffect(() => {
    setChosenIcon(possibleIcons[Math.floor(Math.random() * possibleIcons.length)])
  }, [])

  if (!affiliate) return <></>
  return <p style={{ textAlign: 'center' }}>{chosenIcon}Thank you for your support!{chosenIcon}</p>
}

export default AffiliateThankYou
