import styled from '@emotion/styled'
import { css } from '@emotion/react'

const ImageGridRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  gap: 2px;
  overflow: hidden;
  ${({ roundCorners }) => roundCorners && css`border-radius: 10px 10px 0 0;`}
`

export default ImageGridRow
