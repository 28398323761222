import styled from 'styled-components'

const Card = styled.div`
  position: relative;
  display: flex;
  /* box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.25); */
  width: 100%;
  max-width: 30rem;
  background-color: #f9f3ec;
  margin: auto;
  flex-wrap: wrap;
  padding: 0.5rem;
  justify-content: space-evenly;
  border-radius: 13px;
  border: 4px solid #847d72;
  box-shadow: 0 0 0 3pt #1d191b;


  .checkbox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: flex-start;
    margin: 0.5rem 0;
    padding: 0.75rem 0;
    color: white;
    min-width: 70px;

    @media (max-width: 740px) {
      min-width: 60px;
      flex: 1 3 5%;
      padding: 0.3rem;
    }
  }

  .chars {
    font-size: 15px;
    align-self: center;
    padding-top: 1rem;
    cursor: default;
    color: #5c4d4a;
    writing-mode: vertical-lr;
    word-wrap: break-word;
    max-height: 95px;
    margin: 0;

    h3 { font-weight: bold; }
  }
  /* #toggle:checked {
    background-color: yellow;
  } */

  & label {
    align-self: center;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 0 0 2pt #1d191b;
    background-color: #1d191b;
    border-radius: 10px;
    font-weight: 600;
    color: white;
    padding: 0.5rem;
    width: 40px;
    text-align: center;
    user-select: none;
  }

  @media (max-width: 740px) {
      width: 85% !important;
      padding: 0.25rem;
      flex-shrink: 0.75;
    }
  & input {
    appearance: none;
  }
`

export default Card
