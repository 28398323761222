import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'

import available_badges, { Badges } from 'src/util/availableBadges'
import { Badge } from 'src/types'
import { RootState } from 'src/configureStore'
import useStaffRoles from 'src/hooks/useStaffRoles'

type BadgeWithData = Badge & Badges

const UserBadge = () => {
  const badges = useSelector((state: RootState) => state.auth?.badges)
  const [selectedBadge, setSelectedBadge] = useState<Badge>()
  const [isStaffRole] = useStaffRoles()

  useEffect(() => {
    if (!badges) return

    const selected_badge = badges.find((b) => b.selected === true)

    if (!selected_badge) return
    const updatedBadge = { ...selected_badge }
    const matchingAvailableBadge: BadgeWithData = available_badges.find(
      (b: Badges) => b.identifier === selected_badge?.identifier
    )

    if (!matchingAvailableBadge) return
    updatedBadge.icon = matchingAvailableBadge?.icon || ''
    updatedBadge.name = matchingAvailableBadge?.name || ''
    setSelectedBadge(updatedBadge)
  }, [badges])

  return (
    <>
      {(!!badges?.length && selectedBadge) && (
        <div className='user-badge'>
          <img
            src={selectedBadge.icon}
            draggable={false}
            className='no-select'
            alt={`${selectedBadge.name}'s Badge`}
            title={`${selectedBadge.name}: Earned ${moment(
              selectedBadge.badge_earned
            ).format('MMM D, YYYY - hh:mm a')}`}
          />
        </div>
      )}
      {(!badges?.length && isStaffRole) && (
        <div className='user-badge'>
          <img
            src='https://d32tv5vz8ua1sf.cloudfront.net/images/badges/badge_dev.png'
            draggable={false}
            className='no-select'
            title='Admin Routes'
          />
        </div>
      )}
    </>
  )
}

export default UserBadge
