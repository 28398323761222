import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import useSublessonStore from '../../../../../stores/sublesson.store'

import { Transition } from '@headlessui/react'
import { EmptyPadding } from '../styles'
import { PaletteBody, WidgetCard, AddWidgetButton, Palette, WidgetCardDeck, WidgetGroup } from '../styles/WidgetPalette.styles'
import Obscured from 'src/components/navigation/styles/Obscured'

const BASIC_WIDGETS = [
  'conversation',
  'image',
  'kanji',
  // 'phrase',
  'qna',
  // 'qna_structure',
  'table',
  'text',
  'text-list',
  'video',
]

const LIST_WIDGETS = [
  'examples',
  'dialogue',
  'reading-comprehension',
  'sentences',
]

const SPECIAL_WIDGETS = [
  'grid',
  'more-details',
  'structure',
  'usage',
  'mini_quiz',
  'download',
  'presentation',
  'kanji-memory'
]

// const ACTIVITY_WIDGETS = [
//   'activity-sentence-builder',
//   'activity-fill-in-the-blanks',
//   'image',
//   'line-connect',
//   'question-and-answer',
//   'activity-sentence-translation',
// ]

const valueModelMap = {
  examples: 'ExamplesWidget',
  sentences: 'SentencesWidget',
  dialogue: 'DialogueWidget',
  'reading-comprehension': 'ReadingComprehensionWidget'
}

const INITIAL_OPEN = {
  basic: true,
  list: true,
  special: true,
  activity: true,
}

const WidgetPalette: React.FC = () => {
  const [willOpen, setWillOpen] = useState(true)
  const [openGroups, setOpenGroups] = useState(INITIAL_OPEN)
  const [selectedWidget, setSelectedWidget] = useState<string>()

  const parts = useSublessonStore((state) => state.parts)
  const addWidget = useSublessonStore((state) => state?.addWidget)
  const setSublessonParts = useSublessonStore((state) => state.setParts)
  // const fetchSublesson = useSublessonStore((state) => state?.fetchSublesson)
  const newWidgetPosInfo = useSublessonStore((state) => state?.newWidgetPosInfo)
  const widgetPaletteOpen = useSublessonStore((state) => state.widgetPaletteOpen)
  const setWidgetPaletteOpen = useSublessonStore((state) => state.setWidgetPaletteOpen)
  const location = useLocation()

  const handleBeforeEnter = () => setWillOpen(false)
  const handleAfterLeave = () => setWillOpen(true)

  const closePalette = () => {
    setWidgetPaletteOpen(false)
    setOpenGroups(INITIAL_OPEN)
    setSelectedWidget(undefined)
  }

  // marks the part stale, meaning it should be refetched in SubLessonPart or higher
  const markPartStale = (part_id: string) => {
    if (!parts) return
    const _parts = [...parts]
    const updatedParts = []
    _parts.forEach((p) => {
      let stale_part = false
      if (p._id === part_id) stale_part = true
      updatedParts.push({ ...p, stale_part })
    })

    setSublessonParts(updatedParts)
  }

  const handleAddWidget = async () => {
    let subtype: string
    let selected = selectedWidget
    if (LIST_WIDGETS.includes(selectedWidget)) {
      subtype = valueModelMap[selectedWidget]
      selected = 'list'
    }

    const partId = newWidgetPosInfo?.partId
    const insertAt = newWidgetPosInfo?.insertAt ?? undefined
    const page = newWidgetPosInfo?.page ?? undefined
    const sectionIdx = newWidgetPosInfo?.sectionIdx ?? undefined

    await addWidget(partId, selected, insertAt, subtype, sectionIdx)
    // await fetchSublesson()
    markPartStale(partId)
    closePalette()
  }

  const handleShowGroup = (groupName: string) => {
    setOpenGroups((prev) => ({
      ...prev,
      [groupName]: !openGroups[groupName]
    }))
  }

  const handleKeyboardShortcuts = (e: KeyboardEvent) => {
    let groupName: string
    switch (e.key) {
      case 'Escape': {
        return closePalette()
      }
      case '1':
        groupName = 'basic'
        break
      case '2':
        groupName = 'list'
        break
      case '3':
        groupName = 'special'
        break
      default:
    }
    if (groupName) handleShowGroup(groupName)
  }

  useEffect(() => {
    window.addEventListener('keyup', handleKeyboardShortcuts)
    return () => {
      window.removeEventListener('keyup', handleKeyboardShortcuts)
    }
  }, [openGroups])

  useEffect(() => {
    closePalette()
  }, [location])

  const enterLeave = 'tw-transform tw-transition tw-ease-in-out tw-duration-500 sm:tw-duration-700'
  const enterLeaveFrom = 'tw-translate-x-[400px]'
  const enterLeaveTo = 'tw-translate-x-0'

  return (
    <>
      {widgetPaletteOpen && (<Obscured onClick={closePalette} onClickOnly />)}
      <PaletteBody willOpen={willOpen}>
        <Transition
          show={widgetPaletteOpen}
          enter={enterLeave}
          enterFrom={enterLeaveFrom}
          enterTo={enterLeaveTo}
          leave={enterLeave}
          leaveFrom={enterLeaveTo}
          leaveTo={enterLeaveFrom}
          beforeEnter={() => handleBeforeEnter()}
          afterLeave={() => handleAfterLeave()}
        >
          <Palette>
            <h2 className='no-select'>Widget Palette</h2>
            <h3>insert at: {newWidgetPosInfo?.insertAt}</h3>
            <h3>part: {newWidgetPosInfo?.partId}</h3>
            {selectedWidget ? (
              <AddWidgetButton
                type='button'
                onClick={handleAddWidget}
              >
                Add {selectedWidget.replace(/_|-/g, ' ').replace('mini', 'lesson')} Widget
              </AddWidgetButton>
            ) : (
              <EmptyPadding />
            )}
            <WidgetGroup>
              <h3
                className='no-select'
                onClick={() => handleShowGroup('basic')}
              >
                Basic Widgets
              </h3>
              <WidgetCardDeck>
                {BASIC_WIDGETS.map((widget, idx) => (
                  <WidgetCard
                    className='no-select'
                    key={`basic-${idx}`}
                    onClick={() => setSelectedWidget(widget)}
                    selected={selectedWidget === widget}
                    visible={openGroups.basic}
                  >
                    <p>{widget.replace(/_|-/g, ' ')}</p>
                  </WidgetCard>
                ))}
              </WidgetCardDeck>
            </WidgetGroup>

            <WidgetGroup>
              <h3
                className='no-select'
                onClick={() => handleShowGroup('list')}
              >
                List Widgets
              </h3>
              <WidgetCardDeck>
                {LIST_WIDGETS.map((widget, idx) => (
                  <WidgetCard
                    className='no-select'
                    key={`list-${idx}`}
                    onClick={() => setSelectedWidget(widget)}
                    selected={selectedWidget === widget}
                    visible={openGroups.list}
                  >
                    <p>{widget.replace(/_|-/g, ' ')}</p>
                  </WidgetCard>
                ))}
              </WidgetCardDeck>
            </WidgetGroup>

            <WidgetGroup>
              <h3
                className='no-select'
                onClick={() => handleShowGroup('special')}
              >
                Special Widgets
              </h3>
                <WidgetCardDeck>
                {SPECIAL_WIDGETS.map((widget, idx) => (
                  <WidgetCard
                    className='no-select'
                    key={`special-${idx}`}
                    onClick={() => setSelectedWidget(widget)}
                    selected={selectedWidget === widget}
                    visible={openGroups.special}
                  >
                    <p>{widget.replace(/_|-/g, ' ').replace('mini', 'lesson')}</p>
                  </WidgetCard>
                ))}
              </WidgetCardDeck>
            </WidgetGroup>

          </Palette>
        </Transition>
      </PaletteBody>
    </>
  )
}

export default WidgetPalette
