import React, { useEffect, useMemo, useRef, useState } from 'react'
import useCF from 'src/hooks/useCF'

import { ImageUploaderTool, ToolInput } from '../styles'
import ConnectForm from '../tools/ConnectForm'
import kajikun from 'src/components/kaji.png'

import { Icon } from 'semantic-ui-react'

interface ToolImageUploaderProps {
  currentImage: {
    path: string
    title?: string
    caption?: string
    height?: number
  }
  flexDirection: 'column' | 'row'
  handleFieldError: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void
  index: number
  updateWidgetImage: (arg0: File, arg1: number) => Promise<boolean>
  embedded?: boolean
  handleRemoveImage?: () => void
  handleOpenCommentary?: () => void
}

const ToolImageUploader: React.FC<ToolImageUploaderProps> = ({
  currentImage,
  flexDirection,
  handleFieldError,
  index,
  updateWidgetImage,
  embedded,
  handleRemoveImage,
  handleOpenCommentary
}) => {
  const [imageSrc, setImageSrc] = useState<string>()
  const [imageDidError, setImageDidError] = useState<boolean>()
  const filePicker = useRef<HTMLInputElement>()
  const [cfPath, getCfPath] = useCF()
  const [commentaryOpen, setCommentaryOpen] = useState(false)

  const handleImageUpdated = async (
    htmlEvent: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ): Promise<void> => {
    const file = htmlEvent.target.files[0]
    const res = await updateWidgetImage(file, idx)
    setImageDidError(() => !res)
  }

  const handleImagePasted = async (htmlEvent: React.ClipboardEvent, idx: number) => {
    if (!htmlEvent?.clipboardData?.files?.[0]) return setImageDidError(() => true)
    const file = htmlEvent.clipboardData.files[0]
    const res = await updateWidgetImage(file, idx)
    setImageDidError(() => !res)
  }

  const onFieldError = (e) => {
    handleFieldError(e)
    setImageDidError(() => true)
    setImageSrc(() => kajikun)
  }

  useEffect(() => {
    // console.log('currentImage', currentImage)
    if (!currentImage?.path?.trim().length) return
    getCfPath(currentImage.path)
  }, [currentImage])

  useEffect(() => {
    // console.log('cfPath', cfPath)
    if (cfPath?.length) setImageSrc(() => cfPath)
    else setImageSrc(kajikun)
  }, [cfPath])

  const pathString = useMemo(() => {
    let _path = currentImage?.path || 'No image assigned'
    if (imageDidError) _path = 'Image Source Error'
    return _path
  }, [currentImage?.path, cfPath, imageDidError, imageSrc])

  return (
    <ImageUploaderTool
      className='flex-center'
      flexDirection={flexDirection}
      embedded={embedded}
    >
      <div
        onPaste={(e) => handleImagePasted(e, index)}
        style={{ position: 'relative' }}
      >
        {/* red X in top right corner to remove image if handlRemoveImage exists */}
        {handleRemoveImage && (
          <Icon name='close'
            style={{ position: 'absolute', top: '5px', right: '4px', zIndex: 1, cursor: 'pointer' }}
            color='red'
            onClick={handleRemoveImage}
          />
        )}
        {handleOpenCommentary && (
          <Icon name='comment alternate'
            style={{ position: 'absolute', top: '5px', left: '8px', zIndex: 1, cursor: 'pointer' }}
            color='black'
            onClick={handleOpenCommentary}
          />
        )}
        {!embedded && <p style={{ textAlign: 'center' }}>Path:&nbsp;{pathString}</p>}
        <img
          src={imageSrc}
          alt={currentImage.title}
          onError={(e) => onFieldError(e)}
          onErrorCapture={(e) => onFieldError(e)}
          draggable='false'
          className='no-select tw-cursor-pointer tw-mx-auto tw-my-none'
          onClick={() => filePicker.current?.click()}
        />
        {embedded && (
          <input
            type='file'
            accept='image/*'
            hidden
            aria-hidden={true}
            ref={filePicker}
            onChange={(e) => handleImageUpdated(e, index)}
            onError={(e) => console.error(e.target)}
          />
        )}
      </div>
      {!embedded && (
        <div className='flex-center' style={{ minWidth: '200px' }}>
          <ToolInput color='var(--off-white)'>
            <ConnectForm>
              {({ register }) => (
                <input
                  type='text'
                  placeholder='Title'
                  className='tk-light-border'
                  autoComplete='false'
                  autoCorrect='true'
                  {...register(`${index > 0 ? `pairedImages.${index - 1}.title` : 'title'}`)}
                />
              )}
            </ConnectForm>
          </ToolInput>
          <ToolInput color='var(--off-white)'>
            <ConnectForm>
              {({ register }) => (
                <input
                  type='text'
                  placeholder='Caption'
                  className='tk-light-border'
                  autoComplete='false'
                  autoCorrect='true'
                  {...register(`${index > 0 ? `pairedImages.${index - 1}.caption` : 'caption'}`)}
                />
              )}
            </ConnectForm>
          </ToolInput>
          <ToolInput color='var(--off-white)'>
            <ConnectForm>
              {({ register }) => (
                <input
                  type='text'
                  placeholder='On Hover Video Path (e.g., hiragana/A)'
                  className='tk-light-border'
                  autoComplete='false'
                  autoCorrect='true'
                  {...register('video_path')}
                />
              )}
            </ConnectForm>
          </ToolInput>
          <ToolInput color='var(--off-white)'>
            <ConnectForm>
              {({ register }) => (
                <input
                  type='number'
                  placeholder='Image Height (px)'
                  className='tk-light-border'
                  autoComplete='false'
                  {...register('height')}
                  style={{ border: '1px solid var(--light-border)' }}
                />
              )}
            </ConnectForm>
          </ToolInput>
          <input
            type='file'
            accept='image/*'
            hidden
            aria-hidden={true}
            ref={filePicker}
            onChange={(e) => handleImageUpdated(e, index)}
            onError={(e) => console.error(e.target)}
          />

          {/* Thought the button below was confusing since it's below the text fields, but all it does is duplicate
          the functionality of clicking on the image - Mike */}

          {/* <button
            className='tk-image-picker'
            type='button'
            onClick={() => filePicker.current?.click()}
          >
            Update Image
          </button> */}
        </div>
      )}
    </ImageUploaderTool>
  )
}

export default ToolImageUploader
