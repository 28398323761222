import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { QNA } from '../toolkit.constants'
import { QnAWidget, QnAWidgetBlocks } from 'src/types'

import { BarButton, ToolBody, ToolSubheader } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { IconButton, ToolFooter, ToolHeader, ToolInput, ToolOptions } from '../parts'
import FormatPreviewShell from 'src/components/fz_courses/sublessonpart/FormatPreviewShell'
import ToolForm from '../parts/ToolForm.part'
import QnABlocks from './qna/QnABlocks'
import useWidgetSaved from '../hooks/useWidgetSaved'

const QnATool: React.FC<ToolProps<QnAWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const [widget, fetchWidget, , editWidget] = useWidget<QnAWidget>(id)
  const [qnaBlocks, setQnaBlocks] = useState<QnAWidgetBlocks[][]>()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const hookFormMethods = useForm()

  const [previewOpen, setPreviewOpen] = useState(false)
  const [refresher, setRefresher] = useState(0)

  // console.log(hookFormMethods.getValues())

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  const commitTemporaryChanges = (_qnaBlocks: QnAWidgetBlocks[][]) => {
    hookFormMethods.setValue('blocks', _qnaBlocks)
    setQnaBlocks(_qnaBlocks)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setQnaBlocks(widget.blocks)
      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const handleAddBlock = () => {
    const _blocks = hookFormMethods.getValues()['blocks']
    _blocks.push([{ english: [''], other_lang: [''], aside: '' }])
    commitTemporaryChanges(_blocks)
  }

  const handleRemoveBlock = () => {
    const _blocks = hookFormMethods.getValues()['blocks']
    _blocks.pop()
    commitTemporaryChanges(_blocks)
  }

  const handleModifyResponses = (
    blockIdx: number,
    qIdx: number,
    remove: boolean,
  ) => {
    const _blocks = hookFormMethods.getValues()['blocks'] as QnAWidgetBlocks[][]
    const _pairs = _blocks[blockIdx]
    const _indexToModify = qIdx ?? _pairs.length - +remove
    
    if (remove) {
      _pairs.splice(_indexToModify, +remove)
    } else {
      _pairs.splice(_indexToModify, +remove, { english: [''], other_lang: [''], aside: '' })
    }

    _blocks[blockIdx] = _pairs
    commitTemporaryChanges(_blocks)
  }

  const handleSwapEntries = () => {
    let _blocks = hookFormMethods.getValues()['blocks']
    _blocks = _blocks.map((block) => block.map((b) => {
      const eng = [...b.english]
      /* eslint-disable no-param-reassign */
      b.english = b.other_lang
      b.other_lang = eng
      /* eslint-enable */
      return b
    }))
    commitTemporaryChanges(_blocks)
  }

  const fieldLabels = {
    displayOrder: 'English First',
    hideHeader: 'Hide Widget Title',
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={QNA}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={QNA}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              {Object.keys(fieldLabels).map((key: string, idx: number) => (
                <ToolInput
                  key={idx}
                  value={key}
                  inputType='checkbox'
                  inputLabel={fieldLabels[key]}
                  labelPosition='after'
                  width='auto'
                  removeMargin={true}
                />
              ))}
            </div>
            <div>
              <IconButton
                icon='exchange'
                color='purple'
                contrastColor='white'
                handleClick={() => handleSwapEntries()}
                title='Correct entries if Target is in the wrong fields'
              />
              <strong>Swap Tar/Eng</strong>
            </div>
          </ToolOptions>
          <ToolBody>
            <ToolSubheader className='tw-border-b-2 tw-border-gray-300'>
              <ToolInput
                value='title'
                placeholder='Widget Title'
              />
            </ToolSubheader>
            <QnABlocks
              qna_id={id}
              qnaBlocks={qnaBlocks}
              addResponse={handleModifyResponses}
              removeResponse={handleModifyResponses}
              onEdit={() => setRefresher((r) => r + 1)}
            />
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }
          }
            saveDisabled={!hookFormMethods.formState.isValid}
            removeLeftMargin
            widget_id={id}
          >
            <BarButton
              type='button'
              color='white'
              backgroundColor='var(--add)'
              contrastColor='var(--save)'
              borderColor='var(--save)'
              onClick={() => handleAddBlock()}
            >
              Add Q
            </BarButton>
            {qnaBlocks?.length > 1 && (
              <BarButton
                type='button'
                onClick={() => handleRemoveBlock()}
                color='white'
                backgroundColor='var(--delete)'
                contrastColor='var(--orange)'
                borderColor='var(--orange)'
              >
                Remove Q
              </BarButton>
            )}
            {/* eyeball icon button that will open the widget render preview */}
            <div>
              <IconButton
                icon='eye'
                backgroundColor='transparent'
                contrastColor='white'
                title='Preview'
                handleClick={() => setPreviewOpen((p) => !p)}
              />
            </div>
          </ToolFooter>
          {previewOpen && (
            <FormatPreviewShell
              widget={{ ...hookFormMethods.getValues(), id }}
              type='qna'
              refresher={refresher}
              course={course}
            />
          )}
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default QnATool
