import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'
import { REDUX_ACTIONS } from 'src/types'

import { InputSlider, SliderExplanation, SliderMain } from '../../styles/InputSlider.styles'
import useAPI from 'src/hooks/useAPI'
import { FlexCol } from 'src/assets/styles/globalStyles'

const FuriOpacitySlider = () => {
  const [furiOpacity, setFuriOpacity] = useState<number>()
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const dispatchProg = useDispatch()
  const [, , progSettingsPost] = useAPI('/user/progressive_settings', 'POST')

  const setting = progSettings?.main_default
  const furi_opacity = progSettings?.furi_opacity

  useEffect(() => {
    setFuriOpacity(furi_opacity)
  }, [furi_opacity])

  const handleChangeFuriOpacity = async () => {
    dispatchProg({
      type: REDUX_ACTIONS.CHANGE_SETTING,
      payload: { ...progSettings, main_default: setting, furi_opacity: furiOpacity }
    })

    try {
      // we don't care about the response in order to set redux state
      // it's only important to fire this off to save it their record
      await progSettingsPost({ setting, furi_opacity: furiOpacity })
    } catch (err) {
      const error = err as AxiosError
      console.error(error?.message)
      console.error(error?.name)
    }
  }

  return (
    <SliderMain>
      <label htmlFor='opacity'>
        <p>Furigana Visibility: {furiOpacity > 0 ? `${furiOpacity}%` : 'Hover'}</p>
      </label>
      <FlexCol center>
        <InputSlider
          type='range'
          min='0'
          max='100'
          step='10'
          value={furiOpacity}
          onChange={(e) => setFuriOpacity(parseInt(e.target.value, 10))}
          onTouchEnd={() => handleChangeFuriOpacity()}
          onPointerUp={() => handleChangeFuriOpacity()}
          id='opacity'
        />
        <SliderExplanation>Change transparency of the Kana above Kanji</SliderExplanation>
      </FlexCol>
    </SliderMain>
  )
}

export default FuriOpacitySlider
