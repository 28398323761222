import React, { useEffect, useState } from 'react'
import axios from '../../../../api'

import { Button, Divider, Form, Grid, Header, Icon, Segment } from 'semantic-ui-react'

const QnAStructureWidgetEditor = props => {
    const [qnaBlocks, setQnABlocks] = useState([])
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        fetchQnABlocks()
    }, [])

    useEffect(() => {
        console.log(qnaBlocks)
    }, [qnaBlocks])

    const fetchQnABlocks = () => {
        setLoading(true)

        axios.get(`/widgets/${props.id}`)
        .then((res) => {
            if (res.data.success) {
                setQnABlocks(res.data.widget.blocks)
                setLoading(false)
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const saveList = () => {
        axios.post(`/widgets/${props.id}/edit`, {
            blocks: qnaBlocks
        })
        .then((res) => {
            if (res.data.success) {
                fetchQnABlocks()
            }
        })
        .catch((err) => {
            console.log(err)
        })
    }

    const addQnABlock = () => {
        let updatedQnABlocks = [...qnaBlocks]
        updatedQnABlocks.push({other_lang: [], english: []})
        setQnABlocks(updatedQnABlocks)
    }

    const addQnAQuestion = idx => {
        let updatedQnABlocks = [...qnaBlocks]
        updatedQnABlocks[idx].push({other_lang: '', english: ''})
        setQnABlocks(updatedQnABlocks)
    }

    const deleteQnASentence = (idx, idy) => {
        let updatedQnABlocks = [...qnaBlocks]
        updatedQnABlocks[idx].splice(idy, 1)
        setQnABlocks(updatedQnABlocks)
    }

    const deleteQnABlock = (idx) => {
        let updatedQnABlocks = [...qnaBlocks]
        updatedQnABlocks.splice(idx, 1)
        setQnABlocks(updatedQnABlocks)
    }

    const setQnAStructure = (idx, lang, type, val, idy) => {
        let updatedQnABlocks = [...qnaBlocks]

        if (type === 'english') updatedQnABlocks[idx][type][lang] = val
        else updatedQnABlocks[idx][type][lang][idy] = val

        setQnABlocks(updatedQnABlocks)
    }

    return (
        <Segment loading={loading} raised inverted>
            <Form>
                <Header textAlign='center' inverted>Q&amp;A Structure Widget</Header>
                {qnaBlocks.map((b, idx) =>
                    <div>
                        <Segment style={{marginBottom: '10px'}} raised>
                            <Header as='h2'>Q&amp;A Block #{idx+1}</Header>
                            <Header as='h4'>Question</Header>
                            <Form.Input
                                value={b.q.english}
                                onChange={(e, {value}) => setQnAStructure(idx, 'english', 'q', value)}
                                label='English'
                            />
                            <Form.Group widths='equal'>
                                {b.q.other_lang.map((o, idy) =>
                                    <Form.Input
                                        fluid
                                        value={o.q.other_lang[idy]}
                                        onChange={(e, {value}) => setQnAStructure(idx, 'other_lang', 'q', value, idy)}
                                    />)}
                            </Form.Group>
                            <Header as='h4'>Answer</Header>
                            <Form.Input
                                value={b.a.english}
                                onChange={(e, {value}) => setQnAStructure(idx, 'english', 'a', value)}
                                label='English'
                            />
                            <Form.Group widths='equal'>
                                {b.a.other_lang.map((o, idy) =>
                                    <Form.Input
                                        fluid
                                        value={o.a.other_lang[idy]}
                                        onChange={(e, {value}) => setQnAStructure(idx, 'other_lang', 'a', value, idy)}
                                    />)}
                            </Form.Group>
                            <Divider />
                            <Grid centered>
                                <Button style={{margin: '10px'}} color='red' onClick={() => deleteQnABlock(idx)}><Icon name='trash' /></Button>
                                <Button style={{margin: '10px'}} color='blue' onClick={() => addQnAQuestion(idx)}><Icon name='plus' /></Button>
                            </Grid>
                        </Segment>
                    </div>
                )}
            </Form>
            <Grid centered>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Button color='green' onClick={() => saveList()}><Icon name='save' /></Button>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Button color='blue' onClick={() => addQnABlock()}><Icon name='plus' /></Button>
                    </Grid.Column>
                    <Grid.Column width={5}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default QnAStructureWidgetEditor