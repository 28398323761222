/* eslint-disable max-len */
import { SyntheticEvent, useEffect, useState } from 'react'
import DropdownStyles, { AbsoluteCenterDiv } from '../../styles/DropdownStyles'
import styled from '@emotion/styled'
import useMobile from 'src/hooks/useMobile'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import { Icon } from 'semantic-ui-react'

import FZExclamation from 'src/components/FZExclamation'
import JapanesePoster from 'src/components/pages/hirakata_poster/posters/JapanesePoster'

import { RootState } from 'src/configureStore'
import { REDUX_ACTIONS } from 'src/types'
import useAPI from 'src/hooks/useAPI'

import {
  DashAndBubble,
  DashLine,
  DashBubble
} from 'src/components/fz_courses/widgets/styles/QnAStyles'

const ExitButton = styled.div`
  position: absolute;
  background-color: #f57504;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 4px solid var(--off-white);
  z-index: 2;
  cursor: pointer;

  &>div {
    position: relative;
    border: 2px solid black;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  i {
    position: relative;
    color: var(--off-white);
    top: 6px;
    left: 3px;
    margin-left: auto;
    margin-right: auto;
  }
`

const CustomProgressiveToggle = styled.input`
  position: relative;
  appearance: none;
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
  height: 1.5rem;
  width: 3rem;
  background-color: white;
  border-radius: 20px;
  cursor: pointer;
  border: 2px solid #1d78be;

  &::before {
    content: '';
    display: inline-block;
    height: 1rem;
    width: 1rem;
    background-color: ${({ checked }) => checked ? '#28c6aa' : '#e83563'};
    border-radius: 50%;
    position: absolute;
    left: 10%;
    transition: .3s;
  }

  &:checked::before {
    left: 60%;
  }
`

const AdvancedProgSettings = ({ open, onClose }) => {
  const isMobile = useMobile()
  const [shouldDisplay, setShouldDisplay] = useState(false)
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const [res, loading, req] = useAPI('/progressive_settings/toggle_custom', 'PATCH')
  const toggleCustomDispatch = useDispatch()

  const resolveAnimation = (e: SyntheticEvent) => {
    if (!open) setShouldDisplay(false)
  }

  const toggleCustomProgressive = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.checked
    const assocEnable = R.assocPath(['custom_progressive', 'enabled'], val, progSettings)
    const assocProg = R.assoc('main_default', 'progressive', assocEnable)

    await req({ toggle: val })

    toggleCustomDispatch({
      type: REDUX_ACTIONS.CHANGE_SETTING,
      payload: assocProg
    })
  }

  useEffect(() => {
    if (open && !shouldDisplay) {
      setShouldDisplay(true)
    }
  }, [open, shouldDisplay])

  if (!shouldDisplay) return <></>

  return (
    <DropdownStyles willOpen={false}>
      <AbsoluteCenterDiv
        open={open}
        shouldDisplay={shouldDisplay}
        onAnimationEnd={resolveAnimation}
        isMobile={isMobile}
      >
        <ExitButton>
          <div onClick={onClose}>
            <Icon name='close' style={{ display: 'flex', alignItems: 'center' }} />
          </div>
        </ExitButton>
        <FZExclamation
          color="blue"
          centered={true}
          title={'Advanced Settings'}
        />
        <DashAndBubble>
          <DashLine thick color={'var(--jfz-blue)'}/>
          <DashBubble color={'var(--jfz-blue)'} isMobile={isMobile}>
            Progressive Settings
          </DashBubble>
          <DashLine thick color={'var(--jfz-blue)'} />
        </DashAndBubble>
        <div style={{ marginTop: '20px' }}>
          <p style={{ textAlign: 'left', padding: '30px' }}>
            Need help further memorizing a forgotten kana? Use the selector below to customize the Japanese characters
            shown to you within the course. <span style={{ color: '#52526b', fontWeight: 'bold' }}>Active Kana will be shown in grey.</span>
          </p>
          <label style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {progSettings?.custom_progressive?.enabled ? 'Disable' : 'Enable'} custom progressive.
          </label>
          <CustomProgressiveToggle
            type='checkbox'
            checked={progSettings?.custom_progressive?.enabled}
            onChange={(e) => toggleCustomProgressive(e)}
          />
          <JapanesePoster
            view='hiragana'
            customProgressiveSelector={true}
            embed
            isMobile={isMobile}
          />
        </div>
      </AbsoluteCenterDiv>
    </DropdownStyles>
  )
}

export default AdvancedProgSettings
