import React from 'react'
import Helmet from 'react-helmet'
import { useLocation } from 'react-router-dom'

interface HelmetProps {
  title: string
  quote?: string
  description?: string
  hashtag?: string
  content?: 'website' | 'article' | 'profile' | 'video' | 'image' | 'book' | 'music'
  image?: string
  contentSuffix?: string
  url?: string
  tag?: string
}

const HelmetMetaData: React.FC<HelmetProps> = ({
  title = 'Begin Your Language Journey | From Zero!',
  quote = '',
  description = 'Begin your language journey today',
  hashtag = '',
  content = 'website',
  image,
  contentSuffix,
  url,
}) => {
  const location = useLocation()
  const currentUrl: string = url || `https://www.fromzero.com${location.pathname}`

  // og: properties are for Facebook, allegedly
  return (
    <Helmet>
      <title>{title}</title>
      <meta charSet='utf-8' />
      <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
      <meta property='type' content={contentSuffix ? `${content}:${contentSuffix}` : content} />
      <meta property='url' content={currentUrl} />
      <meta name='viewport' content='width=device-width, initial-scale=1.0' />

      <meta name='msapplication-TileColor' content='#3765B0' />
      <meta name='theme-color' content='#3765B0' />

      <meta name='robots' content='noodp' />

      <meta property='title' content={title} />
      <meta property='quote' content={quote} />
      <meta name='description' content={description} />
      {image && <meta property='image' content={image} />}

      <meta property='og:locale' content='en_US' />
      <meta
        property='og:type'
        content={contentSuffix ? `${content}:${contentSuffix}` : content}
      />
      <meta property='og:url' content='https://www.fromzero.com/' />
      <meta property='og:image' content='https://d32tv5vz8ua1sf.cloudfront.net/images/branding/chibi_greeting.png' />
      <meta property='og:title' content={title} />
      <meta property='og:quote' content={quote} />
      <meta
        property='og:hashtag'
        content={`#yesjapan #fromzero #japanese #language #learning ${hashtag}`}
      />
      <meta property='og:site_name' content='FromZero!' />
      <meta property='og:description' content={description} />

      <meta property='twitter:description' content={description} />
      <meta property='twitter:card' content='summary_large_image' />
      <meta property='twitter:domain' content='fromzero.com' />
      <meta property='twitter:url' content='https://www.fromzero.com' />
      <meta property='twitter:title' content='From Zero! - Begin your language journey' />
      <meta property='twitter:image' content='https://d32tv5vz8ua1sf.cloudfront.net/images/branding/chibi_greeting.png' />
      <meta name='robots' content='index, follow' />
    </Helmet>
  )
}

export default HelmetMetaData
