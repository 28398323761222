import styled from '@emotion/styled'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const assetsPath = `${CLOUDFRONT_BASE}/images/banners/back_to_school`
const banner = `${assetsPath}/campaign-banner-account-30-percent.webp`

const BackToSchoolBanner = styled.div`
  height: 75px;
  max-width: 500px;
  align-self: center;
  background-image: url(${banner});
  background-position: center 40%;
  background-size: cover;
  margin: 16px auto;

  @media (max-width: 560px) { height: 50px }
  @media (max-width: 400px) { height: 40px }
`

export default BackToSchoolBanner
