import { createGlobalStyle } from 'styled-components'
import exclamation from './edit_mode_exclamation.svg'
import title from './edit_mode_title.svg'

const gridLines = `rgba(0,0,0,0) 0px,
                   #ddd 2px,
                   rgba(0,0,0,0) 0px,
                   rgba(0,0,0,0) 15px`
const gridLinesPrefersDark = `
                   rgba(0,0,0,0) 0px,
                   var(--blue-gray) 2px,
                   rgba(0,0,0,0) 0px,
                   rgba(0,0,0,0) 15px`

export default createGlobalStyle`
  body {
    background:
      repeating-linear-gradient(
        to top,
        ${gridLines}
      ),
      repeating-linear-gradient(
        to right,
        ${gridLines}
      ),
      #fff;

    ::before {
      background: url(${exclamation});
      background-position-x: right;
      background-size: 33%;
    }

    ::after {
      background: url(${title});
      background-position-x: 13px;
      z-index: -1;
    }

    ::before, ::after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      background-repeat: no-repeat;
      background-position-y: center;
    }
  }

  #course-contents-pane {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1) !important;
  }

  @media (prefers-color-scheme: dark) {
    body {
      background:
      repeating-linear-gradient(
        to top,
        ${gridLinesPrefersDark}
      ),
      repeating-linear-gradient(
        to right,
        ${gridLinesPrefersDark}
      ),
      #2c3e50;
    }
  }
`
