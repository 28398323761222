import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, useLocation } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import ReactGA from 'react-ga'
import { ThemeProvider } from 'styled-components'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import HelmetMetaData from './HelmetMetaData'

import NotFound from './pages/NotFound'
import SoundPopup from './fz_courses/sound_popup/SoundPopup'

import JFZFont from './app/JFZFont'
import Navigator from './navigation/Navigator'

import HistoryTracker from './app/HistoryTracker'

import { AudioProvider } from 'src/contexts/AudioContext'
import { UserEventProvider } from 'src/contexts/UserEventContext'
import { TabProvider } from 'src/contexts/TabContext'
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext'

import AdminRoute from './app/AdminRoute'
import PremiumRoute from './app/PremiumRoute'
import PrivateRoute from './app/PrivateRoute'
import SubscriptionRoute from './app/SubscriptionRoute'
import TeacherRoute from './app/TeacherRoute'
import UltraPremiumRoute from './app/UltraPremiumRoute'
import VaultAdminRoute from './app/VaultAdminRoute'

import {
  suspendedActivateAccountPage,
  suspendedAdminPanelPage,
  suspendedAffiliateViewSubsPage,
  suspendedAffiliateJoinPage,
  suspendedAskATeacherPage,
  suspendedAskATeacherQuestionPage,
  suspendedBadgesPage,
  suspendedBlogEditorPage,
  suspendedBlogHomePage,
  suspendedBlogPage,
  suspendedBookSessionsPage,
  suspendedBookStorePage,
  suspendedBugReportDetailsPage,
  suspendedBugReportPage,
  suspendedBulletinBoardPage,
  suspendedChangeEmailPage,
  suspendedChangePasswordPage,
  suspendedClaimAccountPage,
  suspendedConjugationGamePage,
  suspendedCourseMainPage,
  suspendedCourseVideoPlayerPage,
  suspendedCoursePreviewPage,
  suspendedCoursesPreviewPage,
  suspendedDesdeCeroLandingPage,
  suspendedDevPlayground,
  suspendedDictionaryPage,
  suspendedEarlySupporterThankYouPage,
  suspendedFAQPage,
  suspendedFlashCardsPage,
  suspendedFZLivePage,
  suspendedGamesPage,
  suspendedHomePage,
  suspendedHomeroom,
  suspendedInteractiveVideoPage,
  suspendedKanaGamePage,
  suspendedKanjiClass,
  suspendedKanjiDrawPage,
  suspendedKansaiCoursePage,
  suspendedKansaiCourseBuyPage,
  suspendedKoreanRedirectPage,
  suspendedLandingPage,
  suspendedLessonOverviewPage,
  suspendedLoginPage,
  suspendedMyAccountPage,
  suspendedMyNotebookPage,
  suspendedNotificationsPage,
  suspendedOrganizationPage,
  suspendedPostersPage,
  suspendedPremiumMembershipSubscribePage,
  suspendedPrivacyPolicyPage,
  suspendedPublicBugPage,
  suspendedRegisterPage,
  suspendedRequestPasswordChangePage,
  suspendedResendConfirmationPage,
  suspendedSearchPage,
  suspendedSimilarKanjiPage,
  suspendedStorePage,
  suspendedSubLessonPage,
  suspendedTeacherPanelPage,
  suspendedTermsOfUse,
  suspendedThankYouPage,
  suspendedThankYouPremiumPage,
  suspendedToolsPage,
  suspendedUpdatesPage,
  suspendedVaultMembershipPage,
  suspendedVideoVaultAdminPage,
  suspendedVideoVaultCategoryPage,
  suspendedVideoVaultPage,
  suspendedVideoVaultEdit,
  suspendedVideoVaultDelete,
  suspendedVideoVaultVideoPage,
  suspendedViewCoursePage,
  suspendedUserProfile,
  suspendedWordCafe,
  suspendedVerbLab
} from 'src/components/app/suspendedPages'

import 'src/assets/main.css'
import 'src/components/HeaderNav.css'
import 'src/components/App.css'
import 'src/components/fonts.css'
import 'src/assets/styles/animations.css'

const clientDev = 'Ac5E-QH9mPYECY8BW1FkIgiy2WH-SNvRRKjHvaJ9rsCjzp8TNJmeGf8gxta1m5nxQimaAtW29gtb_dtm'
const clientLive = 'AWKY5o7_lLJH6M9USPoPZqmqclOfO6NKyv5pHOwvCPBF8daJFo0P3RKCR1Jg9gCJYcIRysev7BSDFsOs'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

const LoadPayPal = (callback) => {
  const existingScript = document.getElementById('paypalScript')
  if (!existingScript) {
    const script = document.createElement('script')
    script.id = 'paypalScript'
    const cred = process.env.REACT_APP_YJ_DEV === 'true' ? clientDev : clientLive
    script.src = `https://www.paypal.com/sdk/js?client-id=${cred}&vault=true&intent=subscription`
    /* eslint-enable */
    script.onload = callback
    document.body.appendChild(script)
  } else {
    callback()
  }
}

const PayPalLoader = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!loaded) {
      LoadPayPal(() => {
        setLoaded(true)
      })
    }
  })

  return (
    <></>
  )
}

ReactGA.initialize('G-1CBGMBXDT8')

const consoleWarnBlacklist = [
  'If you intentionally want it to appear in the DOM as a custom attribute',
  'If you want to write it to the DOM',
]

const jpSplitlineRegex = /[　ぁ-んァ-ン一-龠。！？「」、ー]\n *[　ぁ-んァ-ン一-龠。！？「」、ー]/g
const jpLoneCharRegex = /　([ぁ-んァ-ン一-龠。！？「」、ー])　/g

const jpKanjiStageOneRegex = /([一-龠])([ぁ-ん]+)([一-龠])/g
const jpKanjiStageTwoRegex = /([一-龥])([ぁ-ん]+(?=[ぁ-ん]|$))/g

const queryClient = new QueryClient()

const App = () => {
  const theme = {}

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  useEffect(() => {
    // remove certain console warnings
    const consoleWarn = console.warn
    console.warn = (...args) => {
      if (typeof args[0] === 'string' && consoleWarnBlacklist.some((str) => args[0].includes(str))) {
        return
      }
      consoleWarn.apply(console, args)
    }

    const consoleError = console.error
    console.error = (...args) => {
      if (typeof args[0] === 'string' && consoleWarnBlacklist.some((str) => args[0].includes(str))) {
        return
      }
      consoleError.apply(console, args)
    }

    // return if cypress is detected within the global window object
    if ((window as any).Cypress) {
      return
    }

    let script
    const head = document.getElementsByTagName('head')[0]
    let completed = false
    const cred = process.env.REACT_APP_YJ_DEV === 'true' ? clientDev : clientLive

    const addPayPalScript = async () => {
      script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://www.paypal.com/sdk/js?client-id=${cred}&vault=true&intent=subscription`
      script.async = true
      script.onload = () => {
        // nothing
      }

      head.appendChild(script)
      completed = true
    }

    addPayPalScript().then(() => {
      completed = true
    })

    return () => {
      if (completed) {
        head.removeChild(script)
      }
    }
  }, [])

  // global copy listener that will remove a newline from a Japanese character if the
  // next character after the newline is also a Japanese character or a space
  // e.g., こんにちは\n　みなさん -> こんにちは　みなさん
  useEffect(() => {
    document.addEventListener('copy', (e) => {
      // if we're in any kind of input field, just return copy as normal
      if (document.activeElement?.tagName === 'INPUT' || document.activeElement?.tagName === 'TEXTAREA') {
        return
      }
      const selection = window.getSelection()
      const text = selection?.toString()
      let newText = text
      let lastText = newText
      e.preventDefault()

      // perform stage one of the regex transform, taking capture group one as-is,
      // adding prens around capture group 2, and then replacing the whole thing with
      // that plus capture group 3 added as-is
      while (newText && newText.match(jpKanjiStageOneRegex)) {
        newText = newText.replace(jpKanjiStageOneRegex, (match, p1, p2, p3) => `${p1}(${p2})${p3}`)
        if (lastText === newText) {
          break
        }
        lastText = newText
      }
      while (newText && newText.match(jpKanjiStageTwoRegex)) {
        newText = newText.replace(jpKanjiStageTwoRegex, (match, p1, p2) => `${p1}(${p2})`)
        if (lastText === newText) {
          break
        }
        lastText = newText
      }
      while (newText && newText.match(jpSplitlineRegex)) {
        newText = newText.replace(jpSplitlineRegex, (match) => match.replace('\n', ''))
        if (lastText === newText) {
          break
        }
        lastText = newText
      }
      while (newText && newText.match(/([　ぁ-んァ-ン一-龠。！？「」、ー])(\n\s+)([　ぁ-んァ-ン一-龠。！？「」、ー])/g)) {
        newText = newText.replace(/([　ぁ-んァ-ン一-龠。！？「」、ー])(\n\s+)([　ぁ-んァ-ン一-龠。！？「」、ー])/g, (match, p1, p2, p3) => `${p1}${p3}`)
      }

      // get rid of preceding jp spaces in the lone char regex
      // take the single capture group and just add a jp space to it, in other words
      while (newText && newText.match(jpLoneCharRegex)) {
        newText = newText.replace(jpLoneCharRegex, (match, p1) => `${p1}　`)
      }
      // replace all newlines with empty string
      newText = newText.replace(/　/g, '')
      e.clipboardData.setData('text/plain', newText)
    })
  }, [])

  return (
    <CookiesProvider>
      <TabProvider>
      <QueryClientProvider client={queryClient}>
      <Elements stripe={stripePromise}>
      <AudioProvider>
        <BrowserRouter>
          <UserEventProvider>
            <SubscriptionProvider>
            <ThemeProvider theme={theme}>
              <div className='content'>
                <JFZFont />
                <HelmetMetaData title='From Zero!' />
                <SoundPopup />
                <Navigator>
                  <HistoryTracker>
                    <Route exact path='/' component={suspendedLandingPage} />
                    <Route exact path='/shop' component={() => {
                      window.location.href = 'https://shop.fromzero.com'
                      return null
                    }} />
                    <Route exact path='/ask' component={suspendedAskATeacherPage} />
                    <Route exact path='/dev_notes' component={suspendedBulletinBoardPage} />
                    <Route exact path='/faq' component={suspendedFAQPage} />
                    <Route exact path='/korean/:trail+' component={suspendedKoreanRedirectPage} />
                    <Route exact path='/memberships/vault' component={suspendedVaultMembershipPage} />
                    <Route exact path='/privacy' component={suspendedPrivacyPolicyPage} />
                    <Route exact path='/store' component={suspendedStorePage} />
                    <Route exact path='/v/:nickname' component={suspendedInteractiveVideoPage} />
                    <Route exact path='/activate/:hash' component={suspendedActivateAccountPage} />
                    <Route exact path='/ask/:id' component={suspendedAskATeacherQuestionPage} />
                    <Route exact path='/bugs/public/:id' component={suspendedPublicBugPage} />
                    <Route exact path='/books' component={suspendedBookStorePage} />
                    <Route exact path='/forgot_password' component={suspendedRequestPasswordChangePage} />
                    <Route exact path='/login' component={suspendedLoginPage} />
                    <Route exact path={['/register', '/join']} component={suspendedRegisterPage} />
                    <Route exact path='/resend' component={suspendedResendConfirmationPage} />
                    <Route exact path='/reset/:hash' component={suspendedChangePasswordPage} />
                    <Route exact path='/change_email/:hash' component={suspendedChangeEmailPage} />
                    <Route exact path='/tos' component={suspendedTermsOfUse} />
                    <Route exact path='/join/:affiliate' component={suspendedLandingPage} />
                    <Route exact path='/store/kansai' component={suspendedKansaiCoursePage} />
                    <Route exact path='/es' component={suspendedDesdeCeroLandingPage} />
                    <Route exact path='/updates' component={suspendedUpdatesPage} />
                    <Route exact path='/support' component={suspendedBugReportPage} />
                    <Route exact path='/claim' component={suspendedClaimAccountPage} />
                    <Route exact path='/ask/bugs/:id' component={suspendedPublicBugPage} />

                    <PrivateRoute exact path='/account' component={suspendedMyAccountPage} />
                    <PrivateRoute exact path='/ask/aat/:id' component={suspendedPublicBugPage} />
                    <PrivateRoute exact path='/badges' component={suspendedBadgesPage} />
                    <PrivateRoute exact path='/courses' component={suspendedCoursesPreviewPage} />
                    <PrivateRoute exact path='/courses/:id' component={suspendedCoursePreviewPage} />
                    <PrivateRoute exact path='/notifications' component={suspendedNotificationsPage} />
                    <PrivateRoute exact path='/fz-live' component={suspendedFZLivePage} />
                    <PrivateRoute exact path='/games' component={suspendedGamesPage} />
                    <PrivateRoute exact path='/games/conjugation-game' component={suspendedConjugationGamePage} />
                    <PrivateRoute exact path='/games/flash-cards' component={suspendedFlashCardsPage} />
                    <PrivateRoute exact path='/games/kana-game' component={suspendedKanaGamePage} />
                    <PrivateRoute exact path='/games/similar-kanji' component={suspendedSimilarKanjiPage} />
                    <PrivateRoute exact path='/games/word-cafe' component={suspendedWordCafe} />
                    <PrivateRoute exact path='/games/verb-lab' component={suspendedVerbLab} />
                    <PrivateRoute exact path='/games/kanji-class' component={suspendedKanjiClass} />
                    <PrivateRoute exact path='/home' component={suspendedHomePage} />
                    <PrivateRoute exact path='/memberships/premium/subscribe' component={suspendedPremiumMembershipSubscribePage} />
                    <PrivateRoute exact path='/notebook' component={suspendedMyNotebookPage} />
                    <PrivateRoute exact path='/player' component={suspendedCourseVideoPlayerPage} />
                    <PrivateRoute exact path='/search' component={suspendedSearchPage} />
                    <PrivateRoute exact path='/store/kansai/buy' component={suspendedKansaiCourseBuyPage} />
                    <PrivateRoute exact path='/thankyou' component={suspendedThankYouPage} />
                    <PrivateRoute exact path='/user/@:username' component={suspendedUserProfile} />
                    <PrivateRoute exact path='/thankyou/early-supporter' component={suspendedEarlySupporterThankYouPage} />
                    <PrivateRoute exact path='/thankyou/premium-monthly' component={suspendedThankYouPremiumPage} />

                    <PremiumRoute exact path='/vault/category/:id' component={suspendedVideoVaultCategoryPage} />
                    <PremiumRoute exact path='/vault/:id' component={suspendedVideoVaultVideoPage} />
                    <PremiumRoute exact path='/vault' component={suspendedVideoVaultPage} />

                    <VaultAdminRoute exact path='/admin/vault/delete/:id' component={suspendedVideoVaultDelete} />
                    <VaultAdminRoute exact path='/admin/vault/edit/:id' component={suspendedVideoVaultEdit} />
                    <VaultAdminRoute exact path='/admin/vault' component={suspendedVideoVaultAdminPage} />

                    <SubscriptionRoute exact path='/courses/:course/:lesson/:sub/:part' component={suspendedSubLessonPage} />
                    <SubscriptionRoute exact path='/courses/:course/:lesson/:sub' component={suspendedSubLessonPage} />
                    <SubscriptionRoute exact path='/courses/:course/:lesson' component={suspendedLessonOverviewPage} />

                    {/* new flow view for SubLessonPage, component needs to be passed `flow` prop */}
                    <SubscriptionRoute
                      exact
                      path='/courses/:course/:lesson/:sub/:part/:widget/flow'
                      // pass `flow` prop to suspended (lazy) suspendedSubLessonPage component
                      component={() => suspendedSubLessonPage({ flow: true })}
                    />

                    <UltraPremiumRoute exact path='/course/kansai/:lesson' component={suspendedCourseVideoPlayerPage} />
                    <UltraPremiumRoute exact path='/course/kansai' component={suspendedCourseMainPage} />
                    <UltraPremiumRoute exact path='/course/:id/view' component={suspendedViewCoursePage} />

                    <TeacherRoute exact path='/teacher' component={suspendedTeacherPanelPage} />

                    {/* new teaching pages for the calendly system */}
                    <PrivateRoute exact path='/sessions/book' component={suspendedBookSessionsPage} />

                    <AdminRoute exact path='/admin' component={suspendedAdminPanelPage} />
                    <AdminRoute exact path='/admin/bug/:id' component={suspendedBugReportDetailsPage} />
                    <AdminRoute exact path='/tools' component={suspendedToolsPage} />
                    <AdminRoute exact path='/dev/playground' component={suspendedDevPlayground} />

                    <AdminRoute exact path='/dev/dictionary' component={suspendedDictionaryPage} />
                    <AdminRoute exact path='/dev/poster' component={suspendedPostersPage} />
                    <PrivateRoute exact path='/affiliate-panel' component={suspendedAffiliateViewSubsPage} />
                    <PrivateRoute exact path='/organization' component={suspendedOrganizationPage} />
                    <AdminRoute exact path='/kanji_draw' component={suspendedKanjiDrawPage} />

                    <AdminRoute exact path='/blog' component={suspendedBlogHomePage} />
                    <AdminRoute exact path='/blog/edit/:slug' component={suspendedBlogEditorPage} />
                    <Route exact path='/blog/:slug' component={suspendedBlogPage} />

                    <Route component={NotFound} />
                  </HistoryTracker>
                </Navigator>
              </div>
            </ThemeProvider>
            </SubscriptionProvider>
          </UserEventProvider>
        </BrowserRouter>
      </AudioProvider>
      </Elements>
      </QueryClientProvider>
      </TabProvider>
    </CookiesProvider>
  )
}

export default App
