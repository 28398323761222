import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ExtendedWidget, TextboxWidget, Widget } from 'src/types'

import useStaffRoles from 'src/hooks/useStaffRoles'
import useToolkitStore from '../../../stores/toolkit.store'

import { Draggable, Droppable } from 'react-beautiful-dnd'
import { Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { FZButton, FZButtonIcon } from 'src/assets/styles/globalStyles'
import SoundRenderer from '../widgets/SoundRenderer'

import { DataGrabberButton, DeleteWidgetButton, ListifyButton, RefreshWidgetCacheButton, widgetOptions } from './sublesson_part_imports'
import AddWidgetButton from '../admin/creation_toolkit/parts/AddWidgetButton'
import useMobile from 'src/hooks/useMobile'
import { PageType } from 'src/components/pages/blog/BlogEditor'

interface CopyToClipboardButtonProps {
  id: string
  hideId?: boolean
  leadingMessage?: string
}

export const CopyToClipboardButton: React.FC<CopyToClipboardButtonProps> = ({
  id,
  leadingMessage,
  hideId = false,
}) => {
  const [copied, setCopied] = useState(false)

  const copy = () => {
    navigator.clipboard.writeText(id)
    setCopied(true)
  }

  return (
    <FZButtonIcon>
      <FZButton
        onClick={copy}
        backgroundColor='var(--blue)'
        borderColor='var(--blue)'
        hoverBackgroundColor='white'
        hoverBorderColor='var(--blue)'
        hoverColor='var(--blue)'
        fontWeight='bold'
        widthMaxContent
        title={`Copy ${id} to clipboard`}
      >
        {leadingMessage && `${leadingMessage} `}
        {!hideId && id}&nbsp;&nbsp;
        {copied ? <Icon fitted name="checkmark" /> : <Icon fitted name="clipboard" />}
      </FZButton>
    </FZButtonIcon>
  )
}

interface WidgetEditView {
  course?: string
  dragging?: boolean
  id?: string
  lesson?: string
  loading?: boolean
  loadPart?: () => void
  fetchSubLesson?: () => void
  fetchWidget?: (string) => void
  partId?: string
  justChanged?: string | null

  populateWidgetData?: (
    arg0: { type: string, _id: string },
    arg1: ExtendedWidget[],
  ) => void

  sublesson?: string
  widgets?: Widget[]

  fetchCounter?: number

  // if we use the edit view within a blog page vs. courses
  page?: PageType
  sectionIdx?: number
}

const WidgetEditView: React.FC<WidgetEditView> = ({
  course = null,
  dragging = false,
  lesson = null,
  loadPart = () => null,
  fetchSubLesson = () => null,
  fetchWidget = () => null,
  partId = null,
  populateWidgetData = () => null,
  sublesson = null,
  widgets = [],
  justChanged = null,
  loading: outsideLoading = false,
  fetchCounter = 0,
  page,
  sectionIdx,
}) => {
  // console.log(fetchWidget)
  const isMobile = useMobile()
  const location = useLocation()
  const resetToolkit = useToolkitStore((state) => state.reset)
  const editingWidgetIds = useToolkitStore((state) => state.editingWidgetIds)
  const setEditingId = useToolkitStore((state) => state.setEditingId)
  const [admin] = useStaffRoles('admin', 'dev', 'teacher')
  // const fetchSublesson = useSublessonStore((state) => state.fetchSublesson)
  const usesNewEditor = [ // TODO: remove once all editors are done
    'ConversationWidget',
    'DownloadWidget',
    'GridWidget',
    'ImageWidget',
    'KanjiMemoryWidget',
    'KanjiWidget',
    'ListWidget',
    'MiniQuizWidget',
    'MoreDetailsWidget',
    'PresentationWidget',
    'QnAWidget',
    'StructureWidget',
    'TableWidget',
    'TextboxWidget',
    'TextListWidget',
    'UsageWidget',
    'VideoWidget',
  ]
  const pageRef = useRef(page)

  const handleInPlaceEditing = (e, widget_id: string) => {
    if (!admin || isMobile || editingWidgetIds.includes(widget_id)) return
    e.stopPropagation()
    setEditingId(widget_id)
  }

  useEffect(() => {
    resetToolkit()
  }, [location])

  if (!admin) return (<></>)

  if (!widgets?.length) return (<AddWidgetButton sectionIdx={sectionIdx} page={page} partId={partId} index={0} />)

  return (
    <Droppable droppableId={`drop-widgets-${partId}`} type='WIDGET'>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          style={{
            backgroundColor: snapshot.isDraggingOver ? '#45C7FA' : '',
          }}
          {...provided.droppableProps}
        >
          {widgets?.map((w, idx) => {
            const { title, type, _id } = w
            const WidgetEditor = widgetOptions[type]?.e
            return (
              <Draggable draggableId={`${idx}`} index={idx} key={_id}>
                {(provided2) => (
                  <div
                    ref={provided2.innerRef}
                    onDoubleClick={(e) => handleInPlaceEditing(e, w._id)}
                    {...provided2.draggableProps}
                  >
                    {(!dragging && !usesNewEditor.includes(type)) && (
                      <Header as='h4' style={{ marginTop: '10px', paddingLeft: '5px' }}>
                        {type}
                      </Header>
                    )}
                    {dragging ? (
                      <Segment>
                        {(title && typeof title !== 'object' && title)
                          ?? widgetOptions[type]?.text
                          ?? type
                        }
                      </Segment>
                    ) : (
                      <React.Fragment>
                        <AddWidgetButton sectionIdx={sectionIdx} page={page} partId={partId} index={idx} />
                        <WidgetEditor
                          id={_id}
                          index={idx}
                          incomingWidget={w as TextboxWidget}
                          widgetsLength={widgets.length}
                          course={course}
                          lesson={lesson}
                          sublesson={sublesson}
                          draggable_props={provided2.dragHandleProps}
                          justChanged={justChanged}
                          outsideLoading={outsideLoading}
                          loadPart={loadPart}
                          fetchSubLesson={fetchSubLesson}
                          fetchWidget={fetchWidget}
                          fetchCounter={fetchCounter}
                        />
                        <AddWidgetButton sectionIdx={sectionIdx} page={page} partId={partId} index={idx + 1} />
                      </React.Fragment>
                    )}
                    {(!dragging && !usesNewEditor.includes(type)) && (
                      <Grid centered style={{ marginBottom: '5px' }}>
                        <Grid.Row>
                          <DeleteWidgetButton
                            compact
                            size='small'
                            handleWidgetDeleted={loadPart}
                            id={_id}
                          />
                          <DataGrabberButton
                            selected={type}
                            compact
                            size='small'
                            type={type}
                            onDataProcessed={(data) => {
                              populateWidgetData({ type, _id }, data)
                            }}
                          />
                          <ListifyButton widget={_id} />
                          <RefreshWidgetCacheButton widget={_id} />
                          <CopyToClipboardButton id={_id} />
                        </Grid.Row>
                      </Grid>
                    )}
                  </div>
                )}
              </Draggable>
            )
          })}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  )
}

export default WidgetEditView
