import { CourseInfo } from 'src/types'
import styled from 'styled-components'

interface CrumbProps {
  courseInfo: CourseInfo
}

export default styled.div.attrs((props: CrumbProps) => ({
  alt_color_3: props.courseInfo.alt_color_3,
}))<CrumbProps>`
  display: inline-block;
  position: relative;
  height: 26px;
  width: 130px;
  margin: 0 2px 0 5px;
  background-color: ${(props) => props.alt_color_3};
  z-index: 2;
  padding-right: 1px;

  :before {
    content: '';
    position: absolute;
    right: -13px;
    bottom: 0;
    width: 13px;
    height: 26px;
    border-left: 13px solid ${(props) => props.alt_color_3};
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
  }

  div {
    height: 14px;
    color: var(--off-white);
    display: inline-block;
    margin: 4px 0 8px 0;
  }

  @media (max-width: 377px) {
    width: 120px;
    font-size: 0.9rem;
  }

  @media (max-width: 343px) {
    width: 110px;
    font-size: 0.75rem;
  }
`
