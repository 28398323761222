import React, { useState } from 'react'
import styled from '@emotion/styled/macro'
import { Button, Modal, ModalActions, ModalDescription, ModalHeader } from 'semantic-ui-react'

const ModalBody = styled(Modal)`
  * { font-family: 'Milliard Book', sans-serif !important; }
`

const ModalTitle = styled(ModalHeader)`
  h2 { font-size: 1.6rem; color: var(--blue-gray); }
`

const ModalMessage = styled(ModalDescription)`
  background: repeating-linear-gradient(135deg, var(--yellow), var(--yellow) 12px, #333 12px, #333 24px);
  padding: 2rem 0;
  text-align: center;

  h3 {
    padding: 1rem 1.5rem;
    margin: 0.5rem auto;
    width: fit-content;
    background: var(--off-white);
    border: 4px solid var(--red);
    border-radius: 12px;
    color: var(--blue-gray);
    font-size: 1.2rem;

    strong { color: var(--red); }
  }
`

interface ConfirmationModalProps {
  message: string
  emphasized: string
  deletePromptOpen: boolean
  setDeletePromptOpen: (open: boolean) => void
  closeModal?: () => void
  handleDeleteConfirmed: () => void
}

const DeleteConfirmationModal: React.FC<ConfirmationModalProps> = ({
  message,
  emphasized,
  deletePromptOpen,
  setDeletePromptOpen,
  handleDeleteConfirmed,
  closeModal = null,
}) => {
  const [confirmation, setConfirmation] = useState('')

  const handleCloseModal = () => {
    if (closeModal) closeModal()
    else setDeletePromptOpen(false)
  }
  return (
    <ModalBody
      open={deletePromptOpen}
      onClose={() => handleCloseModal()}
    >
      <ModalTitle>
        <h2>Are you sure?</h2>
      </ModalTitle>
      <ModalMessage>
        <div className='tw-bg-white tw-pt-4'>
          <h3>{message} <strong>{emphasized}</strong> if you continue.
          </h3>
          <p>Type &quot;delete&quot; to confirm your intent.</p>
          <input
            className='tw-py-2 tw-px-4 tw-border-2 tw-border-red-600 tw-mb-2 tw-rounded-md'
            type='text'
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
          />
        </div>
      </ModalMessage>
      <ModalActions>
        <Button.Group>
          <Button
            color='grey'
            type='button'
            onClick={() => handleCloseModal()}
          >
            Cancel
          </Button>
          <Button.Or />
          <Button
            negative
            type='button'
            onClick={() => handleDeleteConfirmed()}
            disabled={confirmation.toLowerCase() !== 'delete'}
          >
            Delete
          </Button>
        </Button.Group>
      </ModalActions>
    </ModalBody>
  )
}

export default DeleteConfirmationModal
