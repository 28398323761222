import React, { useEffect, useRef, useState, ChangeEvent } from 'react'
import { AxiosResponse, AxiosError } from 'axios'
import axios from 'src/api'
import { Icon } from 'semantic-ui-react'

import { MiniQuizQuestion } from 'src/types'
import { ListInputDivider, ToolImageUploader, ToolInput, ToolSubheader } from '../../parts'
import { DeleteListItemButton, MiniQuizItemAnswers, MQItemAnswerRow, AddCorrectAnswer } from '../../styles/MiniQuiz.styles'
import { SubTypeSelect } from '../../styles/ListTool.styles'

const timeout = (ms) => new Promise((res) => setTimeout(res, ms))

const sleep = async (ms) => {
  await timeout(ms)
}

const QuizImageUploader = ({
  course, lesson, sublesson, item, handleSetCommentary,
  widget_id, handleSaveWidget, qidx, qidy, handleRemoveImage
}) => {
  const [uploadingImage, setUploadingImage] = useState(false)
  const [commentaryOpen, setCommentaryOpen] = useState(false)

  const handleUpdate = () => {
    // console.log(commentaryOpen)
    if (commentaryOpen) setCommentaryOpen(false)
  }

  const updateWidgetImage = async (incomingImage: File, idx: number): Promise<boolean> => {
    if (uploadingImage) return
    setUploadingImage(() => true)

    const imageName = incomingImage.name.replaceAll(' ', '_')

    // generate random four-letter hash
    const hash = Math.random().toString(36).substring(2, 6)

    const _path = `courses/images/${course}/${lesson}/${sublesson}/quiz/${widget_id}`
    // console.log(_path)

    const _data = new FormData()
    _data.append('location', _path)
    _data.append('image', incomingImage, `${qidx}-${qidy === 'q' ? qidy : hash}-${imageName}`)
    _data.append('mimetype', incomingImage.type)

    let _response: AxiosResponse
    try {
      _response = await axios.post(
        '/images',
        _data,
        { headers: { 'Content-Type': 'multipart/form-data;' } }
      )
    } catch (err) {
      const _error = err as AxiosError
      console.error(_error.stack)
      console.warn(_error.message)
      console.warn(_error.name)
    }
    await sleep(500)
    if (_response.data.success) {
      handleSaveWidget(qidx, `${_path}/${qidx}-${qidy === 'q' ? qidy : hash}-${imageName}`, qidy)
    }
    setUploadingImage(() => false)
    return _response.data.success
  }

  if (uploadingImage) return <Icon name='spinner' loading />

  return (
    <>
      {commentaryOpen && (
        <ToolInput
          style={{ position: 'absolute', top: '-45px', left: 0, zIndex: 1, minWidth: '500px' }}
          placeholder='Commentary'
          onChange={(e) => handleSetCommentary(e.target.value)}
          value={`items.${qidx}.other_commentary.${qidy}`}
          onClickOut={handleUpdate}
        />
      )}
      <ToolImageUploader
        embedded
        currentImage={{
          path: qidy === 'q' ? item?.question_image : item?.other_answers[qidy],
          // title: widget?.title,
          // caption: widget?.caption
        }}
        flexDirection='row'
        // flexDirection={widget?.pairedImages?.length ? 'column' : 'row'}
        handleFieldError={() => {}}
        index={0}
        updateWidgetImage={updateWidgetImage}
        handleOpenCommentary={handleSetCommentary ? () => setCommentaryOpen(() => !commentaryOpen) : undefined}
        handleRemoveImage={qidy !== 'q' ? handleRemoveImage : undefined}
      />
    </>
  )
}

interface MiniQuizItemProps {
  total_questions: number
  item: MiniQuizQuestion
  item_index: number
  handleAddQuestion: (q_index: number) => void
  handleAddWrongAnswer: (q_index: number, item_index: number) => void
  handleRemoveQuestion: (q_index: number) => void
  handleRemoveWrongAnswer: (q_index: number, item_index: number) => void
  handleAddCorrectAnswer: (q_index: number) => void
  handleRemoveCorrectAnswer: (q_index: number, item_index: number) => void
  handleActivityTypeChange: (q_index: number, value: string, type?: string) => void
  handleForcedVersionChange: (q_index: number, value: string, type?: string) => void
  handleQuestionImageChange: (q_index: number, value: string, type?: string) => void
  handleAnswerImageChange: (q_index: number, value: string, item_index: number) => void
  handleSetCommentary: (q_index: number, item_index: number, value: string) => void
  course: string
  lesson: string
  sublesson: string
  widget_id: string
}

const MiniQuizItem: React.FC<MiniQuizItemProps> = ({
  total_questions,
  item,
  item_index,
  handleAddQuestion,
  handleAddWrongAnswer,
  handleRemoveQuestion,
  handleRemoveWrongAnswer,
  handleAddCorrectAnswer,
  handleRemoveCorrectAnswer,
  handleActivityTypeChange,
  handleForcedVersionChange,
  handleQuestionImageChange,
  handleAnswerImageChange,
  handleSetCommentary,
  course,
  lesson,
  sublesson,
  widget_id
}) => {
  return (
    <>
      <ToolSubheader>
        <SubTypeSelect>
          <select
            name='subtype'
            id='subtype'
            defaultValue={item?.question_activity_type}
            onChange={(e) => handleActivityTypeChange(item_index, e.target.value, 'question_')}
          >
            <option value='text'>Text</option>
            <option value='sound'>Sound</option>
            <option value='image'>Image</option>
          </select>
        </SubTypeSelect>
        {!['sound', 'image'].includes(item?.question_activity_type) && (
          <SubTypeSelect>
            <span style={{ fontWeight: 'bold' }}>Forced Version</span>
            <select
              name='forced_version'
              id='forced_version'
              defaultValue={item?.question_forced_version}
              onChange={(e) => handleForcedVersionChange(item_index, e.target.value, 'question_')}
              style={{ marginLeft: '10px' }}
            >
              <option value='none'>None</option>
              <option value='kanji'>Kanji</option>
              <option value='hirakata'>Hirakata</option>
              <option value='romaji'>Romaji</option>
            </select>
          </SubTypeSelect>
        )}
        {!['sound', 'image'].includes(item?.question_activity_type) && (
          <ToolInput
            value={`items.${item_index}.question_display_sound_icon`}
            inputType='checkbox'
            inputLabel='Display Sound Icon'
            labelColor='var(--blue-gray)'
            labelPosition='before'
          />
        )}
      </ToolSubheader>
      <ToolSubheader>
        <ToolInput
          value={`items.${item_index}.question`}
          placeholder={`Question ${item_index + 1}`}
        />
        {total_questions > 1 && (
          <DeleteListItemButton
            onClick={() => handleRemoveQuestion(item_index)}
            title='Remove question'
            leftPad
          >
            <Icon name='trash' fitted />
          </DeleteListItemButton>
        )}
      </ToolSubheader>
      {item?.question_activity_type === 'sound' && (
        <ToolSubheader>
          <ToolInput
            value={`items.${item_index}.question_subdata`}
            placeholder={'Insert Japanese text that will be displayed as a sound'}
            centerPlaceholder
          />
        </ToolSubheader>
      )}
      {item?.question_activity_type === 'image' && (
        <ToolSubheader>
          <QuizImageUploader
            item={item}
            qidx={item_index}
            qidy={'q'}
            handleSaveWidget={handleQuestionImageChange}
            course={course}
            lesson={lesson}
            sublesson={sublesson}
            widget_id={widget_id}
            handleRemoveImage={undefined}
            handleSetCommentary={undefined}
          />
        </ToolSubheader>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', paddingLeft: '10px', paddingTop: '10px', gap: '10px' }}>
        <SubTypeSelect>
          <select
            name='subtype'
            id='subtype'
            defaultValue={item?.activity_type}
            onChange={(e) => handleActivityTypeChange(item_index, e.target.value)}
          >
            <option value='text'>Text</option>
            <option value='sounds'>Sounds</option>
            <option value='images'>Images</option>
            <option value='input'>Input</option>
            <option value='fill-in-the-blank'>Fill-in-the-Blank</option>
            <option value='matching'>Matching</option>
          </select>
        </SubTypeSelect>
        {!['images', 'input', 'sounds'].includes(item?.activity_type) && (
          <SubTypeSelect>
            <span style={{ fontWeight: 'bold' }}>Forced Version</span>
            <select
              name='forced_version'
              id='forced_version'
              defaultValue={item?.forced_version}
              onChange={(e) => handleForcedVersionChange(item_index, e.target.value)}
              style={{ marginLeft: '10px' }}
            >
              <option value='none'>None</option>
              <option value='kanji'>Kanji</option>
              <option value='hirakata'>Hirakata</option>
              <option value='romaji'>Romaji</option>
            </select>
          </SubTypeSelect>
        )}
        {!['images', 'input', 'sounds'].includes(item?.activity_type) && (
          <ToolInput
            value={`items.${item_index}.display_sound_icon`}
            inputType='checkbox'
            inputLabel='Display Sound Icon'
            labelColor='var(--blue-gray)'
            labelPosition='before'
          />
        )}
      </div>
      {item?.activity_type !== 'images' && (
        <MiniQuizItemAnswers>
          {item?.activity_type !== 'matching' && (
            <MQItemAnswerRow>
              <ToolInput
                value={`items.${item_index}.correct_answer`}
                placeholder={`Correct Answer ${item_index + 1}`}
                bgColor='#dcf7dd'
                validationCb={(value: string) => value !== '' || 'Quiz answers can\'t be empty (correct answer)'}
              />
              <ToolInput
                value={`items.${item_index}.correct_commentary`}
                placeholder={`Correct Answer Commentary ${item_index + 1}`}
                bgColor='#dcf7dd'
              />
              <AddCorrectAnswer
                onClick={() => handleAddCorrectAnswer(item_index)}
                title='Add correct answer'
              >
                <Icon name='plus' fitted />
              </AddCorrectAnswer>
            </MQItemAnswerRow>
          )}
          {item?.multichoice_correct_answers?.map((_, multichoice_idx) => (
            <MQItemAnswerRow key={`multichoice-${multichoice_idx}`}>
              <ToolInput
                value={`items.${item_index}.multichoice_correct_answers.${multichoice_idx}`}
                placeholder={`Correct Answer ${multichoice_idx + 2}`}
                bgColor='#dcf7dd'
                validationCb={(value: string) => value !== '' || 'Quiz answers can\'t be empty (multichoice)'}
              />
              <ToolInput
                value={`items.${item_index}.multichoice_correct_commentary.${multichoice_idx}`}
                placeholder={`Correct Answer Commentary ${multichoice_idx + 2}`}
                bgColor='#dcf7dd'
              />
              <DeleteListItemButton
                  title='Remove correct answer'
                  onClick={() => handleRemoveCorrectAnswer(item_index, multichoice_idx)}
                >
                  <Icon name='delete' fitted />
                </DeleteListItemButton>
            </MQItemAnswerRow>
          ))}
          {!['input'].includes(item?.activity_type) && (
            <ListInputDivider
              handleClicked={() => handleAddWrongAnswer(item_index, -1)}
              borderStyle='dashed'
              buttonLeftShift={'calc(50% - 2.15rem)'}
              title='Add wrong answer'
            />
          )}
          {item?.other_answers?.map((_, answer_index) => (
            <React.Fragment key={`answers-${answer_index}`}>
              <MQItemAnswerRow>
                <ToolInput
                  value={`items.${item_index}.other_answers.${answer_index}`}
                  placeholder={`${item?.activity_type === 'matching' ? 'Left' : 'Wrong'} Answer ${answer_index + 1}`}
                  validationCb={(value: string) => value !== '' || 'Quiz answers can\'t be empty (other answers)'}
                />
                {!(item?.activity_type === 'matching') && (
                  <ToolInput
                    value={`items.${item_index}.other_commentary.${answer_index}`}
                    placeholder={`Wrong Answer Commentary ${answer_index + 1}`}
                  />
                )}
                {item?.activity_type === 'matching' && (
                  <ToolInput
                    value={`items.${item_index}.matching_answers.${answer_index}`}
                    placeholder={`Right Answer ${answer_index + 1}`}
                  />
                )}
                <DeleteListItemButton
                  onClick={() => handleRemoveWrongAnswer(item_index, answer_index)}
                  title='Remove wrong answer'
                >
                  <Icon name='delete' fitted />
                </DeleteListItemButton>
              </MQItemAnswerRow>
              {item?.activity_type === 'matching' && (
                <MQItemAnswerRow>
                  <ToolInput
                    value={`items.${item_index}.other_commentary.${answer_index}`}
                    placeholder={`Matching Commentary ${answer_index + 1}`}
                  />
                </MQItemAnswerRow>
              )}
              <ListInputDivider
                handleClicked={() => handleAddWrongAnswer(item_index, answer_index)}
                borderStyle='dashed'
                buttonLeftShift={'calc(50% - 2.15rem)'}
                title='Add wrong answer'
              />
            </React.Fragment>
          ))}
        </MiniQuizItemAnswers>
      )}
      {item?.activity_type === 'images' && (
        <div style={{ display: 'flex', gap: '20px', marginBottom: '50px', marginTop: '30px' }}>
          {item?.other_answers?.map((_, answer_index) => (
            <div key={`image-${item_index}-${answer_index}`}
              style={{ position: 'relative' }}
            >
              <QuizImageUploader
                item={item}
                qidx={item_index}
                qidy={answer_index}
                handleSaveWidget={handleAnswerImageChange}
                course={course}
                lesson={lesson}
                sublesson={sublesson}
                widget_id={widget_id}
                handleRemoveImage={() => handleRemoveWrongAnswer(item_index, answer_index)}
                handleSetCommentary={(commentary) => handleSetCommentary(item_index, answer_index, commentary)}
              />
              <div
                style={{
                  position: 'absolute',
                  bottom: '-30px',
                  left: '50px'
                }}
              >
                <ToolInput
                  value={`items.${item_index}.image_correct_answers.${answer_index}`}
                  inputType='checkbox'
                  inputLabel='Correct'
                  labelColor='var(--blue-gray)'
                  labelPosition='after'
                />
              </div>
            </div>
          ))}
          {item?.other_answers?.length < 4 && (
            <button
              onClick={() => handleAddWrongAnswer(item_index, item.other_answers.length - 1)}
              style={{ width: '100%', height: '100%', border: 'none', backgroundColor: 'transparent' }}
            >
              <Icon name='plus' size='big' />
            </button>
          )}
        </div>
      )}
      <ListInputDivider
        handleClicked={() => handleAddQuestion(item_index)}
        buttonLeftShift={'calc(50% - 2.15rem)'}
        title='Add Question'
        buttonAsText
        noBgFill
        buttonTextHShift={-45}
      />
      <div className='tw-mb-2'></div>
    </>
  )
}

export default MiniQuizItem
