import styled from 'styled-components'

const StudyButton = styled.button`
  cursor: pointer;
  font: inherit;
  font-weight: 600;
  border-radius: 13px;
  border: 2px solid white;
  color: white;
  padding: 0.25rem 1rem;
  display: flex;
  align-self: center;
  margin: 2rem auto;
  background-color: green;
  box-shadow: 0 0 0 2pt #1d191b;

  &:hover,
  &:active {
    background-color: #287320;
  }

  &:disabled {
    cursor: default;
    background-color: #6f6f6f;
  }
`

export default StudyButton
