import React, { useEffect, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { LIST } from '../toolkit.constants'
import { ListWidget, ListWidgetList } from 'src/types'

import CourseCreationToolkit from '../CourseCreationToolkit'
import {
  ColumnSwapper,
  IconButton,
  ListInputDivider,
  ToolFooter,
  ToolForm,
  ToolHeader,
  ToolInput,
  ToolOptions,
  ToolSubheader,
} from '../parts'

import { InputRow, ToolBody } from '../styles'
import useWidgetSaved from '../hooks/useWidgetSaved'
import { ChainLink, LineOptions, LinkItemsButton } from '../styles/ListTool.styles'
import { Icon } from 'semantic-ui-react'
import DialogueSpeakerSelect from './list/DialogueSpeakerSelect'
import FormatPreviewShell from 'src/components/fz_courses/sublessonpart/FormatPreviewShell'
import { FlexRow } from 'src/assets/styles/globalStyles'

const ListTool: React.FC<ToolProps<ListWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  outsideLoading,
  // justChanged,
  isGridCell = false,
  fetchSubLesson,
  fetchCounter,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [openCommentary, setOpenCommentary] = useState<number[]>([])
  const [columnsSwapped, setColumnsSwapped] = useState(false)
  const [storedList, setStoredList] = useState<ListWidgetList>()
  const editingWidgetIds = useToolkitStore((state) => state.editingWidgetIds)
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, loading, editWidget] = useWidget<ListWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const isOpen = isGridCell || editingWidgetIds?.includes(id)

  const [previewOpen, setPreviewOpen] = useState(false)
  const [refresher, setRefresher] = useState(0)

  const handleSaveWidget = async () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    await editWidget({ ...values, course })

    setSaved(true)
    fetchSubLesson()
  }

  const commitTemporaryChanges = (list: ListWidgetList) => {
    setStoredList(list)
    hookFormMethods.setValue('list', list)
  }

  useEffect(() => {
    if (editingWidgetIds?.includes(iw._id)) fetchWidget()
  }, [editingWidgetIds, outsideLoading])

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      
      const list = widget?.list

      const hasCommentary = []
      list.forEach((l, i) => {
        if (l.commentary?.content?.trim().length) hasCommentary.push(i)
      })

      setColumnsSwapped(widget?.swapColumnDisplay)

      setStoredList(list)
      setWidgetValues(widget)
      setOpenCommentary(hasCommentary)
    }
  }, [editingWidgetIds, widget, loading])

  useEffect(() => {
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const handleSubtypeUpdated = (newSubtype: string) => {
    const values = hookFormMethods.getValues()
    values.subtype = newSubtype

    // set vertical to true if the new subtype is SentencesWidget
    if (newSubtype === 'SentencesWidget') {
      values.vertical = true
    }

    if (!hookFormMethods.formState.isValid) return
    editWidget({ ...values, course })
  }

  const handleListUpdated = (updatedList: ListWidgetList, swapColumnDisplay?: boolean) => {
    commitTemporaryChanges(updatedList)
    hookFormMethods.setValue('swapColumnDisplay', swapColumnDisplay)
  }

  const handleChainItem = (chainFromIndex: number) => {
    const current = hookFormMethods.getValues()

    if (current?.list?.length > 1) {
      current.list[chainFromIndex].chained = !current.list[chainFromIndex].chained ?? true
      handleListUpdated(current.list)
    }
  }

  const handleAddToList = (insertAtIndex: number) => {
    const current = hookFormMethods.getValues()
    const newListItem = {
      english: '',
      other_lang: '',
      speaker: '',
      chained: false,
      commentary: {
        align: false,
        content: '',
      },
    }

    if (current?.list?.length <= 1 || !current?.list) {
      if (!current?.list) current.list = []
      current.list.push(newListItem)
    } else {
      current.list.splice(insertAtIndex + 1, 0, newListItem)
    }
    handleListUpdated(current.list)
  }

  const handleRemoveFromList = (removeAtIndex: number) => {
    const current = hookFormMethods.getValues()
    if (current?.list?.length > 1) {
      current.list.splice(removeAtIndex, 1)
      handleListUpdated(current.list)
    }
  }

  const onColumnsSwapped = () => {
    const current = hookFormMethods.getValues()
    const swapColumnDisplay = !current.swapColumnDisplay

    handleListUpdated(current.list, swapColumnDisplay)
    setColumnsSwapped(swapColumnDisplay)
  }

  const showCommentaryField = (i: number) => {
    let newIndices = [...openCommentary]
    if (newIndices?.includes(i)) {
      newIndices = newIndices.filter((idx) => idx !== i)
    } else {
      newIndices.push(i)
    }
    setOpenCommentary(newIndices)
  }

  const fieldLabels = {
    spaceEvery: 'Space Every',
    startNum: 'Start From',
    other_lang: 'Target Language',
    english: 'English',
    vertical: 'English Below',
    plays_sound: 'Play Sound',
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      widget_type={LIST}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      fetchCounter={fetchCounter}
    >
      <div className='textbox-tool'>
        {widget?._id ? (
          <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
            <ToolHeader
              widget_type={LIST}
              widget_subtype={hookFormMethods.watch()['subtype']}
              widget_id={id}
              isGridCell={isGridCell}
              onSubtypeChange={handleSubtypeUpdated}
              hookForm={hookFormMethods.getValues()}
              isListTool
            />
            <ToolOptions id={id} isGridCell={isGridCell}>
              <div>
                {['spaceEvery', 'startNum'].map((inp, idx) => (
                  <ToolInput
                    key={idx}
                    labelColor='var(--blue-gray)'
                    value={inp}
                    inputType='number'
                    inputLabel={fieldLabels[inp]}
                    labelPosition='before'
                    min={idx}
                  />
                ))}
                <ToolInput
                  labelColor='var(--blue-gray)'
                  value='ordered'
                  inputType='checkbox'
                  inputLabel='Numbered'
                  labelPosition='before'
                />
              </div>
            </ToolOptions>
            <ToolSubheader>
              <ToolInput value='title' placeholder='Header' />
            </ToolSubheader>
            <ToolBody>
              <ColumnSwapper
                handleColumnsSwapped={() => onColumnsSwapped()}
                inputDisabled
                swapped={columnsSwapped}
              />
              <Droppable displayName='ListItemDroppable' droppableId={`drop-list-${id}`} type='LIST_ITEM'>
                {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                {(provided, snapshot) => (
                  <div
                    style={{ width: '100%' }}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {storedList?.length ? (
                      storedList.map((litem, liIndex) => (
                        <Draggable
                          draggableId={`list-item-drag-${liIndex}-${id}`}
                          index={liIndex}
                          key={`list-item-${liIndex}`}
                          displayName='ListItemDraggable'
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                          {(innerProvided, innerSnapshot) => (
                            <InputRow
                              key={liIndex}
                              style={{ marginTop: liIndex > 0 ? '0px' : '' }}
                              ref={innerProvided.innerRef}
                              {...innerProvided.draggableProps}
                              {...innerProvided.dragHandleProps}
                              displayName='InputRow'
                            >
                              <FlexRow style={{ position: 'relative', width: '100%' }}>
                                <LineOptions
                                  style={{
                                    minWidth: widget?.subtype === 'DialogueWidget'
                                      ? 'max-content'
                                      : '30px',
                                    width: widget?.subtype === 'DialogueWidget'
                                      ? '20%'
                                      : '30px',
                                    marginRight: '1rem'
                                  }}
                                >
                                  <LinkItemsButton
                                    onClick={() => handleChainItem(liIndex)}
                                    active={litem.chained}
                                    linked={storedList[liIndex - 1]?.chained}
                                    title='Link to next item'
                                    disabled={liIndex === storedList.length - 1}
                                  >
                                    <Icon
                                      name={litem.chained ? 'chain' : 'broken chain'}
                                      fitted
                                      size='large'
                                    />
                                  </LinkItemsButton>
                                  {litem?.chained && (<ChainLink />)}
                                  {widget?.subtype === 'DialogueWidget' && (
                                    <DialogueSpeakerSelect line_index={liIndex} />
                                  )}
                                </LineOptions>
                                <LineOptions style={{ width: '100%' }}>
                                  {(columnsSwapped ? ['english', 'other_lang'] : ['other_lang', 'english']).map((inp, idx) => (
                                    <ToolInput
                                      key={idx}
                                      classNames={['tk-light-border']}
                                      value={`list.${liIndex}.${inp}`}
                                      placeholder={fieldLabels[inp]}
                                      onEdit={() => setRefresher((r) => r + 1)}
                                    />
                                  ))}
                                  <div style={{ width: openCommentary?.includes(liIndex) ? '100%' : '5%' }}>
                                    {openCommentary?.includes(liIndex) ? (
                                      <ToolInput
                                        classNames={['tk-light-border']}
                                        value={`list.${liIndex}.commentary.content`}
                                        placeholder={storedList[liIndex]?.commentary?.content || 'Commentary'}
                                      />
                                    ) : (
                                      <IconButton
                                        color='var(--add)'
                                        contrastColor='var(--off-white)'
                                        icon='comment alternate outline'
                                        handleClick={() => showCommentaryField(liIndex)}
                                      />
                                    )}
                                  </div>
                                  <IconButton
                                    color='var(--red)'
                                    contrastColor='var(--off-white)'
                                    handleClick={() => handleRemoveFromList(liIndex)}
                                    icon='delete'
                                  />
                                </LineOptions>
                              </FlexRow>
                              <ListInputDivider
                                handleClicked={() => handleAddToList(liIndex)}
                              />
                            </InputRow>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <ListInputDivider handleClicked={() => handleAddToList(0)} />
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </ToolBody>

            <ToolFooter
              lastEdited={widget?.updatedAt}
              lastEditedBy={widget?.last_edited_by}
              savedTimestamp={updatedAt}
              rawTimestamp={rawTimestamp}
              handleSave={() => {
                handleSaveWidget()
                onGridWidgetSave()
              }}
              saveDisabled={!hookFormMethods.formState.isValid}
              widget_id={id}
            >
              {['vertical', 'plays_sound'].map((inp, idx) => (
                <div key={idx}>
                  <ToolInput
                    value={inp}
                    inputType='checkbox'
                    inputLabel={fieldLabels[inp]}
                    labelPosition='after'
                    labelColor='white'
                  />
                </div>
              ))}
              {/* eyeball icon button that will open the widget render preview */}
              <div>
                <IconButton
                  icon='eye'
                  backgroundColor='transparent'
                  contrastColor='white'
                  title='Preview'
                  handleClick={() => setPreviewOpen((p) => !p)}
                />
              </div>
            </ToolFooter>
            {previewOpen && (
              <FormatPreviewShell
                widget={{ ...hookFormMethods.getValues(), id }}
                type='list'
                refresher={refresher}
                course={course}
              />
            )}
          </ToolForm>
        ) : (
          <div style={{ padding: '20px 0' }}>
            <p>Editable data not initialized.</p>
          </div>
        )}
      </div>
    </CourseCreationToolkit>
  )
}

export default ListTool
