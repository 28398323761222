import { createGlobalStyle } from 'styled-components'

const JFZFont = createGlobalStyle`
  html {
    --small-font-size: 1rem;
    --reg-font-size: 1.5rem;
    --large-font-size: 1.8rem;

    --font-size: 1.5rem;
    --fs2: 2rem;
    --fs3: 3rem;

    --off-white: #fbfaff;
    --bg-color: var(--off-white); // changes on dark mode

    --orange: #ffae20;
    --yellow: #fbc531;
    --red: #e84118;
    --blue: #3765B0;
    --jfz-blue: var(--blue);
    --sub-blue: #ADC1E0;

    --blue-gray: #51536b;
    --blue-grey: var(--blue-gray);
    --text-color: var(--blue-gray);
    --light-blue-gray: #6e7192;
    --light-blue-grey: var(--light-blue-gray);
    --light-text-color: var(--light-blue-gray);

    --lesson-font-color: var(--blue-gray);

    --blue-border: 1px solid var(--blue);
    --blue-border-thick: 2px solid var(--blue);
    --blue-border-thicker: 4px solid var(--blue);
    --jfz-blue-border: var(--blue-border);

    /* @media (prefers-color-scheme: dark) {
      --bg-color:  #1a1a1f;
      --text-color: #d0d2f2;
      --white-font: var(--off-white);
      --black-font: var(--blue-gray);
    } */
    
    @media (max-width: 768px) {
      --small-font-size: 0.8rem;
      --reg-font-size: 1.1rem;
      --large-font-size: 1.3rem;

      --font-size: var(--reg-font-size);
    }

    /* @media only screen and (orientation: landscape) {
      body #root {
        width: 100vh !important;
        height: 100vw !important;
        transform: rotate(-90deg);
      }
    } */
  }
`

export default JFZFont
