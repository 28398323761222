import React from 'react'
import ConnectForm from '../../tools/ConnectForm'

import { DialogueSpeakerSelector } from '../../styles/ListTool.styles'

const DialogueSpeakerSelect: React.FC<{ line_index: number }> = ({
  line_index,
}) => {
  return (
    <DialogueSpeakerSelector>
      <label htmlFor='speaker-choice'>Speaker:</label>
      <ConnectForm>
      {({ register }) => (
        <input
          list='speakers'
          id='speaker-choice'
          name='speaker-choice'
          {...register(`list.${line_index}.speaker`)}
        />
      )}
      </ConnectForm>
      <datalist id='speakers'>
        {['Tomoko', 'Kenji', 'Mari', 'Ren', 'Rika', 'Natsumi'].map((name) => (
          <option key={name} value={name} />
        ))}
      </datalist>
    </DialogueSpeakerSelector>
  )
}

export default DialogueSpeakerSelect
