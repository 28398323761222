import React from 'react'
import gfm from 'remark-gfm'
import ReactMarkdown, { Options } from 'react-markdown'

const color_syntax_regex = /\|:.*?:\|/ig
const underscores_regex = /(__([^:]+\s*)__)/ig

const MarkdownBlock: React.FC<{
  content: string,
  options?: Options
}> = ({ content, options }) => {
  // return <>{content}</>
  const renderer = {
    p({ children }) {
      return <span>{children}</span>
    },
    // two * wrapping a string
    strong({ children }) {
      // let _children = children
      // // match strings surrounded with underscores
      // const double_underscores = /(?:^|\s)[^@#\s_]*(__([^_]+)__)/
      // if (double_underscores?.test(content)) {
      //   const matched = content?.match(double_underscores)
      //   console.log(content[node.position.start.column])
      //   return <span className='tw-underline'>{_children}</span>
      // }
      return <strong>{children}</strong>
    },
    li({ children }) {
      if (!children) return null
      return <li style={{ listStyle: 'none' }}>{children}</li>
    },
    // one/three * or two/three _ wrapping a string
    em({ children }) {
      return <em style={{ fontStyle: 'italic' }}>{children}</em>
    },
    ins({ children }) {
      return <ins>{children}</ins>
    },
    a({ children, node }) {
      return <a href={node.href}>{children}</a>
    },
    /* eslint-disable max-len */
    // code({ children }) {
    //   return (
    //     <code className='tw-bg-slate-900 tw-text-white tw-py-2 tw-px-4 tw-shadow-md tw-rounded-lg tw-border-2 tw-border-slate-700'>
    //       {children}
    //     </code>
    //   )
    // },
    /* eslint-enable */
    table({ children }) {
      return (
        <table className='tw-w-full tw-border-collapse tw-border-2 tw-border-gray-300 tw-rounded-lg tw-overflow-hidden'>
          {children}
        </table>
      )
    }
  }

  const restoreLineBreaks = (part) => {
    const result = []
    part.split('\n').forEach((line, index) => {
      if (line === '') result.push(<br key={`break-${index}`} />)
      else {
        result.push(
          <ReactMarkdown key={`rmd-${index}`} remarkPlugins={[gfm]} components={options?.components || renderer}>
            {line}
          </ReactMarkdown>
        )
      }
    })
    return result
  }

  const underlinify = (str) => {
    // TODO: make this logic similar to `colorify` below
    if (!str) return
    const parts = str.split(underscores_regex)
    const result = [parts[0]]

    if (parts.length > 1) {
      for (let i = 1; i < parts.length; i += 1) {
        if (underscores_regex.exec(parts[i])) {
          if (i === 1) {
            const output = parts[i].replaceAll('__', '')
            result.push(' ')
            result.push(<span key={`underline-${i}`} className='tw-underline'>{output}</span>)
            result.push(' ')
          }
        } else if (i !== 2) {
          result.push(restoreLineBreaks(parts[i]))
        }
      }
    }

    return result
  }

  const colorify = (str: string) => {
    const color = null
    const backgroundColor = 'transparent'
    const output = []

    const matched = color_syntax_regex.exec(str)
    if (matched) {
      const { index } = matched
      // split string on first |:
      const split = str.split(`${str[index]}:`)
      output.push(split[0])

      // TODO: if split is longer than 2 items, there is more coloring to do
      // i'm too drained on this task to make a function to do all the below multiple times

      // get first word of matched syntax, this is now the color or bg color
      const color_match = split[1].substring(0, split[1].indexOf(' '))
      // get the rest of the string contained in matching syntax
      const ending_syntax = split[1].indexOf(':|')
      const color_me = split[1].substring(split[1].indexOf(' ') + 1, ending_syntax)
      // get the rest of the string after the matching syntax
      const rest_of_str = split[1].split(color_me)[1].replace(':|', '')

      let styling = {
        color,
        backgroundColor,
        padding: '0',
        borderRadius: '0',
      }
      // determine whether to apply color or background color
      if (color_match?.startsWith('bg-')) {
        styling = {
          color: 'white',
          backgroundColor: color_match.substring(3),
          padding: '4px',
          borderRadius: '4px',
        }
      } else {
        styling.color = color_match
      }

      output.push(<span style={styling} key='colored'>{color_me}</span>)
      output.push(rest_of_str)

      return output
    }

    // TODO: check for underline syntax

    // seems to be an issue when asterisk is used to denote that more information is noted below
    // (causes react-markdown to try making a list)
    if (!str?.length || str.trim() === '*') return null
    // no matched color syntax, defer to react-markdown
    return (
      <ReactMarkdown remarkPlugins={[gfm]} components={options?.components || renderer}>
        {str}
      </ReactMarkdown>
    )
  }

  if (!content?.length) return null

  return (
    <>
      {colorify(content)}
    </>
  )
}

export default MarkdownBlock
