import styled from 'styled-components'

const PlayField = styled.div`
  position: relative;
  margin: auto;
  box-shadow: 0 10px 10px 10px rgba(0, 0, 0, 0.25);
  padding: 0.5rem;
  margin-top: 5rem;
  width: 30vw;
  height: 30%;
  max-height: 30%;
  -moz-outline-radius: 10px;
  color: white;
  text-align: center;
  background-color: #f9f3ec;
  border-radius: 13px;
  border: 4px solid #847d72;
  box-shadow: 0 0 0 3pt #1d191b;

  .padding {
    padding: 0;
    height: 2.5rem;
  }

  & h2 {
    font-size: 72px;
    font-weight: 200;
    color: black;
    padding: 4rem;
    margin: 0;
  }

  & h5 {
    font-size: 20px;
    padding: 2rem;
    color: black;
    font-weight: 400;
  }

  & label {
    color: black;
  }

  & input {
    text-transform: 'lowercase'; 
    text-align: 'center';

    ::placeholder {
      font-size: 10px;
      opacity: 1;
    }
  }

  & p {
    color: black;
    display: inline-block;
  }

  .correct {
    color: green;
    padding-right: 0.5rem;
  }

  .feedback {
    display: block;
    color: red;
  }

  & h6 {
    color: red;
    font-weight: 400;
    font-size: 18px;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 740px) {
    width: 70%;
  }
`
export default PlayField
