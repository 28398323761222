import React, { useCallback, useEffect, useState } from 'react'
import LoginModal from '../modals/LoginModal'
import { connect } from 'react-redux'
import axios from '../../api'
import { Redirect, Route, useLocation } from 'react-router-dom'

import { signOut } from '../../actions'
import { AxiosError } from 'axios'

const privateCore = ({ component: Component, ...rest }) => {
  const [authed, setAuthed] = useState(true)
  const location = useLocation()

  if (!rest.isSignedIn) return <LoginModal />
 
  const course = rest?.computedMatch?.params?.id
  const routePath = location.pathname.split('/').filter((p) => p)[0]
  const isCoursesPath = routePath === 'courses'
  // if we're not staff and try to access jfz1 after japanese1 (v8) is live, we have to
  // redirect to it (only if we're at a courses route)
  // so e.g., /courses/jfz1/1/1 -> /courses/japanese1/6/1
  // (lessons are +5 in japanese1 from jfz1)
  // prelessons need to be converted to a number (A-E mapping to 1-5)
  const v8Redirect = false
  if (v8Redirect && course === 'jfz1' && isCoursesPath) {
    const lesson = rest?.computedMatch?.params?.lesson
    const sublesson = rest?.computedMatch?.params?.sublesson
    const redirectStr = '/courses/japanese1/'

    if (!lesson) return <Redirect to={redirectStr} />

    const newLesson = parseInt(lesson, 10) ? parseInt(lesson, 10) + 5 : lesson.charCodeAt(0) - 64
    return <Redirect to={`${redirectStr}${newLesson}/${sublesson}`} />
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        authed ? (rest.render?.(props) || <Component {...props} />) : <LoginModal />
      )}
    />
  )
}

const mapStateToPropsPrivate = (state) => ({ isSignedIn: state.auth.isSignedIn })
const PrivateRoute = connect(mapStateToPropsPrivate, { signOut })(privateCore)

export default PrivateRoute
