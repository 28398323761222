import { Link, useLocation } from 'react-router-dom'
import { Button } from 'semantic-ui-react'

const LoggedOutNav = () => {
  const { pathname } = useLocation()
  if (['/es', '/login', '/forgot_password', '/claim'].includes(pathname)
    || pathname.indexOf('/reset/') !== -1) return <></>
  
  return (
    <div className='logged-out-nav'>
      <div>
        <Button
          className="fz-button"
          as={Link}
          to='/login'
          data-cy='sign-in-btn'
        >
          Sign In
        </Button>
      </div>
    </div>
  )
}

export default LoggedOutNav
