import styled from 'styled-components'
import tw from 'twin.macro'

export const TLSection = styled.div`
  ${tw`tw-w-full tw-flex tw-flex-row tw-items-start tw-gap-2 tw-mt-4 tw-pl-6 tw-pr-2`}

  :last-child {
    ${tw`tw-mb-4`}
  }
`

export const InsetDiv = styled.div`
  ${tw`tw-w-full tw-pl-8 tw-pr-16`}
`

export const TLContent = styled.div`
  ${tw`tw-w-full tw-flex tw-flex-col`}
`

export const TextListPair = styled.div`
  ${tw`tw-flex tw-flex-row tw-items-center tw-justify-between tw-gap-6 tw-mt-2`}
`

export const VerticalButtonGroup = styled.div`
  ${tw`tw-flex tw-flex-col tw-items-center tw-gap-3`}
`

export const AddGroupLine = styled.div`
  ${tw`tw-w-11/12 tw-mb-6 tw-mt-4`}

  border-bottom: 1px solid var(--blue-gray);

  >div {
    ${tw`tw-relative tw-mt-2`}

    >button {
      background-color: var(--main);
      color: var(--off-white);
      ${tw`tw-py-1 tw-px-2 tw-rounded-lg tw-absolute`}
      top: -12px;
      left: -24px;
    }
  }
`
