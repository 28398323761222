import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setPopup } from 'src/actions'
import * as R from 'ramda'

import { Loader } from 'semantic-ui-react'
import { useScrollIntoView } from '@mantine/hooks'

import TeacherNote from '../admin/TeacherNote'

import useHandleKeyDown from 'src/hooks/useHandleKeyDown'
import useMobile from 'src/hooks/useMobile'
import useBrowser from 'src/hooks/useBrowser'
import useSoundText from 'src/hooks/useSoundText'
import useRecord from 'src/hooks/useRecord'
import useSound from 'src/hooks/useSound'
import useIsPlaying from 'src/hooks/useIsPlaying'
import useStaffRoles from 'src/hooks/useStaffRoles'
import { TNumSpan } from 'src/assets/styles/globalStyles'

import PlayButton from './PlayButton'
import SoundIconStyleWrapper from './SoundIcon.styles'

import { RootState } from 'src/configureStore'

const useCustomProgressive = () => {
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const customEnabled = progSettings?.custom_progressive?.enabled
  const customSettings = progSettings?.custom_progressive?.settings

  return { customEnabled, customSettings }
}

const SoundIcon = (props) => {
  const {
    widget,
    noPopup,
    onSound,
    num,
    playingNum,
    male,
    female,
    swap_furi_colors,
    record: r,
    base_text,
    progressive_settings: {
      main_default = 'progressive', furi_opacity = 100
    } = {},
    preferred_speaker,
    kanjiData: kd,
    ping,
    presentation,
    formatText,
    teacherNote: note,
    widgetType
  } = props

  const { record, bonusData: bd } = useRecord(r, base_text, widget)
  const playButtonRef = useRef(null)
  const { bonusData, noIcon, iconOnly } = bd || {}
  const { customEnabled, customSettings } = useCustomProgressive()
  const { playing, setPlaying, playAudio, setInternalAudioSrc } = useIsPlaying(record, playingNum, num, props.cafe, playButtonRef, ping)
  const [holdsFuri, setHoldsFuri] = useState(false)
  const [furiVisible, setFuriVisible] = useState(false)
  const location = useLocation()
  const isMobile = useMobile()
  const currentSoundPopup = useSelector((state: RootState) => state.soundPopup?.activePopup)
  const [browser, os] = useBrowser()
  const { sound } = useSound(record, male, female, preferred_speaker)
  const bonusForceMode = bonusData?.force_display
  const { forcedVersion } = props
  const [showEnglish, setShowEnglish] = useState(false)
  const [highlightSound, setHighlightSound] = useState(false)
  const [isStaffRole] = useStaffRoles()
  const { targetRef, scrollIntoView } = useScrollIntoView<HTMLQuoteElement>({ offset: 80 })
  const toggleShowEnglish = useCallback(() => isStaffRole ? setShowEnglish((prev) => !prev) : null, [isStaffRole])
  // const colorized_furi = useSelector((state: RootState) => state.auth.colorized_furi)

  useHandleKeyDown('E', 'shift', toggleShowEnglish)

  const { translation } = props
  const englishToShow = Array.isArray(translation) ? translation.map((t) => t.text).join(' ') : translation || record?.english?.text || 'ENGLISH MISSING'

  // leaves space to click "text" to open popup if noIcon is true; not needed for soundQuestion
  const IconOnly = props.soundQuestion ? <></> : <>&nbsp;&nbsp;</>

  const forceMode = {
    romaji: 'romaji',
    kana: 'hirakata',
    hirakata: 'hirakata',
    kanji: 'kanji'
  }[forcedVersion && forcedVersion !== 'none' ? forcedVersion : bonusForceMode]
  const { soundText } = useSoundText(record, {
    ...props, location, main_default, forceMode, holdsFuri, setHoldsFuri, furi_opacity, customEnabled, customSettings, bonusData, formatText
  })

  const kanjiData = R.pipe(
    R.unnest,
    R.indexBy(R.prop('literal'))
  )(kd || [])

  useEffect(() => {
    // check that there's actually a sound popup in state so we don't flood redux with empty actions
    if (!currentSoundPopup) return

    setFuriVisible(false)
    props.setPopup(null)
  }, [location])

  useEffect(() => {
    // denotes specific sound from bug report/aat
    const soundID = location?.search.split('soundID=')
    if (soundID?.length > 1) {
      if (record?._id === soundID[1]) {
        setHighlightSound(true)
        const scrollDelay = setTimeout(() => scrollIntoView(), 1600) // if part in path, wait for part scroll to finish (1500ms)
        return () => clearTimeout(scrollDelay)
      }
    }
  }, [location])

  useEffect(() => {
    if (currentSoundPopup !== props.path) setFuriVisible(false)
  }, [currentSoundPopup])

  useEffect(() => {
    if (playing) setFuriVisible(false)
  }, [playing])

  useEffect(() => {
    // initialize audio with callbacks
    if (onSound) onSound(num, !bonusData?.noIcon, sound)

    setInternalAudioSrc(sound)
  }, [sound])

  const openPopup = useCallback((e) => {
    if (props.noPopup) return

    const rect = e?.target?.getBoundingClientRect()

    props.setPopup({
      path: props.path || props.widget || props.lessonPath,
      record,
      widget: props.widget,
      note,
      onSave: props.onSave,
      x: rect.x,
      y: rect.bottom + rect.height + window.scrollY + 400,
      english: englishToShow,
      widgetType,
      bonusData
    })
  }, [englishToShow])

  const handleSoundPopup = (e) => {
    const recordId = record?._id || props?.record?._id || null
    if (!recordId) return

    if (isMobile) {
      if ((['progressive', 'kanji'].includes(main_default))) setFuriVisible(true)
      else setFuriVisible(false)
    }

    openPopup(e)
  }

  const handleClick = (e) => {
    if (presentation) playAudio(e, false)
    else if (noPopup) e.preventDefault()
    else handleSoundPopup(e)
  }

  const browserShort = useMemo(() => {
    if (!browser) return 'chrome'
    return browser.split(' ')[0].toLowerCase()
  }, [browser])

  const osShort = useMemo(() => {
    if (!os) return 'windows'
    return os.split(' ')[0].toLowerCase()
  }, [os])

  // Wrapper as <blockquote> for letting screen readers know the text inside of this is not English
  return (record || props.record) ? (
    <SoundIconStyleWrapper
      noPopup={noPopup}
      playing={(Object.keys(record.sounds || [])?.length > 0 && (playing || props.playing)) && true}
      holdsFuri={holdsFuri}
      progSetting={main_default}
      furiOpacity={furi_opacity}
      lang="jp"
      inverted={props.inverted}
      white={props.white}
      wrapAtIcon={props.wrapAtIcon}
      browser={browserShort}
      os={osShort}
      noFuri={bonusData?.no_furi}
      huge={props.huge}
      soundQuestion={props.soundQuestion}
      swapFuriColors={swap_furi_colors}
      presentation={presentation}
      highlightSound={highlightSound}
      ref={targetRef}
      isMobile={isMobile}
    >
      {props?.lineNum && <TNumSpan>{props.lineNum}</TNumSpan>}
      <span className='sound'>
        {props?.icon
        && !props.record?.icon
        && !noIcon
        && (props.display_sound_icon !== false || props.soundQuestion)
        && !presentation
        && (
          <PlayButton
            data-t={`sound-icon-record:${record?._id}-widget:${props.widget}`}
            huge={props.huge}
            iconOnly={iconOnly || props.iconOnly}
            playAudio={playAudio}
            record={record}
            playing={playing || props.playing}
            soundQuestion={props.soundQuestion}
            innerRef={playButtonRef}
            isMobile={isMobile}
          />
        )}
        <span>
          <span
            data-t={`sound-text-${record?._id}`}
            tabIndex={0}
            className={isMobile && furiVisible ? ' visible' : ''}
            onKeyUpCapture={(e) => e.key === 'Enter' && handleSoundPopup(e)}
            onClick={handleClick}
          >
            {(iconOnly || props.soundQuestion || props.iconOnly) ? IconOnly : soundText}
          </span>
          {note && <TeacherNote white={props.white} text={note?.text} />}
          {showEnglish && (
            <span className='english'>
              (英語: {englishToShow})
            </span>
          )}
        </span>
      </span>
    </SoundIconStyleWrapper>
  ) : (
    <Loader active inline />
  )
}

const mapStateToProps = (state) => {
  const {
    progressive_settings, progressive_data, preferred_speaker, colorized_furi, swap_furi_colors
  } = state.auth
  return {
    progressive_settings,
    progressive_data,
    preferred_speaker,
    colorized_furi,
    swap_furi_colors
  }
}

export default connect(mapStateToProps, { setPopup })(SoundIcon)
