import React, { useEffect, useState } from 'react'
import * as R from 'ramda'

import { Grid, Segment } from 'semantic-ui-react'

const KanjiPreview = props => {
    console.log(props)

    return (
        <Segment style={{padding: '0px'}}>
            <Grid celled='internally' style={{fontFamily: 'Milliard Book, Yu Gothic UI Regular', fontSize: '1.25rem'}}>
                <Grid.Row>
                    <Grid.Column width={4} style={{padding: '0px', fontFamily: 'Milliard Bold, Yu Gothic UI Regular'}}>
                        <Grid celled='internally' verticalAlign='middle'>
                            <Grid.Row textAlign='center'>
                                <Grid.Column width={5} style={{backgroundColor: '#2e64bc', color: 'white'}}>
                                    {props.data?.number}
                                </Grid.Column>
                                <Grid.Column width={6} style={{backgroundColor: '#adc1e0'}}>
                                    {props.data?.radical}
                                </Grid.Column>
                                <Grid.Column width={5} style={{fontFamily: 'Milliard Book, Yu Gothic UI Regular'}}>
                                    {props.data?.strokes} 画
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column width={16} textAlign='center'>
                                    <div id={props.data?.kanji} style={{fontSize: '10rem', paddingTop: '25%'}}>
                                        {props.data?.kanji}
                                    </div>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={12} style={{padding: '0px'}}>
                        <Grid celled='internally'>
                            <Grid.Row>
                                <Grid.Column textAlign='center' width={16} style={{fontFamily: 'Milliard Bold'}}>{props.data?.meaning}</Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign='center' width={3} style={{backgroundColor: '#adc1e0'}}>
                                    くんよみ
                                </Grid.Column>
                                <Grid.Column width={13}>
                                    {props.data?.kunyomi}
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column textAlign='center' width={3} style={{backgroundColor: '#adc1e0'}}>
                                    おんよみ
                                </Grid.Column>
                                <Grid.Column width={13}>
                                    {props.data?.onyomi}
                                </Grid.Column>
                            </Grid.Row>
                            {props.data?.examples && R.splitEvery(2)(props.data?.examples).map(exs =>
                                <Grid.Row>
                                    <Grid.Column width={8}>
                                        {exs[0]?.japanese} {exs[0]?.english}
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        {exs[1]?.japanese} {exs[1]?.english}
                                    </Grid.Column>
                                </Grid.Row>)}
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Segment>
    )
}

export default KanjiPreview