import { REDUX_NAV_ACTIONS } from './types'

export const setSlug = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_SLUG,
  payload
})

export const setSubLesson = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_SUBLESSON,
  payload
})

export const setNumLessons = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_NUM_LESSONS,
  payload
})

export const setNumPrelessons = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_NUM_PRELESSONS,
  payload
})

export const setAvatarCf = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_AVATAR_CF,
  payload
})

export const setBarColor = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_BAR_COLOR,
  payload
})

export const setNotificationCount = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_NOTIFICATION_COUNT,
  payload
})

export const setAdminView = (payload) => ({
  type: REDUX_NAV_ACTIONS.SET_ADMIN_VIEW,
  payload
})
