import {
  LOAD_COURSES,
  SET_USER_AGENT,
  SIGN_OUT,
  TOGGLE_DARK_MODE,
  UPDATE_HISTORY,
} from '../actions/types'

const INITIAL_STATE = {
  browser: '',
  courses: null,
  darkMode: false,
  history: ['/'],
  os: '',
}

// eslint-disable-next-line
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_COURSES: {
      return { ...state, courses: action.payload.map((e) => e.identifier) }
    }
    case SET_USER_AGENT: {
      return { ...state, browser: action.payload.browser, os: action.payload.os }
    }
    case SIGN_OUT: {
      return { ...INITIAL_STATE }
    }
    case TOGGLE_DARK_MODE: {
      return { ...state, darkMode: !state.darkMode }
    }
    case UPDATE_HISTORY: {
      if (!state.history) {
        return { ...state, history: ['/'] }
      }

      const newPath = `${action.payload.pathname}${action.payload.search}`
      let updatedHistory = state.history

      // only update if the incoming path is not the last path in history
      if (updatedHistory && updatedHistory[updatedHistory.length - 1] !== newPath) {
        updatedHistory = [...updatedHistory, newPath]
        if (updatedHistory.length > 15) updatedHistory.shift()
      }

      return { ...state, history: updatedHistory }
    }
    default: {
      return state
    }
  }
}
