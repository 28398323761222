import { PropsWithChildren } from 'react'
/**
 * OLD EDITORS
 */
// import ConversationWidgetEditor from '../admin/editor/ConversationWidgetEditor'
import DialogueWidgetEditor from '../admin/editor/DialogueWidgetEditor'
import ExamplesWidgetEditor from '../admin/editor/ExamplesWidgetEditor'
import FillInTheBlanksWidgetEditor from '../admin/editor/activities/FillInTheBlanksWidgetEditor'
// import GridWidgetEditor from '../admin/editor/GridWidgetEditor'
import ImageQuestionWidgetEditor from '../admin/editor/activities/ImageQuestionWidgetEditor'
// import ImageWidgetEditor from '../admin/editor/ImageWidgetEditor'
// import KanjiWidgetEditor from '../admin/editor/KanjiWidgetEditor'
import LineConnectWidgetEditor from '../admin/editor/activities/LineConnectWidgetEditor'
// import MiniQuizWidgetEditor from '../admin/editor/MiniQuizWidgetEditor'
// import MoreDetailsWidgetEditor from '../admin/editor/MoreDetailsWidgetEditor'
import PhraseWidgetEditor from '../admin/editor/PhraseWidgetEditor'
import QnAStructureWidgetEditor from '../admin/editor/QnAStructureWidgetEditor'
// import QnAWidgetEditor from '../admin/editor/QnAWidgetEditor'
import QuestionAndAnswerWidgetEditor from '../admin/editor/activities/QuestionAndAnswerWidgetEditor'
import ReadingComprehensionWidgetEditor from '../admin/editor/activities/ReadingComprehensionWidgetEditor'
import SentenceBuilderWidgetEditor from '../admin/editor/activities/SentenceBuilderWidgetEditor'
import SentenceTranslationWidgetEditor from '../admin/editor/activities/SentenceTranslationWidgetEditor'
import SentencesWidgetEditor from '../admin/editor/SentencesWidgetEditor'
// import StructureWidgetEditor from '../admin/editor/StructureWidgetEditor'
// import TableWidgetEditor from '../admin/editor/TableWidgetEditor'
// import TextBoxWidgetEditor from './admin/editor/TextBoxWidgetEditor'
// import TextListWidgetEditor from '../admin/editor/TextListWidgetEditor'
// import UsageWidgetEditor from '../admin/editor/UsageWidgetEditor'
// import VideoWidgetEditor from '../admin/editor/VideoWidgetEditor'

/**
 * WIDGET
 */
import ConversationWidget from '../widgets/ConversationWidget'
import DialogueWidget from '../widgets/DialogueWidget'
import DownloadWidget from '../widgets/DownloadWidget'
import ExamplesWidget from '../widgets/ExamplesWidget'
import FillInTheBlanksWidget from '../widgets/activities/FillInTheBlanksWidget'
import GridWidget from '../widgets/GridWidget'
// import FillInTheKanjiWidget from './widgets/activities/FillInTheKanjiWidget'
import ImageQuestionWidget from '../widgets/activities/ImageQuestionWidget'
import ImageWidget from '../widgets/ImageWidget'
import KanjiMemoryWidget from '../widgets/KanjiMemoryWidget'
import KanjiWidget from '../widgets/KanjiWidget'
import LineConnectWidget from '../widgets/activities/LineConnectWidget'
import ListWidget from '../widgets/ListWidget'
import MiniQuizWidget from '../widgets/MiniQuizWidget'
import MoreDetailsWidget from '../widgets/MoreDetailsWidget'
import PhraseWidget from '../widgets/PhraseWidget'
import PresentationWidget from '../widgets/PresentationWidget'
import QnAStructureWidget from '../widgets/QnAStructureWidget'
import QnAWidget from '../widgets/QnAWidget'
import QuestionAndAnswerWidget from '../widgets/activities/QuestionAndAnswerWidget'
import ReadingComprehensionWidget from '../widgets/activities/ReadingComprehensionWidget'
import SentenceBuilderWidget from '../widgets/activities/SentenceBuilderWidget'
import SentencesWidget from '../widgets/SentencesWidget'
import SentenceTranslationWidget from '../widgets/activities/SentenceTranslationWidget'
import StructureWidget from '../widgets/StructureWidget'
import TableWidget from '../widgets/TableWidget'
import TextBoxWidget from '../widgets/TextBoxWidget'
import TextListWidget from '../widgets/TextListWidget'
import UsageWidget from '../widgets/UsageWidget'
import VideoWidget from '../widgets/VideoWidget'

/**
 * NEW EDITORS
 */
import {
  ConversationTool,
  DownloadTool,
  GridTool,
  ImageTool,
  KanjiMemoryTool,
  KanjiTool,
  ListTool,
  MiniQuizTool,
  MoreDetailsTool,
  QnATool,
  PresentationTool,
  StructureTool,
  TableTool,
  TextboxTool,
  TextListTool,
  UsageTool,
  VideoTool,
} from '../admin/creation_toolkit/tools'

/**
 * WIDGET DATA MANAGEMENT COMPONENTS
 * ???
 */
export { default as DataGrabberButton } from '../admin/editor/DataGrabberButton'
export { default as DeleteWidgetButton } from '../admin/editor/DeleteWidgetButton'
export { default as RefreshWidgetCacheButton } from '../admin/editor/RefreshWidgetCacheButton'
export { default as ListifyButton } from '../admin/editor/ListifyButton'

/**
 * WHAT THESE I DON'T KNOW
 */
export const topKeys = {
  ConversationWidget: 'conversations',
  DialogueWidget: 'lines',
  DownloadWidget: 'download',
  ExamplesWidget: 'examples',
  FillInTheBlanksWidget: 'activity-fill-in-the-blanks',
  // 'FillInTheKanjiWidget': 'activity-fill-in-the-kanji',
  GridWidget: 'grid',
  ImageWidget: 'path',
  ImageQuestionWidget: 'questions',
  InvalidWidget: 'invalid',
  KanjiMemoryWidget: 'kanji-memory',
  KanjiWidget: 'kanji',
  LineConnectWidget: 'pairs',
  ListWidget: 'list',
  MiniQuizWidget: 'items',
  MoreDetailsWidget: 'contents',
  PhraseWidget: 'phrase',
  PresentationWidget: 'presentation',
  QnAWidget: 'blocks',
  QnAStructureWidget: 'qna_structure',
  QuestionAndAnswerWidget: 'question-and-answer',
  ReadingComprehensionWidget: 'reading-comprehension',
  SentencesWidget: 'sentences',
  SentenceBuilderWidget: 'activity-sentence-builder',
  SentenceTranslationWidget: 'activity-sentence-translation',
  StructureWidget: 'parts',
  TableWidget: 'table',
  TextboxWidget: 'body',
  TextListWidget: 'sections',
  UsageWidget: 'usage',
  VideoWidget: 'yt_id',
}

export const widgetOptions: {
  [key: string]: {
    w: (props) => JSX.Element
    // eslint-disable-next-line
    e: (props: PropsWithChildren<any>) => JSX.Element
    value: string
    text: string
  }
} = {
  ConversationWidget: {
    w: ConversationWidget,
    // e: ConversationWidgetEditor,
    e: ConversationTool,
    value: 'conversation',
    text: 'Conversation Widget',
  },
  DialogueWidget: {
    w: DialogueWidget,
    e: DialogueWidgetEditor,
    value: 'dialogue',
    text: 'Dialogue Widget',
  },
  DownloadWidget: {
    w: DownloadWidget,
    e: DownloadTool,
    value: 'download',
    text: 'Download Widget',
  },
  ExamplesWidget: {
    w: ExamplesWidget,
    e: ExamplesWidgetEditor,
    // e: ListTool,
    value: 'examples',
    text: 'Examples Widget',
  },
  FillInTheBlanksWidget: {
    w: FillInTheBlanksWidget,
    e: FillInTheBlanksWidgetEditor,
    value: 'activity-fill-in-the-blanks',
    text: 'Fill In The Blanks Widget',
  },
  /* {
       w: FillInTheKanjiWidget',
       value: 'activity-fill-in-the-kanji',
       text: 'Fill In The Kanji Widget'
     },
  */
  GridWidget: {
    w: GridWidget,
    e: GridTool,
    // e: GridWidgetEditor,
    value: 'grid',
    text: 'Grid Widget',
  },
  ImageWidget: {
    w: ImageWidget,
    e: ImageTool,
    // e: ImageWidgetEditor,
    value: 'image',
    text: 'Image Widget',
  },
  ImageQuestionWidget: {
    w: ImageQuestionWidget,
    e: ImageQuestionWidgetEditor,
    value: 'image-question',
    text: 'Image Question Widget',
  },
  KanjiMemoryWidget: {
    w: KanjiMemoryWidget,
    e: KanjiMemoryTool,
    // e: KanjiMemoryWidgetEditor,
    value: 'kanji-memory',
    text: 'Kanji Memory Widget',
  },
  KanjiWidget: {
    w: KanjiWidget,
    // e: KanjiWidgetEditor,
    e: KanjiTool,
    value: 'kanji',
    text: 'Kanji Widget',
  },
  ListWidget: {
    w: ListWidget,
    e: ListTool,
    value: 'list',
    text: 'List Widget'
  },
  LineConnectWidget: {
    w: LineConnectWidget,
    e: LineConnectWidgetEditor,
    value: 'line-connect',
    text: 'Line Connect Widget',
  },
  MiniQuizWidget: {
    w: MiniQuizWidget,
    // e: MiniQuizWidgetEditor,
    e: MiniQuizTool,
    value: 'mini_quiz',
    text: 'Mini-Quiz Widget',
  },
  MoreDetailsWidget: {
    w: MoreDetailsWidget,
    e: MoreDetailsTool,
    // e: MoreDetailsWidgetEditor,
    value: 'more-details',
    text: 'More Details Widget',
  },
  PhraseWidget: {
    w: PhraseWidget,
    e: PhraseWidgetEditor,
    value: 'phrase',
    text: 'Phrase Widget',
  },
  PresentationWidget: {
    w: PresentationWidget,
    e: PresentationTool,
    value: 'presentation',
    text: 'Presentation Widget',
  },
  QnAStructureWidget: {
    w: QnAStructureWidget,
    e: QnAStructureWidgetEditor,
    value: 'qna_structure',
    text: 'Q&A Structure Widget',
  },
  QnAWidget: {
    w: QnAWidget,
    e: QnATool,
    // e: QnAWidgetEditor,
    value: 'qna',
    text: 'Q&A Widget',
  },
  QuestionAndAnswerWidget: {
    w: QuestionAndAnswerWidget,
    e: QuestionAndAnswerWidgetEditor,
    value: 'question-and-answer',
    text: 'Question and Answer Widget',
  },
  ReadingComprehensionWidget: {
    w: ReadingComprehensionWidget,
    e: ReadingComprehensionWidgetEditor,
    value: 'reading-comprehension',
    text: 'Reading Comprehension Widget',
  },
  SentenceBuilderWidget: {
    w: SentenceBuilderWidget,
    e: SentenceBuilderWidgetEditor,
    value: 'activity-sentence-builder',
    text: 'Sentence Builder Widget',
  },
  SentenceTranslationWidget: {
    w: SentenceTranslationWidget,
    e: SentenceTranslationWidgetEditor,
    value: 'activity-sentence-translation',
    text: 'Sentence Translation',
  },
  SentencesWidget: {
    w: SentencesWidget,
    e: SentencesWidgetEditor,
    value: 'sentences',
    text: 'Sentences Widget',
  },
  StructureWidget: {
    w: StructureWidget,
    // e: StructureWidgetEditor,
    e: StructureTool,
    value: 'structure',
    text: 'Structure Widget',
  },
  TableWidget: {
    w: TableWidget,
    // e: TableWidgetEditor,
    e: TableTool,
    value: 'table',
    text: 'Table Widget',
  },
  TextboxWidget: {
    w: TextBoxWidget,
    e: TextboxTool,
    value: 'text',
    text: 'Textbox Widget',
  },
  TextListWidget: {
    w: TextListWidget,
    // e: TextListWidgetEditor,
    e: TextListTool,
    value: 'text-list',
    text: 'Text List Widget',
  },
  UsageWidget: {
    w: UsageWidget,
    // e: UsageWidgetEditor,
    e: UsageTool,
    value: 'usage',
    text: 'Usage Widget',
  },
  VideoWidget: {
    w: VideoWidget,
    // e: VideoWidgetEditor,
    e: VideoTool,
    value: 'video',
    text: 'Video Widget',
  },
}
