import React, { useState, useEffect } from 'react'

import styled from 'styled-components'
import { Header } from 'semantic-ui-react'

export const TimerWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    padding: 20px;

    h2 {
        position: absolute;
        top: -10px;
        left: 15px;
    }
`

export const Spinner = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`

const Timer = ({
  color = 'var(--blue-gray)',
  message = '',
  showMinutes = false,
  showSpinner = true,
  spinnerWithMessage = '',
  initialMinute = 0,
  initialSeconds = 0,
}) => {
  const [minutes, setMinutes] = useState(Math.ceil(initialMinute))
  const [seconds, setSeconds] = useState(Math.ceil(initialSeconds))

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval)
        } else {
          setMinutes(minutes - 1)
          setSeconds(59)
        }
      }
    }, 1000)
    return () => {
      clearInterval(myInterval)
    }
  })

  if (spinnerWithMessage) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <TimerWrapper>
          <Spinner>
            <div className='half-circle-spinner'>
              <div className='circle circle-1'></div>
              <div className='circle circle-2'></div>
            </div>
          </Spinner>
        </TimerWrapper>
        <Header
          as='h3'
          style={{
            fontFamily: 'Jost',
          }}
        >
          {spinnerWithMessage}
        </Header>
      </div>
    )
  }

  return (
    <>
      {(minutes === 0 && seconds === 0) ? (
        message.length && (
          <Header
            as='h2'
            style={{
              fontFamily: 'Jost',
            }}
          >
            {message}
          </Header>
        )
      ) : (
        <TimerWrapper>
          {(showSpinner && minutes === 0) && (
            <Spinner>
              <div className='half-circle-spinner'>
                <div className='circle circle-1'></div>
                <div className='circle circle-2'></div>
              </div>
            </Spinner>
          )}
          <Header
            as='h2'
            style={{
              color,
              fontFamily: 'Jost',
              fontFeatureSettings: 'tnum',
              fontVariantNumeric: 'tabular-nums',
              textAlign: 'right',
              backgroundColor: 'transparent'
            }}
          >
            {showMinutes && `${minutes.toString().padStart(initialMinute > 9 ? 2 : 1, '0')}:`}{!showMinutes && seconds > 9 ? '' : <>&nbsp;</>}{seconds.toString().padStart(showMinutes ? 2 : 1, '0')}
          </Header>
        </TimerWrapper>
      )}
    </>
  )
}

export default Timer
