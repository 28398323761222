import React from 'react'
import useMobile from 'src/hooks/useMobile'

import { widgetOptions } from 'src/components/fz_courses/sublessonpart/sublesson_part_imports'

import RenderedWidget from '../../editor/styles/RenderedWidget'
import { EditableWidget } from '../styles/BaseTool.styles'

export interface ClientViewProps {
  course: string
  id: string
  index: number
  widgetsLength: number
  widgetType: string
  floating?: boolean
  widget?: any
  fetchCounter?: number
}

const ClientView: React.FC<ClientViewProps> = ({
  course,
  id,
  index,
  widgetsLength,
  widgetType,
  floating,
  widget,
  fetchCounter
}) => {
  const isMobile = useMobile()
  const WidgetView = widgetOptions[widgetType].w

  return (
    <EditableWidget floating={floating}>
      <RenderedWidget
        idx={index}
        hasNoPadding={false}
        isFirstIndex={index === 0}
        isLastIndex={index === widgetsLength - 1}
        isMobile={isMobile}
        widgetType={widgetType}
        className={widgetType}
        fetchCounter={fetchCounter}
      >
        {(widgetType && widget) && (
          <WidgetView
            id={id}
            // lesson={lessonID}
            course={course}
            adminView={false}
            widget={widget}
            fetchCounter={fetchCounter}
          />
        )}
      </RenderedWidget>
    </EditableWidget>
  )
}

export default ClientView
