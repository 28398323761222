import React, { useCallback, useEffect, useState } from 'react'
import { toRomaji } from 'wanakana'
import KanaCard from './KanaCard'

interface LabelClickResponse {
  className: string
  cards: string[]
  cardType: string
  labelType?: 'row' | 'column'
}

interface ChartProps {
  activeColumn: string
  activeRow: string
  doubleKana: boolean
  kanaChart: string[][][]
  kanaView: number
  collectedKana?: { [key: string]: boolean }
  viewChange: boolean
  embed?: boolean
  isMobile?: boolean
  handleKanaClicked: (arg: string) => void
  handleLabelCLicked: (arg0: LabelClickResponse) => void
}

const KanaChart: React.FC<ChartProps> = ({
  activeColumn,
  activeRow,
  doubleKana,
  kanaChart,
  kanaView,
  collectedKana,
  viewChange,
  handleKanaClicked,
  handleLabelCLicked,
  embed,
  isMobile
}) => {
  const [animating, setAnimating] = useState<boolean>(false)

  useEffect(() => {
    setAnimating(true)

    setTimeout(() => {
      setAnimating(false)
    }, 500)
  }, [viewChange])

  const isActiveColumn = useCallback((kana: string) => {
    return activeColumn === kana
  }, [activeColumn])

  const handleCardClicked = (_kana: string) => {
    handleKanaClicked(_kana)
  }

  // send back:
  // single kana for active classname,
  // column (full array of column),
  // card type (kana vs youon)
  const handleColumnClicked = (_column: string[][]) => {
    const _type = doubleKana ? 'youon' : 'kana'
    handleLabelCLicked({
      className: _column[kanaView][0],
      cards: _column[kanaView],
      cardType: _type,
      labelType: 'column',
    })
  }

  const rows = {
    あ: Array.from('あかがさざただなはばぱまやらまわ'),
    ア: Array.from('アカガサザタダナハバパマヤラマワ'),
    い: Array.from('いきぎしじちぢにひびぴみり'),
    イ: Array.from('イキギシジチヂニヒビピミリ'),
    う: Array.from('うくぐすずつづぬふぶぷむゆるを'),
    ウ: Array.from('ウクグスズツヅヌフブプムユルヲ'),
    え: Array.from('えけげせぜてでねへべぺめれ'),
    エ: Array.from('エケゲセゼテデネヘベペメレ'),
    お: Array.from('おこごそぞとどのほぼぽもよろん'),
    オ: Array.from('オコゴソゾトドノホボポモヨロン'),
    きゃ: ['きゃ', 'ぎゃ', 'しゃ', 'じゃ', 'ちゃ', 'にゃ', 'ひゃ', 'びゃ', 'ぴゃ', 'みゃ', 'りゃ'],
    キャ: ['キャ', 'ギャ', 'シャ', 'ジャ', 'チャ', 'ニャ', 'ヒャ', 'ビャ', 'ピャ', 'ミャ', 'リャ'],
    きゅ: ['きゅ', 'ぎゅ', 'しゅ', 'じゅ', 'ちゅ', 'にゅ', 'ひゅ', 'びゅ', 'ぴゅ', 'みゅ', 'りゅ'],
    キュ: ['キュ', 'ギュ', 'シュ', 'ジュ', 'チュ', 'ニュ', 'ヒュ', 'ビュ', 'ピュ', 'ミュ', 'リュ'],
    きょ: ['きょ', 'ぎょ', 'しょ', 'じょ', 'ちょ', 'にょ', 'ひょ', 'びょ', 'ぴょ', 'みょ', 'りょ'],
    キョ: ['キョ', 'ギョ', 'ショ', 'ジョ', 'チョ', 'ニョ', 'ヒョ', 'ビョ', 'ピョ', 'ミョ', 'リョ'],
  }

  // send back:
  // row (single kana),
  // full array of row,
  // card type (kana vs youon)
  const handleRowClicked = (_row: string) => {
    const _type = doubleKana ? 'youon' : 'kana'
    handleLabelCLicked({
      className: _row,
      cards: rows[_row],
      cardType: _type,
      labelType: 'row',
    })
  }

  return (
  <section className='kana-chart'>
    {kanaChart.map((column, cIndex) => (
      <React.Fragment key={`kana-${cIndex}`}>
        {cIndex !== 0 ? (
          <div className={`kana column column-${column[kanaView][0]}`}>
            <>
              <div
                key={`header-${cIndex}`}
                className={`header column-${column[kanaView][0]}${
                  isActiveColumn(column[kanaView][0]) ? ' active-column' : ''
                }`}
                onClick={() => handleColumnClicked(column)}
                style={{
                  backgroundColor: isActiveColumn(column[kanaView][0]) ? '#1d78be' : '',
                }}
              >
                <blockquote lang='jp'>
                  <h3>{
                    doubleKana
                      ? toRomaji(column[kanaView][0][0])
                      : toRomaji(column[kanaView][0])
                  }</h3>
                </blockquote>
              </div>
              <div className='kana-cards'>
                {column[kanaView].map((row, rIndex) => (
                  row !== '@' ? (
                    <KanaCard
                      key={`kana-${rIndex}`}
                      selected={collectedKana?.[row]}
                      active={
                        activeColumn === column[kanaView][0]
                        || activeRow === kanaChart[0][0][rIndex]
                      }
                      animating={animating}
                      delay={rIndex * 150}
                      kana={row}
                      handleCardClicked={() => handleCardClicked(row)}
                      embed={embed}
                    />
                  ) : (
                    <div key={`kana-${rIndex}`}></div>
                  )
                ))}
              </div>
            </>
          </div>
        ) : (
          <div className='empty-column column kana'>
            <div className='header'></div>
            <div className='kana-cards'>
              <div className='kana row-labels'>
                {column[kanaView].map((row, rIndex) => (
                  <div
                    key={`kana-${rIndex}`}
                    className={`row row-${row}${
                      activeRow === row ? ' active-row' : ''
                    }`}
                    onClick={() => handleRowClicked(row)}
                  >
                    <div>
                      <blockquote lang='jp'>
                        {doubleKana
                          ? toRomaji(column[0][rIndex][1])
                          : toRomaji(column[0][rIndex])}
                      </blockquote>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    ))}
  </section>
  )
}

export default KanaChart
