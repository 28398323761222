import {
  BUY_COURSE,
  CHANGE_SETTING,
  CHANGE_SETTINGS,
  SIGN_IN,
  SIGN_OUT,
  SUBSCRIBE,
  CHANGE_PROGRESSIVE,
  LOAD_SUBS,
  SET_OWN_AVATAR,
  UNSET_OWN_AVATAR,
  SET_BADGES,
  UNSET_BADGES,
  SET_DEFAULT_AVATAR,
  CHANGE_COURSE_BACKGROUND,
  SET_REFERRER,
  SET_ROLES,
  SET_AVATAR_CF,
  CHANGE_STUDY_MODE,
  CHANGE_PREFERRED_SPEAKER,
  SWITCH_COLORIZED_FURI,
  SWAP_FURI_COLORS,
  CHANGE_USERNAME,
  CHANGE_HOMEROOM_NOTIFICATIONS,
  TOGGLE_V8_PROGRESSIVE,
  SET_V8_DISCLAIMER,
  SET_V7_LAST_QUIZ,
} from '../actions/types'

import { AUTH_STATE } from '../types'

const INITIAL_STATE: AUTH_STATE = {
  isSignedIn: null,
  userId: null,
  userEmail: '',
  badges: undefined,
  roles: [],
  access: [],
  subscriptions: [],
  progressive_data: {},
  progressive_settings: {
    main_default: 'progressive',
    furi_opacity: 100,
    custom_progressive: {
      enabled: false,
      settings: {}
    }
  },
  subs: [],
  avatar_url: '',
  cf_avatar_url: undefined,
  default_avatar: {
    color: 'black',
    version: 1,
  },
  course_background: 'scenery',
  _id: '',
  study_mode_settings: {
    inStudyMode: false,
    shownLanguage: 'other'
  },
  preferred_speaker: 'Kanako',
  colorized_furi: false,
  swap_furi_colors: false,
  initial_login: false,
  planSelectionAtSignup: 'free',
  affiliateId: undefined,
  username_changed: false,
  referral_origin: undefined,
  v8_progressive: false,
  calligraphy_mode: false,
  needs_v8_disclaimer: false,
  v7_last_quiz: '',
}

type Action = {
  type: string
  payload: any
}

/* eslint-disable */
export default (state: AUTH_STATE = INITIAL_STATE, action: Action) => {
/* eslint-enable */
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        isSignedIn: true,
        userId: action.payload.username,
        userEmail: action.payload.email,
        badges: action.payload.badges,
        roles: action.payload.roles,
        access: action.payload.access,
        subscriptions: action.payload.subscriptions,
        progressive_settings: action.payload.progressive_settings,
        default_avatar: action.payload.default_avatar,
        course_background: action.payload.course_background || 'scenery',
        avatar_url: action.payload?.avatar_url,
        _id: action.payload._id,
        initial_login: action.payload.initial_login,
        planSelectionAtSignup: action.payload.planSelectionAtSignup,
        affiliateId: action.payload.affiliateId,
        username_changed: action.payload.username_changed,
        homeroom_notifications: action.payload.homeroom_notifications,
        preferred_speaker: action.payload.preferred_speaker || 'Kanako',
        v8_progressive: action.payload.v8_progressive,
        calligraphy_mode: action.payload.calligraphy_mode,
      }
    }
    case SIGN_OUT: {
      return { ...INITIAL_STATE }
    }
    case BUY_COURSE: {
      return { ...state, badges: action.payload.badges }
    }
    case SUBSCRIBE: {
      return { ...state, subscriptions: ['early-supporter'] }
    }
    case CHANGE_SETTING: {
      return { ...state, progressive_settings: action.payload }
    }
    case CHANGE_PROGRESSIVE: {
      return { ...state, progressive_data: action.payload || {} }
    }
    case LOAD_SUBS: {
      return { ...state, subs: action.payload || [] }
    }
    case SET_OWN_AVATAR: {
      return { ...state, avatar_url: action.payload }
    }
    case UNSET_OWN_AVATAR: {
      return { ...state, avatar_url: undefined, cf_avatar_url: undefined, }
    }
    case SET_BADGES: {
      return { ...state, badges: action.payload.badges }
    }
    case UNSET_BADGES: {
      return { ...state, badges: INITIAL_STATE.badges }
    }
    case SET_DEFAULT_AVATAR: {
      return {
        ...state,
        default_avatar: {
          color: action.payload.color,
          version: action.payload.version,
        },
      }
    }
    case CHANGE_COURSE_BACKGROUND: {
      return { ...state, course_background: action.payload }
    }
    case SET_ROLES: {
      return { ...state, roles: action.payload }
    }
    case SET_AVATAR_CF: {
      return { ...state, cf_avatar_url: action.payload }
    }
    case CHANGE_STUDY_MODE: {
      return {
        ...state,
        study_mode_settings: {
          inStudyMode: action.payload.inStudyMode,
          shownLanguage: action.payload.shownLanguage
        }
      }
    }
    case CHANGE_PREFERRED_SPEAKER: {
      return { ...state, preferred_speaker: action.payload }
    }
    case SWITCH_COLORIZED_FURI: {
      return { ...state, colorized_furi: action.payload }
    }
    case SWAP_FURI_COLORS: {
      return { ...state, swap_furi_colors: !state.swap_furi_colors }
    }
    case CHANGE_USERNAME: {
      return { ...state, userId: action.payload, username_changed: true }
    }
    case CHANGE_HOMEROOM_NOTIFICATIONS: {
      return { ...state, homeroom_notifications: action.payload }
    }
    case SET_REFERRER: {
      return { ...state, referral_origin: action.payload }
    }
    case TOGGLE_V8_PROGRESSIVE: {
      return { ...state, v8_progressive: action.payload }
    }
    case CHANGE_SETTINGS: {
      return { ...state, calligraphy_mode: action.payload.calligraphy_mode }
    }
    case SET_V8_DISCLAIMER: {
      return { ...state, needs_v8_disclaimer: action.payload }
    }
    case SET_V7_LAST_QUIZ: {
      return { ...state, v7_last_quiz: action.payload }
    }
    default: {
      return state
    }
  }
}
