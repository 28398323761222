import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import shallow from 'zustand/shallow'
import { useForm } from 'react-hook-form'

import useToolkitStore from '../../../../../stores/toolkit.store'

import { ToolFooterProps } from '../interfaces/CreationToolkit.interface'
import { MINIQUIZ } from '../toolkit.constants'

import { ToolInput } from '../parts'
import {
  BarButton,
  ToolFooter as Footer,
  ToolFooterLeft,
  ToolFooterRight,
} from '../styles'
import { Timestamp } from '../styles/BaseTool.styles'

/**
 * ToolFooter
 * Wrap your children around it.
 * Should be at the bottom of the tool tree so that it appears at the bottom of the tool view.
 * Always renders a save widget button on the right side of the footer bar.
 */
const ToolFooter: React.FC<ToolFooterProps> = ({
  children,
  handleSave,
  saveDisabled,
  savedTimestamp,
  rawTimestamp,
  lastEdited,
  lastEditedBy,
  widget_id,
  removeLeftMargin = false,
  widget_type = null,
}) => {
  const [viewFullTimeStamp, setViewFullTimeStamp] = useState(false)
  const setSavedIds = useToolkitStore((state) => state.setSavedIds)
  const alteredWidgetIds = useToolkitStore((state) => state.alteredWidgetIds, shallow)
  const toolkitStore = useToolkitStore

  const buttonText = useMemo(() => {
    if (saveDisabled) return 'Cannot Save'
    if (savedTimestamp) return 'Saved'
    return 'Save'
  }, [saveDisabled, savedTimestamp])

  const timestampText = useMemo(() => {
    if (lastEdited) {
      const timestamp = moment(lastEdited).format('MMM D, YYYY @ h:mm a')
      return `${timestamp}`
    }
  }, [lastEdited])

  const onSave = () => {
    if (saveDisabled) {
      if (widget_type === MINIQUIZ) alert('Quiz answers cannot be empty.')
      return
    }

    const _alteredWidgetIds = alteredWidgetIds.filter((id) => id !== widget_id)
    setSavedIds(widget_id)

    toolkitStore.setState({ 'alteredWidgetIds': _alteredWidgetIds })
    handleSave()
  }

  const dirtyTool: boolean = useMemo(() => {
    return alteredWidgetIds?.includes(widget_id)
  }, [alteredWidgetIds])

  return (
    <Footer id='tk-tool-footer'>
      <ToolFooterLeft removeLeftMargin={removeLeftMargin}>
        {children}
      </ToolFooterLeft>
      <ToolFooterRight>
        <ToolInput
          value='nonbreaking'
          inputType='checkbox'
          inputLabel='Non-breaking'
          labelPosition='before'
          labelColor='white'
        />
        <Timestamp>
          {savedTimestamp && (
            <p>Saved at: <time dateTime={rawTimestamp?.toString()}>{savedTimestamp}</time></p>
          )}
          <p
            onMouseEnter={() => setViewFullTimeStamp(true)}
            onMouseLeave={() => setViewFullTimeStamp(false)}
            onTouchStart={() => setViewFullTimeStamp(true)}
            onTouchCancel={() => setViewFullTimeStamp(false)}
            onTouchEnd={() => setViewFullTimeStamp(false)}
            style={{ cursor: 'pointer' }}
          >
            Last edited
            {viewFullTimeStamp ? (
              ` by ${lastEditedBy?.username} on ${timestampText}`
            ) : (
              `: ${timestampText}`
            )}
          </p>
        </Timestamp>
        <BarButton
          type='button'
          disabled={saveDisabled && widget_type !== MINIQUIZ}
          onClick={onSave}
          color='white'
          backgroundColor={dirtyTool ? 'var(--yellow)' : 'var(--save)'}
          contrastColor='var(--add)'
          borderColor='var(--add)'
          spaceLeft
        >
          {buttonText}
        </BarButton>
      </ToolFooterRight>
    </Footer>
  )
}

export default ToolFooter
