import { useCallback, useEffect, useState } from 'react'
import { AxiosError, AxiosResponse } from 'axios'
// import { setFlashMessage } from '../../../context/FlashMessageContext'

import axios from '../../../api'
import useStaffRoles from '../../../hooks/useStaffRoles'

import { ErrorObject } from '../../../types'

type UseWidgetHook<T> = [
  T | undefined,
  () => Promise<void>,
  boolean,
  // eslint-disable-next-line
  (arg0: any) => Promise<void>,
  ErrorObject | undefined,
  () => void
]

/* eslint-disable max-len */
/**
 * @description A hook setup to return a widget.
 * @param {string} id The ID of the desired widget.
 * @returns {APIHook<T>} The return of this hook has 5 parts in an array:
 * - `widget` the most recently returned widget data.
 * - `fetchWidget()` fetches a widget by id
 * - `loading` represents if a request is in a pending state.
 * - `editWidget()` send post request to save changes to widget to DB
 * - `error` which is any errors that occurred with the request, this is reset if a new request is made without errors.
 * */
/* eslint-enable */

// eslint-disable-next-line
const useWidget = <T = any>(id: string, cache?: boolean): UseWidgetHook<T> => {
  const [widget, setWidget] = useState<T>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<ErrorObject>()
  const [isStaffRole] = useStaffRoles()

  const fetchWidget = async (_id?: string) => {
    if (loading || error) return
    setLoading(true)

    let res: AxiosResponse

    try {
      res = await axios.get(`/widgets/${_id || id}`, { params: { cache } })
      if (res.data.success) setWidget(!cache ? res.data.widget : JSON.parse(res.data.widget?.cache))
    } catch (err) {
      const _error = err as AxiosError
      if (isStaffRole) setError(_error?.response?.data)
    }

    setLoading(false)
  }

  // eslint-disable-next-line
  const editWidget = async (body: any) => {
    if (!isStaffRole) return

    setLoading(true)

    let res: AxiosResponse
    try {
      res = await axios.post(`/widgets/${id}/edit`, body)
      if (res.data.success) fetchWidget()
    } catch (err) {
      const _error = err as AxiosError
      setError(_error?.response?.data)
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchWidget()
  }, [id])

  const reset = () => {
    setWidget(undefined)
    setLoading(false)
    setError(undefined)
  }

  return [widget, fetchWidget, loading, editWidget, error, reset]
}

export default useWidget
