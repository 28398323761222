import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import axios from 'src/api'
import { useCookies } from 'react-cookie'

import { RootState } from 'src/configureStore'

import useIsLoggedIn from 'src/hooks/useIsLoggedIn'
import { useQuery as useQueryParams } from 'src/components/pages/landing_page/v3/hooks/useAffiliateClick'

const getAffiliateStatus = async () => {
  try {
    const res = await axios.get('/affiliate')
    return res.data.affiliate || null
  } catch (e) {
    return null
  }
}

const getAffiliateActive = async (affiliateId) => {
  if (!affiliateId) {
    return null
  }

  try {
    const res = await axios.get('/affiliate/active', { params: { affiliateId } })
    return res.data.affiliate || null
  } catch (e) {
    return null
  }
}

const getAffiliateCampaign = async (campaign) => {
  if (!campaign) {
    return null
  }

  try {
    const res = await axios.get('/affiliate/campaign', { params: { campaign } })
    return res.data.campaign || null
  } catch (e) {
    return null
  }
}

const useAffiliate = () => {
  const [isLoggedIn] = useIsLoggedIn()
  const queryParams = useQueryParams()
  const { _id } = useSelector((state: RootState) => state.auth)
  const { data: affiliate } = useQuery({ queryKey: ['affiliate_status', _id], queryFn: getAffiliateStatus })

  const [cookies] = useCookies(['affiliate', 'aff_post'])
  const { data: affiliateActive } = useQuery({ queryKey: ['affiliate_active', cookies.affiliate], queryFn: () => getAffiliateActive(cookies.affiliate) })
  const { data: affiliate_campaign } = useQuery({ queryKey: ['affiliate_campaign', cookies.aff_post], queryFn: () => getAffiliateCampaign(cookies.aff_post) })

  return { affiliate: isLoggedIn ? affiliate : affiliateActive, affiliate_campaign }
}

export default useAffiliate
