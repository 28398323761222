import React, { useEffect, useState } from 'react'

import styled from 'styled-components'

const PlaceholderStyles = styled.div`
  width: 100%;
  border-radius: ${(props) => props.hasOpaqueBackground ? '15px' : ''};
  background-color: ${(props) => props.hasOpaqueBackground ? 'white' : ''};
  border: ${(props) => props.hasOpaqueBackground ? '1px solid var(--blue-gray)' : ''}; 
  margin-bottom: ${(props) => props.hasMarginBottom ? '50px' : ''};

  padding: ${(props) => props.hasMarginBottom ? '15px' : ''};
`

const PlaceholderHeader = styled.div`
  width: ${(props) => props.hwidth}%;
  box-sizing: border-box;
  height: 50px;
  margin: 8px;
  ${(props) => props.noMarginBottom && 'margin-bottom: 0;'}
  ${(props) => props.noMarginTop && 'margin-top: 0;'}
`

const PlaceholderParagraph = styled.div`
  margin-top: 8px;
  box-sizing: border-box;
  
  .paragraph-flex-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;  

    div {
      width: 50%;
    }
  }
  .paragraph-line {
    box-sizing: border-box;
    width: ${(props) => props.pwidth}%;
    max-width: 100%;
    height: 25px;
    margin: 8px;
  }
`

const PlaceholderImage = styled.div`
  width: 250px;
  height: 250px;
  margin: 8px auto;
`

const PlaceholderBox = styled.div`
  width: ${(props) => props.fullWidth ? '98.5' : '55'}%;
  height: ${(props) => props.height}px;
  margin: 0 auto;
  border-radius: 15px;
`

const QnAPlaceHolder = () => {
  return (
    <>
      <PlaceholderHeader className="placeholder" hwidth={20} />
      <br />
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
    </>
  )
}

const TextboxPlaceholder = () => {
  const [randomizeLines, setRandomizeLines] = useState(Math.round(Math.random() * 4) + 1)
  const [textboxes, setTextboxes] = useState([])

  const fillArray = () => {
    const updatedTextboxes = []
    for (let i = 0; i < randomizeLines; i++) {
      updatedTextboxes.push('')
    }

    setTextboxes(updatedTextboxes)
  }

  useEffect(() => {
    fillArray()
  }, [])

  return (
    <>
      <PlaceholderHeader className="placeholder" hwidth={60} />
      <br />
      {textboxes.map((_, idx) => {
        const randomLength = (Math.random() * 40) + 30
        return (
          <PlaceholderParagraph pwidth={randomLength} key={idx}>
            <div className="paragraph-line placeholder"></div>
          </PlaceholderParagraph>
        )
      })}
    </>
  )
}

const ConversationPlaceholder = () => {
  return (
    <>
      <PlaceholderHeader className="placeholder" hwidth={80} />
      <br />
      <PlaceholderParagraph pwidth={60}>
        <div className="paragraph-line placeholder"></div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={75}>
        <div className="paragraph-line placeholder"></div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={60}>
        <div className="paragraph-line placeholder"></div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={75}>
        <div className="paragraph-line placeholder"></div>
      </PlaceholderParagraph>
    </>
  )
}

const TablePlaceholder = () => {
  return (
    <>
      <PlaceholderHeader className="placeholder" hwidth={98.5} noMarginBottom />
      <PlaceholderHeader className="placeholder" hwidth={98.5} noMarginTop />
      <br />
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
    </>
  )
}

const ImagePlaceHolder = () => {
  return (
    <>
      <PlaceholderImage className="placeholder" />
    </>
  )
}

const StructurePlaceholder = (props) => {
  const randomHeight = (Math.random() * 250) + 100
  return (
    <PlaceholderBox className="placeholder" height={randomHeight} fullWidth={props.fullWidth} />
  )
}

const UsagePlaceholder = () => {
  return (
    <>
      <PlaceholderHeader className="placeholder" hwidth={98.5} />
    </>
  )
}

const KanjiPlaceholder = () => {
  return (
    <>
      <PlaceholderHeader className="placeholder" hwidth={20} noMarginBottom />
      <PlaceholderHeader className="placeholder" hwidth={20} noMarginTop noMarginBottom />
      <PlaceholderHeader className="placeholder" hwidth={20} noMarginTop />
      <br />
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
      <PlaceholderParagraph pwidth={100}>
        <div className="paragraph-flex-group">
          <div className="paragraph-line placeholder"></div>
          <div className="paragraph-line placeholder"></div>
        </div>
      </PlaceholderParagraph>
    </>
  )
}

const ReadingComprehensionPlaceholder = () => {
  const [randomizeLines, setRandomizeLines] = useState(Math.round(Math.random() * 4) + 3)
  const [textboxes, setTextboxes] = useState([])

  const fillArray = () => {
    const updatedTextboxes = []
    for (let i = 0; i < randomizeLines; i++) {
      updatedTextboxes.push('')
    }

    setTextboxes(updatedTextboxes)
  }

  useEffect(() => {
    fillArray()
  }, [])

  return (
    <>
      {textboxes.map((_, idx) => {
        const randomLength = (Math.random() * 40) + 30
        return (
          <PlaceholderParagraph pwidth={parseInt(randomLength, 10)} key={idx}>
            <div className="paragraph-line placeholder"></div>
          </PlaceholderParagraph>
        )
      })}
    </>
  )
}

const MixedWidgetsPlaceholder = () => {
  return (
    <StructurePlaceholder fullWidth />
  )
}

const PlaceholderWidget = ({ widget_type = 'MixedWidget' }) => {
  const [hasOpaqueBackground, setHasOpaqueBackground] = useState(false)
  const [hasMarginBottom, setHasMarginBottom] = useState(true)
  
  useEffect(() => {
    if (
      widget_type !== 'TextboxWidget'
        && widget_type !== 'ImageWidget'
        && widget_type !== 'StructureWidget'
        && widget_type !== 'UsageWidget'
        && widget_type !== 'MixedWidget'
    ) setHasOpaqueBackground(true)

    if (widget_type === 'UsageWidget') setHasMarginBottom(false)
  }, [])

  return (
    <PlaceholderStyles hasOpaqueBackground={hasOpaqueBackground} hasMarginBottom={hasMarginBottom} className="fz-placeholder-gradient">
      {{
        TextboxWidget: <TextboxPlaceholder />,
        ConversationWidget: <ConversationPlaceholder />,
        // "TextListWidget": <TextboxPlaceholder />, // not implemented
        TableWidget: <TablePlaceholder />,
        ImageWidget: <ImagePlaceHolder />,
        SentencesWidget: <TextboxPlaceholder />,
        QnAWidget: <QnAPlaceHolder />,
        ExamplesWidget: <TextboxPlaceholder />,
        StructureWidget: <StructurePlaceholder />,
        VideoWidget: <TextboxPlaceholder />,
        MiniQuizWidget: <TextboxPlaceholder />,
        // "QnAStructureWidget": <TextboxPlaceholder />, // not implemented
        UsageWidget: <UsagePlaceholder />,
        DialogueWidget: <TextboxPlaceholder />,
        KanjiWidget: <KanjiPlaceholder />,
        GridWidget: <MixedWidgetsPlaceholder />,
        MoreDetailsWidget: <TextboxPlaceholder />,
        // "LineConnectWidget": <TextboxPlaceholder />, // activities not implemented
        // "FillInTheBlanksWidget": <TextboxPlaceholder />,
        ReadingComprehensionWidget: <ReadingComprehensionPlaceholder />,
        // "QuestionAndAnswerWidget": <TextboxPlaceholder />,
        // "FillInTheKanjiWidget": <TextboxPlaceholder />,
        // "SentenceTranslationWidget": <TextboxPlaceholder />,
        // "ImageQuestionWidget": <TextboxPlaceholder />,
        MixedWidget: <MixedWidgetsPlaceholder />,
      }[widget_type]}
    </PlaceholderStyles>
  )
}

export default PlaceholderWidget
