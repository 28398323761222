import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react/macro'
import styled from '@emotion/styled'

import { Button, Icon } from 'semantic-ui-react'
import {
  Question,
  Answer,
  Answers,
  ImageContainer,
  Chibi,
  English,
  OtherLang,
  QnaAside,
  DashedLine,
  PlayAll,
  DashAndBubble,
  DashBubble,
  DashLine,
  Placeholder,
  BlockNumber,
  EnglishProps,
  OtherLangProps,
  StyledBlock,
  BlockComment,
  BlockTitle,
  QnATitle
} from './styles/QnAStyles'

import { darkFont, coloredFont } from 'src/assets/styles/globalStyles'

import getCourseInfo, { getTitle } from '../../../util/courseInfo'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import PlaceholderWidget from './PlaceholderWidget'
import SoundRenderer from './SoundRenderer'

import useMobile from '../../../hooks/useMobile'
import usePlayAll from '../hooks/usePlayAll'
import useStudyMode from '../hooks/useStudyMode'

import tomoko from '../../../assets/chibi/tomoko/chibi_pronunciation.png'
import kenji from '../../../assets/chibi/kenji/chibi_common_questions.png'
import mari from '../../../assets/chibi/mari/chibi_writing_points.png'
import ren from '../../../assets/chibi/ren/chibi_grammar.png'
import rika from '../../../assets/chibi/rika/chibi_reading_comprehension.png'
import natsumi from '../../../assets/chibi/natsumi/chibi_quiz.png'
import tomokoAnswer from '../../../assets/chibi/q_a_chibi/q_a_tomoko.png'
import kenjiAnswer from '../../../assets/chibi/q_a_chibi/q_a_kenji.png'
import mariAnswer from '../../../assets/chibi/q_a_chibi/q_a_mari.png'
import renAnswer from '../../../assets/chibi/q_a_chibi/q_a_ren.png'
import rikaAnswer from '../../../assets/chibi/q_a_chibi/q_a_rika.png'
import natsumiAnswer from '../../../assets/chibi/q_a_chibi/q_a_natsumi.png'

const icons = { tomoko, kenji, mari, ren, rika, natsumi }
const answerIcons = { tomokoAnswer, kenjiAnswer, mariAnswer, renAnswer, rikaAnswer, natsumiAnswer }

type BlurType = {
  blur?: boolean
  color: string
}

const shouldColored = ({ i }) => i === 0 ? coloredFont : css`${darkFont}`

const BlurEnglish = styled(English)<EnglishProps & BlurType>`
  ${shouldColored}
`

const BlurOtherLang = styled(OtherLang)<OtherLangProps & BlurType>`
  ${shouldColored}
`

type Bullet = {
  english: string[]
  other_lang: string[]
  aside?: string
}

interface GridRowProps {
  bullet: Bullet
  i: number
  current: number
  next: (num: number) => void
  register: (num: number) => void
  displayOrder: boolean
  stackVertical: boolean
  hideEnglish: boolean
  hideOtherLang: boolean
  blockIcons: string[]
  courseColor: string
  isMobile: boolean
}

const GridRow: React.FC<GridRowProps> = (props) => {
  const {
    bullet,
    i,
    current,
    next,
    register,
    displayOrder,
    stackVertical,
    hideEnglish,
    hideOtherLang,
    blockIcons,
    courseColor,
    isMobile,
  } = props
  
  const icon = blockIcons ? blockIcons[i] : 'tomoko'
  const isMale = icon === 'kenji' || icon === 'ren'

  const RenderedEnglish = (
    <BlurEnglish
      i={i}
      stackVertical={stackVertical}
      color={courseColor}
    >
      <SoundRenderer
        index={i}
        placeholder={hideEnglish}
        playingNum={current}
        ping={next} num={i} onSound={register}
        text={bullet?.english?.[0] || bullet?.english}
        translation={bullet?.other_lang?.[0] || bullet?.other_lang}
      />
    </BlurEnglish>
  )

  return (
    <>
      <ImageContainer i={i} stackVertical={stackVertical} hasAside={bullet?.aside && true}>
        <Chibi
          src={i === 0 ? icons[icon] : answerIcons[`${icon}Answer`]}
          i={i}
          icon={icon}
          className='no-select'
          draggable='false'
          stackVertical={stackVertical}
          hasAside={bullet?.aside && true}
        />
      </ImageContainer>
      {displayOrder && RenderedEnglish}
      <div>
        <BlurOtherLang
          i={i}
          stackVertical={stackVertical}
          color={courseColor}
        >
          <SoundRenderer
            index={i}
            placeholder={hideOtherLang}
            playingNum={current}
            ping={next} num={i} onSound={register}
            text={bullet?.other_lang?.[0]}
            wrapAtIcon
            male={isMale}
            female={!isMale}
            translation={bullet?.english?.[0]}
          />
        </BlurOtherLang>
      </div>
      {!displayOrder && RenderedEnglish}
      {bullet?.aside && (
        <QnaAside isMobile={isMobile} stackVertical={stackVertical}>({bullet.aside})</QnaAside>
      )}
    </>
  )
}

interface BlockProps {
  block: Bullet[]
  displayOrder: boolean
  stackVertical: boolean
  isMobile: boolean
  hideEnglish: boolean
  hideOtherLang: boolean
  blockIcons: string[]
  idx: number
  courseColor: string
  courseColor2: string
  blockComment: string | null
  blockTitle: string | null
  widget: string
}

const Block: React.FC<BlockProps> = (props) => {
  const {
    block,
    displayOrder,
    stackVertical,
    isMobile,
    hideEnglish,
    hideOtherLang,
    blockIcons,
    idx,
    courseColor,
    courseColor2,
    blockComment,
    blockTitle,
    widget
  } = props
  
  const { playAll, play, current, register, next } = usePlayAll()

  const rowProps = {
    current,
    next,
    register,
    displayOrder,
    stackVertical,
    isMobile,
    hideEnglish,
    hideOtherLang,
    blockIcons,
    blockTitle
  }

  return (
    <StyledBlock>
      <Question {...rowProps}>
        <BlockNumber courseColor={courseColor} span2={stackVertical && !blockTitle}>{idx + 1}</BlockNumber>
        {blockTitle && (
          <>
            <BlockTitle>{blockTitle}</BlockTitle>
            <Placeholder stackVertical={stackVertical} />
          </>
        )}
        <GridRow
          courseColor={courseColor}
          bullet={block[0]}
          i={0}
          {...rowProps}
        />
      </Question>
      <Answers>
        <Answer {...rowProps}>
          <DashAndBubble>
            <DashLine color={courseColor2}/>
            <DashBubble color={courseColor2} isMobile={isMobile}>
              Possible Answer{block.length > 2 ? 's' : ''}
            </DashBubble>
            <DashLine color={courseColor2} />
          </DashAndBubble>
        </Answer>
        {blockComment && (
          <Answer {...rowProps}>
            <BlockComment>{blockComment}</BlockComment>
          </Answer>
        )}
        {block.map((bullet, i) => (
          i !== 0 && (
            <Answer key={i} {...rowProps}>
              <Placeholder stackVertical={stackVertical} />
              <GridRow
                courseColor={courseColor}
                bullet={bullet}
                i={i}
                {...rowProps}
              />
              {i !== (block.length - 1) && <DashedLine />}
            </Answer>
          )
        ))}
        {playAll && (
          <Answer {...rowProps}>
            <PlayAll
              className={current === -1 ? 'fz-play-all' : 'fz-stop-all'}
              onClick={() => play(widget)}
              displayOrder={displayOrder}
              stackVertical={stackVertical}
            >
              <Icon name="play" />
              {current === -1 ? 'Play All' : 'Stop All'}
            </PlayAll>
          </Answer>
        )}
      </Answers>
    </StyledBlock>
  )
}

interface QnAWidgetTypes {
  widget: {
    title: string
    blocks: Bullet[][]
    displayOrder: boolean
    hideHeader: boolean
    blockComments?: string[]
    blockTitles?: string[]
    is_draft: boolean
    no_sound: boolean
    plays_sound: boolean
    sound_instances: []
    type: string
    _id: string
  }
  course: string
}

const QnAWidget: React.FC<QnAWidgetTypes> = ({ widget, course }) => {
  const { title, blocks, displayOrder, blockComments, blockTitles, _id } = widget

  const [loading, setLoading] = useState(!widget)
  const [blockIcons, setBlockIcons] = useState([])
  const [stackVertical, setStackVertical] = useState(false)

  const isMobile = useMobile()
  const isSmallScreen = useMobile(1200)
  const { hideEnglish, hideOtherLang } = useStudyMode()

  const courseColor = getCourseInfo(getTitle(course)).alt_color_1
  const courseColor2 = getCourseInfo(getTitle(course)).alt_color_2

  useEffect(() => {
    setLoading(!widget)
  }, [widget])

  useEffect(() => {
    let shouldStackWidgetVertical = false
    if (isSmallScreen) return setStackVertical(true)

    blocks.forEach((block) => {
      block.forEach((bullet) => {
        if (bullet.english?.[0]?.length > 35) shouldStackWidgetVertical = true
        if (bullet.other_lang?.[0]?.length > 25) shouldStackWidgetVertical = true
      })
    })
    setStackVertical(shouldStackWidgetVertical)
  }, [blocks, isSmallScreen])

  // generates random icons
  useEffect(() => {
    if (!loading) {
      const iconArrays = []

      blocks.forEach((block) => {
        const iconKeys = Object.keys(icons)
        const randomIcons: string[] = []

        block.forEach(() => {
          const icon = iconKeys[Math.floor(Math.random() * iconKeys.length)]
          randomIcons.push(icon)

          const iconIndex = iconKeys.indexOf(icon)
          iconKeys.splice(iconIndex, 1)
        })
        iconArrays.push(randomIcons)
      })
      setBlockIcons(iconArrays)
    }
  }, [loading])
  
  return (
    <div style={{ marginTop: '30px', position: 'relative' }}>
      {loading
        ? <PlaceholderWidget widget_type={'QnAWidget'}/>
        : blocks && (
          <WidgetWrapper
            data-t={`widget:qna-id:${_id}`}
            widget={{ widgetId: widget._id, widgetType: 'QnAWidget' }}
          >
            {title && (
              <QnATitle isMobile={isMobile} courseColor={courseColor}>
                <SoundRenderer text={title} />
              </QnATitle>
            )}
            {blocks.map((block: Bullet[], idx: number) => (
              <Block
                key={`${hideEnglish}-${hideOtherLang}-qna-block-${idx}`}
                block={block}
                displayOrder={displayOrder}
                stackVertical={stackVertical}
                isMobile={isMobile}
                hideEnglish={hideEnglish}
                hideOtherLang={hideOtherLang}
                blockIcons={blockIcons[idx]}
                idx={idx}
                courseColor={courseColor}
                courseColor2={courseColor2}
                blockComment={blockComments ? blockComments[idx] : null}
                blockTitle={blockTitles ? blockTitles[idx] : null}
                widget={`${_id}-${idx}`}
              />
            ))}
          </WidgetWrapper>
        )
      }
    </div>
  )
}

export default QnAWidget
