import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { Button, Icon, Label, Segment } from 'semantic-ui-react'
import styled from 'styled-components'

import PlaceholderWidget from '../PlaceholderWidget'
import SoundRenderer from '../SoundRenderer'

import usePlayAll from '../../hooks/usePlayAll'

const CustomLabel = styled.div`
  display: grid;
  background-color: #2e64bc;
  justify-content: center;
  color: white;
  width: 24px;
  height: 24px;
  font-feature-settings: tnum;
  font-variant-numeric: tabular-nums;
  font-size: .9rem;
  padding: 5px;
  border-radius: 50%;
  font-weight: bold;
             
  & > p {
    position: relative;
    top: -3px;
  }
`

const ReadingComprehensionWidget = (props) => {
  const [lines, setLines] = useState([])
  const [widget, setWidget] = useState(props.widget)
  const [loading, setLoading] = useState(false)
  const { playAll, play, stop, current, register, next } = usePlayAll()

  useEffect(() => {
    if (!widget) return
    setLines(widget.lines)
  }, [widget])

  const adjustMargin = (index) => {
    let margin = -0.5
    if (index >= 9) {
      margin = -1
    }
    return margin
  }

  if (loading) return <PlaceholderWidget widget_type="ReadingComprehensionWidget" />
    
  return (
    <Segment style={{ borderRadius: '15px' }}>
      {lines.map((p, idx) => (
        <div key={`reading-comp-${idx}`} style={{ lineHeight: '3rem', marginRight: '20px', marginTop: '10px' }}>
          <div style={{ position: 'relative', fontSize: '1.5rem', color: 'var(--blue-gray)', display: 'inline-flex', justifyContent: 'center', verticalAlign: 'middle', alignItems: 'baseline' }}>
            <CustomLabel>
              <p>{(idx + 1).toString().padStart(2, '0')}</p>
            </CustomLabel>
            <span style={{ marginLeft: '8px' }}>
              <SoundRenderer
                playingNum={current} num={idx}
                onSound={register} ping={next}
                text={p.other_lang}
                wrapAtIcon
              />
            </span>
          </div>
        </div>))}
        {playAll && lines.length > 1 && (
          <Button style={{ marginTop: '20px' }} className={current === -1 ? 'fz-play-all' : 'fz-stop-all'} onClick={() => play(props.widget._id)}>
            <Icon name="volume down" />
            {current === -1 ? 'Play All' : 'Stop All'}
          </Button>
        )}
    </Segment>
  )
}

const mapStateToProps = (state) => {
  return { progressive_settings: state.auth.progressive_settings }
}

export default connect(mapStateToProps)(ReadingComprehensionWidget)
