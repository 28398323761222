import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'
import { OptionProps } from '../types'
import { motion } from 'framer-motion'

const black = '#3C4043'
const background = '#F4F6FC'
const highlight = '#C8D0EA'
const green = '#9AD9B4'
const red = '#FF8C8C'

//
// Answers Wrapper Styles
//

export const AnswersWrapper = styled.section<{ transitioning: boolean }>`
  opacity: ${({ transitioning }) => transitioning ? 0 : 1};
  transition: all 0.5s ease-in-out;
`

//
// Styles for Question additions in the Answers Section (i.e, images, sounds, etc..)
//

export const Kana = styled.h4`
  font-size: 7rem !important;
  font-family: 'Noto Sans JP Bold';
  padding-top: 16px;
  color: ${black};
  text-align: center;
`

export const ConjugationVerbSection = styled.div`
  display: grid;
  place-items: center;
  gap: 16px;
  padding-top: 16px;
`

export const ConjugationEnglish = styled.p<{ isMobile: boolean, form?: boolean }>`
  color: ${black};
  text-align: center;

  ${({ isMobile }) => isMobile && css`font-size: 1.1rem;`}
`

export const Conjugated = styled.p`
  margin: 24px 0px !important;
  color: ${black};
  text-align: center;
  border: dashed 2px #89e9d9;
  border-radius: 20px;
  padding: 8px 32px;
  justify-self: center;
`

//
// Options Container Styles
//

const imageGrid = css`
  grid-template-columns: repeat(auto-fit, 200px);
  grid-auto-rows: 200px;
  justify-content: center;
  gap: 24px;
  padding: 32px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fit, 150px);
    grid-auto-rows: 150px;
    gap: 16px;
    padding: 16px 0px;
  }

  @media (max-width: 375px) {
    grid-template-columns: repeat(auto-fit, 125px);
    grid-auto-rows: 125px;
  }
`

const optionsContainerStyles = {
  text: css``,
  images: imageGrid,
  sounds: imageGrid,
}

export const OptionsContainer = styled.div<{ activityType: string }>`
  display: grid;

  // individual styles
  ${({ activityType }) => optionsContainerStyles[activityType]}
`

//
// Answer Option Styles
//

const hoverStyles = css`
  &:hover {
    background-color: ${highlight};
    cursor: pointer;
  }
`

const textOption = css`
  font-family: 'Poppins';
  font-size: 1.25rem;
  color: ${black};
  border: 1px solid ${highlight};
  margin-bottom: 20px;

  @media (max-width: 768px) { font-size: 1rem; }
`

const imageOption = css`
  --shadow-color: 226deg 45% 85%;
  box-shadow:
    0px 0.3px 0.3px hsl(var(--shadow-color) / 0.53),
    0px 1.2px 1.3px -0.9px hsl(var(--shadow-color) / 0.52),
    0px 3.2px 3.6px -1.7px hsl(var(--shadow-color) / 0.51),
    0.1px 7.9px 8.8px -2.6px hsl(var(--shadow-color) / 0.5);
  background-color: white;
`

const soundOption = css`
  ${imageOption}
  display: grid;
  place-content: center;
`

const answerOptionStyles = {
  text: textOption,
  images: imageOption,
  sounds: soundOption,
}

export const AnswerOption = styled(motion.div)<OptionProps>`
  background-color: ${background};
  border-radius: 20px;
  padding: 20px 16px 16px;
  transition: all 0.1s ease-in-out;

  // individual styles
  ${({ activityType }) => answerOptionStyles[activityType]}

  // answer feedback styles
  background-color: ${({ selected, submitted, correct }) => {
    if (selected && !submitted) return highlight
    if (selected && submitted) return correct ? green : red
  }};

  // hover styles
  ${({ answeredCorrectly, selected }) => !answeredCorrectly && !selected && hoverStyles}
`

export const AnswerInput = styled.input`
  border: solid 1px ${black};
  border-radius: 20px;
  padding: 4px 8px;
  justify-self: center;
  text-align: center;
  margin: 32px 0px;
  font-family: 'Noto Sans JP';
`

//
// Image Renderer Styles
//

export const ImageRendererImage = styled.img<{ customStyles?: SerializedStyles }>`
  ${({ customStyles }) => customStyles}
`

export const imgStyles = css`
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
`

export const questionImageStyles = css`
  height: 200px;
  margin: 0 auto;

  @media (max-width: 768px) { height: 150px; }
  @media (max-width: 375px) { height: 125px; }
`
