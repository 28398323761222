import styled, { css } from 'styled-components'

interface RenderedWidgetProps {
  idx: number
  hasNoPadding: boolean
  isFirstIndex: boolean
  isLastIndex?: boolean
  isMobile: boolean
  widgetType: string
  fetchCounter: number
}

const ImageWidgetStyles = css`
  display: inline-block;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  width: 100%;
`

export default styled.div<RenderedWidgetProps>`
  display: block;
  justify-content: flex-start;
  text-align: left;
  margin-top: ${({ isMobile, isFirstIndex }) => (isMobile && isFirstIndex) ? '6px' : ''};
  margin-bottom: ${({ isMobile }) => isMobile ? '10px' : '5px'};
  
  padding-bottom: 10px;
  padding: ${({ isMobile }) => isMobile && '10px 0 0'};
  padding-right: ${({ isMobile, hasNoPadding }) => (isMobile || hasNoPadding) ? '' : '45px'};
  padding-left: ${({ isMobile, hasNoPadding }) => (isMobile || hasNoPadding) ? '' : '45px'};

  ${({ widgetType }) => widgetType === 'ImageWidget' && ImageWidgetStyles}
  ${({ widgetType, isLastIndex, idx }) => (
    widgetType === 'ImageWidget'
    && !isLastIndex
  ) && `
    margin-bottom: '5px';
    padding-bottom: '5px';
    margin-top: ${idx === 0 ? '5px' : ''};
  `}

  margin-bottom: ${({ isMobile, widgetType, isLastIndex }) => (
    isMobile
    && widgetType === 'ImageWidget'
    && !isLastIndex
  ) && '10px'};

  ${({ widgetType, isLastIndex }) => (
    ['KanjiWidget', 'KanjiMemoryWidget'].includes(widgetType)
  ) && `
    margin-bottom: ${isLastIndex ? '5px' : '30px'};
  `}
`
