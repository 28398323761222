import React, { useEffect, useState } from 'react'

import { useLocation, Switch } from 'react-router-dom'
import { connect, useSelector } from 'react-redux'
import axios from 'src/api'
import PubSub from 'pubsub-js'

import { updateHistory, loadCourses, loadSubs } from 'src/actions'
import { RootState } from 'src/configureStore'

interface HistoryProps {
  loadCourses: (arg0: any) => void
  loadSubs: (arg0: any) => void
  updateHistory: (arg0: any) => void
}

const historyTracking: React.FC<HistoryProps> = (props) => {
  const [loadingCourses, setLoadingCourses] = useState(false)
  const location = useLocation()
  const isSignedIn = useSelector((state: RootState) => state.auth.isSignedIn)

  // get fresh subscribed / purchased courses data
  const fetchCourses = () => {
    setLoadingCourses(true)

    axios.get('/user/get_courses')
      .then(({ data }) => {
        if (data.success) {
          props.loadCourses(data.courses)
          props.loadSubs(data.subs)
        }
      })
      .catch((err) => console.error(err))

    setLoadingCourses(false)
  }

  useEffect(() => {
    PubSub.publishSync('PAGE CHANGE')
    if (!isSignedIn) return
    props.updateHistory(location)

    // console.log(location)

    if (loadingCourses) return
    fetchCourses()
  }, [location])

  return (
    <Switch>
      {props.children}
    </Switch>
  )
}

const mapStateToPropsHistory = (state) => ({ history: state.user.history })

const HistoryTracker = connect(mapStateToPropsHistory, {
  updateHistory, loadCourses, loadSubs
})(historyTracking)

export default HistoryTracker
