import React, { Suspense } from 'react'
import LoadingScreen from './LoadingScreen'

const withSuspense = (WrappedComponent) => {
  const hocComponent = ({ ...props }) => (
    <Suspense fallback={<LoadingScreen />}>
      <WrappedComponent {...props} />
    </Suspense>
  )

  hocComponent.propTypes = {}

  return hocComponent
}

export default withSuspense
