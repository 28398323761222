import React, { useCallback, useEffect, useState } from 'react'
import * as R from 'ramda'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { GRID } from '../toolkit.constants'
import { GridWidget } from 'src/types'

import { ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { ToolFooter, ToolForm, ToolHeader, ToolInput, ToolOptions, ToolSubheader } from '../parts'

import AddCellOrGridRow from './grid/AddCellOrGridRow'

import useWidgetSaved from '../hooks/useWidgetSaved'
import useAPI from 'src/hooks/useAPI'
import { CarouselRow, DividingDiv, GridCol, GridRow, OptionButton } from '../styles/GridTool.styles'
import { Icon } from 'semantic-ui-react'
import GridCell from './grid/GridCell'

const GridTool: React.FC<ToolProps<GridWidget>> = ({
  course,
  lesson,
  sublesson,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  defaultOpen,
  isGridCell = false,
  isMoreDetailsCell = false,
  fetchWidget: fetchW,
  fetchSubLesson = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [expandCarousel, setExpandCarousel] = useState(false)
  // const [cellWidthsChanged, setCellWidthsChanged] = useState(false)
  const [storedWidget, setStoredWidget] = useState<GridWidget>()
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)

  const [widget, fetchWidget, , editWidget] = useWidget<GridWidget>(id)
  const [assignedWidget, assigningWidget, assignWidget] = useAPI(`/widgets/grid/${id}`, 'POST')

  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const resetColumnWidths = useCallback(() => {
    const values = hookFormMethods.getValues()
    const updatedColumnWidth = [...values.columnWidth]

    updatedColumnWidth.forEach((row, rowIndex) => {
      const valueRow = values.rows[rowIndex]
      const columnWidth = Math.ceil(16 / valueRow.length)
      updatedColumnWidth[rowIndex] = valueRow.map(() => columnWidth)
    })

    hookFormMethods.setValue('columnWidth', updatedColumnWidth)
  }, [storedWidget])

  const handleSaveWidget = () => {
    console.log('calling grid widget save')
    if (!hookFormMethods.formState.isValid) return
    console.log('made it after the validity check')
    let values = hookFormMethods.getValues()
    values['rows'] = values.rows.filter((r) => r.filter((c) => c !== ''))

    // fix (maybe temporary) for grid cells not saving a column width
    if (R.any(values.rows.map((r, idx) => r?.length !== values.columnWidth[idx]?.length))) {
      resetColumnWidths()
      values = hookFormMethods.getValues()
    }
    values['columnWidth'] = values.columnWidth.map((row) => row.map((col) => parseInt(col, 10)))

    console.log('calling edit widget')
    console.log(values, course)
    editWidget({ ...values, course })
    console.log('setting saved to true')
    setSaved(true)
    console.log('calling fetch widget')
    fetchWidget()
  }

  const initializeGrid = (w: GridWidget) => {
    // fresh grids are cooked with no row data,
    // so we'll check for this and add an empty row and cell if this is the case
    const tempGridWidget = { ...w }
    if (!tempGridWidget?.rows?.length) {
      tempGridWidget.rows = [['']]
    }

    // check grid for columnWidths (fresh grids will especially lack this)
    if (!tempGridWidget?.columnWidth?.length) {
      const columnWidths = []

      tempGridWidget.rows.forEach((r, ir) => {
        // initialize an empty array at the index to avoid bad times
        if (columnWidths[ir] === undefined) columnWidths[ir] = []
        r.forEach((_, ic) => {
          columnWidths[ir][ic] = Math.ceil(16 / r.length)
        })
      })

      tempGridWidget.columnWidth = columnWidths
    }

    setStoredWidget(tempGridWidget)
  }

  const commitRowChanges = (rows: string[][], columnWidth?: number[][]) => {
    const newWidget = { ...storedWidget }
    hookFormMethods.setValue('rows', rows)
    newWidget.rows = rows

    if (columnWidth) {
      hookFormMethods.setValue('columnWidth', columnWidth)
      newWidget.columnWidth = columnWidth
    }

    setStoredWidget(newWidget)
    resetColumnWidths()
  }

  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })

      initializeGrid(widget)
      setWidgetValues(widget)
    }

    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  useEffect(() => {
    if (assignedWidget?.success) fetchWidget()
  }, [assignedWidget, assigningWidget])

  const handleAddCell = (row_index: number, col_index: number) => {
    const updatedWidget = { ...storedWidget }
    const { rows, columnWidth } = updatedWidget

    rows[row_index].splice(col_index, 0, '')
    columnWidth[row_index].splice(col_index, 0, Math.ceil(16 / rows[row_index].length))

    // console.log(rows, columnWidth)

    commitRowChanges(rows, columnWidth)
  }

  const handleRemoveCell = (row_index: number, col_index: number) => {
    const newRows = [...storedWidget.rows]
    const newColumnWidth = [...storedWidget.columnWidth]

    // remove cell from row
    newRows[row_index].splice(col_index, 1)
    newColumnWidth[row_index]?.splice(col_index, 1)

    // remove whole row if no more cells
    if (!newRows[row_index].length) {
      newRows.splice(row_index, 1)
      newColumnWidth?.splice(row_index, 1)
    }

    commitRowChanges(newRows, newColumnWidth)
    handleSaveWidget()
  }

  const handleAddRow = (row_index: number) => {
    const newRows = [...storedWidget.rows]
    const newColumnWidth = [...storedWidget.columnWidth]

    const newIndex = row_index + 1

    newRows.splice(newIndex, 0, [''])
    newColumnWidth.splice(newIndex, 0, [Math.ceil(16 / newRows[newIndex].length)])

    commitRowChanges(newRows, newColumnWidth)
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={GRID}
      draggable_props={draggable_props}
      defaultOpenToolkit={defaultOpen || isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
    >
      <div className='grid-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          {!defaultOpen && (
            <>
              <ToolHeader
                widget_type={GRID}
                widget_id={id}
                isGridCell={isGridCell}
                hookForm={hookFormMethods.getValues()}
              />
              <ToolOptions id={id} isGridCell={isGridCell}>
                <div>
                  <div>
                    <ToolInput
                      value='isImageGrid'
                      inputLabel='Image Grid'
                      inputType='checkbox'
                      labelPosition='after'
                      title='Positions images closer together'
                    />
                  </div>
                  <div>
                    <ToolInput
                      value='isMoreDetails'
                      inputLabel='Details Grid'
                      inputType='checkbox'
                      labelPosition='after'
                      title='Style as a More Details widget'
                    />
                  </div>
                  <OptionButton
                    onClick={() => setExpandCarousel((p) => !p)}
                    title='View full width widgets'
                  >
                    <Icon name={expandCarousel ? 'boxes' : 'box'} />
                    {expandCarousel ? 'Pack View' : 'Unpack View'}
                  </OptionButton>
                  <OptionButton onClick={() => resetColumnWidths()}>
                    <Icon name='chart pie' />
                    Reset Widths
                  </OptionButton>
                </div>
              </ToolOptions>
              {iw?.isMoreDetails && (
                <ToolSubheader>
                  <ToolInput
                    value='title'
                    inputLabel='Title'
                    placeholder='Title'
                    inputType='text'
                  />
                </ToolSubheader>
              )}
            </>
          )}
          <ToolBody>
            <DividingDiv>
              {storedWidget && storedWidget.rows.map((row, rIdx) => (
                <GridRow
                  key={`row-${rIdx}`}
                  style={{
                    overflowX: (row.length >= 2 || expandCarousel) ? 'scroll' : 'inherit',
                    overflowY: 'visible',
                    overflow: 'scroll'
                  }}
                >
                  <GridCol>
                    <CarouselRow
                      fitInView={expandCarousel}
                      rowLength={row.length}
                    >
                      {row.map((col, cIdx) => (
                        <GridCell
                          key={`row-${rIdx}-col-${cIdx}`}
                          fitInView={expandCarousel}
                          rowLength={row.length}
                          rowIndex={rIdx}
                          colIndex={cIdx}
                          cell_widget_id={col}
                          course={course}
                          lesson={lesson}
                          sublesson={sublesson}
                          assignWidget={assignWidget}
                          handleAddCell={handleAddCell}
                          handleRemoveCell={handleRemoveCell}
                          handleGridWidgetSave={handleSaveWidget}
                          fetchWidget={() => fetchW && fetchW(id)}
                          fetchSubLesson={() => null}
                        />
                      ))}
                    </CarouselRow>
                    {(storedWidget.rows?.[0].length) && (
                      <AddCellOrGridRow handleClicked={() => handleAddRow(rIdx)} />
                    )}
                  </GridCol>
                </GridRow>
              ))}
            </DividingDiv>
          </ToolBody>

          {!defaultOpen && (
            <ToolFooter
              lastEdited={widget?.updatedAt}
              lastEditedBy={widget?.last_edited_by}
              savedTimestamp={updatedAt}
              rawTimestamp={rawTimestamp}
              handleSave={() => {
                handleSaveWidget()
                fetchW(id)
              }}
              saveDisabled={!hookFormMethods.formState.isValid}
              widget_id={id}
            >
              <h2 style={{ color: 'var(--yellow)' }}>End of Grid Tool</h2>
            </ToolFooter>
          )}
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default GridTool
