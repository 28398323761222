import { DashLine } from 'src/components/fz_courses/widgets/styles/QnAStyles'
import styled, { css } from 'styled-components'

import { offWhiteColorCode } from 'src/assets/styles/globalStyles'

import { ReactComponent as MonthlyLabel } from 'src/assets/subscription/label_monthly.svg'
import { ReactComponent as AnnualLabel } from 'src/assets/subscription/label_yearly.svg'
import { ReactComponent as FreeLabel } from 'src/assets/subscription/label_free.svg'

const speechBubbleDynamic = 'https://d32tv5vz8ua1sf.cloudfront.net/images/register/label-value-speech-bubble.svg'

interface IsMobile {
  isMobile: boolean
}

interface WithPlan {
  plan: 'Yearly' | 'Monthly' | 'Free'
  selected?: boolean
}

export const RegisterPageContainer = styled.div<IsMobile>`
  display: grid;
  font-family: 'Milliard Book';
  padding-top: 4rem;
  
  & > p {
    justify-self: center;
    margin-bottom: 4rem;
    font-size: ${({ isMobile }) => isMobile ? '1rem' : '1.25rem'};
  }
`

export const Step = styled.img<IsMobile>`
  margin: ${({ isMobile }) => isMobile ? '0' : '0 1rem'};
  height: ${({ isMobile }) => isMobile ? '42.5px' : '50px'};
`

export const StepDashLine = styled(DashLine)<IsMobile>`
  width: ${({ isMobile }) => isMobile && 0};
  border-color: #6c7095;
  opacity: .7;
`

interface StepSubheaderProps extends IsMobile {
  create?: boolean
}

const createSubheaderStyles = ({ isMobile }) => css`
  font-size: ${isMobile ? '1rem' : '1.25rem'};
  margin: ${!isMobile && '1rem !important'};
`

export const StepSubheader = styled.p<StepSubheaderProps>`
  margin: 1rem 1rem 0rem !important;
  align-self: center;
  font-family: 'Milliard Book';
  font-size: ${(props) => props.isMobile ? '1rem' : '1.5rem'};
  ${({ create }) => create && createSubheaderStyles}
  margin-bottom: 2rem !important;
`

export const Plans = styled.section`
  display: flex;
  justify-content: center;
  margin: 1rem 0 3rem;
`

export const MobilePlans = styled.section`
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding: 0 1rem;
  margin-top: 1rem;
`

export const Plan = styled.div<WithPlan>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 275px;
  margin: 2rem;
  border: ${({ selected }) => selected ? 'solid 2px #ffb901' : 'solid 2px #cdcddb'};
  border-radius: 15px;
  background-color: ${({ selected }) => selected ? '#356cbf' : offWhiteColorCode};
  overflow: hidden;
  padding-bottom: 1rem;

  @media (max-width: 1000px) {
    margin: 2rem .5rem;
  }
  @media (max-width: 900px) {
    width: 250px;
  }
`

export const PlanHeader = styled.header<WithPlan>`
  height: 80px;
  background-color: ${({ selected }) => selected ? '#ffbe1a' : offWhiteColorCode};
`

export const planLabelStyles = {
  select: ({ isMobile }) => css`
    margin: ${isMobile ? '.5rem 0' : '1rem auto 0'};
    height: ${isMobile && '15px'};
    justify-self: ${isMobile && 'center'};
  `,
  selected: css`
    margin: 0.5rem 0 1rem;
  `,
  payment: css``
}

interface WithPlanStep extends IsMobile {
  step: 'select' | 'selected' | 'payment'
  selected?: boolean
  reverse?: boolean
}

export const PlanLabelFree = styled(FreeLabel)<WithPlanStep>`
  height: 35px !important;
  max-width: 150px !important;
  ${({ step }) => planLabelStyles[step]}
  color: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#777'};
  stroke: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#777'};

  @media (max-width: 550px) {
    height: 20px !important;
  }
`

export const PlanLabelMonthly = styled(MonthlyLabel)<WithPlanStep>`
  height: 35px !important;
  max-width: 150px !important;
  ${({ step }) => planLabelStyles[step]}
  color: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};
  stroke: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};

  @media (max-width: 550px) {
    height: 20px !important;
  }
`

export const PlanLabelAnnual = styled(AnnualLabel)<WithPlanStep>`
  height: 35px !important;
  max-width: 140px !important;
  ${({ step }) => planLabelStyles[step]}
  color: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};
  stroke: ${({ selected, reverse }) => selected ? (reverse ? '#2a64bc' : '#ffb901') : '#2a64bc'};

  @media (max-width: 550px) {
    height: 20px !important;
  }
`

export const PlanLabel = styled.img<WithPlanStep>`
  ${({ step }) => planLabelStyles[step]}
  color: ${({ selected }) => selected ? '#ffb901' : '#2a64bc'};
  stroke: ${({ selected }) => selected ? '#ffb901' : '#2a64bc'};
`

interface DiscountProps {
  selected?: boolean
}

export const YearlyDiscount = styled.p<DiscountProps>`
  color: #356cbf;
  background-color: #ffbe1a;
  text-align: center;
  font-weight: bold;
  padding-top: .25rem;
  background-color: ${({ selected }) => selected ? '#ffbe1a' : offWhiteColorCode};
  color: ${({ selected }) => selected ? '#356cbf' : '#6c7095'};
`

export const MobilePlanTitle = styled.h2<{ premium: boolean }>`
  color: ${({ premium }) => premium ? '#356cbf' : '#6c7095'};
  font-variant: small-caps;
  margin-top: 1rem !important;
  justify-self: center;
`

interface WithSelected extends WithPlan {
  selected: boolean
}

export const PlanDashLine = styled(DashLine)<WithSelected>`
  margin: ${({ selected }) => selected ? '0' : '0 1rem'};
  border-color: ${({ plan }) => plan === 'Yearly' && 'white'};
  opacity: 75%;
`

export const MobileDashLine = styled(DashLine)`
  grid-column: span 3;
  margin: 0.5rem 0;
`

export const Exclamation = styled.img`
  position: absolute;
  opacity: .07;
  width: 195px;
  top: -20px;
  right: -40px;
`

export const Perks = styled.ul`
  position: relative;
  padding: 1rem !important;
`

export const Perk = styled.li`
  list-style: none;
  display: grid;
  grid-template-columns: auto 1fr;
  margin: 0.5rem 0;
`

export const Checkmark = styled.p<WithPlan>`
  margin-right: 0.4rem !important;
  font-weight: bold;
  color: ${({ plan }) => ['Monthly', 'Yearly'].includes(plan) && '#7eadff'};
  color: ${({ selected }) => selected && '#ffbe1a'};
`

interface IsPremium {
  premium: boolean
}

export const MobileCheckmark = styled.p<IsPremium>`
  justify-self: center;
  padding-top: 0.5rem;
  color: ${({ premium }) => premium ? '#356cbf' : 'grey'};
  font-weight: bold;
`

export const mobilePerkTextStyles = css`
  color: #404040;
  padding: 0.5rem 0 0;
  font-size: 12px;
`

export const PerkText = styled.p<WithPlan & IsMobile>`
  color: ${({ selected }) => selected && 'white'};
  ${({ isMobile }) => isMobile && mobilePerkTextStyles}
`

export const MonthlyPrice = styled.p<WithPlan>`
  color: ${({ selected }) => selected ? 'white' : 'black'};
  font-weight: bold;
  align-self: center;
  text-decoration: line-through red;
  margin-bottom: -1rem !important;
`

export const PlanButton = styled.button<WithPlan>`
  border-radius: 15px;
  background-color: #6c7095;
  background-color: ${({ plan }) => ['Monthly', 'Yearly'].includes(plan) && '#7eadff'};
  background-color: ${({ selected }) => selected && '#ffbe1a'};
  justify-self: center;
  padding: .5rem 1rem;
  color: ${({ selected }) => selected ? '#356cbf' : 'white'};
  align-self: center;
  position: relative;
  z-index: 2;
  bottom: .5rem;
`

interface IsPremiumOrYearly {
  premium: boolean
  yearly: boolean
}

export const MobilePlanButton = styled.button<IsPremiumOrYearly>`
  border-radius: 15px;
  background-color: ${({ premium }) => premium ? '#7eadff' : '#6c7095'};
  background-color: ${({ yearly }) => yearly && '#ffbe1a'};
  margin: ${({ yearly }) => yearly ? '0 1rem 0 0' : '2rem 0'};
  margin-left: ${({ premium }) => premium && '.25rem'};
  padding: 0.25rem 0.5rem;
  color: ${({ yearly }) => yearly ? '#2a64bc' : 'white'};
  font-weight: ${({ yearly }) => yearly && 'bold'};
  font-size: 12px;
`

export const MobileYearlyBanner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0 2rem;
  background-color: #2a64bc;
  font-size: 11.5px;

  & > p {
    color: white;
    padding: 1rem 0 1rem 1rem;
  }
  & span {
    font-weight: bold;
    color: #ffbe1a;
  }
`

export const CreateAccountContainer = styled.section<IsMobile>`
  display: grid;
  grid-column-gap: 8rem;
  grid-template-columns: ${({ isMobile }) => isMobile ? '1fr' : 'minmax(auto, 400px) minmax(auto, 450px)'};
  margin: 2rem 1rem 4rem;
  justify-self: ${({ isMobile }) => isMobile ? 'stretch' : 'center'};

  @media (max-width: 1000px) {
    grid-column-gap: 2rem;
  }
`

export const RegisterForm = styled.form<IsMobile>`
  display: flex;
  flex-direction: column;
  min-width: ${({ isMobile }) => !isMobile && '400px'};
`

interface InputContainerProps {
  password: boolean
  noLine: boolean
}

export const InputContainer = styled.div<InputContainerProps>`
  display: grid;
  grid-template-columns: ${({ password }) => password ? 'auto 1fr auto' : 'auto 1fr'};
  align-items: center;
  border-bottom: ${({ noLine }) => !noLine && 'solid 1px #d6d7e3'};
`

export const RegisterInput = styled.input`
  padding: 0.5rem;
  background-color: #fbfbfe;

  &::placeholder {
    color: #547ec5;
  }
  &:focus {
    outline: none;
  }
`

export const Star = styled.span`
  position: absolute;
  color: #547ec5;
  left: .70rem;
`

export const Invalid = styled.h4`
  color: #356cbf;
  margin: 0.5rem 0 !important;
  display: grid;
  grid-template-columns: auto 1fr;
  align-self: center;
`

export const ConfirmAccount = styled.button`
  align-self: center;
  background-color: #7eadff;
  color: white;
  border-radius: 15px;
  padding: 0.5rem 4rem;
  margin: 2rem 0;
`

export const SelectedPlan = styled.div<IsMobile>`
  border: solid 2px #cdcddb;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  order: ${({ isMobile }) => isMobile && -1};
  padding: 1.5rem 1.5rem 3rem;
  margin-bottom: ${({ isMobile }) => isMobile && '2rem'};

  & > p {
    align-self: center;
  }
  & > h6 {
    margin-top: 2rem;
    color: #75758a;
  }
`

export const PriceInfo = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 0;

  & > p {
    color: #6d6c82;
  }
`

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.4);
`

export const CheckEmail = styled.div`
  display: grid;
  grid-template-columns: 175px 1fr;
  position: relative;
  background-color: #fbfbfe;
  width: 415px;
  padding: 0 2rem;
  border: 1px solid grey;
  border-radius: 20px;
  margin: 0 .5rem;

  & > p {
    padding: 3rem 0 3rem 0.5rem;
    font-family: 'Milliard Book';
    color: #77768a;
  }
  & > h6 {
    padding: 1rem 1rem 2rem;
    font-family: 'Milliard Book';
    font-size: 1rem;
    color: #585771;
    grid-column: 1 / -1;
  }
`

export const HannahContainer = styled.div`
  position: relative;
`

export const Hannah = styled.img<{ isMobile?: boolean, yjClaim: boolean }>`
  position: absolute;
  width: 175px;
  bottom: -139px;
  clip-path: inset(0 0 45% 0);

  ${({ isMobile, yjClaim }) => isMobile && yjClaim && css`
    top: -202px;
  `}
`

export const YJClaimModalContainer = styled.div`
  display: grid;
  grid-template-columns: 175px 1fr;
  background-color: #fbfbfe;
  max-width: 800px;
  border: 1px solid grey;
  border-radius: 20px;
  padding: 32px;
  margin: 16px;
`

export const TopText = styled.h3<IsMobile>`
  font-family: 'Milliard Book';
  color: #444;
  padding: ${({ isMobile }) => isMobile ? '0 0 16px' : '16px'};
  grid-column: ${({ isMobile }) => isMobile ? '1 / -1' : ''};
  font-size: ${({ isMobile }) => isMobile ? '17px' : ''} !important;
`

export const BottomText = styled.p`
  font-family: 'Milliard Book';
  grid-column: 1 / -1;
  padding-top: 16px;
`

export const PaymentPlans = styled.div<{ isAatPage?: boolean }>`
  display: flex;
  margin: 48px 0 16px;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  padding: 0 1rem;

  @media (max-width: 450px) {
    gap: 0.5rem;
  }

  // only if not modal
  // modal is kinda a mess and doesn't scroll
  @media (max-width: 425px) {
    margin-top: 18px;

    ${({ isAatPage }) => !isAatPage && css`
      flex-direction: column;
      align-items: center;
      margin-top: 36px;
      text-align: left;
    `}
  }
`

export const PaymentPlanContainer = styled.div<{ isSelected: boolean, chosenPlan: string, plan: string, isAatPage?: boolean }>`
  border: ${({ isSelected }) => isSelected ? 'solid 3px #ffb901' : 'solid 1px #cdcddb'};
  border-radius: 15px;
  padding: 1rem 7rem 1rem 1rem;
  position: relative;
  cursor: pointer;
  max-width: 280px;
  justify-self: center;
  ${({ isSelected }) => isSelected && 'background-color: #2a64bc;'}
  ${({ isSelected }) => isSelected && 'background-color: #2a64bc;'}
  fill: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;

  & > p {
    font-weight: bold;
    color: #6d6c82;
    ${({ isSelected }) => isSelected && 'color: white !important;'}
    ${({ isSelected }) => isSelected && 'color: white !important;'}
  }

  & > svg {
    color: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;
    fill: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;
    stroke: ${({ isSelected }) => isSelected ? '#ffb901' : '#2a64bc'} !important;
  }

  @media (max-width: 550px) {
    padding: 1rem 5rem 1rem 1rem;
  }

  // only if not modal
  @media (max-width: 425px) {
    ${({ isAatPage }) => !isAatPage && css`
      min-width: 250px;
    `}
  }
`

export const PaymentChibi = styled.img`
  position: absolute;
  width: 100px;
  right: -14.5px;
  bottom: -36px;
  clip-path: inset(0 14px 35px 0 round 13px);
  z-index: 2;

  @media (max-width: 550px) {
    width: 85px;
  }
`

export const SpeechBubble = styled.img`
  position: absolute;
  top: -30px;
  right: 70px;
  width: 125px;

  @media (max-width: 550px) {
    width: 105px;
    right: 55px;
    top: -20px;
  }
`

export const SpeechBubbleDynamic = styled.h4`
  font-family: 'Poppins Bold' !important;
  color: #2a64bc !important;
  position: absolute;
  top: -30px;
  right: 70px;
  background-image: url(${speechBubbleDynamic});
  padding: 8px 24px 20px !important;

  @media (max-width: 550px) {
    padding: 8px 8px 20px !important;
  }

  @media (max-width: 425px) {
    top: -25px;
    right: 65px;
  }
`

export const MonthlySavings = styled.h4<{ isAatPage?: boolean }>`
  font-family: 'Poppins Bold' !important;
  color: #2a64bc !important;
  position: absolute;
  top: -20px;
  right: 40%;

  // only if not modal since modal doesn't stack vertically
  @media (max-width: 425px) {
    ${({ isAatPage }) => !isAatPage && css`right: 29%;`}
  }
`

export const DisclaimerSpan = styled.span`
  color: #fcfaff;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 8px;
`

export const RedX = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  font-weight: bolder;
  color: white;
  background-color: red;
  position: absolute;
  border: solid 2px white;
  right: -7px;
  top: -7px;

  :hover {
    cursor: pointer;
  }
`

export const HannahEmailDashLine = styled(DashLine)`
  grid-column: 1 / -1;
`
