// import React, { useContext, useMemo, MutableRefObject } from 'react'
// import styled from 'styled-components'

import { Icon } from 'semantic-ui-react'
import FlashMessageStyles from '../styles/FlashMessageStyles'

const FlashMessage = () => {
  const renderMessage = undefined
  // const renderMessage = useContext(FlashMessageContext)

  // const topOffset = useMemo(() => {
  //   let _tOffset = 0
  //   if (!renderMessage?.length || !context || !context?.current?.clientHeight) return _tOffset
  //   _tOffset = context.current.clientHeight
  //   return _tOffset
  // }, [context, renderMessage])

  // const widthOffset = useMemo(() => {
  //   let _wOoffset = 0
  //   if (!renderMessage?.length || !context || !context?.current?.offsetWidth) return _wOoffset
  //   _wOoffset = context.current.offsetWidth

  //   return window.innerWidth - _wOoffset
  // }, [context, renderMessage, window.innerWidth])

  return (
    <FlashMessageStyles>
      {renderMessage?.length
        && <div className='flash-message'>
          <Icon name="exclamation circle" color="yellow" />
          <div>
            <p>{renderMessage}</p>
          </div>
        </div>
      }
    </FlashMessageStyles>
  )
}

export default FlashMessage
