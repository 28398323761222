import React from 'react'
import { IconButton, ToolInput } from '../../parts'
import { QnAResponse } from '../../styles'

interface QnAResponseProps {
  outerBlockIndex: number
  blockIndex: number
  removeResponse: (arg0: number, arg1: number, arg2: boolean, arg3?: number) => void
  onEdit?: () => void
}

const QnAResponses: React.FC<QnAResponseProps> = ({
  outerBlockIndex,
  blockIndex,
  removeResponse,
  onEdit = () => null
}) => {
  const responseFields = [
    [`blocks.${outerBlockIndex}.${blockIndex}.other_lang.0`, 'Target Language'],
    [`blocks.${outerBlockIndex}.${blockIndex}.english.0`, 'English'],
    [`blocks.${outerBlockIndex}.${blockIndex}.aside`, 'Aside (optional)'],
  ]
  return (
    <QnAResponse>
      <div>
      {responseFields.map((field, idx) => (
        <React.Fragment key={idx}>
          <ToolInput
            value={field[0]}
            placeholder={field[1]}
            onEdit={onEdit}
          />
          {idx < 2 && <br />}
        </React.Fragment>
      ))}
      </div>
      <IconButton
        color='var(--red)'
        contrastColor='var(--off-white)'
        handleClick={() => removeResponse(outerBlockIndex, blockIndex, true)}
        icon='delete'
        title='Delete Row'
      />
    </QnAResponse>
  )
}

export default QnAResponses
