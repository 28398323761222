import { useEffect, useRef } from 'react'

/* eslint-disable max-len */
/* eslint-disable no-trailing-spaces */

/**
 * Hook to create a portal element
 *
 * A working example can be found at client/src/components/fz_courses/admin/creation_toolkit/tools/WidgetSample.tsx
 *
 * @example
 *
 * "client/src/components/MyComponentWithPortal.tsx"
 *
 * import { createPortal } from 'react-dom'
 *
 * const MyPortal = ({ children }) => {
 *   const portalTarget = usePortal('portal-target')
 *   return createPortal(children, portalTarget)
 * }
 *
 * const MyComponentWithPortal = () => {
 *   return (
 *     <div>
 *       <p>"I don't want to portal this part"</p>
 *       <MyPortal>
 *         <p>"Now you're thinking with portals"</p>
 *       </MyPortal>
 *     </div>
 *   )
 * }
 *
 * export default MyComponentWithPortal
 * 
 * "client/src/components/MyComponentWithPortalTarget.tsx"
 * 
 * import MyComponentWithPortal from './MyComponentWithPortal'
 * 
 * const MyComponentWithPortalTarget = () => {
 *   return (
 *    <div id='portal-target'></div>
 *   )
 * }
 * 
 * export default MyComponentWithPortalTarget
 */
const usePortal = (html_tag_id: string) => {
  const rootElemRef = useRef(document.createElement('div'))

  useEffect(() => {
    // Look for existing target dom element to append to
    const parentElem = document.querySelector(`#${html_tag_id}`)
    if (parentElem) {
      // Add the detached element to the parent
      parentElem.appendChild(rootElemRef.current)
      // This function is run on unmount
    } else {
      // eslint-disable-next-line no-console
      console.log('No parent element found for portal')
    }

    return function removeElement() {
      rootElemRef.current.remove()
    }
  }, [html_tag_id])

  return rootElemRef.current
}

export default usePortal
