import React, { useEffect, useState } from 'react'

import { Divider, Header, Table } from 'semantic-ui-react'

const QnAPreview = props => {

    return <>
        {props.data.map((block, idx) =>
            <>
                <Header as='h3'>Q&amp;A {idx+1}</Header>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Japanese</Table.HeaderCell>
                            <Table.HeaderCell>English</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {block.map(sentence => <Table.Row><Table.Cell>{sentence.other_lang}</Table.Cell><Table.Cell>{sentence.english}</Table.Cell></Table.Row>)}
                    </Table.Body>
                </Table>
            </>)}
        </>
}

export default QnAPreview