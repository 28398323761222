import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { MOREDETAILS } from '../toolkit.constants'
import { MoreDetailsWidget } from 'src/types'

import { ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { ToolFooter, ToolForm, ToolHeader, ToolInput, ToolOptions, ToolSubheader } from '../parts'
import useWidgetSaved from '../hooks/useWidgetSaved'

import NestedWidgetTool from './NestedWidgetTool'

const MoreDetailsTool: React.FC<ToolProps<MoreDetailsWidget>> = ({
  course,
  lesson,
  sublesson,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [storedWidget, setStoredWidget] = useState<MoreDetailsWidget>()
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<MoreDetailsWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      setStoredWidget(widget)
      setWidgetValues(widget)
    }

    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={MOREDETAILS}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='more-details-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={MOREDETAILS}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell} />
          <ToolSubheader>
            <ToolInput
              value='title'
              inputLabel='Title'
              placeholder='Title'
              inputType='text'
            />
          </ToolSubheader>
          <ToolBody>
            {storedWidget?.contents && (
              <NestedWidgetTool
                widget_id={storedWidget.contents}
                course={course}
                lesson={lesson}
                sublesson={sublesson}
              />
            )}
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          >
            <h2 style={{ color: 'var(--yellow)' }}>
              End of More Details Tool
            </h2>
          </ToolFooter>
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default MoreDetailsTool
