import styled, { css } from 'styled-components'

const userAvatarWidth = css`
  .user-badge-avatar {
    min-width: 265px;
    width: 265px;
    max-width: 265px;
  }

  @media (max-width: 1400px) {
    .user-badge-avatar {
      width: 100px;
      max-width: 100px;
      min-width: 100px;
    }
  }

  @media (min-width: 1600px) {
    .user-badge-avatar {
      min-width: 265px;
      width: 265px;
      max-width: 265px;
    }
  }
`

interface StyleProps {
  mobile: boolean
  hardUserAvatarWidth: boolean
  transparent_nav_bar: boolean
}

export default styled.div<StyleProps>`
  position: relative;
  width: 100%;
  padding: ${(props) => (props.transparent_nav_bar ? '0' : '5px 0')};
  max-height: 70px;

  .fz-label,
  .fz-input,
  input {
    color: var(--blue-gray);
  }
  .fz-input {
    border: var(--blue-border);
    border-radius: 5px;
    margin-left: 8px;
    padding: 4px 6px;
  }

  .active {
    font-weight: bold;
  }

  #content-body {
    min-height: ${(props) => props.transparent_nav_bar ? '0' : 'calc(100vh - 70px - 50px)'};
  }

  .navbar {
    height: ${(props) => (props.transparent_nav_bar ? '0' : '65px')};
  }

  .fz-logo {
    width: ${(props) => (props.mobile ? '170px' : '195px')} !important;
    max-width: ${(props) => (props.mobile ? '170px' : '195px')} !important;
    min-width: ${(props) => (props.mobile ? '170px' : '195px')} !important;
    margin-right: auto;
    object-fit: contain;
    cursor: pointer;

    span {
      display: inline-flex;
      flex-direction: row;
      align-items: center;

      .chevron {
        margin-left: 8px;
        .white {
          fill: var(--off-white);
        }
        .blue {
          fill: var(--jfz-blue);
        }
      }
    }

    img {
      max-height: 65px;
      max-width: 100%;
    }
  }

  .user-badge-avatar {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .admin-edit-mode {
      margin-right: 12px;
      background-color: white;
      padding: 6px 10px 6px 6px;
      border-radius: 8px;
      cursor: pointer;

      span { display: inline-block; }
      .icon { margin-right: 2px; }
      .view { margin-top: 2px; }
    }

    .user-badge {
      cursor: pointer;
      height: 50px;
      width: 50px;
      margin-right: 5px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .user-avatar {
      position: relative;
      cursor: pointer;

      div:first-child {
        display: inline-block;
        overflow: hidden;
        border-radius: 50%;
        object-fit: contain;
        width: 50px;
        min-width: 50px;
        height: 50px;
        min-height: 50px;
        background-color: var(--off-white);

        img {
          width: 100%;
          height: 100%;
        }
      }

      .soft-glow {
        animation-name: breathing_blue;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-direction: alternate;
        animation-iteration-count: infinite;
      }
    }

    .notification-bubble {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      height: 20px;
      min-width: 20px;
      border-radius: 50px;
      background-color: var(--red);
      position: absolute;
      top: 0;
      right: -5px;

      p {
        font-family: 'Jost', sans-serif;
        padding: 0;
        margin: 0;
        font-size: 0.8rem;
        font-feature-settings: tnum;
        font-variant-numeric: tabular-nums;
        color: var(--off-white);
      }
    }
  }

  .logged-out-nav {
    > div {
      border: 2px solid white;
      border-radius: 8px;
    }

    a {
      font-family: 'Milliard Book';
    }

    .fz-button {
      :hover {
        color: var(--blue-gray) !important;
        border-color: white !important;
      }
      :focus {
        color: var(--blue-gray);
        outline-color: white;
      }
    }
  }

  .fz-button {
    font-size: 1.2rem;
    color: white !important;
    border: 2px solid var(--jfz-blue);
    background-color: var(--jfz-blue);
    border-radius: 5px !important;
    padding: 8px !important;
    margin: 0 auto;
    cursor: pointer !important;

    display: flex;
    align-items: center;

    &:hover {
      background-color: white !important;
      color: var(--jfz-blue) !important;
      border-color: var(--jfz-blue) !important;
    }
    &:nth-child(2) {
      margin: 0 20px;
    }
    &:focus {
      outline: 2px solid #333;
    }
  }

  .logout button {
    padding: 5px 10px;
    border: 1.5px solid var(--blue-gray);
    border-radius: 50px;
    font-weight: bold;
    cursor: pointer;
    transition: 0.2s ease-in all;

    :hover {
      background-color: var(--jfz-blue);
      border-color: var(--jfz-blue);
      color: var(--off-white);
    }
  }

  ${userAvatarWidth}

  @media (max-width: 1400px) {
    .admin-edit-mode {
      padding-right: 6px !important;
      .view {
        display: none !important;
        visibility: hidden;
      }
      .icon { margin-right: 0 !important; }
    }
  }

  @media (max-width: 1100px) {
    .nav-icon {
      a,
      svg,
      img {
        margin-right: 0 !important;
      }
    }
    span.link-name {
      display: none;
    }
  }

  @media (max-width: 900px) {
    .admin-edit-mode {
      position: absolute;
      bottom: -65px;
      right: -5px;
    }
  }
  
  @media (max-width: 768px) {
    .admin-edit-mode {
      position: relative;
      bottom: 0;
      right: 0;
    }
    .fz-logo,
    .user-badge-avatar {
      display: ${(props) => (props.mobile ? '' : 'none')};
    }
    .fz-logo {
      box-sizing: content-box;
      min-width: 225px;
      padding: 10px;
      border-radius: 8px;
    }
  }
`
