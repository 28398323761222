import styled from '@emotion/styled'
import { FaCaretRight } from 'react-icons/fa6'
import { Loader } from '@mantine/core'
import { buttonPatternDefault, buttonPatternHover, red } from '../styles'

export const Button = styled.button`
  min-width: 275px;
  background-color: ${red};
  background-image: url(${buttonPatternDefault});
  background-size: cover;
  color: white;
  padding: 10px 24px;
  border-radius: 24px;
  font-size: 1.5rem;
  font-family: 'Tilt Warp';

  &:hover { background-image: url(${buttonPatternHover}); cursor: pointer; }
  
  @media (max-width: 575px) { font-size: 1.25rem; min-width: 250px; }
`

const RedButton = ({ loading = false, children, ...rest }) => {
  return (
    <Button {...rest}>
      {loading ? <Loader color='white' variant='dots' style={{ display: 'inline-block' }} /> : children}
      {!loading && <FaCaretRight style={{ display: 'inline' }}/>}
    </Button>
  )
}

export default RedButton;
