import { useState } from 'react'
import axios from 'src/api'
import { Button } from 'semantic-ui-react'

type ListifyButtonProps = {
  widget: string
}

// button that takes an examples|sentences|reading comp|dialogue widget and converts it
// to the new list widget
const ListifyButton: React.FC<ListifyButtonProps> = ({ widget }) => {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handleClick = async () => {
    setLoading(true)
    const res = await axios.post(`/widgets/listify/${widget}`)
    setLoading(false)
    setSuccess(res.data.success)
  }

  return (
    <Button
      loading={loading}
      onClick={handleClick}
      color="blue"
      content="Listify"
      disabled={success}
    />
  )
}

export default ListifyButton
