import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import authReducer from './authReducer'
import userReducer from './userReducer'
import popupReducer from './popupReducer'
import navigatorReducer from './navigatorReducer'

export default combineReducers({
  auth: authReducer,
  form: formReducer,
  user: userReducer,
  soundPopup: popupReducer,
  navigator: navigatorReducer,
})
