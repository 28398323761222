import React, { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from '../../../api'
import { connect, useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import Confetti from 'react-dom-confetti'
import { Grid, Header, Image, Icon } from 'semantic-ui-react'
import { ProgressBar, QuizOption } from './styles/MiniQuizWidgetStyles'
import { Progress, Space } from '@mantine/core'
import getCourseInfo, { getTitle } from 'src/util/courseInfo'
import Activity from './activities/new/Activity'

import useMobile from '../../../hooks/useMobile'
import useCF from 'src/hooks/useCF'

import WidgetWrapper from '../../error_boundary/WidgetWrapper'
import SoundRenderer from './SoundRenderer'

import tomoko from '../../../assets/quiz/chibi_tomoko_cheer_quiz_results.png'
import tomokoPity from '../../../assets/quiz/tomoko_pity_clipped.png'
// import natsumi from '../../../assets/chibi/jfz_chibi_natsumi_2.png'
import mari from '../../../assets/chibi/mari/chibi_writing_points.png'
import bg from '../../../assets/quiz/bkgd_quiz_result.png'
// import stripe from '../../../assets/quiz/stripe.png'
import starsBG from '../../../assets/quiz/quiz_result_bkgd_0_2.png'
import amazing from '../../../assets/quiz/quiz_result_amazing.png'
import great from '../../../assets/quiz/quiz_result_great.png'

import stamp70 from '../../../assets/quiz/stamp_70_or_above.svg'
import stamp100 from '../../../assets/quiz/stamp_100.svg'
import stampIncomplete from '../../../assets/quiz/stamp_incomplete_or_below_70.svg'

import dingPop from '../../../assets/quiz/happy-pop-ding-success.mp3'
import shinyAchieve from '../../../assets/quiz/good-idea-shiny-achievement-achieve.mp3'
import wrongAnswer from '../../../assets/quiz/wrong_answer.mp3'
import gameOver from '../../../assets/quiz/game_over.mp3'
import useStaffRoles from 'src/hooks/useStaffRoles'
import { FZButton } from 'src/assets/styles/globalStyles'
import useSublessonStore from 'src/stores/sublesson.store'
// import { FromZeroButton } from 'src/assets/FromZeroButton'
import { CLOUDFRONT_BASE } from 'src/util/constants'

// new activity style imports
const activityBase = `${CLOUDFRONT_BASE}/images/activities`
const bkgdPolyfetti = `${activityBase}/bkgd_polyfetti_c1.svg`
const tomokoIdle = `${activityBase}/act_tmk_idle.webp`

/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
const mobileQuizFinishedMediaQueries = css`
  @media (max-width: 555px) {
    .results {
      grid-template-columns: 40%;
    }
    .results h4 {
      font-size: 1.25rem;
      align-self: end;
    }
    .results h3 {
      font-size: 2.5rem;
      margin-right: .5rem;
    }
    .results h2 {
      font-size: 3rem;
    }
  }
  @media (max-width: 450px) {
    .chibi {
      right: -20%;
    }
  }
  @media (max-width: 360px) {
    .chibi {
      right: -25%;
    }
    .pity {
      right: -28%;
    }
  }
`

const desktopQuizFinishedMediaQueries = css`
  @media (min-width: 992px) {
    .image-container {
      min-height: 390px;
    }
    .chibi {
      width: 350px;
    }
    .exclamation {
      height: 119px;
    }
    .results h4 {
      font-size: 2rem;
    }
    .results h3 {
      font-size: 3.5rem;
      margin-right: .5rem;
    }
    .results h2 {
      font-size: 4rem;
    }
  }
  @media (min-width: 1200px) {
    .image-container {
      min-height: 479px;
    }
    .chibi {
      width: 430px;
    }
    .exclamation {
      height: 146px;
    }
    .results h4 {
      font-size: 2.5rem;
    }
    .results h3 {
      font-size: 4rem;
      margin-right: .5rem;
    }
    .results h2 {
      font-size: 4.5rem;
    }
  }
`

const QuizFinishedStyles = styled.div`
  color: var(--blue-gray);
  font-family: 'Milliard Book';
  font-size: 1.5rem;
  margin-top: ${({ embedded }) => (embedded ? '60px' : '15px')};
  position: relative;

  .image-container {
    position: relative;
    margin: ${(props) => props.isMobile ? '0 -9.5px' : '0 -5px'};
    background-image: ${(props) => props.quizGrade >= 0.70 ? `url(${bg})` : `url(${starsBG}), linear-gradient(#e8c09c, #fcf3cb)`};
    background-size: cover;
    min-height: 292px;
    overflow: hidden;
  }

  .chibi {
    position: absolute;
    right: 3%;
    top: -10%;
    width: 262px;
  }

  .pity {
    top: 3%;
  }

  .exclamation {
    position: absolute;
    height: 90px;
    bottom: 7%;
    left: 25%;
  }

  .orange {
    font-family: 'Work Sans', sans-serif;
    color: #e97257;
  }

  .results {
    position: relative;
    z-index: 1;
    margin-top: 2rem;
    display: grid;
    grid-template-columns: 35%;
    grid-template-rows: 1fr 2fr 1fr 3fr;
    justify-items: end;
  }
  .results h4 {
    font-size: 1.5rem;
    align-self: end;
  }
  .results h3 {
    color: ${(props) => props.quizGrade < 0.70 ? '#ba7e48' : ''};
    font-size: 3rem;
    margin-right: .5rem;
  }
  .results h2 {
    font-size: 3.5rem;
  }

  .num-right {
    display: flex;
    align-items: center;
    align-self: start;
  }

  ${(props) => props.isMobile
    ? mobileQuizFinishedMediaQueries
    : desktopQuizFinishedMediaQueries
  // eslint-disable-next-line
  }
`

const ViewAnswers = styled.button`
  padding: 0.35rem 1rem;
  border: solid 1px #888888;
  border-radius: 20px;
  background-color: white;
  font-size: 1.1rem;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  z-index: 2;

  :hover {
    background-color: #8ebfe8;
    color: white;
    border: none;
  }

  @media (max-width: 991px) {
    bottom: 2rem;
    left: 1.5rem;
    font-size: 1rem;
  }
  @media (max-width: 555px) {
    bottom: 6.5rem;
    left: 6rem;
    font-size: 12px;
  }
  // kinda silly but working within the current structure
  @media (max-width: 530px) { left: 5rem; }
  @media (max-width: 500px) { left: 4.5rem; }
  @media (max-width: 480px) { left: 3.75rem; }
  @media (max-width: 450px) { left: 3rem; }
  @media (max-width: 425px) { left: 2.25rem; }
  @media (max-width: 400px) { left: 1.5rem; }
  @media (max-width: 375px) { left: .75rem; }
  @media (max-width: 350px) { left: 0; }
`

const MiniQuizDiv = styled.div`
  // border: 2px dashed #60A2EF;
  padding: ${({ isMobile }) => isMobile ? '14px' : '28px'};
  font-size: 1.5rem;
  background-color: white;
  background-image: url(${bkgdPolyfetti});
  background-size: contain;
  min-height: ${({ embedded }) => embedded ? '645px' : '560px'};
  padding: ${({ quizFinished, isMobile }) => quizFinished ? isMobile ? '0 5px' : '16px 5px' : ''};
`

const QuizHeader = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  gap: 16px;
`

const ActivityType = styled.h3`
  font-size: 2rem !important;
  font-family: Poppins;
  color: ${({ primaryColor }) => primaryColor};
  border-bottom: 1px dashed #aaa;
  width: 80%;
  display: grid;
  align-items: center;
`

const Tomoko = styled.img`
  grid-row: 1 / 3;
  grid-column: 3;
  height: ${({ isMobile }) => isMobile ? 100 : 150}px;
  align-self: end;
`

const Question = styled.h4`
  font-size: ${({ isMobile }) => isMobile ? 1.1 : 1.5}rem !important;
  font-family: Poppins;
  color: #3C4043;
  grid-column: 1 / 3;
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: baseline;
  gap: 8px;
  padding-bottom: 16px;
`

const QuestionNumber = styled.span`
  font-size: ${({ isMobile }) => isMobile ? 1 : 1.25}rem;
  width: ${({ isMobile }) => isMobile ? 25 : 30}px;
  height: ${({ isMobile }) => isMobile ? 25 : 30}px;
  border-radius: 50%;
  background-color: #3C4043;
  color: white;
  display: grid;
  place-content: center;
`

const GradientProgess = styled(Progress)`
  border: 1px solid #eee;
  background-color: white;
  padding: 8px;

  & .mantine-Progress-bar {
    background: linear-gradient(90deg, #B0C6FF 0%, #89E9D9 100%);
  }
`

const CheckAnswer = styled.button`
  display: block;
  margin: 0 auto;
  background-color: #60A2EF;
  color: white;
  border: none;
  border-radius: 30px;
  padding: 16px 40px;
  font-size: 1rem;
  font-family: Poppins;
  margin-top: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:disabled {
    background-color: #C8D0EA;
    cursor: default;
  }
`

const QuizImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: relative;

  /* The initial styles */
  display: block;
  min-height: 4rem;
  width: 100%;
  
  &::before {
    /* Hide the default placeholder */
    background: #fff;
    content: '';
  }

  &::before, &::after {
    /* Take full size of the image */
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

const QuizImage = ({ src }) => {
  const [cfPath, getCfPath] = useCF('')

  useEffect(() => {
    if (!src?.trim().length) return
    getCfPath(src)
  }, [src])

  return (
    <QuizImg
      src={cfPath}
      alt="quiz image"
      style={{ width: '100%', height: '100%', objectFit: 'contain' }}
    />
  )
}

const Quiz = (props) => {
  const {
    checkCorrectAnswer,
    currentQuestion,
    course,
    correctChosen,
    selectedAnswer,
    setSelectedAnswer,
    shuffledItems,
    answerOrder,
    // widgetId,
    wrongChosen,
    isMultichoice,
    selectedAnswers,
    setSelectedAnswers,
    multichoiceResults
  } = props
  // console.log(checkCorrectAnswer)
  const isMobile = useMobile()
  const [inputAnswer, setInputAnswer] = useState('')

  useEffect(() => {
    setInputAnswer('')
  }, [currentQuestion])

  const handleSetSelectedAnswers = (idx) => {
    setSelectedAnswers((prev) => {
      const newState = [...prev]
      newState[idx] = !newState[idx]
      return newState
    })
  }

  const soundsType = shuffledItems[currentQuestion]?.activity_type === 'sounds'
  const imagesType = shuffledItems[currentQuestion]?.activity_type === 'images'
  const inputType = shuffledItems[currentQuestion]?.activity_type === 'input'
  const matchingType = shuffledItems[currentQuestion]?.activity_type === 'matching'
  const textType = shuffledItems[currentQuestion]?.activity_type === 'text' || (!soundsType && !imagesType && !inputType && !matchingType)

  if (textType || soundsType) {
    return (
      <div>
        <div
          className='mini-quiz flex-center'
          style={{
            flexDirection: soundsType ? 'row' : 'column',
            alignItems: 'stretch',
          }}
        >
          {isMultichoice
            ? answerOrder.map((ao, idx) => {
              const isCorrect = ao < 0
              const currentQ = shuffledItems[currentQuestion]
              return (
                <div key={`multichoice-${currentQuestion}-${ao}`}>
                  <QuizOption
                    isSelected={selectedAnswers[idx]}
                    course={course}
                    tabIndex='0'
                    onClick={() => handleSetSelectedAnswers(idx)}
                    onKeyUpCapture={(e) => e.key === 'Enter' && handleSetSelectedAnswers(idx)}
                    correct={multichoiceResults[idx] === 'correct'}
                    wrong={multichoiceResults[idx] === 'wrong'}
                    missed={multichoiceResults[idx] === 'missed'}
                    style={{
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                      padding: isMobile ? '13px 10px 10px' : '',
                      minHeight: isMobile ? '' : '65px',
                    }}
                    disableHover={isMobile}
                  >
                    <SoundRenderer
                      noPopup
                      text={
                        ao === 0
                          ? currentQ.correct_answer
                          : isCorrect
                            ? currentQ.multichoice_correct_answers[Math.abs(ao) - 1]
                            : currentQ.other_answers[ao - 1]
                      }
                      key={`${currentQuestion}-${ao}`}
                      forcedVersion={currentQ.forced_version}
                      display_sound_icon={currentQ.display_sound_icon !== undefined ? currentQ.display_sound_icon : true}
                    />
                  </QuizOption>
                </div>
              )
            })
            : answerOrder.map((ao, idx) => {
              const otherA = shuffledItems[currentQuestion].other_answers[ao - 1]
              const isValidOption = otherA !== '' && otherA !== undefined && otherA !== null && !Number.isNaN(otherA)

              const cachedData = ao === 0
                ? shuffledItems[currentQuestion].correct_answer
                : shuffledItems[currentQuestion].other_answers[ao - 1]

              let cachedText = typeof cachedData === 'string'
                ? cachedData
                : cachedData?.map((d) => d.text).join('')

              if (!cachedText) cachedText = 'undefined'
              const currentQ = shuffledItems[currentQuestion]
      
              if (ao === 0 || (otherA !== '' && isValidOption)) {
                return (
                <div style={{
                  width: soundsType ? '20%' : '100%',
                  margin: soundsType ? '10px' : 'auto',
                  height: soundsType ? '200px' : 'auto'
                }}>
                  <QuizOption
                    key={`${currentQuestion}-${cachedText}`}
                    isSelected={selectedAnswer === idx}
                    course={course}
                    tabIndex='0'
                    onClick={() => setSelectedAnswer(idx)}
                    onKeyUpCapture={(e) => e.key === 'Enter' && setSelectedAnswer(idx)}
                    correct={correctChosen && answerOrder[idx] === 0}
                    wrong={wrongChosen === idx}
                    style={{
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                      padding: isMobile ? '13px 10px 10px' : '',
                      minHeight: isMobile ? '' : '65px',
                      height: '100%',
                      display: soundsType ? 'flex' : 'block',
                      alignItems: soundsType ? 'center' : 'stretch',
                      justifyContent: soundsType ? 'center' : 'stretch',
                    }}
                    disableHover={isMobile}
                  >
                    <SoundRenderer
                      noPopup
                      text={
                        ao === 0
                          ? shuffledItems[currentQuestion].correct_answer
                          : shuffledItems[currentQuestion].other_answers[ao - 1]
                      }
                      key={`${currentQuestion}-${ao}`}
                      iconOnly={soundsType}
                      huge={soundsType}
                      isSelected={selectedAnswer === idx && soundsType}
                      forcedVersion={currentQ.forced_version}
                      display_sound_icon={currentQ.display_sound_icon !== undefined ? currentQ.display_sound_icon : true}
                    />
                  </QuizOption>
                </div>
                )
              }
              return (<></>)
            })
          }
        </div>
        <CheckAnswer
          disabled={isMultichoice ? false : selectedAnswer === -1}
          onClick={checkCorrectAnswer}
        >
          Check
        </CheckAnswer>
      </div>
    )
  }

  if (imagesType) {
    // console.log('images type')
    // console.log(isMultichoice ? false : selectedAnswer === -1)
    // console.log(isMultichoice, selectedAnswer)
    return (
      <div>
        <div className='mini-quiz flex-center'>
          {answerOrder.map((ao, idx) => {
            const otherA = shuffledItems[currentQuestion].other_answers[Math.abs(ao) - 1]
            const isValidOption = otherA !== '' && otherA !== undefined && otherA !== null && !Number.isNaN(otherA)

            const cachedData = ao === 0
              ? shuffledItems[currentQuestion].correct_answer
              : shuffledItems[currentQuestion].other_answers[Math.abs(ao) - 1]

            let cachedText = typeof cachedData === 'string'
              ? cachedData
              : cachedData?.map((d) => d.text).join('')

            if (!cachedText) cachedText = 'undefined'

            if ((otherA !== '' && isValidOption)) {
              return (
                <div style={{
                  width: soundsType ? '20%' : 'auto',
                  margin: soundsType ? '10px' : 'auto',
                  height: soundsType ? '200px' : 'auto'
                }}>
                  <QuizOption
                    key={`${currentQuestion}-${cachedText}`}
                    isSelected={selectedAnswers[idx]}
                    course={course}
                    tabIndex='0'
                    onClick={() => handleSetSelectedAnswers(idx)}
                    onKeyUpCapture={(e) => e.key === 'Enter' && setSelectedAnswer(idx)}
                    correct={multichoiceResults[idx] === true}
                    wrong={multichoiceResults[idx] === false}
                    missed={multichoiceResults[idx] === false}
                    style={{
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                      padding: isMobile ? '13px 10px 10px' : '',
                      minHeight: isMobile ? '' : '65px',
                      height: '200px',
                      width: '200px',
                      display: soundsType ? 'flex' : 'block',
                      alignItems: soundsType ? 'center' : 'stretch',
                      justifyContent: soundsType ? 'center' : 'stretch',
                    }}
                  >
                    <QuizImage
                      key={`${currentQuestion}-${ao}`}
                      src={shuffledItems[currentQuestion].other_answers[Math.abs(ao) - 1]}
                    />
                  </QuizOption>
                </div>
              )
            }
            return (<></>)
          })}
        </div>
        <FZButton
          style={{
            margin: '0 auto',
            marginTop: '20px',
          }}
          disabled={(isMultichoice || imagesType) ? false : selectedAnswer === -1}
          onClick={checkCorrectAnswer}
          hoverBackgroundColor='white'
          hoverColor='var(--jfz-blue)'
          backgroundColor='var(--jfz-blue)'
          disabledBackgroundColor='gray'
          borderColor='var(--jfz-blue)'
        >
          Check
        </FZButton>
      </div>
    )
  }

  // left-right matching pairs; when you click the left pair, it waits for the user to select
  // the right pair, then it checks if the right pair is correct
  if (matchingType) {
    // console.log(answerOrder)
    return (
      <div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {answerOrder.map((ao, idx) => (
                  <QuizOption
                    key={`${currentQuestion}-${idx}-left`}
                    isSelected={selectedAnswer === idx}
                    course={course}
                    tabIndex='0'
                    onClick={() => setSelectedAnswer(idx)}
                    onKeyUpCapture={(e) => e.key === 'Enter' && setSelectedAnswer(idx)}
                    correct={correctChosen && answerOrder[idx] === 0}
                    wrong={wrongChosen === idx}
                    style={{
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                      padding: isMobile ? '13px 10px 10px' : '',
                      minHeight: isMobile ? '' : '65px',
                      height: '100%',
                      display: soundsType ? 'flex' : 'block',
                      alignItems: soundsType ? 'center' : 'stretch',
                      justifyContent: soundsType ? 'center' : 'stretch',
                    }}
                  >
                    <SoundRenderer
                      noPopup
                      text={shuffledItems[currentQuestion].other_answers[Math.abs(ao) - 1]}
                      key={`${currentQuestion}-${ao}-left`}
                      iconOnly={soundsType}
                      huge={soundsType}
                      isSelected={selectedAnswer === idx}
                    />
                  </QuizOption>
                ))}
              </div>
            </Grid.Column>
            <Grid.Column width={8}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {answerOrder.map((ao, idx) => (
                  <QuizOption
                    key={`${currentQuestion}-${idx}-right`}
                    isSelected={selectedAnswer === idx}
                    course={course}
                    tabIndex='0'
                    onClick={() => setSelectedAnswer(idx)}
                    onKeyUpCapture={(e) => e.key === 'Enter' && setSelectedAnswer(idx)}
                    correct={correctChosen && answerOrder[idx] === 0}
                    wrong={wrongChosen === idx}
                    style={{
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                      padding: isMobile ? '13px 10px 10px' : '',
                      minHeight: isMobile ? '' : '65px',
                      height: '100%',
                      display: soundsType ? 'flex' : 'block',
                      alignItems: soundsType ? 'center' : 'stretch',
                      justifyContent: soundsType ? 'center' : 'stretch',
                    }}
                  >
                    <SoundRenderer
                      noPopup
                      text={shuffledItems[currentQuestion].matching_answers[Math.abs(ao) - 1]}
                      key={`${currentQuestion}-${ao}-right`}
                      iconOnly={soundsType}
                      huge={soundsType}
                      isSelected={selectedAnswer === idx}
                    />
                  </QuizOption>
                ))}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <FZButton
          style={{
            margin: '0 auto !important',
            marginTop: '20px !important',
            backgroundColor: inputAnswer === '' ? 'gray !important' : 'auto',
          }}
          disabled={inputAnswer === ''}
          onClick={checkCorrectAnswer}
          hoverBackgroundColor='white'
          hoverColor='var(--jfz-blue)'
          backgroundColor='var(--jfz-blue)'
          disabledBackgroundColor='gray'
          borderColor='var(--jfz-blue)'
        >
          Check
        </FZButton>
      </div>
    )
  }

  // input type
  return (
    <div>
      <div>
        <input
          type='text'
          className='fz-input'
          placeholder='Type your answer here'
          value={inputAnswer}
          onChange={(e) => setInputAnswer(e.target.value)}
          style={{
            width: '100%',
            margin: '0 auto',
            marginTop: '20px',
            marginBottom: '20px',
            height: '75px',
            fontSize: 'var(--reg-font-size)',
          }}
        />
      </div>
      <FZButton
        style={{
          margin: '0 auto',
          marginTop: '20px',
          backgroundColor: inputAnswer === '' ? 'gray' : 'auto',
        }}
        disabled={inputAnswer === ''}
        onClick={checkCorrectAnswer}
        hoverBackgroundColor='white'
        hoverColor='var(--jfz-blue)'
        backgroundColor='var(--jfz-blue)'
        disabledBackgroundColor='gray'
        borderColor='var(--jfz-blue)'
      >
        Check
      </FZButton>
    </div>
  )
}

const BreakdownAnswer = ({
  viewingNum, isMobile, quizPerformance, shuffledItems,
  isMultichoice, correctColor, incorrectColor, multichoiceStyleOptions
}) => {
  if (viewingNum === shuffledItems.length) return null
  const [openCommentary, setOpenCommentary] = useState(-1)
  const si = shuffledItems[viewingNum]
  const isImages = si.activity_type === 'images'
  const isInput = si.activity_type === 'input'

  return (
    <div style={{ textAlign: 'left', marginBottom: '50px' }} key={viewingNum}>
      <h3
        style={{
          fontWeight: 'bold',
          fontSize: isMobile ? 'var(--reg-font-size)' : 'var(--large-font-size)',
          marginBottom: '10px',
        }}
      >
        {`${viewingNum + 1}. `}
        <SoundRenderer text={si.question} />
      </h3>
      {isImages && (
        <div>
          <div className='mini-quiz flex-center' style={{ marginTop: '60px' }}>
            {si.other_answers.map((ao, idx) => {
              return (
                <div key={`answer-${viewingNum}-${idx}`} style={{
                  width: '20%',
                  margin: '10px',
                  height: '200px'
                }}>
                  <QuizOption
                    tabIndex='0'
                    // onClick={() => handleSetSelectedAnswers(idx)}
                    // onKeyUpCapture={(e) => e.key === 'Enter' && setSelectedAnswer(idx)}
                    // correct={multichoiceResults[idx] === true}
                    // wrong={multichoiceResults[idx] === false}
                    // missed={multichoiceResults[idx] === false}
                    disableHover
                    style={{
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                      padding: isMobile ? '13px 10px 10px' : '',
                      minHeight: isMobile ? '' : '65px',
                      height: '200px',
                      width: '200px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      border: '3px solid ' + (si.image_correct_answers[idx] ? correctColor : incorrectColor),
                    }}
                  >
                    <Icon name='comment'
                      style={{ position: 'absolute', top: 0, left: '4px', zIndex: 1, cursor: 'pointer' }}
                      color='black'
                      onClick={() => setOpenCommentary(idx)}
                    />
                    {/* absolute positioned speech bubble above if openCommentary === idx */}
                    {openCommentary === idx && si.other_commentary[idx] && (
                      <div style={{
                        position: 'absolute',
                        top: '-65px',
                        left: '50%',
                        transform: 'translateX(-50%)',
                        backgroundColor: 'white',
                        padding: '10px',
                        borderRadius: '15px',
                        boxShadow: '0 0 5px 0 rgba(0,0,0,0.25)',
                        zIndex: 1,
                        width: '400px'
                      }}>
                        <p style={{ fontSize: 'var(--small-font-size)', margin: 0 }}>{si.other_commentary[idx]}</p>
                      </div>
                    )}
                    <QuizImage
                      src={ao}
                    />
                  </QuizOption>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {!isImages && quizPerformance[viewingNum].order.map((o, idy) => {
        const multichoiceResponse = quizPerformance[viewingNum].multichoiceResults[idy]
        const isCorrect = isMultichoice ? multichoiceResponse === 'correct' : (o === 0 || isInput)
        
        const singleChoiceStyles = {
          backgroundColor: isCorrect
            ? correctColor
            : idy === quizPerformance[viewingNum].chosen
              ? incorrectColor
              : 'white',
          borderColor: isCorrect
            ? correctColor
            : idy === quizPerformance[viewingNum].chosen
              ? incorrectColor
              : 'var(--blue-gray)',
          color: isCorrect
            ? 'var(--off-white)'
            : idy === quizPerformance[viewingNum].chosen
              ? 'var(--off-white)'
              : 'var(--blue-gray)',
        }
        
        const quizTypeStyles = isMultichoice
          ? multichoiceStyleOptions[multichoiceResponse]
          : singleChoiceStyles

        const commentary_styles = {
          marginTop: '25px',
          border: '1px solid transparent',
          padding: isCorrect ? '20px 24px 16px' : '16px 24px 20px',
          borderRadius: '15px',
          ...quizTypeStyles
        }

        // const matchFirstEnglishWord = /^[a-z0-9]\s*/i
        if (typeof o !== 'number') return

        if (isMultichoice || isInput) {
          const textOptions = o <= 0
            ? o === 0
              ? [si.correct_answer, si.correct_commentary]
              : [
                si.multichoice_correct_answers[Math.abs(o) - 1],
                si.multichoice_correct_commentary[Math.abs(o) - 1]
              ]
            : [si.other_answers[o - 1], si.other_commentary[o - 1]]
          return (
            <div key={`${viewingNum}-${idy}`} style={commentary_styles}>
              <p
                style={{
                  fontSize: isMobile ? 'var(--reg-font-size)' : '',
                  padding: 0,
                  margin: 0,
                }}
              >
                <SoundRenderer text={textOptions[0]} inverted />
              </p>
              {textOptions[1] && (
                <div
                  style={{
                    borderTop: `1px solid ${
                      multichoiceResponse === 'missed'
                        ? 'var(--blue-gray)'
                        : multichoiceResponse
                          ? 'white'
                          : '#dddddd'
                    }`,
                    marginTop: '10px',
                    // paddingLeft: isJapanese(si.correct_answer) ? '25px' : '',
                    paddingLeft: 0,
                    paddingTop: '15px',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                    }}
                  >
                    <SoundRenderer text={textOptions[1]} inverted />
                  </p>
                </div>
              )}
            </div>
          )
        }
        if (isCorrect && si.correct_answer) {
          // correct answer was chosen
          return (
            <div key={`${viewingNum}-${idy}`} style={commentary_styles}>
              <p
                style={{
                  // marginLeft: isJapanese(typeof si.correct_answer === 'string'
                  // ? si.correct_answer
                  // : si.correct_answer[0].text)
                  //   ? (si?.correct_commentary ? '25px' : '')
                  //   : '',
                  fontSize: isMobile ? 'var(--reg-font-size)' : '',
                  padding: 0,
                  margin: 0,
                }}
              >
                <SoundRenderer text={si.correct_answer} inverted />
              </p>
              {si?.correct_commentary && (
                <div
                  style={{
                    color: isCorrect
                      ? 'var(--off-white)'
                      : idy === quizPerformance[viewingNum].chosen
                        ? 'var(--off-white)'
                        : 'var(--blue-gray)',
                    borderTop: '1px solid white',
                    marginTop: '10px',
                    // paddingLeft: isJapanese(si.correct_answer) ? '25px' : '',
                    paddingLeft: 0,
                    paddingTop: '15px',
                  }}
                >
                  <p
                    style={{
                      padding: 0,
                      margin: 0,
                      fontSize: isMobile ? 'var(--reg-font-size)' : '',
                    }}
                  >
                    <SoundRenderer text={si.correct_commentary} inverted />
                  </p>
                </div>
              )}
            </div>
          )
        }
        // incorrect answer was chosen
        if (!si.other_answers[o - 1]) return
        return (
          <div key={`${viewingNum}-${idy}`} style={commentary_styles}>
            <p
              style={{
                // marginLeft: isJapanese(si.other_answers[o - 1])
                //  ? (si?.correct_commentary ? '25px' : '')
                //  : '',
                // marginLeft: matchFirstEnglishWord.test(si.other_answers[o - 1])
                //  ? (si?.correct_commentary ? '25px' : '')
                //  : '',
                fontSize: isMobile ? 'var(--reg-font-size)' : '',
                padding: 0,
                margin: 0,
              }}
            >
              <SoundRenderer text={si.other_answers[o - 1]} />
            </p>
            {si?.other_commentary[o - 1] && (
              <div
                style={{
                  color: isCorrect
                    ? 'var(--off-white)'
                    : idy === quizPerformance[viewingNum].chosen
                      ? 'var(--off-white)'
                      : 'var(--blue-gray)',
                  borderTop: '1px solid transparent',
                  borderColor:
                    idy === quizPerformance[viewingNum].chosen ? 'var(--off-white)' : '#ddd',
                  marginTop: '10px',
                  paddingLeft: 0,
                  // paddingLeft: isJapanese(si.other_answers[o - 1]) ? '25px' : '',
                  paddingTop: '15px',
                  fontSize: isMobile ? 'var(--reg-font-size)' : '',
                }}
              >
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: isMobile ? 'var(--reg-font-size)' : '',
                  }}
                >
                  <SoundRenderer text={si.other_commentary[o - 1]} />
                </p>
              </div>
            )}
          </div>
        )
      })}
      {/* move to previous and next question buttons */}
    </div>
  )
}

const QuizPerformance = (props) => {
  const {
    quizGrade, correct, quizLength, shuffledItems, quizPerformance, embedded, restartQuiz, onGoBack
  } = props
  const [viewingNum, setViewingNum] = useState(0)
  const isMobile = useMobile()

  const correctColor = '#86ac71' // green
  const incorrectColor = '#ff8c8c' // red
  const missedColor = '#f0e5a1' // yellow

  const multichoiceStyleOptions = {
    correct: {
      backgroundColor: correctColor,
      borderColor: correctColor,
      color: 'var(--off-white)'
    },
    wrong: {
      backgroundColor: incorrectColor,
      borderColor: incorrectColor,
      color: 'var(--off-white)'
    },
    missed: {
      backgroundColor: missedColor,
      borderColor: missedColor,
      color: 'var(--blue-gray)'
    },
    '': {
      backgroundColor: 'white',
      borderColor: 'var(--blue-gray)',
      color: 'var(--blue-gray)'
    }
  }

  const isMultichoice = quizPerformance[viewingNum]?.multichoiceResults?.length

  // eslint-disable-next-line
  // const nonEng = /[^\x00-\x7F’]+/

  //   const isJapanese = (content) => {
  //     if (typeof content === 'string') {
  //       // console.log(content)
  //       return nonEng.test(content)
  //     } else {
  //       // console.log(content[0].text)
  //       return nonEng.test(content[0].text)
  //     }
  //   }

  return (
    <div style={{ marginTop: '20px', padding: '0 50px', color: 'var(--blue-gray)', minHeight: '505px' }}>
      <p style={{ textAlign: 'center' }}>Let&apos;s break down this lesson quiz.</p>
      <div style={{ margin: '0 45% 20px' }}>
        <hr />
      </div>
      {embedded ? (
        <BreakdownAnswer
          key={`bd-answer-${viewingNum}`}
          shuffledItems={shuffledItems}
          viewingNum={viewingNum}
          isMobile={isMobile}
          quizPerformance={quizPerformance}
          isMultichoice={isMultichoice}
          correctColor={correctColor}
          incorrectColor={incorrectColor}
          multichoiceStyleOptions={multichoiceStyleOptions}
        />
      ) : (
        shuffledItems.map((si, idx) => {
          return (
            <BreakdownAnswer
              key={`bd-answer-${idx}`}
              shuffledItems={shuffledItems}
              viewingNum={idx}
              isMobile={isMobile}
              quizPerformance={quizPerformance}
              isMultichoice={isMultichoice}
              correctColor={correctColor}
              incorrectColor={incorrectColor}
              multichoiceStyleOptions={multichoiceStyleOptions}
            />
          )
        })
      )}
      {(viewingNum === shuffledItems.length || !embedded) && (
        <>
          <div
            style={{
              width: '30%',
              margin: '50px auto 0',
              objectFit: 'contain',
            }}
          >
            {quizGrade > 0.99 ? (
              <Image src={tomoko} alt='Tomoko cheering' />
            ) : (
              <Image src={mari} style={{ width: '70%', margin: '0 auto' }} alt='Mari' />
            )}
          </div>
          <h2 style={{ textAlign: 'center', color: 'var(--blue-gray)' }}>
            {quizGrade > 0.7 ? 'Well done!' : 'Great Attempt!'}
          </h2>
          {quizGrade < 1 && (
            <p style={{ width: isMobile ? '100%' : '50%', margin: '0 auto', fontSize: '1.2rem' }}>
              You answered {correct} out of the {quizLength} questions on this quiz correctly. Feel free
              to come back in order to strive for an amazing score!
            </p>
          )}
        </>
      )}
      {embedded && (
        <button
          onClick={() => {
            if (viewingNum > 0) {
              setViewingNum(viewingNum - 1)
            } else {
              onGoBack()
            }
          }}
          style={{
            background: 'transparent',
            border: 'none',
            color: 'var(--blue-gray)',
            fontSize: 'var(--reg-font-size)',
            cursor: 'pointer',
            padding: '0',
            margin: '0',
            outline: 'none',
            position: 'absolute',
            top: '250px',
            left: '15px',
          }}
        >
          <Icon name='arrow left' />
        </button>
      )}
      {/* button to restart quiz from beginning */}
      {embedded && viewingNum === shuffledItems.length && (
        <div style={{ textAlign: 'center', margin: '50px auto 10px' }}>
          <button
            onClick={() => {
              setViewingNum(0)
              restartQuiz()
            }}
            style={{
              backgroundColor: 'var(--jfz-blue)',
              borderRadius: '10px',
              color: 'white',
              fontSize: 'var(--reg-font-size)',
              cursor: 'pointer',
              padding: '5px',
              marginLeft: 'auto',
              marginRight: 'auto',
              outline: 'none',
            }}
          >
            <span style={{ fontSize: 'var(--reg-font-size)' }}>Retake Quiz</span>
          </button>
        </div>
      )}
      {embedded && (
        <button
          onClick={() => {
            if (viewingNum < quizPerformance.length) {
              setViewingNum(viewingNum + 1)
            }
          }}
          style={{
            background: 'transparent',
            border: 'none',
            color: 'var(--blue-gray)',
            fontSize: 'var(--reg-font-size)',
            cursor: 'pointer',
            padding: '0',
            margin: '0',
            outline: 'none',
            position: 'absolute',
            top: '250px',
            right: '15px',
          }}
        >
          <Icon name='arrow right' />
          <br />
          <span style={{ fontSize: 'var(--reg-font-size)' }}>
            {viewingNum < quizPerformance.length - 1 ? `Q${viewingNum + 2}` : ''}
          </span>
        </button>
      )}
    </div>
  )
}

// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
const shuffle = (a) => {
  const arr = [...a]
  let currentIndex = arr.length,
    randomIndex

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1;

    // And swap it with the current element.
    // eslint-disable-next-line
    [arr[currentIndex], arr[randomIndex]] = [arr[randomIndex], arr[currentIndex]]
  }

  return arr
}

const QuizFinished = ({
  isMobile, quizGrade, correct, items, shuffledItems, quizPerformance, embedded, restartQuiz
}) => {
  const [viewQuizPerformance, setViewQuizPerformance] = useState(false)

  return (
    <QuizFinishedStyles isMobile={isMobile} quizGrade={quizGrade} embedded={embedded}>
      {!viewQuizPerformance && (
        <section className='image-container'>
          <img
            src={quizGrade >= 0.7 ? tomoko : tomokoPity}
            alt={quizGrade >= 0.7 ? 'Tomoko cheering' : 'Tomoko having pity'}
            className={quizGrade >= 0.7 ? 'chibi' : 'chibi pity'}
          />
          {quizGrade >= 0.7 && (
            <img
              src={quizGrade === 1 ? amazing : great}
              alt={quizGrade === 1 ? 'amazing!' : 'great!'}
              className='exclamation'
            />
          )}
          <div className='results'>
            <h4>You Answered</h4>
            <div className='num-right'>
              <h3 className='orange'>{' '}{correct}/{items.length}</h3>
              <img
                src={quizGrade === 1
                  ? stamp100
                  : quizGrade >= 0.7
                    ? stamp70
                    : stampIncomplete
                }
              />
            </div>
            <h4>...and scored</h4>
            <h2 className="orange">{Math.round(quizGrade * 100)}%</h2>
          </div>
        </section>)}
      {viewQuizPerformance ? (
        <QuizPerformance
          correct={correct}
          quizGrade={quizGrade}
          quizLength={items.length}
          shuffledItems={shuffledItems}
          quizPerformance={quizPerformance}
          embedded={embedded}
          restartQuiz={restartQuiz}
          onGoBack={() => setViewQuizPerformance(false)}
        />
      ) : (
        <ViewAnswers onClick={() => setViewQuizPerformance(true)}>
          View Answers
        </ViewAnswers>
      )}
    </QuizFinishedStyles>
  )
}

const MiniQuizWidget = (props) => {
  const [widget, setWidget] = useState(props.widget)
  const [embedded] = useState(props.widget?.embedded)
  const [items, setItems] = useState(props.widget?.items)
  const [shuffledItems, setShuffledItems] = useState(shuffle(props.widget?.items))
  const [answerOrder, setAnswerOrder] = useState([0, 1, 2, 3])
  const [quizPerformance, setQuizPerformance] = useState([])
  const [viewQuizPerformance, setViewQuizPerformance] = useState(false)

  const [selectedAnswer, setSelectedAnswer] = useState(-1)
  const [selectedAnswers, setSelectedAnswers] = useState([]) // multichoice question
  const [currentQuestion, setCurrentQuestion] = useState(0)
  const [correct, setCorrect] = useState(0)
  const [incorrect, setIncorrect] = useState(0)
  const [correctChosen, setCorrectChosen] = useState(false)
  const [wrongChosen, setWrongChosen] = useState(-1)
  const [canInteract, setCanInteract] = useState(true)
  const [multichoiceResults, setMultichoiceResults] = useState([])

  const [lesson, setLesson] = useState(props.lesson)
  const [quizFinished, setQuizFinished] = useState(false)
  const [quizGrade, setQuizGrade] = useState(0)

  const [confettiActive, setConfettiActive] = useState(false)
  const [badConfettiActive, setBadConfettiActive] = useState(false)
  const [muteQuizSounds, setMuteQuizSounds] = useState(false)

  const [autoSolving, setAutoSolving] = useState(false)

  const isMobile = useMobile()
  const location = useLocation()

  const [isStaffRole] = useStaffRoles()
  const isEditView = useSelector((state) => state.navigator.admin_view)
  const teacherAdmin = props.roles?.includes('teacher') || props.roles?.includes('admin')

  const isMultichoice = shuffledItems[currentQuestion]?.multichoice_correct_answers?.length > 0
  const isImages = shuffledItems[currentQuestion]?.activity_type === 'images'
  const isInput = shuffledItems[currentQuestion]?.activity_type === 'input'

  const primaryColor = getCourseInfo(getTitle(props.course)).alt_color_7 ?? '#60a2ef'

  const setQuizCompleted = useSublessonStore((state) => state.setQuizCompleted)

  const restartQuiz = () => {
    setQuizFinished(false)
    setQuizPerformance([])
    setViewQuizPerformance(false)
    setCorrect(0)
    setIncorrect(0)
    setCorrectChosen(false)
    setWrongChosen(-1)
    setCanInteract(true)
    setMultichoiceResults([])
    setConfettiActive(false)
    setBadConfettiActive(false)
    setMuteQuizSounds(false)
    setAutoSolving(false)
    setAnswerOrder([0, 1, 2, 3])
    setShuffledItems(shuffle(items))
    setCurrentQuestion(0)
    setSelectedAnswer(-1)
    setSelectedAnswers([])
  }

  const confetti_config = {
    angle: 90,
    spread: 210,
    startVelocity: 35,
    elementCount: 100,
    dragFriction: 0.15,
    duration: 3000,
    stagger: 10,
    width: '15px',
    height: '10px',
    perspective: '1000px',
    colors: ['#88b4e0', '#ef6960', '#74be72', '#b772b3', '#d76c8d'], // colors of JFZ 1 - 5
  }
  
  // useEffect(() => {
  //   if (!props.widget?.items) return
  //   console.log('shuffling items')
  //   setShuffledItems(shuffle(props.widget.items))
  // }, [props.widget])

  useEffect(() => {
    // console.log('answer order and multichoice results')
    let correctAnswers, wrongAnswers, shuffledAnswers
    // console.log(shuffledItems)
    // console.log(shuffledItems[currentQuestion])
    if (!isImages) {
      correctAnswers = shuffledItems[currentQuestion]?.multichoice_correct_answers?.map(
        (_, idx) => -(idx + 1)
      ) ?? []
      wrongAnswers = shuffledItems[currentQuestion]?.other_answers?.map((_, idx) => idx + 1)
        ?? []
      shuffledAnswers = shuffle([0, ...correctAnswers, ...wrongAnswers])
    } else {
      // console.log(shuffledItems[currentQuestion]?.image_correct_answers)
      shuffledAnswers = shuffle(shuffledItems[currentQuestion]?.image_correct_answers?.map(
        (a, idx) => a ? -(idx + 1) : (idx + 1)
      ))
      // console.log(shuffledAnswers)
    }
    
    setAnswerOrder(shuffledAnswers)
    setMultichoiceResults([])
  }, [currentQuestion])

  const playOneShot = (type) => {
    if (muteQuizSounds) return
    const correctAnswer = new Audio(shinyAchieve)
    correctAnswer.volume = 0.2

    const incorrectAnswer = new Audio(wrongAnswer)
    incorrectAnswer.volume = 0.2

    const quizPassed = new Audio(dingPop)
    quizPassed.volume = 0.2

    const quizFailed = new Audio(gameOver)
    quizFailed.volume = 0.2

    let audioElement

    switch (type) {
      case 'correct':
        audioElement = shinyAchieve // correct answer was chosen
        break
      case 'incorrect':
        audioElement = wrongAnswer // incorrect answer was chosen
        break
      case 'quiz pass':
        audioElement = dingPop // quiz passed
        break
      case 'quiz fail':
        audioElement = gameOver // quiz failed
        break
      default:
    }

    if (audioElement) {
      const ae = new Audio(audioElement)
      ae.volume *= 0.4
      ae.play()
    }
  }

  const checkCorrectAnswer = () => {
    console.log(canInteract)
    if (!canInteract) return
    setCanInteract(false)

    let results = []

    if (isInput) {
      setCorrectChosen(true)
      setCorrect(correct + 1)
      playOneShot('correct')
    } else if (isMultichoice || isImages) {
      // console.log(answerOrder)
      results = answerOrder.map((answer, idx) => {
        if (selectedAnswers[idx]) {
          return answer <= 0 ? 'correct' : 'wrong'
        }
        return answer <= 0 ? 'missed' : ''
      })
      // console.log(results)
      const answeredCorrectly = results.reduce((bool, result) => {
        if (result === 'wrong' || result === 'missed' || result === false) return false
        return bool
      }, true)
      // console.log(answeredCorrectly)

      setMultichoiceResults(results)
      if (answeredCorrectly) {
        setCorrect(correct + 1)
        playOneShot('correct')
      } else {
        playOneShot('incorrect')
        setIncorrect(incorrect + 1)
      }
    } else if (answerOrder[selectedAnswer] === 0) {
      setCorrectChosen(answerOrder[selectedAnswer] === 0)
      setCorrect(correct + 1)
      playOneShot('correct')
    } else {
      setWrongChosen(selectedAnswer)
      playOneShot('incorrect')
      setIncorrect(incorrect + 1)
    }

    setQuizPerformance(
      [...quizPerformance,
        { order: answerOrder, chosen: selectedAnswer, multichoiceResults: results }
      ]
    )

    // increment question if not the last
    if (currentQuestion + 1 < items.length) {
      setTimeout(() => {
        setCurrentQuestion(currentQuestion + 1)
        setCanInteract(true)
        // setAnswerOrder(shuffle([0, 1, 2, 3]))
        setCorrectChosen(false)
        setWrongChosen(-1)
      }, 1000)
    } else {
      setTimeout(() => {
        setQuizFinished(true)
      }, 1500)
    }

    setSelectedAnswer(-1)
    setSelectedAnswers([])
  }

  const handleConfetti = (status) => {
    if (status === 'fail') {
      setBadConfettiActive(true)
      playOneShot('quiz fail')
      setTimeout(() => {
        setBadConfettiActive(false)
      }, 3000)
    }
    if (status === 'pass') {
      setConfettiActive(true)
      playOneShot('quiz pass')
      setTimeout(() => {
        setConfettiActive(false)
      }, 3000)
    }
  }

  const handleQuizFinished = useCallback(() => {
    const statusPerc = correct / items.length
    const status = statusPerc >= 0.7 ? 'pass' : 'fail'
    setQuizGrade(statusPerc)

    handleConfetti(status)

    setQuizCompleted(true)

    if (isEditView || !lesson) {
      console.info('Not saving results, reason:\n', `isEditView: ${isEditView}\n`, `lesson: ${lesson}\n`)
      return
    }

    // no need to post progress if embedded
    if (embedded) return

    // console.log('submitting quiz for lesson', lesson)

    axios
      .post(`/progress/quiz/${lesson}`, {
        numRight: correct,
        status,
        finalGrade: statusPerc,
        location: location.pathname.slice(9),
      })
      // .then(() => console.log('Quiz results submitted'))
      .catch((err) => console.error(err))
  }, [correct, items.length, lesson, location.pathname, embedded, isEditView, setQuizCompleted])

  useEffect(() => {
    if (!quizFinished) return

    handleQuizFinished()
  }, [correct, quizFinished])

  const finishQuiz = () => {
    setCorrect(items.length)
    setQuizFinished(true)
    setQuizPerformance(
      shuffledItems.map((item) => {
        const _multichoiceResults = []
        const _order = [0]

        if (item.multichoice_correct_answers?.length) {
          _multichoiceResults.push('correct')

          item.multichoice_correct_answers.forEach((_, idx) => {
            _order.push(-(idx + 1))
            _multichoiceResults.push('correct')
          })
          item.other_answers.forEach(() => _multichoiceResults.push(''))
        }
        
        item.other_answers.forEach((_, idx) => _order.push(idx + 1))

        return { order: _order, chosen: 0, multichoiceResults: _multichoiceResults }
      })
    )
    setViewQuizPerformance(true)
    setQuizCompleted(true)
  }

  const instantQuiz = () => {
    if (answerOrder[0] === 0) {
      setCorrectChosen(answerOrder[0] === 0)
      setCorrect(correct + 1)
    } else {
      setWrongChosen(0)
      setIncorrect(incorrect + 1)
    }

    setQuizPerformance(
      [...quizPerformance, { order: answerOrder, chosen: 0, multichoiceResults: [] }]
    )

    // increment question if not the last
    if (currentQuestion + 1 < items.length) {
      setCurrentQuestion(currentQuestion + 1)
      setCanInteract(true)
      // setAnswerOrder(shuffle([0, 1, 2, 3]))
      setCorrectChosen(false)
      setWrongChosen(-1)
    } else {
      setQuizFinished(true)
      setQuizCompleted(true)
    }
  }

  const TeacherAutoSolver = () => {
    if (!isStaffRole) return <></>
    return (
      <div style={{ width: '100%', margin: '10px auto 0', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        {/* <button
          className='fz-button'
          onClick={() => handleConfetti('pass')}
          disabled={confettiActive || badConfettiActive}
          >
          DEV: Good Confetti
        </button>
        <button
          className='fz-button'
          onClick={() => handleConfetti('fail')}
          disabled={confettiActive || badConfettiActive}
          >
          DEV: Bad Confetti
        </button> */}
        <FZButton
          onClick={() => instantQuiz()}
          disabled={autoSolving || quizFinished}
        >
          DEV: {autoSolving ? 'Iterating...' : 'AutoQuiz'}
        </FZButton>
        <FZButton
          onClick={finishQuiz}
        >
          DEV: Finish Quiz
        </FZButton>
      </div>
    )
  }

  const QuizStatsNumberBox = ({ number, text }) => {
    return (
      <div className='flex-center' style={{ flexDirection: 'column' }}>
        <Header
          as='h3'
          style={{
            fontSize: isMobile ? '1rem' : '1.5rem',
            color: text === 'correct' ? '#5ebd5e' : text === 'incorrect' ? '#f05a5a' : 'var(--blue-gray)',
            margin: 0,
            padding: 0,
          }}
        >
          {number || 0}
        </Header>
        <p
          style={{
            color: 'var(--blue-gray)',
            textTransform: 'capitalize',
            fontSize: isMobile ? '1rem' : '1.5rem',
          }}
        >
          {text}
        </p>
      </div>
    )
  }

  const QuizStats = () => {
    const [stats] = useState([
      {
        number: currentQuestion,
        text: 'answered',
      },
      {
        number: correct,
        text: 'correct',
      },
      {
        number: incorrect,
        text: 'incorrect',
      },
      {
        number: items.length - currentQuestion,
        text: 'remaining',
      },
    ])

    return (
      <div
        style={{
          border: '1px solid #3C4043',
          fontFamily: 'Poppins',
          borderRadius: '20px',
          marginTop: '32px',
          padding: '8px',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        {stats.map((stat) => (
          <QuizStatsNumberBox key={stat.text} text={stat.text} number={stat.number} />
        ))}
      </div>
    )
  }

  useEffect(() => {
    setQuizCompleted(false)
  }, [])

  const activitySubtypes = ['mini-quiz', 'kana-typer', 'conjugation']

  // console.log(shuffledItems[currentQuestion]?.question)
  const cq = shuffledItems[currentQuestion]?.question
  const cqk = typeof cq === 'string' ? cq : cq?.map((q) => q.text).join('-')

  if (activitySubtypes.includes(widget.subtype)) {
    return (
      <WidgetWrapper widget={{ widgetType: 'MiniQuiz', widgetId: widget._id }}>
        <Activity widget={widget} course={props.course} />
      </WidgetWrapper>
    )
  }

  return (
    <WidgetWrapper
      data-t={`widget:miniquiz-id:${widget._id}`}
      widget={{ widgetType: 'MiniQuiz', widgetId: widget._id }}
    >
      <div
        className='mini-quiz flex-center'
        style={{
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        {teacherAdmin && <TeacherAutoSolver />}
        {items && shuffledItems.length > 0 && (
          <>
            <Icon
              style={{
                cursor: 'pointer',
                position: 'relative',
                alignSelf: 'flex-end',
                top: isMobile ? '-10px' : '25px',
                right: '10px',
              }}
              onClick={() => setMuteQuizSounds(!muteQuizSounds)}
              color='grey'
              name={muteQuizSounds ? 'volume off' : 'volume up'}
            />
            <MiniQuizDiv isMobile={isMobile} embedded={embedded} quizFinished={quizFinished}>
              {teacherAdmin && (
                <p style={{ fontSize: '12px', color: 'orange' }}>TEACHER / ADMIN MODE</p>
              )}
              {quizFinished ? (
                <></>
              ) : (
                <>
                  <QuizHeader>
                    <ActivityType primaryColor={primaryColor}>Quiz</ActivityType>
                    {/* This will be the commentary */}
                    <div></div>
                    {/* <Tomoko src={tomokoIdle} alt='Tomoko observing' isMobile={isMobile} /> */}
                    <Question isMobile={isMobile}>
                      <QuestionNumber isMobile={isMobile}>{currentQuestion + 1}</QuestionNumber>
                      <SoundRenderer
                        key={`question-${cqk}`}
                        text={shuffledItems[currentQuestion].question}
                        bold
                        fontFamily='Poppins'
                        fontSize={isMobile ? '1rem' : '1.25rem'}
                      />
                    </Question>
                  </QuizHeader>
                  <GradientProgess value={(currentQuestion / items.length) * 100} size='xl' radius='xl' />
                  <Space h='xl' />
                  {shuffledItems[currentQuestion].question_activity_type === 'image' && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: '20px 0',
                      }}
                    >
                      <div
                        style={{
                          height: '200px',
                          width: '200px'
                        }}
                      >
                        <QuizImage
                          key={`${currentQuestion}-image`}
                          src={shuffledItems[currentQuestion].question_image}
                        />
                      </div>
                    </div>
                  )}
                  {shuffledItems[currentQuestion].question_activity_type === 'sound' && (
                    <SoundRenderer
                      key={`question-${cqk}-soundq`}
                      text={shuffledItems[currentQuestion].question_subdata}
                      soundQuestion
                      styles={{ fontFamily: 'Milliard Bold' }}
                    />
                  )}
                </>
              )}
              {quizFinished ? (
                <QuizFinished
                  isMobile={isMobile}
                  quizGrade={quizGrade}
                  correct={correct}
                  items={items}
                  shuffledItems={shuffledItems}
                  quizPerformance={quizPerformance}
                  embedded={embedded}
                  restartQuiz={restartQuiz}
                />
              ) : (
                <>
                  <Quiz
                    checkCorrectAnswer={checkCorrectAnswer}
                    currentQuestion={currentQuestion}
                    course={props.course}
                    correctChosen={correctChosen}
                    selectedAnswer={selectedAnswer}
                    setSelectedAnswer={setSelectedAnswer}
                    shuffledItems={shuffledItems}
                    answerOrder={answerOrder}
                    wrongChosen={wrongChosen}
                    widgetId={widget._id}
                    isMultichoice={isMultichoice}
                    selectedAnswers={selectedAnswers} // multichoice question
                    setSelectedAnswers={setSelectedAnswers} // multichoice question
                    multichoiceResults={multichoiceResults}
                  />
                  <QuizStats />
                </>
              )}
            </MiniQuizDiv>
          </>
        )}
        <div style={{ position: 'absolute', top: '15%', left: '10%' }}>
          <Confetti active={confettiActive} config={confetti_config} />
          <Confetti
            active={badConfettiActive}
            config={{
              ...confetti_config,
              startVelocity: Math.ceil(Math.random() * 3),
              elementCount: Math.ceil(Math.random() * 6),
              duration: 2000,
            }}
          />
        </div>
        <div style={{ position: 'absolute', top: '15%', right: '10%' }}>
          <Confetti active={confettiActive} config={confetti_config} />
          <Confetti
            active={badConfettiActive}
            config={{
              ...confetti_config,
              startVelocity: 1 + Math.ceil(Math.random() * 3),
              elementCount: Math.ceil(Math.random() * 5),
              duration: 2000,
            }}
          />
        </div>
      </div>
    </WidgetWrapper>
  )
}

const mapStateToProps = (state) => {
  return { roles: state.auth.roles }
}

export default connect(mapStateToProps)(MiniQuizWidget)
