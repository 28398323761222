import React, { useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'
import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { KANJI } from '../toolkit.constants'
import { KanjiExamples, KanjiWidget } from 'src/types'

import CourseCreationToolkit from '../CourseCreationToolkit'
import {
  ListInputDivider,
  ToolFooter,
  ToolHeader,
  ToolInput,
  ToolOptions,
  ToolSubheader,
} from '../parts'

import ToolForm from '../parts/ToolForm.part'

import { KanjiBox, KanjiExampleBox, KanjiInfo, KanjiReadings, ThreeInputRow } from '../styles/KanjiTool.styles'
import { EmptyPadding, ListSectionDivider, PairedField, ToolBody } from '../styles'
import { PairedFieldsWithIcon } from '../styles/UsageTool.styles'
import { DeleteConversationLineButton } from '../styles/ConversationTool.styles'
import { Icon } from 'semantic-ui-react'
import useWidgetSaved from '../hooks/useWidgetSaved'

interface KanjiFromRapidAPI {
  character: string
  meaning?: string
  onyomi?: string
  kunyomi?: string
  strokes?: number
  radical?: string
}

const KanjiTool: React.FC<ToolProps<KanjiWidget>> = ({
  course,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [kanjiResponse, setKanjiResponse] = useState<KanjiFromRapidAPI>()
  const [rapidAPIError, setRapidAPIError] = useState<string>()
  const [storedExamples, setStoredExamples] = useState<KanjiExamples[]>([])
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<KanjiWidget>(id)
  const hookFormMethods = useForm()
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()
  const kanjiRef = useRef<HTMLDivElement>(null)
  
  const handleSaveWidget = () => {
    if (!hookFormMethods.formState.isValid) return
    const values = hookFormMethods.getValues()
    editWidget({ ...values, course })
    setSaved(true)
    fetchWidget()
    fetchSubLesson()
  }

  const commitTemporaryChanges = (examples: KanjiExamples[]) => {
    hookFormMethods.setValue('examples', examples)
    setStoredExamples(examples)
  }

  // pass widget info up to wrapper element
  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })

      const examples = widget?.examples?.length
        ? widget?.examples
        : [{ japanese: '', english: '' }] as KanjiExamples[]

      setStoredExamples(examples)
      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const fetchKanjiData = async (_kanji: string) => {
    // Free Kanji data via KanjiAlive's RapidAPI to autofill busy work fields
    const response = await fetch(`https://kanjialive-api.p.rapidapi.com/api/public/kanji/${_kanji}`, {
      method: 'GET',
      headers: {
        'X-RapidAPI-Host': 'kanjialive-api.p.rapidapi.com',
        'X-RapidAPI-Key': 'e26ce70ce5msh977d114e9dc66c5p15cdfajsnb4e28f4e2f4a',
      }
    })

    const data = await response.json()
    let kanji_data: KanjiFromRapidAPI = { character: _kanji }

    if (data?.error) {
      const error = data.error === 'No kanji found.' ? 'Kanji data not found.' : data.error
      setRapidAPIError(error)
      return kanji_data
    }
    if (data?.kanji) {
      kanji_data = {
        ...kanji_data,
        meaning: data.kanji.meaning.english,
        onyomi: data.kanji.onyomi.katakana || 'none',
        kunyomi: data.kanji.kunyomi.hiragana || 'none',
        strokes: data.kanji.strokes.count,
      }
    }
    if (data?.radical) {
      kanji_data = {
        ...kanji_data,
        radical: data.radical.character,
      }
    }
    // KanjiAlive also provides 0~10 examples per kanji
    // but the parentheses for furigana are formatted incorrectly for our usage
    // (uses Japanese parens and has okurigana still attached)

    return kanji_data
  }

  const handleKanjiInputBlur = async (_kanji: string) => {
    if (!_kanji) {
      setKanjiResponse(undefined)
      return
    }

    if (_kanji === kanjiResponse?.character) return

    const kanji_data = await fetchKanjiData(_kanji)

    if (kanji_data) {
      setKanjiResponse(kanji_data)

      hookFormMethods.setValue('radical', kanji_data?.radical, { shouldDirty: true })
      hookFormMethods.setValue('strokes', kanji_data?.strokes)
      hookFormMethods.setValue('onyomi', kanji_data?.onyomi, { shouldDirty: true })
      hookFormMethods.setValue('kunyomi', kanji_data?.kunyomi, { shouldDirty: true })
      hookFormMethods.setValue('meaning', kanji_data?.meaning, { shouldDirty: true })
    }
  }

  const addExample = (idx: number) => {
    const _examples = hookFormMethods.getValues()['examples']
    _examples.splice(idx, 0, { japanese: '', english: '' })
    commitTemporaryChanges(_examples)
  }

  const removeExample = (idx: number) => {
    const _examples = hookFormMethods.getValues()['examples']
    _examples.splice(idx, 1)
    commitTemporaryChanges(_examples)
  }

  const kanjiTopFields = {
    onyomi: ['onyomi', 'Japanese origin reading'],
    kunyomi: ['kunyomi', 'Chinese origin reading'],
  }
  
  const kanjiBottomFields = {
    number: ['FZ! number', 'Kanji number in FZ! series'],
    radical: ['radical', 'Primary radical for the kanji'],
    strokes: ['strokes', 'Stroke count for the kanji'],
  }

  /*
   imports a kanji given the following representative example in the `kanji` string passed in:
   259	田
    5画	もう(す)
    シン

    申

    申(もう)す
    内(ない)申(しん)
    申(もうし)立(たて)人(にん)

    to say; to submit 	unofficial / confidential report	petitioner
    申(もう)込(こ)む
    申(もう)し出(で)
    申(しん)請(せい)

    to apply 	offer; approach, proposal	application; request 
          
    submit; offer; say	田 (field) + 﫽 (sword)
  */
  const importKanji = (kanji: string) => {
    const lines = kanji?.split('\n')?.map((line) => line.trim())?.filter((line) => line !== '')
    console.log('lines', lines)
    const [number, radical] = lines[0].split('\t')
    const [_strokes, kunyomi] = lines[1].split('\t')
    const strokes = _strokes.split('画')[0]

    const onyomi = lines[2]
    const character = lines[3]

    // 4 5 and 6 are jp examples, 7 is their english tab-separated
    // 8 9 and 10 are jp examples, 11 is their english tab-separated
    const engs = lines[7].split('\t')
    const engs2 = lines[11].split('\t')
    const examples = [
      { japanese: lines[4], english: engs[0] },
      { japanese: lines[5], english: engs[1] },
      { japanese: lines[6], english: engs[2] },
      { japanese: lines[8], english: engs2[0] },
      { japanese: lines[9], english: engs2[1] },
      { japanese: lines[10], english: engs2[2] },
    ]

    console.log('examples', examples)

    const meaning = lines[12].split('\t')[0]
    const structure = lines[12].split('\t')[1]

    // fill react hook form state with these values
    hookFormMethods.setValue('kanji', character, { shouldDirty: true })
    hookFormMethods.setValue('number', number, { shouldDirty: true })
    hookFormMethods.setValue('radical', radical, { shouldDirty: true })
    hookFormMethods.setValue('strokes', strokes, { shouldDirty: true })
    hookFormMethods.setValue('onyomi', onyomi, { shouldDirty: true })
    hookFormMethods.setValue('kunyomi', kunyomi, { shouldDirty: true })
    hookFormMethods.setValue('meaning', meaning, { shouldDirty: true })
    hookFormMethods.setValue('structure', structure, { shouldDirty: true })
    hookFormMethods.setValue('examples', examples, { shouldDirty: true })

    commitTemporaryChanges(examples)
  }

  // set up keyboard shortcut for ctrl-enter in kanji widget to paste kanji from clipboard
  // but only if the `kanji` field in the hook form is empty
  useEffect(() => {
    const handlePaste = (e: KeyboardEvent) => {
      // return if we're not hovered over our kanji ref div
      if (!kanjiRef.current?.contains(e.target as Node)) return
      if (e.ctrlKey && e.key === 'Enter') {
        navigator.clipboard.readText().then((text) => {
          if (hookFormMethods.getValues()['kanji'] === '') {
            importKanji(text)
          }
        })
      }
    }

    document.addEventListener('keydown', handlePaste)

    return () => {
      document.removeEventListener('keydown', handlePaste)
    }
  }, [hookFormMethods])

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={KANJI}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='structure-tool' ref={kanjiRef}>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={KANJI}
            widget_id={id}
            isGridCell={isGridCell}
          />
          <ToolOptions id={id} isGridCell={isGridCell} />
          <ToolSubheader>
            <KanjiBox>
              <label htmlFor='kanji' title='Kanji character to display'>Kanji</label>
              <input
                type='text'
                maxLength={1}
                // onBlurCapture={(e) => handleKanjiInputBlur(e.target.value)}
                title='Kanji character to display'
                {...hookFormMethods.register('kanji')}
              />
              {rapidAPIError && <p className='error'>{rapidAPIError}</p>}
            </KanjiBox>
          </ToolSubheader>
          <ToolBody>
            <KanjiInfo>
              <KanjiReadings>
                {Object.entries(kanjiTopFields)
                  .map(([registeredValue, [label, placeholder]], idx) => (
                    <div key={`${registeredValue}-${idx}`}>
                      <ToolInput
                        value={registeredValue}
                        inputLabel={label}
                        placeholder={placeholder}
                        title={placeholder}
                      />
                    </div>
                  ))
                }
              </KanjiReadings>
              <ThreeInputRow>
                {Object.entries(kanjiBottomFields)
                  .map(([registeredValue, [label, placeholder]], idx) => (
                    <div key={`${registeredValue}-${idx}`}>
                      <ToolInput
                        removeMargin
                        value={registeredValue}
                        inputLabel={label}
                        title={placeholder}
                      />
                    </div>
                  ))
                }
              </ThreeInputRow>
              <ToolInput
                value='meaning'
                placeholder='English meaning for the kanji'
                title='English meaning for the kanji'
              />
              <EmptyPadding />
              <ToolInput
                value='structure'
                placeholder='こうぞう (structure)'
                title='Parts that form the kanji'
              />
              <EmptyPadding />
            </KanjiInfo>
            <KanjiExampleBox>
              {storedExamples?.map((ex, idx) => (
                <>
                  <PairedFieldsWithIcon key={ex?._id ?? idx}>
                    <PairedField>
                      <div>
                        <ToolInput
                          value={`examples.${idx}.japanese`}
                          placeholder={'Japanese example text'}
                          title='Japanese example text'
                        />
                        <ToolInput
                          value={`examples.${idx}.english`}
                          placeholder='English translation'
                          title='English translation'
                        />
                      </div>
                    </PairedField>
                    <DeleteConversationLineButton
                      type='button'
                      title='Delete this conversation segment'
                      onClick={() => removeExample(idx)}
                    >
                      <Icon name='delete' size='large' />
                    </DeleteConversationLineButton>
                  </PairedFieldsWithIcon>
                  {storedExamples.length < 6 ? (
                    <ListInputDivider handleClicked={() => addExample(idx + 1)} />
                  ) : (
                    <ListSectionDivider />
                  )}
                </>
              ))}
            </KanjiExampleBox>
            {!storedExamples.length && <ListInputDivider handleClicked={() => addExample(0)} />}
          </ToolBody>
          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            saveDisabled={!hookFormMethods.formState.isValid}
            widget_id={id}
          >
            <ToolInput
              value='plays_sound'
              inputType='checkbox'
              placeholder='Play Sound'
              inputLabel='Play Sound'
              labelColor='white'
              labelPosition='after'
            />
          </ToolFooter>
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default KanjiTool
