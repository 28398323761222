import { Progress } from 'semantic-ui-react'
import styled, { css } from 'styled-components'
import getCourseInfo, { getTitle } from '../../../../util/courseInfo'

export const ProgressBar = styled(Progress).attrs((props) => ({
  color: getCourseInfo(getTitle(props.course)).color,
}))`
  background: #ddd !important;
  border-radius: 100px !important;

  .bar {
    background: ${(props) => props.color} !important;
    border-radius: 100px !important;
  }
`

const black = '#3C4043'

const hoverOptions = css`
  cursor: pointer;

  &:hover {
    border-color: ${props => props.correct ? props.correct_color : (props.wrong ? black : props.selection)} !important;
    background-color: ${props => props.correct ? props.correct_color : (props.wrong ? black : props.selection)} !important;
    color: ${props => props.correct || props.wrong ? 'white' : black};
  }
`

export const QuizOption = styled.div.attrs(props => ({
  color: getCourseInfo(getTitle(props.course)).color,
  selection: getCourseInfo(getTitle(props.course)).alt_color_4,
  correct_color: getCourseInfo(getTitle(props.course)).alt_color_7
}))`
  font-family: 'Poppins';
  font-size: 1.25rem;

  color: ${props => props.correct || props.wrong ? 'white' : black};
  background-color: ${props => props.isSelected ? props.selection : (props.correct ? props.correct_color : (props.wrong ? black : '#F4F6FC'))} !important;
  background-color: ${({ isSelected, missed }) => !isSelected && missed && '#fffedd'} !important;

  border: 1px solid transparent;
  border-radius: 20px;
  border-color: ${props => props.isSelected ? props.selection : (props.correct ? props.correct_color : (props.wrong ? black : props.correct_color))};

  padding: 20px 15px 15px;
  margin-bottom: 20px;
  
  ${({ disableHover }) => !disableHover && hoverOptions}

  transition: all 0.1s ease-in-out;
`
