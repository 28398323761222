import PlayField from '../UI/PlayField'
import React, { useState, useEffect, useCallback } from 'react'
import Button from '../UI/Button'
import GameButtons from '../UI/GameButtons'
import InfoCard from '../UI/InfoCard'
import { Icon } from 'semantic-ui-react'
import _ from 'lodash'

import correctSound from '../UI/correctSound.mp3'

/* eslint-disable react/prop-types */
const Game = ({ gameStarter, gameArray, setGameArray }) => {
  const [feedback, setFeedback] = useState('')
  const [displayIndex, setDisplayIndex] = useState(0)
  const [enteredText, setEnteredText] = useState('')
  const [correctCounter, setCorrectCounter] = useState(0)
  const [isCorrect, setIsCorrect] = useState(true)
  const [gameOver, setGameOver] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const [muted, setMuted] = useState(false)

  const inputChangeHandler = (event) => {
    setEnteredText(event.target.value)
  }

  const audioObject = new Audio(correctSound)
  audioObject.volume = 0.2

  const playSound = () => {
    if (!muted) audioObject.play()
  }

  const inputChar = enteredText.toLowerCase().trim()
  const currentKana = gameArray[displayIndex][0]
  const currentRomaji = gameArray[displayIndex][1]
  const currentAlt = gameArray[displayIndex][2]
    ? gameArray[displayIndex][2]
    : null

  const replayGame = (event) => {
    event.preventDefault()
    setGameArray(_.shuffle(gameArray))
    setGameOver(false)
    setDisplayIndex(0)
    setCorrectCounter(0)
  }

  const gameEnd = () => {
    setGameOver(true)
    setFeedback('')
  }

  const gameUpdater = useCallback(
    (event) => {
      if (event) {
        event.preventDefault()
      }
      const newIndex = displayIndex + 1
      const newCounter = correctCounter + 1
      if (
        inputChar === currentKana
        || inputChar === currentRomaji
        || inputChar === currentAlt
      ) {
        if (displayIndex + 1 < gameArray.length) {
          setDisplayIndex(newIndex)
          if (isCorrect) {
            playSound()
            setCorrectCounter(newCounter)
          }
        } else {
          if (isCorrect) {
            setCorrectCounter(newCounter)
          }
          gameEnd()
        }
        setIsCorrect(true)
        setFeedback('')
        setEnteredText('')
      } else if (inputChar !== currentRomaji || currentAlt) {
        setFeedback('X')
        setIsCorrect(false)
        setEnteredText('')
      }
      if (inputChar === '') {
        setFeedback(currentRomaji)
        setIsCorrect(false)
      }
      if (feedback === currentRomaji) {
        if (displayIndex + 1 < gameArray.length) {
          setDisplayIndex(newIndex)
        } else {
          if (isCorrect) {
            setCorrectCounter(newCounter)
          }
          gameEnd()
        }
        setEnteredText('')
        setFeedback('')
        setIsCorrect(true)
      }
    },
    [
      correctCounter,
      currentAlt,
      currentKana,
      currentRomaji,
      displayIndex,
      feedback,
      gameArray.length,
      inputChar,
      isCorrect,
    ]
  )

  useEffect(() => {
    const delayCheck = setTimeout(() => {
      if (inputChar.match(/[aiueo]/)) {
        gameUpdater()
      }
      if (inputChar.match(/[a-z]/) && currentRomaji === 'n') {
        gameUpdater()
      }
    }, 300)

    return () => {
      clearTimeout(delayCheck)
    }
  }, [inputChar, currentRomaji, gameUpdater])

  return (
    <>
      <form onSubmit={gameUpdater}>
        <PlayField>
          <span>
            <p className='correct'>{`Correct: ${correctCounter} `}</p>
            <p>{` Shown: ${displayIndex + 1}`}</p>
          </span>
          <Icon style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '7px',
            right: '0px'
          }}
            onClick={() => setMuted(!muted)}
            color='grey'
            name={muted ? 'volume off' : 'volume up'}
          />
          <Icon style={{
            cursor: 'pointer',
            position: 'absolute',
            top: '7px',
            left: '7px'
          }}
            onClick={() => setShowInfo(!showInfo)}
            color='grey'
            name='question circle outline'
          />
          {showInfo && (
            <InfoCard>
              <p>Typing the correct ro-maji will automatically advance the game.
                <br />
                <br />
                 If you get stumped, press <em> enter </em> while the text field is empty
                 and the ro-maji will be shown, then press <em> enter </em> again to continue.
                <br />
                <br />
                Happy typing!</p>
            </InfoCard>
          )}
          {!gameOver ? (
            <h2>{currentKana}</h2>
          ) : (
            <h5>
              {`You got ${correctCounter} correct out of ${displayIndex + 1}!`}
            </h5>
          )}
          <div className='padding'>
            {feedback !== '' && <h6 className='feedback'>{feedback}</h6>}
          </div>
          {!gameOver && (
            <input
              onFocus={() => setShowInfo(false)}
              style={{ textTransform: 'lowercase', textAlign: 'center' }}
              type='text'
              onChange={inputChangeHandler}
              value={enteredText}
              placeholder={displayIndex > 0 ? '' : 'press enter to show romaji'}
              autoFocus
            ></input>
          )}
        </PlayField>
      </form>
      <GameButtons>
        {gameOver && (
          <Button className='black' onClick={replayGame}>
            Replay
          </Button>
        )}
        <Button className='black' onClick={gameStarter}>
          Go Back
        </Button>
      </GameButtons>
    </>
  )
}

export default Game
