import { useCallback, useContext, useEffect, useState } from 'react'

import { AudioContext } from 'src/contexts/AudioContext'

const usePlayAll = () => {
  // whether play all is supported or not
  const [playAll, setPlayAll] = useState(false)
  const [playAllWidget, setPlayAllWidget] = useState('')

  // context containing a global audo object and a setter for its src
  const { audio, setAudioSrc, clickTriggered, setClickTriggered, paWidget, setPAWidget, changeAudioSrc, playAudio, pauseAudio } = useContext(AudioContext)

  // the current index in the entries object
  const [current, setCurrent] = useState(-1)

  // array containing all sound entries gathered from walking a widget tree
  // in the case of widgets with multiple sounds in one row etc., the index at that
  // point in the array will be an array of the sounds in that row
  const [entries, setEntries] = useState({})

  const [largest, setLargest] = useState(0)

  // callback triggered by SoundIcon when initialized with a sound src
  const register = (num, noIcon, src) => {
    // console.log(num, noIcon, src)
    setPlayAll(true)
    setEntries((es) => { return { ...es, [num]: src } })
    if (num > largest) setLargest(num)
  }

  useEffect(() => {
    setClickTriggered(false)
  }, [current])

  // watch for paWidget to change; if it does and it's not the same widget as this play all hook is watching,
  // end this hook's playing by setting current to -1
  useEffect(() => {
    if (paWidget && paWidget !== playAllWidget) {
      setCurrent(-1)
    }
  }, [paWidget])

  // watch for current and set the onended callback on the audio to play the next sound if it exists
  useEffect(() => {
    // console.log('current changed to ', current)
    try {
      pauseAudio()
    } catch (e) {
      console.log(e)
    }
    audio.currentTime = 0

    // -1 means it was terminated, so no need to check; instead, stop the sound
    if (current === -1) {
      return
    }

    // console.log('setting audio src to ', entries[current])
    changeAudioSrc(entries[current])

    // set onended of the audio context to play the next sound
    audio.onended = () => {
      if (entries[current + 1]) {
        // console.log('audio ended, incrementing current')
        setCurrent(current + 1)
      } else setCurrent(-1)
    }

    // console.log(audio)
    // in case we start the same sound over again, a load isn't necessary to play it
    if (audio.readyState >= 4) {
      playAudio()
    } else {
      audio.onloadeddata = () => setTimeout(() => playAudio(), 300)
    }
  }, [current])

  // if a click is detected as playing a separate sound, we need to stop the audio
  useEffect(() => {
    if (clickTriggered) {
      setCurrent(-1)
    }
  }, [clickTriggered])

  const play = useCallback((widget) => {
    // console.log('entries', entries)
    // console.log(widget)
    setPAWidget(widget)
    setPlayAllWidget(widget)

    // play being triggered while already playing should stop by going to -1
    if (current >= 0) return setCurrent(-1)

    // sometimes index 0 won't have a sound, so try index 1 instead
    if (!entries[0]) setCurrent(1)
    else setCurrent(0)

    // set autoplay on audio to true if we're on iOS
    if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
      audio.autoplay = true
    }
  }, [audio, entries, current])

  const stop = () => {
    setCurrent(-1)
  }

  const next = useCallback((num) => {
    // console.log('next', current, num)
    // next should not be firing when current or num are -1, only from the `play` function
    if (current === -1 || num === -1) {
      setCurrent(-1)
      return
    }

    // this accounts for gaps in the entries object (e.g., a row has no sounds in a list)
    let n = current + 1
    while (n <= largest && !entries[n]) {
      if (!entries[n]) {
        n += 1
      }
    }

    // we've gone past the end of the entries object, aka reached the end
    if (n > largest) {
      setCurrent(-1)
      return
    }

    setCurrent(n)
  }, [current, largest, entries])

  return { playAll, play, stop, current, register, next, entries }
}

export default usePlayAll
