import { useEffect, useState } from 'react'
import Bowser from 'bowser'

const useBrowser = () => {
  const [browser, setBrowser] = useState('')
  const [os, setOS] = useState('')
  const [platform, setPlatform] = useState('')

  useEffect(() => {
    const parser = Bowser.getParser(window.navigator.userAgent)
    const _browser = parser.getBrowser()
    const _os = parser.getOS()
    const _platform = parser.getPlatform()
    setBrowser(`${_browser.name} ${_browser.version}`)
    setOS(`${_os.name} ${_os.version}`)
    setPlatform(`${_platform.vendor} ${_platform.type}`)
  }, [])

  return [browser, os, platform]
}

export default useBrowser
