import styled from 'styled-components'

export default styled.div`
  p { font-weight: lighter; }

  > div {
    display: flex;
    justify-content: space-around;
    flex: 0 1 auto;

    button {
      color: #cbcbcb !important;
      background-color: var(--off-white);
      padding: 5px 15px;
      border-radius: 12px;
      width: 50px;
    }

    button.active {
      color: var(--jfz-blue) !important;
    }
  }
`
