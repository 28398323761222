import React from 'react'
import { Icon } from 'semantic-ui-react'
import { Link, useLocation } from 'react-router-dom'

import styled from 'styled-components'
import tw from 'twin.macro'

import useMobile from '../hooks/useMobile'

import ErrorReportModal from './modals/ErrorReportModal'
import FZExclamation from './FZExclamation'
import { useSelector } from 'react-redux'
import { RootState } from 'src/configureStore'

interface FooterProps {
  isAuthRoute: boolean
  isMobile: boolean
  prefersDarkMode: boolean
}

const Footer = styled.div<FooterProps>`
  ${tw`tw-relative tw-text-center tw-flex tw-flex-col tw-justify-center tw-items-center`}
  font-family: 'Jost';
  color: ${({ isAuthRoute, isMobile }) => isAuthRoute && isMobile ? 'var(--off-white)' : 'var(--jfz-blue)'};

  @media (min-width: 767px) {
    ${tw`tw-flex-row tw-justify-between tw-mx-4`}
  }

  /* @media (prefers-color-scheme: dark) {
    * { color: white; }

    >section>a {
      color: white;

      :hover {
        color: var(--jfz-blue);
        border-bottom: 1px solid var(--jfz-blue);
      }
    }
  } */
`

const FooterSection = styled.section`
  ${tw`tw-flex tw-justify-center tw-items-center tw-gap-2`}
`

const FooterSectionLegal = styled(FooterSection)<{ isAuthedMobile: boolean }>`
  ${tw`tw-flex-row`}
  color: ${({ isAuthedMobile }) => isAuthedMobile ? 'var(--off-white)' : 'var(--jfz-blue)'};

  a:hover {
    color: var(--blue-gray);
    /* border-bottom: 1px solid var(--blue-gray); */
  }

  @media (min-width: 767px) {
    ${tw`tw-flex-row tw-gap-2`}
  }
`

const FooterSectionSocial = styled(FooterSection)<{ noMarginRight: boolean }>`
  ${tw`tw-py-2 tw-gap-4 tw-mr-0`}

  i {
    ${tw`tw-cursor-pointer`}
    font-size: 1.2rem;
  }

  @media (min-width: 767px) {
    ${tw`tw-mr-12`}
    ${({ noMarginRight }) => noMarginRight && tw`tw-mr-0`}
  }
`

const ModalRevealButton = styled.div<{ isMobile: boolean }>`
  ${tw`tw-flex tw-items-center tw-justify-center tw-absolute tw-bottom-0 tw-cursor-pointer tw-z-10`}
  right: ${({ isMobile }) => (isMobile ? '0' : '-20px')};

  width: 50px;
  height: 50px;
  background-color: var(--sub-blue);
  border-top-left-radius: 5px;
  transition: all 0.2s ease-in;

  .exclamation {
    ${tw`tw-w-full tw-h-full`}
    object-fit: scale-down;
    min-width: 30px;
    min-height: 30px;
    max-width: 30px;
    max-height: 30px;
    transition: all 0.2s ease-in;
  }

  &:hover {
    width: 100px;
    height: 100px;
    border-top-left-radius: 50px;

    .exclamation {
      min-width: 50px;
      min-height: 50px;
      max-width: 50px;
      max-height: 50px;
      margin-left: 10px;
    }
  }
`

const FooterLinks = () => {
  const isMobile = useMobile()
  const location = useLocation()
  const prefersDarkMode = useSelector((state: RootState) => state.user?.darkMode) ?? false

  const date = new Date()
  const YYYY = date.getFullYear()

  const authRoutes = ['login', 'register']
  const routeName = location.pathname.split('/')[1]

  const isAuthRoute = authRoutes.includes(routeName)
  const showIssueReportButton = location.pathname.includes('bugs/public')

  const RevealButton = <ModalRevealButton
                         tabIndex={0}
                         isMobile={isMobile}
                        >
                         <FZExclamation color='blue' title='no title' />
                       </ModalRevealButton>

  return (
    <>
      <Footer isAuthRoute={isAuthRoute} isMobile={isMobile} prefersDarkMode={prefersDarkMode}>
        <FooterSection>
          <p>©1996-{YYYY} From Zero LLC</p>
        </FooterSection>

        <FooterSectionLegal isAuthedMobile={isAuthRoute && isMobile}>
          <Link to='/privacy'>Privacy Policy</Link>&nbsp;|&nbsp;
          <Link to='/tos'>Terms of Use</Link>&nbsp;|&nbsp;
          <Link to='/ask'>{isMobile ? 'AaT' : 'Ask-a-Teacher'}</Link>
        </FooterSectionLegal>

        <FooterSectionSocial noMarginRight={showIssueReportButton}>
          <Icon
            tabIndex={0}
            name='youtube'
            onClick={() => window.open('https://www.youtube.com/user/yesjapan', '_blank', 'noreferrer noopener')
            }
            onKeyUpCapture={(e) => e.key === 'Enter'
              && window.open('https://www.youtube.com/user/yesjapan', '_blank', 'noreferrer noopener')
            }
          />
          <Icon
            tabIndex={0}
            name='discord'
            onClick={() => window.open('http://learnfz.com/JFZDiscord', '_blank', 'noreferrer noopener')
            }
            onKeyUpCapture={(e) => e.key === 'Enter'
              && window.open('http://learnfz.com/JFZDiscord', '_blank', 'noreferrer noopener')
            }
          />
          <Icon
            tabIndex={0}
            name='amazon'
            onClick={() => window.open(
              'https://www.amazon.com/Japanese-Zero-Techniques-Students-Professionals/dp/0976998122',
              '_blank',
              'noreferrer noopener'
            )
            }
            onKeyUpCapture={(e) => e.key === 'Enter'
              && window.open(
                'https://www.amazon.com/Japanese-Zero-Techniques-Students-Professionals/dp/0976998122',
                '_blank',
                'noreferrer noopener'
              )
            }
          />
          <Icon
            tabIndex={0}
            name='twitter'
            onClick={() => window.open('https://twitter.com/yesjapan', '_blank', 'noreferrer noopener')
            }
            onKeyUpCapture={(e) => e.key === 'Enter'
              && window.open('https://twitter.com/yesjapan', '_blank', 'noreferrer noopener')
            }
          />
        </FooterSectionSocial>
        {!showIssueReportButton && <ErrorReportModal trigger={RevealButton} />}
      </Footer>
    </>
  )
}

export default FooterLinks
