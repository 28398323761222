import React, { useEffect, useState } from 'react'
import { Icon } from 'semantic-ui-react'

import { Obscured } from 'src/assets/styles/DefaultModalStyle'
import { ChangeCellWidget, Modal, OptionPortal, WidgetOption, WidgetOptions, WidgetSelectButton, WidgetSelectGrid, WidgetSelection } from '../../styles/NestedTool.styles'
import WidgetSample from '../WidgetSample'

const WIDGET_OPTIONS = [
  { key: 'ConversationWidget', value: 'conversation', text: 'Conversation Widget' },
  // { key: 'GridWidget', value: 'grid', text: 'Grid Widget' },
  { key: 'ImageWidget', value: 'image', text: 'Image Widget' },
  { key: 'KanjiWidget', value: 'kanji', text: 'Kanji Widget' },
  { key: 'ListWidget', value: 'list', text: 'List Widget' },
  { key: 'MiniQuizWidget', value: 'mini_quiz', text: 'Mini-Quiz Widget' },
  { key: 'QnAWidget', value: 'qna', text: 'Q&A Widget' },
  { key: 'StructureWidget', value: 'structure', text: 'Structure Widget' },
  { key: 'TableWidget', value: 'table', text: 'Table Widget' },
  { key: 'TextListWidget', value: 'text-list', text: 'Text List Widget' },
  { key: 'TextboxWidget', value: 'text', text: 'Textbox Widget' },
  { key: 'VideoWidget', value: 'video', text: 'Video Widget' },
  { key: 'UsageWidget', value: 'usage', text: 'Usage Widget' },
]

interface GridCellProps {
  assignWidget: (arg: { row: number, col: number, type: string }) => void
  columnIndex: number
  rowIndex: number
  forceModalOpen: boolean
  onModalClose: () => void
}

const EmptyGridCell: React.FC<GridCellProps> = ({
  assignWidget,
  columnIndex,
  rowIndex,
  forceModalOpen,
  onModalClose,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [viewingSample, setViewingSample] = useState<string>()
  const [selectedType, setSelectedType] = useState<string>()
  const [currentCursor, setCurrentCursor] = useState<number>(0)

  const assignWidgetToCell = (row, col, w_type) => {
    const type = WIDGET_OPTIONS.find((w) => w.key === w_type)?.value
    assignWidget({ row, col, type })
    setModalOpen(false)
    onModalClose()
  }

  const handleSampleClicked = (sample_id, sample_type, index) => {
    if (sample_id === viewingSample) {
      setViewingSample(undefined)
      setSelectedType(undefined)
      setCurrentCursor(undefined)
      return
    }
    setViewingSample(sample_id)
    setSelectedType(sample_type)
    setCurrentCursor(index)
  }

  const changeWidgetFromKeyboardShortcut = (cursor: number) => {
    if (cursor < 0 || cursor > WIDGET_OPTIONS.length - 1) return

    const { text, key } = WIDGET_OPTIONS[cursor]
    setViewingSample(text)
    setSelectedType(key)
    setCurrentCursor(() => cursor)
  }

  const handleKeyboardShortcut = (e: KeyboardEvent) => {
    if (e.key === 'Escape') setModalOpen(false)

    if (e.key === 'ArrowRight') {
      let next = currentCursor + 1
      if (currentCursor >= WIDGET_OPTIONS.length - 1) next = 0
      changeWidgetFromKeyboardShortcut(next)
    }

    if (e.key === 'ArrowLeft') {
      let next = currentCursor - 1
      if (currentCursor === 0) {
        next = WIDGET_OPTIONS.length - 1
      }
      changeWidgetFromKeyboardShortcut(next)
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    onModalClose()
  }

  useEffect(() => {
    window.addEventListener('keyup', handleKeyboardShortcut)
    return () => {
      window.removeEventListener('keyup', handleKeyboardShortcut)
    }
  }, [selectedType, currentCursor])

  return (
    <>
      {(forceModalOpen || modalOpen) ? (
        <>
          <Obscured onClick={() => handleCloseModal()} />
          <Modal>
            <WidgetSelectGrid>
              <WidgetOptions>
                {WIDGET_OPTIONS.map((option, w_idx) => (
                  <WidgetOption key={option.value}>
                    <WidgetSample
                      sampleWidgetText={option.text.replace(' Widget', '')}
                      widget_type={option.key}
                      onSampleClicked={(w_id, w_type) => (
                        handleSampleClicked(w_id, w_type, w_idx)
                      )}
                      viewingSample={viewingSample}
                    />
                  </WidgetOption>
                ))}
              </WidgetOptions>
              <WidgetSelection>
                {selectedType && (
                  <WidgetSelectButton
                    disabled={!selectedType}
                    onClick={() => (
                      assignWidgetToCell(rowIndex, columnIndex, selectedType)
                    )}
                  >
                    Use {selectedType}
                  </WidgetSelectButton>
                )}
                <OptionPortal id="option-portal" shrunk={!!viewingSample}>
                  {!viewingSample && (
                    <p>Select a widget to add to row {rowIndex + 1} column {columnIndex + 1}</p>
                  )}
                </OptionPortal>
              </WidgetSelection>
            </WidgetSelectGrid>
          </Modal>
        </>
      ) : (
        <ChangeCellWidget onClick={() => setModalOpen(true)}>
          <Icon name='images outline' size='big' fitted />
          <p>Add widget to cell</p>
        </ChangeCellWidget>
      )}
    </>
  )
}

export default EmptyGridCell
