import styled from '@emotion/styled'
import { css, keyframes } from '@emotion/react'
import { absoluteCenter, noScrollBars, offWhiteColorCode } from 'src/assets/styles/globalStyles'

const slidein = keyframes`
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 70px;
    opacity: 1;
  }
`

const slideout = keyframes`
  from {
    top: 70px;
    opacity: 1;
  }

  to {
    top: -300px;
    opacity: 0;
  }
`

const slideInAnimation = css`animation: ${slidein} 1s ease;`
const slideOutAnimation = css`animation: ${slideout} 1s ease;`

type AbsoluteCenterProps = {
  open: boolean
  shouldDisplay: boolean
  isMobile?: boolean
}

export const AbsoluteCenterDiv = styled.div<AbsoluteCenterProps>`
  background-color: ${offWhiteColorCode};
  width: ${({ isMobile }) => isMobile ? '100vw' : '80vw'};
  ${absoluteCenter}
  ${noScrollBars}
  top: 70px;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: scroll;
  max-height: 95vh;

  ${({ open, shouldDisplay }) => (open && shouldDisplay) ? slideInAnimation : ''}
  ${({ open, shouldDisplay }) => (!open && shouldDisplay) ? slideOutAnimation : ''}
`

interface DropdownProps {
  willOpen: boolean
}

/* eslint-disable */
export default styled.div<DropdownProps>`
  position: absolute;
  z-index: 4;
  top: 90px;
  bottom: 0;
  right: 0;

  ${(props) => props.willOpen
    ? 'display: none; visibility: hidden; min-height: 0; width: 0;'
    : 'display: block; visibility: visible; min-height: 370px; width: 300px;'
  }

  * { font-size: 1.1rem; }

  .transition-from {
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: -90vh;
  }

  .transition-to {
    position: fixed;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    top: 70px;
  }

  a, p {
    padding: 0;
    margin: 0;
  }
  
  fieldset {
    height: 100%;
    border: 1px solid #ddd;
  }

  .advanced-prog {
    position: absolute;
    right: 90px;
    width: 1280px;
  }

  .staff-routes {
    background-color: white;
    box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.2);
    padding: 24px 12px 12px;
    border-radius: 8px;
    border-color: transparent;
    padding-left: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;

    h3 {
      font-weight: bold;
      margin-bottom: 12px;
    }

    .admin-links, .teacher-links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      a {
        margin: 4px 0;
        padding: 4px;
      }

      span.icon {
        margin-right: 8px;
      }

      span.s-ui {
        position: relative;
        left: 4px;
        i { color: var(--jfz-blue); }
      }
    }
  }
`
/* eslint-enable */
