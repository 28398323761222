import React, { useState } from 'react'

import useFullscreen from 'src/components/fz_courses/hooks/useFullscreen'

const usePresentation = (widget, ref) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [currentItem, setCurrentItem] = useState(0)
  const { isFullscreen, goFullscreen } = useFullscreen(ref)

  const { title, subtitle, slides } = widget
  const slideLength = slides[currentSlide]?.english.length * 2

  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight') {
      if (currentItem < slideLength) setCurrentItem((p) => p + 1)
      else if (currentSlide < slides.length - 1) {
        setCurrentSlide((p) => p + 1)
        setCurrentItem(0)
      }
    }
    else if (e.key === 'ArrowLeft') {
      if (currentItem > 0) {
        setCurrentItem((p) => p - 1)
      }
      else if (currentSlide > 0) {
        setCurrentSlide((p) => {
          const newSlide = p - 1
          setCurrentItem(slides[newSlide].english.length * 2)
          return newSlide
        })
      }
    }
  }

  return {
    title,
    subtitle,
    slides,
    currentSlide,
    currentItem,
    isFullscreen,
    goFullscreen,
    handleKeyPress,
  }
}

export default usePresentation
