import styled from '@emotion/styled'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const assetsPath = `${CLOUDFRONT_BASE}/images/banners/summer_break`
const banner = `${assetsPath}/campaign-banner-account-90-days.webp`

const SummerBreakBanner = styled.div`
  height: 75px;
  max-width: 500px;
  align-self: center;
  background-image: url(${banner});
  background-position: center 40%;
  background-size: cover;
  margin: 16px auto;

  @media (max-width: 535px) { height: 50px }
  @media (max-width: 400px) { height: 40px }
`

export default SummerBreakBanner
