import { useContext } from 'react'
import { ActivityContext } from './useActivity'
import SoundRenderer from '../../SoundRenderer'
import useMobile from 'src/hooks/useMobile'
import {
  ActivityHeader,
  ActivityType,
  SpeechContainer,
  SpeechBubble,
  Commentary,
  Tomoko,
  Question,
  QuestionNumber,
  GradientProgress
} from './Activity.styles'
import { CLOUDFRONT_BASE } from 'src/util/constants'

const activityBase = `${CLOUDFRONT_BASE}/images/activities`
const tomokoIdle = `${activityBase}/act_tmk_idle.webp`
const tomokoCorrect = `${activityBase}/act_tmk_answer_correct.webp`
const tomokoIncorrect = `${activityBase}/act_tmk_answer_incorrect.webp`

const QuestionSection = ({ course }) => {
  const {
    currentQuestion, numberOfQuestions, question, commentary, showCommentary, answerSubmitted, answeredCorrectly, isBeta
  } = useContext(ActivityContext)
  const isMobile = useMobile()

  const feedbackTomoko = answeredCorrectly ? tomokoCorrect : tomokoIncorrect
  const questionText = Array.isArray(question) ? question.map((q) => q.text).join('') : question
  const commentaryText = Array.isArray(commentary) ? commentary.map((q) => q.text).join('') : commentary

  return (
    <>
      <ActivityHeader>
        <ActivityType course={course} isBeta={isBeta}>Activity</ActivityType>
        {showCommentary && commentary && (
          <SpeechContainer isMobile={isMobile}>
            <SpeechBubble xmlns="http://www.w3.org/2000/svg" viewBox="0 0 799 158" preserveAspectRatio="none">
              <path id="Rounded_Rectangle_1_copy" data-name="Rounded Rectangle 1 copy" className="cls-1" d="M48,0H731a48,48,0,0,1,48,48V82a47.778,47.778,0,0,1-7.506,25.784C770.913,108.7,770,110,770,110l29,48-63-28a18,18,0,0,0-2.425-.068Q732.3,130,731,130H48A48,48,0,0,1,0,82V48A48,48,0,0,1,48,0Z"/>
            </SpeechBubble>
            <Commentary>
              <SoundRenderer key={`${currentQuestion}-${commentaryText}`} text={commentary} />
            </Commentary>
          </SpeechContainer>
        )}
        <Tomoko src={answerSubmitted ? feedbackTomoko : tomokoIdle} alt='Tomoko observing' isMobile={isMobile} />
        <Question isMobile={isMobile}>
          <QuestionNumber isMobile={isMobile}>{currentQuestion + 1}</QuestionNumber>
          <SoundRenderer
            key={`${currentQuestion}-${questionText}`}
            text={question}
            bold
            fontFamily='Poppins'
            fontSize={isMobile ? '1rem' : '1.25rem'}
          />
        </Question>
      </ActivityHeader>
      <GradientProgress value={(currentQuestion / numberOfQuestions) * 100} size='xl' radius='xl' />
    </>
  )
}

export default QuestionSection
