import styled from 'styled-components'
import tw from 'twin.macro'

export const SliderMain = styled.div`
  margin: 0 auto;

  p { font-weight: lighter; }
`

export const InputSlider = styled.input`
  ${tw`tw-w-full tw-h-4 tw-rounded-full tw-outline-none tw-mt-2`}
  -webkit-appearance: none;
  appearance: none;
  background: var(--off-white);

  ::-webkit-slider-thumb {
    ${tw`tw-bg-fzblue tw-rounded-full tw-cursor-pointer`}
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border: 4px solid var(--off-white);
  }

  ::-moz-range-thumb {
    ${tw`tw-bg-fzblue tw-rounded-full tw-cursor-pointer`}
    width: 25px;
    height: 25px;
  }
`

export const SliderExplanation = styled.aside`
  ${tw`tw-mt-2 tw-text-white`}
  font-size: 0.9rem !important;
  font-weight: lighter;
  color: #e0e0e0;
`
