import styled from 'styled-components'

type BodyType = {
  type?: string
  isMobile?: boolean
}

type IsFreeAccount = {
  isFreeAccount?: boolean
}

const AskAQuestionBody = styled.div<BodyType>`
  padding: 20px !important;
  border-radius: 20px !important;
  ${({ type }) => !type && 'margin-bottom: 40px !important;'}
  ${({ type }) => type && 'padding-top: 0px !important;'}
  ${({ isMobile }) => isMobile && 'padding-top: 10px !important;'}

  .ui .header,
  .ui .button {
    color: var(--jfz-blue);
  }

  .ui .button {
    padding: 6px 12px;
    border: 2px solid var(--jfz-blue);
    background-color: white;
    margin: 40px auto 20px;
    font-size: 1.2rem;

    &:hover {
      color: white;
      background-color: var(--jfz-blue);
    }
  }
`

const QuestionTypesBody = styled.div`
  color: var(--blue-gray);
  background-color: white;
  padding: 25px 25px 50px;
  border-radius: 10px;

  h2 { font-size: 2rem; }
  * { font-family: 'Milliard Book'; }

  @media (max-width: 768px) {
    padding: 0 0 75px;
    background-color: transparent;
    h2 { font-size: 1.6rem; }
  }
`

const QuestionTypePickers = styled.div`
  display: flex;
  gap: 3rem;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    gap: 0;
  }
`

const QuestionTypeButton = styled.div<IsFreeAccount>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  filter: ${({ isFreeAccount }) => isFreeAccount && 'saturate(0)'};

  width: 400px;
  margin: 30px auto 0;
  border: 2px solid var(--jfz-blue);
  border-radius: 1.2rem;

  transition: all 175ms ease-out;
  cursor: pointer;

  :hover {
    background: linear-gradient(to bottom, #ADC1E0 77.5%, #ADC1E0 85%, #3765B0 100%);
    box-shadow: 3px 3px 6px 2px rgba(0,0,0,0.2);
    color: white;
    transform: scale(1.05);
  }

  img {
    width: 200px;
    margin: 20px auto 0;
  }

  @media (max-width: 768px) {
    width: 80vw;
    img { max-width: 175px; }
  }
`

const CardHeader = styled.div`
  width: 100%;
  padding: 25px 40px;
  background-color: var(--jfz-blue);
  text-align: center;
  border-bottom: 2px solid var(--sub-blue);

  h3 {
    font-size: 1.6rem;
    color: white;
  }

  @media (max-width: 768px) {
    h3 { font-size: 1.4rem; }
  }
`

const ViewTypesButton = styled.button`
  font-family: 'Milliard Book', sans-serif;
  background-color: white;
  border: 1.5px solid var(--jfz-blue);
  padding: 6px 12px 6px 6px;
  line-height: 2.4rem;
  border-radius: 8px;

  i {
    color: var(--jfz-blue);
    font-size: 2.4rem;
    border-radius: 50%;
  }

  span {
    margin-left: 8px;
    color: #333;
  }

  :hover {
    background-color: var(--jfz-blue);
    * { color: white; }
  }
  
  @media (max-width: 768px) {
    margin: -15px auto 25px;
  }
`

export {
  AskAQuestionBody,
  CardHeader,
  QuestionTypesBody,
  QuestionTypePickers,
  QuestionTypeButton,
  ViewTypesButton,
}
