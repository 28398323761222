import styled from 'styled-components'
import tw from 'twin.macro'

export const TagRing = styled.div`
  ${tw`tw-relative tw-inline-flex tw-items-center tw-rounded-full tw-border tw-border-gray-300 tw-px-3 tw-py-0.5 tw-mr-2`}
`

export const TagDotContainer = styled.div`
  ${tw`tw-absolute tw-flex-shrink-0 tw-flex tw-items-center tw-justify-center`}
`

export const TagDot = styled.span<{ dotColor: string }>`
  ${tw`tw-h-1.5 tw-w-1.5 tw-rounded-full`}
  background-color: ${({ dotColor }) => dotColor};
`

export const TagText = styled.div`
  ${tw`tw-ml-3.5`}
  text-transform: capitalize;
`
