import React, { useEffect } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import useToolkitStore from 'src/stores/toolkit.store'

interface ToolFormProps {
  children: React.ReactNode
  hookFormMethods: UseFormReturn
  widget_id: string
}

const ToolForm: React.FC<ToolFormProps> = ({
  children,
  hookFormMethods,
  widget_id,
}) => {
  const setAlteredWidgetIds = useToolkitStore((state) => state.setAlteredId)
  const savedIds = useToolkitStore((state) => state.savedIds)
  const watchForm = hookFormMethods.watch()
  // eslint-disable-next-line prefer-destructuring
  const dirtyFields = hookFormMethods.formState.dirtyFields

  useEffect(() => {
    const hasDirtyFields = Object.keys(dirtyFields).length
    if (!savedIds.includes(widget_id)) setAlteredWidgetIds(widget_id, hasDirtyFields > 0)
  }, [watchForm, dirtyFields])

  return (
    <FormProvider { ...hookFormMethods }>
      {children}
    </FormProvider>
  )
}

export default ToolForm
