import React, { useEffect, useMemo, useState } from 'react'
import * as R from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import axios from '../../../../api'
import { AxiosError, AxiosResponse } from 'axios'

import { PosterProps } from '../poster.types'

import styled, { createGlobalStyle } from 'styled-components'
import { KanaPosterMobile, KanaPosterDesktop } from '../styles/KanaPoster'
// import SoundRenderer from "src/components/fz_courses/widgets/SoundRenderer"

import useCF from 'src/hooks/useCF'
import useStaffRoles from 'src/hooks/useStaffRoles'
import useAPI from 'src/hooks/useAPI'
import { RootState } from 'src/configureStore'

import KanaChart from './KanaChart'
import ViewSwapButton from './ViewSwapButton'
import { ProgressiveSettingsResponse, ProgressiveSettings, CustomProgressive, REDUX_ACTIONS } from 'src/types'

const PosterBackground = createGlobalStyle`
  body {
    background-color: #ededf5;
  }
`

const WritingGuide = styled.section`
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 9px 3px rgba(0, 0, 0, 0.1);
  background-color: white;

  > div {
    width: 100%;
    object-fit: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 100%;
    filter: contrast(200%);
  }
`

interface JapanesePosterProps extends PosterProps {
  view: string
  customProgressiveSelector: boolean
  embed?: boolean
  isMobile?: boolean
}

interface WritingGuide {
  path: string
  canPoster?: boolean
  title: string
  _id: string
}

interface LabelClickResponse {
  className: string
  cards: string[]
  cardType: string
  labelType?: 'row' | 'column'
}

interface SavedCustomProgressive {
  enabled: boolean
  settings: CustomProgressive
}

const JapanesePoster: React.FC<JapanesePosterProps> = ({
  view,
  customProgressiveSelector,
  embed,
  isMobile
}) => {
  const [kanaView, setKanaView] = useState(0)
  const [viewChange, setViewChange] = useState(false)
  const [activeRow, setActiveRow] = useState<string>()
  const [activeColumn, setActiveColumn] = useState<string>()
  const [activeRowColType, setActiveRowColType] = useState<string>('kana')
  const [selectedKana, setSelectedKana] = useState<string>()
  const [writingGuide, setWritingGuide] = useState<WritingGuide>()

  const [collectedKana, setCollectedKana] = useState<CustomProgressive>({})
  const progSettings = useSelector((state: RootState) => state.auth.progressive_settings)
  const customProgDispatch = useDispatch()

  const [cfPath, getCfPath] = useCF()
  const [isStaffRole] = useStaffRoles()
  const [
    customSettingsResponse,
    loadingCustomSettings,
    patchCustomSettings
  ] = useAPI<ProgressiveSettingsResponse>('/progressive_settings/update_settings', 'PATCH')
  const [
    settingsResponse,
    loadingSettings,
  ] = useAPI<ProgressiveSettingsResponse>('/progressive_settings', 'GET')

  const updateCollectedKanaFromAPI = (custom_progressive: SavedCustomProgressive) => {
    setCollectedKana(custom_progressive?.settings)
  }

  useEffect(() => {
    if (
      settingsResponse?.success
      && settingsResponse?.progressive_settings?.custom_progressive?.settings
    ) {
      const { custom_progressive } = settingsResponse.progressive_settings
      updateCollectedKanaFromAPI(custom_progressive)
      const assocPath = R.assocPath(['custom_progressive', 'settings'], custom_progressive.settings, progSettings)
      customProgDispatch({
        type: REDUX_ACTIONS.CHANGE_SETTING,
        payload: assocPath
      })
    }
  }, [settingsResponse, loadingSettings])

  useEffect(() => {
    if (
      customSettingsResponse?.success
      && customSettingsResponse?.progressive_settings?.custom_progressive?.settings
    ) {
      const { custom_progressive } = customSettingsResponse.progressive_settings
      updateCollectedKanaFromAPI(custom_progressive)
      const assocPath = R.assocPath(['custom_progressive', 'settings'], custom_progressive.settings, progSettings)
      customProgDispatch({
        type: REDUX_ACTIONS.CHANGE_SETTING,
        payload: assocPath
      })
    }
  }, [customSettingsResponse, loadingCustomSettings])

  useEffect(() => {
    if (['hiragana', 'katakana'].includes(view.toLowerCase())) {
      if (view.toLowerCase() === 'hiragana') setKanaView(0)
      else if (view.toLowerCase() === 'katakana') setKanaView(1)
    }
  }, [view])
  
  useEffect(() => {
    setActiveRow(undefined)
    setActiveColumn(undefined)
    setWritingGuide(undefined)
    setActiveRowColType(undefined)
  }, [kanaView])

  const kanaCollector = (incomingKana: string[]) => {
    if (
      !incomingKana?.length
      // || !customProgressiveSelector
    ) return

    const _collected = {}

    let amountSelected = 0

    incomingKana.forEach((kana) => {
      if (collectedKana?.[kana]) amountSelected += 1
    })

    if (incomingKana.length === 1) {
      const kana = incomingKana[0]
      _collected[kana] = !collectedKana[kana]
    } else {
      incomingKana.forEach((kana) => {
        const isSelected = (Math.round(amountSelected / 3) < incomingKana.length)
          ? !collectedKana[kana]
          : true
        _collected[kana] = isSelected
      })
    }

    const custom_progressive = {
      ...collectedKana,
      ..._collected,
    }

    setCollectedKana(custom_progressive)
    console.log(custom_progressive)

    patchCustomSettings({ custom_progressive: { settings: custom_progressive, enabled: progSettings?.custom_progressive?.enabled } })
  }

  const buildKanaChartColumns = (hira: string[], kata: string[]) => {
    const threeSetColumnsHira = ['や', 'ゆ', 'よ', 'わ', 'を', 'ん']
    const threeSetColumnsKata = ['ヤ', 'ユ', 'ヨ', 'ワ', 'ヲ', 'ン']

    // use money for padding just like i wish i could do in real life
    const yaColumn = [
      ['や', '@', 'ゆ', '@', 'よ'],
      ['ヤ', '@', 'ユ', '@', 'ヨ'],
    ]
    const waColumn = [
      ['わ', '@', 'を', '@', 'ん'],
      ['ワ', '@', 'ヲ', '@', 'ン'],
    ]

    // hard coding indices of や columns since kana shouldn't change too often
    const yaColumnIndex = 12

    const fiveSetHira: string[] = hira.filter((kana: string) => !threeSetColumnsHira.includes(kana))
    const fiveSetKata: string[] = kata.filter((kana: string) => !threeSetColumnsKata.includes(kana))

    // every 5 indices of hira/kata arrays push the five set into a new array
    const fiveSetColumnHira = []
    R.splitEvery(5, fiveSetHira).forEach((_hira: string[]) => fiveSetColumnHira.push(_hira))

    const fiveSetColumns = []
    R.splitEvery(5, fiveSetKata).forEach((_kata: string[], index: number) => {
      fiveSetColumns.push([fiveSetColumnHira[index], _kata])
    })

    // adds yaColumn at the appropriate index
    fiveSetColumns.splice(yaColumnIndex, 0, yaColumn)

    // slap waColumn at the end
    fiveSetColumns.push(waColumn)

    fiveSetColumns.unshift(fiveSetColumns[0])

    return fiveSetColumns
  }

  const buildYouonChartColumns = (hira: string[], kata: string[]) => {
    const threeSetColumnHira: string[][] = []
    const threeSetColumnKata: string[][] = []

    R.splitEvery(3, hira).forEach((_hira) => {
      threeSetColumnHira.push(_hira)
    })

    R.splitEvery(3, kata).forEach((_kata) => {
      threeSetColumnKata.push(_kata)
    })

    const threeSetColumns: string[][][] = []
    threeSetColumnHira.forEach((col: string[], index: number) => {
      threeSetColumns.push([col, threeSetColumnKata[index]])
    })

    threeSetColumns.unshift(threeSetColumns[0])

    return threeSetColumns
  }

  const buildKanaChart = () => {
    const kataOffset = 'ア'.charCodeAt(0) - 'あ'.charCodeAt(0)
    const hira = Array.from('あいうえおかきくけこがぎぐげごさしすせそざじずぜぞたちつてとだぢづでどなにぬねのはひふへほばびぶべぼぱぴぷぺぽまみむめもやゆよらりるれろわをん')

    const kata: string[] = []
    // using the magic number, create a katakana from the charCode of the hira + the offset
    
    hira.forEach((_hira: string) => {
      const kataCharCode: number = _hira.charCodeAt(0)
      kata.push(String.fromCharCode(kataCharCode + kataOffset))
    })

    // forward results to another function to build columns to keep this one tidy
    return buildKanaChartColumns(hira, kata)
  }

  const buildYouonChart = () => {
    const kataOffset = 'ア'.charCodeAt(0) - 'あ'.charCodeAt(0)

    const kanaConcat = (p, s) => `${p}${s}`

    const youonHira: string[] = []
    const youonHiraPrefix = Array.from('きぎしじちにひびぴみり')
    const youonHiraSuffix = Array.from('ゃゅょ')

    youonHiraPrefix.forEach((pref: string) => {
      youonHiraSuffix.forEach((suf: string) => {
        youonHira.push(kanaConcat(pref, suf))
      })
    })

    const youonKata: string[] = []
    youonHiraPrefix.forEach((pref: string) => {
      const preKataCharCode: number = pref.charCodeAt(0)
      const preKata = String.fromCharCode(preKataCharCode + kataOffset)

      youonHiraSuffix.forEach((suf: string) => {
        const sufKataCharCode: number = suf.charCodeAt(0)
        const sufKata = String.fromCharCode(sufKataCharCode + kataOffset)
        youonKata.push(kanaConcat(preKata, sufKata))
      })
    })

    return buildYouonChartColumns(youonHira, youonKata)
  }

  // const kataToHira = (kata: string) => {
  //   const hiraOffset = "あ".charCodeAt(0) - "ア".charCodeAt(0)
  //   return String.fromCharCode(kata.charCodeAt(0) + hiraOffset)
  // }

  const getWritingGuide = async (kana: string) => {
    let _view: string
    if (view === 'Hiragana') _view = 'hira'
    if (view === 'Katakana') _view = 'kata'

    try {
      const response: AxiosResponse = await axios.post('/posters/japanese/writing-guide', {
        [_view]: kana,
        type: _view,
      })
      if (response.data.success) {
        await getCfPath(response.data.kanaWritingGuide.path)
        setWritingGuide(response.data.kanaWritingGuide)
      }
    } catch (err) {
      const error = err as AxiosError
      setSelectedKana(undefined)
      setWritingGuide(undefined)
      if (isStaffRole) console.error(error?.response?.data)
    }
  }

  const handleKanaClicked = (kana: string) => {
    if (kana === selectedKana) {
      setSelectedKana(undefined)
      setWritingGuide(undefined)
      return
    }

    setActiveColumn(undefined)
    setActiveRow(undefined)

    // add to selected kana
    kanaCollector([kana])

    // highlight cell
    setSelectedKana(kana)

    // get writing guide picture
    getWritingGuide(kana)
  }

  const handleLabelCLicked = (options: LabelClickResponse) => {
    const {
      className,
      cards,
      cardType,
      labelType
    } = options

    kanaCollector(cards)
    
    if (activeRowColType !== cardType) {
      setActiveColumn(undefined)
      setActiveRow(undefined)
    }
    
    setActiveRowColType(cardType)

    if (labelType === 'row') {
      return activeRow === className ? setActiveRow(undefined) : setActiveRow(className)
    }
    return activeColumn === className ? setActiveColumn(undefined) : setActiveColumn(className)
  }

  const kanaChart: string[][][] = useMemo(() => {
    return buildKanaChart()
  }, [])

  const youonChart: string[][][] = useMemo(() => {
    return buildYouonChart()
  }, [])

  const handleKanaView = () => {
    if (kanaView === 1) setKanaView(0)
    else setKanaView(1)
  }

  const handleViewChange = () => {
    setViewChange((prev) => !prev)
  }

  // FIXME: you-on should display a conjoined writing guide, need files for it
  // TODO: figure out `inject sounds` in backend to get proper sound files

  const KanaPoster = isMobile ? KanaPosterMobile : KanaPosterDesktop

  return (
    <KanaPoster embed={embed} className='poster japanese'>
      <PosterBackground />
      <div className='charts'>
        <ViewSwapButton
          beforeSwap={() => handleViewChange()}
          handleSwap={() => handleKanaView()}
          view={kanaView}
          embed={embed}
          isMobile={isMobile}
        />
        {/* <div
          style={{
            position: 'absolute',
            top: '-10px',
            left: '-105px',
            width: '60px',
            backgroundColor: 'white',
            padding: '8px',
            borderRadius: '15px',
            display: 'flex',
            flexDirection: 'column',
            gap: 0,
            maxHeight: '80vh',
            flexWrap: 'wrap',
          }}
        >
          {Object.keys(collectedKana).map((kana) => {
            if (!collectedKana[kana]) return
            return (
              <p key={kana} style={{ margin: '0' }}>
                {kana}
              </p>
            )
          })}
        </div> */}
        <div className='kana'>
          {kanaChart && (
            <KanaChart
              activeColumn={activeColumn}
              activeRow={activeRow}
              doubleKana={false}
              kanaChart={kanaChart}
              kanaView={kanaView}
              collectedKana={collectedKana}
              viewChange={viewChange}
              handleKanaClicked={handleKanaClicked}
              handleLabelCLicked={handleLabelCLicked}
              embed={embed}
              isMobile={isMobile}
            />
          )}
        </div>

        <div className='guide youon'>
          {selectedKana && cfPath && writingGuide && (
            <WritingGuide className='writing-guide'>
              <div>
                <img src={cfPath} alt={`how to write ${selectedKana}`} />
              </div>
            </WritingGuide>
          )}

          <section className='youon-chart'>
            {youonChart && (
              <KanaChart
                activeColumn={activeColumn}
                activeRow={activeRow}
                doubleKana={true}
                kanaChart={youonChart}
                kanaView={kanaView}
                collectedKana={collectedKana}
                viewChange={viewChange}
                handleKanaClicked={handleKanaClicked}
                handleLabelCLicked={handleLabelCLicked}
                embed={embed}
                isMobile={isMobile}
              />
            )}
          </section>
        </div>
      </div>
    </KanaPoster>
  )
}

export default JapanesePoster
