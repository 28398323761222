import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import useToolkitStore from '../../../../../stores/toolkit.store'
import useWidget from 'src/components/fz_courses/hooks/useWidget'

import { ToolProps } from '../interfaces/Tools.interface'
import { MINIQUIZ } from '../toolkit.constants'
import { MiniQuizQuestion, MiniQuizWidget } from 'src/types'
import { ToolBody } from '../styles'

import CourseCreationToolkit from '../CourseCreationToolkit'
import { ListInputDivider, ToolFooter, ToolForm, ToolHeader, ToolInput, ToolOptions, ToolSubheader } from '../parts'
import useWidgetSaved from '../hooks/useWidgetSaved'
import MiniQuizItem from './mini_quiz/MiniQuizItem'
import ConjugationSelection from './mini_quiz/ConjugationSelection'

const MiniQuizTool: React.FC<ToolProps<MiniQuizWidget>> = ({
  course,
  lesson,
  sublesson,
  index,
  incomingWidget: iw,
  widgetsLength,
  id,
  draggable_props,
  isGridCell = false,
  fetchCounter,
  fetchSubLesson,
  onGridWidgetSave = () => null,
}) => {
  const [saved, setSaved] = useState(false)
  const [storedItems, setStoredItems] = useState<MiniQuizQuestion[]>()
  const isOpen = isGridCell || useToolkitStore((state) => state.editingWidgetIds?.includes(id))
  const setWidgetValues = useToolkitStore((state) => state.setWidgetValues)
  const [widget, fetchWidget, , editWidget] = useWidget<MiniQuizWidget>(id)
  const hookFormMethods = useForm({ mode: 'onChange' })
  const [updatedAt, rawTimestamp, setUpdatedWidget] = useWidgetSaved()

  const { isValid, errors } = hookFormMethods.formState
  const subtype = hookFormMethods.watch('subtype')
  
  const AutomatedType = subtype === 'conjugation'
    ? <ConjugationSelection widget={widget} formMethods={hookFormMethods} />
    : <div className='tw-flex tw-justify-center tw-p-4'>This activity type is automated based on course location</div>

  const commitTemporaryChanges = (items: MiniQuizQuestion[]) => {
    hookFormMethods.setValue('items', items)
    setStoredItems(items)
  }

  const handleSaveWidget = async () => {
    if (!hookFormMethods.formState.isValid) return

    const values = hookFormMethods.getValues()
    if (['kana-typer', 'conjugation'].includes(values.subtype)) values.activity_type = 'input'

    await editWidget({ ...values, course }) // fetchWidget() is called in editWidget
    setSaved(true)
    fetchSubLesson()
  }

  useEffect(() => {
    // console.log('initializing watcher')
    // create a watcher on the hook form to detect and output changes
    hookFormMethods.watch((data) => {
      hookFormMethods.trigger()

      // also output whether the state is valid
      console.log('valid', hookFormMethods.formState.isValid)
      // console.log('form state', hookFormMethods.formState)
      // console.log('hook form methods', hookFormMethods)

      // if not valid, output the reason
      if (!hookFormMethods.formState.isValid) {
        console.log('invalid because', hookFormMethods.formState.errors)
      }
    })
  }, [])

  useEffect(() => {
    if (iw) fetchWidget()
  }, [iw, isOpen])

  useEffect(() => {
    if (widget) {
      const filterKeys = ['_id', '__v', 'type', 'sound_instances']
      Object.keys(widget).forEach((key) => {
        if (!filterKeys.includes(key)) hookFormMethods.setValue(key, widget[key])
      })
      if (widget.items.length) {
        commitTemporaryChanges(widget.items)
      } else {
        commitTemporaryChanges([{
          question: '',
          other_answers: [],
          other_commentary: [],
          correct_answer: '',
          multichoice_correct_answers: [],
          multichoice_correct_commentary: []
        }])
      }
      setWidgetValues(widget)
    }
    if (widget && saved) {
      setSaved(false)
      setUpdatedWidget(widget)
    }
  }, [widget])

  const addQuestion = (question_index: number) => {
    const _items = hookFormMethods.getValues()['items']
    _items.splice(question_index + 1, 0, {
      question: '',
      correct_answer: '',
      other_answers: [''],
      correct_commentary: '',
      other_commentary: [''],
      multichoice_correct_answers: [],
      multichoice_correct_commentary: [],
      forced_version: 'none',
      display_sound_icon: true,
      activity_type: 'text',
      question_activity_type: 'text',
      question_forced_version: 'none',
      question_display_sound_icon: true,
      image_correct_answers: []
    })
    commitTemporaryChanges(_items)
  }

  const removeQuestion = (question_index: number) => {
    const _items = hookFormMethods.getValues()['items']
    _items.splice(question_index, 1)
    commitTemporaryChanges(_items)
  }

  const addWrongAnswerToQuestion = (question_index: number, answer_index: number) => {
    const _items = hookFormMethods.getValues()['items']
    // may have been removed by react-hook-form unregister in ToolInput
    if (!_items[question_index].other_answers) _items[question_index].other_answers = []
    if (!_items[question_index].other_commentary) _items[question_index].other_commentary = []

    _items[question_index].other_answers.splice(answer_index + 1, 0, '')
    _items[question_index].other_commentary.splice(answer_index + 1, 0, '')
    if (_items[question_index].activity_type === 'images') {
      if (!_items[question_index].image_correct_answers) _items[question_index].image_correct_answers = []
      _items[question_index].image_correct_answers.splice(answer_index + 1, 0, false)
    }
    commitTemporaryChanges(_items)
  }

  const removeWrongAnswer = (question_index: number, answer_index: number) => {
    const _items = hookFormMethods.getValues()['items']
    _items[question_index].other_answers.splice(answer_index, 1)
    _items[question_index].other_commentary.splice(answer_index, 1)
    commitTemporaryChanges(_items)
  }

  const addCorrectAnswerToQuestion = (question_index: number) => {
    const _items = hookFormMethods.getValues()['items']
    // may have been removed by react-hook-form unregister in ToolInput
    if (!_items[question_index].multichoice_correct_answers) _items[question_index].multichoice_correct_answers = []
    
    _items[question_index].multichoice_correct_answers.push('')
    _items[question_index].multichoice_correct_commentary.push('')
    commitTemporaryChanges(_items)
  }

  const removeCorrectAnswer = (question_index: number, multichoice_index: number) => {
    const _items = hookFormMethods.getValues()['items']
    _items[question_index].multichoice_correct_answers.splice(multichoice_index, 1)
    _items[question_index].multichoice_correct_commentary.splice(multichoice_index, 1)
    commitTemporaryChanges(_items)
  }

  const handleSubtypeUpdated = (newSubtype: string) => {
    const values = hookFormMethods.getValues()
    values.subtype = newSubtype

    if (!hookFormMethods.formState.isValid) return
    editWidget({ ...values, course })
  }

  const handleActivityTypeChange = (idx: number, newActivityType: string, type = '') => {
    const _items = hookFormMethods.getValues()['items']
    _items[idx][`${type}activity_type`] = newActivityType
    hookFormMethods.setValue(`${type}activity_type`, newActivityType)
    commitTemporaryChanges(_items)
  }

  const handleForcedVersionChange = (idx: number, newForcedVersion: string, type = '') => {
    const _items = hookFormMethods.getValues()['items']
    _items[idx][`${type}forced_version`] = newForcedVersion
    hookFormMethods.setValue(`${type}forced_version`, newForcedVersion)
    commitTemporaryChanges(_items)
  }

  const handleQuestionImageChange = (idx: number, newImage: string) => {
    console.log(idx, newImage)
    const values = hookFormMethods.getValues()
    const _items = hookFormMethods.getValues()['items']
    _items[idx].question_image = newImage
    hookFormMethods.setValue(`items.${idx}.question_image`, newImage)
    commitTemporaryChanges(_items)
    editWidget({ ...values, course })
  }

  const handleAnswerImageChange = (idx: number, newImage: string, idy: number) => {
    console.log(idx, idy, newImage)
    const values = hookFormMethods.getValues()
    const _items = hookFormMethods.getValues()['items']
    _items[idx].other_answers[idy] = newImage
    hookFormMethods.setValue('other_images', newImage)
    commitTemporaryChanges(_items)
    editWidget({ ...values, course })
  }

  const handleSetCommentary = (idx: number, idy: number, commentary: string) => {
    const _items = hookFormMethods.getValues()['items']
    _items[idx].other_commentary[idy] = commentary
    commitTemporaryChanges(_items)
  }

  return (
    <CourseCreationToolkit
      widget_id={id}
      widget_type={MINIQUIZ}
      draggable_props={draggable_props}
      defaultOpenToolkit={isGridCell}
      course={course}
      index={index}
      incomingWidget={iw}
      widgetsLength={widgetsLength}
      fetchCounter={fetchCounter}
    >
      <div className='textbox-tool'>
        <ToolForm hookFormMethods={hookFormMethods} widget_id={id}>
          <ToolHeader
            widget_type={MINIQUIZ}
            widget_id={id}
            isGridCell={isGridCell}
            onSubtypeChange={handleSubtypeUpdated}
            hookForm={hookFormMethods.getValues()}
            widget_subtype={hookFormMethods.watch()['subtype']}
          />
          <ToolOptions id={id} isGridCell={isGridCell}>
            <div>
              <ToolInput
                value='is_draft'
                inputType='checkbox'
                inputLabel='Drafting'
                labelColor='var(--blue-gray)'
                labelPosition='after'
              />
              <ToolInput
                value='embedded'
                inputType='checkbox'
                inputLabel='Embedded'
                labelColor='var(--blue-gray)'
                labelPosition='after'
              />
            </div>
          </ToolOptions>
          {['kana-typer', 'conjugation'].includes(subtype)
            ? AutomatedType
            : (
              <ToolBody>
                {!storedItems?.length ? (
                  <ListInputDivider
                    handleClicked={() => addQuestion(0)}
                    buttonLeftShift={'calc(50% - 19px)'}
                    title='Add Question'
                    buttonAsText
                  />
                ) : (storedItems.map((item, item_index) => (
                  <MiniQuizItem
                    key={item_index}
                    total_questions={storedItems.length}
                    item={item}
                    item_index={item_index}
                    handleAddQuestion={addQuestion}
                    handleAddWrongAnswer={addWrongAnswerToQuestion}
                    handleRemoveQuestion={removeQuestion}
                    handleRemoveWrongAnswer={removeWrongAnswer}
                    handleAddCorrectAnswer={addCorrectAnswerToQuestion}
                    handleRemoveCorrectAnswer={removeCorrectAnswer}
                    handleActivityTypeChange={handleActivityTypeChange}
                    handleForcedVersionChange={handleForcedVersionChange}
                    handleQuestionImageChange={handleQuestionImageChange}
                    handleAnswerImageChange={handleAnswerImageChange}
                    handleSetCommentary={handleSetCommentary}
                    course={course}
                    lesson={lesson}
                    sublesson={sublesson}
                    widget_id={id}
                  />
                )))}
              </ToolBody>
            )
          }

          <ToolFooter
            lastEdited={widget?.updatedAt}
            lastEditedBy={widget?.last_edited_by}
            savedTimestamp={updatedAt}
            rawTimestamp={rawTimestamp}
            handleSave={() => {
              handleSaveWidget()
              onGridWidgetSave()
            }}
            // save disabled if not valid AND errors is not just an empty object
            saveDisabled={!isValid && Object.keys(errors).length !== 0}
            widget_id={id}
            widget_type={MINIQUIZ}
          />
        </ToolForm>
      </div>
    </CourseCreationToolkit>
  )
}

export default MiniQuizTool
