import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Form, Grid, Icon, Input, Label, Segment } from 'semantic-ui-react'

import useWidget from '../../hooks/useWidget'

const SentencesWidgetEditor = (props) => {
  const [sentences, setSentences] = useState([])
  const [title, setTitle] = useState()
  const [vertical, setVertical] = useState(false)
  const [widget, fetchWidget, loading, editWidget] = useWidget(props.id)
  const [ordered, setOrdered] = useState(false) // change to pseudo ordered list
  const [startNum, setStartNum] = useState(0) // what number to start counting from on client view
  const [playsSound, setPlaysSound] = useState(false) // disables SoundInjector
  const [spaceEvery, setSpaceEvery] = useState(0)

  useEffect(() => {
    setSentences(widget?.sentences || [])
    setVertical(widget?.vertical || false)
    setPlaysSound(widget?.plays_sound)
    setOrdered(widget?.ordered)
    setStartNum(widget?.startNum)
    setTitle(widget?.title)
    setSpaceEvery(widget?.spaceEvery)
  }, [widget])

  const setSentence = (idx, val) => {
    let updatedSentences = [...sentences]
    updatedSentences[idx] = val
    setSentences(updatedSentences)
  }

  const addSentence = () => {
    let updatedSentences = [...sentences]
    updatedSentences.push('')
    setSentences(updatedSentences)
  }

  const deleteSentence = (idx) => {
    let updatedSentences = [...sentences]
    updatedSentences.splice(idx, 1)
    setSentences(updatedSentences)
  }

  const addPair = (idx) => {
    let updatedSentences = [...sentences]
    updatedSentences[idx].pairs.push({
      english: "",
      other_lang: "",
    })
    setSentences(updatedSentences)
  }

  const removePair = (idx, pidx) => {
    let updatedSentences = [...sentences]
    updatedSentences[idx].pairs.splice(pidx, 1)
    setSentences(updatedSentences)
  }

  const handleCommentaryAlignmentChange = (index) => {
    let updatedSentences = [...sentences]
    let comm = updatedSentences[index].commentary
    if (typeof comm === 'object') comm.align = !comm?.align
    if (!comm?.content) comm.content = ""
    setSentences(updatedSentences)
  }

  const handlePairsSentenceChange = (e, idx, pidx, value) => {
    let updatedSentences = [...sentences]
    updatedSentences[idx].pairs[pidx][e.target.name] = value
    setSentences(updatedSentences)
  }

  return (
    <Segment raised inverted>
      <Grid centered style={{ marginBottom: "20px" }}>
        <Grid.Row style={{ alignItems: "center" }}>
          <Grid.Column width={1}>
            <Label>Title:</Label>
          </Grid.Column>
          <Grid.Column width={15}>
            <Input
              name="title"
              fluid
              value={title}
              placeholder="Title"
              onChange={(_, { value }) => setTitle(value)}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Form>
        {sentences.map((_, idx) => (
          <div style={{ marginBottom: "20px", width: "100%" }}>
            <Label>Example sentence {idx + 1}:</Label><br/>
            <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
              <Label style={{ width: "100%", margin: "0 auto 10px" }} for={`other_lang-${idx}`}>Target language {idx + 1} 必須
                <Form.Input
                  name={`other_lang-${idx}`}
                  placeholder="Target language"
                  value={sentences[idx].other_lang}
                  onChange={(_2, { value }) =>
                  setSentence(idx, { ...sentences[idx], other_lang: value })
                }
                />
              </Label>
              <Label style={{ width: "100%", margin: "0 auto 10px" }} for={`english-${idx}`}>English {idx + 1} 必須
                <Form.Input
                  name={`english-${idx}`}
                  placeholder="English"
                  value={sentences[idx].english}
                  onChange={(_3, { value }) =>
                    setSentence(idx, { ...sentences[idx], english: value })
                  }
                />
              </Label>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                <Label style={{ width: '80%', margin: '0 auto 10px 0' }} for={`commentary-${idx}`}>Commentary {idx + 1} 任意
                  <Form.Input
                    name={`commentary-${idx}`}
                    placeholder='Commentary'
                    value={sentences[idx].commentary?.content}
                    onChange={(_3, { value }) =>
                      setSentence(idx, { ...sentences[idx], commentary: { ...sentences[idx].commentary, content: value } })
                    }
                  />
                </Label>
                <Button
                  color="purple"
                  onClick={() => handleCommentaryAlignmentChange(idx)}
                  icon={sentences[idx].commentary?.align ? "align left" : "align right"}
                  label={sentences[idx].commentary?.align ? "Left Column" : "Right Column"}
                />
              </div>
            </div>
            {!!sentences[idx].pairs?.length &&
            <>
              {sentences[idx].pairs?.map((_4, pidx) => (
                <div style={{ width: "90%", margin: "0 auto", display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
                  <Label style={{ width: "100%", margin: "0 auto 10px" }}>Pair {idx + 1}-{pidx + 1} Target language 必須
                    <Form.Input
                      placeholder={`Pair ${idx + 1}-${pidx + 1} Target language`}
                      value={sentences[idx].pairs[pidx].other_lang}
                      name="other_lang"
                      onChange={(e, { value }) => handlePairsSentenceChange(e, idx, pidx, value)}
                    />
                  </Label>
                  <Label style={{ width: "100%", margin: "0 auto 10px" }}>Pair {idx + 1}-{pidx + 1} English 必須
                    <Form.Input
                      placeholder={`Pair ${idx + 1}-${pidx + 1} English`}
                      value={sentences[idx].pairs[pidx].english}
                      name="english"
                      onChange={(e, { value }) => handlePairsSentenceChange(e, idx, pidx, value)}
                    />
                  </Label>
                  <Button
                    color="red"
                    title="Remove Pair"
                    style={{
                      alignSelf: "flex-end",
                      marginBottom: "10px",
                    }}
                    onClick={() => removePair(idx, pidx)}
                  >
                    <Icon name="trash" />
                  </Button>
                </div>
              ))}
            </>
            }
            <Button color="red" title="Delete Sentence (and Pairs)" onClick={() => deleteSentence(idx)}>
              <Icon name="trash" />
            </Button>
            <Button color="green" title="Add Pair" onClick={() => addPair(idx)}>
              <Icon name="add circle" />
            </Button>
          </div>
        ))}
      </Form>
      <Grid centered>
        <Grid.Row>
          <Grid.Column width={3}>
            <Button color="green" onClick={() => editWidget({ sentences, vertical, title, ordered, startNum, plays_sound: playsSound, spaceEvery })}>
              <Icon name="save" />
            </Button>
          </Grid.Column>
          <Grid.Column width={3}>
            <Button color="blue" onClick={addSentence}>
              <Icon name="plus" />
            </Button>
          </Grid.Column>
          <Grid.Column width={8}>
            <Segment
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <Label>
                <Checkbox
                  checked={vertical}
                  onChange={(_, { checked }) => setVertical(checked)}
                />
                &nbsp;Display Vertical
              </Label>

              <Label>
                <Checkbox
                  checked={playsSound}
                  onChange={(_, { checked }) => setPlaysSound(checked)}
                />
                &nbsp;Play Sound?
              </Label>

              <Label>
                <Checkbox
                  checked={ordered}
                  onChange={(_, { checked }) => setOrdered(checked)}
                  />
                &nbsp;Numbered List
              </Label>

              <Label>Start from:&nbsp;
                <Input
                  type="number"
                  min="1"
                  value={startNum}
                  disabled={!ordered}
                  onChange={(_, { value }) => setStartNum(value)}
                  style={{
                    width: "75px",
                  }}
                />
              </Label>

              <Label>Space every:&nbsp;
                <Input
                  type="number"
                  min="0"
                  value={spaceEvery}
                  onChange={(_, { value }) => setSpaceEvery(value)}
                  style={{
                    width: "75px",
                  }}
                />
              </Label>
              
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

export default SentencesWidgetEditor