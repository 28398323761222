import React, { useEffect, useState } from 'react'
import { Button, List, Paper, Text, Title } from '@mantine/core'
import { Label } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { Accordion, LoadingOverlay, Pagination, Tabs } from '@mantine/core'

import { Widget } from 'src/types/mongo_types/course_widgets'
import DropdownStyles, { AbsoluteCenterDiv } from '../../styles/DropdownStyles'
import { widgetOptions } from '../../../fz_courses/sublessonpart/sublesson_part_imports'
import RenderedWidget from '../../../fz_courses/admin/editor/styles/RenderedWidget'
import { CacheLanguageRecord, CacheMasterRecord, DictionaryRecord, SearchResults, AaTQuestion, AaTAnswer } from 'src/hooks/useSearch'
import useMobile from 'src/hooks/useMobile'
import useAPI from 'src/hooks/useAPI'
import SoundRenderer from 'src/components/fz_courses/widgets/SoundRenderer'

const SearchResultPaper = styled(Paper)`
  text-align: left;
  padding: 20px;

  div > button {
    background-color: teal;
  }
`

type EmbeddedWidgetProps = {
  widget: Widget
  idx: number
}

const EmbeddedWidget: React.FC<EmbeddedWidgetProps> = ({ widget, idx }) => {
  const { type, _id } = widget
  const isMobile = useMobile()
  const WidgetView = widgetOptions[type]?.w

  return (
    <RenderedWidget
      key={`rendered-widget-${_id}`}
      idx={idx}
      hasNoPadding={['ExamplesWidget', 'SentencesWidget', 'QnAWidget'].includes(type)}
      isFirstIndex={idx === 0}
      isMobile={isMobile}
      widgetType={type}
      fetchCounter={0}
    >
      <WidgetView
        id={_id}
        lesson=''
        course=''
        adminView={true}
        widget={widget}
        courseColor=''
      />
    </RenderedWidget>
  )
}

const courseMap = {
  'jfz1': 'JapaneseFZ 1',
  'jfz2': 'JapaneseFZ 2',
  'jfz3': 'JapaneseFZ 3',
  'jfz4': 'JapaneseFZ 4',
  'jfz5': 'JapaneseFZ 5',
}

const CourseLocationBreadcrumb = ({ courses_location, record }) => {
  const { course, lesson, sublesson, sublessonpart } = courses_location

  const courseStr = `/courses/${course?.identifier}`
  
  const lessonIndex = course?.lessons?.indexOf(lesson?._id)
  const prelessonIndex = course?.prelessons?.indexOf(lesson?._id)
  const preToL = String.fromCharCode('A'.charCodeAt(0) + prelessonIndex)
  const lessonChar = prelessonIndex > -1 ? preToL : lessonIndex + 1

  const lessonStr = `${courseStr}/${lessonChar}`
  const lessonLink = <Link to={lessonStr}>{courseMap[course?.identifier]} - Lesson {lessonChar}</Link>
  const courseLink = <Link to={`${courseStr}`}>{course?.identifier}</Link>

  const sublessonIndex = lesson?.sublessons?.indexOf(sublesson?._id)
  const sublessonStr = `${lessonStr}/${sublessonIndex + 1}`
  const sublessonLink = <Link to={`${sublessonStr}${record ? `?soundID=${record._id}` : ''}`}>{sublesson?.title}</Link>

  const partIndex = sublesson?.parts?.indexOf(sublessonpart?._id)
  const partStr = partIndex > 0 ? `${sublessonStr}/${partIndex + 1}` : sublessonStr
  const partLink = <Link to={`${partStr}${record ? `?soundID=${record._id}` : ''}`}>{sublessonpart?.title || sublesson?.title}</Link>
  const partTitle = sublessonpart?.title || sublesson?.title

  return (
    <div>
      {/* {courseLink} &gt;&nbsp; */}
      {lessonLink} &gt;&nbsp;
      {/* {sublessonLink} &gt;&nbsp; */}
      {partLink}
      {/* put a yellow "Admin" label if "Lesson Quiz" is in the partTitle */}
      {partTitle?.includes('Lesson Quiz') && <Label color='yellow' size='mini' style={{ marginLeft: '10px' }}>Admin</Label>}
    </div>
  )
}

const EmbeddedWidgetContainer = ({ cache, record }) => {
  const [visible, setVisible] = useState(false)
  const { courses_location = {} } = cache

  console.log(cache)
  console.log(courses_location)

  return (
    <div>
      <CourseLocationBreadcrumb courses_location={courses_location} record={record} />
      {cache?.type && <Button onClick={() => setVisible(!visible)}>View Section</Button>}
      {visible && <EmbeddedWidget idx={0} key={cache._id} widget={cache} />}
    </div>
  )
}

type SiteSearchResultsProps = {
  open: boolean
  onClose: () => void
  results: SearchResults
  setCategory: (category: string) => void
  setPage: (page: number) => void
  counts: {
    sounds: number
    questions: number
    answers: number
    dict: number
    english: number
  }
  loading?: boolean
}

type SearchResultProps = {
  record: CacheLanguageRecord
}

type SearchResultPropsEn = {
  record: CacheMasterRecord
}

type SearchResultQuestionProps = {
  record: AaTQuestion
}

type SearchResultAnswerProps = {
  record: AaTAnswer
}

const LessonLocations: React.FC<SearchResultProps> = ({ record }) => {
  const [value, setValue] = useState(null)
  const [res, loading, req] = useAPI('/search/sounds_locs', 'LAZYGET')

  useEffect(() => {
    if (res) return
    if (!value) return

    req({ params: { record } })
  }, [value])

  return (
    <Accordion value={value} onChange={setValue}>
      <LoadingOverlay visible={loading} />
      <Accordion.Item value='lessons'>
        <Accordion.Control>Links</Accordion.Control>
        <Accordion.Panel>
          {res?.caches?.map((cache, idx) => <EmbeddedWidgetContainer key={`${cache._id}-${record._id}-${idx}-jp`} cache={cache} record={record} />)}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}

const LessonLocationsEn: React.FC<SearchResultPropsEn> = ({ record }) => {
  const [value, setValue] = useState(null)
  const [res, loading, req] = useAPI('/search/english_locs', 'LAZYGET')

  useEffect(() => {
    if (res) return
    if (!value) return

    req({ params: { record } })
  }, [value])

  return (
    <Accordion value={value} onChange={setValue}>
      <LoadingOverlay visible={loading} />
      <Accordion.Item value='lessons'>
        <Accordion.Control>Links</Accordion.Control>
        <Accordion.Panel>
          {res?.caches?.map((cache, idx) => <EmbeddedWidgetContainer key={`${cache._id}-${record._id}-${idx}-en`} cache={cache} record={record} />)}
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}

const SearchResult: React.FC<SearchResultProps> = ({ record }) => {
  return (
    <SearchResultPaper>
      {/* <Title>Language Record</Title> */}
      <List>
        <List.Item>
          <SoundRenderer
            text={[{ record }]}
          />
        </List.Item>
        {/* <List.Item icon={<span>漢字</span>}>
          {record.kanji}
        </List.Item>
        <List.Item icon={<span>ひらカタ</span>}>
          {record.hirakata}
        </List.Item>
        <List.Item icon={<span>ABC</span>}>
          {record.romaji}
        </List.Item> */}
        <List.Item icon={<span>E</span>}>
          {record.english?.[0]?.text}
        </List.Item>
      </List>
      <LessonLocations record={record} />
      {/* <Button onClick={() => console.log(record)}>Add to Notebook</Button> */}
    </SearchResultPaper>
  )
}

const SearchResultLesson: React.FC<SearchResultProps> = ({ record }) => {
  return (
    <SearchResultPaper>
      {/* <Title>Lesson Record</Title> */}
      <List>
        <List.Item>
          <SoundRenderer
            text={[{ record }]}
          />
        </List.Item>
        {/* <List.Item icon={<span>漢字</span>}>
          {record.kanji}
        </List.Item>
        <List.Item icon={<span>ひらカタ</span>}>
          {record.hirakata}
        </List.Item>
        <List.Item icon={<span>ABC</span>}>
          {record.romaji}
        </List.Item> */}
        <List.Item icon={<span>E</span>}>
          {record.english?.[0]?.text}
        </List.Item>
      </List>
      {record?.caches?.map((cache, idx) => <EmbeddedWidgetContainer key={`${cache._id}-${record._id}-${idx}-l`} cache={cache} record={record} />)}
    </SearchResultPaper>
  )
}

type DictionarySearchResultProps = {
  record: DictionaryRecord
}

const DictionaryResult: React.FC<DictionarySearchResultProps> = ({ record }) => {
  // console.log(record)
  const labels = {
    n: 'noun',
    'adj-f': 'adjective',
  }

  const { _id } = record

  const normalizedSense = Array.isArray(record.sense) ? record.sense : [record.sense]
  const glosses = normalizedSense.map((sense, idy) => {
    const glossLabels = Array.isArray(sense?.pos)
      ? sense.pos.map((pos, idx) => (
        <Label key={`${_id}-pos-${idx}`}>
          {pos.replace('&', '').replace(';', '')}
        </Label>
      )) : (
        <Label>
          {sense?.pos.replace('&', '').replace(';', '')}
        </Label>
      )
    const glossArr = Array.isArray(sense?.gloss) ? sense.gloss.join(', ') : (sense?.gloss?.['#text'] || sense?.gloss)

    return (
      <div key={`${_id}-gloss-${idy}`}>
        {glossLabels}&nbsp;
        {glossArr}
      </div>
    )
  })

  return (
    <SearchResultPaper>
      {record.k_ele?.length ? <Title>{record.k_ele.map((kele) => kele.keb).join(', ')}</Title> : <></>}
      {!record.k_ele?.length ? <Title>{record.r_ele?.map((rele) => rele.reb).join(', ')}</Title> : <></>}
      {record.k_ele?.length ? <Text>{record.r_ele?.map((rele) => rele.reb).join(', ')}</Text> : <></>}
      <Text>
        {glosses}
      </Text>
    </SearchResultPaper>
  )
}

const QuestionResult: React.FC<SearchResultQuestionProps> = ({ record }) => {
  return (
    <SearchResultPaper>
      <Title>{record?.title}</Title>
      <Text>{record?.body?.slice(0, 100)}</Text>
    </SearchResultPaper>
  )
}

const AnswerResult: React.FC<SearchResultAnswerProps> = ({ record }) => {
  return (
    <SearchResultPaper>
      <Text>{record?.body?.slice(0, 100)}</Text>
    </SearchResultPaper>
  )
}

type SearchResultEnProps = {
  record: CacheMasterRecord
}

const SearchResultEn: React.FC<SearchResultEnProps> = ({ record }) => {
  return (
    <SearchResultPaper>
      {/* <Title>Master Record</Title> */}
      <Text>{record.text}</Text>
      <LessonLocationsEn record={record} />
    </SearchResultPaper>
  )
}

type SoundResultsProps = {
  results: SearchResults
  count?: number
  setPage?: (page: number) => void
}

const SoundResults: React.FC<SoundResultsProps> = ({ results, count, setPage }) => {
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    if (setPage) {
      setPage(activePage)
    }
  }, [activePage])

  return (
    <Tabs.Panel value="sounds">
      <Pagination value={activePage} onChange={setActivePage} total={Math.ceil(count / 20)} />
      {results?.jp?.map((result) => <SearchResult key={`${result._id}-jp`} record={result} />)}
      {/* {results?.en?.map((result) => <SearchResultEn key={result._id} record={result} />)} */}
    </Tabs.Panel>
  )
}

const EnglishResults: React.FC<SoundResultsProps> = ({ results, count, setPage }) => {
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    if (setPage) {
      setPage(activePage)
    }
  }, [activePage])

  return (
    <Tabs.Panel value="english">
      <Pagination value={activePage} onChange={setActivePage} total={Math.ceil(count / 20)} />
      {results?.en?.map((result) => <SearchResultEn key={`${result._id}-en`} record={result} />)}
      {/* {results?.en?.map((result) => <SearchResultEn key={result._id} record={result} />)} */}
    </Tabs.Panel>
  )
}

const LessonResults: React.FC<SoundResultsProps> = ({ results }) => {
  return (
    <Tabs.Panel value="lessons">
      {results?.jp?.map((result) => <SearchResultLesson key={result._id} record={result} />)}
      {results?.en?.map((result) => <SearchResultEn key={result._id} record={result} />)}
    </Tabs.Panel>
  )
}

const QuestionResults: React.FC<SoundResultsProps> = ({ results }) => {
  return (
    <Tabs.Panel value="questions">
      {results?.aat?.questions?.map((result) => <QuestionResult key={result._id} record={result} />)}
    </Tabs.Panel>
  )
}

const DictionaryResults: React.FC<SoundResultsProps> = ({ results, count, setPage }) => {
  const [activePage, setActivePage] = useState(1)

  useEffect(() => {
    if (setPage) {
      setPage(activePage)
    }
  }, [activePage])

  return (
    <Tabs.Panel value="dictionary">
      <Pagination value={activePage} onChange={setActivePage} total={Math.ceil(count / 20)} />
      {results?.dict?.map((result) => <DictionaryResult key={result._id} record={result} />)}
    </Tabs.Panel>
  )
}

const AnswerResults: React.FC<SoundResultsProps> = ({ results }) => {
  return (
    <Tabs.Panel value="answers">
      {results?.aat?.answers?.map((result) => <AnswerResult key={result._id} record={result} />)}
    </Tabs.Panel>
  )
}

const SiteResults: React.FC<SoundResultsProps> = ({ results }) => {
  return (
    <Tabs.Panel value="site">
    </Tabs.Panel>
  )
}

const SiteSearchResults: React.FC<SiteSearchResultsProps> = ({
  open, onClose, results, setCategory, setPage, counts, loading
}) => {
  const [activeTab, setActiveTab] = useState('sounds')

  useEffect(() => {
    setCategory(activeTab)
    setPage(1)
  }, [activeTab])

  if (!open) return <></>

  return (
    <DropdownStyles willOpen={false}>
      <AbsoluteCenterDiv open={open} shouldDisplay={open}>
        <Title order={1} style={{ paddingBottom: '40px' }}>Search Results</Title>
        <Tabs value={activeTab} onTabChange={setActiveTab}>
          <Tabs.List>
            <Tabs.Tab value="sounds">Sounds ({counts?.sounds})</Tabs.Tab>
            <Tabs.Tab value='english'>English ({counts?.english})</Tabs.Tab>
            <Tabs.Tab value="lessons">Lessons</Tabs.Tab>
            <Tabs.Tab value="dictionary">Dictionary ({counts?.dict})</Tabs.Tab>
            <Tabs.Tab value="questions">Questions ({counts?.questions})</Tabs.Tab>
            <Tabs.Tab value="answers">Answers ({counts?.answers})</Tabs.Tab>
            <Tabs.Tab value="site">Site</Tabs.Tab>
          </Tabs.List>
          <LoadingOverlay visible={loading} />
          <SoundResults results={results} count={counts?.sounds} setPage={setPage} />
          <EnglishResults results={results} count={counts?.english} setPage={setPage} />
          <LessonResults results={results} />
          <DictionaryResults results={results} count={counts?.dict} setPage={setPage} />
          <QuestionResults results={results} count={counts?.questions} />
          <AnswerResults results={results} count={counts?.answers} />
          <SiteResults results={results} />
        </Tabs>
      </AbsoluteCenterDiv>
    </DropdownStyles>
  )
}

export default SiteSearchResults
