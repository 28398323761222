import styled from 'styled-components'

interface DrawerProps {
  willOpen: boolean
}

export default styled.div<DrawerProps>`
  position: absolute;
  z-index: 2;
  top: 70px;
  bottom: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  ${(props) => props.willOpen
    ? 'display: none; visibility: hidden; height: 0;'
    : 'display: block; visibility: visible; height: 100vh;'
    // eslint-disable-next-line
  }

  * {
    font-size: 1.1rem;
  }

  >div {
    width: 100%;
    height: 100%;
    border-right: 1px solid #ddd;
  }

  .nav-items {
    height: 100vh;
    background-color: var(--off-white);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 10px;
    width: 100%;
    overflow-y: auto;
    overflow-x: auto;
  }
  
  .drawer-icon {
    margin: 4px 8px;

    .s-ui i {
      font-size: 1.8rem;
      margin: 0 10px 10px 0;
    }
    .s-ui.white i { color: var(--off-white); }
    .s-ui.gray i { color: var(--blue-gray); }
    .s-ui.blue i { color: var(--jfz-blue); }

    :first-child {
      padding-top: 24px;
    }

    a {
      min-width: 165px;
      display: inline-flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      
      padding: 8px;
      border-bottom: 2px solid transparent;
      
      svg, img {
        width: 25px;
        height: 25px;
        margin-right: 8px;
      }

      :hover {
        border-color: var(--jfz-blue);
      }
    }
    a.active {
      border-color: var(--jfz-blue);
    }

  }

  .drawer-button { margin: 12px auto 0; }

  .drawer-button button {
    padding: 8px 20px;
    border-radius: 50px;
    color: var(--off-white);
    background-color: var(--jfz-blue);
  }
`
